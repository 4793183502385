import { Collapse, Spin } from "antd";
import { orderBy } from "lodash";
import { useCallback, useState } from "react";
import { TComposition } from "shared/types/assetExporter";
import { AddMediaDropdown } from "./panelVariables/AddMediaDropdown";
import { ImageVariableInput } from "../../assetBatchDrawer/panelVariables/ImageVariableInput";
import { LogoVariableInput } from "../../assetBatchDrawer/panelVariables/LogoVariableInput";
import { getValueMappingKey } from "../../assetBatchDrawer/utils";
import { AssetBatchesCompositionProvider } from "../../shared/contexts/AssetBatchesCompositionContext";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { mediaTypes } from "../../shared/types";
import { VariableCollapseHeader } from "./panelVariables/VariableCollapseHeader";
import { TextVariableInput } from "../../assetBatchDrawer/panelVariables/TextVariableInput";
import { DraggableList } from "shared/components/draggableList/DraggableList";
import { BeforeCapture, DropResult } from "react-beautiful-dnd";
import { HolderOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./PanelVariables.module.scss";

export const PanelVariables = () => {
  const {
    variables: allVariables,
    compositions,
    isFetchingMediaCols,
    currentCompositionId,
    setCurrentCompositionId,
    setCompositions,
  } = useAssetBatchesContext();
  const [hoveredPanel, setHoveredPanel] = useState<string | undefined>();

  const getSortedVariables = useCallback(
    (composition: TComposition) => {
      const variables = allVariables.find(
        vars => vars.compositionId === composition.compositionId,
      )?.variables;
      if (!variables) return [];
      return [
        ...variables.filter(
          variable => variable.variable === "Theme Background",
        ),
        ...orderBy(
          variables,
          variable =>
            `${variable.type}-${variable.variable}-${variable.logoData?.logoType}`,
          ["asc"],
        ).filter(
          variable =>
            mediaTypes.includes(variable.type) &&
            variable.variable !== "Theme Background",
        ),
      ];
    },
    [allVariables],
  );

  const getSortedTextVariables = useCallback(
    (composition: TComposition) => {
      const variables = allVariables.find(
        vars => vars.compositionId === composition.compositionId,
      )?.variables;
      return orderBy(variables, variable => variable.variable, ["asc"]).filter(
        variable => variable.type === "text",
      );
    },
    [allVariables],
  );

  const customHeader = (composition: TComposition, isActive: boolean) => (
    <div
      className={styles.customPanelHeader}
      onMouseEnter={() => setHoveredPanel(composition.compositionId)}
      onMouseLeave={() => setHoveredPanel(undefined)}
    >
      {hoveredPanel === composition.compositionId && (
        <HolderOutlined className={styles.holderIcon} />
      )}
      <RightOutlined
        rotate={isActive ? 90 : 0}
        className={styles.rightArrow}
        width="10px"
        height="10px"
      />
      <VariableCollapseHeader composition={composition} />
    </div>
  );

  const createDisplayVariables = (compositions: TComposition[]) => {
    return compositions.map(composition => (
      <AssetBatchesCompositionProvider
        key={composition.compositionId}
        editingComposition={composition}
      >
        <Collapse
          activeKey={[currentCompositionId ?? ""]}
          onChange={() => {
            if (currentCompositionId === composition.compositionId) {
              setCurrentCompositionId(undefined);
            } else {
              setCurrentCompositionId(composition.compositionId);
            }
          }}
          bordered={false}
          className={styles.collapseContainer}
        >
          <Collapse.Panel
            key={composition.compositionId}
            header={customHeader(
              composition,
              currentCompositionId === composition.compositionId,
            )}
            showArrow={false}
          >
            {getSortedVariables(composition).map(variable =>
              variable.type === "logo" ? (
                <LogoVariableInput
                  key={`${composition.compositionId}-${variable.id}-${variable.variable}`}
                  variable={variable}
                  valueMapping={
                    composition.variables[getValueMappingKey(variable)]
                  }
                  mappingKey={getValueMappingKey(variable)}
                />
              ) : (
                <ImageVariableInput
                  key={`${composition.compositionId}-${variable.id}-${variable.variable}`}
                  compositionId={composition.compositionId}
                  variable={variable}
                  valueMapping={
                    composition.variables[getValueMappingKey(variable)]
                  }
                  mappingKey={getValueMappingKey(variable)}
                />
              ),
            )}

            {getSortedTextVariables(composition).map((variable, idx) => {
              const mappingKey = getValueMappingKey(variable);

              return (
                <TextVariableInput
                  key={`${composition.compositionId}-${variable.id}-${variable.variable}-${idx}`}
                  variable={variable}
                  valueMapping={composition.variables[mappingKey]}
                  mappingKey={mappingKey}
                />
              );
            })}
          </Collapse.Panel>
        </Collapse>
      </AssetBatchesCompositionProvider>
    ));
  };

  const handleOnBeforeCapture = (item: BeforeCapture) => {
    const { draggableId } = item;
    if (currentCompositionId !== draggableId) {
      setCurrentCompositionId(undefined);
    }
  };
  const handleDragEnd = (droppedItem: DropResult) => {
    setCurrentCompositionId(
      compositions[droppedItem?.destination?.index ?? 0].compositionId,
    );
  };

  return (
    <div className={styles.compVariablesContainer}>
      <div className={styles.dropdownContainer}>
        <AddMediaDropdown />
      </div>
      <div>
        {isFetchingMediaCols && <Spin />}
        {!isFetchingMediaCols && (
          <>
            <DraggableList
              listItems={createDisplayVariables(compositions)}
              originalListItems={compositions}
              updateOriginalListItems={setCompositions}
              handleOnBeforeCapture={handleOnBeforeCapture}
              handleDragEnd={handleDragEnd}
            />
          </>
        )}
      </div>
    </div>
  );
};
