import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { IAssetGroup, IAssetGroupDetail } from "shared/types/adLibrary";
import { IApiResponse } from "shared/types/shared";
import { IDataTableError } from "shared/types/errors";
import { useState, useCallback } from "react";
import {
  StatusResponse,
  UploadResults,
} from "screens/adLibrary/pmaxAssetGroups/shared/types";
import { useUpdateAssetGroup } from "./useUpdateAssetGroup";
import { message } from "antd";

const uploadAssetGroup = async (assetGroup: IAssetGroup) =>
  await API.services.adLibrary.uploadAssetGroup(assetGroup);

export const useUploadAssetGroup = () => useMutation(uploadAssetGroup);

const parseResponse = (
  assetGroup: IAssetGroup,
  response: IApiResponse<IAssetGroupDetail, IDataTableError>,
): StatusResponse => ({
  name: assetGroup.name,
  id: assetGroup.id,
  status: response.result ? "Loaded" : "Failed",
});

export const useUploadAssetGroups = () => {
  const queryClient = useQueryClient();
  const { mutateAsync, ...restMutationValues } = useUploadAssetGroup();
  const { mutate: updateAssetGroup } = useUpdateAssetGroup();
  const [results, setResults] = useState<UploadResults>({
    progress: 0,
    responses: [],
  });

  const upload = useCallback(
    async (assetGroups: IAssetGroup[]) => {
      const [assetGroup, ...rest] = assetGroups;
      try {
        const response = await mutateAsync(assetGroup);

        setResults(prev => ({
          progress: Math.round(
            ((prev.responses.length + 1) /
              (prev.responses.length + assetGroups.length)) *
              100,
          ),
          responses: [...prev.responses, parseResponse(assetGroup, response)],
        }));

        if (response.result) {
          updateAssetGroup({
            id: response.result.id,
            pmaxStatus: "Loaded",
            lastUpdatedAt: Date.now(),
          } satisfies Partial<IAssetGroup>);
        }
      } catch (error: any) {
        message.error(error?.message || "Something went wrong");
      } finally {
        if (rest.length) await upload(rest);
        else {
          queryClient.invalidateQueries("assetGroups");
        }
      }
    },
    [mutateAsync, queryClient, updateAssetGroup],
  );

  const initUpload = useCallback(
    (assetGroups: IAssetGroup[]) => {
      setResults({ progress: 0, responses: [] });
      upload(assetGroups);
    },
    [upload],
  );

  return {
    results,
    upload: initUpload,
    ...restMutationValues,
  };
};
