import { last } from "lodash";
import { appendUrlPrefix } from "./appendUrlPrefix";
import { fontThumbSrc } from "./images/fontThumbSrc";
import { isValidAssetTypeToPreview } from "./utils.filetype";

export const getCAMThumbnail = (asset: WDAsset, pos = 3) => {
  const { thumbnailurls = [] } = asset;
  const isValidAssetType = isValidAssetTypeToPreview(asset);
  const { url, ...rest } = thumbnailurls[pos] ?? last(thumbnailurls);

  return {
    ...rest,
    url: appendUrlPrefix(isValidAssetType ? url : fontThumbSrc),
  };
};
