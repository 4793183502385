import { IAdFields } from "screens/adLibrary/fields";
import { useDataListURLFilters } from "shared/components/dataListURL/useDataListURLFilters";
import { useDataListURLIds } from "shared/components/dataListURL/useDataListURLIds";
import { useDataListURLSorter } from "shared/components/dataListURL/useDataListURLSorter";
import { useDataListURLGlobalFilter } from "shared/components/dataListURL/useDataListURLGlobalFilter";
import { useDataListURLContext } from "shared/components/dataListURL/dataListURLContext";
import {
  Props,
  useDataListURLData,
} from "shared/components/dataListURL/useDataListURLData";
import {
  GoogleAdCampaignInternal,
  IAd,
  InstantExperienceTableEntry,
} from "shared/types/adLibrary";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { IAdReviewFields } from "screens/campaignManagement/fields";
import { IEverythingAdFields } from "screens/everythingAds/Ads/fields";
import { CampaignPlannerFieldKeys } from "screens/campaignManagement/campaignPlanner/campaignPlannerList/dataList/fields";
import { CampaignPlanner } from "screens/campaignManagement/campaignPlanner/types";
import { DesignStudioFields } from "screens/designStudio/context/fields";
import { DesignStudioTableItem } from "screens/designStudio/context/DesignStudioDataProvider";
import { getAllAdIds } from "shared/components/dataListURL/utils";
import { getAllRowKeys } from "screens/designStudio/helpers";
import { getPlannerIds } from "screens/campaignManagement/campaignPlanner/utils";
import { PmaxCampaignsFields } from "screens/campaignManagement/googlePerformanceMax/pmaxCampaignsTable/fields";
import { getAllPmaxCampaignIds } from "screens/campaignManagement/googlePerformanceMax/pmaxCampaignsTable/helpers";

// Ad Library
export const useAdLibraryContext = () =>
  useDataListURLContext<IAdFields, IAd>();

export const useAdLibraryData = (props: Props<IAd>) =>
  useDataListURLData<IAdFields, IAd>(props);

export const useAdLibraryFilters = () =>
  useDataListURLFilters<IAdFields, IAd>();

export const useAdLibrarySorter = () => useDataListURLSorter<IAdFields, IAd>();

export const useAdLibraryIds = () => useDataListURLIds<IAd>(getAllAdIds);

export const useAdLibraryGlobalFilter = () => useDataListURLGlobalFilter();

// Campaign Management
export const useCampaignManagementContext = () =>
  useDataListURLContext<IAdReviewFields, AdReviewTableEntry>();

export const useCampaignManagementData = (props: Props<AdReviewTableEntry>) =>
  useDataListURLData<IAdReviewFields, AdReviewTableEntry>(props);

export const useCampaignManagementFilters = () =>
  useDataListURLFilters<IAdReviewFields, AdReviewTableEntry>();

export const useCampaignManagementSorter = () =>
  useDataListURLSorter<IAdReviewFields, AdReviewTableEntry>();

export const useCampaignManagementIds = () =>
  useDataListURLIds<AdReviewTableEntry>(getAllAdIds);

export const useCampaignManagementGlobalFilter = () =>
  useDataListURLGlobalFilter();

// Everything Ads
export const useEverythingAdsContext = () =>
  useDataListURLContext<IEverythingAdFields, InstantExperienceTableEntry>();

export const useEverythingAdsData = (
  props: Props<InstantExperienceTableEntry>,
) =>
  useDataListURLData<IEverythingAdFields, InstantExperienceTableEntry>(props);

export const useEverythingAdsFilters = () =>
  useDataListURLFilters<IEverythingAdFields, InstantExperienceTableEntry>();

export const useEverythingAdsSorter = () =>
  useDataListURLSorter<IEverythingAdFields, InstantExperienceTableEntry>();

export const useEverythingAdsIds = () =>
  useDataListURLIds<InstantExperienceTableEntry>(getAllAdIds);

export const useEverythingAdsGlobalFilter = () => useDataListURLGlobalFilter();

// Campaign Planner

export const useCampaignPlannerContext = () =>
  useDataListURLContext<CampaignPlannerFieldKeys, CampaignPlanner>();

export const useCampaignPlannerData = (props: Props<CampaignPlanner>) =>
  useDataListURLData<CampaignPlannerFieldKeys, CampaignPlanner>(props);

export const useCampaignPlannerFilters = () =>
  useDataListURLFilters<
    CampaignPlannerFieldKeys,
    InstantExperienceTableEntry
  >();

export const useCampaignPlannerSorter = () =>
  useDataListURLSorter<CampaignPlannerFieldKeys, CampaignPlanner>();

export const useCampaignPlannerIds = () =>
  useDataListURLIds<CampaignPlanner>(getPlannerIds);

export const useCampaignPlannerGlobalFilter = () =>
  useDataListURLGlobalFilter();

// Design Studio
export const useDesignStudioDataURLContext = () =>
  useDataListURLContext<DesignStudioFields, DesignStudioTableItem>();

export const useDesignStudioFilters = () =>
  useDataListURLFilters<DesignStudioFields, DesignStudioTableItem>();

export const useDesignStudioSorter = () =>
  useDataListURLSorter<DesignStudioFields, DesignStudioTableItem>();

export const useDesignStudioIds = () =>
  useDataListURLIds<DesignStudioTableItem>(getAllRowKeys);

export const useDesignStudioGlobalFilter = () => useDataListURLGlobalFilter();

export const useDesignStudioURLData = (props: Props<DesignStudioTableItem>) =>
  useDataListURLData<DesignStudioFields, DesignStudioTableItem>(props);

// Performance Max Campaigns
export const usePmaxCampaignsFilters = () =>
  useDataListURLFilters<PmaxCampaignsFields, GoogleAdCampaignInternal>();

export const usePmaxCampaignsIds = () =>
  useDataListURLIds<GoogleAdCampaignInternal>(getAllPmaxCampaignIds);

export const usePmaxCampaignsGlobalFilter = () => useDataListURLGlobalFilter();

export const usePmaxCampaignsData = (props: Props<GoogleAdCampaignInternal>) =>
  useDataListURLData<PmaxCampaignsFields, GoogleAdCampaignInternal>(props);
