import {
  createContext,
  memo,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useDataList } from "shared/components/dataList";
import {
  ASSET_BATCH_SELECTION,
  AssetBatchDrawerMode,
  FeedTblCol,
  FeedTblRow,
  RadioBtnAssetBatchesVal,
  TAssetBatch,
} from "shared/types/assetExporter";
import useGetAssetBatches from "../hooks/useGetAssetBatches";

type ContextProviderProps = PropsWithChildren<{
  columns: FeedTblCol[];
  feedId: string;
  rows?: { [key: string]: string | number }[];
}>;

interface ContextProps extends Omit<ContextProviderProps, "children"> {
  loading: boolean;
  isFetching: boolean;
  assetBatches: TAssetBatch[];
  assetBatchesForSelectedRows: TAssetBatch[];
  selectedRows: string[];
  selectedAssetBatches: string[];
  exportOption: RadioBtnAssetBatchesVal;
  openAssetBatchDrawer: boolean;
  assetBatchDrawerMode: AssetBatchDrawerMode;
  editingAssetBatch?: TAssetBatch;
  isUpdated: boolean;
  onSelectAllAssetBatches: () => void;
  onSelectAssetBatches: (assetBatches: string[]) => void;
  clearContext: () => void;
  updateExportOption: (newOption: RadioBtnAssetBatchesVal) => void;
  setAssetBatchesForSelectedRows: (assetBatches: TAssetBatch[]) => void;
  setOpenAssetBatchDrawer: (open: boolean) => void;
  setAssetBatchDrawerMode: (mode: AssetBatchDrawerMode) => void;
  setEditingAssetBatch: (assetBatchId: TAssetBatch | undefined) => void;
  setIsUpdated: (isUpdated: boolean) => void;
}

const Context = createContext<ContextProps>({} as ContextProps);

const ContextProvider = ({
  children,
  columns,
  rows,
  feedId,
}: ContextProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [dataList] = useDataList<FeedTblRow>();
  const [exportOption, setExportOption] = useState<RadioBtnAssetBatchesVal>(
    ASSET_BATCH_SELECTION.EXPORT_ALL,
  );
  const [assetBatchesForSelectedRows, setAssetBatchesForSelectedRows] =
    useState<TAssetBatch[]>([]);

  const { data: assetBatches, isLoading: isFetching } =
    useGetAssetBatches(feedId);

  const [selectedAssetBatches, setSelectedAssetBatches] = useState<string[]>(
    [],
  );
  const [openAssetBatchDrawer, setOpenAssetBatchDrawer] = useState(false);
  const [assetBatchDrawerMode, setAssetBatchDrawerMode] =
    useState<AssetBatchDrawerMode>("Create");
  const [editingAssetBatch, setEditingAssetBatch] = useState<TAssetBatch>();
  const [isUpdated, setIsUpdated] = useState(false);

  const clearContext = useCallback(() => {}, []);

  const updateExportOption = useCallback(
    (newOption: RadioBtnAssetBatchesVal) => setExportOption(newOption),
    [setExportOption],
  );

  const onSelectAssetBatches = useCallback(
    (newAssetBatches: string[]) => setSelectedAssetBatches(newAssetBatches),
    [setSelectedAssetBatches],
  );

  const onSelectAllAssetBatches = useCallback(() => {
    if (assetBatchesForSelectedRows?.length) {
      onSelectAssetBatches(
        assetBatchesForSelectedRows
          ?.filter(asset => !!asset.assetBatchId)
          ?.map(asset => asset.assetBatchId as string) ?? [],
      );
    }
  }, [assetBatchesForSelectedRows, onSelectAssetBatches]);

  useEffect(() => {
    if (assetBatches?.length) {
      setSelectedAssetBatches(
        assetBatches
          ?.filter(asset => !!asset.assetBatchId)
          ?.map(asset => asset.assetBatchId as string) ?? [],
      );
    }

    return () => {
      setSelectedAssetBatches([]);
    };
  }, [assetBatches]);

  useEffect(() => {
    const contextMounted = () => {
      setLoading(false);
    };

    contextMounted();

    return () => {
      setLoading(true);
      setSelectedAssetBatches([]);
    };
  }, []);

  const providerValues = useMemo(
    () => ({
      loading,
      assetBatches: assetBatches?.filter(asset => !!asset.assetBatchId) ?? [],
      isFetching,
      selectedRows: dataList.selectedIds,
      selectedAssetBatches,
      onSelectAllAssetBatches,
      feedId,
      columns,
      rows,
      clearContext,
      onSelectAssetBatches,
      exportOption,
      updateExportOption,
      assetBatchesForSelectedRows,
      setAssetBatchesForSelectedRows,
      openAssetBatchDrawer,
      setOpenAssetBatchDrawer,
      assetBatchDrawerMode,
      setAssetBatchDrawerMode,
      editingAssetBatch,
      setEditingAssetBatch,
      isUpdated,
      setIsUpdated,
    }),
    [
      loading,
      assetBatches,
      isFetching,
      dataList.selectedIds,
      selectedAssetBatches,
      onSelectAllAssetBatches,
      feedId,
      columns,
      rows,
      clearContext,
      onSelectAssetBatches,
      exportOption,
      updateExportOption,
      assetBatchesForSelectedRows,
      setAssetBatchesForSelectedRows,
      openAssetBatchDrawer,
      setOpenAssetBatchDrawer,
      assetBatchDrawerMode,
      setAssetBatchDrawerMode,
      editingAssetBatch,
      setEditingAssetBatch,
      isUpdated,
      setIsUpdated,
    ],
  );

  return <Context.Provider value={providerValues}>{children}</Context.Provider>;
};

export const FeedProvider = memo(ContextProvider);

export const useFeedContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Feed Context must be used within a Feed ContextProvider");
  }

  return context;
};
