import { useMediaActions } from "redux/media/media.slice";
import { Ellipsis } from "../../Ellipsis";
import { getCAMThumbnail } from "../../getCAMThumbnail";
import { useFileTypes } from "../../useFileTypes";
import { useSelected } from "../../useSelected";
import { EditableCell } from "../EditableCell";

import styles from "./Asset.module.scss";

type Props = {
  resource: WDAsset;
};

export const Asset = ({ resource }: Props) => {
  const { toggleSelected } = useSelected();
  const { setAssetsToEdit } = useMediaActions();
  const { url } = getCAMThumbnail(resource);
  const { canSelect } = useFileTypes();

  return (
    <div className={styles.host}>
      <EditableCell
        editable
        title="Name"
        element="div"
        dataIndex="name"
        record={resource}
      >
        <div
          className={styles.name}
          onClick={() => setAssetsToEdit([resource])}
        >
          <Ellipsis>{resource.name}</Ellipsis>
        </div>
      </EditableCell>
      <div
        className={styles.image}
        data-selectable={canSelect(resource)}
        onClick={() => toggleSelected(resource)}
      >
        <img src={url} alt={resource.name} />
      </div>
    </div>
  );
};
