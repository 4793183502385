import { UploadFile } from "antd/lib/upload/interface";
import { ImageType, ILogos } from "shared/types/brandsAccounts";
import { IFile } from "shared/types/uploadAsset";

export const getUploadAssetList = (
  logos?: Partial<ILogos>,
): Array<{
  title: ImageType;
  fileList: UploadFile<IFile>[];
  maxLength: number;
  key: keyof ILogos;
}> => [
  {
    title: "Horizontal",
    fileList: logos?.horizontalLogos || [],
    maxLength: 5,
    key: "horizontalLogos",
  },
  {
    title: "Vertical",
    fileList: logos?.verticalLogos || [],
    maxLength: 5,
    key: "verticalLogos",
  },
  {
    title: "Square",
    fileList: logos?.squareLogos || [],
    maxLength: 5,
    key: "squareLogos",
  },
];
