import {
  DeleteOutlined,
  DownloadOutlined,
  FolderOutlined,
} from "@ant-design/icons";
import { AdvancedImage, lazyload, placeholder } from "@cloudinary/react";
import { Cloudinary } from "@cloudinary/url-gen";
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
import { pad } from "@cloudinary/url-gen/actions/resize";
import { generativeFill } from "@cloudinary/url-gen/qualifiers/background";
import { Button, Checkbox, Input, Progress, Typography, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import FileUploadDragger from "screens/adLibrary/adWizard/shared/FileUploadDragger";
import { CAMUpload, CAM_ENABLED } from "shared/components/media";
import useResizeImage from "shared/hooks/genAI/useResizeImage";
import { AiResizeIcon } from "shared/icons/AiResizeIcon";
import { IUploadMediaUrls } from "utils/uploadMedia";
import ToolPicker from "../components/ToolPicker";
import { defaultResizeDimensions } from "../utils/constants";
import {
  handleDownloadAllFromUrl,
  handleDownloadFromUrl,
} from "../utils/functions/imageInfoUtils";
import styles from "./Resize.module.scss";

type Dimension = {
  width: number;
  height: number;
  label: string;
  selected: boolean;
  id: number;
  transformedS3Url?: string;
};

const cld = new Cloudinary({
  cloud: {
    cloudName: "const",
  },
});

const Resize = () => {
  const [dimensions, setDimensions] = useState<Dimension[]>(
    defaultResizeDimensions,
  );
  const [progress, setProgress] = useState(0);
  const [displayCustomDimensions, setDisplayCustomDimensions] = useState(false);
  const [customDimensions, setCustomDimensions] = useState<Dimension[]>([]);

  const [fileUrls, setFileUrls] = useState<IUploadMediaUrls>({
    thumbnail: "",
    videoUrl: "",
    assetId: "",
  });

  const { data, isFetching, handleResizeImage, cancel } = useResizeImage({
    url: fileUrls.thumbnail || "",
  });

  const [isResizing, setIsResizing] = useState(
    isFetching || (progress < 100 && progress > 0),
  );

  useEffect(() => {
    setIsResizing(isFetching || (progress < 100 && progress > 0));
  }, [isFetching, progress]);

  const handleResize = async () => {
    if (isResizing) {
      setProgress(100);
      setIsResizing(false);
      cancel();
      return;
    }

    if (fileUrls.thumbnail) {
      handleResizeImage();
      for (let i = 0; i <= 100; i += 5) {
        setProgress(i);
        await new Promise(resolve => setTimeout(resolve, 800));
      }
      setIsResizing(false);
    }
  };

  const handleSelectDimension = (id: number) => {
    setDimensions(prevDimensions =>
      prevDimensions.map(dimension =>
        dimension.id === id
          ? { ...dimension, selected: !dimension.selected }
          : dimension,
      ),
    );
  };

  const handleAddCustomDimension = () => {
    setCustomDimensions(prev => [
      ...prev,
      {
        width: 0,
        height: 0,
        label: "Custom",
        id: prev.length + dimensions.length,
        selected: true,
      },
    ]);
  };

  const handleDeleteDimension = (id: number) => {
    setCustomDimensions(prev => prev.filter(dimension => dimension.id !== id));
  };

  const handleEditCustomDimension = (
    id: number,
    width?: number,
    height?: number,
  ) => {
    setCustomDimensions(prev =>
      prev.map(dimension =>
        dimension.id === id
          ? {
              ...dimension,
              width: width || dimension.width,
              height: height || dimension.height,
            }
          : dimension,
      ),
    );
  };

  const urls = useMemo(() => {
    return [...dimensions, ...customDimensions]
      .filter(
        dimension =>
          dimension.selected === true && dimension.height && dimension.width,
      )
      .map(dimension => {
        const myImage = cld.image(data?.publicId || "");
        myImage
          .resize(
            pad()
              .width(dimension.width)
              .height(dimension.height)
              .background(generativeFill()),
          )
          .delivery(defaultImage("default.png"));

        return myImage.toURL();
      });
  }, [dimensions, customDimensions, data]);

  const handleDownloadAll = () => {
    handleDownloadAllFromUrl(urls);
  };

  const dimensionsToUse = displayCustomDimensions
    ? [...dimensions, ...customDimensions]
    : dimensions;

  return (
    <div className={styles.resizeContainer}>
      <div className={styles.leftPanel}>
        <ToolPicker />
        <div className={styles.inputSectionContainer}>
          <div>
            <Typography.Title level={5}>Resize image</Typography.Title>
            <Typography.Text>Upload your image to resize it</Typography.Text>
          </div>
          <FileUploadDragger
            fileUrls={fileUrls}
            handleChangeFile={setFileUrls}
            placeholder="Upload"
          />
          <Checkbox.Group className={styles.dimensionList}>
            <Typography.Text className={styles.infoText}>
              Desired dimension(s)
            </Typography.Text>
            {dimensions.map(dimension => (
              <div key={dimension.label} className={styles.dimensionListItem}>
                <Checkbox
                  value={dimension.id}
                  checked={dimension.selected}
                  onChange={() => handleSelectDimension(dimension.id)}
                >
                  <Typography.Text>
                    {dimension.label} - {dimension.width}x{dimension.height}
                  </Typography.Text>
                </Checkbox>
              </div>
            ))}
            <Checkbox
              value="custom"
              checked={displayCustomDimensions}
              onChange={() => {
                setDisplayCustomDimensions(prev => !prev);
                setCustomDimensions(prev =>
                  prev.length > 0
                    ? prev
                    : [
                        {
                          id: 4,
                          width: 0,
                          height: 0,
                          label: "Custom",
                          selected: true,
                        },
                      ],
                );
              }}
            >
              Custom
            </Checkbox>
          </Checkbox.Group>
          {displayCustomDimensions && (
            <div className={styles.customDimensionContainer}>
              {customDimensions.map(dimension => (
                <div
                  key={dimension.id}
                  className={styles.customDimensionInputRow}
                >
                  <div className={styles.customDimensionInput}>
                    <Typography.Text>W</Typography.Text>
                    <Input
                      onChange={e =>
                        handleEditCustomDimension(
                          dimension.id,
                          parseInt(e.target.value),
                        )
                      }
                      value={dimension.width || 0}
                    />
                  </div>
                  <div className={styles.customDimensionInput}>
                    <Typography.Text>H</Typography.Text>
                    <Input
                      onChange={e =>
                        handleEditCustomDimension(
                          dimension.id,
                          undefined,
                          parseInt(e.target.value),
                        )
                      }
                      value={dimension.height || 0}
                    />
                  </div>
                  {customDimensions.length > 0 && (
                    <Button
                      icon={<DeleteOutlined />}
                      type="ghost"
                      onClick={() => handleDeleteDimension(dimension.id)}
                      className={styles.deleteButton}
                    />
                  )}
                </div>
              ))}
              <Button
                onClick={handleAddCustomDimension}
                className={styles.addCustomDimension}
                type="link"
              >
                + Add custom dimension
              </Button>
            </div>
          )}
          {isResizing && <Progress percent={progress} showInfo={false} />}
          <Button
            type="primary"
            onClick={handleResize}
            disabled={!fileUrls.thumbnail}
          >
            {isResizing
              ? "Cancel"
              : data && data.url
              ? "Resize Again"
              : "Resize"}
          </Button>
        </div>
      </div>
      <div className={styles.resultsContainer}>
        <div className={styles.buttonsRow}>
          <Button onClick={handleDownloadAll}>
            <DownloadOutlined />
            Download All
          </Button>
          {CAM_ENABLED && (
            <CAMUpload
              urls={urls}
              onComplete={() => {
                message.success("All images saved");
              }}
            >
              <Button type="primary">
                <FolderOutlined /> Save All
              </Button>
            </CAMUpload>
          )}
        </div>
        {data?.publicId && !isFetching && progress === 100 ? (
          dimensionsToUse
            .filter(
              dimension =>
                dimension.selected === true &&
                dimension.height &&
                dimension.width,
            )
            .map(dimension => {
              const myImage = cld.image(data?.publicId || "");
              myImage
                .resize(
                  pad()
                    .width(dimension.width)
                    .height(dimension.height)
                    .background(generativeFill()),
                )
                .delivery(defaultImage("default.png"));

              const imageWidth = (dimension.width / 3) * 2;
              const imageHeight = (dimension.height / 3) * 2;

              const imageUrl = myImage.toURL();

              return (
                <div key={dimension.id} className={styles.image}>
                  <Typography.Text>
                    {dimension.label} - {dimension.width} x {dimension.height}{" "}
                    <Button
                      icon={<DownloadOutlined />}
                      type="ghost"
                      onClick={() => handleDownloadFromUrl(imageUrl)}
                      className={styles.button}
                    />
                    {CAM_ENABLED && (
                      <CAMUpload
                        urls={[imageUrl]}
                        onComplete={() => {
                          message.success("Image saved");
                        }}
                      >
                        <Button
                          icon={<FolderOutlined />}
                          type="ghost"
                          className={styles.button}
                        />
                      </CAMUpload>
                    )}
                  </Typography.Text>
                  <AdvancedImage
                    cldImg={myImage}
                    plugins={[placeholder({ mode: "blur" }), lazyload()]}
                    style={{
                      width: `${imageWidth}px`,
                      height: `${imageHeight}px`,
                    }}
                  />
                </div>
              );
            })
        ) : (
          <div className={styles.emptyState}>
            <AiResizeIcon height={100} width={100} color="#1890ff" />
            Resizing images made simpler and smarter
          </div>
        )}
      </div>
    </div>
  );
};

export default Resize;
