import { Steps } from "antd";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import {
  CREATE_BATCH_TITLES,
  CREATE_BATCH_TITLES_V2,
  STEP_CREATE_BATCH,
  STEP_CREATE_BATCH_V2,
} from "shared/types/assetExporter";
import { useAssetBatchesContext } from "../shared/contexts/AssetBatchesContext";
import { useFeedContext } from "../shared/contexts/FeedContext";
import styles from "./StepsConfig.module.scss";

const { Step } = Steps;

type AssetBatchStepsProps = {
  step: number;
  maxStep: number;
  onChangeStep: (step: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2) => void;
  selectedItems?: number;
};

export const AssetBatchSteps = ({
  step,
  selectedItems,
  onChangeStep,
}: AssetBatchStepsProps) => {
  const { getStepStatus, getStepsDisabled, maxStep } = useAssetBatchesContext();
  const { assetBatchDrawerMode } = useFeedContext();
  const titles = !videoCompositionEnabled
    ? CREATE_BATCH_TITLES
    : CREATE_BATCH_TITLES_V2;

  return (
    <Steps
      direction="vertical"
      size="small"
      current={step}
      className={styles.steps}
    >
      {titles.map((currStep, idx) => {
        const status = getStepStatus(step, idx);
        const isDisabled =
          assetBatchDrawerMode === "Create"
            ? getStepsDisabled(idx) || idx > maxStep
            : getStepsDisabled(idx);

        return (
          <Step
            key={currStep}
            title={currStep}
            description={
              currStep === CREATE_BATCH_TITLES[0] && selectedItems
                ? `${selectedItems} matching rows`
                : undefined
            }
            onClick={() => !isDisabled && onChangeStep(idx)}
            disabled={isDisabled}
            status={status}
          />
        );
      })}
    </Steps>
  );
};
