import { isEmpty } from "lodash";
import { IDataListURLFields } from "./types";
import { RecordWithId } from "./useDataListURLData";
import { nonNullable } from "utils/helpers.array";

export const normalizeArray = (
  value: string | (string | null)[] | null | undefined,
): string[] => {
  if (Array.isArray(value)) {
    return value.filter(nonNullable);
  }

  if (value) {
    return [value];
  }

  return [];
};

export const getEntries = <RecordType extends Record<string, any>>(
  entries: RecordType,
) => {
  return Object.entries(entries) as [
    keyof RecordType,
    RecordType[keyof RecordType],
  ][];
};

export const fromEntries = <Key extends string, RecordType>(
  entries: [Key, RecordType][],
) => {
  return Object.fromEntries(entries) as Record<Key, RecordType>;
};

type GetFiltersPair<FieldKey extends string, RecordType> = [
  Record<FieldKey, unknown>[FieldKey],
  (value: any, record: RecordType) => boolean,
];

export const getFilters = <FieldKey extends string, RecordType>(
  fieldEntries: [
    FieldKey,
    IDataListURLFields<FieldKey, RecordType>[FieldKey],
  ][],
  filters: Record<FieldKey, unknown>,
) => {
  return fieldEntries
    .map<GetFiltersPair<FieldKey, RecordType> | undefined>(([key, field]) => {
      const filterValue = filters[key];

      if (!filterValue || isEmpty(filterValue) || !field?.filterFn)
        return undefined;

      return [filterValue, field.filterFn];
    })
    .filter(nonNullable);
};

export const getSelectedItems = <RecordType extends RecordWithId>(
  data: RecordType[] | undefined,
  selectedIds: string[],
) => {
  if (!data) return [];

  return data.filter(record => {
    if (record.children?.length) {
      return record.children.some(child =>
        selectedIds.includes(child.id || ""),
      );
    } else {
      return selectedIds.includes(record.id);
    }
  });
};

export const isColumnKeyInFields = <FieldKey extends string>(
  columnKey: React.Key | undefined,
  fields: FieldKey[],
): columnKey is FieldKey => {
  return columnKey !== undefined && fields.includes(columnKey as FieldKey);
};

export const getAllAdIds = <RecordType extends RecordWithId>(
  data: RecordType[],
) => {
  return data.map(({ id }) => id);
};
