import { Button, Col, Drawer, Row, Space } from "antd";
import { isEqual } from "lodash";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useFetchTemplates } from "shared/hooks/useFetchTemplates";
import {
  FeedTblRow,
  ICondition,
  IMedia,
  STEP_CREATE_BATCH,
  STEP_CREATE_BATCH_V2,
  TAssetBatch,
} from "shared/types/assetExporter";
import styles from "./AssetBatchDrawer.module.scss";
import { ApplyCondition } from "./assetBatchDrawer/ApplyCondition";
import { DynamicText } from "./assetBatchDrawer/DynamicText";
import { ManageMedia } from "./assetBatchDrawer/ManageMedia";
import { AssetBatchSteps } from "./assetBatchDrawer/StepsConfig";
import { useAssetBatchesContext } from "./shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "./shared/contexts/AssetBatchesValueMappingContext";
import {
  getMediaArray,
  parseConditions,
} from "./shared/contexts/AssetBatchesContext.utils";
import { useFeedContext } from "./shared/contexts/FeedContext";
import { StringParam, useQueryParams } from "use-query-params";

interface AssetBatchDrawerProps {
  onSubmit: (assetBatch: TAssetBatch) => void;
  onClose: (showUndoMessage: boolean, batchName: string) => void;
  setFilteredRows: Dispatch<SetStateAction<FeedTblRow[]>>;
}

export const AssetBatchDrawer = ({
  onSubmit,
  onClose,
  setFilteredRows,
}: AssetBatchDrawerProps) => {
  const {
    feedId,
    clearContext,
    batchName,
    previewCount,
    templatesToUse: templates,
    ruleConditions,
    compositions,
    backgroundMedias,
    isAllValid,
    isFirstStepValid,
    isSecondStepValid,
    maxStep,
    currentStep,
    updateMaxStep,
    updateCurrentStep,
    useEditingAssetBatchEffect,
    setTemplates,
  } = useAssetBatchesContext();

  const { useTemplateEffect } = useAssetBatchesValueMappingContext();

  const {
    editingAssetBatch,
    openAssetBatchDrawer: open,
    assetBatchDrawerMode: mode,
    setOpenAssetBatchDrawer,
    setEditingAssetBatch,
    assetBatches,
  } = useFeedContext();

  const [{ assetId }] = useQueryParams({
    assetId: StringParam,
  });

  const assetBatch = useMemo(() => {
    return assetBatches.find(assetB => assetB.assetBatchId === assetId);
  }, [assetBatches, assetId]);

  useEditingAssetBatchEffect(editingAssetBatch);
  useTemplateEffect(editingAssetBatch, setTemplates);

  useEffect(() => {
    if (assetBatch) {
      setEditingAssetBatch(assetBatch);
      setOpenAssetBatchDrawer(true);
    }
  }, [assetBatch, setEditingAssetBatch, setOpenAssetBatchDrawer]);

  useFetchTemplates({ enabled: open });

  const isLastStep = () => currentStep === STEP_CREATE_BATCH.THREE_TEXT;

  const handleClose = (isSave?: boolean) => {
    clearContext?.();
    onClose(isSave ?? false, batchName ?? "");
  };

  const handleChangeStep = (step: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2) => {
    updateCurrentStep(step);
  };

  const handlePrevStep = () => {
    if (currentStep === STEP_CREATE_BATCH.ONE_CONDITION) {
      return;
    }

    updateCurrentStep(currentStep - 1);
  };

  const handleNextStep = (isSave?: boolean) => {
    if (currentStep + 1 > maxStep) updateMaxStep(currentStep + 1);
    if (!isLastStep() && !isSave) {
      updateCurrentStep(currentStep + 1);
      return;
    }

    updateCurrentStep(STEP_CREATE_BATCH.ONE_CONDITION);

    if (
      !batchName ||
      !compositions?.length ||
      !templates.every(template => !!template.id)
    ) {
      return;
    }

    const conditions: ICondition[] = ruleConditions.map((condition, index) => ({
      type: index === 0 ? "if" : `${condition.operator}-if`,
      leftEq: {
        text: condition.columnName,
        value: condition.columnName,
      },
      op: condition.comparisonOperator,
      rightEq: condition.value,
    }));

    const media: IMedia[] = getMediaArray(
      backgroundMedias?.[Object.keys(backgroundMedias)[0]],
    );

    const assetBatch: TAssetBatch = {
      feedId: feedId,
      assetBatchId: isSave ? editingAssetBatch?.assetBatchId : undefined,
      name: batchName,
      compositions,
      conditions,
      media,
      template: compositions[0].template,
      variables: compositions[0].variables,
    };

    onSubmit(assetBatch);
    handleClose(isSave ?? false);
  };

  const edited = () => {
    if (!editingAssetBatch) return false;

    const areSameNames = editingAssetBatch?.name === batchName;
    const areSameConditions = isEqual(
      parseConditions(editingAssetBatch.conditions),
      ruleConditions,
    );

    const areSameVariables = compositions.every(composition =>
      isEqual(
        composition.variables,
        editingAssetBatch?.compositions.find(
          com => com.compositionId === composition.compositionId,
        )?.variables,
      ),
    );

    if (areSameNames && areSameConditions && areSameVariables) {
      return false;
    }

    return true;
  };

  const disabledSaveForFirstStep = !isFirstStepValid || !edited();
  const disabledSaveForSecondStep = !isSecondStepValid || !edited();
  const disabledSaveForLastStep = !isAllValid || !edited();

  const isFirstStep = currentStep === STEP_CREATE_BATCH.ONE_CONDITION;
  const isSecondStep = currentStep === STEP_CREATE_BATCH.TWO_MEDIA;
  return (
    <Drawer
      className={styles.drawer}
      title={
        <div className={styles.drawerHeader}>
          <strong className={styles.batchNameTitle}>{mode} asset batch</strong>
        </div>
      }
      placement="right"
      visible={open}
      width="calc(100vw - 48px)"
      bodyStyle={{ paddingBottom: 0 }}
      footer={
        <Space className={styles.drawerFooter}>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <div>
            {!isFirstStep && (
              <Button onClick={handlePrevStep} className={styles.backButton}>
                Back
              </Button>
            )}
            {mode === "Create" && (
              <Button
                type="primary"
                onClick={() => handleNextStep()}
                data-cy="feed-next-btn"
                disabled={
                  (isFirstStep && !isFirstStepValid) ||
                  (isSecondStep && !isSecondStepValid) ||
                  (isLastStep() && !isAllValid)
                }
              >
                {isLastStep() ? "Save" : "Next"}
              </Button>
            )}
            {mode === "Edit" && (
              <>
                {!isLastStep() && (
                  <Button
                    onClick={() => handleNextStep()}
                    className={styles.editNextButton}
                    disabled={
                      (isFirstStep && !isFirstStepValid) ||
                      (isSecondStep && !isSecondStepValid)
                    }
                  >
                    Next
                  </Button>
                )}

                <Button
                  type="primary"
                  onClick={() => handleNextStep(true)}
                  disabled={
                    (isFirstStep && disabledSaveForFirstStep) ||
                    (isSecondStep && disabledSaveForSecondStep) ||
                    (isLastStep() && disabledSaveForLastStep)
                  }
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </Space>
      }
      onClose={() => handleClose()}
      closable={false}
    >
      <Row className={styles.bodyContainer}>
        <Col span={3} className={styles.borderLine}>
          <AssetBatchSteps
            step={currentStep}
            maxStep={maxStep}
            onChangeStep={handleChangeStep}
            selectedItems={previewCount}
          />
        </Col>
        <Col span={21}>
          <div className={styles.stepsContainer}>
            {currentStep === STEP_CREATE_BATCH.ONE_CONDITION && (
              <ApplyCondition setFilteredRows={setFilteredRows} />
            )}
            {currentStep === STEP_CREATE_BATCH.TWO_MEDIA && <ManageMedia />}
            {currentStep === STEP_CREATE_BATCH.THREE_TEXT && <DynamicText />}
          </div>
        </Col>
      </Row>
    </Drawer>
  );
};
