/* eslint-disable react/display-name */
import { Space, Typography } from "antd";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { formatDateValue } from "utils/helpers";
import {
  AdType,
  FacebookAdEffectiveStatus,
  IFacebookAd,
} from "screens/adLibrary/facebookUtils/types";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { QCStatus } from "shared/types/shared";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import {
  isMultipleUrls,
  shouldShowDestinationUrl,
} from "../shared/utils/helpers.ad";
import QCStatusSelect from "../shared/components/QCStatusSelect";

import styles from "../AdTableListContainer.module.scss";
import DeliveryStatusBadge from "../shared/components/DeliveryStatusBadge";
import { uniq } from "lodash";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { IAdReviewFields } from "screens/campaignManagement/fields";

export type CampaignTableProps = ITableColumnURL<
  IAdReviewFields,
  AdReviewTableEntry
> & {
  hidden?: boolean;
};

const { Link } = Typography;

interface IProps {
  isAdmin: boolean;
  facebookAds: IFacebookAd[];
  setDestinationUrls: (destinationUrls: (string | undefined)[]) => void;
  setSelectedEntryId: (id: string) => void;
  setShowDetails: (setShowDetails: boolean) => void;
}

export const deliveryStatusLabelDic: Partial<
  Record<FacebookAdEffectiveStatus, string>
> = {
  ACTIVE: "Active",
  PAUSED: "Off",
  DISAPPROVED: "Rejected",
  CAMPAIGN_PAUSED: "Off",
  ADSET_PAUSED: "Off",
  WITH_ISSUES: "Rejected",
  PENDING_REVIEW: "Pending",
};

export const deliveryStatusOptions = uniq(
  Object.values(deliveryStatusLabelDic),
).map(value => ({
  text: value,
  value: value,
}));

export const getColumns = ({
  isAdmin,
  facebookAds,
  setDestinationUrls,
  setSelectedEntryId,
  setShowDetails,
}: IProps) => {
  const columns: CampaignTableProps[] = [
    {
      width: 450,
      fixed: "left",
      dataIndex: "name",
      title: "Name of Ad",
      key: "adName",
      render: (name: string, record) => (
        <span className={!record.children ? styles.childRowSpan : undefined}>
          {record.children ? (
            <Link
              onClick={() => {
                setSelectedEntryId(record.id);
                setShowDetails(true);
              }}
            >
              {name}
            </Link>
          ) : (
            name
          )}
        </span>
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 130,
      fixed: "left",
      title: "QC Status",
      dataIndex: "status",
      key: "status",
      render: (_status: QCStatus, record: AdReviewTableEntry) => {
        return <QCStatusSelect record={record} readonly={!record.children} />;
      },
      filters: Object.values(QCStatus)
        .filter(Boolean)
        .map(status => {
          return { text: status, value: status };
        }),
      hidden: !isAdmin,
    },
    {
      width: 130,
      title: "Created Date",
      dataIndex: "createdTime",
      key: "createdTime",
      render: (date: string) => formatDateValue(date),
    },
    {
      width: 130,
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (date: string) => formatDateValue(date),
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 130,
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (date: string) => formatDateValue(date),
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
    {
      width: 130,
      title: "Delivery",
      dataIndex: "effectiveStatus",
      key: "effectiveStatus",
      render: (status: FacebookAdEffectiveStatus) => (
        <DeliveryStatusBadge status={status} />
      ),
      filters: deliveryStatusOptions,
    },
    {
      width: 140,
      title: "Ad Format",
      dataIndex: "adFormat",
      key: "adFormat",
      render: (adFormat: string) =>
        [AdType.InstantExperience].includes(adFormat as AdType)
          ? "Instant Experience"
          : adFormat || "",
      filters: Object.values(AdType).map(adType => {
        [].indexOf;
        return {
          text: adType === "InstantExperience" ? "Instant Experience" : adType,
          value: adType,
        };
      }),
    },
    {
      width: 300,
      title: "Destination URL",
      dataIndex: "destinationUrl",
      key: "destinationUrl",
      render: (text, record) => {
        if (!shouldShowDestinationUrl(record)) {
          return null;
        }
        const count = text.split("\n").length;
        const ids = record.id.split("_");
        const [selectedFacebookAd] = facebookAds.filter(
          ({ id }) => !!id && ids.includes(id),
        );
        const url =
          text ||
          selectedFacebookAd?.creative?.asset_feed_spec?.link_urls?.[0]
            ?.website_url;
        return (
          <Space direction="horizontal" align="center">
            <Typography.Paragraph
              ellipsis={{ rows: 2, tooltip: true }}
              style={{ margin: 0, cursor: "pointer" }}
            >
              <Link
                href={isMultipleUrls(text) ? "#" : text}
                onClick={e => {
                  if (isMultipleUrls(text)) {
                    setDestinationUrls(text.split("\n"));
                  } else {
                    e.preventDefault();
                    window.open(text, "_blank");
                  }
                }}
              >
                {isMultipleUrls(text) ? `Multiple (${count})` : url}
              </Link>
            </Typography.Paragraph>
          </Space>
        );
      },
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
    },
  ];

  return columns;
};
