import { message } from "antd";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import TabContainer from "shared/components/TabContainer";
import {
  IAssetBuilderState,
  ISavedOrderState,
  MetaFeedMapping,
  OfferData,
  OfferRebateKeys,
  SingletonOfferKeys,
  Tab,
  TGetOfferListResult,
} from "shared/types/assetBuilder";
import OfferFilterV2 from "./select/OfferFilterV2";
import { TCellOfferKey } from "./select/offerListSection/offerCollapse/offerTypeCollapse/row/Cell";
import styles from "./Select.module.scss";
import { OfferType } from "shared/types/shared";
import useOfferList, { TOfferListSection } from "./select.hooks/useOfferList";
import OfferListSection from "./select/OfferListSection";
import { useNavigate } from "react-router-dom";
import { FeedType } from "shared/types/configuration";
import API from "services";
import FeedSelector from "./select/FeedSelector";
import useTabs from "./select.hooks/useTabs";
import TabDeleteButton from "./select/TabDeleteButton";
import { memo } from "react";
import { isEmpty, isEqual } from "lodash";
import { useCallback } from "react";
import { shallowEqual } from "react-redux";
import { KeyValues } from "services/assetBuilder";
import { useAppSelector } from "shared/hooks/useAppSelector";

export type TOfferCollapseData = {
  offerData: Partial<OfferData>;
  offerTypes: OfferType[];
  lastUpdated?: number;
  flagged?: boolean;
  warned?: boolean;
  editedKeys?: (SingletonOfferKeys | OfferRebateKeys)[];

  // { <offer key>: <original feed value> }
  editedPairs?: Record<TCellOfferKey, string>;
};
export type TMovingOfferData = {
  sourceDataKey: string;
  section: TOfferListSection;
  vin: string;
  offerTypes: OfferType[];
  feedId: string;
};
interface Props {
  savedOrder: IAssetBuilderState["savedOrder"];
  feed: FeedType;
  searchBy: string;
  addingMode: boolean;
}

interface Handlers {
  setAddingMode: Dispatch<SetStateAction<boolean>>;
}

const SelectV2 = (props: Props & Handlers) => {
  const [duplicatedOfferData, setDuplicatedOfferData] =
    useState<Partial<OfferData>>();
  const [movingOfferData, setMovingOfferData] = useState<TMovingOfferData>();

  const { orderId } = props.savedOrder || {};
  const {
    currentTab,
    onTabAdd,
    onTabChange,
    onTabDelete,
    selectedTabs,
    availableTabs,
    loading,
  } = useTabs({
    orderId,
  });

  const [data, setData] = useState<Record<string, TGetOfferListResult>>({});

  const { savedOrder, addingMode, setAddingMode } = props;
  const { meta } = savedOrder || {};
  const feedMappings = useRef<Array<MetaFeedMapping>>(meta?.feedMappings || []);
  const onOfferMoved = useCallback(
    async (
      offerListData: {
        selected?: TOfferCollapseData[];
        available?: TOfferCollapseData[];
      },
      movingOfferData: TMovingOfferData,
      cb?: () => void,
    ) => {
      if (!savedOrder) return;

      const { meta: existingMeta } = savedOrder;
      const filteredFeedMappings = feedMappings.current.filter(
        item => item.vin !== movingOfferData.vin,
      );
      const updatedFeedMappings =
        movingOfferData.section === "selected"
          ? [
              ...filteredFeedMappings,
              {
                vin: movingOfferData.vin,
                feedId: movingOfferData.feedId,
              },
            ]
          : filteredFeedMappings;

      const meta: ISavedOrderState["meta"] = {
        ...(existingMeta || {}),
        ...(updatedFeedMappings.length > 0
          ? {
              feedMappings: updatedFeedMappings,
            }
          : {}),
      };

      feedMappings.current = updatedFeedMappings;

      const updatedSavedOrder: ISavedOrderState = {
        ...savedOrder,
        selectedOffers:
          offerListData.selected?.map(listData => ({
            offerData: listData.offerData as OfferData,
            offers: listData.offerTypes as OfferType[],
          })) || null,
        meta,
      };

      await API.services.assetBuilder.updateOrder(updatedSavedOrder);

      setMovingOfferData(undefined); // reset otherwise, same offer will be repeatedly added

      cb?.();
    },
    [savedOrder, setMovingOfferData, feedMappings],
  );
  // This one is to organize fetched offer list into correct section and perform data transformation if needed.
  const { offerListData, isUpdating, counts, editedVins } = useOfferList({
    offerListResults: data,
    savedOrder: props.savedOrder,
    movingOfferData,
    currentTab,
    onOfferMoved,
  });

  const moveOfferTo =
    (currentTab?: Tab) =>
    (section: TOfferListSection, vin: string, offerTypes: OfferType[]) => {
      if (!currentTab) return;
      const sourceDataKey = `${
        section === "available" ? "selected" : "available"
      }-${currentTab.id}`;
      // NOTE: the API call and updating "selected" and "available" sections will be done in one of the useEffect in "useOfferList" custom hook
      setMovingOfferData({
        sourceDataKey,
        section,
        vin,
        offerTypes,
        feedId: currentTab.id,
      });
    };

  // NOTE: undefined feedId means default feed
  const onDataFetchComplete = useCallback(
    (key: string, result: TGetOfferListResult, reset: boolean) => {
      setData(prev => {
        return {
          ...prev,
          [key]: {
            ...(prev[key] || {}),
            ...result,
            offerList:
              reset || !prev[key]
                ? result.offerList
                : prev[key].offerList.concat(result.offerList),
          },
        };
      });
    },
    [],
  );

  const { offerData, editedKeys } = useAppSelector(
    ({ assetBuilder }: { assetBuilder: IAssetBuilderState }) => ({
      offerData: assetBuilder.offerData,
      editedKeys: assetBuilder.editedKeys,
    }),
    shallowEqual,
  );
  const bulkUpdateTimoutRef = useRef<NodeJS.Timeout>();
  const updateOfferFieldsDS = useCallback(() => {
    if (isEmpty(editedKeys) || !offerData) return;

    if (bulkUpdateTimoutRef.current) clearTimeout(bulkUpdateTimoutRef.current);

    bulkUpdateTimoutRef.current = setTimeout(() => {
      const keysToUpdate = Object.entries(editedKeys)
        .filter(([, value]) => value)
        .map(([key]) => key);

      const keyValuesToUpdate = keysToUpdate.reduce<KeyValues>((acc, key) => {
        if (!offerData || !offerData[key as keyof OfferData]) return acc;

        return {
          ...acc,
          [key]: {
            value: offerData[key as keyof OfferData],
          },
        };
      }, {});

      const { vin } = offerData;
      API.services.assetBuilder
        .updateOfferFields({
          vin: vin,
          orderId: orderId,
          keyValues: keyValuesToUpdate,
          sectionKey: "selected", // only selected offer can be edited. Hence, payment engine button is only enabled on selected offer.
        })
        .catch(() => {
          // The endpoint returns 204 for success or 500 for failure. If it fails, this catch block will be called.
          message.error("Unable to update.");
        });
    }, 1000);
  }, [editedKeys, offerData, orderId]);
  useEffect(updateOfferFieldsDS, [updateOfferFieldsDS]);

  const [filterField, setFilterField] = useState<string>();
  const onFilterFieldChange = (value: string) => {
    setFilterField(value);
  };

  return savedOrder ? (
    <TabContainer
      selectedTab={currentTab?.name.toLowerCase()}
      displaySearchView={{
        displayNewOffer: true,
        displaySearchInput: true,
        displayPlusButton: false,
      }}
      displayFilterSection={true}
      filterTab={
        <OfferFilterV2
          feed={props.feed}
          tab={currentTab}
          onFilterFieldChange={onFilterFieldChange}
          savedOrder={props.savedOrder}
        />
      }
      onChange={tabName => {
        // NOTE: "key" on TabPane is set in lower case.
        const tabs = selectedTabs.filter(t => t.name.toLowerCase() === tabName);
        const notExist = tabs.length === 0;
        if (notExist) message.error("There was system error. Tab not found.");

        const isTabDuplicated = tabs.length > 1;
        if (isTabDuplicated)
          return message.error("Found multiple tabs with same name.");

        const [tab] = tabs;
        onTabChange(tab);
      }}
      contentTabs={selectedTabs.map((tab, idx) => {
        return {
          title: tab.name,
          tabButton:
            idx > 0 ? (
              <TabDeleteButton tab={tab} onDelete={onTabDelete} />
            ) : undefined,
          component: (
            <div className={styles.OfferCollapseWrapper}>
              {(["selected", "available"] as TOfferListSection[]).map(key => {
                return (
                  // For "Selected Offers" and "Available Offers"
                  <OfferListSection
                    key={`offer-list-section-${key}-${tab.id}`}
                    tab={tab}
                    savedOrder={savedOrder}
                    sectionKey={key}
                    offerList={
                      offerListData?.[key as "selected" | "available"] || []
                    }
                    offerListData={offerListData}
                    editedVins={editedVins}
                    shouldDisplayLoadMore={false}
                    moveOfferTo={moveOfferTo(currentTab)}
                    onDataFetchComplete={onDataFetchComplete}
                    isUpdating={isUpdating}
                    count={counts?.[tab.id]}
                    searchBy={props.searchBy}
                    filterField={key === "available" ? filterField : undefined}
                    addingMode={addingMode}
                    setAddingMode={setAddingMode}
                    duplicatedOfferData={duplicatedOfferData}
                    setDuplicatedOfferData={setDuplicatedOfferData}
                  />
                );
              })}
            </div>
          ),
        };
      })}
      addButton={
        <FeedSelector
          tabs={availableTabs}
          loading={loading}
          onSelect={tab => {
            onTabAdd(tab);
          }}
        />
      }
    />
  ) : null;
};

const areEqual = (prev: Props, next: Props) => {
  return (
    isEqual(prev.savedOrder, next.savedOrder) &&
    isEqual(prev.searchBy, next.searchBy) &&
    isEqual(prev.addingMode, next.addingMode)
  );
};
export default memo(SelectV2, areEqual);
