import { Button } from "antd";
import classNames from "classnames";
import { useNavigate, useLocation } from "react-router-dom";
import { AiIcon } from "shared/icons/AiIcon";
import { AiResizeIcon } from "shared/icons/AiResizeIcon";
import styles from "./ToolPicker.module.scss";

const ToolPicker = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const selectedTab = location.pathname === "/ai-image-tools" ? "1" : "2";

  const handleTabChange = (activeKey: string) => {
    if (activeKey === "1" && selectedTab === "2") {
      navigate("/ai-image-tools");
    } else if (activeKey === "2" && selectedTab === "1") {
      navigate("/ai-image-tools/resize");
    }
  };

  return (
    <div className={styles.toolPicker}>
      <Button
        type="ghost"
        onClick={() => handleTabChange("1")}
        className={classNames(styles.toolPickerTab, {
          [styles.toolPickerTabActive]: selectedTab === "1",
        })}
      >
        <AiIcon
          height={40}
          width={40}
          color={selectedTab === "1" ? "#1890ff" : "black"}
        />
        Generate Image
      </Button>
      <Button
        type="ghost"
        onClick={() => handleTabChange("2")}
        className={classNames(styles.toolPickerTab, {
          [styles.toolPickerTabActive]: selectedTab === "2",
        })}
      >
        <AiResizeIcon
          height={40}
          width={40}
          color={selectedTab === "2" ? "#1890ff" : "black"}
        />
        Resize Image
      </Button>
    </div>
  );
};

export default ToolPicker;
