
import API from "services";

export const fetchAudienceIntegrationLogs = async () => {
  const { result, error } =
    await API.services.adLibrary.getAudienceIntegrationLogs();

  if (!result || error) {
    throw Error(
      error?.message ||
        "Something went wrong while fetching audience integration logs.",
    );
  }

  return result;
};
