import { TableColumnProps } from "antd";
import { AccountRecord, BrandAccountRecord } from "shared/types/brandsAccounts";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { returnFilterOptions } from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/utils";
import { FilterDropdownWithFilterSearch } from "shared/components/FilterDropdownWithFilterSearch";
import { IDataListColumns } from "shared/components/dataList";
import { IDataListFilters } from "shared/components/dataList/types";
import { compareStringBy } from "utils/helpers.table";
import { filterColumn } from "./utils/table.utils";
import { FileImageOutlined } from "@ant-design/icons";
import styles from "./BrandsAccountsTable.module.scss";

export const getColumns = (
  dataSource: BrandAccountRecord[],
  filters: IDataListFilters,
  onNameClick: (record: BrandAccountRecord) => void,
  onlyAccountsMode?: boolean,
): Array<TableColumnProps<BrandAccountRecord>> => [
  {
    title: <b>Name</b>,
    dataIndex: "name",
    key: "name",
    width: 325,
    fixed: "left",
    sorter: compareStringBy("name"),
    render: (name: string, record: BrandAccountRecord) => (
      <div
        className={styles.brandsClickable}
        data-cy="column-name"
        onClick={() => {
          onNameClick(record);
        }}
      >
        {record.type === "Brand" &&
          (record.logoUrl ? (
            <img
              alt="brand_logo"
              className={styles.logoImage}
              src={record.logoUrl}
            />
          ) : (
            <FileImageOutlined className={styles.placeholderImage} />
          ))}
        <AutoEllipsisText
          className={
            record.type === "Account" && !onlyAccountsMode
              ? styles.accountName
              : undefined
          }
        >
          {name}
        </AutoEllipsisText>
      </div>
    ),
    filters: returnFilterOptions(
      getChildrenFilterOptions("name", dataSource, onlyAccountsMode),
    ),
    filterDropdown: FilterDropdownWithFilterSearch,
    filteredValue: filters?.name || null,
  },
  {
    title: <b>Location</b>,
    dataIndex: "location",
    key: "location",
    width: 150,
    sorter: (a: BrandAccountRecord, b: BrandAccountRecord) => {
      return a.type === "Account" && b.type === "Account"
        ? compareStringBy("location")(a, b)
        : 0;
    },
    render: location => (
      <span>{!!location && location !== "" ? location : "-"}</span>
    ),

    filters: returnFilterOptions(
      getChildrenFilterOptions("location", dataSource, onlyAccountsMode),
    ),
    filterDropdown: FilterDropdownWithFilterSearch,
    filteredValue: filters?.location || null,
  },
  {
    title: <b>URL</b>,
    dataIndex: "url",
    key: "url",
    width: 225,
    sorter: (a: BrandAccountRecord, b: BrandAccountRecord) => {
      return a.type === "Account" && b.type === "Account"
        ? compareStringBy("url")(a, b)
        : 0;
    },
    render: url => <span>{!!url && url !== "" ? url : "-"}</span>,
    filters: returnFilterOptions(
      getChildrenFilterOptions("url", dataSource, onlyAccountsMode),
    ),
    filterDropdown: FilterDropdownWithFilterSearch,
    filteredValue: filters?.url || null,
  },
  {
    title: <b>Logos</b>,
    dataIndex: "logos",
    key: "logos",
    width: 150,
    sorter: (a: BrandAccountRecord, b: BrandAccountRecord) =>
      a.logos.length - b.logos.length,
    render: (logos: string[]) => (
      <span>
        {logos.length}
        {logos.length === 1 ? " File" : " Files"}
      </span>
    ),
  },
  {
    title: <b>Fonts</b>,
    dataIndex: "fonts",
    key: "fonts",
    width: 150,
    sorter: (a: BrandAccountRecord, b: BrandAccountRecord) =>
      a.type === "Brand" && b.type === "Brand"
        ? a.fonts.length - b.fonts.length
        : 0,
    render: (fonts?: string[]) =>
      !fonts ? (
        <span>-</span>
      ) : (
        <span>
          {fonts.length} {fonts.length === 1 ? "File" : "Files"}
        </span>
      ),
  },
  {
    title: <b>FB Account ID</b>,
    dataIndex: "fbAccountId",
    key: "fbAccountId",
    width: 150,
    sorter: (a: BrandAccountRecord, b: BrandAccountRecord) =>
      a.type === "Account" && b.type === "Account"
        ? compareStringBy("fbAccountId")(a, b)
        : 0,
    render: fbAccountId => (
      <span>{!!fbAccountId && fbAccountId !== "" ? fbAccountId : "-"}</span>
    ),
    filters: returnFilterOptions(
      getChildrenFilterOptions("fbAccountId", dataSource, onlyAccountsMode),
    ),
    filterDropdown: FilterDropdownWithFilterSearch,
    filteredValue: filters?.fbAccountId || null,
  },
  {
    title: <b>FB Page ID</b>,
    dataIndex: "fbPageId",
    key: "fbPageId",
    width: 150,
    sorter: (a: BrandAccountRecord, b: BrandAccountRecord) =>
      a.type === "Account" && b.type === "Account"
        ? compareStringBy("fbPageId")(a, b)
        : 0,
    render: fbPageId => (
      <span>{!!fbPageId && fbPageId !== "" ? fbPageId : "-"}</span>
    ),
    filters: returnFilterOptions(
      getChildrenFilterOptions("fbPageId", dataSource, onlyAccountsMode),
    ),
    filterDropdown: FilterDropdownWithFilterSearch,
    filteredValue: filters?.fbPageId || null,
  },
  {
    title: <b>FB Pixel ID</b>,
    dataIndex: "fbPixelId",
    key: "fbPixelId",
    width: 150,
    sorter: (a: BrandAccountRecord, b: BrandAccountRecord) =>
      a.type === "Account" && b.type === "Account"
        ? compareStringBy("fbPixelId")(a, b)
        : 0,
    render: fbPixelId => (
      <span>{!!fbPixelId && fbPixelId !== "" ? fbPixelId : "-"}</span>
    ),
    filters: returnFilterOptions(
      getChildrenFilterOptions("fbPixelId", dataSource, onlyAccountsMode),
    ),
    filterDropdown: FilterDropdownWithFilterSearch,
    filteredValue: filters?.fbPixelId || null,
  },
];

const getChildrenFilterOptions = (
  key: keyof AccountRecord,
  dataSource: BrandAccountRecord[],
  isOnlyAccountsMode: boolean | undefined,
) => {
  let values = dataSource.flatMap(brand =>
    brand.type === "Brand"
      ? brand.children.map(child => child[key]?.toString())
      : [],
  );
  values = isOnlyAccountsMode
    ? [
        ...values,
        ...dataSource.map(brand => (brand as AccountRecord)[key]?.toString()),
      ]
    : values;

  const uniqueValues = [...new Set(values)];

  return uniqueValues.sort();
};

export const getColumnsForDataFeed = (): IDataListColumns<BrandAccountRecord> =>
  Object.assign(
    {},
    ...[
      { key: "name", displayName: "Name" },
      { key: "location", displayName: "Location" },
      { key: "url", displayName: "URL" },
      { key: "logos", displayName: "Logos" },
      { key: "fbAccountId", displayName: "FB Account ID" },
      { key: "fbPageId", displayName: "FB Page ID" },
      { key: "fbPixelId", displayName: "FB Pixel ID" },
    ].map(({ key: brandKey, displayName }) => ({
      [brandKey]: {
        display: (value: string) => displayName + ": " + value,
        filterFn: (value: string | string[], record: BrandAccountRecord) =>
          filterColumn(brandKey as keyof BrandAccountRecord, value, record),
      },
    })),
  );
