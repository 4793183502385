import { groupBy } from "lodash";
import { KeysWithStringOrUndefinedValues, NonFalsy } from "./utilityTypes";

export const removeItemOrDups = <T>(
  arr: T[],
  predicate: (item: T) => boolean,
): T[] => {
  let oneInstanceAlreadyFound = false;

  return arr.filter(item => {
    if (predicate(item) && !oneInstanceAlreadyFound) {
      oneInstanceAlreadyFound = true;
      return false;
    }
    return true;
  });
};

export const dedupe = <T>(data: T, index: number, arr: T[]) => {
  return arr.indexOf(data) === index;
};

export const dedupeBy =
  <T>(key: KeysWithStringOrUndefinedValues<T>) =>
  (data: T, index: number, arr: T[]) => {
    return arr.findIndex(item => item[key] === data[key]) === index;
  };

export const typedGroupBy = <
  K extends string | number | symbol,
  T extends Record<K, unknown>[],
>(
  collection: T,
  keyGetter: (item: T[number]) => K,
) => {
  return groupBy(collection, keyGetter) as Partial<Record<K, T>>;
};

export const isTruthy = <T>(value: T): value is NonFalsy<T> => !!value;

export const nonNullable = <T>(value: T): value is NonNullable<T> => {
  return value !== null && value !== undefined;
};

/**
 * Given a 2d array, a line number and item index, returns the item in the previous position.
 * If the item is the first in the line, returns the last item in the previous line.
 *
 * @param arr 2d array
 * @param line line number
 * @param index item index
 */
export const getPreviousValue2d = <T>(
  arr: T[][],
  line: number,
  index: number,
) => {
 if (line === 0 && index === 0) {
    return null;
  }
  // If it's the start of any row (but not the first row)
  if (index === 0) {
    const prevLine = arr[line - 1];
    return prevLine?.[prevLine.length - 1];
  }
  return arr[line]?.[index - 1];
};
