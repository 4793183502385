import { Spin } from "antd";
import { ITableColumn } from "shared/components/dataList";
import {
  SyncOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import NameTag from "screens/adLibrary/shared/components/NameTag";
import { IAccount } from "shared/types/accountManagement";
import moment from "moment";
import { GoogleAdCampaignInternalWithStatus } from "../CampaignLoadTable";
import { LoadStatus } from "screens/adLibrary/pmaxAssetGroups/shared/types";
import { UseMutationResult } from "react-query";
import styles from "../../../GooglePerformanceMax.module.scss";

const CampaignStatusIconCell = ({ status }: { status: LoadStatus }) => {
  if (status === "Failed")
    return <CloseCircleFilled className={styles.errorIcon} />;
  if (status === "Loaded")
    return <CheckCircleFilled className={styles.successIcon} />;
  return <Spin size="small" indicator={<SyncOutlined spin />} />;
};

export const getColumns = (
  store: IAccount | undefined,
  batchCampaignLoadStatus: UseMutationResult["status"],
): ITableColumn<GoogleAdCampaignInternalWithStatus>[] => {
  return [
    {
      key: "status",
      title: "",
      width: 30,
      dataIndex: ["status"],
      render: loadStatus => {
        if (batchCampaignLoadStatus === "idle") return null;
        return <CampaignStatusIconCell status={loadStatus} />;
      },
    },
    {
      key: "name",
      title: "Campaign Name",
      dataIndex: ["name"],
      width: 300,
    },
    {
      key: "store",
      title: "Store",
      render: () =>
        store ? (
          <NameTag
            name={store.dealer_name}
            id={`CID: ${store.details.google?.adAccountCID}`}
          />
        ) : (
          "-"
        ),
    },
    {
      key: "startDate",
      title: "Start Date",
      dataIndex: ["startDate"],
      render: date => moment(date).format("MM/DD/YYYY"),
    },
    {
      key: "endDate",
      title: "End Date",
      dataIndex: ["endDate"],
      render: date => (date ? moment(date).format("MM/DD/YYYY") : "-"),
    },
    {
      key: "budget",
      title: "Budget",
      dataIndex: ["dailyBudget"],
      render: budget => `$${budget}/day`,
    },
  ];
};
