const config: MGConfig = {
  wdBaseUrl: "https://apiv2.webdamdb.com",
  wdClientId: "c6ec0106d047c0c65d60cc3a6c0dd9764580179c",
  wdBrandPortalId: 3239,
  active: false,
  integrations: false,
  standalone: false,
  keywordsUrl: "https://apiv2.webdamdb.com/metadataschemas/keywords",
  metaFields: {},
  defaultFields: [
    {
      field_name: "filetype",
      filter_type: "match",
      operator: "OR",
      value: "png,jpg,jpeg,woff,mp4,mov",
    },
  ],
  extraFields: [],
  tableSearchCols: ["city", "model"],
  tableSelectCols: ["city"],
  getCustomPayload(): Partial<MGSearchPayload> {
    return {};
  },
};

export default config;
