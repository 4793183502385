export const terms = {
  account: "Account",
  accountName: "Account Name",
} as const;

/**
 * Pluralize a word based on a count, this is a very simple implementation
 */
export const pluralize = (word: string, count: number) => {
  return count === 1 ? word : `${word}s`;
};
