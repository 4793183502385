import { AuthProvider } from "contexts/AuthContext";
import { FrameDaemon } from "daemon/FrameDaemon";
import { createContext, PropsWithChildren } from "react";
import NewVersionAlert from "./app/NewVersionAlert";
import { NotificationsDaemon } from "daemon/NotificationsDaemon";

export const AppContext = createContext({});

type AppProviderProps = PropsWithChildren<object>;

export const AppProvider = ({ children }: AppProviderProps) => {
  // TODO: disable v3 for v2 + auth0 standalone
  // useFetchV3User();
  // useSubUpdateV3User();
  // FIXME: this is the notification spammer - not authorized no table
  // useNewAlarmNotification();

  // // TODO: this should be in each component based on rbac
  // const checkAccess = useCallback(() => {
  //   if (user) {
  //     const alexiaModule = helpers.getValidAlexiaModuleFromPath(currentPath);
  //     const userAccess = user.toolAccess?.[alexiaModule as AlexiaModule];
  //     const alexiaModuleToStartCase = startCase(alexiaModule.toLowerCase());
  //     if (userAccess && userAccess !== "Full") {
  //       const { desc, redirect } = helpers.getNotificationMessageFromAccess(
  //         userAccess,
  //         alexiaModuleToStartCase,
  //         currentPath,
  //       );
  //       if (desc === alexiaModuleToStartCase) {
  //         setInvalidAccess(redirect);
  //         notification.warning({
  //           message: "Warning",
  //           description: `Unable to access ${desc}. Please reach out to an account admin if you require access.`,
  //           placement: "bottomRight",
  //         });
  //       } else if (desc.length) {
  //         setInvalidAccess(redirect);
  //         notification.warning({
  //           message: "Warning",
  //           description: `Unable to access ${desc}. Please reach out to an account admin if you require access.`,
  //           placement: "bottomRight",
  //         });
  //       }
  //     }
  //   }
  // }, [currentPath, user]);

  // // TODO: this shouldn't even exist
  // const handleInvalid = useCallback(() => {
  //   if (invalidAccess) {
  //     // console.log("handleInvalid");
  //     navigate(invalidAccess);
  //   }
  // }, [navigate, invalidAccess]);

  // // TODO: why?
  // const handleReferrer = useCallback(() => {
  //   if (!isLoading && !isPublic && redirectPath) {
  //     // console.log("handleReferrer");
  //     const targetPath = user && referrer ? referrer : redirectPath;
  //     navigate(targetPath);
  //   }
  // }, [navigate, isLoading, isPublic, redirectPath, referrer, user]);
  return (
    <AppContext.Provider value={{}}>
      <AuthProvider>
        <>
          <NewVersionAlert />
          {children}
          <FrameDaemon />
          <NotificationsDaemon />
        </>
      </AuthProvider>
    </AppContext.Provider>
  );
};

export const AppConsumer = AppContext.Consumer;
