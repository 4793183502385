import { useContext, createContext, useState, ReactNode, useMemo } from "react";
import { useDesignStudioData } from "../designStudioLayout/designStudioTable/hooks/useDesignStudioData";
import { ITemplate, IArtboard, IStamp } from "shared/types/designStudio";
import { getRowKey } from "../helpers";
import { useDesignStudioIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { DesignStudioFields, designStudioTableFields } from "./fields";
import useSelectedLayout from "shared/hooks/userPreferences/useSelectedLayout";
import { TableCardLayout } from "shared/types/toolbar";

export type DesignStudioAssetType = "template" | "artboard" | "stamp";
export type DesignStudioTableItemWithoutId = Omit<DesignStudioTableItem, "id">;
export type DesignStudioTableTemplate = {
  itemType: "template";
  asset: ITemplate;
  id: string;
};

export type DesignStudioTableArtboard = {
  itemType: "artboard";
  asset: IArtboard;
  id: string;
};

export type DesignStudioTableStamp = {
  itemType: "stamp";
  asset: IStamp;
  id: string;
};

export type DesignStudioTableItem =
  | DesignStudioTableTemplate
  | DesignStudioTableArtboard
  | DesignStudioTableStamp;

type DesignStudioContextType = {
  selectedRows: DesignStudioTableItem[];
  isLoading: boolean;
  designStudioAssets: DesignStudioTableItem[];
  filteredDesignStudioAssets: DesignStudioTableItem[];
  previewTemplate: ITemplate | null;
  setPreviewTemplate: (previewTemplate: ITemplate | null) => void;
  isColumnPickerOpen: boolean;
  setIsColumnPickerOpen: (isOpen: boolean) => void;
  isFetchingMoreTemplates: boolean;
  layout: TableCardLayout;
  setLayout: (layout: TableCardLayout) => void;
};

const Context = createContext<DesignStudioContextType | null>(null);

export const useDesignStudioContext = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error(
      "useDesignStudioContext must be used within a DesignStudioDataProvider",
    );
  }
  return ctx;
};

const DesignStudioContext = ({ children }: { children: ReactNode }) => {
  const { selectedIds } = useDesignStudioIds();
  const {
    isLoading,
    data: filteredDesignStudioAssets,
    originalData: designStudioAssets,
    isFetchingMoreTemplates,
  } = useDesignStudioData();
  const [previewTemplate, setPreviewTemplate] = useState<ITemplate | null>(
    null,
  );

  const { layout, setLayout } = useSelectedLayout();
  const [isColumnPickerOpen, setIsColumnPickerOpen] = useState(false);

  const selectedRows = useMemo(() => {
    if (!selectedIds) return [];

    return designStudioAssets.filter(item =>
      selectedIds.includes(getRowKey(item)),
    );
  }, [selectedIds, designStudioAssets]);

  const memoizedContextValue = useMemo(
    () => ({
      isLoading,
      designStudioAssets,
      filteredDesignStudioAssets,
      previewTemplate,
      setPreviewTemplate,
      selectedRows,
      isColumnPickerOpen,
      setIsColumnPickerOpen,
      isFetchingMoreTemplates,
      layout,
      setLayout,
    }),
    [
      isLoading,
      designStudioAssets,
      filteredDesignStudioAssets,
      previewTemplate,
      selectedRows,
      isColumnPickerOpen,
      isFetchingMoreTemplates,
      layout,
      setLayout,
    ],
  );

  return (
    <Context.Provider value={memoizedContextValue}>{children}</Context.Provider>
  );
};

export const DesignStudioDataProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return (
    <DataListURLProvider<DesignStudioFields, DesignStudioTableItem>
      fields={designStudioTableFields}
    >
      <DesignStudioContext>{children}</DesignStudioContext>
    </DataListURLProvider>
  );
};
