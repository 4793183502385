import { AutoComplete, Col, Row, Select } from "antd";
import { FieldWrapper } from "screens/assetExporter/feedConfigurationV2/shared/components/FieldWrapper";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import {
  TMediaResizeType,
  TValueMapping,
  TVariable,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { MediaColumn } from "shared/types/assetExporter";
import { FieldTitle } from "../../shared/components/FieldTitle";
import { getInputValueForMapping } from "../../shared/contexts/AssetBatchesContext.utils";
import styles from "./VariableInput.module.scss";
import { useHightlightVarEvents } from "./hooks/useHighlightVarEvents";
import { useAssetBatchesCompositionContext } from "../../shared/contexts/AssetBatchesCompositionContext";

type VariableInputProps = {
  compositionId: string;
  variable: TVariable;
  valueMapping?: TValueMapping;
  mappingKey: string;
  mediaColumns?: MediaColumn[];
};

export const ImageVariableInput = ({
  variable,
  valueMapping,
  mappingKey,
}: VariableInputProps) => {
  const { setShowMediaAlert, mediaColumns } = useAssetBatchesContext();
  const { getMediaInputError } = useAssetBatchesValueMappingContext();
  const { onSelectResizeType, onSelectInputValueSelect, onSelectInputValue } =
    useAssetBatchesCompositionContext();
  const { onMouseEnter, onMouseLeave, onFocus, onBlur, onValueChange } =
    useHightlightVarEvents(mappingKey);
  const { templatesToUse: templates } = useAssetBatchesContext();
  const value = getInputValueForMapping(valueMapping);
  const resizeType = valueMapping?.resizeType ?? "fill";
  const error = getMediaInputError(value, valueMapping);

  const leftColSpan = templates?.[0]?.type === "html" ? 24 : 21;

  return (
    <>
      <FieldTitle title={variable.variable} />
      <Row
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={styles.row}
        justify="space-around"
        align="top"
      >
        <Col span={leftColSpan}>
          <FieldWrapper label="Column or URL" width={24}>
            <AutoComplete
              allowClear={true}
              options={mediaColumns?.map(col => {
                return {
                  key: col.columnName,
                  value: col.columnName,
                };
              })}
              filterOption
              className={error ? `${styles.inputError}` : `${styles.input}`}
              onFocus={() => onFocus(mappingKey)}
              onBlur={() => onBlur(mappingKey)}
              onClear={() => {
                onSelectInputValue(mappingKey, undefined);
              }}
              onChange={(val, option) => {
                setShowMediaAlert(false);
                onValueChange(mappingKey, !val);

                if (val && "key" in option && typeof option.key === "string") {
                  onSelectInputValueSelect(mappingKey, {
                    column: option.key,
                    type: "match_to_column",
                  });
                } else {
                  onSelectInputValue(mappingKey, val);
                }
              }}
              onKeyDown={e => {
                if (e.key === "Enter" && value) {
                  onSelectInputValueSelect(mappingKey, {
                    column: value,
                    type: "match_to_column",
                  });
                  onSelectResizeType(mappingKey, resizeType);
                }
                if (e.key === "Esc" || e.key === "Escape") {
                  onSelectInputValue(mappingKey, undefined);
                }
              }}
              value={value}
              data-cy={mappingKey}
              bordered={!!error}
            />
            {error && <div className={styles.errorDesc}>{error}</div>}
          </FieldWrapper>
        </Col>
        {templates?.[0]?.type !== "html" && (
          <Col span={3}>
            <FieldWrapper label="Resize">
              <Select
                defaultValue={"fill"}
                disabled={!value}
                onSelect={val => {
                  onSelectResizeType(mappingKey, val as TMediaResizeType);
                }}
                value={resizeType}
              >
                <Select.Option value="fill">Fill</Select.Option>
                <Select.Option value="fit">Fit</Select.Option>
              </Select>
            </FieldWrapper>
          </Col>
        )}
      </Row>
    </>
  );
};
