import { TableFiltersSection } from "../TableFiltersSection";
import { TagType } from "../TagList";
import { useDataListURLContext } from "./dataListURLContext";
import { RecordWithId } from "./useDataListURLData";
import { useDataListURLFilters } from "./useDataListURLFilters";
import { useDataListURLIds } from "./useDataListURLIds";
import { getAllAdIds, getEntries } from "./utils";
import { nonNullable } from "utils/helpers.array";

type Props<RecordType extends RecordWithId> = {
  data: RecordType[];
  originalData: RecordType[];
  showSelectAll?: boolean;
};

export const DataListTagsURL = <
  FieldKey extends string,
  RecordType extends RecordWithId,
>({
  data,
  originalData,
  showSelectAll,
}: Props<RecordType>) => {
  const { fields } = useDataListURLContext<FieldKey, RecordType>();

  const { filters, resetFilters, removeFilter } = useDataListURLFilters<
    FieldKey,
    RecordType
  >();


  const { selectedIds, showSelected, toggleShowSelected, toggleSelectAll } =
    useDataListURLIds<RecordType>(getAllAdIds);

  const getTags = () => {
    const filterEntries = getEntries(filters);

    return filterEntries
      .flatMap(([key, value]): TagType<FieldKey>[] | undefined => {
        if (!key || !value) return undefined;

        return value.map((v: string) => ({
          key: key,
          value: v,
          displayValue: fields[key]?.display?.(v) ?? v,
        }));
      })
      .filter(nonNullable);
  };

  return (
    <TableFiltersSection<FieldKey>
      tags={getTags()}
      onFilterClick={toggleShowSelected}
      selectedCount={selectedIds.length}
      displaySelectedOnly={showSelected}
      clearTags={resetFilters}
      removeTag={removeFilter}
      currentItems={data.length}
      groupedItems={originalData.length}
      totalItems={getAllAdIds(originalData).length}
      onSelectAll={() => toggleSelectAll(originalData)}
      showSelectAll={showSelectAll}
    />
  );
};
