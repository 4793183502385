import { Checkbox, Space, Tag } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import styles from "../ExportDrawer.module.scss";
import { useFeedContext } from "../shared/contexts/FeedContext";

export const AssetBatchesSelection = () => {
  const {
    assetBatchesForSelectedRows,
    onSelectAssetBatches,
    selectedAssetBatches,
  } = useFeedContext();

  const onCheckBoxChange = (e: CheckboxChangeEvent, assetBatchId: string) =>
    e.target.checked
      ? onSelectAssetBatches([...selectedAssetBatches, assetBatchId])
      : onSelectAssetBatches(
          selectedAssetBatches.filter(id => id !== assetBatchId),
        );

  return (
    <>
      <div
        className={`${styles.radioSelectionTitle} ${styles.sectionTopSpacing}`}
      >
        Asset Batches
      </div>
      <div id="columns-to-export" className={styles.assetBatchOptions}>
        <Space direction="vertical">
          {assetBatchesForSelectedRows.map(({ name, assetBatchId }) => {
            const val = assetBatchId || "";
            return (
              <Checkbox
                key={assetBatchId}
                checked={selectedAssetBatches.includes(val)}
                onChange={e => onCheckBoxChange(e, val)}
                data-cy={`assetBatchesToExport-${assetBatchId}`}
              >
                <Tag className={styles.tag} key={name}>
                  {name}
                </Tag>
              </Checkbox>
            );
          })}
        </Space>
      </div>
    </>
  );
};
