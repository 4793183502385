import { useCallback } from "react";
import actions from "redux/rootActions";
import {
  DesignStudioTableItem,
  useDesignStudioContext,
} from "screens/designStudio/context/DesignStudioDataProvider";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import {
  isTemplate,
  isStamp,
  isArtboard,
  isPublished,
  isHTMLTemplate,
  isV3Template,
} from "../../../helpers";
import { useDesignStudioIds } from "screens/adLibrary/shared/hooks/dataListHooks";
import { useNavigate } from "react-router-dom";
import {
  showArchivePrompt,
  showDeletePrompt,
  showEditStampPrompt,
  showPublishPrompt,
  showDuplicatePrompt,
} from "../../shared/helpers.prompts";
import { useMutateTemplate } from "shared/hooks/useMutateTemplate";
import { message } from "antd";
import { IStamp, ITemplate } from "shared/types/designStudio";
import sortBy from "lodash/sortBy";
import { useDesignStudioEditor } from "./useDesignStudioEditor";
import {
  DESIGN_STUDIO_V2_EDITOR_ENABLED,
  POLOTNO_EDITOR,
} from "screens/designStudio/designStudioV2/constants";

export const useDesignStudioTableActions = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setPreviewTemplate } = useDesignStudioContext();
  const { setSelectedItemIds } = useDesignStudioIds();
  const { setEditor } = useDesignStudioEditor();
  const { mutateAsync: updateTemplate, isLoading: isMutatingTemplates } =
    useMutateTemplate(() => message.success("Template updated successfully"));

  const editAssetParameters = useCallback(
    (item: DesignStudioTableItem) => {
      if (isTemplate(item))
        dispatch(actions.designStudio.setTemplateToUpdate(item.asset));
      if (isStamp(item))
        dispatch(actions.designStudio.setStampToUpdate(item.asset));
      if (isArtboard(item))
        dispatch(actions.designStudio.setArtboardToUpdate(item.asset));
    },
    [dispatch],
  );

  const archiveStamp = useCallback(
    (stamp: IStamp) => {
      dispatch(
        actions.designStudio.updateStamp({
          ...stamp,
          isDeleted: true,
        }),
      );
    },
    [dispatch],
  );

  const archiveTemplate = useCallback(
    (template: ITemplate) => {
      updateTemplate({
        template: {
          ...template,
          isDeleted: true,
        },
        mode: "UPDATE",
      });
    },
    [updateTemplate],
  );

  const archiveAssets = useCallback(
    (items: DesignStudioTableItem[]) => {
      const stamps = items.filter(isStamp).map(item => item.asset);
      const templates = items.filter(isTemplate).map(item => item.asset);
      const assetNames = sortBy(items, "itemType").map(
        item => `${item.asset.name} (${item.itemType})`,
      );

      showArchivePrompt(assetNames, () => {
        stamps.forEach(stamp => archiveStamp(stamp));
        templates.forEach(template => archiveTemplate(template));
        setSelectedItemIds([]);
      });
    },
    [archiveStamp, archiveTemplate, setSelectedItemIds],
  );

  const showTemplatePreview = useCallback(
    (item: DesignStudioTableItem) => {
      if (!isTemplate(item)) return;
      setPreviewTemplate(item.asset);
    },
    [setPreviewTemplate],
  );

  const editAsset = useCallback(
    (item: DesignStudioTableItem) => {
      const nav = (itemId: string, params?: string) => {
        const baseRoute = isTemplate(item)
          ? "/design-studio/editor/templates/:targetId/editor-v2"
          : "/design-studio/editor/stamps/:targetId/editor-v2";
        const route = baseRoute.replace(":targetId", itemId);
        navigate(params ? `${route}?${params}` : route);
      };

      if (isArtboard(item)) return;
      if (isStamp(item)) showEditStampPrompt(item.asset.id, nav);

      if (isV3Template(item) && DESIGN_STUDIO_V2_EDITOR_ENABLED) {
        setEditor({
          editor: "templates",
          assetId: item.asset.id,
          mode: POLOTNO_EDITOR,
        });
        return;
      }
      if (isTemplate(item) && item.asset.id) nav(item.asset.id);
    },
    [navigate, setEditor],
  );

  const deleteAsset = useCallback(
    (item: DesignStudioTableItem) => {
      if (!isArtboard(item)) return;

      showDeletePrompt(() =>
        dispatch(actions.designStudio.deleteArtboard(item.asset)),
      );
    },
    [dispatch],
  );

  const publishAsset = useCallback(
    (item: DesignStudioTableItem) => {
      showPublishPrompt(item, () => {
        if (!isTemplate(item)) return;

        updateTemplate({
          template: {
            ...item.asset,
            status: isPublished(item) ? "UN-PUBLISHED" : "PUBLISHED",
          },
          mode: "UPDATE",
        });
      });
    },
    [updateTemplate],
  );

  const duplicateAsset = useCallback(
    (item: DesignStudioTableItem) => {
      if (isArtboard(item)) return;

      if (isTemplate(item)) {
        showDuplicatePrompt(item, () =>
          updateTemplate(
            {
              template: {
                ...item.asset,
                name: `${item.asset.name}-Copy`,
                status: "UN-PUBLISHED",
              },
              mode: "DUPLICATE",
            },
            {
              onSettled: createdTemplate => {
                dispatch(
                  actions.designStudio.setTemplateToUpdate(createdTemplate),
                );
              },
            },
          ),
        );
      }

      if (isStamp(item)) {
        showDuplicatePrompt(item, () =>
          dispatch(
            actions.designStudio.duplicateStamp({
              ...item.asset,
              name: `${item.asset.name}-Copy`,
            }),
          ),
        );
      }
    },
    [dispatch, updateTemplate],
  );

  return {
    isMutatingTemplates,
    edit: {
      disabled: (item?: DesignStudioTableItem) =>
        !item || isArtboard(item) || isHTMLTemplate(item),
      handler: editAsset,
    },
    editParams: {
      disabled: (item?: DesignStudioTableItem) => !item,
      handler: editAssetParameters,
    },
    archive: {
      disabled: (items: DesignStudioTableItem[]) =>
        !items.length || items.some(isArtboard),
      handler: archiveAssets,
    },
    deleteAsset: {
      disabled: (item?: DesignStudioTableItem) => !item || !isArtboard(item),
      handler: deleteAsset,
    },
    showPreview: {
      disabled: (item?: DesignStudioTableItem) => !item || !isTemplate(item),
      handler: showTemplatePreview,
    },
    publish: {
      disabled: (item?: DesignStudioTableItem) => !item || !isTemplate(item),
      handler: publishAsset,
    },
    duplicate: {
      disabled: (item?: DesignStudioTableItem) => !item || isArtboard(item),
      handler: duplicateAsset,
    },
  };
};
