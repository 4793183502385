import { useState } from "react";
import { Image, Popover, Spin } from "antd";
import { getCAMThumbnail } from "./getCAMThumbnail";
import { getPreviewThumbnail } from "./getPreviewThumbnail";
import { handleImgError } from "./handleImgError";
import ReactPlayer from "react-player";

type Props = {
  asset: WDAsset;
  size?: number;
};

export const MediaThumbHover = ({ asset, size = 40 }: Props) => {
  const { videourls } = asset;
  const mediaThumbnail = getCAMThumbnail(asset, 1);
  const previewThumbnail = getPreviewThumbnail(asset);
  const [loaded, setLoaded] = useState(false);

  return (
    <Popover
      placement="rightTop"
      content={
        videourls ? (
          <ReactPlayer
            playing={true}
            muted={true}
            loop={true}
            key={previewThumbnail.url}
            url={previewThumbnail.url}
          />
        ) : (
          <Spin spinning={!loaded}>
            <Image
              src={previewThumbnail.url}
              onLoad={() => setLoaded(true)}
              width={300}
              height={200}
              style={{ objectFit: "contain" }}
              preview={false}
            />
          </Spin>
        )
      }
      trigger="hover"
    >
      <Image
        wrapperStyle={{ display: "flex" }}
        src={mediaThumbnail.url}
        width={size}
        height={size}
        loading="lazy"
        style={{
          objectFit: "contain",
        }}
        onError={handleImgError}
        preview={false}
      />
    </Popover>
  );
};
