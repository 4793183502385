import { useEffect, useState } from "react";
import {
  TCanvasAction,
  TCanvasActionType,
} from "screens/designStudio/editor/canvasContainer/Canvas";
import { FabricEvent } from "screens/designStudio/editor/canvasContainer/canvas.utils/EventHandlers";
import {
  getPropertyValue,
  getHighlightedPropertyValue,
  isNumber,
} from "../FontProperty.utils";
import { getPreviousValue2d } from "utils/helpers.array";

type Args = {
  textbox?: fabric.Textbox;
  canvasAction?: TCanvasAction;
};

export default (args: Args) => {
  const { textbox, canvasAction } = args;
  const [fontName, setFontName] = useState<string>(
    getPropertyValue(textbox, "fontFamily"),
  );
  const [fontSize, setFontSize] = useState<number | string>(
    getPropertyValue(textbox, "fontSize"),
  );

  useEffect(() => {
    if (!textbox) return;

    const allowedCanvasActions: TCanvasActionType[] = [
      "objectSelected",
      FabricEvent.TEXT_SELECTION_CHANGED,
    ];
    const okToProceed =
      canvasAction && allowedCanvasActions.includes(canvasAction?.action.type);
    if (!okToProceed) return;

    const isTextboxSelected = canvasAction?.action.type === "objectSelected";
    if (isTextboxSelected) {
      setFontName(getPropertyValue(textbox, "fontFamily"));

      const possibleFontSize = getPropertyValue(textbox, "fontSize");
      if (isNumber(possibleFontSize)) setFontSize(possibleFontSize);

      return;
    }

    const highlightedStyles = textbox.getSelectionStyles();

    const isTextHighlighted =
      highlightedStyles.length > 0 &&
      canvasAction?.action.type === FabricEvent.TEXT_SELECTION_CHANGED;

    if (isTextHighlighted) {
      setFontName(getHighlightedPropertyValue(textbox, "fontFamily"));
      const possibleFontSize = getHighlightedPropertyValue(textbox, "fontSize");
      if (isNumber(possibleFontSize)) setFontSize(possibleFontSize);
    } else {
      const { styles } = textbox;
      const { lineIndex, charIndex } = textbox.get2DCursorLocation();

      const charStyle =
        styles?.[lineIndex]?.[charIndex] ||
        getPreviousValue2d(styles, lineIndex, charIndex);

      const { fontFamily } = charStyle || styles || {};
      setFontName(prev => fontFamily || textbox.fontFamily || prev);

      const { fontSize } = charStyle || styles || {};
      setFontSize(prev => fontSize || textbox.fontSize || prev);
    }
  }, [textbox, canvasAction]);

  return {
    fontName,
    fontSize,
    setFontSize,
    setFontName,
  };
};
