import { useState, FC } from "react";

import { Checkbox, Select } from "antd";
import { IDisclosure } from "shared/types/legalLingo";

import { useFetchDisclosures } from "shared/hooks/legalLingo/useFetchDisclosures";
import { IAssetBuildInstance } from "shared/types/assetBuilder";
import { lowerCase } from "lodash";

interface DisclosureSelectionProps {
  onDisclosureChange: (disclosure: {
    allSizes?: IDisclosure;
    currentSize?: IDisclosure;
    selectedAsset?: IDisclosure;
  }) => void;
  assetBuildInstance?: IAssetBuildInstance;
}

const defaultDisclosure = {
  id: 0,
  name: "",
  vin: true,
  condition: [],
  location: [],
  oem: [],
  store: [],
  disclosures: [],
};

const DisclosureSelection: FC<DisclosureSelectionProps> = ({
  onDisclosureChange,
  assetBuildInstance,
}) => {
  const { disclosure = {} } = assetBuildInstance || {};
  const { disclosures } = useFetchDisclosures();
  const [disclosureKey, disclosureValue] =
    Object.entries(disclosure)?.[0] || [];
  const [selectedKey, setSelectedKey] = useState<string | null>(disclosureKey);
  const [selectedDisclosure, setSelectedDisclosure] = useState<IDisclosure>(
    disclosureValue || defaultDisclosure,
  );
  const [prevInstance, setPrevInstance] = useState(assetBuildInstance);

  if (prevInstance !== assetBuildInstance) {
    setSelectedKey(disclosureKey);
    setSelectedDisclosure(disclosureValue);
    setPrevInstance(assetBuildInstance);
  }

  return (
    <div>
      <br />
      <Select
        placeholder={"Select a Custom Disclosure"}
        style={{ display: "block", paddingBottom: "20px" }}
        value={selectedDisclosure?.name}
        showSearch
        onChange={disc => {
          const foundDisc = disclosures.find(
            fullDiscs => fullDiscs.name === disc,
          );
          if (foundDisc) {
            setSelectedDisclosure(foundDisc);
            setSelectedKey(null);
          }
        }}
      >
        {disclosures.map((disc: IDisclosure) => {
          return (
            <Select.Option key={disc.name} value={disc.name}>
              {disc.name}
            </Select.Option>
          );
        })}
      </Select>

      {["allSizes", "currentSize", "selectedAsset"].slice(0, 12).map(con => (
        <Checkbox
          key={con}
          value={con}
          checked={selectedKey === con}
          disabled={!selectedDisclosure?.disclosures?.length}
          style={{
            paddingBottom: "15px",
            paddingRight: "2em",
            marginLeft: "0px",
          }}
          onChange={discOption => {
            const target = discOption.target;
            const { value, checked } = target;
            const disclosureToSet = checked
              ? selectedDisclosure
              : defaultDisclosure;
            setSelectedKey(checked ? con : null);
            onDisclosureChange({ [value]: { ...disclosureToSet } });
          }}
        >
          {`Add to ${lowerCase(con)}`}
        </Checkbox>
      ))}
    </div>
  );
};

export default DisclosureSelection;
