import { useUpdateAssetMetaMutation } from "redux/media/media.api";
import { finishUpload, getPresignedUrl, uploadToS3 } from "../utils.upload";

export const useUpload = () => {
  const [updateMeta] = useUpdateAssetMetaMutation();

  return async (url: string, folderId: string, meta?: MGFormValues) => {
    try {
      const fileName = url.split("/").at(-1)!;
      const res = await fetch(url);
      const blob = await res.blob();
      const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now(),
      });

      // Get pre-signed url from AWS.
      const { presignedUrl, processId } = await getPresignedUrl(folderId, file);
      // Upload filt to S3.
      await uploadToS3(presignedUrl, file);
      // Notify to webdam.
      const { id } = await finishUpload(processId);
      // Update asset metadata.
      await updateMeta({ payload: [{ id, ...meta }] }).unwrap();
    } catch {
      throw new Error("Failed to upload asset");
    }
  };
};
