import { useState, useRef, useCallback, useEffect } from "react";
import { Input, Space } from "antd";
import { DraggableProvided } from "react-beautiful-dnd";
import { TLayerItem } from "screens/designStudio/editor.hooks/useLayers";
import { getLayerTitle } from "screens/designStudio/editor/propertySectionV2/propertyRow/manageLayers.utils/Utils";
import { HolderOutlined } from "@ant-design/icons";
import styles from "./LayerItem.module.scss";
import classNames from "classnames";
import { LayerIcon } from "./layerItem/LayerIcon";
import {
  DeleteLayer,
  ToggleLayerLock,
  ToggleLayerVisibility,
} from "./layerItem/LayerAction";
import useOutsideClick from "shared/hooks/useOutsideClick";
import { useMergeRefs } from "shared/hooks/useMergeRefs";
import { useRefFocus } from "shared/hooks/useRefFocus";

interface LayerItemProps {
  layer: TLayerItem;
  draggableProvided: DraggableProvided;
  highlight: boolean;
  editing: boolean;
  onDoubleClick: (layer: TLayerItem) => void;
  onKeyDown: (
    layer: TLayerItem,
    cleanUp: () => void,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  onToggleLayerVisibility: (layer: TLayerItem) => void;
  onToggleLayerLock: (layer: TLayerItem) => void;
  onDeleteLayer: (layer: TLayerItem) => void;
  onLayerClickOutside: (layer: TLayerItem) => void;
  onClick: (layer: TLayerItem) => void;
}

const LayerItem = ({
  layer,
  draggableProvided,
  onDoubleClick,
  highlight,
  editing,
  onClick,
  onKeyDown,
  onToggleLayerVisibility,
  onToggleLayerLock,
  onDeleteLayer,
  onLayerClickOutside,
}: LayerItemProps) => {
  const { fullText } = getLayerTitle(layer, 15);

  const [value, setValue] = useState(fullText);
  const inputRef = useRef<Input>(null);

  const draggableProps = {
    ...draggableProvided.draggableProps,
    ...draggableProvided.dragHandleProps,
    style: draggableProvided.draggableProps.style,
  };

  const isHighlighted = Boolean(highlight && inputRef.current);
  const isEditing = Boolean(highlight && editing && inputRef.current);

  const containerRef = useOutsideClick(() => onLayerClickOutside(layer));
  const mergedRefs = useMergeRefs(draggableProvided?.innerRef, containerRef);

  const cleanUpInnerState = useCallback(() => setValue(fullText), [fullText]);
  useEffect(cleanUpInnerState, [isEditing, cleanUpInnerState]);
  useRefFocus(inputRef, isEditing);

  return (
    <div
      ref={mergedRefs}
      onDoubleClick={() => onDoubleClick(layer)}
      className={classNames(
        styles.layerItemContainer,
        isHighlighted ? styles.highlighted : undefined,
        isEditing ? styles.editing : undefined,
      )}
      onClick={() => onClick(layer)}
      {...draggableProps}
    >
      <HolderOutlined className={styles.holderIcon} />
      <LayerIcon type={layer.type} />
      <div className={styles.inputContainer}>
        <Input
          ref={inputRef}
          bordered={isEditing ? true : false}
          value={value}
          onChange={e => setValue(e.target.value)}
          onKeyDown={e => onKeyDown(layer, cleanUpInnerState, e)}
        />
      </div>

      <Space className={styles.hoverActions} size="small">
        <ToggleLayerVisibility
          layer={layer}
          onClick={onToggleLayerVisibility}
        />
        <ToggleLayerLock layer={layer} onClick={onToggleLayerLock} />
        <DeleteLayer layer={layer} onClick={onDeleteLayer} />
      </Space>
    </div>
  );
};

export default LayerItem;
