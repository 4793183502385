import { FC } from "react";
import {
  IAccount,
  IAccountPosition,
  IAccountRecord,
} from "shared/types/accountManagement";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Row, Input, Button, Modal, Select, Form } from "antd";

import "./AssetLauncherPanel.scss";

interface IAssetLauncherPanelPositioningTabProps {
  dealerResult: IAccount | null | undefined;
  mouseHoverIndex: number | null;
  showNewPositionPresetModal: boolean;
  posIndexToEditOrDelete: number | null;
  dealerAsIDealerRecord: IAccountRecord | null;
  newPositionPreset: IAccountPosition;
  showDeletePosModal: boolean;
}

interface IAssetLauncherPanelPositioningTabHandlers {
  setNewPositionPreset: (newPositionPreset: IAccountPosition) => void;
  setPosIndexToEditOrDelete: (posIndexToEditOrDelete: number | null) => void;
  toggleShowNewPositionPresetModal: (
    showNewPositionPresetModal: boolean,
  ) => void;
  setMouseHoverIndex: (mouseHoverIndex: number | null) => void;
  toggleShowDeletePosModal: (showDeletePosModal: boolean) => void;
  updateDealer: (
    inputDealer: IAccountRecord,
    updatedData?: Partial<IAccount>,
  ) => void;
}

const AssetLauncherPanelPositioningTab: FC<
  IAssetLauncherPanelPositioningTabProps &
    IAssetLauncherPanelPositioningTabHandlers
> = ({
  dealerResult,
  mouseHoverIndex,
  showNewPositionPresetModal,
  posIndexToEditOrDelete,
  dealerAsIDealerRecord,
  newPositionPreset,
  showDeletePosModal,
  setMouseHoverIndex,
  setNewPositionPreset,
  setPosIndexToEditOrDelete,
  toggleShowNewPositionPresetModal,
  toggleShowDeletePosModal,
  updateDealer,
}) => {
  const positionModifiersList = [
    "Before CSS Selector As Sibling",
    "After CSS Selector As Sibling",
    "After CSS Selector As First Child",
    "After CSS Selector As Last Child",
  ];

  return (
    <div className="positioning-tab">
      <Button
        className="positon-preset-button"
        type="dashed"
        size="large"
        onClick={() => {
          setNewPositionPreset({
            name: "",
            relativePath: "",
            location: "",
            mobileLocation: "",
            positionModifer: "",
            mobilePositionModifer: "",
          });
          setPosIndexToEditOrDelete(null);
          toggleShowNewPositionPresetModal(true);
        }}
      >
        + Position Preset
      </Button>
      {(dealerResult?.web_int_positions || []).map(
        (pos: IAccountPosition, index) => {
          return (
            <div
              key={`${pos.name}-${index}`}
              className={`asset-position-card ${pos.name}`}
              onMouseEnter={() => {
                setMouseHoverIndex(index);
              }}
              onMouseLeave={() => {
                setMouseHoverIndex(null);
              }}
              style={{
                position: "relative",
                height: "200px",
              }}
            >
              <div
                key={`${pos.name}-${index}`}
                className="asset-position-card-info"
                style={{
                  position: "absolute",
                }}
              >
                {[
                  { label: "Name", key: "name" },
                  { label: "Relative Path", key: "relativePath" },
                  { label: "Desktop CSS Selector", key: "location" },
                  {
                    label: "Desktop Position Modifier",
                    key: "positionModifer",
                  },
                  { label: "Mobile CSS Selector", key: "mobileLocation" },
                  {
                    label: "Mobile Position Modifier",
                    key: "mobilePositionModifer",
                  },
                ].map(row => {
                  if (!pos[row.key as keyof IAccountPosition]) return null;
                  return (
                    <div
                      key={`${row.key}-key`}
                      style={{
                        display: "flex",
                        paddingBottom: "10px",
                        lineHeight: "1",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "13px",
                          paddingRight: "3px",
                        }}
                      >
                        {`${row.label}: `}
                      </div>
                      <div style={{ fontSize: "13px" }}>
                        {pos[row.key as keyof IAccountPosition]}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                className="icons-edit-overlay"
                style={{
                  backgroundColor:
                    mouseHoverIndex !== null && index === mouseHoverIndex
                      ? "gray"
                      : "",
                  opacity: "0.75",
                  display:
                    mouseHoverIndex !== null && index === mouseHoverIndex
                      ? "block"
                      : "none",
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  zIndex: 1,
                }}
              >
                <div
                  className="asset-position-card-buttons"
                  style={{
                    marginTop: "30%",
                    fontSize: "2em",
                    color: "white",
                  }}
                >
                  <EditOutlined
                    onClick={() => {
                      setNewPositionPreset({
                        name: pos.name,
                        relativePath: pos.relativePath,
                        location: pos.location,
                        mobileLocation: pos.mobileLocation,
                        positionModifer: pos.positionModifer,
                        mobilePositionModifer: pos.mobilePositionModifer,
                      });
                      setPosIndexToEditOrDelete(index);
                      toggleShowNewPositionPresetModal(true);
                    }}
                  ></EditOutlined>
                  <DeleteOutlined
                    onClick={() => {
                      setPosIndexToEditOrDelete(index);
                      toggleShowDeletePosModal(true);
                    }}
                  ></DeleteOutlined>
                </div>
              </div>
            </div>
          );
        },
      )}

      {showNewPositionPresetModal && (
        <Modal
          title={"Add New Position Preset"}
          visible={showNewPositionPresetModal}
          maskClosable={true}
          onCancel={() => {
            toggleShowNewPositionPresetModal(false);
            setPosIndexToEditOrDelete(null);
          }}
          closable={true}
          footer={[
            [
              <Button
                key="cancel"
                className="secondary-btn"
                onClick={() => {
                  toggleShowNewPositionPresetModal(false);
                  setPosIndexToEditOrDelete(null);
                }}
              >
                Cancel
              </Button>,
              <Button
                disabled={
                  !newPositionPreset.name ||
                  !newPositionPreset.relativePath ||
                  (!newPositionPreset.location &&
                    !newPositionPreset.mobileLocation) ||
                  (!newPositionPreset.positionModifer &&
                    !newPositionPreset.mobilePositionModifer)
                }
                style={{ color: "white" }}
                key="save"
                type="primary"
                onClick={() => {
                  let updatedPositionsArr = [
                    ...(dealerResult?.web_int_positions || []),
                  ];
                  if (posIndexToEditOrDelete === null) {
                    // create
                    updatedPositionsArr = [
                      ...(dealerResult?.web_int_positions || []),
                    ].concat([newPositionPreset]);
                  } else {
                    // update
                    updatedPositionsArr[posIndexToEditOrDelete] =
                      newPositionPreset;
                  }
                  const webPos = { web_int_positions: updatedPositionsArr };
                  dealerAsIDealerRecord &&
                    updateDealer(dealerAsIDealerRecord, webPos);

                  toggleShowNewPositionPresetModal(false);
                  setPosIndexToEditOrDelete(null);
                }}
              >
                Save
              </Button>,
            ],
          ]}
        >
          <Form layout="horizontal" hideRequiredMark={true}>
            <Row gutter={[4, 4]}>
              <Form.Item label="Name">
                <Input
                  style={{ color: "black" }}
                  disabled={false}
                  key="name-text"
                  placeholder="e.g. testpage > below header"
                  onChange={event =>
                    setNewPositionPreset({
                      ...newPositionPreset,
                      name: event.target.value,
                    })
                  }
                  value={newPositionPreset.name}
                />
              </Form.Item>
            </Row>
            <Row gutter={[4, 4]}>
              <Form.Item label="Relative Path">
                <Input
                  key="relative-path-text"
                  placeholder="e.g. /testpage.htm"
                  onChange={event =>
                    setNewPositionPreset({
                      ...newPositionPreset,
                      relativePath: event.target.value,
                    })
                  }
                  value={newPositionPreset.relativePath}
                />
              </Form.Item>
            </Row>
            <Row gutter={[4, 4]}>
              <Form.Item label="Desktop CSS Selector">
                <Input
                  key="location-key"
                  placeholder="e.g. .div-wrapper"
                  onChange={event =>
                    setNewPositionPreset({
                      ...newPositionPreset,
                      location: event.target.value,
                    })
                  }
                  value={newPositionPreset.location}
                />
              </Form.Item>
            </Row>
            <Row gutter={[4, 4]}>
              <Form.Item label="Position Modifier">
                <Select
                  placeholder="select where you want your asset(s) be located on your site"
                  onChange={(value: string) => {
                    setNewPositionPreset({
                      ...newPositionPreset,
                      positionModifer: value,
                    });
                  }}
                  value={newPositionPreset.positionModifer || undefined}
                >
                  {positionModifiersList.map((type: string) => {
                    return (
                      <Select.Option
                        className="template-tag-item"
                        key={`${type}-key`}
                        value={type}
                      >
                        {type}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Row>
            <Row gutter={[4, 4]}>
              <Form.Item label="Mobile CSS Selector">
                <Input
                  key="mobile-location-text"
                  placeholder="e.g. .mobile-container"
                  onChange={event =>
                    setNewPositionPreset({
                      ...newPositionPreset,
                      mobileLocation: event.target.value,
                    })
                  }
                  value={newPositionPreset.mobileLocation}
                />
              </Form.Item>
            </Row>
            <Row gutter={[4, 4]}>
              <Form.Item label="Mobile Position Modifier">
                <Select
                  placeholder="select where you want your asset(s) be located on your site"
                  onChange={(value: string) => {
                    setNewPositionPreset({
                      ...newPositionPreset,
                      mobilePositionModifer: value,
                    });
                  }}
                  value={newPositionPreset.mobilePositionModifer || undefined}
                >
                  {positionModifiersList.map((type: string) => {
                    return (
                      <Select.Option
                        className="template-tag-item"
                        key={`${type}-key`}
                        value={type}
                      >
                        {type}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      )}
      {showDeletePosModal && (
        <Modal
          title={"Position Preset Delete Confirmation"}
          visible={showDeletePosModal}
          maskClosable={true}
          onCancel={() => {
            toggleShowDeletePosModal(false);
            setPosIndexToEditOrDelete(null);
          }}
          closable={true}
          footer={[
            [
              <Button
                key="cancel"
                className="secondary-btn"
                onClick={() => {
                  toggleShowDeletePosModal(false);
                }}
              >
                Cancel
              </Button>,
              <Button
                style={{ color: "white" }}
                key="save"
                type="primary"
                onClick={() => {
                  const updatedPositions =
                    posIndexToEditOrDelete !== null
                      ? dealerResult?.web_int_positions
                          ?.slice(0, posIndexToEditOrDelete)
                          .concat(
                            dealerResult?.web_int_positions?.slice(
                              posIndexToEditOrDelete + 1,
                            ),
                          )
                      : [];
                  const webPos = { web_int_positions: updatedPositions };
                  dealerAsIDealerRecord &&
                    updateDealer(dealerAsIDealerRecord, webPos);
                  setPosIndexToEditOrDelete(null);
                  toggleShowDeletePosModal(false);
                }}
              >
                Yes
              </Button>,
            ],
          ]}
        >
          Are you sure you want to delete this Position preset?
        </Modal>
      )}
    </div>
  );
};

export default AssetLauncherPanelPositioningTab;
