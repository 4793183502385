import { useMemo } from "react";

type ReactRef<T> = React.RefCallback<T> | React.MutableRefObject<T>;

const assignRef = <T = unknown>(ref: ReactRef<T> | null, value: T) => {
  if (!ref) return;

  if (typeof ref === "function") {
    ref(value);
    return;
  }

  try {
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
};

const mergeRefs =
  <T>(...refs: (ReactRef<T> | null)[]) =>
  (node: T | null) => {
    refs.forEach(ref => {
      assignRef(ref, node);
    });
  };

/**
 * Utility hook to merge refs. This is specially useful when a single element needs to be assigned to multiple refs.
 * @example
 * const ref1 = useRef();
 * const ref2 = useRef();
 * const mergedRef = useMergeRefs(ref1, ref2);
 */
export const useMergeRefs = <T>(...refs: (ReactRef<T> | null)[]) =>
  useMemo(() => mergeRefs(...refs), [refs]);
