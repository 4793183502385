import { Key } from "react";
import moment from "moment";
import {
  GoogleAdCampaignInternalForm,
  GoogleAdCampaignInternal,
} from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";
import { add, differenceInCalendarDays, lastDayOfMonth } from "date-fns";

export const isPastDate = (
  newDate?: moment.Moment,
  currentDate?: moment.Moment,
) => {
  if (!newDate) return false;
  const dateToCompareTo = currentDate ? currentDate : moment();
  return newDate.isBefore(dateToCompareTo.startOf("day"));
};

export const createOptions = (options: string[] | undefined | null) => {
  if (!options) return [];
  return options.map(option => ({ value: option, label: option }));
};

const knownGeoTargets = new Map([
  ["United Kingdom", "geoTargetConstants/2826"],
  ["Turkey", "geoTargetConstants/2792"],
  ["Spain", "geoTargetConstants/2724"],
  ["Italy", "geoTargetConstants/2380"],
  ["India", "geoTargetConstants/2356"],
  ["France", "geoTargetConstants/2250"],
  ["Austria", "geoTargetConstants/2040"],
]);

export const initialFormValues: GoogleAdCampaignInternalForm = {
  id: "",
  name: "",
  radius: 20,
  radiusUnit: "mi",
  urlExclusions: [],
  locationTarget: "",
  dailyBudget: "",
  totalBudget: "",
  rulesetName: "",
  excludeLocations: Array.from(knownGeoTargets.values()),
};

export const toInternalGoogleAdCampaignForm = (
  campaignData: GoogleAdCampaignInternal,
): { form: GoogleAdCampaignInternalForm; assetGroupIds: Key[] } => {
  const { assetGroupIds, ...form } = campaignData;
  return {
    assetGroupIds: assetGroupIds ?? [],
    form,
  };
};

export const toInternalGoogleAdCampaign = (
  formData: GoogleAdCampaignInternalForm,
  assetGroupIds: Key[],
): GoogleAdCampaignInternal => {
  return {
    ...formData,
    assetGroupIds: assetGroupIds.map((id: Key) => `${id}`),
  };
};

export const getDaysBetweenDates = (startDate?: Date, endDate?: Date) => {
  if (!startDate) return 30;
  const endDateToUse = add(endDate ? endDate : lastDayOfMonth(startDate), {
    days: 1,
  });
  return differenceInCalendarDays(endDateToUse, startDate);
};

export const calcDailyBudget = (
  totalBudget: number,
  startDate?: Date,
  endDate?: Date,
) => {
  const days = getDaysBetweenDates(startDate, endDate);
  return (totalBudget / days).toFixed(2);
};

export const getDateToUse = (dateString?: string) => {
  if (!dateString) return undefined;
  return moment(dateString).toDate();
};

export const numberInputFormatter = (value?: string) =>
  value ? `$ ${value}` : "$";

export const dateStringToMoment = (dateString?: string) => {
  if (!dateString) return undefined;
  return moment(dateString);
};

export const hasCompleteAddress = (account: IAccount) => {
  return Boolean(
    account.address && account.city && account.state && account.zip,
  );
};

export const getAccountsWithFullAddress = (accounts: IAccount[]) =>
  accounts.filter(hasCompleteAddress).map(account => account.dealer_name);
