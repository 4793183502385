import { useQuery } from "react-query";
import API from "services";
import {
  QUERY_KEY_ASSET_BATCHES,
  TAssetBatch,
} from "shared/types/assetExporter";

const getAssetBatches = async (feedId: string) =>
  await API.services.assetExporter.getAssetBatch(feedId);

const useGetAssetBatches = (feedId: string, onSuccess?: () => void) => {
  return useQuery<TAssetBatch[], Error>(
    [QUERY_KEY_ASSET_BATCHES, feedId],
    () => getAssetBatches(feedId),
    {
      enabled: !!feedId,
      onSuccess,
    },
  );
};

export default useGetAssetBatches;
