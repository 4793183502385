import { Button, Col, Row } from "antd";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import {
  TValueMapping,
  TVariable,
} from "screens/assetExporter/feedConfigurationV2/shared/types";
import { useDataList } from "shared/components/dataList";
import { useLogoSelectContext } from "shared/components/logoSelect/context/LogoSelectContext";
import { BrandLogo } from "shared/types/brandsAccounts";
import { FieldTitle } from "../../shared/components/FieldTitle";
import { isLogoValue } from "../../shared/validators";
import styles from "./VariableInput.module.scss";
import { useHightlightVarEvents } from "./hooks/useHighlightVarEvents";
import { useCallback } from "react";
import { useAssetBatchesCompositionContext } from "../../shared/contexts/AssetBatchesCompositionContext";

type LogoVariableInputProps = {
  variable: TVariable;
  valueMapping?: TValueMapping;
  mappingKey: string;
};

export const LogoVariableInput = ({
  variable,
  valueMapping,
  mappingKey,
}: LogoVariableInputProps) => {
  const { templatesToUse: templates } = useAssetBatchesContext();
  const { onLogoSelect, editingComposition } =
    useAssetBatchesCompositionContext();
  const template = templates.find(
    temp => editingComposition.template === temp.id,
  );
  const { onMouseEnter, onMouseLeave, onValueChange } =
    useHightlightVarEvents(mappingKey);
  const { setShowLogoSelectDrawer, setOnSelect } = useLogoSelectContext();
  const [, dataListActions] = useDataList<BrandLogo>();

  const setLogoSelectFilters = useCallback(() => {
    dataListActions.resetFilters();
    const filters: Record<string, string[]> = {
      brand: [...(template?.oems ?? [])],
      account: [...(template?.stores ?? [])],
      logoType: variable?.logoData?.logoType
        ? [variable.logoData.logoType.toString()]
        : [],
      artboardName: variable?.logoData?.artboardName
        ? [variable.logoData.artboardName.toString()]
        : [],
    };
    const updatedFilters = Object.keys(filters).reduce(
      (acc: Record<string, string[]>, key: string) => {
        if (filters[key].length) return { ...acc, [key]: filters[key] };
        return acc;
      },
      {},
    );
    dataListActions.setFilters(updatedFilters);
  }, [dataListActions, template, variable]);

  const showLogoDrawer = useCallback(() => {
    setShowLogoSelectDrawer(true);
    setLogoSelectFilters();
    setOnSelect(() => (logo: BrandLogo) => {
      onLogoSelect(mappingKey, logo);
      const hasValue =
        (valueMapping?.value && isLogoValue(valueMapping.value)) || false;
      onValueChange(mappingKey, hasValue);
    });
    if (valueMapping?.value && isLogoValue(valueMapping.value)) {
      dataListActions.setSelectedItems([valueMapping.value.id]);
    }
  }, [
    setShowLogoSelectDrawer,
    setLogoSelectFilters,
    setOnSelect,
    onLogoSelect,
    mappingKey,
    valueMapping,
    onValueChange,
    dataListActions,
  ]);

  const displayName = isLogoValue(valueMapping?.value)
    ? `${valueMapping?.value.name} - ${valueMapping?.value.logoType} - ${valueMapping?.value.artboardName}`
    : "";

  const variableName =
    variable.variable !== ""
      ? variable.variable
      : `${variable.logoData?.logoType} - ${variable.logoData?.artboardName} Logo`;

  return (
    <>
      <FieldTitle title={variableName} />
      <Row
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={styles.row}
        justify="space-around"
        align="top"
      >
        <Col span={24}>
          <Button onClick={showLogoDrawer}>
            {valueMapping?.value ? "Change" : "Select"}
          </Button>
          <span className={styles.logoSelectName}>{displayName}</span>
        </Col>
      </Row>
    </>
  );
};
