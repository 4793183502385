import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Radio, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { formatDistance, formatDistanceToNow } from "date-fns";
import { forwardRef, useMemo, useRef, useState } from "react";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { FiltersType } from "screens/assetExporter/feedConfigurationV2/shared/types";
import VirtualTable from "shared/components/VirtualTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { ITemplate } from "shared/types/designStudio";
import styles from "./TemplateList.module.scss";
import useGetTemplatePreviewUrl from "screens/assetExporter/feedConfigurationV2/shared/hooks/useGetTemplatePreviewUrl";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { DataListStatusCell } from "shared/components/dataList/DataListStatusCell";
import ImagePreview from "shared/components/imagePreviewOverlay/ImagePreview";
import { compareStringBy } from "utils/helpers.table";
import {
  TFilterOption,
  disable,
  filter,
  filterByDateRange,
  toDimensionStr,
} from "./TemplateList.utils";
import FilterSection from "./templateList/FilterSection";
import IframePreview from "./templateList/IframePreview";
import Search from "./templateList/Search";
import SearchDateRange from "./templateList/SearchDateRange";
import SearchInput from "./templateList/SearchInput";
import TagColumn from "shared/components/TagColumn";

const DEFAULT_COLUMN_WIDTH = 150;
const DEFAULT_DISABLED_TOOLTIP_MSG =
  "templates contining stamps are not supported";
const DRAFT_DISABLED_TOOLTIP_MSG = "cannot select a template in draft";

const getNameStyle = (record: ITemplate) => {
  const { thumbnailUrl, status, type } = record;
  const isSelectable = status === "PUBLISHED";
  if (!thumbnailUrl && isSelectable && type !== "html")
    return { color: "black" };

  return undefined;
};

interface Props {
  setFilters: React.Dispatch<React.SetStateAction<Partial<FiltersType>>>;
  filters: Partial<FiltersType>;
  templates: ITemplate[];
  isFetching: boolean;
  filterOptions: TFilterOption;
}

const TemplateList = ({
  filters,
  setFilters,
  templates,
  isFetching,
  filterOptions,
}: Props) => {
  const { windowInnerHeight, windowInnerWidth } = useWindowSize();
  const context = useAssetBatchesContext();

  const [searchBy, setSearchBy] = useState<string>();
  const nameSearchInputRef = useRef<Input>(null);

  const [previewTemplate, setPreviewTemplate] = useState<ITemplate>();

  const { data: previewUrl } = useGetTemplatePreviewUrl(previewTemplate);

  const onClose = () => setPreviewTemplate(undefined);

  const templateNameWidth =
    windowInnerWidth > 1400
      ? DEFAULT_COLUMN_WIDTH + 100
      : windowInnerWidth / 3.5;

  const columns: ColumnsType<ITemplate> = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      fixed: "left",
      width: templateNameWidth,
      render: (value, record) => {
        const { thumbnailUrl } = record;
        const isDisabled =
          (!thumbnailUrl && record.type !== "html") ||
          (record.type === "html" && !record.id) ||
          disable(record, context.templatesDataFilters);

        return (
          <Button
            disabled={isDisabled}
            style={getNameStyle(record)}
            className={styles.colName}
            type={isDisabled ? "text" : "link"}
            onClick={() => {
              setPreviewTemplate(record);
            }}
          >
            <AutoEllipsisText>{value}</AutoEllipsisText>
          </Button>
        );
      },
      filteredValue: filters.name,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterDropdown: args => (
        <SearchInput {...args} ref={nameSearchInputRef} />
      ),
      onFilterDropdownVisibleChange: visible => {
        if (!visible) return;

        setTimeout(() => {
          nameSearchInputRef.current?.focus();
        }, 100);
      },
      sorter: compareStringBy("name"),
    },
    {
      title: "Status",
      dataIndex: "status",
      width: DEFAULT_COLUMN_WIDTH,
      filters: filterOptions.status.map(type => ({
        text: type,
        value: type,
      })),
      filteredValue: filters.status,
      sorter: compareStringBy("status"),
      render: (_, item) => (
        <DataListStatusCell
          item={item.status}
          isDisabled={disable(item, context.templatesDataFilters)}
        />
      ),
    },
    {
      title: "Asset type",
      dataIndex: "assetType",
      width: DEFAULT_COLUMN_WIDTH,
      filteredValue: filters.assetType,
      filters: filterOptions.assetType.map(type => ({
        text: type,
        value: type,
      })),
      sorter: compareStringBy("assetType"),
    },
    {
      title: "Template type",
      dataIndex: "type",
      width: DEFAULT_COLUMN_WIDTH,
      filteredValue: filters.type,
      filters: filterOptions.type.map(type => ({
        text: type,
        value: type,
      })),
      sorter: compareStringBy("type"),
    },
    {
      title: "Dimensions",
      dataIndex: "dimension",
      width: DEFAULT_COLUMN_WIDTH,
      render: (val, record) => toDimensionStr(record) || "-",
      filteredValue: filters.dimension,
      filters: filterOptions.dimension.map(d => ({ text: d, value: d })),
      sorter: compareStringBy(dim => toDimensionStr(dim)),
    },
    {
      key: "artboardName",
      title: "Artboard name",
      dataIndex: "artboardName",
      width: DEFAULT_COLUMN_WIDTH,
      render: (_, record) => record.artboard.name,
      filteredValue: filters.artboardName,
      filters: filterOptions.artboardName.map(d => ({ text: d, value: d })),
      sorter: compareStringBy(template => template.artboard.name),
    },
    {
      title: "Accounts",
      dataIndex: "stores",
      filteredValue: filters.stores,
      filters: filterOptions.stores.map(store => ({
        text: store,
        value: store,
      })),
      width: DEFAULT_COLUMN_WIDTH,
      render: (_, item) => <TagColumn tags={item.stores} />,
      sorter: compareStringBy(record => record.stores?.join(", ")),
    },
    {
      title: "Brands",
      dataIndex: "oems",
      width: DEFAULT_COLUMN_WIDTH,
      filteredValue: filters.oems,
      filters: filterOptions.oems.map(oem => ({
        text: oem,
        value: oem,
      })),
      render: (_, item) => <TagColumn tags={item.oems} />,
      sorter: compareStringBy(record => record.oems?.join(", ")),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      width: DEFAULT_COLUMN_WIDTH,
      render: (val, record) => {
        const { createdAt } = record;
        const dist = createdAt ? formatDistanceToNow(createdAt) : "-";
        return `${dist} ago`;
      },
      defaultSortOrder: "ascend",
      sorter: (a, b) => {
        if (!a.createdAt || !b.createdAt) return 0;

        return b.createdAt - a.createdAt;
      },
      filteredValue: filters.createdAt,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterDropdown: args => <SearchDateRange {...args} />,
      onFilter: (value, record) =>
        filterByDateRange(record.createdAt, value as string),
    },
    {
      title: "Last edited",
      dataIndex: "lastEdited",
      width: DEFAULT_COLUMN_WIDTH,
      render: (val, record) => {
        const { lastUpdatedAt } = record;
        if (!lastUpdatedAt) return "-";

        const dateDesc = formatDistance(lastUpdatedAt, Date.now());
        return `${dateDesc} ago`;
      },
      filteredValue: filters.lastUpdatedAt,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      filterDropdown: args => <SearchDateRange {...args} />,
      onFilter: (value, record) =>
        filterByDateRange(record.lastUpdatedAt, value as string),
      sorter: (a, b) => {
        const { lastUpdatedAt: aDate } = a;
        const { lastUpdatedAt: bDate } = b;
        if (!aDate || !bDate) return 0;

        return bDate - aDate;
      },
    },
    {
      title: "# Stamps",
      dataIndex: "stamps",
      width: DEFAULT_COLUMN_WIDTH,
      render: (_, record) => record.numOfStamps,
      sorter: (r1, r2) => {
        if (r1.numOfStamps === r2.numOfStamps) {
          return 0;
        }

        return r2.numOfStamps > r1.numOfStamps ? 1 : -1;
      },
    },
    {
      title: "Tags",
      dataIndex: "tags",
      width: DEFAULT_COLUMN_WIDTH,
      render: (_, item) => <TagColumn tags={item.tags} />,
      filteredValue: filters.tags,
      filters: filterOptions.tags.map(tag => ({ text: tag, value: tag })),
    },
  ];

  const dataSource = useMemo(
    () => filter(templates, searchBy, filters),
    [templates, searchBy, filters],
  );

  const [hoveredRowId, setHoveredRowId] = useState<string>();
  const [disabledRowTooltipMsg, setDisabledRowTooltipMsg] = useState<string>(
    DEFAULT_DISABLED_TOOLTIP_MSG,
  );

  const [expand, setExpand] = useState(false);
  const onToggleDensity = () => {
    setExpand(prev => !prev);
  };

  // eslint-disable-next-line react/display-name
  const Row = forwardRef<HTMLTableRowElement, { className: string }>(
    (props, ref) => {
      const { children, ...rest } = props;

      const isDisabled = rest.className.includes("disabled");
      const id = isDisabled
        ? rest.className?.split(" ").pop()?.split("_").pop() // first split class names and then last split will extract "row id" from "id-<row id>"
        : undefined;
      return isDisabled ? (
        <Tooltip
          mouseEnterDelay={5}
          title={disabledRowTooltipMsg}
          placement="topLeft"
          visible={hoveredRowId === id}
        >
          <tr {...rest} ref={ref}>
            {children}
          </tr>
        </Tooltip>
      ) : (
        <tr {...rest} ref={ref}>
          {children}
        </tr>
      );
    },
  );

  return (
    <div className={styles.container}>
      <Search
        onChange={newSearchBy => setSearchBy(newSearchBy)}
        onToggleExpand={onToggleDensity}
      />
      <FilterSection<ITemplate>
        showFilteredCount={!!searchBy || Object.keys(filters).length > 0}
        dataSource={templates}
        currentDataSource={dataSource}
        filters={filters}
        onRemove={(key, value) => {
          setFilters(prev => {
            const currentKeyFilters = prev[key]?.filter(
              prevValue => prevValue !== value,
            );

            return {
              ...prev,
              [key]: !currentKeyFilters?.length ? null : currentKeyFilters,
            };
          });
        }}
        onClear={() =>
          setFilters(prev =>
            Object.keys(prev).reduce(
              (acc, key) => ({ ...acc, [key]: null }),
              {},
            ),
          )
        }
      />
      <VirtualTable<ITemplate>
        size={expand ? "large" : "small"}
        pagination={false}
        components={{
          body: {
            row: Row,
          },
        }}
        onRow={record => ({
          onMouseEnter: () => {
            setHoveredRowId(record.id);
            if (record.status === "UN-PUBLISHED")
              setDisabledRowTooltipMsg(DRAFT_DISABLED_TOOLTIP_MSG);
            else setDisabledRowTooltipMsg(DEFAULT_DISABLED_TOOLTIP_MSG);
          },
          onMouseLeave: () => setHoveredRowId(undefined),
        })}
        rowSelection={{
          type: "radio",
          selectedRowKeys: context.selectedTemplate?.id
            ? [context.selectedTemplate.id]
            : [],

          onSelect: record => {
            context.setSelectedTemplate(record);
          },
          getCheckboxProps: record => ({
            disabled: disable(record, context.templatesDataFilters),
          }),
          renderCell: (_, record) => (
            <div onClick={e => e.stopPropagation()}>
              <Radio
                data-cy="template-radio"
                checked={context.selectedTemplate?.id === record.id}
                disabled={disable(record, context.templatesDataFilters)}
                onChange={() => context.setSelectedTemplate(record)}
              />
            </div>
          ),
        }}
        scroll={{ y: windowInnerHeight - 205 }}
        rowKey={record => record.id!}
        columns={columns}
        dataSource={dataSource}
        loading={isFetching}
        onChange={(_, newFilters) => setFilters(newFilters)}
        rowClassName={record =>
          disable(record, context.templatesDataFilters)
            ? `${styles.disabledRow} disabled id_${record.id}`
            : ""
        }
        bordered
      />
      {previewUrl && previewTemplate?.type !== "html" && (
        <ImagePreview url={previewUrl} onClose={onClose} />
      )}
      {previewUrl && previewTemplate?.type === "html" && (
        <IframePreview
          iframePreview={{ template: previewTemplate, url: previewUrl }}
          onClose={onClose}
        />
      )}
    </div>
  );
};

export default TemplateList;
