import { uniq } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useGetAssetPermissionsMutation } from "redux/media/media.api";

export const useFetchPerms = (folderIds: string[], visible: boolean) => {
  const [perms, setPerms] = useState<string[]>();
  const [getAssetPermissions] = useGetAssetPermissionsMutation();
  const canEdit = !!perms?.includes("edit");
  const canDelete = !!perms?.includes("delete");
  const canMove = !!perms?.includes("move");
  const canRename = !!perms?.includes("move");
  const canDownload = !!perms?.includes("download");
  const ids = useMemo(() => uniq(folderIds).join(","), [folderIds]);

  useEffect(() => {
    if (visible) {
      getAssetPermissions(ids).unwrap().then(setPerms);
    }
  }, [getAssetPermissions, ids, visible]);

  return {
    canDelete,
    canDownload,
    canEdit,
    canMove,
    canRename,
  };
};
