import { FONT_TYPES, IMG_TYPES, VIDEO_TYPES } from "./constants";

export const isFontType = (filetype: string) => {
  return FONT_TYPES.includes(filetype);
};

const isImageType = (filetype: string) => {
  return IMG_TYPES.includes(filetype);
};

const isVideoType = (filetype: string) => {
  return VIDEO_TYPES.includes(filetype);
};

export const isValidAssetTypeToPreview = ({ filetype }: WDAsset) => {
  return isImageType(filetype) || isVideoType(filetype);
};
