import { ReactComponent as LithiaIcon } from "statics/images/svg/icons/lithia-icon.svg";
import { ReactComponent as NissanUnitedIcon } from "statics/images/svg/icons/nu-icon.svg";
import { ReactComponent as BearabyIcon } from "statics/images/svg/icons/bearaby-icon.svg";
import { ReactComponent as IhgIcon } from "statics/images/svg/icons/ihg-icon.svg";
import { ReactComponent as GatorCasesIcon } from "statics/images/svg/icons/gatorcases-icon.svg";
import { ReactComponent as HenryRoseIcon } from "statics/images/svg/icons/henryrose-icon.svg";
import { ReactComponent as TrueCarIcon } from "statics/images/svg/icons/truecar-icon.svg";
import { ReactComponent as KlarnCoIcon } from "statics/images/svg/icons/klarnco-icon.svg";
import { ReactComponent as AutonomyIcon } from "statics/images/svg/icons/autonomy-icon.svg";
import { ReactComponent as BiohavenIcon } from "statics/images/svg/icons/biohaven-icon.svg";
import { ReactComponent as FrameIcon } from "statics/images/svg/icons/frame-icon.svg";
import { ReactComponent as SaatvaIcon } from "statics/images/svg/icons/saatva-icon.svg";
import { ReactComponent as GreenCarsIcon } from "statics/images/svg/icons/greencars-icon.svg";
import { ReactComponent as BgdIcon } from "statics/images/svg/icons/bgd-icon.svg";
import { ReactComponent as SanofiIcon } from "statics/images/svg/icons/sanofi-icon.svg";
import { ReactComponent as AmnealIcon } from "statics/images/svg/icons/amneal-icon.svg";
import { ReactComponent as GskIcon } from "statics/images/svg/icons/gsk-icon.svg";
import { ReactComponent as RytaryIcon } from "statics/images/svg/icons/rytary-icon.svg";
import { ReactComponent as LondreIcon } from "statics/images/svg/icons/londre-icon.svg";
import { ReactComponent as HeimatIcon } from "statics/images/svg/icons/heimat-icon.svg";
import { ReactComponent as BYDIcon } from "statics/images/svg/icons/byd-icon.svg";
import { ReactComponent as ADCIcon } from "statics/images/svg/icons/adc-icon.svg";
import { ReactComponent as PhillipLimIcon } from "statics/images/svg/icons/philliplim-icon.svg";
import { ReactComponent as AflacIcon } from "statics/images/svg/icons/aflac-icon.svg";
import { ReactComponent as EsperionIcon } from "statics/images/svg/icons/esperion-icon.svg";

const clientName = process.env.REACT_APP_AV2_CLIENT || "internal";

interface IProps {
  width?: string | number;
  height?: string | number;
}

const ClientIcons = ({ width = "24px", height = "24px" }: IProps) => {
  const style = { marginRight: 10 };
  const clientNameToUse = () => {
    switch (clientName) {
      case "autonomy":
        return <AutonomyIcon width={72} height={72} style={style} />;
      case "aflac":
        return <AflacIcon width={72} height={72} style={style} />;
      case "amneal":
        return <AmnealIcon width={100} height={76} style={style} />;
      case "bearaby":
        return <BearabyIcon width={width} height={height} />;
      case "biohaven":
        return <BiohavenIcon width={72} height={72} style={style} />;
      case "bgd":
        return <BgdIcon width={64} height={64} style={style} />;
      case "frame":
        return <FrameIcon width={64} height={64} style={style} />;
      case "gatorcases":
        return <GatorCasesIcon width={72} height={72} />;
      case "greencars":
        return <GreenCarsIcon width={96} height={96} style={style} />;
      case "gsk":
        return <GskIcon width={72} height={72} style={style} />;
      case "heimat":
        return <HeimatIcon width={100} height={40} />;
      case "henry-rose":
        return <HenryRoseIcon width={96} height={96} />;
      case "ihg":
        return <IhgIcon width={72} height={72} />;
      case "klarnco":
        return <KlarnCoIcon width={56} height={56} style={style} />;
      case "ladtech":
        return <LithiaIcon width={width} height={height} />;
      case "londre":
        return <LondreIcon width={80} height={30} />;
      case "nu":
        return <NissanUnitedIcon width={width} height={height} />;
      case "rytary":
        return <RytaryIcon width={72} height={72} />;
      case "sanofi":
        return <SanofiIcon width={72} height={56} />;
      case "saatva":
        return <SaatvaIcon width={56} height={56} style={style} />;
      case "truecar":
        return <TrueCarIcon width={72} height={72} />;
      case "byd":
        return <BYDIcon width={72} height={72} />;
      case "adc":
        return <ADCIcon width={72} height={72} />;
      case "philliplim":
        return <PhillipLimIcon width={72} height={72} />;
      case "esperion":
        return <EsperionIcon width={72} height={72} />;
    }
  };

  return <>{clientNameToUse()}</>;
};

export default ClientIcons;
