import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { compareString } from "utils/helpers";
import { CampaignPlannerFieldKeys } from "./fields";
import {
  CampaignPlanner,
  CampaignPlannerCondition,
  CampaignPlannerInstance,
  CampaignPlannerStatus,
} from "../../types";
import EditableCell, {
  EditableCellType,
} from "shared/components/dataList/EditableCell";
import { Link } from "react-router-dom";
import { ConditionMap, PlannerStatusMap, isNewItem } from "../../utils";
import moment from "moment";
import { dateFormat } from "shared/constants/dataManagement";
import { EditableColumnParameters } from "shared/components/dataList/setupEditableCells";
import { Badge } from "antd";

export type CampaignPlannerColumn<
  TableItem extends CampaignPlanner | CampaignPlannerInstance = CampaignPlanner,
> = ITableColumnURL<CampaignPlannerFieldKeys, TableItem> &
  EditableColumnParameters<TableItem>;

export const getColumns = <
  TableItem extends CampaignPlanner | CampaignPlannerInstance = CampaignPlanner,
>(
  onEditEnd: (record: TableItem) => void,
  parentRecord?: CampaignPlanner,
): CampaignPlannerColumn<TableItem>[] => {
  const getLink = (record: TableItem) =>
    parentRecord
      ? `./${parentRecord.id}/instance/${record.id}`
      : `./${record.id}`;
  return [
    {
      editable: (record: TableItem) => !isNewItem(record),
      title: "Name",
      dataIndex: ["name"],
      key: "name",
      width: 300,
      sorter: (a: TableItem, b: TableItem) => {
        return compareString(a.name?.trim(), b.name?.trim());
      },
      render: (name, record) => {
        return isNewItem(record) ? (
          <EditableCell<TableItem>
            editable
            title="name"
            record={record}
            key="name"
            dataIndex={["name"]}
            isEditing
            handleSave={onEditEnd}
          />
        ) : (
          <Link to={getLink(record)}>{name}</Link>
        );
      },
    },
    {
      title: "Ad Shells",
      dataIndex: ["adShells"],
      key: "adShells",
      width: 100,
      render: (_, record) => {
        return record.adShells.length || 0;
      },
    },
    {
      title: "Instances",
      dataIndex: ["instances"],
      key: "instances",
      width: 100,
      render: instances => {
        return parentRecord ? "-" : instances?.length || 0;
      },
    },
    {
      editable: true,
      title: "Condition",
      dataIndex: ["condition"],
      width: 200,
      key: "condition",
      render: (_: CampaignPlannerCondition, record) => {
        return (
          <span>
            <Badge color={ConditionMap[record.condition]?.color} />
            {ConditionMap[record.condition]?.text}
          </span>
        );
      },
      editableCellType: EditableCellType.Select,
      selectOptions: [
        {
          key: CampaignPlannerCondition.ACTIVE,
          value: CampaignPlannerCondition.ACTIVE,
          text: ConditionMap.ACTIVE.text,
        },
        {
          key: CampaignPlannerCondition.IN_PROGRESS,
          value: CampaignPlannerCondition.IN_PROGRESS,
          text: ConditionMap.IN_PROGRESS.text,
        },
        {
          key: CampaignPlannerCondition.EXPIRED,
          value: CampaignPlannerCondition.EXPIRED,
          text: ConditionMap.EXPIRED.text,
        },
      ],
    },
    {
      editable: true,
      title: "Status",
      dataIndex: ["status"],
      width: 150,
      key: "status",
      render: (status: CampaignPlannerStatus) => {
        return (
          <span>
            <Badge color={PlannerStatusMap[status]?.color} />
            {PlannerStatusMap[status]?.text}
          </span>
        );
      },
      editableCellType: EditableCellType.Select,
      selectOptions: parentRecord ? instanceStatuses : plannerStatuses,
    },
    {
      title: "Created Date",
      editable: false,
      width: 150,
      dataIndex: ["createdAt"],
      render: (date: string) => moment(date).format(dateFormat),
      key: "createdAt",
    },
    {
      editable: false,
      title: "Last Edit",
      width: 150,
      render: (date: string) => moment(date).format(dateFormat),
      dataIndex: ["updatedAt"],
      key: "updatedAt",
    },
    {
      title: "Created By",
      editable: false,
      width: 300,
      dataIndex: ["createdBy"],
      key: "createdBy",
    },
    {
      title: "Last Edited By",
      editable: false,
      width: 300,
      dataIndex: ["updatedBy"],
      key: "updatedBy",
    },
  ];
};

const plannerStatuses = [
  {
    value: CampaignPlannerStatus.DRAFT,
    key: CampaignPlannerStatus.DRAFT,
    text: PlannerStatusMap.DRAFT.text,
  },
  {
    key: CampaignPlannerStatus.AWAITING_APPROVAL,
    value: CampaignPlannerStatus.AWAITING_APPROVAL,
    text: PlannerStatusMap.AWAITING_APPROVAL.text,
  },
  {
    key: CampaignPlannerStatus.INTERNALLY_APPROVED,
    value: CampaignPlannerStatus.INTERNALLY_APPROVED,
    text: PlannerStatusMap.INTERNALLY_APPROVED.text,
  },
  {
    key: CampaignPlannerStatus.DISAPPROVED,
    value: CampaignPlannerStatus.DISAPPROVED,
    text: PlannerStatusMap.DISAPPROVED.text,
  },
];

const instanceStatuses = [
  {
    key: CampaignPlannerStatus.INTERNALLY_APPROVED,
    value: CampaignPlannerStatus.INTERNALLY_APPROVED,
    text: PlannerStatusMap.INTERNALLY_APPROVED.text,
  },
  {
    key: CampaignPlannerStatus.HAS_REQUESTS,
    value: CampaignPlannerStatus.HAS_REQUESTS,
    text: PlannerStatusMap.HAS_REQUESTS.text,
  },
];
