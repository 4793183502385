import { createStore } from "polotno/model/store";

type PolotnoFont = {
  fontFamily: string;
  url: string;
};

type PolotnoPage = {
  id: string;
  children: any[];
  width: number;
  height: number;
  bleed: number;
  duration: number;
  background: string;
};

export type PolotnoJSON = {
  width: number;
  height: number;
  fonts: PolotnoFont[];
  pages: PolotnoPage[];
  unit: string;
  dpi: number;
};

const apiKey = process.env.REACT_APP_POLOTNO_EDITOR_APIKEY;
const storeConfig = apiKey ? { key: apiKey, showCredit: false } : undefined;

// Store is a singleton, for now. We could create it on demand but so far it is not needed.
const store = createStore(storeConfig);

const getBase64Thumbnail = async () => {
  const dataUrl = await store.toDataURL();

  return dataUrl.replace(/^data:image\/(jpg|jpeg|png);base64,/, "");
};

const toJSON = () => {
  // Polotno's types are not great yet, so we're casting to our own type for this MVP.
  return store.toJSON() as unknown as PolotnoJSON;
};

export const usePolotnoStore = () => {
  return {
    store,
    toJSON,
    getBase64Thumbnail,
  };
};
