import { isFeatureEnabled } from "utils/helpers";
import { dedupe } from "utils/helpers.array";

import { AdType, IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { Filters } from "../hooks/usePublicAdReviewState";
import { last } from "lodash";
import { IPagination } from "shared/types/adReview";
import moment from "moment-timezone";
import {
  IInstantExperience,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import { AD_NAME_DELIMITER } from "./helpers.table";

type AdNameArgs = {
  name?: string;
  delimiter: string;
};

export const getAdNamePart = ({ name, delimiter }: AdNameArgs) => {
  const namePart = name?.split(delimiter);
  return namePart?.[namePart.length - 1] ?? name;
};

export const extractAdName = ({ name, delimiter }: AdNameArgs) => {
  return isFeatureEnabled("ENABLE_AD_TO_LOAD_CONCAT_NAME")
    ? name
    : getAdNamePart({ name, delimiter });
};

type GetAdNameArgs = AdNameArgs & {
  adsetName?: string;
};
// Possible future TODO: make ad naming dynamic based on selected taxonomy
export const getAdName = ({ name, delimiter, adsetName }: GetAdNameArgs) => {
  if (isFeatureEnabled("ENABLE_AD_TO_LOAD_CONCAT_NAME")) {
    const adName = adsetName ? name?.split(adsetName)[1] : name;
    const noDelimiter = adName?.split(delimiter).join(" ");
    return noDelimiter?.trim() || "";
  }
  return getAdNamePart({ name, delimiter });
};

/**
 * Dedupe ads for the same row id in the table
 */
export function getDedupedAds(
  dataSource: AdReviewTableEntry[],
  selectedAdIds: string[],
) {
  const delimiter = "_";
  const adGroups: Record<string, string> = dataSource.reduce(
    (groups, { id }) => {
      const adIds = id.split(delimiter);
      const newGroups = adIds.reduce(
        (pairs, adId) => ({ ...pairs, [adId]: adIds[0] }),
        {},
      );
      return { ...groups, ...newGroups };
    },
    {},
  );
  const dedupedAds = selectedAdIds
    .map(selectedAdId => adGroups[selectedAdId] ?? selectedAdId)
    .filter(dedupe);

  return dedupedAds;
}

/**
 * Dedupe ads for the same row id in the table
 */
export const getSelectedEntries = (
  dataSource: AdReviewTableEntry[],
  selectedAdIds: string[],
  adsToShow: IFacebookAd[],
) => {
  const deDupedAds = getDedupedAds(dataSource, selectedAdIds);
  const adIds = adsToShow
    .filter(ad => ad.id && deDupedAds.includes(ad.id))
    .map(ad => ad.id);
  const delimiter = "_";
  return dataSource.filter(({ id }) => {
    const ids = id.split(delimiter);
    return ids.some(adId => adIds.includes(adId));
  });
};

export const isFilteredByCreative = (ad: IFacebookAd, filters: Filters[]) => {
  const isAIA = ad.type === AdType.AIA;
  const isCreative = !isAIA;

  return filters.length > 0
    ? (filters.includes(Filters.Creative) && isCreative) ||
        (filters.includes(Filters.AIA) && isAIA)
    : true;
};

export const isMultipleUrls = (text: string) => {
  return text.split("\n").length > 1;
};

const isCanvasUrl = (text: string) => {
  return text.search("https://fb.com/canvas_doc/") !== -1;
};

export const shouldShowDestinationUrl = (selectedEntry: AdReviewTableEntry) => {
  return (
    selectedEntry?.adFormat !== AdType.InstantExperience &&
    selectedEntry?.adFormat !== AdType.Collection &&
    !isCanvasUrl(selectedEntry?.destinationUrl || "")
  );
};

export const getCanvasId = (facebookAd: IFacebookAd) => {
  const canvasLink =
    facebookAd.creative?.object_story_spec?.video_data?.call_to_action?.value
      ?.link ??
    facebookAd.creative?.page_object_story_spec?.call_to_action?.value?.link ??
    "";
  const canvasId = last(canvasLink.split("/"));
  return canvasId ?? undefined;
};

export const getNextPageParam = (paging?: IPagination) => {
  return paging?.next ? paging?.cursors.after : undefined;
};

export const getAdCopy = (ad: IFacebookAd) => {
  const {
    video_data: videoData,
    link_data: linkData,
    template_data: templateData,
  } = ad.creative?.object_story_spec || {};
  return videoData?.message || linkData?.message || templateData?.message;
};

export const getAdHeadline = (ad: IFacebookAd) => {
  const {
    video_data: videoData,
    link_data: linkData,
    template_data: templateData,
  } = ad.creative?.object_story_spec || {};
  const names = linkData?.child_attachments?.map(ad => ad.name) ?? [];
  return (
    videoData?.title || linkData?.name || names.join(" ") || templateData?.name
  );
};

export const getAdNewsLinkDescription = (ad: IFacebookAd) => {
  const {
    video_data: videoData,
    link_data: linkData,
    template_data: templateData,
  } = ad.creative?.object_story_spec || {};
  return (
    videoData?.link_description ||
    linkData?.description ||
    templateData?.description
  );
};

export const getAdDisplayUrl = (ad: IFacebookAd) => {
  const {
    video_data: videoData,
    link_data: linkData,
    template_data: templateData,
  } = ad.creative?.object_story_spec || {};
  return (
    videoData?.call_to_action?.value?.link_caption ||
    linkData?.link ||
    linkData?.call_to_action?.value?.link_caption ||
    templateData?.link
  );
};

export const getAdCta = (ad: IFacebookAd) => {
  const { creative: { object_story_spec } = {} } = ad;
  const { template_data, video_data, link_data } = object_story_spec || {};
  const { call_to_action } = template_data || video_data || link_data || {};
  return call_to_action?.type;
};

export const getDestinationUrlsFromIE = ({
  instantExperienceId,
  instantExperiences,
}: {
  instantExperienceId: string;
  instantExperiences: IInstantExperience[];
}): string[] => {
  const instantExperience = instantExperiences.find(
    ie => ie.id === instantExperienceId,
  );
  if (!instantExperience?.id) return [];

  const destUrls = instantExperience.body_elements?.flatMap(element =>
    getElementDestinationUrl({ element, instantExperiences }),
  );

  return destUrls ?? [];
};

const getElementDestinationUrl = ({
  element,
  instantExperiences,
}: {
  element: InstantExperienceBodyElement;
  instantExperiences: IInstantExperience[];
}): string[] | string => {
  switch (element.element_type) {
    case "BUTTON":
    case "PHOTO": {
      const elementDestination = element.destination;
      if (!elementDestination) return [];

      const { urlLabel, instantExperienceId } = elementDestination;
      if (urlLabel) return urlLabel;

      if (instantExperienceId)
        return getDestinationUrlsFromIE({
          instantExperienceId,
          instantExperiences,
        });

      return [];
    }
    case "CAROUSEL":
    case "FOOTER": {
      return (
        element.child_elements?.flatMap(childElement => {
          return getElementDestinationUrl({
            element: childElement,
            instantExperiences,
          });
        }) ?? []
      );
    }
    default: {
      return [];
    }
  }
};

/**
 * Extracts the primary ad ID from an ad id.
 *
 * For carousel ads, multiple IDs are concatenated and separated by
 * the "AD_NAME_DELIMITER". This function retrieves only the primary (first) ID
 * from an ad id.
 *
 * @param {string} adId - The ad id.
 * @returns {string} - The primary ad id.
 */
export const extractPrimaryIdFromAdId = (adId: string) =>
  adId.split(AD_NAME_DELIMITER)[0];

/**
 * Extracts the primary ad IDs from an array ads.
 *
 * For carousel ads, multiple IDs are concatenated and separated by
 * the "AD_NAME_DELIMITER". This function retrieves only the primary (first) ID
 * from each ad in the array.
 *
 * @param {string[]} adNamesArray - The array of ads.
 * @returns {string[]} - An array of primary ad IDs.
 */
export const extractPrimaryIdsFromCarouselAds = (adsIds: string[]) =>
  adsIds.flatMap(extractPrimaryIdFromAdId);

export const getDateRange = (date: string): [number, number] | undefined => {
  if (!date) return;
  const [year, monthOrQuarter] = date.split("-");
  const quarters = ["Q1", "Q2", "Q3", "Q4"];
  if (quarters.includes(monthOrQuarter)) {
    const month = quarters.indexOf(monthOrQuarter) * 3 + 1;
    const startDate = `${year}-${month}`;
    return [
      +moment(startDate).tz("America/New_York"),
      +moment(startDate).tz("America/New_York").add(2, "months").endOf("month"),
    ];
  } else {
    return [
      +moment(date).tz("America/New_York"),
      +moment(date).tz("America/New_York").endOf("month"),
    ];
  }
};
