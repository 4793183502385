import {
  CheckOutlined,
  CopyOutlined,
  DeleteOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  IAssetBuild,
  IAssetBuildInstance,
  OfferData,
  TVisibility,
  IAssetBuilderState,
  TLogoSubstitution,
  ISelectedOffer,
  FeedTab,
  SelectedInstance,
} from "shared/types/assetBuilder";
import { OfferType } from "shared/types/shared";
import {
  ITemplate,
  ExtendedObjectType,
  LogoEventType,
} from "shared/types/designStudio";
import { INewOrder } from "shared/types/newOrders";
import actions from "redux/rootActions";
import TemplatePreviewMemo from "shared/components/TemplatePreviewMemo";
import RenderTemplateProvider from "shared/components/contextAPI/shared/RenderTemplate";

import { IConfig, IConfigurationState } from "shared/types/configuration";
import { TDataCache } from "shared/hooks/useFetchRenderTemplate";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { SelectInstance } from "./SelectInstance";

import styles from "./TemplateCard.module.scss";

interface ITemplateCardProps {
  feedTabs?: FeedTab[];
  renderTemplateCache?: TDataCache;
  instance: IAssetBuildInstance;
  offers: IAssetBuild["offers"];
  templates: ITemplate[];
  selectedOffers: ISelectedOffer[];
  currentOrder?: INewOrder;
  highlights?: {
    [key in ExtendedObjectType]?: boolean;
  };
  assetType: string;
  order: number;
  size: string;
  lifestyleImageUrl: IAssetBuilderState["lifestyleImageUrl"];
  supportVideos?: boolean;
  config?: IConfig;
  allObjectsShown: {
    [key in ExtendedObjectType | LogoEventType]?: {
      toggle: boolean;
      action: "show" | "hide";
    };
  };
  triggerAssetInstance?: boolean;
}

interface ITemplateCardHandlers {
  toggleHighlightStamps?: (on: boolean, size: string) => void;
  toggleHighlightLogos?: (on: boolean, size: string) => void;
  setSelectedAssetBuildInstance?: (args: SelectedInstance[]) => void;
  deleteSelectedAssetBuildInstance?: (args: SelectedInstance) => void;
  cloneAssetInstance: () => void;
  updateAssetInstance: (instance: IAssetBuildInstance) => void;
  onChangeLogoSubstitution: (logoSub: TLogoSubstitution) => void;
  deleteAssetInstance: (id: string) => void;
  setVisibilitiesOfInstance: (
    visibilities: TVisibility[],
    assetType: string,
    size: string,
    order: number,
  ) => void;
  gatherMissingOfferTypes: (offerTypes: string[]) => void;
  instancesBySize: Record<string, IAssetBuildInstance[]>;
}

const TemplateCard: FC<ITemplateCardProps & ITemplateCardHandlers> = ({
  instance,
  offers,
  currentOrder,
  config,
  cloneAssetInstance,
  instancesBySize,
  ...props
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [playVideos, setPlayVideos] = useState(false);
  const [selectedDisclosureRender, setSelectedDisclosureRender] =
    useState(false);
  const selectedInstances = useAppSelector(
    state => state.assetBuilder.buildPage.selectedInstance,
  );
  const [selectedInstance] = selectedInstances;

  useEffect(() => {
    if (!instance.disclosure) return;
    setSelectedDisclosureRender(true);
  }, [instance.disclosure]);

  useEffect(() => {
    if (!selectedDisclosureRender) return;
    setSelectedDisclosureRender(false);
  }, [selectedDisclosureRender]);

  const clickOutsideHandler = useCallback(() => {
    if (showPopover) {
      setShowPopover(false);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutsideHandler);
    return () => {
      document.removeEventListener("mousedown", clickOutsideHandler);
    };

    // eslint-disable-next-line
  }, []);

  const offer = useMemo(() => {
    const selectedOffer = instance.selectedOffer;

    if (selectedOffer) {
      const placeholderIndex = selectedOffer.offerTypes.indexOf(
        "PurchasePlaceholder",
      );

      const newOfferTypes = [...selectedOffer.offerTypes] as OfferType[];

      if (placeholderIndex !== -1) {
        newOfferTypes.splice(
          placeholderIndex,
          1,
          ...selectedOffer.purchaseOptions,
        );
      }

      return {
        offerData: offers.find(
          offer => offer.offerData.vin === selectedOffer?.vin,
        )?.offerData as OfferData,
        offerTypes: (selectedOffer.offerSelectSortingTracker ||
          newOfferTypes) as OfferType[],
      };
    }
  }, [instance.selectedOffer, offers]);

  const offerMemo = useMemo(() => {
    return offer;
    // eslint-disable-next-line
  }, [JSON.stringify(offer)]);

  const logoSubstitutionsMemo = useMemo(() => {
    return instance.logoSubstitutions;
    // eslint-disable-next-line
  }, [JSON.stringify(instance.logoSubstitutions)]);

  const templateMemo = useMemo(() => {
    return instance.template ?? null;
    // eslint-disable-next-line
  }, [JSON.stringify(instance.template)]);

  const lifestyleImageUrl = instance.lifestyleImageUrl;
  const lifestyleFabricImageJson =
    instance?.lifestyleFabricImageJson ||
    selectedInstance?.instance?.lifestyleFabricImageJson;

  const isSelected = selectedInstances.some(i => i.instance.id === instance.id);

  const imgUrl = instance.lifestyleImageUrl || "";
  const isBgVideo = imgUrl.endsWith("mp4");
  const { mediaType } = instance.template || {};

  return (
    <div className={styles.host} data-selected-tpl-card={isSelected}>
      <div className={styles.selectInstance}>
        <SelectInstance
          order={props.order}
          instance={instance}
          instancesBySize={instancesBySize}
        />
      </div>
      {!isSelected && (
        <div
          className={styles.cardOverlay}
          onClick={async () => {
            let updatingInstance = { ...instance };
            const selected = offers.find(
              off => off.offerData.vin === instance.selectedOffer?.vin,
            );

            if (selected && updatingInstance.selectedOffer) {
              updatingInstance = {
                ...updatingInstance,
                selectedOffer: {
                  ...updatingInstance.selectedOffer,
                  offerData: selected.offerData,
                },
              };
            }

            props.setSelectedAssetBuildInstance?.([
              {
                instance: updatingInstance,
                assetType: props.assetType,
                order: props.order,
                size: props.size,
              },
            ]);
          }}
        />
      )}
      <div
        className={styles.previewWrapper}
        style={
          instance.template
            ? {
                overflow: instance.template.artboard.width > 1473 ? "auto" : "",
              }
            : { padding: "0.25em" }
        }
      >
        <RenderTemplateProvider config={config}>
          {!selectedDisclosureRender &&
          instance.template &&
          instance.selectedOffer &&
          currentOrder ? (
            <TemplatePreviewMemo
              renderTemplateCache={props.renderTemplateCache}
              template={templateMemo}
              vin={instance.selectedOffer?.vin}
              disclosure={instance.disclosure}
              offer={offerMemo}
              offers={offers}
              selectedOffers={props.selectedOffers}
              triggerAssetInstance={props.triggerAssetInstance}
              order={currentOrder}
              selectedInstances={selectedInstances}
              displayLabelBox={true}
              supportVideos={props.supportVideos}
              playVideos={playVideos}
              objectVisibilities={instance.visibilities}
              onToggleVisibility={(visibilities: TVisibility[]) => {
                props.updateAssetInstance({
                  ...instance,
                  visibilities,
                });
              }}
              onToggleAllVisibilities={visibilities => {
                props.setVisibilitiesOfInstance(
                  visibilities,
                  props.assetType,
                  props.size,
                  props.order,
                );
              }}
              logoSubstitutions={logoSubstitutionsMemo}
              onChangeLogoSubstitution={props.onChangeLogoSubstitution}
              highlights={props.highlights}
              editable={(
                Object.keys(props.highlights || {}) as ExtendedObjectType[]
              ).some(key => props.highlights?.[key])}
              lifestyleImageUrl={lifestyleImageUrl}
              lifestyleFabricImageJson={lifestyleFabricImageJson}
              isLifestyleImageEditable={instance.template.type === "lifestyle"}
              allObjectsShown={props.allObjectsShown}
              gatherMissingOfferTypes={props.gatherMissingOfferTypes}
              feedTabs={props.feedTabs}
            />
          ) : instance.template ? (
            <div
              className={styles.assetPlaceholder}
              style={{
                height: instance.template.artboard.height,
                width: instance.template.artboard.width,
                margin: "auto",
              }}
            />
          ) : (
            <div className={styles.assetPlaceholder} />
          )}
        </RenderTemplateProvider>
      </div>

      <div className={styles.cardControls}>
        {instance.template && (
          <Button
            size="large"
            shape="circle"
            icon={<CopyOutlined />}
            onClick={cloneAssetInstance}
          />
        )}

        <Button
          className={styles.check}
          size="large"
          shape="circle"
          icon={<CheckOutlined />}
          disabled={isSelected}
          onClick={() => {
            props.setSelectedAssetBuildInstance?.([
              {
                instance,
                assetType: props.assetType,
                order: props.order,
                size: props.size,
              },
            ]);
          }}
        />

        {props.supportVideos && (mediaType === "mp4" || isBgVideo) && (
          <Button
            size="large"
            shape="circle"
            icon={playVideos ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
            title={`${playVideos ? "Stop" : "Start"} videos in this instance`}
            onClick={() => {
              // Possible to do: check if instance has video BEFORE showing button
              setPlayVideos(!playVideos);
            }}
          />
        )}

        <Popconfirm
          title="Remove Instance?"
          okText="Remove"
          okType="danger"
          onConfirm={() => {
            props.deleteSelectedAssetBuildInstance?.({
              instance: instance,
              assetType: props.assetType,
              order: props.order,
              size: props.size,
            });
            props.setSelectedAssetBuildInstance?.([]);
            props.deleteAssetInstance(instance.id);
          }}
        >
          <Button
            data-cy="deleteInstance"
            size="large"
            shape="circle"
            icon={<DeleteOutlined />}
            type="primary"
            danger
          ></Button>
        </Popconfirm>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  assetBuilder: IAssetBuilderState;
  configuration: IConfigurationState;
}) => {
  const {
    assetBuilder: { lifestyleImageUrl, selectedOffers, triggerAssetInstance },
    configuration: { config },
  } = state;

  return {
    triggerAssetInstance,
    config,
    selectedOffers,
    lifestyleImageUrl,
    supportVideos: config?.featureFlags?.supportVideos,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    setSelectedAssetBuildInstance: (args: SelectedInstance[]) => {
      dispatch(actions.assetBuilder.setSelectedAssetBuildInstance(args));
    },

    deleteSelectedAssetBuildInstance: (args: SelectedInstance) => {
      dispatch(actions.assetBuilder.deleteSelectedAssetBuildInstance(args));
    },

    setVisibilitiesOfInstance: (
      visibilities: TVisibility[],
      assetType: string,
      size: string,
      order: number,
    ) => {
      dispatch(
        actions.assetBuilder.setVisibilitiesOfInstance({
          visibilities,
          assetType,
          size,
          order,
        }),
      );
    },
    gatherMissingOfferTypes: (offerTypes: string[]) => {
      dispatch(actions.assetBuilder.gatherMissingOfferTypes(offerTypes));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateCard);
