import { Select, SelectProps } from "antd";
import { FilterBy } from "shared/components/FilterByInput/FIlterByInput";
import styles from "../FilterDrawer.module.scss";
import { SelectValue } from "antd/lib/select";

type SelectFilterProps<T> = { title: string } & SelectProps<T>;

export const SelectFilter = <T extends SelectValue>({
  title,
  children,
  ...rest
}: SelectFilterProps<T>) => {
  return (
    <FilterBy title={title}>
      <Select showSearch className={styles.filterInput} allowClear {...rest}>
        {children}
      </Select>
    </FilterBy>
  );
};
