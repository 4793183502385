import { Drawer, Space, Button, Typography, message } from "antd";
import { useState } from "react";
import SingleAccountSelect from "shared/components/SingleAccountSelect";
import { useUploadPmaxCampaigns } from "shared/hooks/adLibrary/pmax/useUploadPmaxCampaigns";
import { IAccount } from "shared/types/accountManagement";
import styles from "../GooglePerformanceMax.module.scss";
import { usePmaxCampaignsContext } from "../PmaxCampaignsProvider";
import { CampaignLoadTable } from "./campaignLoadDrawer/CampaignLoadTable";

export const CampaignLoadDrawer = () => {
  const { loadDrawerOpen, setLoadDrawerOpen, selectedCampaigns } =
    usePmaxCampaignsContext();
  const [store, setStore] = useState<IAccount | undefined>();

  const {
    mutate: uploadCampaigns,
    results,
    status: batchCampaignLoadStatus,
    reset: resetUploadCampaignsMutation,
    isLoading,
  } = useUploadPmaxCampaigns({
    campaigns: selectedCampaigns,
    adAccount: {
      dealerName: store?.dealer_name || "",
      id: store?.details.google?.adAccountCID || "",
      dealerOem: store?.dealer_oem,
    },
  });

  const onStoreChange = (selectedStoreName: string, stores?: IAccount[]) => {
    const selectedStore = stores?.find(
      ({ dealer_name }) => dealer_name === selectedStoreName,
    );

    if (!selectedStore?.details.google?.adAccountCID) {
      message.error("Store does not have a Google Ads account CID");
      return;
    }
    setStore(selectedStore);
  };

  const campaignsWithLoadStatus = selectedCampaigns.map(campaign => ({
    ...campaign,
    status: results[campaign.name] || "Loading",
  }));

  return (
    <Drawer
      title={"Assign Campaigns to Store"}
      destroyOnClose
      visible={loadDrawerOpen}
      afterVisibleChange={resetUploadCampaignsMutation}
      closable={false}
      maskClosable={false}
      width={"95%"}
      footer={
        <Space className={styles.loadDrawerFooter}>
          <Button onClick={() => setLoadDrawerOpen(false)}>Cancel</Button>
          <Button
            onClick={() => uploadCampaigns()}
            type="primary"
            loading={isLoading}
            disabled={!store || isLoading}
          >
            Load to Google Ads
          </Button>
        </Space>
      }
    >
      <Space direction="vertical" className={styles.loadDrawerContent}>
        <Typography>Select a Store</Typography>
        <SingleAccountSelect
          selectedDealer={store?.dealer_name}
          className={styles.storeSelect}
          onChange={onStoreChange}
        />

        <CampaignLoadTable
          store={store}
          campaigns={campaignsWithLoadStatus}
          batchCampaignLoadStatus={batchCampaignLoadStatus}
        />
      </Space>
    </Drawer>
  );
};
