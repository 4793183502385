import API from "services";
import {
  AdFormat,
  CreateAdParameters,
  FacebookHTTPMethod,
  ICreateAdImageResponseData,
  ICreateOrUpdateResponse,
  ICreateOrUpdateResult,
  IFacebookAccount,
  IFacebookAd,
  IFacebookAdCreative,
  IFacebookAdset,
  IFacebookCampaign,
  IFacebookErrorObject,
  IFacebookGetResponseData,
  IFacebookObject,
  IGetAdVideoResponseData,
  IGetFacebookCampaignsParams,
  IInstagramGetResponseData,
  IProxyRequestBody,
  IUpdateFacebookAdParams,
} from "./types";
import { template } from "lodash";
import {
  alexiaTestPage,
  nissanDemoPage,
  nissanUsaAccountIds,
  alexiaTestCatalogs,
  alexiaTestPixel,
  nissanDemoCatalog,
  nissanDemoPixel,
  getDefaultPropValue,
  alexiaTestInstagram,
  nissanDemoInstagram,
  getUserFacingFacebookErrorMessage,
} from "./utils";
import { getEnvVar, isEnvVarEquals } from "utils/helpers";
import memoizee from "memoizee";
import { pullUntilVideoIsReady } from "./adVideo";
import { facebookCacheStaleTime } from "shared/constants/adLibrary";

interface IGeneratePreviewsResponseData {
  data: [
    {
      body: string;
    },
  ];
}

interface ICreateAdVideoResponseData {
  id: string;
}

type GetFacebookObjectArgs = {
  accountName?: string;
  accountId?: string;
  serverSidePagination?: boolean;
  skipNameMatching?: boolean;
  pageId?: string;
};

type MakeCreateOrUpdateRequestArgs = {
  endpoint: string;
  willGetEndpoint?: boolean;
  data: CreateAdParameters | IFacebookAdCreative;
};

export default class AdAccount {
  constructor(readonly accountId: string) {}

  getAccountId() {
    return this.accountId;
  }

  static businessId = "1069169469786223";
  private defaultError = {
    message: "Something went wrong while getting the Facebook data.",
  };

  private getEndpoint(url: string) {
    return `/act_${this.accountId}${url}`;
  }

  private getProxyRequestBody(params: {
    method: FacebookHTTPMethod;
    apiEndpoint: string; // the api endpoint like /campaigns or /<campaign_id/ads
    useAccountId?: boolean; // if true, will use /act_<id>/ prefix
    fieldsString?: string; // parameters in Facebook Edge query
    limit?: number; // limits the number of items to return
    serverSidePagination?: boolean;
  }) {
    const {
      apiEndpoint,
      useAccountId,
      fieldsString,
      limit,
      method,
      serverSidePagination,
    } = params;

    let queryTemplateString = fieldsString ? `?fields=<%= fields%>` : undefined;

    if (limit) {
      queryTemplateString = queryTemplateString
        ? `${queryTemplateString}&limit=${params.limit}`
        : `?limit=${params.limit}`;
    }

    const proxyBody: IProxyRequestBody = {
      method,
      platform: "facebook",
      endpoint: useAccountId ? this.getEndpoint(apiEndpoint) : apiEndpoint,
      serverSidePagination: serverSidePagination,
      query: queryTemplateString
        ? template(queryTemplateString)({ fields: fieldsString })
        : limit
        ? queryTemplateString
        : undefined,
    };

    return proxyBody;
  }

  private async makeGetRequestWithPagination<TResult>(
    proxyBody: IProxyRequestBody<TResult>,
  ) {
    const { result, error } = await this.makeRequest<
      IFacebookGetResponseData<TResult>
    >(proxyBody);

    if (error || !result) {
      return {
        result: null,
        error: error || this.defaultError,
      };
    }

    let nextToken = proxyBody.serverSidePagination ? null : result.paging?.next;
    while (nextToken) {
      const { result: nextResult, error: nextError } = await this.makeRequest<
        IFacebookGetResponseData<TResult>
      >({ ...proxyBody, query: `${proxyBody.query}&next=${nextToken}` });
      if (nextError || !nextResult?.data) {
        return {
          result: null,
          error: nextError || this.defaultError,
        };
      }
      result.data = [...result.data, ...nextResult!.data];
      nextToken = nextResult.paging?.next;
    }

    return {
      result: {
        ...result,
      },
      error: null,
    };
  }

  private async makeCreateOrUpdateRequest(args: MakeCreateOrUpdateRequestArgs) {
    const proxyBody: IProxyRequestBody = {
      endpoint: args.willGetEndpoint
        ? this.getEndpoint(args.endpoint)
        : args.endpoint,
      method: "POST",
      platform: "facebook",
      data: args.data,
    };
    try {
      const createOrUpdateResponse =
        await this.makeRequest<ICreateOrUpdateResult>(proxyBody);
      return createOrUpdateResponse;
    } catch (errorResponse) {
      return {
        result: null,
        error: (errorResponse as { error: any }).error,
      };
    }
  }

  async getAdAccounts(accountType: "owned" | "client", nextCursor?: string) {
    const query = new URLSearchParams({
      fields: "name, account_id, account_status",
      filtering: `[{ field: "account_status", operator: "EQUAL", value: 1 }]`,
      limit: "250",
    });

    if (nextCursor) {
      query.append("after", nextCursor);
    }

    const queryString = `?${query.toString()}`;
    const getAccountsProxyBody: IProxyRequestBody = {
      endpoint: `/${AdAccount.businessId}/${accountType}_ad_accounts`, // TO DO: Move this ID elsewhere
      method: "GET",
      platform: "facebook",
      query: queryString,
      cache: true,
      staleTime: facebookCacheStaleTime,
    };

    const { result, error } = await this.makeRequest<
      IFacebookGetResponseData<IFacebookAccount>
    >({
      ...getAccountsProxyBody,
    });

    if (error || !result) {
      throw Error(error?.message);
    }

    return result;
  }

  async getPages(params: GetFacebookObjectArgs) {
    const { accountName, accountId, serverSidePagination } = params;

    if (accountId && accountName?.toLowerCase().startsWith("alexia test")) {
      return {
        result: {
          data: [alexiaTestPage],
        } as IFacebookGetResponseData<IFacebookObject>,
        error: null,
      };
    }

    if (
      accountId &&
      nissanUsaAccountIds.includes(accountId) &&
      process.env.REACT_APP_AV2_CLIENT === "nu"
    ) {
      return {
        result: {
          data: [nissanDemoPage],
        } as IFacebookGetResponseData<IFacebookObject>,
        error: null,
      };
    }
    // owned_pages?fields=name,access_token&filtering=[{field: "name",operator:"CONTAIN", value:'Alexia Test 1'}]
    const pagesQuery = "?fields=name&limit=100";
    const ownedPagesEndpoint = `${AdAccount.businessId}/owned_pages`;
    const clientPagesEndpoint = `${AdAccount.businessId}/client_pages`;

    const getPagesProxyBody: IProxyRequestBody = {
      endpoint: ownedPagesEndpoint,
      method: "GET",
      platform: "facebook",
      query: `${pagesQuery}${
        accountName && !params.skipNameMatching
          ? `&filtering=[{field: "name",operator:"CONTAIN", value:'${accountName}'}]`
          : ""
      }`,
      serverSidePagination,
    };

    const { result: ownedPagesRes, error: ownedPagesErr } =
      await this.makeGetRequestWithPagination<IFacebookObject>(
        getPagesProxyBody,
      );

    if (ownedPagesErr || !ownedPagesRes) {
      return {
        result: null,
        error: {
          message: ownedPagesErr?.message || "Could not fetch owned pages",
        },
      };
    }

    let pages = ownedPagesRes.data;

    const { result: clientPagesRes, error: clientPagesErr } =
      await this.makeGetRequestWithPagination<IFacebookObject>({
        ...getPagesProxyBody,
        endpoint: clientPagesEndpoint,
      });

    if (clientPagesErr || !clientPagesRes) {
      return {
        result: null,
        error: {
          message: clientPagesErr?.message || "Could not fetch client pages",
        },
      };
    }

    pages = pages.concat(clientPagesRes.data);

    if (pages.length < 1) {
      return {
        result: null,
        error: {
          message: "There are no active pages available.",
        },
      };
    }

    return {
      result: {
        data: pages,
      } as IFacebookGetResponseData<IFacebookObject>,
      error: null,
    };
  }

  async getCatalogs(params: GetFacebookObjectArgs) {
    const { accountName, accountId, serverSidePagination, skipNameMatching } =
      params;

    if (accountId && accountName?.toLowerCase().startsWith("alexia test")) {
      return {
        result: {
          data: alexiaTestCatalogs,
        } as IFacebookGetResponseData<IFacebookObject>,
        error: null,
      };
    }

    if (
      accountId &&
      nissanUsaAccountIds.includes(accountId) &&
      process.env.REACT_APP_AV2_CLIENT === "nu"
    ) {
      return {
        result: {
          data: [nissanDemoCatalog],
        } as IFacebookGetResponseData<IFacebookObject>,
        error: null,
      };
    }
    const query = "?fields=name&limit=100";
    const ownedCatalogsEndpoint = `${AdAccount.businessId}/owned_product_catalogs`;
    const clientCatalogsEndpoint = `${AdAccount.businessId}/client_product_catalogs`;

    const getCatalogsProxyBody: IProxyRequestBody = {
      endpoint: ownedCatalogsEndpoint,
      method: "GET",
      platform: "facebook",
      query: `${query}${
        accountName && !skipNameMatching
          ? `&filtering=[{field: "name",operator:"CONTAIN", value:'${accountName}'}]`
          : ""
      }`,
      serverSidePagination,
      cache: true,
      staleTime: facebookCacheStaleTime,
    };

    const catalogIdEnvVar = getEnvVar("CATALOG_IDS");
    const catalogIds = catalogIdEnvVar?.split(",");

    const { result: ownedCatalogsRes, error: ownedCatalogsErr } =
      await this.makeGetRequestWithPagination<IFacebookObject>(
        getCatalogsProxyBody,
      );

    if (ownedCatalogsErr || !ownedCatalogsRes) {
      return {
        result: null,
        error: {
          message:
            ownedCatalogsErr?.message || "Could not fetch owned catalogs",
        },
      };
    }

    const { result: clientCatalogsRes, error: clientCatalogsErr } =
      await this.makeGetRequestWithPagination<IFacebookObject>({
        ...getCatalogsProxyBody,
        endpoint: clientCatalogsEndpoint,
      });

    if (clientCatalogsErr || !clientCatalogsRes) {
      return {
        result: null,
        error: {
          message:
            clientCatalogsErr?.message || "Could not fetch client catalogs",
        },
      };
    }

    const clientAndOwnedCatalogs = ownedCatalogsRes.data.concat(
      clientCatalogsRes.data,
    );

    const catalogs =
      !catalogIdEnvVar ||
      catalogIdEnvVar === "null" ||
      catalogIdEnvVar === "none"
        ? clientAndOwnedCatalogs
        : clientAndOwnedCatalogs.filter(catalog =>
            catalogIds?.includes(catalog.id),
          );

    if (catalogs.length < 1) {
      return {
        result: null,
        error: {
          message: "There are no active catalogs available.",
        },
      };
    }

    return {
      result: {
        data: catalogs,
      } as IFacebookGetResponseData<IFacebookObject>,
      error: null,
    };
  }

  async getInstagram(params: GetFacebookObjectArgs) {
    const { accountName, accountId, skipNameMatching, pageId } = params;

    const invalidAccount =
      accountId &&
      accountName?.toLowerCase().startsWith("alexia test") &&
      !skipNameMatching;

    const validNUAccount =
      accountId &&
      nissanUsaAccountIds.includes(accountId) &&
      isEnvVarEquals("CLIENT", "nu");

    if (validNUAccount || invalidAccount) {
      return {
        result: {
          data: [invalidAccount ? alexiaTestInstagram : nissanDemoInstagram],
        } as IFacebookGetResponseData<IFacebookObject>,
        error: null,
      };
    }

    const query = "?fields=access_token";
    const endpoint = `${pageId}`;

    const getPageAccessTokenProxyBody: IProxyRequestBody = {
      endpoint,
      method: "GET",
      platform: "facebook",
      query,
    };

    const { result: pageRes, error: pageErr } = await this.makeRequest<{
      access_token: string;
    }>(getPageAccessTokenProxyBody);

    if (pageErr || !pageRes) {
      return {
        result: null,
        error: {
          message: pageErr?.message || "Could not fetch Page Access Token",
        },
      };
    }

    const { access_token: pageAccessToken } = pageRes;

    const instaQuery = `?fields=instagram_accounts{username}&page_access_token=${pageAccessToken}`;
    const instaEndpoint = `${pageId}`;

    const getInstagramProxyBody: IProxyRequestBody = {
      endpoint: instaEndpoint,
      method: "GET",
      platform: "facebook",
      query: `${instaQuery}`,
    };

    const { result: instaRes, error: instaErr } =
      await this.makeRequest<IInstagramGetResponseData>(getInstagramProxyBody);

    if (instaErr || !instaRes) {
      return {
        result: null,
        error: {
          message:
            instaErr?.message || "Could not fetch Instagram Business Accounts",
        },
      };
    }

    const { instagram_accounts } = instaRes || {};
    const { data: instagramData = [] } = instagram_accounts || {};

    if (instagramData.length < 1) {
      return {
        result: null,
        error: {
          message: "No Instagram Account Connected",
        },
      };
    }

    const returnData = instagramData.map(({ id, username: name }) => {
      return {
        id,
        name,
      };
    });

    return {
      result: {
        data: returnData,
      } as IFacebookGetResponseData<IFacebookObject>,
      error: null,
    };
  }

  async getPixels(params: GetFacebookObjectArgs) {
    const { accountName, accountId, skipNameMatching } = params;

    if (
      accountId &&
      accountName?.toLowerCase().startsWith("alexia test") &&
      !skipNameMatching
    ) {
      return {
        result: {
          data: [alexiaTestPixel],
        } as IFacebookGetResponseData<IFacebookObject>,
        error: null,
      };
    }

    if (
      accountId &&
      nissanUsaAccountIds.includes(accountId) &&
      process.env.REACT_APP_AV2_CLIENT === "nu"
    ) {
      return {
        result: {
          data: [nissanDemoPixel],
        } as IFacebookGetResponseData<IFacebookObject>,
        error: null,
      };
    }

    const query = "?fields=name";
    const pixelsEndpoint = `act_${accountId}/adspixels`;

    const getPixelsProxyBody: IProxyRequestBody = {
      endpoint: pixelsEndpoint,
      method: "GET",
      platform: "facebook",
      query,
    };

    const { result: pixelsRes, error: pixelsErr } = await this.makeRequest<
      IFacebookGetResponseData<IFacebookObject>
    >(getPixelsProxyBody);

    if (pixelsErr || !pixelsRes) {
      return {
        result: null,
        error: {
          message: pixelsErr?.message || "Could not fetch owned pixels",
        },
      };
    }

    const pixels = pixelsRes.data;

    if (pixels.length < 1) {
      return {
        result: null,
        error: {
          message: "There are no active pixels available.",
        },
      };
    }

    return {
      result: {
        data: pixels,
      } as IFacebookGetResponseData<IFacebookObject>,
      error: null,
    };
  }

  async createPixel(name: string, accountId: string): Promise<any> {
    const createPixelEndpoint = `act_${accountId}/adspixels`;
    const createPixelProxyBody: IProxyRequestBody = {
      endpoint: createPixelEndpoint,
      method: "POST",
      platform: "facebook",
      data: { name },
    };

    const { result, error } = await this.makeRequest<ICreateOrUpdateResponse>(
      createPixelProxyBody,
    );
    if (error || !result) {
      const errorMessage = error && getUserFacingFacebookErrorMessage(error);
      throw Error(errorMessage || "Error creating pixel");
    }

    return result;
  }

  async getGeneratePreviews(params: {
    creative: string;
    ad_format: AdFormat;
  }): Promise<IGeneratePreviewsResponseData> {
    const queryString = new URLSearchParams(params).toString();

    const proxyBody: IProxyRequestBody = {
      endpoint: this.getEndpoint("/generatepreviews"),
      method: "GET",
      platform: "facebook",
      query: `?${queryString}`,
    };

    const getProxyApiErrorMessage = (error: IFacebookErrorObject) =>
      error?.error_user_msg || error?.message || "Error generating the preview";

    try {
      const { result, error } =
        await this.makeRequest<IGeneratePreviewsResponseData>(proxyBody);

      if (error || !result) {
        throw Error(getProxyApiErrorMessage(error as IFacebookErrorObject));
      }

      return result;
    } catch (response) {
      const error = (response as { error: IFacebookErrorObject })?.error;
      throw Error(getProxyApiErrorMessage(error));
    }
  }

  async createAdVideo(params: {
    file_url: string;
  }): Promise<ICreateAdVideoResponseData> {
    const proxyBody: IProxyRequestBody = {
      endpoint: this.getEndpoint("/advideos"),
      method: "POST",
      platform: "facebook",
      data: params,
    };

    const { result, error } =
      await this.makeRequest<ICreateAdVideoResponseData>(proxyBody);
    if (error || !result) {
      throw Error(error?.message);
    }

    await pullUntilVideoIsReady(result.id);

    return result;
  }

  /**
   * Memoized method to create an account video
   * It is memoized for 15 minutes to avoid creating the same media multiple times
   */
  async createMemoizedAdVideo(params: {
    file_url: string;
  }): Promise<ICreateAdVideoResponseData> {
    return createMemoizedAdVideo({
      ...params,
      accountId: this.accountId,
    });
  }

  // asset = video or image
  async getAdAssetData<T>(
    assetId: string,
    assetType: "photo" | "video",
    pageId?: string,
  ): Promise<T> {
    const proxyBody: IProxyRequestBody = {
      endpoint: "/" + assetId,
      method: "GET",
      platform: "facebook",
      query: `?fields=${
        assetType === "photo" ? "images,picture" : "thumbnails,source,status"
      }`,
      facebookPageId: pageId,
    };

    const { result, error } = await this.makeRequest<T>(proxyBody);

    if (error || !result) {
      throw Error(error?.message);
    }

    return result;
  }

  async getAdVideoStatus(assetId: string) {
    const proxyBody: IProxyRequestBody = {
      endpoint: "/" + assetId,
      method: "GET",
      platform: "facebook",
      query: "?fields=status",
    };

    const { result, error } = await this.makeRequest<IGetAdVideoResponseData>(
      proxyBody,
    );

    if (error || !result) {
      return { error, result: null };
    }

    return { error: null, result };
  }

  async createAdImage(params: {
    bytes: string;
  }): Promise<ICreateAdImageResponseData> {
    const proxyBody: IProxyRequestBody = {
      endpoint: this.getEndpoint("/adimages"),
      method: "POST",
      platform: "facebook",
      data: params,
    };

    const { result, error } =
      await this.makeRequest<ICreateAdImageResponseData>(proxyBody);
    if (error || !result) {
      throw Error(error?.message);
    }

    return result;
  }

  async deleteAdImage(assetId: string): Promise<ICreateAdVideoResponseData> {
    const proxyBody: IProxyRequestBody = {
      endpoint: "/" + assetId,
      method: "DELETE",
      platform: "facebook",
    };

    const { result, error } = await this.makeRequest<{ success: boolean }>(
      proxyBody,
    );
    if (error || !result?.success) {
      throw Error(error?.message);
    }

    return { id: assetId };
  }

  async createAdCreative(
    creativeParams: IFacebookAdCreative,
  ): Promise<ICreateOrUpdateResponse> {
    return this.makeCreateOrUpdateRequest({
      endpoint: "/adcreatives",
      willGetEndpoint: true,
      data: {
        ...creativeParams,
        degrees_of_freedom_spec: {
          creative_features_spec: {
            standard_enhancements: {
              enroll_status: "OPT_OUT",
            },
          },
        },
      },
    });
  }

  async createAd(
    createAdParams: CreateAdParameters,
  ): Promise<ICreateOrUpdateResponse> {
    return this.makeCreateOrUpdateRequest({
      endpoint: "/ads",
      data: {
        ...createAdParams,
        degrees_of_freedom_spec: {
          creative_features_spec: {
            standard_enhancements: {
              enroll_status: "OPT_OUT",
            },
          },
        },
      },
      willGetEndpoint: true,
    });
  }

  async updateAd(args: {
    facebookAd: IFacebookAd;
    params: IUpdateFacebookAdParams;
  }): Promise<ICreateOrUpdateResponse> {
    if (!args.facebookAd.id) {
      return {
        result: null,
        error: {
          message: "A Facebook ad id was not provided",
        },
      };
    }

    // data type is json, so there is no definitive type
    const jsonData: any = { ...args.params };

    if (jsonData.creative) {
      jsonData.creative = JSON.stringify(jsonData.creative!);
    }

    const response = await this.makeCreateOrUpdateRequest({
      endpoint: `${args.facebookAd.id}`,
      data: jsonData,
      willGetEndpoint: false,
    });

    if (response.error || !response.result) {
      return {
        result: null,
        error: {
          message:
            response.error?.message ||
            "Something went wrong while updating the ad creative.",
        },
      };
    }

    return response;
  }

  async getCampaigns(params: IGetFacebookCampaignsParams) {
    //
    let fieldsString =
      "name,objective,start_time,end_time,stop_time,created_time,updated_time,status,adlabels,special_ad_categories,account_id,promoted_object";

    if (params.includeAdsets) {
      const adsetFields = params.includeAds
        ? params.includeCreative
          ? "adsets.limit(500){id,name,campaign_id,ads{id,name,campaign_id,adset_id,promoted_object,creative{object_story_spec,product_set_id}}}"
          : "adsets.limit(500){id,name,campaign_id,ads{id,name,campaign_id,adset_id, promoted_object}}"
        : "adsets.limit(500){id,name,campaign_id,promoted_object}";
      fieldsString = `${fieldsString},${adsetFields}`;
    }

    if (params.startTimes) {
      const dateValue = `[${params.startTimes
        .map(val => val / 1000)
        .join(",")}]`;
      const dateFilter = `[{field:"campaign.start_time", operator:"IN_RANGE",value:${dateValue}}]`;
      fieldsString = `${fieldsString}&filtering=${dateFilter}`;
    }

    const proxyBody = this.getProxyRequestBody({
      fieldsString,
      method: "GET",
      useAccountId: true,
      limit: params.limit,
      serverSidePagination: params.pullDataViaPagination ? false : true,
      apiEndpoint: "/campaigns",
    });

    const { result, error } = params.pullDataViaPagination
      ? await this.makeGetRequestWithPagination<IFacebookCampaign>(proxyBody)
      : await this.makeRequest<IFacebookGetResponseData<IFacebookCampaign>>(
          proxyBody,
        );

    if (error || !result) {
      return {
        result: null,
        error: error || this.defaultError,
      };
    }

    return {
      result: {
        ...result,
      },
      error: null,
    };
  }

  async getAdsetsFromCampaign(params: {
    campaignId: string;
    includeAds?: boolean;
    includeCreative?: boolean; // only works if ads are included
  }) {
    let fieldsString =
      "id,name,campaign_id,start_time,end_time,created_time,updated_time";

    if (params.includeAds) {
      const adFields = params.includeCreative
        ? "ads{id,status,name,campaign_id,adset_id,creative{object_story_spec,url_tags,product_set_id}}"
        : "ads{id,status,name,campaign_id,adset_id}";
      fieldsString = `${fieldsString},${adFields}`;
    }

    const proxyBody = this.getProxyRequestBody({
      fieldsString,
      method: "GET",
      apiEndpoint: `/${params.campaignId}/adsets`,
    });

    const { result, error } = await this.makeRequest<
      IFacebookGetResponseData<IFacebookAdset>
    >(proxyBody);
    if (error || !result) {
      throw Error(error?.message);
    }

    return (result as IFacebookGetResponseData<IFacebookAdset>).data;
  }

  async getAdsFromCampaign(params: {
    campaignId: string;
    includeCreative?: boolean;
  }) {
    let fieldsString =
      "id,campaign_id,adset_id,name,status,created_time,updated_time, promoted_object";

    if (params.includeCreative) {
      fieldsString = `${fieldsString},creative{id,name,object_story_spec,link_url,object_url,effective_object_story_id,thumbnail_url,url_tags,product_set_id}`;
    }

    const proxyBody = this.getProxyRequestBody({
      fieldsString,
      method: "GET",
      apiEndpoint: `/${params.campaignId}/ads`,
    });

    const { result, error } = await this.makeRequest<
      IFacebookGetResponseData<IFacebookAd>
    >(proxyBody);

    if (error || !result) {
      return {
        result: null,
        error: {
          message:
            error?.message ||
            `Something went wrong while getting ad data for Facebook campaign ${params.campaignId}.`,
        },
      };
    }

    return {
      result: {
        ...result,
      },
      error: null,
    };
  }

  private makeRequest<TResult>(body: IProxyRequestBody) {
    return API.services.adLibrary.platformProxyRequest<
      TResult,
      IFacebookErrorObject
    >(body);
  }
}

/**
 * Memoized function to create an account video
 * It is memoized for 15 minutes to avoid creating the same media multiple times
 */
const createMemoizedAdVideo = memoizee(
  async ({ accountId, file_url }: { file_url: string; accountId: string }) => {
    const adAccount = new AdAccount(accountId);
    return adAccount.createAdVideo({
      file_url,
    });
  },
  {
    promise: true,
    normalizer: ([{ file_url, accountId }]) => {
      return `$${file_url}_${accountId}`;
    },
    maxAge: 1000 * 60 * 15,
  },
);

const adAccount = new AdAccount(getDefaultPropValue("ACCOUNTID"));

export { adAccount };
