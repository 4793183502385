import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ITemplate } from "shared/types/designStudio";
import { TValueMappings } from "../types";
import { useAssetBatchesValueMappingContext } from "./AssetBatchesValueMappingContext";
import { updateValueMappingsForRow } from "./AssetBatchesContext.utils";
import { TComposition } from "shared/types/assetExporter";

type MaskSwitchType = "media" | "text";

interface ContextProps {
  template?: ITemplate;
  iframeRef?: React.RefObject<HTMLIFrameElement>;
  valueMappings: TValueMappings;
  isMediaMaskOn: boolean;
  isTextMaskOn: boolean;
  currentSwitchType?: MaskSwitchType;
  editingComposition: TComposition;
}

interface ContextHandlers {
  toggleMediaMask: (enabled: boolean) => void;
  toggleTextMask: (enabled: boolean) => void;
  setCurrentSwitchType: Dispatch<SetStateAction<MaskSwitchType | undefined>>;
}

type ContextProviderProps = {
  children: ReactNode;
  template?: ITemplate;
  row: any;
  editingComposition: TComposition;
};

const Context = createContext<ContextProps & ContextHandlers>(
  {} as ContextProps & ContextHandlers,
);

const ContextProvider = ({
  children,
  template,
  editingComposition,
}: ContextProviderProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isMediaMaskOn, setIsMediaMaskOn] = useState(true);
  const [isTextMaskOn, setIsTextMaskOn] = useState(true);
  const [currentSwitchType, setCurrentSwitchType] = useState<MaskSwitchType>();

  const { updateValueMappings, selectedRow, hoveredMappingKey } =
    useAssetBatchesValueMappingContext();

  const updateTemplateVars = (mappings: Record<string, unknown>) => {
    if (!iframeRef.current) return;
    iframeRef.current.contentWindow?.postMessage(
      {
        type: "updateMappings",
        data: mappings,
      },
      "*",
    );
  };

  const hoverEvent = useCallback(
    (hoverKey?: string) => {
      return new Promise(resolve => {
        if (!iframeRef.current) return resolve(true);
        const { variable } = hoverKey
          ? editingComposition.variables[hoverKey]
          : { variable: undefined };
        iframeRef.current.contentWindow?.postMessage(
          {
            type: "hoverEvent",
            data: variable,
          },
          "*",
        );
        resolve(true);
      });
    },
    [editingComposition],
  );

  useEffect(() => {
    hoverEvent(hoveredMappingKey);
  }, [hoverEvent, hoveredMappingKey]);

  const handlePostMessage = useCallback(
    (e: MessageEvent) => {
      // Ignore any messages we are not expecting
      if (!e.data.hasOwnProperty("initialVars")) return;
      if (Object.keys(editingComposition.variables).length) {
        const mappings = updateValueMappingsForRow(
          editingComposition.variables,
          selectedRow,
        );
        updateTemplateVars(mappings);
        return;
      }
      updateValueMappings(e.data.initialVars, editingComposition);
    },
    [selectedRow, updateValueMappings, editingComposition],
  );

  useEffect(() => {
    const mappings = updateValueMappingsForRow(
      editingComposition.variables,
      selectedRow,
    );
    updateTemplateVars(mappings);
  }, [selectedRow, editingComposition]);

  useEffect(() => {
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [handlePostMessage]);

  const toggleMediaMask = (enabled: boolean) => {
    setIsMediaMaskOn(enabled);
    const mappings = updateValueMappingsForRow(
      editingComposition.variables,
      selectedRow,
      enabled,
      isTextMaskOn,
    );
    updateTemplateVars(mappings);
  };

  const toggleTextMask = (enabled: boolean) => {
    setIsTextMaskOn(enabled);
    const mappings = updateValueMappingsForRow(
      editingComposition.variables,
      selectedRow,
      isMediaMaskOn,
      enabled,
    );
    updateTemplateVars(mappings);
  };

  return (
    <Context.Provider
      value={{
        template,
        iframeRef,
        valueMappings: editingComposition.variables,
        isMediaMaskOn,
        isTextMaskOn,
        currentSwitchType,
        editingComposition,
        toggleMediaMask,
        toggleTextMask,
        setCurrentSwitchType,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const AssetBatchesHtmlRenderProvider = memo(ContextProvider);

export const useAssetBatchesHtmlRenderContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Context must be used within a ContextProvider");
  }

  return context;
};
