import API from "services";
import { useMutation, useQueryClient } from "react-query";

const flipFacebookCampaigns = async (body: {
  campaigns: Record<React.Key, React.Key[]>;
  flipAds: boolean;
  flipDate: string;
}) => {
  const { result, error } = await API.services.adReview.flipFacebookCampaigns(
    body,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useFlipFacebookCampaigns = () => {
  const queryClient = useQueryClient();

  return useMutation<any | null, Error, any>(flipFacebookCampaigns, {
    onSettled: () => {
      queryClient.invalidateQueries("fbCampaign");
    },
  });
};
