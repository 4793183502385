import { memo, useCallback, useEffect, useState } from "react";
import { message, Spin, Modal, notification } from "antd";

import FilterDrawer from "./FilterDrawer";
import ToolbarTable, {
  ToolbarButton,
} from "shared/components/toolbarTable/ToolbarTable";
import AdCardListContainer from "./adList/AdCardListContainer";
import AdTableListContainer from "./adList/AdTableListContainer";
import AdWizard, { ActiveKeyType } from "screens/adLibrary/AdWizard";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  useBatchMutateAds,
  useOptimisticallyMutateAd,
} from "shared/hooks/adLibrary/useMutateAd";
import {
  useBatchMutateAdShells,
  useOptimisticallyMutateAdShell,
} from "shared/hooks/adLibrary/useMutateAdShell";
import { useNavigate } from "react-router-dom";
import { useUser } from "shared/hooks/useUser";
import { getEnvVar, isFeatureEnabled } from "utils/helpers";
import { IAd } from "shared/types/adLibrary";
import { IndustryType } from "shared/types/shared";
import styles from "./AdList.module.scss";
import { getAdCopy, isAllAdsReadyToLoad } from "../utils";
import {
  useAdLibraryFilters,
  useAdLibraryGlobalFilter,
  useAdLibraryIds,
  useAdLibrarySorter,
} from "../shared/hooks/dataListHooks";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { useAds } from "../shared/hooks/useAds";
import { IAdFields } from "../fields";
import { localStorageLayoutKey } from "../constants";
import { useLayoutPreference } from "../shared/hooks/useLayoutPreference";
import { StringParam, useQueryParam } from "use-query-params";
import AdFilter from "./FiltersSection/AdFilter";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import { useCreateAdLoadSession } from "../adLoadV2/hooks/useCreateAdLoadSession";
import { useFetchCampaignPlanners } from "shared/hooks/campaignPlanner/useFetchCampaignPlanner";
import { DeleteAdWithPlannersError } from "./adList/DeleteAdWithsPlannerError";
import { longAlert } from "utils/antd/longAlert/longAlert";

type AdListProps = {
  openAdLoadDrawer?: () => void;
  setCreateAdWizardVisible: (createAdWizardVisible: boolean) => void;
  openImportDrawer: () => void;
  toolbarContentsExtra?: ToolbarButton;
  disabledRows?: Record<string, string>;
};

const AdList = ({
  openAdLoadDrawer,
  setCreateAdWizardVisible,
  openImportDrawer,
  toolbarContentsExtra,
  disabledRows,
}: AdListProps) => {
  const isAdLoadV2Enabled = isFeatureEnabled("ENABLE_AD_LOAD_V2");
  const { createAdLoadSession, isCreatingAdLoadSession } =
    useCreateAdLoadSession();

  const user = useUser();
  const navigate = useNavigate();
  const [adIdToEdit, setAdIdToEdit] = useQueryParam("id", StringParam);

  const { globalFilter, setGlobalFilter } = useAdLibraryGlobalFilter();
  const { sortKey, sortOrder, sortItems } = useAdLibrarySorter();
  const { setSelectedItemIds } = useAdLibraryIds();

  const { data, originalData, selectedItems, isLoading, isError } = useAds({
    includePlanners: true,
  });

  const { data: campaignPlanners } = useFetchCampaignPlanners({
    enabled: true,
  });

  const { filters, resetFilters } = useAdLibraryFilters();

  const enableAdLibraryExports = isFeatureEnabled("ENABLE_AD_LIBRARY_EXPORTS");

  const [layout, setLayout] = useLayoutPreference(localStorageLayoutKey);
  const [modalAction, setModalAction] = useState<"Delete" | "Duplicate" | null>(
    null,
  );
  const [filterDrawer, setFilterDrawer] = useState(false);
  const [showExportDrawer, setShowExportDrawer] = useState(false);
  const industry = getEnvVar("INDUSTRY");
  const [clientType, setClientType] = useState<IndustryType>(
    (industry as IndustryType) || "retail",
  );

  const processingAds = useAppSelector(
    state => state.adLibrary.adImport.processingAds,
  );

  const { mutate: mutateAd } = useOptimisticallyMutateAd();

  const { mutate: mutateAdShell } = useOptimisticallyMutateAdShell();

  const { mutate: batchMutateAds } = useBatchMutateAds();

  const { mutate: batchMutateAdShells } = useBatchMutateAdShells();

  useEffect(() => {
    if (isLoading) return;
    const adToEdit = originalData.find(ad => ad.id === adIdToEdit);
    if (adIdToEdit) {
      adToEdit &&
        setAdForWizard({ adForWizard: adToEdit, tabToDisplay: "create" });
    }
    if (originalData.length && adIdToEdit && !adToEdit) {
      notification.info({
        message: `Ad with id ${adIdToEdit} does not exist`,
      });
      navigate("/ad-library", { replace: true });
    }
  }, [adIdToEdit, originalData, isLoading, navigate]);

  const [expand, setExpand] = useState(false);
  const [displayColumn, setDisplayColumn] = useState(false);

  const [adForWizard, setAdForWizard] = useState<{
    adForWizard: IAd;
    tabToDisplay: ActiveKeyType;
  } | null>(null);

  const onPreviewClick = useCallback(
    (ad: IAd) => setAdForWizard({ adForWizard: ad, tabToDisplay: "preview" }),
    [],
  );

  const onEditClick = useCallback(
    (ad: IAd) => {
      setAdForWizard({ adForWizard: ad, tabToDisplay: "create" });
      setAdIdToEdit(ad.id);
    },
    [setAdIdToEdit],
  );

  if (isError) {
    message.error("Error fetching ads.");
  }

  const deleteAd = (ad: IAd) => {
    mutateAdShell(
      { ...ad, isDeleted: true },
      {
        onError: () => {
          message.error("Error deleting the Ad");
        },
      },
    );
  };

  const duplicateAd = (ad: IAd) => {
    mutateAd(getAdCopy(ad, user), {
      onSuccess: () => {
        message.success(
          `${ad.inputParameters.name} was duplicated successfully`,
        );
      },
      onError: () => {
        message.error("Error duplicating the Ad");
      },
    });
  };

  const onConfirmDelete = () => {
    const itemsWithPlannersOrInstances = selectedItems.filter(
      item => item.planners?.length || item.instances?.length,
    );

    if (itemsWithPlannersOrInstances.length && campaignPlanners) {
      itemsWithPlannersOrInstances.forEach(item => {
        longAlert({
          type: "error",
          header: `Please remove the ad "${item.inputParameters.name}" from the following planners/instances before deleting`,
          body: (
            <DeleteAdWithPlannersError ad={item} planners={campaignPlanners} />
          ),
        });
      });
      setModalAction(null);
      return;
    }

    batchMutateAdShells(
      selectedItems.map(ad => ({ ...ad, isDeleted: true })),
      {
        onSuccess: () => {
          message.success(
            `The ${selectedItems.length} ad${
              selectedItems.length > 1 ? "s" : ""
            } were deleted successfully`,
          );
        },
        onError: () => {
          message.error("Error deleting Ads");
        },
      },
    );
    setModalAction(null);
    setSelectedItemIds([]);
  };

  const onConfirmDuplicate = () => {
    batchMutateAds(
      selectedItems.map(item => getAdCopy(item, user)),
      {
        onSuccess: () => {
          message.success(
            `The ${selectedItems.length} ad${
              selectedItems.length > 1 ? "s were" : " was"
            } duplicated successfully`,
          );
        },
        onError: () => {
          message.error("Error duplicating Ads");
        },
      },
    );
    setModalAction(null);
    setSelectedItemIds([]);
  };

  const ConfirmModal = () => {
    return (
      <Modal
        title={`${modalAction} Ads`}
        data-cy="ad-library-confirm-modal"
        width="300px"
        closable={false}
        visible={!!modalAction}
        okText={modalAction}
        onCancel={() => setModalAction(null)}
        onOk={modalAction === "Delete" ? onConfirmDelete : onConfirmDuplicate}
      >
        {`Are you sure you want to ${modalAction?.toLowerCase()} ${
          selectedItems.length
        } ad${selectedItems.length > 1 ? "s" : ""}?`}
      </Modal>
    );
  };

  const shouldDisableLoadButton = !isAllAdsReadyToLoad(selectedItems);

  const toolbarContents: ToolbarButton = {
    New: {
      disabled: false,
      onClick: () => setCreateAdWizardVisible(true),
    },
    Edit: {
      disabled: selectedItems.length !== 1,
      onClick: () => selectedItems.length && onEditClick(selectedItems[0]),
    },
    Export: {
      disabled: selectedItems.length === 0,
      onClick: () => {
        if (layout !== "table") {
          setLayout("table");
        }
        setShowExportDrawer(true);
      },
      display: enableAdLibraryExports,
    },

    ...(isAdLoadV2Enabled
      ? {
          LoadV2: {
            disabled: shouldDisableLoadButton,
            onClick: () => createAdLoadSession(),
            loading: isCreatingAdLoadSession,
          },
        }
      : {
          Load: {
            disabled: shouldDisableLoadButton,
            onClick: () => openAdLoadDrawer?.(),
          },
        }),
    Duplicate: {
      disabled: !selectedItems.length,
      onClick: () => setModalAction("Duplicate"),
    },
    Delete: {
      disabled: !selectedItems.length,
      onClick: () => setModalAction("Delete"),
    },
    Column: {
      onClick: () => setDisplayColumn(column => !column),
    },
    Import: {
      disabled: processingAds,
      onClick: () => openImportDrawer(),
    },
    Expand: {
      onClick: () => setExpand(expand => !expand),
    },
    Filter: {
      onClick: () => setFilterDrawer(!filterDrawer),
    },
    SelectWithSort: {
      onClick: () => null,
      extraInfo: [
        {
          value: "qcStatus",
          label: "Status",
        },
        {
          value: "type",
          label: "Ad Format",
        },
        {
          value: "createdAt",
          label: "Created",
        },
        {
          value: "updatedBy",
          label: "Last Edit By",
        },
        {
          value: "updatedAt",
          label: "Last Edit",
        },
        {
          value: "adUrl",
          label: "Ad URL",
        },
        {
          value: "destinationUrl",
          label: "Destination URL",
        },
        {
          value: "postCopy",
          label: "Primary Text",
        },
        {
          value: "headline",
          label: "Headline",
        },
        {
          value: "displayUrl",
          label: "Display URL",
        },
        {
          value: "headlineDesctiption",
          label: "Description",
        },
        {
          value: "utm",
          label: "UTM",
        },
        {
          value: "campaignStartDate",
          label: "Start Date",
        },
        {
          value: "campaignEndDate",
          label: "End Date",
        },
        {
          value: "ctaButtonText",
          label: "CTA Button",
        },
        {
          value: "store",
          label: "Store Name",
        },
        {
          value: "year",
          label: "Year",
        },
        {
          value: "version",
          label: "Version",
        },
        {
          value: "strategy",
          label: "Strategy",
        },
        {
          value: "planners",
          label: "Campaign Planners",
        },
      ],
    },
    ...toolbarContentsExtra,
  };

  const generateToolbarContents: (record?: IAd) => ToolbarButton = record => ({
    Edit: {
      onClick: () => record && onEditClick(record),
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <EditOutlined />,
    },
    Duplicate: {
      onClick: () => {
        setModalAction("Duplicate");
        record && setSelectedItemIds([record.id]);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <CopyOutlined />,
    },
    Export: {
      onClick: () => {
        if (layout !== "table") {
          setLayout("table");
        }
        record && setSelectedItemIds([record.id]);
        setShowExportDrawer(true);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <ExportOutlined />,
      display: true,
    },
    Load: {
      onClick: () => {
        record && setSelectedItemIds([record.id]);
        openAdLoadDrawer?.();
      },
      extraInfo: {
        title: "Load to Facebook",
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <FileDoneOutlined />,
    },
    Delete: {
      onClick: () => {
        setModalAction("Delete");
        record && setSelectedItemIds([record.id]);
      },
      showInInlineMenu: true,
      showInContextMenu: true,
      icon: <DeleteOutlined style={{ fill: "red" }} />,
    },
    ...toolbarContentsExtra,
  });

  return (
    <Spin
      spinning={isLoading}
      aria-busy={isLoading}
      data-cy="loading-ads-spinner"
      wrapperClassName={styles.spin}
    >
      <div className={styles.container}>
        <ConfirmModal />
        <FilterDrawer
          filterDrawer={filterDrawer}
          setFilterDrawer={setFilterDrawer}
          isSomeFilterApplied={Object.values(filters).some(value => !!value)}
          resetFilters={resetFilters}
        >
          <AdFilter />
        </FilterDrawer>
        <ToolbarTable
          layout={layout}
          onClickLayout={setLayout}
          toolbarContents={toolbarContents}
          clientType={clientType}
          searchPlaceholder="Search"
          searchValue={globalFilter ?? ""}
          sortingOrder={sortKey && sortOrder ? [sortKey, sortOrder] : undefined}
          onSearch={setGlobalFilter}
          onSortChange={([columnKey, order]) => {
            sortItems(columnKey, order);
          }}
          titleTooltip="Search by any ad information"
        />
        <DataListTagsURL<IAdFields, IAd>
          data={data}
          originalData={originalData}
          showSelectAll={layout === "card"}
        />
        {layout === "table" ? (
          <TableActionsContextProvider
            renderContextActions={(record?: IAd) => (
              <ContextTableActions
                getItemsToRender={generateToolbarContents}
                record={record}
              />
            )}
            renderHoverActions={(record?: IAd) => (
              <InlineTableActions
                getItemsToRender={generateToolbarContents}
                record={record}
              />
            )}
          >
            <AdTableListContainer
              expand={expand}
              displayColumn={displayColumn}
              clientType={clientType}
              setClientType={setClientType}
              setDisplayColumn={setDisplayColumn}
              showExportDrawer={showExportDrawer}
              setShowExportDrawer={setShowExportDrawer}
              onEditClick={onEditClick}
              data={data}
              selectedItems={selectedItems}
              disabledRows={disabledRows}
            />
          </TableActionsContextProvider>
        ) : (
          <AdCardListContainer
            onEditClick={onEditClick}
            onPreviewClick={onPreviewClick}
            onLoadClick={openAdLoadDrawer}
            onDuplicateClick={duplicateAd}
            onDeleteClick={deleteAd}
            useLegacyCard={false}
          />
        )}
        {adForWizard && (
          <AdWizard
            onClose={() => {
              setAdForWizard(null);
              setAdIdToEdit(undefined);
            }}
            adEdit={adForWizard.adForWizard}
            initialActiveTab={adForWizard.tabToDisplay}
          />
        )}
      </div>
    </Spin>
  );
};

export default memo(AdList);
