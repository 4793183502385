import { fabric } from "fabric";
import { useEffect, useRef, useState } from "react";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { ICanvasData, isStamp } from "shared/types/assetBuilder";
import {
  IDimension,
  IExtendedFabricObject,
  isCanvasBackground,
} from "shared/types/designStudio";
import { loadFontsFromJson } from "utils/fabric/helpers.text";
import { extendFabricObject } from "utils/helpers.fabric";
import { clearCanvas, getTransformedObjects } from "../canvas.utils/Parsers";
import { useBrandsFonts } from "../../propertySectionV2/propertyRow/manageText/fontProperty.hooks/useBrandsFonts";

/**
 * This hook is responsbile for re-drawing canvas when needed.
 * - When canvas resized (window resized), render the following.
 *  -> The outer canvas boundary.
 *  -> The canvas area. This need to be re-centered.
 *  -> The content in the canvas.
 */
export default (args: {
  canvas?: fabric.Canvas;
  canvasJson?: { version: string; objects: []; height: number; width: number };
  margin: { top: number; left: number };
  widthDiff: number;
  originalDimension: IDimension;
  canvasDimension: IDimension;
}) => {
  const [canvasArea, setCanvasArea] = useState<IExtendedFabricObject>();
  const brandFonts = useBrandsFonts();

  useDeepEffect(() => {
    args.canvas?.getObjects().forEach(obj => {
      obj.set({
        left: (obj.left || 0) + args.widthDiff,
      });
    });

    args.canvas?.requestRenderAll();
  }, [args.widthDiff]);

  useEffect(() => {
    if (!args.canvas || !args.canvasDimension) return;
    const { width, height } = args.canvasDimension;

    args.canvas.setDimensions({
      width,
      height,
    });
  }, [args.canvasDimension, args.canvas]);

  useDeepEffect(async () => {
    if (!args.canvas) return;

    // When canvas dimension changes, we have to redraw the canvas.
    // Things need to do are:
    //  - reset the dimension of the outer canvas
    //  - re-center the wrapper
    //  - re-render the json contents
    args.canvas.clear();

    const { width, height } = args.originalDimension; // original template dimension

    const canvasArea = extendFabricObject({
      objectType: "canvas_area",
      object: new fabric.Rect({
        width,
        height,
        absolutePositioned: true,
        strokeWidth: 1,
        selectable: false,
        evented: false,
        fill: "lightgray",
        // adjust the position of the canvas area
        left: args.margin.left,
        top: args.margin.top,
      }),
    });

    args.canvas.insertAt(canvasArea, 0, false);
    setCanvasArea(canvasArea);
  }, [args.canvas, args.margin]);

  const activeObjRef = useRef<string>();

  const shouldSkipRendering = (json: any, canvas: fabric.Canvas): boolean => {
    const jsonObjs = json?.objects;
    const canvasObjs = canvas.getObjects();

    // canvasJson is emptied prior to undoing/redoing and other operations that require re-rendering.
    if (!Array.isArray(jsonObjs)) return false;

    // check if all objects are same except bg image. bg image will be handled separately.
    const filter = (obj: any) =>
      !isCanvasBackground(obj) && obj.customType !== "canvas_area";
    const areAllObjsSame = jsonObjs
      .filter(filter)
      .map((obj: any) => obj.name)
      .every((name: string) =>
        canvasObjs
          .filter(filter)
          .map(obj => obj.name)
          .includes(name),
      );

    // In either case, we skip rendering.
    //  Case 1. When both json and canvas has bg object.
    //  Case 2. When both DO NOT have bg object.
    const jsonBg = json?.backgroundImage;
    const canvasBg = canvas
      .getObjects()
      .find((obj: any) => isCanvasBackground(obj));
    const passBgCheck =
      (!Boolean(jsonBg) && !Boolean(canvasBg)) ||
      (Boolean(jsonBg) && Boolean(canvasBg));

    return areAllObjsSame && !!passBgCheck;
  };

  useDeepEffect(() => {
    if (!canvasArea || !args.canvas) return;

    const skipRendering = shouldSkipRendering(args.canvasJson, args.canvas);
    if (skipRendering) return;

    // adjust the position of the canvas area
    canvasArea.set({
      left: args.margin.left,
      top: args.margin.top,
    });

    activeObjRef.current = args.canvas?.getActiveObject()?.name;

    clearCanvas(args.canvas);

    if (!args.canvasJson) return;

    const renderObjects = async (json: any) => {
      // need to load fonts before create text objects
      await loadFontsFromJson(args.canvasJson as ICanvasData, brandFonts);

      const objects = await getTransformedObjects(
        json,
        args.margin,
        canvasArea,
      );

      objects.forEach(obj => {
        // We should allow controls on certain object type.
        // For stamp, thre are templates created when we disabled stamp resize. We need to allow resizing on those templates.
        if (isStamp(obj)) {
          (obj as unknown as fabric.Object).set({
            hasControls: true,
          });
        }
        if (!args.canvas?.getObjects().some(object => object.name === obj.name))
          args.canvas?.add(obj);

        if (activeObjRef.current && obj.name === activeObjRef.current) {
          activeObjRef.current = undefined;

          args.canvas?.setActiveObject(obj);
        }
      });
    };

    renderObjects(args.canvasJson);
  }, [args.canvasJson, args.margin, canvasArea, brandFonts]);

  return {
    canvasArea,
  };
};
