import { useCallback, useState } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Popover, Select, Button, message } from "antd";
import {
  TExtendedTextbox,
  TVariable,
  TVariableAlias,
} from "shared/types/designStudio";

import "./VariableAlias.scss";
import useDeepEffect from "shared/hooks/useDeepEffect";
import * as utils from "./VariableAlias.utils";
import { isEmpty } from "lodash";
import useVariables from "./variableAlias.hooks/useVariables";
import AliasPopoverContent from "./variableAlias/AliasPopoverContent";

export type TAliasAction = "mask" | "caps";

interface VariableAliasProps {
  triggerPosition: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  textbox: TExtendedTextbox;
}

interface VariableAliasHandlers {
  updateTextbox: (
    textbox: TExtendedTextbox,
    alias: TVariableAlias,
    textToUpdate?: string,
  ) => void;
}

const VariableAlias = (props: VariableAliasProps & VariableAliasHandlers) => {
  const variables = useVariables({ textbox: props.textbox });

  const [variableAlias, setVariableAlias] = useState<
    TVariableAlias | undefined
  >(props.textbox.variableAlias);
  const [selectedVariable, setSelectedVariable] = useState<TVariable>();

  useDeepEffect(() => {
    if (!variables || isEmpty(variables)) return;

    setSelectedVariable(prev => prev || variables[0]);

    setVariableAlias(prev => utils.generateVariableAlias(variables, prev));
  }, [variables]);

  const onSwitchChange = useCallback(
    (type: TAliasAction) => (isOn: boolean) => {
      const { value } = selectedVariable || { value: "" };
      const alias = variableAlias?.[value];

      if (isEmpty(alias)) {
        message.error("Unable to mask variable.");

        return;
      }

      const key = type === "mask" ? "isMaskOn" : "isCapsOn";
      const variable = value;

      const updatedAlias: TVariableAlias = {
        ...(variableAlias || {}),
        [variable]: {
          ...(alias! || {}),
          [key]: isOn,
        },
      };
      setVariableAlias(updatedAlias);

      const textToUpdate = utils.generateTextToDisplay(
        props.textbox.text || "",
        updatedAlias,
      );

      props.updateTextbox(props.textbox, updatedAlias, textToUpdate);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [variableAlias, selectedVariable, variables],
  );
  const onVariableSelect = useCallback(
    (value: string) => {
      const variable = variables?.find(item => item.value === value);

      setSelectedVariable(variable);
    },
    [variables],
  );

  return (
    <div
      className="variable-alias-tigger-container"
      style={{
        position: "absolute",
        top: props.triggerPosition.top,
        left: props.triggerPosition.left,
        height: props.triggerPosition.height,
      }}
    >
      <div
        className="trigger-wrapper"
        style={{
          height: props.triggerPosition.height,
          lineHeight: `${props.triggerPosition.height}px`,
        }}
      >
        <Popover
          title={
            <Select
              className="variable-alias-select"
              placeholder="Select variable"
              value={selectedVariable?.value}
              onChange={onVariableSelect}
            >
              {variables?.map(variable => (
                <Select.Option
                  key={`variable-alias-${variable.value}`}
                  value={variable.value}
                >
                  {variable.value}
                </Select.Option>
              ))}
            </Select>
          }
          trigger="click"
          content={
            <AliasPopoverContent
              variables={variables}
              selectedVariable={selectedVariable}
              variableAlias={variableAlias}
              onAliasUpdate={(
                previousVariableAlias,
                updatedAlias,
                aliasInfo,
              ) => {
                setVariableAlias(updatedAlias);

                if (aliasInfo.isMaskOn) {
                  const textToUpdate = utils.generateTextToDisplay(
                    props.textbox.text || "",
                    updatedAlias,
                    previousVariableAlias,
                  );

                  props.updateTextbox(
                    props.textbox,
                    updatedAlias,
                    textToUpdate,
                  );
                }
              }}
              onSwitchChange={onSwitchChange}
            />
          }
        >
          <Button icon={<SettingOutlined />} />
        </Popover>
      </div>
    </div>
  );
};

export default VariableAlias;
