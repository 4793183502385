import { CarOutlined, SmileOutlined, WarningOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  message,
  Modal,
  Select,
  Spin,
  Tooltip,
  Popover,
  Menu,
  Dropdown,
  Col,
  Row,
} from "antd";

import { fabric } from "fabric";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { IConfig } from "shared/types/configuration";

import { offerTypes } from "shared/constants/dataManagement";
import actions from "../../../../../redux/rootActions";

import {
  CanvasProcessing,
  ICustomVideoData,
  IDesignStudioState,
  IDimension,
  IExtendedFabricObject,
  IPreviewOfferDataObj,
  isStamp,
  isTemplate,
  IVariableAlias,
} from "shared/types/designStudio";
import { OfferType } from "shared/types/shared";
import * as textHelpers from "utils/fabric/helpers.text";
import {
  extendFabricObject,
  processCanvasBeforeSerialization,
} from "utils/helpers.fabric";

import { calcPreviewDimension, scaleCanvas } from "utils/fabric/helpers.scale";
import { useClientRect } from "utils/helpers.hooks";
import "./Preview.scss";
import { context } from "shared/components/contextAPI/shared/RenderTemplate";
import { INewOrder } from "shared/types/newOrders";
import { useRenderTemplate } from "shared/components/RenderTemplateProvider";
import {
  ICanvasData,
  IPreviewSelectedOptions,
  TLogoSubstitution,
  VehicleConditions,
  isVideo,
} from "shared/types/assetBuilder";
import { useFetchDealers } from "shared/hooks/useFetchDealersV2";
import { TResource } from "screens/designStudio/Editor.context";
import { createVideoElement } from "utils/media/utils.input";
import useVideoControl from "../canvas.hooks/useVideoControl";
import VideoControl from "../../canvas/VideoControl";
import { useCallback } from "react";
import { useThemeImages } from "./useThemeImages";
import { useJellybeans } from "./useJellybeans";
import { useLogos } from "./useLogos";
import { UseGetTabData } from "shared/hooks/assetBuilder/useGetTabData";
import { useGetOfferTotal } from "../../useGetOfferTotal";

const { SubMenu } = Menu;

interface IPreview {
  config?: IConfig;
  resource?: TResource;
  canvas: fabric.Canvas | null;
  openPreview: boolean;
  onPreviewClose: () => void;
  randomizePreviewData: (
    params: UseGetTabData,
    previewOfferTotal?: number,
    preSelectedOptions?: IPreviewSelectedOptions,
  ) => void;
  randomizingData: CanvasProcessing;
  previewOfferData: IPreviewOfferDataObj | null;
  resetRandomizationData: () => void;
  isDarkBackground: boolean;
  customAttributes: string[];
  variableAlias: IVariableAlias;
  disablePlayButton?: boolean;
}

const Preview: FC<IPreview> = ({
  config,
  canvas: originalCanvas,
  openPreview,
  onPreviewClose,
  randomizePreviewData,
  randomizingData,
  previewOfferData,
  resetRandomizationData,
  isDarkBackground,
  customAttributes,
  variableAlias,
  ...props
}) => {
  const template = useMemo(
    () => (isTemplate(props.resource) ? props.resource : null),
    [props.resource],
  );
  const stamp = useMemo(
    () => (isStamp(props.resource) ? props.resource : null),
    [props.resource],
  );

  const [previewDimension, setPreviewDimension] = useState<IDimension>({
    width: 0,
    height: 0,
  });
  const [, setCanvasWrapperRectRef, canvasWrapperRect] = useClientRect();

  const [selectedOfferTypes, setSelectedOfferTypes] = useState<OfferType[]>([
    OfferType.Lease,
  ]);
  const [accountList, setAccountList] = useState<string[]>([]);
  const [selectedBrand, setSelectedBrand] = useState<string>();
  const [selectedAccountName, setSelectedAccountName] = useState<string>();
  const [selectedBrandLogo, setSelectedBrandLogo] = useState<string>("");
  const [selectedAccountLogo, setSelectedAccountLogo] = useState<string>("");
  const [selectedEventLogo, setSelectedEventLogo] = useState<string>("");
  const [selectedCondition, setSelectedCondition] =
    useState<VehicleConditions>("New");
  const [logoSubs, setLogoSubs] = useState<TLogoSubstitution[]>([]);
  const [selectedThemeImage, setSelectedThemeImage] = useState<string>();
  const [selectedJellybean, setSelectedJellybean] = useState<string>();
  const [loadingJellybean, setLoadingJellybean] = useState<boolean>(false);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const [openLogoMenu, setOpenLogoMenu] = useState<boolean>(false);

  const [initializing, setInitializing] = useState<boolean>(true);
  const canvasElementRef = useRef<HTMLCanvasElement>(null);
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);

  const { dealers } = useFetchDealers();

  const preSelectedOptions: IPreviewSelectedOptions = {
    oem: selectedBrand || "",
    storeName: selectedAccountName || "",
    vehicleCondition: selectedCondition,
  };
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const { themeImages } = useThemeImages(template, config);
  const { jellybeans } = useJellybeans(previewOfferData?.offerData);
  const { oemLogos, storeLogos, eventLogos } = useLogos({
    dealers,
    originalCanvas,
    selectedAccountName,
    selectedBrand,
  });

  // difference from templates and stamps are handeled here
  const { params, feedId, previewOfferTotal } = useGetOfferTotal(
    dealers,
    template,
    stamp,
    preSelectedOptions,
  );

  useEffect(() => {
    setTimeout(() => {
      // The reason we are delaying canvas init here is because the canvas element
      // seems like mounted with some delay from ant-design Modal animation.
      if (!canvasElementRef.current) {
        return;
      }

      const initialCanvas = new fabric.Canvas(canvasElementRef.current);

      initialCanvas.on("mouse:down", () => {
        setIsMouseDown(true);
      });
      initialCanvas.on("mouse:up", () => {
        setIsMouseDown(false);
      });
      setCanvas(initialCanvas);
    }, 1000);
  }, []);

  useEffect(() => {
    if (originalCanvas) {
      const result: TLogoSubstitution[] = [];
      originalCanvas
        .getObjects()
        ?.filter(obj => obj.type === "group")
        .forEach((object: any) => {
          const logoEventType = object?.customData?.logoEventType;

          if (
            (logoEventType === "STORE_LOGO" ||
              logoEventType === "ACCOUNT_LOGO") &&
            selectedAccountLogo
          ) {
            result.push({
              id: object.name,
              order: 0,
              type: object.type,
              currentImageUrl: selectedAccountLogo,
            });
          } else if (
            (logoEventType === "OEM_LOGO" || logoEventType === "BRAND_LOGO") &&
            selectedBrandLogo
          ) {
            result.push({
              id: object.name,
              order: 0,
              type: object.type,
              currentImageUrl: selectedBrandLogo,
            });
          } else if (
            logoEventType === "SALES_EVENT_LOGO" &&
            selectedEventLogo
          ) {
            result.push({
              id: object.name,
              order: 0,
              type: object.type,
              currentImageUrl: selectedEventLogo,
            });
          }
        });
      setLogoSubs(result);
    }
  }, [
    selectedBrandLogo,
    selectedAccountLogo,
    selectedEventLogo,
    originalCanvas,
  ]);

  useEffect(() => {
    // setting preview dimension
    // the rule is followings :
    // if the original canvas fits into the max dimension of the preview, use it.
    // if either of the dimension is grester, take the bigger dimension from the original canvas and fit that into the corresponding dimention os the preview max dimension.
    if (!canvas || !originalCanvas || !canvasWrapperRect) {
      return;
    }

    const width = originalCanvas.getWidth();
    const height = originalCanvas.getHeight();

    const { width: canvasWrapperWidth, height: canvasWrapperHeight } =
      canvasWrapperRect as ClientRect;

    // set max preview dimension
    // take 90% of the wrapper div size
    const maxPreviewDimension: IDimension = {
      width: Math.floor(canvasWrapperWidth * 0.9),
      height: Math.floor(canvasWrapperHeight * 0.9),
    };

    const { width: previewWidth, height: previewHeight } = calcPreviewDimension(
      {
        defaultDimension: maxPreviewDimension,
        originalDimension: {
          width,
          height,
        },
      },
    );

    setPreviewDimension({
      width: previewWidth,
      height: previewHeight,
    });
  }, [canvas, originalCanvas, canvasWrapperRect]);

  useEffect(() => {
    // get stores from store management
    if (dealers) {
      const stores = dealers
        .filter(record => {
          return (
            selectedBrand &&
            record.dealer_oem?.split(",").includes(selectedBrand)
          );
        })
        .map(dealerRecord => dealerRecord.dealer_name);
      selectedBrand && setAccountList([...new Set(stores)] as string[]);
    }
  }, [selectedBrand, dealers]);

  useEffect(() => {
    if (
      !canvas ||
      !originalCanvas ||
      (!template && !stamp) ||
      (previewDimension.width !== 0 && previewDimension.height === 0)
    ) {
      return;
    }

    canvas.setDimensions({
      width: previewDimension.width,
      height: previewDimension.height,
    });

    processCanvasBeforeSerialization(originalCanvas);

    canvas.loadFromJSON(originalCanvas.toJSON(customAttributes), () => {
      scaleCanvas({
        canvas,
        originalDimension: {
          width: originalCanvas.getWidth() || 0,
          height: originalCanvas.getHeight() || 0,
        },
        resizedCanvasDimension: previewDimension,
      });

      replaceVideoObject(canvas);

      setInitializing(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewDimension]);

  const replaceVideoObject = useCallback((canvas: fabric.Canvas) => {
    // If video object found, we need to re-instantiate the object.
    const foundVideoObject = canvas
      .getObjects()
      .find(obj => isVideo(obj as any)) as IExtendedFabricObject; // NOTE: time of writing this, only support one video per template.
    if (!foundVideoObject) return;

    const { top, left, name, width, height, scaleX, scaleY } = foundVideoObject;
    createVideoElement(
      (foundVideoObject.customData as ICustomVideoData).videoSrc,
      "mp4",
    ).then(videoElement => {
      const videoObject = new fabric.Image(videoElement, {
        top,
        left,
        name,
        width,
        height,
        scaleX,
        scaleY,
        lockMovementX: true,
        lockMovementY: true,
      });

      const extendedVideoObject = extendFabricObject({
        object: videoObject,
        objectType: "selected_video",
        properties: { ...foundVideoObject.customData },
      });

      const idx = canvas.getObjects().findIndex(obj => isVideo(obj as any));
      if (idx === -1) {
        message.error("System error! Unable to locate video from template.");
        return;
      }

      canvas.remove(foundVideoObject);
      canvas.insertAt(extendedVideoObject, idx, false);
    });
  }, []);

  const renderTemplateV2 = useContext(context);
  const renderTemplateContext = useRenderTemplate();
  useEffect(() => {
    if (
      !canvas ||
      (randomizingData !== "COMPLETE" && !selectedJellybean) ||
      !originalCanvas
    ) {
      return;
    }

    if (!previewOfferData) {
      if (selectedJellybean) {
        return;
      }
      message.error(
        "There is no offer data available for this preview. Try randomizing again.",
      );
      setInitializing(false);
      return;
    }

    const { dealerName } = previewOfferData;
    const offerData = {
      ...previewOfferData.offerData,
      imageUrl: selectedJellybean
        ? selectedJellybean
        : previewOfferData.offerData.imageUrl,
    };
    const dealerNameToUse = dealerName || offerData.dealerName || "";

    if (template) {
      renderTemplateV2
        ?.reload({
          template:
            {
              ...template,
              canvasJson: originalCanvas.toJSON(customAttributes),
            } || undefined,
          offer: {
            offerData,
            offerTypes: selectedOfferTypes,
          },

          // At the time of writing this, reload() requirs an "order" data in order to fetch dealer.
          // From this "order", the attribute "dealer_name" will be used to fetch dealer data.
          order: {
            dealer_name: dealerNameToUse,
          } as INewOrder,

          renderTemplateCache: {
            canvasJsonMap: {
              [template.id!]: originalCanvas.toJSON(customAttributes),
            },
          },
        })
        .then(data => {
          if (!data) {
            message.error("There was error. Unable to render the template.");

            return;
          }
          renderTemplateV2.setDefaultValues(data).then(prefilledData => {
            renderTemplateV2.renderTemplateOn({
              canvas,
              data: prefilledData,
              logoSubstitutions: logoSubs,
              lifestyleImageUrl: selectedThemeImage,
              order: {
                dealer_name: dealerNameToUse,
              } as INewOrder,
              complete: async () => {
                // complete
                scaleCanvas({
                  canvas,
                  originalDimension: {
                    width: originalCanvas.getWidth() || 0,
                    height: originalCanvas.getHeight() || 0,
                  },
                  resizedCanvasDimension: previewDimension,
                });

                canvas.getObjects().forEach(obj => {
                  obj.set({ selectable: false, evented: false });

                  if (obj.type === "textbox" && template) {
                    textHelpers.dynamicallyResizeDisclosureText(
                      obj,
                      template,
                      canvas.getHeight(),
                      previewDimension.height,
                    );
                  }
                });

                replaceVideoObject(canvas);

                canvas.renderAll();
                setInitializing(false);
                setLoadingJellybean(false);
              },
            });
          });
        });
    } else {
      if (!stamp) return;

      const canvasJsonString = JSON.stringify(
        originalCanvas.toDatalessJSON(customAttributes),
      );
      // TO DO: refactor this to use something similar to renderTemplateV2.reload
      renderTemplateContext
        ?.renderStamp(
          stamp,
          canvasJsonString,
          offerData,
          selectedOfferTypes,
          dealerNameToUse,
        )
        .then((data: ICanvasData) => {
          renderUpdatedCanvas(data);
        });
      canvas.renderAll();
    }
    // eslint-disable-next-line
  }, [
    previewOfferData,
    logoSubs,
    selectedJellybean,
    selectedThemeImage,
    selectedCondition,
  ]);

  const renderUpdatedCanvas = (data: ICanvasData) => {
    if (!canvas || !originalCanvas) {
      return;
    }
    canvas.loadFromJSON(data, () => {
      scaleCanvas({
        canvas,
        originalDimension: {
          width: originalCanvas.getWidth() || 0,
          height: originalCanvas.getHeight() || 0,
        },
        resizedCanvasDimension: previewDimension,
      });
      canvas.getObjects().forEach(obj => {
        obj.set({ selectable: false, evented: false });

        if (obj.type === "textbox" && template) {
          textHelpers.dynamicallyResizeDisclosureText(
            obj,
            template,
            canvas.getHeight(),
            previewDimension.height,
          );
        }
      });
      canvas.renderAll();
      setInitializing(false);
    });
  };

  const loadingPreview = !randomizingData
    ? initializing
    : initializing && randomizingData !== "ERROR";

  const logoMenu = (
    <Menu>
      <SubMenu title="Brand Logo" disabled={!oemLogos.length}>
        {oemLogos.map((logoUrl: string) => {
          return (
            <Menu.Item key={logoUrl}>
              <Card
                hoverable
                onClick={() => {
                  setOpenLogoMenu(false);
                  setSelectedBrandLogo(logoUrl);
                }}
              >
                <img width="100px" height="auto" src={logoUrl} />
              </Card>
            </Menu.Item>
          );
        })}
      </SubMenu>
      <SubMenu title="Account Logo" disabled={!storeLogos.length}>
        {storeLogos.map((logoUrl: string) => {
          return (
            <Menu.Item key={logoUrl}>
              <Card
                hoverable
                onClick={() => {
                  setOpenLogoMenu(false);
                  setSelectedAccountLogo(logoUrl);
                }}
              >
                <img width="100px" height="auto" src={logoUrl} />
              </Card>
            </Menu.Item>
          );
        })}
      </SubMenu>
      <SubMenu title="Event Logo" disabled={!eventLogos.length}>
        {eventLogos.map((logoUrl: string) => {
          return (
            <Menu.Item key={logoUrl}>
              <Card
                hoverable
                onClick={() => {
                  setOpenLogoMenu(false);
                  setSelectedEventLogo(logoUrl);
                }}
              >
                <img width="100px" height="auto" src={logoUrl} />
              </Card>
            </Menu.Item>
          );
        })}
      </SubMenu>
    </Menu>
  );

  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [videoControlInfo] = useVideoControl({
    canvas: canvas || undefined,
    isMouseDown,
    isVideoPlaying,
    margin: {
      left:
        ((canvasWrapperRect?.width || previewDimension.width) -
          previewDimension.width) /
        2,
      top:
        ((canvasWrapperRect?.height || previewDimension.height) -
          previewDimension.height) /
          2 -
        0,
    },
  });

  const brands = useMemo(() => {
    const resource = template || stamp;
    if (!resource) return [];

    if ((resource as any).oem) {
      return [(resource as any)?.oem];
    }
    return resource.oems || [];
  }, [template, stamp]);
  return (
    <Modal
      className="template-preview"
      visible={openPreview}
      onCancel={() => {
        resetRandomizationData();
        onPreviewClose();
      }}
      title="PREVIEW"
    >
      <div className="preview-nav-bar">
        <Row align="bottom" justify="center" gutter={16}>
          {template && !stamp && (
            <Col span={4}>
              <div className="offer-types-container preview-field">
                <div className="title">Select Offer Types</div>
                <div className="field">
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    maxTagCount="responsive"
                    disabled={loadingPreview}
                    value={selectedOfferTypes}
                    onChange={(value: OfferType[]) => {
                      setSelectedOfferTypes(value);
                    }}
                  >
                    {offerTypes.map((offerType, index) => (
                      <Select.Option
                        key={`${offerType[0]}-option-${index}`}
                        value={offerType}
                      >
                        {offerType}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>
          )}
          {template && !stamp && (
            <Col span={2}>
              <div className="offer-condition-container preview-field">
                <div className="title">Condition</div>
                <div className="field">
                  <Select
                    style={{ width: "100%" }}
                    disabled={loadingPreview}
                    value={selectedCondition}
                    onChange={(value: VehicleConditions) => {
                      setSelectedCondition(value);
                    }}
                  >
                    {["New", "Used", "CPO", "All"].map(condition => (
                      <Select.Option key={condition} value={condition}>
                        {condition}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>
          )}
          <Col span={3}>
            <div className="oem-container preview-field">
              <div className="title">Brand</div>
              <div className="field">
                <Select
                  style={{ width: "100%" }}
                  disabled={loadingPreview}
                  value={selectedBrand}
                  onChange={(value: string) => {
                    setSelectedBrand(value);
                  }}
                >
                  {brands.map(brand => (
                    <Select.Option key={brand} value={brand}>
                      {brand}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
          <Col span={4}>
            <div className="store-container preview-field">
              <div className="title">Account</div>
              <div className="field">
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  disabled={loadingPreview || !accountList.length}
                  value={selectedAccountName}
                  filterOption={filterBy("value")}
                  onChange={(value: string) => {
                    setSelectedAccountName(value);
                  }}
                >
                  {accountList.map(accountName => (
                    <Select.Option key={accountName} value={accountName}>
                      <Tooltip title={accountName}>{accountName}</Tooltip>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>

          {template && !stamp && (
            <Col span={4}>
              <div className="theme-background-image-container preview-field">
                <div className="title">Theme Background Image</div>
                <div className="field">
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    disabled={loadingPreview}
                    value={selectedThemeImage}
                    filterOption={filterBy("label")}
                    onChange={(value: string) => {
                      setSelectedThemeImage(value);
                    }}
                  >
                    {themeImages?.map(themeImage => (
                      <Select.Option
                        key={themeImage.id}
                        value={themeImage.url}
                        label={themeImage.name}
                      >
                        <Tooltip title={themeImage.name}>
                          {themeImage.name}
                        </Tooltip>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </Col>
          )}
          <Col span={3}>
            <div className="asset-placement-container preview-field">
              <Button
                ghost={true}
                disabled={loadingPreview || !feedId}
                onClick={async () => {
                  setInitializing(true);
                  randomizePreviewData(
                    params,
                    previewOfferTotal,
                    preSelectedOptions,
                  );
                }}
              >
                Pull Random Data
              </Button>
            </div>
          </Col>
          {template && !stamp && (
            <Col span={1.5}>
              <div
                className="logo-container preview-field-right"
                style={{ marginLeft: "2em" }}
              >
                <div className="title">Logo</div>
                <Dropdown overlay={logoMenu} visible={openLogoMenu}>
                  <SmileOutlined
                    style={{ fontSize: "24px", color: "white" }}
                    onClick={() => {
                      setOpenLogoMenu(!openLogoMenu);
                    }}
                  />
                </Dropdown>
              </div>
            </Col>
          )}
          {template && !stamp && (
            <Col span={1.5}>
              <div className="jellybean-container preview-field-right">
                <div className="title">Jellybean</div>
                <Popover
                  placement="bottomRight"
                  trigger={"click"}
                  visible={openPopover}
                  arrowPointAtCenter
                  content={
                    <div className="preview-popover-content">
                      {jellybeans?.length ? (
                        jellybeans.map(({ id, url, name }) => (
                          <Card
                            hoverable
                            key={id}
                            onClick={() => {
                              setSelectedJellybean(url);
                              setLoadingJellybean(true);
                              setOpenPopover(false);
                            }}
                          >
                            <img
                              width="80px"
                              height="70px"
                              loading="lazy"
                              src={url}
                              style={{ padding: "0.3em", objectFit: "contain" }}
                            />
                            {name}
                          </Card>
                        ))
                      ) : (
                        <Card
                          onClick={() => {
                            setOpenPopover(false);
                          }}
                        >
                          <WarningOutlined
                            style={{ color: "red", padding: "0.5em" }}
                          />
                          {"No image matches found"}
                        </Card>
                      )}
                    </div>
                  }
                >
                  <CarOutlined
                    style={{ fontSize: "24px", color: "white" }}
                    onClick={() => {
                      if (previewOfferData) {
                        setOpenPopover(!openPopover);
                      }
                    }}
                  />
                </Popover>
              </div>
            </Col>
          )}
        </Row>
      </div>
      <div className="preview-template-container">
        <Spin
          spinning={(loadingPreview || loadingJellybean) && !!feedId}
          tip={`${
            randomizingData === "IN_PROGRESS"
              ? "Randomizing data"
              : loadingJellybean
              ? "Loading with new jellybean..."
              : "Loading..."
          }`}
          size="large"
        >
          <div
            ref={
              setCanvasWrapperRectRef as (node: HTMLDivElement | null) => void
            }
            className={`canvas-wrapper ${isDarkBackground ? "dark" : "light"}`}
          >
            <canvas
              ref={canvasElementRef}
              id="preview-canvas"
              // width={previewDimension.width}
              // height={previewDimension.height}
            />

            {videoControlInfo && (
              <VideoControl
                isPlaying={isVideoPlaying}
                videoControlInfo={videoControlInfo}
                onPlay={() => {
                  setIsVideoPlaying(true);
                }}
                onPause={() => {
                  setIsVideoPlaying(false);
                }}
              />
            )}
          </div>
        </Spin>
      </div>
    </Modal>
  );
};

const filterBy = (field: string) => {
  return (input: string, option: any) => {
    try {
      const query = input.toLowerCase();
      const label = option[field].toLowerCase();

      return label.includes(query);
    } catch {
      return false;
    }
  };
};

const mapStateToProps = (state: any) => {
  const {
    designStudio,
    configuration: { config },
  } = state;
  const { randomizingData, previewOfferData } =
    designStudio as IDesignStudioState;

  return {
    config,
    randomizingData,
    previewOfferData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    randomizePreviewData: (
      params: UseGetTabData,
      previewOfferTotal?: number,
      preSelectedOptions?: IPreviewSelectedOptions,
    ) => {
      dispatch(
        actions.designStudio.randomizePreviewData(
          params,
          previewOfferTotal,
          preSelectedOptions,
        ),
      );
    },
    resetRandomizationData: () => {
      dispatch(actions.designStudio.resetRandomizationData());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);
