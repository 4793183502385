/* eslint-disable react/display-name */

import { memo, ReactText, useCallback, useState } from "react";

import { IAd, IAdWithChildren } from "shared/types/adLibrary";

import useSelectedColumns from "shared/hooks/userPreferences/useSelectedColumns";

import "./AdImportTableContainer.scss";
import {
  getValidationForAdId,
  IAdValidations,
} from "screens/adLibrary/adImport/adValidationUtils";
import { useWindowSize } from "shared/hooks/useWindowSize";
import useResizableTable from "shared/hooks/useResizableTable";
import { AdTableColumn, getColumns } from "./adImportTableContainer/columns";
import { IndustryType } from "shared/types/shared";
import { getEnvVar } from "utils/helpers";
import ColumnsFilter from "screens/adLibrary/shared/components/ColumnsFilter";
import { AdPreviewModal } from "screens/adLibrary/shared/AdPreviewModal";
import ExpandIcon from "shared/components/ExpandIcon";
import {
  isChildRecord,
  mapAdCardToAdImportTableRecord,
} from "./adImportTableContainer/adImportTable.utils";
import VirtualTable from "shared/components/VirtualTable";
import { setupEditableCells } from "shared/components/dataList/setupEditableCells";

const filterColumns = (
  columns: AdTableColumn[],
  selectedColumns: string[],
  isColumnSelected: (columnName: string) => boolean,
) => {
  return columns
    .filter(c => isColumnSelected(c.name))
    .sort(
      (a, b) =>
        selectedColumns.indexOf(a.name) - selectedColumns.indexOf(b.name),
    );
};

interface Props {
  ads: IAd[];
  setAd: (adId: string, ad: IAd) => void;
  setAds: (ads: IAd[]) => void;
  displayColumn: boolean;
  defaultColumns?: string[];
  setDisplayColumn: (displayColumn: boolean) => void;
  selectedRowKeys: string[];
  setSelectedRowKeys: (selectedRowKeys: any[]) => void;
  stickyColumns?: string[];
  validationErrors?: IAdValidations[];
}

const AdImportTableContainer = ({
  ads,
  setAd,
  setAds,
  displayColumn,
  setDisplayColumn,
  selectedRowKeys,
  setSelectedRowKeys,
  stickyColumns,
  validationErrors,
}: Props) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const onExpand = useCallback(
    (expanded: boolean, record: IAd): void => {
      if (expanded) {
        setExpandedRowKeys([...expandedRowKeys, record.id]);
      } else {
        setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.id));
      }
    },
    [expandedRowKeys],
  );

  const onToggleExpand = useCallback(
    (record: IAd): void => {
      const keyFound = expandedRowKeys.includes(record.id);
      if (keyFound) {
        setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.id));
      } else {
        setExpandedRowKeys([...expandedRowKeys, record.id]);
      }
    },
    [expandedRowKeys],
  );

  const defaultColumns = [
    "Name",
    "Ad Format",
    "Status",
    "Primary Text",
    "Headline",
    "Description",
    "CTA Button",
  ];
  const { isColumnSelected, selectedColumns, setSelectedColumns } =
    useSelectedColumns(defaultColumns);

  const industry = getEnvVar("INDUSTRY");
  const [clientType, setClientType] = useState<IndustryType>(
    (industry as IndustryType) || "auto",
  );

  const [previewAd, setPreviewAd] = useState<IAd | null>(null);

  const { windowInnerHeight } = useWindowSize();

  const columns = getColumns({
    ads,
    setAd,
    setAds,
    stickyColumns,
    validationErrors,
    setPreviewAd,
    onToggleExpand,
    expandedRowKeys,
  });

  const filteredColumns = filterColumns(
    columns,
    selectedColumns,
    isColumnSelected,
  );

  const { components: resizableComponents, resizableColumns } =
    useResizableTable<IAd>(filteredColumns);

  const handleSave = (ad: IAd) => {
    setAd(ad.id, ad);
  };

  const { components: editableComponents, columns: editableColumns } =
    setupEditableCells({
      columns: resizableColumns ?? [],
      handleSave: handleSave,
    });

  const components = {
    ...resizableComponents,
    ...editableComponents,
  };

  const isDisabled = (
    adRecord: IAdWithChildren,
    validationErrors: IAdValidations[] | undefined,
  ) => {
    if (isChildRecord(adRecord)) return false;
    return (
      getValidationForAdId(adRecord.id, validationErrors ?? [])?.hasErrors ??
      false
    );
  };

  const adRecords: IAdWithChildren[] = ads.map(ad => {
    return {
      ...ad,
      children: ad?.visuals?.cards?.map((card, index) =>
        mapAdCardToAdImportTableRecord(ad, card, index),
      ),
    };
  });

  return (
    <>
      <VirtualTable
        data-cy="ad-import-table-container"
        className="ad-import-table-container"
        scroll={{ y: windowInnerHeight - 245 }}
        rowKey={record => record.id}
        pagination={false}
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys: ReactText[]) => {
            setSelectedRowKeys(selectedRowKeys);
          },
          getCheckboxProps: adRecord => ({
            style: isChildRecord(adRecord) ? { display: "none" } : {},
            disabled: isDisabled(adRecord, validationErrors),
          }),
        }}
        columns={editableColumns}
        components={components}
        dataSource={adRecords}
        expandable={{
          expandedRowKeys,
          onExpand,
          expandIcon: props => (
            <ExpandIcon
              {...props}
              tooltip={
                props.expanded
                  ? "Click to collapse Carousel"
                  : "Click to expand Carousel"
              }
              style={{
                height: 30,
                display: "flex",
                alignItems: "center",
              }}
            />
          ),
          indentSize: 60,
        }}
      />
      <ColumnsFilter
        displayColumn={displayColumn}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        setDisplayColumn={setDisplayColumn}
        columnNames={columns.map(column => column.name)}
        clientType={clientType}
        setClientType={setClientType}
      />
      <AdPreviewModal ad={previewAd} setAd={setPreviewAd} />
    </>
  );
};

export default memo(AdImportTableContainer);
