import { Menu as AntMenu, notification } from "antd";
import { isEqual } from "lodash";
import { NavLink, useLocation } from "react-router-dom";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { IMenu, ISubMenu } from "shared/types/menu";
import { AlexiaModule } from "shared/types/shared";
import * as helpers from "utils/helpers";
import {
  getAlexiaPathFromLocation,
  getEnvVar,
  isFeatureEnabled,
} from "utils/helpers";
import styles from "../Layout.module.scss";
import "./Menu.scss";

interface IProps {
  menus: IMenu[] | undefined;
}

interface IHandlers {
  onMenuButtonClick: () => void;
  setSaveDialog: (input: boolean, path?: string) => void;
}

type MenuProps = IProps & IHandlers;

const Menu = ({ menus, onMenuButtonClick, setSaveDialog }: MenuProps) => {
  const { pathname } = useLocation();
  const currentRootPath = helpers.getAlexiaPathFromLocation(pathname);

  const {
    offerData,
    assetInstances,
    offerDataComparator,
    assetInstanceComparator,
  } = useAppShallowEqualSelector(state => state.assetBuilder);

  const clientName = getEnvVar("CLIENT") || "internal";
  const location = useLocation();
  const showSaveDialog =
    (!isEqual(assetInstanceComparator, assetInstances) ||
      !isEqual(offerDataComparator, offerData)) &&
    location.pathname !== "/asset-builder/orders" &&
    !location.pathname.includes("/review") &&
    (location.pathname.includes("/asset-builder/orders") ||
      location.pathname.includes("/edit-offer"));
  const isAdmin = useIsAdmin();

  return (
    <AntMenu
      theme="light"
      mode="inline"
      defaultSelectedKeys={["/new-orders"]}
      selectedKeys={[currentRootPath]}
      className={styles.siderBody}
    >
      {menus
        ?.filter(
          menu =>
            ![AlexiaModule.ASSET_EXPORT].includes(menu.module) &&
            // hidden menus
            !menu.hidden &&
            // excluding modules
            !(
              clientName === "nu" &&
              // user?.role !== Roles.SUPER_ADMIN &&
              menu.module !== AlexiaModule.AD_LIBRARY
            ),
        )
        .map(menu => {
          const { header, subMenus, Icon } = menu;

          // TODO: this is messy - need to overhaul with permissions and rbac
          // const userAccess = user?.toolAccess?.[menu.module];
          // const NONE = userAccess === "None";

          // When the menu doesn't have submenus it should be in this array
          const defaultHeaders = [
            "Asset Builder",
            "Ad Engine",
            "Custom Feed Module",
            "Legal Lingo V2",
            "Creative Asset Manager",
            "Campaign Planner",
            "AI Tools",
          ];

          const headers = isFeatureEnabled("ENABLE_DESIGN_STUDIO_V2")
            ? [...defaultHeaders, "Design Studio"]
            : defaultHeaders;

          if (headers.includes(menu.header) && !isAdmin) {
            return (
              <div
                key={menu.path}
                className={`menu ${
                  currentRootPath.includes(
                    getAlexiaPathFromLocation(menu.path!),
                  )
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  notification.warning({
                    message: "Warning",
                    description: `Unable to access ${menu.header}. Please reach out to an account admin if you require access.`,
                    placement: "bottomRight",
                  });
                }}
              >
                {Icon && <Icon />}
                <span>{menu.header}</span>
              </div>
            );
          } else if (headers.includes(menu.header) && !menu.hidden && isAdmin) {
            return (
              <div key={menu.path}>
                <NavLink
                  onClick={() => {
                    if (showSaveDialog) {
                      setSaveDialog(true);
                    } else {
                      onMenuButtonClick();
                    }
                  }}
                  to={showSaveDialog ? location.pathname : menu.path!}
                >
                  <div
                    className={`menu ${
                      currentRootPath.includes(
                        getAlexiaPathFromLocation(menu.path!),
                      )
                        ? "selected"
                        : ""
                    }`}
                  >
                    {Icon && <Icon />}
                    <span>{menu.header}</span>
                  </div>
                </NavLink>
              </div>
            );
          }

          /* else if (
            brandsAccountsEnabled &&
            menu.module === AlexiaModule.MULTI_BRAND
          ) {
            return <BrandSelect key={header} bordered={true} />;
          }*/

          return (
            <AntMenu.SubMenu
              popupClassName="sub-menu"
              key={header}
              title={
                <span>
                  {Icon && <Icon />}
                  <span>{header}</span>
                </span>
              }
            >
              {subMenus?.map((subMenu: ISubMenu) => {
                const { header, path, hidden } = subMenu;

                if (!isAdmin) {
                  return (
                    !hidden && (
                      <AntMenu.Item
                        onClick={() => {
                          notification.warning({
                            message: "Warning",
                            description: `Unable to access ${name}. Please reach out to an account admin if you require access.`,
                            placement: "bottomRight",
                          });
                        }}
                        key={path}
                      >
                        {header}
                      </AntMenu.Item>
                    )
                  );
                }

                return (
                  !hidden && (
                    <AntMenu.Item key={path}>
                      <NavLink
                        onClick={() => {
                          if (showSaveDialog) {
                            setSaveDialog(true, path);
                          } else {
                            onMenuButtonClick();
                          }
                        }}
                        to={showSaveDialog ? location.pathname : path || ""}
                      >
                        {header}
                      </NavLink>
                    </AntMenu.Item>
                  )
                );
              })}
            </AntMenu.SubMenu>
          );
        })}
    </AntMenu>
  );
};

export default Menu;
