import {
  CSSProperties,
  useState,
  useEffect,
  useMemo,
  ReactElement,
  JSXElementConstructor,
} from "react";
import { Button, Input, message, Select } from "antd";
import { useQuery } from "react-query";

import { createDisplayTitle } from "../facebookUtils/utils";
import { getFacebookAssetQuery } from "../facebookUtils/assetQuery";

import {
  IFacebookAccount,
  IFacebookObject,
  IFacebookAssetType,
} from "../facebookUtils/types";
import { IAccount } from "shared/types/accountManagement";
import styles from "./FacebookAssetSelect.module.scss";
interface IFacebookAssetSelectHandlersProps {
  disabled?: boolean;
  initialValue: string;
  /** Use value if you want to control the component, value should match `initialValue` */
  value?: string;
  style?: CSSProperties;
  title: IFacebookAssetType;
  willSetValueOnLoad?: boolean;
  createPixelSuccess?: boolean;
  selectedAccount?: IFacebookAccount | null;
  selectedStore?: IAccount;
  preLoadedData?: IFacebookObject[];
  pageId?: string;
  facebookNull?: boolean;
}

interface IFacebookAssetSelectHandlers {
  setFacebookIntegration: (facebookIntegration: boolean) => void;
  onSelect: (selected: IFacebookObject | null) => void;
  createPixel?: (value: string) => void;
}

const FacebookAssetSelect = ({
  title,
  style,
  disabled,
  initialValue,
  value,
  selectedAccount,
  createPixelSuccess,
  willSetValueOnLoad,
  preLoadedData,
  selectedStore,
  pageId,
  facebookNull,

  setFacebookIntegration,
  onSelect,
  createPixel,
}: IFacebookAssetSelectHandlersProps & IFacebookAssetSelectHandlers) => {
  const {
    data,
    isLoading,
    isFetching,
    error: fetchError,
    isError: isFetchError,
  } = useQuery(
    [
      `facebook_${title}s`,
      pageId,
      createPixelSuccess,
      title === "pixel" && selectedAccount,
    ],
    getFacebookAssetQuery(title, selectedAccount, pageId),
    {
      retry: false,
      cacheTime: 300000,
      staleTime: Infinity,
      enabled: !preLoadedData,
    },
  );

  useEffect(() => {
    if (isFetchError) {
      setFacebookIntegration(false);
      message.error(
        `Facebook authentication failed. ${
          (fetchError as any)?.error?.message || ""
        }`,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchError]);

  const loading = isLoading || isFetching;
  const [searchInput, setSearchInput] = useState("");
  const [selected, setSelected] = useState<IFacebookObject | null>(null);
  const [pixelInput, setPixelInput] = useState<string>("");

  const availableData = useMemo(() => {
    const catalogIdPlaceholder =
      title === "catalog" ? [{ id: "", name: "No Catalog Selected" }] : [];

    return catalogIdPlaceholder
      .concat(preLoadedData || data?.result?.data || [])
      .filter((asset: IFacebookObject) => {
        const name = asset.name.toLowerCase();
        const accountId = asset.id;
        const searchInputs = searchInput.toLowerCase();
        return (
          name.startsWith(searchInputs) ||
          name.includes(searchInputs) ||
          accountId.startsWith(searchInputs)
        );
      });
  }, [preLoadedData, data, searchInput, title]);

  useEffect(() => {
    const initialObj = availableData?.find(obj => obj.id === initialValue);

    let matchedObj = initialObj;
    if (!!selectedAccount?.name) {
      matchedObj = availableData?.find(
        obj =>
          obj.name.includes(selectedAccount?.name || "") ||
          (title == "catalog" &&
            selectedStore?.details.facebook.fbCatalogId === obj.id),
      );
    }

    const objToUse = initialObj ?? matchedObj;

    if (objToUse) {
      setSelected(objToUse);
      if (!willSetValueOnLoad) return;
      onSelect(objToUse);
    } else {
      setSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedAccount, selectedStore, value, availableData]);

  const additionalDropdownRenders = (
    menu: ReactElement<any, string | JSXElementConstructor<any>>,
  ) => {
    const handleClick = () => {
      if (!selectedAccount?.id) {
        message.error(
          "Please select an Ad Account prior to generating the Pixel.",
        );
        return;
      }
      createPixel && createPixel(pixelInput);
    };

    switch (title) {
      case "pixel":
        return (
          <div>
            {menu}
            <div className={styles.pixelInput}>
              <Input
                value={pixelInput}
                onChange={event => setPixelInput(event.target.value)}
                placeholder="enter pixel ID"
                style={{ width: "60%" }}
              />
              <Button style={{ float: "right" }} onClick={handleClick}>
                + Add Item
              </Button>
            </div>
          </div>
        );
      default:
        return <div>{menu}</div>;
    }
  };

  const getValue = () => {
    if (facebookNull && !selected) {
      return loading ? "Fetching Facebook Page records..." : "";
    }

    return !disabled ? createDisplayTitle(selected) : undefined;
  };

  return (
    <Select
      filterOption={false}
      style={style}
      loading={loading}
      value={getValue()}
      showSearch={true}
      onSearch={(value: string) => {
        setTimeout(() => {
          setSearchInput(value);
        }, 250);
      }}
      disabled={disabled ?? (!selectedAccount || loading)}
      onBlur={() => setSearchInput("")}
      onSelect={(value: string) => {
        if (!!value) {
          const selectedAsset = availableData.find(obj => obj.id === value);
          setSelected(selectedAsset ?? null);
          onSelect(selectedAsset ?? null);
        } else {
          setSelected(null);
          onSelect({
            id: "",
            name: "No FB Page Id Selected -",
          });
        }
      }}
      placeholder={
        !selectedAccount
          ? ""
          : loading
          ? `Fetching ${title}s...`
          : `Select ${title}`
      }
      notFoundContent={`Facebook ${title}s not found`}
      dropdownRender={additionalDropdownRenders}
    >
      {facebookNull && (
        <Select.Option value={""}>No FB Page Id Selected -</Select.Option>
      )}

      {availableData?.map((obj: IFacebookObject) => {
        const displayValue = createDisplayTitle(obj);
        return (
          <Select.Option value={obj?.id} key={obj.id}>
            {displayValue}
          </Select.Option>
        );
      })}

      {title === "instagram" && (
        <Select.Option value={""}>No Account Selected -</Select.Option>
      )}
    </Select>
  );
};

export default FacebookAssetSelect;
