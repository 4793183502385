import { useMemo } from "react";
import { useQuery } from "react-query";
import API from "services";
import { IAssetGroup } from "shared/types/adLibrary";

type Args = {
  selectedIds: string[];
};
export default (args: Args) => {
  const { data: assetGroups, isLoading } = useQuery<IAssetGroup[], Error>(
    ["asset-groups"],
    () => {
      return API.services.adLibrary.getAssetGroups();
    },
  );

  const { selectedIds } = args;
  const selectedAssetGroups = useMemo(
    () => assetGroups?.filter(data => selectedIds.includes(data.id)) || [],
    [selectedIds, assetGroups],
  );

  return {
    isFetching: isLoading,
    assetGroups: assetGroups || [],
    selectedAssetGroups,
  };
};
