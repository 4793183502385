import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Popover, Space, Typography } from "antd";
import { queryClient } from "queryClient";
import { useCallback } from "react";
import { useUser } from "shared/hooks/useUser";
import { getEnvVar } from "utils/helpers";
import styles from "./UserProfilePopover.module.scss";

const UserProfilePopover = () => {
  const { logout } = useAuth0();
  const user = useUser();
  const { name = "Alexia" } = user || {};

  const appVersion = `v. ${getEnvVar("VERSION") || "0.0.0"}`;

  const userLogout = useCallback(() => {
    const asyncUserLogout = async () => {
      queryClient.clear();
      // Remove WD data.
      localStorage.removeItem("media");
      logout({ returnTo: window.location.origin });
    };
    asyncUserLogout();
  }, [logout]);

  return (
    <>
      <Popover
        trigger="hover"
        placement="topRight"
        content={
          <Space direction="vertical" size={4}>
            <Typography.Title level={4}>{`${name}`}</Typography.Title>

            <div>
              <Button
                danger
                type="primary"
                className={styles.popoverItem}
                onClick={userLogout}
              >
                Sign Out
              </Button>
              <span className={styles.appVersion}>{appVersion}</span>
            </div>
          </Space>
        }
      >
        <Avatar
          gap={5}
          size={24}
          data-cy="user-avatar"
          style={{ backgroundColor: "#061178" }}
        >
          {name.charAt(0).toUpperCase()}
        </Avatar>
      </Popover>
    </>
  );
};

export default UserProfilePopover;
