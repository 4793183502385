import { intersection } from "lodash";
import { AdLibraryField, IAd } from "shared/types/adLibrary";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import {
  checkArrayIncludes,
  checkDateMatch,
  checkFilterMatch,
  compareString,
  formatDateValue,
  titleCase,
  getQcStatus,
} from "utils/helpers";

type Fields = {
  name: AdLibraryField;
  type: AdLibraryField;
  qcStatus: AdLibraryField;
  createdAt: AdLibraryField;
  updatedAt: AdLibraryField;
  updatedBy: AdLibraryField;
  destinationUrl: AdLibraryField;
  postCopy: AdLibraryField;
  headline: AdLibraryField;
  displayUrl: AdLibraryField;
  headlineDescription: AdLibraryField;
  utm: AdLibraryField;
  campaignStartDate: AdLibraryField;
  campaignEndDate: AdLibraryField;
  audiences: AdLibraryField;
  ctaButtonText: AdLibraryField;
  tags: AdLibraryField;
  store: AdLibraryField;
  adAccount: AdLibraryField;
  package: AdLibraryField;
  oem: AdLibraryField;
  brand: AdLibraryField;
  modelYear: AdLibraryField;
  modelName: AdLibraryField;
  year: AdLibraryField;
  month: AdLibraryField;
  version: AdLibraryField;
  strategy: AdLibraryField;
  storyPlacement: AdLibraryField;
  category: AdLibraryField;
  createdBy: AdLibraryField;
  inMarketStartDate: AdLibraryField;
  inMarketEndDate: AdLibraryField;
  adUrl: AdLibraryField;
  source: AdLibraryField;
  campaignStatus: AdLibraryField;
  planners: AdLibraryField;
};

export type IAdFields = keyof Fields;

export const fields: Fields = {
  name: {
    filterFn: (value: any, record: IAd) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.name?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.inputParameters?.name, b.inputParameters?.name);
    },
    queryParamConfigFilter: StringParam,
  },
  type: {
    filterFn: (value, record) => {
      return checkArrayIncludes(value, record.type);
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.type, b.type);
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  qcStatus: {
    filterFn: (value, record) => {
      const currentStatus = getQcStatus(record, record.validation?.isValid);
      return checkArrayIncludes(value, currentStatus);
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.qcStatus, b.qcStatus);
    },
    display: titleCase,
    queryParamConfigFilter: DelimitedArrayParam,
  },
  createdAt: {
    filterFn: (value, record) => {
      return checkDateMatch(value?.toString(), record.createdAt);
    },
    sorterFn(a: IAd, b: IAd) {
      return a.createdAt - b.createdAt;
    },
    defaultSortOrder: "descend",
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
    queryParamConfigFilter: StringParam,
  },
  updatedAt: {
    filterFn: (value, record) => {
      return checkDateMatch(value?.toString(), record.updatedAt);
    },
    sorterFn(a: IAd, b: IAd) {
      return a.updatedAt - b.updatedAt;
    },
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
    queryParamConfigFilter: StringParam,
  },
  updatedBy: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.updatedBy?.toString());
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.updatedBy, b.updatedBy);
    },
    queryParamConfigFilter: StringParam,
  },
  destinationUrl: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.destinationUrl?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.inputParameters?.destinationUrl,
        b.inputParameters?.destinationUrl,
      );
    },
    queryParamConfigFilter: StringParam,
  },
  postCopy: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.visuals?.postCopy?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.visuals?.postCopy, b.visuals?.postCopy);
    },
    queryParamConfigFilter: StringParam,
  },
  headline: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.visuals?.headline?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.visuals?.headline, b.visuals?.headline);
    },
    queryParamConfigFilter: StringParam,
  },
  displayUrl: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.visuals?.displayUrl?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.visuals?.displayUrl, b.visuals?.displayUrl);
    },
    queryParamConfigFilter: StringParam,
  },
  headlineDescription: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.visuals?.headlineDescription?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.visuals?.headlineDescription,
        b.visuals?.headlineDescription,
      );
    },
    queryParamConfigFilter: StringParam,
  },
  utm: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.utm?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.inputParameters?.utm, b.inputParameters?.utm);
    },
    queryParamConfigFilter: StringParam,
  },
  campaignStartDate: {
    filterFn: (value, record) => {
      return checkDateMatch(
        value?.toString(),
        parseInt(record.inputParameters?.campaignStartDate || "0"),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.inputParameters?.campaignStartDate,
        b.inputParameters?.campaignStartDate,
      );
    },
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
    queryParamConfigFilter: StringParam,
  },
  campaignEndDate: {
    filterFn: (value, record) => {
      return checkDateMatch(
        value?.toString(),
        parseInt(record.inputParameters?.campaignEndDate || "0"),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.inputParameters?.campaignEndDate,
        b.inputParameters?.campaignEndDate,
      );
    },
    display(value) {
      return value.split(" ").map(formatDateValue).join(" - ");
    },
    queryParamConfigFilter: StringParam,
  },
  audiences: {
    filterFn: (value, record) => {
      return !!record.inputParameters?.audiences?.some(v => value.includes(v));
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  ctaButtonText: {
    filterFn: (value, record) => {
      return value.includes(record.visuals?.ctaButtonText);
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.visuals?.ctaButtonText, b.visuals?.ctaButtonText);
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  tags: {
    filterFn: (value, record) => {
      return !!record.inputParameters?.tags?.some(tag =>
        value.some((v: string) => tag?.toLowerCase().includes(v.toLowerCase())),
      );
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  store: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.client?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.inputParameters?.client,
        b.inputParameters?.client,
      );
    },
    queryParamConfigFilter: StringParam,
  },
  adAccount: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.client?.toString(),
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.inputParameters?.client,
        b.inputParameters?.client,
      );
    },
    queryParamConfigFilter: StringParam,
  },
  package: {
    filterFn: (value, record) => {
      const arr = record.inputParameters?.package
        ?.split(",")
        .map((char: string) => char.trim());

      return !!arr?.some((v: string) => value.includes(v));
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  oem: {
    filterFn: (value, record) => {
      return !!record.inputParameters?.oems?.some(v => value.includes(v));
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },

  brand: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.oems?.join(", "),
      );
    },
    queryParamConfigFilter: StringParam,
  },
  modelYear: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.models?.map(model => model.year).join(","),
      );
    },
    queryParamConfigFilter: StringParam,
  },
  modelName: {
    filterFn: (value, record) => {
      return checkFilterMatch(
        value?.toString(),
        record.inputParameters?.models?.map(model => model.name).join(","),
      );
    },
    queryParamConfigFilter: StringParam,
  },
  year: {
    filterFn: (value, record) => {
      return record.category?.indexOf(value?.toString()) === 0;
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.inputParameters?.year, b.inputParameters?.year);
    },
    queryParamConfigFilter: StringParam,
  },
  month: {
    filterFn: (value: string[], record) => {
      return (
        intersection(value, record.inputParameters?.month).length ==
        value.length
      );
    },
    queryParamConfigFilter: StringParam,
  },
  version: {
    filterFn: (value, record) => {
      return record.category?.indexOf(value?.toString()) === 0;
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.inputParameters?.version,
        b.inputParameters?.version,
      );
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  strategy: {
    filterFn: (value, record) => {
      return record.inputParameters?.strategy?.indexOf(value as string) === 0;
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.inputParameters?.strategy,
        b.inputParameters?.strategy,
      );
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  storyPlacement: {
    filterFn: (value, record) => {
      return (
        record.inputParameters?.storyPlacement?.indexOf(value as string) === 0
      );
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(
        a.inputParameters?.storyPlacement,
        b.inputParameters?.storyPlacement,
      );
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
  category: {
    filterFn: (value, record) => {
      return value.includes(record.category);
    },
    queryParamConfigFilter: StringParam,
  },
  createdBy: {
    filterFn: (value, record) => {
      return checkFilterMatch(value?.toString(), record.updatedBy?.toString());
    },
    queryParamConfigFilter: StringParam,
  },
  inMarketStartDate: {
    filterFn: ([value], record) => {
      const startDate = record.inputParameters?.campaignStartDate;
      const val = parseInt(value);

      if (startDate && !Number.isNaN(val)) {
        return parseInt(startDate) >= val;
      }

      return false;
    },
    display: formatDateValue,
    queryParamConfigFilter: StringParam,
  },
  inMarketEndDate: {
    filterFn: ([value], record) => {
      const endDate = record.inputParameters?.campaignEndDate;
      const val = parseInt(value);

      if (endDate && !Number.isNaN(val)) {
        return parseInt(endDate) <= val;
      }

      return false;
    },
    display: formatDateValue,
    queryParamConfigFilter: StringParam,
  },
  adUrl: {
    filterFn: (value, record) => {
      return checkArrayIncludes(value, record.qcStatus);
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.adUrl, b.adUrl);
    },
    display: titleCase,
    queryParamConfigFilter: DelimitedArrayParam,
  },
  source: {
    filterFn: (value, record) => {
      return record.category?.indexOf(value?.toString()) === 0;
    },
    sorterFn(a: IAd, b: IAd) {
      return compareString(a.adUrl, b.adUrl);
    },
    display: titleCase,
    queryParamConfigFilter: StringParam,
  },
  campaignStatus: {
    filterFn: (value, record) => {
      return record.campaignStatus?.indexOf(value?.toString()) === 0;
    },
    sorterFn(a, b) {
      return compareString(a.campaignStatus, b.campaignStatus);
    },
    display: titleCase,
    queryParamConfigFilter: DelimitedArrayParam,
  },
  planners: {
    filterFn: (value, record) => {
      return !![...(record.planners || []), ...(record.instances || [])].some(
        planner =>
          value.some((v: string) =>
            planner?.name?.toLowerCase().includes(v.toLowerCase()),
          ),
      );
    },
    queryParamConfigFilter: DelimitedArrayParam,
  },
};

export const adFieldKeys = Object.keys(fields) as Array<keyof typeof fields>;
