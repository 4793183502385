import { Modal, message } from "antd";
import { useState, useCallback } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useEditorContext } from "screens/designStudio/Editor.context";
import useLayers, {
  TLayerItem,
} from "screens/designStudio/editor.hooks/useLayers";

type SelectedLayerType = {
  layer: TLayerItem;
  editing: boolean;
};

export const useDesignStudioEditorLayers = () => {
  const { canvasJson, setLayerAction, setCanvasAction } = useEditorContext();
  const [selectedLayer, setSelectedLayer] = useState<SelectedLayerType>();
  const [layers, setLayers] = useLayers({
    json: canvasJson,
  });

  const onLayerSingleClick = useCallback(
    layer => {
      const isCurrentLayer = selectedLayer?.layer?.id === layer.id;
      const isEditing = selectedLayer?.editing;
      if (isCurrentLayer && isEditing) return;
      setSelectedLayer({ layer, editing: false });
      setCanvasAction({
        target: layer.original,
        action: {
          type: "objectSelected",
        },
      });
      setLayerAction({
        layer,
        action: {
          type: "selected",
          data: [layer],
        },
      });
    },
    [setCanvasAction, setLayerAction, selectedLayer],
  );

  const onLayerDoubleClick = (layer: TLayerItem) => {
    setSelectedLayer({ layer, editing: true });
  };

  const onToggleLayerVisibility = (layer: TLayerItem) => {
    setLayerAction({
      layer,
      action: {
        type: "toggle",
        data: !layer.original.visible,
      },
    });
  };

  const onToggleLayerLock = (layer: TLayerItem) => {
    setLayerAction({
      layer,
      action: {
        type: "lock",
        data: !layer.original.selectable,
      },
    });
  };

  const onDeleteLayer = (layer: TLayerItem) => {
    Modal.confirm({
      title: "Delete this object from the canvas?",
      onOk: () => {
        setLayerAction({
          layer,
          action: {
            type: "delete",
          },
        });
      },
      okText: "Delete",
      okType: "danger",
    });
  };

  const onLayerDragEnd = (e: DropResult) => {
    {
      const { source, destination } = e;
      if (!source || !destination) {
        message.error("Layer can only be moved within the list");
        return;
      }

      const doesBackgroundLayerExist = layers.find(
        layer => layer.type === "canvas_bg",
      );

      const toIndex = destination.index;
      const fromIndex = source.index;
      const layersCopy = [...layers];

      if (doesBackgroundLayerExist && toIndex === layers.length - 1) {
        message.error('You cannot re-order "Background".');
        return;
      }

      const movedLayer = { ...layersCopy[fromIndex] };
      const layersCopyWithoutMovedLayer = layersCopy.filter(
        (_, index) => index !== fromIndex,
      );

      setLayers([
        ...layersCopyWithoutMovedLayer.slice(0, toIndex),
        movedLayer,
        ...layersCopyWithoutMovedLayer.slice(toIndex),
      ]);

      const reversedFromIndex = layers.length - 1 - fromIndex;
      const reversedToIndex = layers.length - 1 - toIndex;
      setLayerAction({
        action: {
          type: "re-order",
          data: {
            from: {
              index: reversedFromIndex,
            },
            to: {
              index: reversedToIndex,
            },
          },
        },
      });
    }
  };

  const onLayerKeyDown = (
    layer: TLayerItem,
    cleanUp: () => void,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (selectedLayer?.layer.id !== layer.id) return;
    if (e.key === "Enter") {
      setLayerAction({
        layer: layer,
        action: {
          type: "rename",
          data: e.currentTarget.value,
        },
      });
      setSelectedLayer({ layer, editing: false });
    }

    if (e.key === "Escape") {
      cleanUp();
      setSelectedLayer({ layer, editing: false });
    }
  };

  const onLayerClickOutside = (layer: TLayerItem) => {
    if (selectedLayer?.layer?.id !== layer.id) return;
    setSelectedLayer(undefined);
    setLayerAction({
      layer,
      action: {
        type: "selected",
        data: [],
      },
    });
  };

  return {
    layers,
    setLayers,
    selectedLayer,
    onLayerSingleClick,
    onLayerClickOutside,
    onLayerDragEnd,
    onLayerDoubleClick,
    onLayerKeyDown,
    onToggleLayerVisibility,
    onToggleLayerLock,
    onDeleteLayer,
  };
};
