import {
  EyeOutlined,
  EyeInvisibleOutlined,
  LockOutlined,
  UnlockOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { TLayerItem } from "screens/designStudio/editor.hooks/useLayers";
import styles from "../LayerItem.module.scss";

type LayerActionProps = {
  layer: TLayerItem;
  onClick: (layer: TLayerItem) => void;
};

export const ToggleLayerVisibility = ({ layer, onClick }: LayerActionProps) => {
  const isVisible = layer.original?.visible ?? true;

  return (
    <span
      onClick={e => {
        e.stopPropagation();
        onClick(layer);
      }}
    >
      {isVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
    </span>
  );
};

export const ToggleLayerLock = ({ layer, onClick }: LayerActionProps) => {
  const isLocked = layer.original?.selectable ?? true;

  return (
    <span
      onClick={e => {
        e.stopPropagation();
        onClick(layer);
      }}
    >
      {isLocked ? (
        <UnlockOutlined />
      ) : (
        <LockOutlined className={styles.lockedIcon} />
      )}
    </span>
  );
};

export const DeleteLayer = ({ layer, onClick }: LayerActionProps) => {
  return (
    <span
      onClick={e => {
        e.stopPropagation();
        onClick(layer);
      }}
    >
      <DeleteOutlined />
    </span>
  );
};
