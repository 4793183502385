import Icon, {
  CarOutlined,
  DollarOutlined,
  ShopOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { notification } from "antd";
import { capitalize, castArray, lowerCase } from "lodash";
import {
  AdCategory,
  ConflictStatus,
  IAd,
  IAdDisplay,
  QcStatus,
} from "shared/types/adLibrary";
import { FieldData, FieldDataName } from "shared/types/form";
import { AppColors } from "shared/types/shared";
import {
  formatDateValue,
  isEnvVarEquals,
  isFeatureEnabled,
  titleCase,
  getQcStatus,
} from "utils/helpers";
import {
  bearabyStatuses,
  defaultQcStatuses,
  truecarStatuses,
  klarncoStatuses,
  henryRoseStatuses,
  dynamicAdList,
  callToActionValues,
  fileSizeLimits,
  pharmaStatuses,
  londreStatuses,
} from "./constants";
import { AdType, CallToAction } from "./facebookUtils/types";
import { v4 as uuid } from "uuid";
import { User } from "redux/auth/auth.slice";

const getIconNameAdCategory = (adCategory?: AdCategory): typeof Icon | null => {
  switch (adCategory) {
    case AdCategory.EVENT:
      return StarOutlined;
    case AdCategory.RETAIL:
      return DollarOutlined;
    case AdCategory.MODEL:
      return CarOutlined;
    case AdCategory.DEALER_BRANDING:
      return ShopOutlined;
    default:
      return null;
  }
};

export const getColorFromQcStatus = (qcStatus: QcStatus): string => {
  const isBearaby = isEnvVarEquals("CLIENT", "bearaby");
  const isLondre = isEnvVarEquals("CLIENT", "londre");
  const isTrueCar = isEnvVarEquals("CLIENT", "truecar");
  const isKlarnco = isEnvVarEquals("CLIENT", "klarnco");
  const isHenryRose = isEnvVarEquals("CLIENT", "henry-rose");

  if (isBearaby) {
    switch (qcStatus) {
      case QcStatus.READY_TO_LOAD:
        return AppColors.success;
      case QcStatus.CREATIVE_EDITS:
        return AppColors.danger;
      case QcStatus.BEARABY_REVIEW:
        return AppColors.warning;
      case QcStatus.CREATIVE_APPROVED:
        return AppColors.primary;
      case QcStatus.ERROR:
        return AppColors.danger;
      default:
        return AppColors.secondary;
    }
  }

  if (isLondre) {
    switch (qcStatus) {
      case QcStatus.READY_TO_LOAD:
        return AppColors.success;
      case QcStatus.CREATIVE_EDITS:
        return AppColors.warning;
      case QcStatus.CLIENT_REVIEW:
        return AppColors.danger;
      case QcStatus.CREATIVE_APPROVED:
        return AppColors.primary;
      case QcStatus.CREATIVE_BRIEF_COMPLETE:
        return AppColors.briefComplete;
      case QcStatus.ERROR:
        return AppColors.danger;
      default:
        return AppColors.secondary;
    }
  }

  if (isTrueCar) {
    switch (qcStatus) {
      case QcStatus.APPROVED:
      case QcStatus.TRUECAR_CO_OP_APPROVED:
      case QcStatus.TRUECAR_INTERNALLY_APPROVED:
        return AppColors.success;
      case QcStatus.DISAPPROVED:
        return AppColors.danger;
      case QcStatus.AWAITING_APPROVAL:
        return AppColors.warning;
      case QcStatus.DRAFT:
        return AppColors.primary;
      case QcStatus.ERROR:
        return AppColors.danger;
      default:
        return AppColors.secondary;
    }
  }

  if (isKlarnco) {
    switch (qcStatus) {
      case QcStatus.APPROVED:
        return AppColors.success;
      case QcStatus.EDITS_NEEDED:
        return AppColors.danger;
      case QcStatus.CREATIVE_UPDATED:
        return AppColors.warning;
      case QcStatus.DRAFT:
        return AppColors.primary;
      case QcStatus.AWAITING_APPROVAL:
        return AppColors.tertiary;
      case QcStatus.ERROR:
        return AppColors.danger;
    }
  }

  if (isHenryRose) {
    switch (qcStatus) {
      case QcStatus.DRAFT:
        return AppColors.secondary;
      case QcStatus.CREATIVE_BRIEF_COMPLETE:
        return AppColors.briefComplete;
      case QcStatus.CREATIVE_EDITS:
        return AppColors.edits;
      case QcStatus.CLIENT_REVIEW:
        return AppColors.review;
      case QcStatus.CREATIVE_APPROVED:
        return AppColors.primary;
      case QcStatus.READY_TO_LOAD:
        return AppColors.success;
      case QcStatus.ERROR:
        return AppColors.danger;
    }
  }

  switch (qcStatus) {
    case QcStatus.APPROVED:
    case QcStatus.CO_OP_APPROVED:
    case QcStatus.INTERNALLY_APPROVED:
      return AppColors.success;
    case QcStatus.DISAPPROVED:
      return AppColors.danger;
    case QcStatus.AWAITING_APPROVAL:
      return AppColors.warning;
    case QcStatus.DRAFT:
      return AppColors.primary;
    case QcStatus.ERROR:
      return AppColors.danger;
    default:
      return AppColors.secondary;
  }
};

export const getColorFromConflictStatus = (
  conflictStatus: ConflictStatus,
): string => {
  switch (conflictStatus) {
    case ConflictStatus.READY:
      return AppColors.success;
    case ConflictStatus.SETUP:
      return AppColors.warning;
    case ConflictStatus.CONFLICT:
      return AppColors.danger;
    default:
      return "";
  }
};

export const getAdDisplayFromAd = (ad?: IAd): IAdDisplay | null => {
  if (!ad) return null;
  const updatedQcStatus = getQcStatus(ad, ad.validation?.isValid);
  return {
    id: ad.id,
    name: ad.inputParameters?.name,
    thumbnail: ad.visuals?.thumbnail,
    thumbnail25: ad.visuals?.thumbnail25,
    thumbnail50: ad.visuals?.thumbnail50,
    category: ad.category,
    CategoryIcon: getIconNameAdCategory(ad.category),
    createdBy: ad.createdBy,
    createdAt: formatDateValue(ad.createdAt?.toString()),
    updatedBy: ad.updatedBy,
    updatedAt: formatDateValue(ad.updatedAt?.toString()),
    qcStatus: updatedQcStatus,
    qcStatusColor: getColorFromQcStatus(updatedQcStatus),
    type: ad.type,
    audiences: ad.inputParameters?.audiences?.join(", "),
    campaignStartDate:
      ad.inputParameters?.campaignStartDate &&
      formatDateValue(ad.inputParameters?.campaignStartDate),
    campaignEndDate:
      ad.inputParameters?.campaignEndDate &&
      formatDateValue(ad.inputParameters?.campaignEndDate),
    campaignMonth: ad?.inputParameters?.campaignMonth,
    client: ad.inputParameters?.client,
    description: ad.inputParameters?.description,
    destinationUrl: ad.inputParameters?.destinationUrl,
    models: ad.inputParameters?.models
      ?.map(({ year, name }) => `${year ?? ""} ${name ?? ""}`)
      ?.join(", "),
    oems: ad.inputParameters?.oems?.join(", "),
    package: ad.inputParameters?.package,
    tags: ad.inputParameters?.tags?.join(", "),
  };
};

export const adCategoryValues = [
  { key: 0, value: AdCategory.EVENT, text: "Event", icon: StarOutlined },
  { key: 1, value: AdCategory.RETAIL, text: "Retail", icon: DollarOutlined },
  { key: 2, value: AdCategory.MODEL, text: "Model", icon: CarOutlined },
  {
    key: 3,
    value: AdCategory.DEALER_BRANDING,
    text: "Dealer Branding",
    icon: ShopOutlined,
  },
];

const getQcStatusOptions = (statuses: QcStatus[]) =>
  statuses.map((item, index) => ({
    key: index,
    text: titleCase(item),
    value: item,
  }));

export type StatusOption<T extends string = QcStatus> = {
  key: number | string;
  text: string;
  value: T;
};

export const qcStatusOptions: () => StatusOption[] = () => {
  const isBearaby = isEnvVarEquals("CLIENT", "bearaby");
  const isLondre = isEnvVarEquals("CLIENT", "londre");
  const isTrueCar = isEnvVarEquals("CLIENT", "truecar");
  const isKlarnco = isEnvVarEquals("CLIENT", "klarnco");
  const isHenryRose = isEnvVarEquals("CLIENT", "henry-rose");
  const isPharma = isEnvVarEquals("INDUSTRY", "pharma");

  if (isBearaby) {
    return getQcStatusOptions(bearabyStatuses);
  }
  if (isLondre) {
    return getQcStatusOptions(londreStatuses);
  }
  if (isTrueCar) {
    return getQcStatusOptions(truecarStatuses);
  }
  if (isKlarnco) {
    return getQcStatusOptions(klarncoStatuses);
  }
  if (isHenryRose) {
    return getQcStatusOptions(henryRoseStatuses);
  }
  if (isPharma) {
    return getQcStatusOptions(pharmaStatuses);
  }
  return getQcStatusOptions(defaultQcStatuses);
};

type ErrorDisplay = {
  adDesign: boolean;
  inputParameters: boolean;
};

export const getErrorDisplay = (fields: FieldData[]): ErrorDisplay => {
  const isInputParametersField = (fieldName: FieldDataName) => {
    const nameArray = castArray(fieldName);
    return (
      (nameArray?.[0] === "inputParameters" && nameArray?.[1] !== "name") ||
      nameArray?.[0] === "category"
    );
  };

  const hasAdDesignError = fields.some(
    field => field.errors?.length && !isInputParametersField(field.name),
  );
  const hasInputParametersError = fields.some(
    field => field.errors?.length && isInputParametersField(field.name),
  );

  return {
    adDesign: hasAdDesignError,
    inputParameters: hasInputParametersError,
  };
};

export const getAdTypeLabel = (adType: AdType): string => {
  switch (adType) {
    case AdType.AIA:
      return "Automotive Inventory Ads";
    case AdType.DPA:
      return "Dynamic Product Ads";
    case AdType.FTA:
      return "Facebook Travel Ads";
    default:
      return adType.replace(/([A-Z])/g, " $1").trim();
  }
};

export const getAdFormatTypes = () => {
  const isInternal = isEnvVarEquals("CLIENT", "internal");
  const allowAIA = isInternal || isFeatureEnabled("ENABLE_AIA");
  const allowDPA = isInternal || isFeatureEnabled("ENABLE_DPA");
  const allowFTA = isInternal || isFeatureEnabled("ENABLE_FTA");

  return Object.entries(AdType)
    .filter(([, value]) => {
      return (
        (allowAIA && value === AdType.AIA) ||
        (allowDPA && value === AdType.DPA) ||
        (allowFTA && value === AdType.FTA) ||
        !dynamicAdList.includes(value)
      );
    })
    .map(([key, value]) => {
      return {
        key: key,
        value: value,
        text: getAdTypeLabel(value),
      };
    });
};

export const getCtaText = (cta: CallToAction) => {
  const ctaValue = callToActionValues.find(ctaValue => ctaValue.value === cta);

  return ctaValue?.text ?? capitalize(lowerCase(cta));
};

export const isAllAdsReadyToLoad = (ads: IAd[] = []): boolean => {
  return (
    !!ads.length &&
    ads.every(
      ad =>
        [
          QcStatus.APPROVED,
          QcStatus.CO_OP_APPROVED,
          QcStatus.INTERNALLY_APPROVED,
          QcStatus.READY_TO_LOAD,
          QcStatus.TRUECAR_CO_OP_APPROVED,
          QcStatus.TRUECAR_INTERNALLY_APPROVED,
        ].includes(ad.qcStatus) && ad.validation?.isValid,
    )
  );
};

export const isFileSizeValid = (file: Blob) => {
  const fileSize = file.size / 1024 / 1024;
  const fileType = file.type.includes("video")
    ? "video"
    : file.type.includes("image")
    ? "image"
    : undefined;

  if (fileType && fileSize > fileSizeLimits[fileType]) {
    notification.error({
      bottom: 45,
      duration: 5,
      message: "",
      description: `File is too large for Facebook Ad. Maximum file size is ${fileSizeLimits[fileType]}MB. Please re-upload a smaller file.`,
      placement: "bottomRight",
    });
    return false;
  }

  return true;
};

export const getAdCopy = (ad: IAd, user?: User | null): IAd => {
  const newAdId = uuid();
  const newInputParametersId = uuid();
  const newVisualsId = uuid();
  const currentTime = new Date().getTime();
  return {
    ...ad,
    id: newAdId,
    createdAt: currentTime,
    createdBy: user?.email ?? "",
    updatedAt: currentTime,
    updatedBy: user?.email ?? "",
    inputParametersId: newInputParametersId,
    visualsId: newVisualsId,
    visuals: { ...ad.visuals, id: newVisualsId },
    inputParameters: {
      ...ad.inputParameters,
      name: `${ad.inputParameters.name} - Copy`,
      id: newInputParametersId,
    },
  };
};
