import { Button, Form, Tooltip } from "antd";
import { createPortal } from "react-dom";
import { ButtonGroup } from "../ButtonGroup";
import { Loading } from "../Loading";
import { MetaFormItems } from "../MetaFormItem";
import { useCAMFooter } from "../useCAMFooter";
import { useForm } from "./useForm";

import styles from "./AssetMetaForm.module.scss";

interface Props {
  drawerId: string;
  assets: WDAsset[];
}

export const AssetMetaForm = ({ assets, drawerId }: Props) => {
  const { initialValues, isLoading, onSubmit, onClose, disabled, schema } =
    useForm(assets);
  const footer = useCAMFooter(drawerId);
  const tooltip = disabled && "Requires edit permission";

  if (schema && initialValues) {
    return (
      <Form
        id={drawerId}
        layout="vertical"
        initialValues={initialValues}
        onFinish={onSubmit}
        className={styles.form}
        data-disabled={disabled}
      >
        <MetaFormItems schema={schema} />
        {footer &&
          createPortal(
            <ButtonGroup>
              <Button onClick={onClose}>Close</Button>
              <Tooltip title={tooltip} placement="topRight">
                <Button
                  type="primary"
                  htmlType="submit"
                  form={drawerId}
                  loading={isLoading}
                  disabled={disabled}
                >
                  Save
                </Button>
              </Tooltip>
            </ButtonGroup>,
            footer,
          )}
      </Form>
    );
  }

  return <Loading />;
};
