import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Progress, Tooltip, Typography } from "antd";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDataList } from "shared/components/dataList";
import useHover from "shared/hooks/useHover";
import { TAssetBatch } from "shared/types/assetExporter";
import { useFeedContext } from "../shared/contexts/FeedContext";
import styles from "./ItemAssetBatch.module.scss";
import { DEFAULT_KEY } from "shared/components/dataList/useDataList";

type ItemAssetBatchProps = {
  assetBatch: TAssetBatch;
  onClick: (assetBatch: TAssetBatch) => void;
  onEdit: (assetBatch: TAssetBatch) => void;
  onDelete: (assetBatch: TAssetBatch) => void;
  isLoading?: boolean;
};

export const ItemAssetBatch = ({
  assetBatch,
  onEdit,
  onDelete,
  onClick,
  isLoading,
}: ItemAssetBatchProps) => {
  const [targetRef, isHovered] = useHover<HTMLDivElement>();
  const { assetBatchId: id, name } = assetBatch;
  const [dataList] = useDataList();
  const [counter, setCounter] = useState(0);

  const { editingAssetBatch, isUpdated, setIsUpdated } = useFeedContext();

  useEffect(() => {
    if (!isUpdated) return;
    if (editingAssetBatch?.assetBatchId !== id) return;

    setCounter(0);
    const incrementer = setInterval(() => {
      setCounter(c => {
        if (c < 100) return c + 1;
        clearInterval(incrementer);
        return c;
      });
    }, 5);
  }, [editingAssetBatch?.assetBatchId, id, isUpdated, setIsUpdated]);

  const isSelected =
    dataList.filters[DEFAULT_KEY] &&
    dataList.filters[DEFAULT_KEY]["assetBatches"]?.length === 1 &&
    dataList.filters[DEFAULT_KEY]["assetBatches"]?.[0] === id;
  const inProgress = counter > 0 && counter < 100;

  return (
    <div
      ref={targetRef}
      key={id}
      className={
        isSelected
          ? classNames([styles.item, styles.selectedItem])
          : styles.item
      }
      onClick={() => {
        onClick(assetBatch);
      }}
      data-cy="asset-batch-item"
    >
      {!inProgress && (
        <Tooltip key={id} title={name}>
          <Typography.Link>{name}</Typography.Link>
        </Tooltip>
      )}
      {inProgress && <Progress percent={counter} showInfo={false} />}
      {isHovered && !isLoading && !inProgress && (
        <div className={styles.buttonContainer}>
          <Tooltip title={"Edit"}>
            <Button
              data-cy="edit-toolbar-button"
              className="action-btn-toolbar"
              icon={<EditOutlined />}
              shape="circle"
              onClick={event => {
                event.stopPropagation(); // prevent the click event from bubbling up
                onEdit(assetBatch);
              }}
            />
          </Tooltip>
          <Tooltip title={"Delete"}>
            <Button
              data-cy="delete-toolbar-button"
              className="action-btn-toolbar"
              icon={<DeleteOutlined />}
              shape="circle"
              danger
              onClick={event => {
                event.stopPropagation(); // prevent the click event from bubbling up
                onDelete(assetBatch);
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
