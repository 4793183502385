type IntermediateSwitchProps = {
  disabled?: boolean;
  onClick?: () => void;
};

const IntermediateSwitch = (props: IntermediateSwitchProps) => {
  const disabled = props.disabled ?? false;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "44px",
        height: "22px",
        background: disabled ? "rgba(216,218,220,1)" : "#1890FF",
        borderRadius: "16px",
        margin: "auto",
        cursor: "pointer",
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          margin: "auto",
          width: "19px",
          height: "3px",
          background: "white",
          borderRadius: "1px",
        }}
      ></div>
    </div>
  );
};

export default IntermediateSwitch;
