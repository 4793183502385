import { dedupe, isTruthy } from "./helpers.array";
import { KeysWithStringOrUndefinedValues } from "./utilityTypes";

export const compareStringBy = <T>(
  iteratee:
    | KeysWithStringOrUndefinedValues<T>
    | ((record: T) => string | undefined),
) => {
  const getValue =
    typeof iteratee === "function" ? iteratee : (record: T) => record[iteratee];

  return (a: T, b: T) =>
    String(getValue(a) ?? "").localeCompare(String(getValue(b) ?? ""));
};

export const onFilterBy =
  <T>(
    iteratee:
      | KeysWithStringOrUndefinedValues<T>
      | ((record: T) => string | undefined),
    options?: {
      matchMode?: "equals" | "includes";
      caseInsensitive?: boolean;
    },
  ) =>
  (value: string | number | boolean, record: T) => {
    const getSrtValue = (val: string | number | boolean) => {
      return options?.caseInsensitive ? String(val).toLowerCase() : String(val);
    };
    const recordValue =
      typeof iteratee === "function"
        ? iteratee(record)
        : (record[iteratee] as string | undefined);

    if (!recordValue) return false;
    if (options?.matchMode === "includes") {
      return getSrtValue(recordValue).includes(getSrtValue(value));
    }
    return getSrtValue(recordValue) === getSrtValue(value);
  };

export const getFiltersFromData =
  <T>(dataSource: T[]) =>
  (
    key: KeysWithStringOrUndefinedValues<T>,
    options?: {
      splitBy?: string;
      sorted?: boolean;
    },
  ) => {
    const sorted = options?.sorted ?? true;
    const splitBy = options?.splitBy ?? "";

    return dataSource
      .slice()
      .sort(sorted ? compareStringBy(key) : undefined)
      .map(target => target[key] as string | undefined)
      .filter(isTruthy)
      .flatMap(value => (splitBy ? value.split(splitBy) : value))
      .filter(dedupe)
      .map(value => ({
        text: value,
        value,
      }));
  };
