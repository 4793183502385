import { TooltipProps } from "antd";
import { first } from "lodash";
import { IAssetGroup } from "shared/types/adLibrary";
import { IAccountRecord } from "shared/types/accountManagement";
import { isValidUrl } from "utils/validators";
import { CreateAssetGroupSelection } from "./pmaxLoadDrawer/hooks/useCreateNewAssetGroupSelector";
import { PmaxAdReviewItem } from "./shared/types";

export const getThumbnailImage = (assetGroup: IAssetGroup) => {
  const uploadKeysOrderedByRelevance: (keyof IAssetGroup["uploads"])[] = [
    "landscapeImages",
    "squareImages",
    "portraitImages",
    "squareLogos",
    "landscapeLogos",
  ];

  for (const key of uploadKeysOrderedByRelevance) {
    const firstImage = first(assetGroup.uploads?.[key]);

    if (firstImage) {
      return firstImage.url;
    }
  }

  return undefined;
};

const findSelectedDealerURL = (
  store: IAccountRecord | undefined,
  finalUrl: string,
) => {
  const { labels = [] } = store || {};
  const dealerUrl = labels.find(l => l.name === finalUrl || l.url === finalUrl);

  if (dealerUrl) {
    return {
      hasUrlError: false,
      ...dealerUrl,
    };
  }

  const isFinalUrlValid = isValidUrl(finalUrl);

  return {
    hasUrlError: !isFinalUrlValid,
    url: isFinalUrlValid ? finalUrl : "",
    name: !isFinalUrlValid ? finalUrl : undefined,
  };
};

export const getComputedSelectionItems = (
  assetGroupSelection: CreateAssetGroupSelection[],
  dealer: IAccountRecord,
): PmaxAdReviewItem[] =>
  assetGroupSelection
    .map(selection => {
      const { adAccount } = selection;
      return (
        adAccount?.campaigns?.map(
          campaign =>
            campaign?.assetGroups?.map(assetGroup => {
              return {
                campaign,
                dealer,
                adAccount,
                assetGroup,
                destination: findSelectedDealerURL(dealer, assetGroup.finalUrl),
              };
            }) || [],
        ) || []
      );
    })
    .flat(2);

export const pmaxTooltipProps: Partial<TooltipProps> = {
  mouseEnterDelay: 0.5,
  trigger: ["hover", "click"],
};

type assetGroupError = {
  section?: keyof IAssetGroup;
  subSection?:
    | keyof IAssetGroup["uploads"]
    | keyof IAssetGroup["multiInputAsset"];
  indexesAtSubsection?: number[];
  message: string;
};

type AssetTypes = keyof IAssetGroup["uploads"];

const assetTypes: Array<{ type: AssetTypes; message: string }> = [
  { type: "landscapeImages", message: "landscape images" },
  { type: "squareImages", message: "square images" },
  { type: "portraitImages", message: "portrait images" },
  { type: "squareLogos", message: "square logos" },
  { type: "landscapeLogos", message: "landscape logos" },
];

export const validateUploads = (
  uploads: Partial<IAssetGroup>["uploads"],
): assetGroupError[] => {
  const errors: assetGroupError[] = [];

  if (!uploads) {
    errors.push({ section: "uploads", message: "No uploads found" });
    return errors;
  }

  assetTypes.forEach(({ type, message }) => {
    const assets = uploads[type] ?? [];
    const urls = assets.map(asset => asset.url);
    const uniqueUrls = new Set(urls);

    if (assets.length > 0 && urls.length !== uniqueUrls.size) {
      const duplicateIndexes = urls.reduce<number[]>(
        (acc, url, index): number[] => {
          if (urls.indexOf(url) !== index && !acc.includes(index)) {
            return [...acc, index];
          }
          return acc;
        },
        [],
      );

      errors.push({
        section: "uploads",
        message: `There are repeated ${message}`,
        indexesAtSubsection: duplicateIndexes,
      });
    }
  });

  return errors;
};

export const getRepeatedHeadlines = (headlines: string[]): number[] => {
  return headlines.reduce<number[]>((acc, cur, idx): number[] => {
    const repeatedIdx = headlines.findIndex((item, index): boolean => {
      return item === cur && index !== idx;
    });
    if (repeatedIdx !== -1) {
      return [...acc, idx];
    }
    return acc;
  }, []);
};

export const getErrorsFromAssetGroup = (assetGroup: Partial<IAssetGroup>) => {
  const { uploads, multiInputAsset } = assetGroup;
  const errors = validateUploads(uploads);

  if (!multiInputAsset?.headlines?.length) {
    errors.push({
      message: "No headlines found",
      section: "multiInputAsset",
      subSection: "headlines",
    });
    return errors;
  }

  const repeatedHeadlines = getRepeatedHeadlines(multiInputAsset?.headlines);
  if (repeatedHeadlines.length > 0) {
    errors.push({
      message: "There are repeated headlines",
      section: "multiInputAsset",
      subSection: "headlines",
      indexesAtSubsection: repeatedHeadlines,
    });
  }

  return errors;
};
