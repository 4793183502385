import { Button, Drawer, notification } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { processImportAds } from "redux/adLibrary/adLibrary.slice";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import ImportCsvForm from "./adImportDrawer/ImportCsvForm";
import { processCsvRawData } from "./processingImportUtils";

export class ImportAdData {
  "Ad Name"?: string;
  "Ad Format"?: CsvAdFormat | string;
  Copy?: string;
  Headline?: string;
  "Newslink Description"?: string;
  CTA?: string;
  "Display URL"?: string;
  "Dropbox Link URL"?: string;
  "Destination URL"?: string;
  "Model Year"?: string;
  OEM?: string;
  "Model Name"?: string;
  Tags?: string;
  "Sales Event"?: string;
  "Ad Category"?: string;
  Client?: string;
  Package?: string;
  "Start Date"?: string;
  "End Date"?: string;
  "UTM"?: string;
  "Campaign Month"?: string;
}

export const IMPORT_CSV_HEADERS = Object.keys(new ImportAdData());

export type CsvAdFormat =
  | "Instant Experience"
  | "Canvas"
  | "Collection"
  | "Carousel"
  | "Still"
  | "Motion"
  | "Video"
  | "AIA Carousel"
  | "AIA Still"
  | "DPA Carousel"
  | "DPA Still"
  | "FTA Carousel"
  | "FTA Still";

const AdImportDrawer = ({ handleClose }: { handleClose: () => void }) => {
  // the data array from the csv file, the first row will have the header.
  const [csvRawData, setCsvRawData] = useState<string[][]>();

  const { data: adsData, validCsvHeaders } = processCsvRawData<ImportAdData>(
    csvRawData,
    IMPORT_CSV_HEADERS,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleImportError = (description: string) => {
    notification.error({
      bottom: 45,
      duration: 5,
      message: "",
      placement: "bottomRight",
      description,
    });

    handleClose();
  };

  const handleImport = async () => {
    const totalCsvHeaders = csvRawData?.[0]?.length ?? 0;
    const columnsIgnored: number = totalCsvHeaders - validCsvHeaders?.length;

    if (!adsData.length) {
      handleImportError("Empty CSV file");
      return;
    }

    dispatch(processImportAds(adsData, { columnsIgnored }, navigate));

    handleClose();
  };

  const templateUrl =
    process.env.REACT_APP_ADS_IMPORT_CSV_TEMPLATE_URL ||
    "https://alexia-share.s3.amazonaws.com/Automatic+Ads+Uploader+-+Template.csv";

  return (
    <Drawer
      title="Import"
      visible
      onClose={handleClose}
      width={720}
      closable
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>

          <Button
            disabled={!csvRawData}
            onClick={handleImport}
            type="primary"
            data-cy="ad-import-btn"
          >
            Import
          </Button>
        </div>
      }
    >
      <ImportCsvForm
        headers={validCsvHeaders}
        csvJsonData={csvRawData}
        setCsvJsonData={setCsvRawData}
        csvTemplateUrl={templateUrl}
      />
    </Drawer>
  );
};

export default AdImportDrawer;
