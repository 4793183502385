import { QueryFunctionContext, useInfiniteQuery, useQuery } from "react-query";
import { sortBy } from "lodash";
import API from "../../services";
import {
  IAccount,
  IAccountRecord,
  IGetAccountsResponse,
} from "../types/accountManagement";
import { useMemo } from "react";

export const dealerPagesQueryKey = "dealerPages";

const getDealers = async ({
  pageParam: paginationToken,
}: QueryFunctionContext) => {
  const { result, error } =
    await API.services.dealerManagement.getDealersByPaginatinationToken<IGetAccountsResponse>(
      paginationToken,
    );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useFetchInfiniteDealers = () => {
  const result = useInfiniteQuery<IGetAccountsResponse["result"], Error>(
    dealerPagesQueryKey,
    getDealers,
    {
      staleTime: Infinity,
      getNextPageParam: lastPage =>
        lastPage?.paginationKey?.dealer_name || false,
    },
  );

  if (result.hasNextPage && !result.isFetchingNextPage) {
    result.fetchNextPage();
  }

  const dealers = useMemo(
    () =>
      sortBy(
        result.data?.pages?.flatMap(page => page?.dealers ?? []) ?? [],
        dealer => dealer.dealer_name,
      ),
    [result.data],
  );

  return {
    ...result,
    dealers,
  };
};

const getDealer = (dealerName?: string) => async () => {
  const { result, error } = await API.services.dealerManagement.getDealer(
    dealerName || "",
  );

  if (error || !result) {
    throw Error(error?.message || `Data for ${dealerName} not found.`);
  }

  return returnDealerRecordsFromDealers([result.dealer])[0];
};

export const useFetchDealer = (dealerName?: string) => {
  return useQuery<
    IAccountRecord,
    {
      result: {
        dealer: IAccount;
      } | null;
      error: any;
    }
  >(["dealer", dealerName], getDealer(dealerName), {
    enabled: !!dealerName,
  });
};

export const returnDealerRecordsFromDealers = (
  dealers: IAccount[],
  currentRecordCount?: number,
): IAccountRecord[] =>
  dealers.map((dealer, index) => {
    const logoUrls = dealer.logo_urls_from_S3
      ? JSON.parse(dealer.logo_urls_from_S3)
      : {
          horizontalEventImagesFromS3: [],
          horizontalImagesFromS3: [],
          squareEventImagesFromS3: [],
          squareImagesFromS3: [],
          verticalEventImagesFromS3: [],
          verticalImagesFromS3: [],
        };
    return {
      key:
        currentRecordCount && currentRecordCount > 0
          ? currentRecordCount + index
          : index,
      dealerName: dealer.dealer_name,
      dealerCode: dealer.dealer_code,
      dealerOem: dealer.dealer_oem,
      dealerUrl: dealer.dealer_url,
      logoUrl: dealer.logo_url,
      dealerAddress: dealer.address,
      dealerCity: dealer.city || "",
      dealerUsState: dealer.state,
      dealerZip: dealer.zip,
      dealerPhoneNumber: dealer.phone_number || "",
      dealerFinalPriceName: dealer.final_price_name || "",
      logoUrlsFromS3: logoUrls,
      webIntPositions: dealer.web_int_positions || [],
      labels: dealer.labels || [],
      coopDetails: dealer.coopDetails || {
        emailOrPortal: "",
        coopEmail: "",
        coopUsername: "",
        coopPassword: "",
        coopLoginLocked: false,
        coopDealerCode: "",
        coopSite: "",
      },
      details: dealer.details || {
        facebook: {
          fbPageId: "",
          fbAccountId: "",
          fbCatalogId: "",
          fbPixelId: "",
          fbInstagramId: "",
        },
      },
      createdAt: dealer.created_at,
      updatedAt: dealer.updated_at,
    };
  });
