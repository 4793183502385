import API from "services";
import { useMutation, useQueryClient } from "react-query";
import { ITemplate } from "shared/types/designStudio";
import { useUser } from "shared/hooks/useUser";
import { getCanvasJsonQueryKey } from "shared/hooks/useFetchCanvasJsonObjects";
import { PolotnoJSON } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/usePolotnoStore";

type SaveTemplateDraftBody = {
  template: ITemplate;
  json: PolotnoJSON;
  base64Thumbnail: string;
};

export const useSaveTemplateDraft = () => {
  const user = useUser();

  const queryClient = useQueryClient();
  return useMutation(
    (args: SaveTemplateDraftBody) =>
      API.services.designStudio.saveDraftV3(
        args.template,
        args.json,
        args.base64Thumbnail,
        user,
      ),
    {
      onSuccess: (_response, variables) => {
        queryClient.invalidateQueries(["templates"]);
        const urlToInvalidate = variables.template.canvasJsonUrl;

        if (urlToInvalidate) {
          queryClient.invalidateQueries(getCanvasJsonQueryKey(urlToInvalidate));
        }
      },
    },
  );
};
