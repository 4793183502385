import { memo, ReactElement, useState } from "react";
import {
  InstantExperienceElementStyle,
  IPhotoElement,
} from "shared/types/adLibrary";
import uuid from "uuid";
import ImageComponentForm, {
  IImageComponentForm,
} from "./imageComponent/ImageComponentForm";
import { DestinationType } from "./shared/Destination";

interface IProps {
  instantExperienceElement: IPhotoElement;
  updateElement(photoElement: IPhotoElement): void;
  hideLayoutOption?: boolean;
  hideTitle?: boolean;
  forceValidations?: boolean;
  isLastElement?: boolean;
}

const defaultPhotoValues: Omit<IPhotoElement, "id"> = {
  element_type: "PHOTO",
  name: "Image",
  style: InstantExperienceElementStyle.FIT_TO_WIDTH,
};

export const getDefaultPhotoValues = (): IPhotoElement => ({
  ...defaultPhotoValues,
  id: uuid(),
});

function ImageComponent({
  instantExperienceElement: photoElement,
  updateElement,
  hideLayoutOption,
  hideTitle,
  forceValidations,
  isLastElement,
}: IProps): ReactElement {
  const [destination, setDestination] = useState<DestinationType>(
    photoElement.destination?.instantExperienceId
      ? "instantExperience"
      : "website",
  );

  const [selectedBrand, setSelectedBrand] = useState<string>();

  const initialValues: IImageComponentForm = {
    layoutOption: photoElement.style,
    destination: destination,
    destinationValues:
      destination === "instantExperience"
        ? {
            instantExperienceId: photoElement.destination?.instantExperienceId,
          }
        : { urlLabel: photoElement?.destination?.urlLabel },
    fileUrls: { thumbnail: photoElement.url },
  };

  function getInstantExperienceElement(
    values: IImageComponentForm,
  ): IPhotoElement {
    const destination =
      values.destinationValues?.instantExperienceId ||
      values.destinationValues?.urlLabel
        ? {
            instantExperienceId: values.destinationValues?.instantExperienceId,
            urlLabel: values.destinationValues?.urlLabel,
          }
        : undefined;
    return {
      id: photoElement.id,
      name: photoElement.name,
      element_type: "PHOTO",
      url: values.fileUrls?.thumbnail,
      destination,
      style: values.layoutOption,
    };
  }

  return (
    <ImageComponentForm
      destination={destination}
      setDestination={setDestination}
      setSelectedBrand={setSelectedBrand}
      selectedBrand={selectedBrand}
      hideLayoutOption={hideLayoutOption}
      hideTitle={hideTitle}
      initialValues={initialValues}
      onValuesChange={values => {
        if (!values) {
          return;
        }
        updateElement(getInstantExperienceElement(values));
      }}
      forceValidations={forceValidations}
      destinationRequired={
        isLastElement &&
        (photoElement.style == InstantExperienceElementStyle.FIT_TO_WIDTH ||
          !photoElement.style)
      }
    />
  );
}

export default memo(ImageComponent);
