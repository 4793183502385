import { IConfig } from "shared/types/configuration";
import { IImageGenResponse, IImageResizeResponse } from "shared/types/genAI";
import { IApiResponse } from "shared/types/shared";
import HttpClient from "./httpClient";

export default ({ config }: { headers: any; config: IConfig }) => ({
  generateImages: async (prompt: string, nSamples: number) => {
    const url = config.services.genAI.imageGen;
    const { result, error } = await HttpClient.post<
      IApiResponse<IImageGenResponse>
    >(url, { prompt: prompt, nSamples: nSamples, height: 512, width: 512 });

    if (!result || error) {
      return {
        prompt,
        nSamples: 4,
        height: 512,
        width: 512,
        imageUrls: [""],
      };
    }

    return result;
  },

  resizeImage: async (baseImageUrl: string) => {
    const url = config.services.genAI.imageResize;
    const { result, error } = await HttpClient.post<
      IApiResponse<IImageResizeResponse>
    >(url, { url: baseImageUrl });

    if (!result || error) {
      return {
        url: "",
        publicId: "",
      };
    }

    return result;
  },
});
