export const LOCAL_STORAGE_KEY = "media";
export const REDIRECT_URI = `${location.origin}/media-callback`;
export const MIXED_VALUE = "Mixed";
export const SEARCH_MODE_FILE_TYPES = "png,jpg,jpeg,woff,mp4,mov";
export const LIST_MODE_FILE_TYPES = "image,audiovideo,other";

export const QP_SEARCH = "media_search";
export const QP_BREADCRUMB = "media_breadcrumb";
export const QP_LAYOUT = "media_layout";

export const MM_DD_YYYY_FORMAT = `MM/DD/YYYY hh:mma`;
export const IMG_TYPES = ["png", "jpg", "jpeg", "eps"];
export const VIDEO_TYPES = ["mp4", "mov"];
export const FONT_TYPES = ["otf", "ttf", "woff", "woff2"];
