import { useCallback, useEffect, useState } from "react";
import API from "services";
import {
  FeedOffer,
  ISortingOption,
  VehicleConditions,
} from "shared/types/assetBuilder";
import { useLocation } from "react-router-dom";
import { getErrorMessage } from "utils/errorMessage";
import { debounce } from "lodash";
import {
  aggregateRebateFieldValues,
  formatRateAsPercentage,
} from "utils/helpers.offer";

export type UseGetTabData = {
  feedId?: string;
  dealerCode?: string;
  dealerOem?: string;
  modelCode?: string;
  msrp?: string;
  filterBy: VehicleConditions;
  searchBy: string;
  sortingOptions: ISortingOption[];
  filterField: string;
  filterFieldSearch: string;
  rawOfferUpdated?: boolean;
  termOperator?: "AND" | "OR";
  searchType?: "loose" | "strict";
};

const TIME_1_SEC = 1000;

export const useGetTabData = (args: UseGetTabData) => {
  const {
    feedId,
    dealerCode,
    dealerOem,
    filterBy,
    searchBy,
    sortingOptions,
    filterField,
    filterFieldSearch,
    rawOfferUpdated,
    searchType,
  } = args;
  const [isFetchingPage, setIsFetchingPage] = useState(false);
  const [offerListCp, setOfferListCp] = useState<FeedOffer[]>([]);
  const [totalOffers, setTotalOffers] = useState(0);
  const [page, setPage] = useState(1);
  const [error, setError] = useState("");
  const errorMsg = "Unable to get feed data";
  const location = useLocation();

  const getData = useCallback(async () => {
    const isSelectPage =
      location.pathname.endsWith("/select") || rawOfferUpdated;
    if (!feedId || !dealerCode || !dealerOem || !isSelectPage) return;
    setIsFetchingPage(true);
    setError("");
    try {
      const params = {
        feedId,
        dealerCode,
        dealerOem,
        filterBy,
        sortingOptions,
        searchBy,
        filterField,
        filterFieldSearch,
        searchType,
      };
      const res = await API.services.assetBuilder.getTabData(params, 1);
      const { result, error: apiError } = res;

      if (!result || !!apiError) {
        setError(errorMsg);
        return;
      }

      /**
       * Bug fix Ticket: https://theconstellationagency.atlassian.net/browse/AV2-5088
       * When worked on this, referenced `fetchOrderStateSuccess()` function which does
       *  transform raw offer rebate fields into proper one. The types were very clear and
       *  existing logic were using 'any'. For the purpose of bug fix, will do the same here.
       */
      setOfferListCp(
        result.offerList.reduce((acc, offer) => {
          const { row } = aggregateRebateFieldValues({
            row: offer,
          } as any);

          return [
            ...acc,
            {
              ...row,
              aprRate: formatRateAsPercentage(row.aprRate),
              financeRate: formatRateAsPercentage(row.financeRate),
            },
          ] as any;
        }, []),
      );
      setTotalOffers(result.total);
    } catch (err) {
      const parsedErrorMsg = getErrorMessage(err);
      if (parsedErrorMsg.includes("search_phase_execution_exception")) {
        setError("One or more sort fields dont exist on the feed");
      } else setError(errorMsg);
    } finally {
      setIsFetchingPage(false);
    }
  }, [
    feedId,
    dealerCode,
    dealerOem,
    filterBy,
    sortingOptions,
    searchBy,
    filterField,
    filterFieldSearch,
    rawOfferUpdated,
    location,
    searchType,
  ]);

  const getTabData = useCallback(() => {
    getData();
  }, [getData]);

  const searchDelayedFetch = useCallback(() => {
    const delayedFetch = debounce(getTabData, TIME_1_SEC);
    delayedFetch();
    if (!searchBy && !filterFieldSearch) {
      delayedFetch.cancel();
      getTabData();
    }
    return () => delayedFetch.cancel();
  }, [filterFieldSearch, getTabData, searchBy]);

  useEffect(searchDelayedFetch, [searchDelayedFetch]);

  const getNextPage = useCallback(async () => {
    if (!feedId || !dealerCode || !dealerOem) return;
    setIsFetchingPage(true);
    try {
      const params = {
        feedId,
        dealerCode,
        dealerOem,
        filterBy,
        searchBy,
        sortingOptions,
        filterField,
        filterFieldSearch,
      };
      const res = await API.services.assetBuilder.getTabData(params, page + 1);
      const { result, error } = res;

      if (!result || !!error) {
        setError(errorMsg);
        return;
      }
      const addOffers = result.offerList;
      setOfferListCp(prev => [...prev, ...addOffers]);
      setPage(prev => prev + 1);
    } catch (error) {
      setError(errorMsg);
    } finally {
      setIsFetchingPage(false);
    }
  }, [
    feedId,
    dealerCode,
    dealerOem,
    filterBy,
    searchBy,
    sortingOptions,
    filterField,
    filterFieldSearch,
    page,
  ]);

  return {
    isFetchingPage,
    offerListCp,
    totalOffers,
    error,
    getNextPage,
  };
};
