import { Col, Form, Row, Typography } from "antd";
import styles from "./AdPlatformsTab.module.scss";
import { FacebookAccountSelectOrInput } from "screens/adLibrary/shared/FacebookAccountSelectOrInput";
import FacebookAssetSelect from "screens/adLibrary/shared/FacebookAssetSelect";
import { useState } from "react";
import { IFacebookAccount } from "screens/adLibrary/facebookUtils/types";
import { IAccountRecord } from "shared/types/accountManagement";
import { FacebookAssetSelectOrInput } from "screens/adLibrary/shared/FacebookAssetSelectOrInput";
import GoogleAdAccountSelect from "screens/adLibrary/shared/GoogleAdAccountSelect";
import { GoogleAdAccount, IAdLibraryState } from "shared/types/adLibrary";
import FacebookPixelModal from "screens/adLibrary/shared/FacebookPixelModal";
import { useAdPlatformsAccount } from "shared/hooks/brandsAccountsManagement/useAdPlatformsAccount";
import { useSelector } from "react-redux";
import { FormInstance } from "antd/es/form/Form";
import { useIsAdmin } from "shared/hooks/useIsAdmin";

interface AdPlatformsTabProps {
  accountToEdit: IAccountRecord;
  form: FormInstance<IAccountRecord>;
  setAccountToEdit: (accountToEdit: IAccountRecord) => void;
}

export const AdPlatformsTab = ({
  accountToEdit,
  form,
  setAccountToEdit,
}: AdPlatformsTabProps) => {
  const isAdmin = useIsAdmin();
  const { facebook } = accountToEdit.details;
  const [selectedAccount, setSelectedAccount] = useState<IFacebookAccount>();
  const [showPixelModal, setShowPixelModal] = useState(false);
  const [pixelModalData, setPixelModalData] = useState<IFacebookAccount>();
  const [facebookIntegration, setFacebookIntegration] = useState(true);
  const [createPixelSuccess, setCreatePixelSuccess] = useState(false);
  const [selectedGoogleAccountCID, setSelectedGoogleAccountCID] = useState<
    GoogleAdAccount["id"] | undefined
  >(accountToEdit.details?.google?.adAccountCID);

  const selectedFacebookAccounts: IFacebookAccount[] =
    useSelector(
      (state: { adLibrary: IAdLibraryState }) =>
        state.adLibrary.selectedFacebookAccounts,
    ) || [];

  const {
    handleFacebookAccountSelect,
    handlePageSelect,
    handlePixelSelect,
    handleAccountIdChange,
    handlePageIdChange,
    handleCreatePixel,
    handleSelectGoogleAccount,
    handleClearGoogleAccount,
    handleCatalogSelect,
    handleInstagramSelect,
    handleCatalogIdChange,
  } = useAdPlatformsAccount({
    accountToEdit,
    selectedAccount,
    setAccountToEdit,
    setSelectedGoogleAccountCID,
    setSelectedAccount,
    setPixelModalData,
    setShowPixelModal,
  });

  return (
    <div>
      <Form
        layout="vertical"
        requiredMark={false}
        className={styles.form}
        form={form}
      >
        <Row className={styles.rowContainer}>
          <Col span={12} className={styles.leftColContainer}>
            <Typography.Text className={styles.title}>Facebook</Typography.Text>
            <div className={styles.paddingContainer}>
              <Col>
                <Form.Item label={"FB Account ID"}>
                  <FacebookAccountSelectOrInput
                    disabled={!isAdmin}
                    selectedAccounts={selectedFacebookAccounts}
                    selectedAccount={selectedAccount}
                    facebook={facebook}
                    onSelect={handleFacebookAccountSelect}
                    onChangeInput={handleAccountIdChange}
                    enableInput={facebookIntegration}
                    displayAccountId={true}
                    facebookNull={true}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={"FB Page ID"}>
                  <FacebookAssetSelectOrInput
                    title={"page"}
                    willSetValueOnLoad
                    selectedAccount={selectedAccount}
                    setFacebookIntegration={setFacebookIntegration}
                    onSelect={handlePageSelect}
                    enableInput={facebookIntegration}
                    initialValue={facebook.fbPageId}
                    onChangeInput={handlePageIdChange}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={"FB Pixel ID"}>
                  {facebookIntegration && (
                    <FacebookAssetSelect
                      title={"pixel"}
                      willSetValueOnLoad
                      initialValue={facebook.fbPixelId}
                      createPixel={handleCreatePixel}
                      selectedAccount={selectedAccount}
                      createPixelSuccess={createPixelSuccess}
                      setFacebookIntegration={setFacebookIntegration}
                      onSelect={handlePixelSelect}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={"Catalog ID"}>
                  <FacebookAssetSelectOrInput
                    title={"catalog"}
                    willSetValueOnLoad
                    selectedAccount={selectedAccount}
                    setFacebookIntegration={setFacebookIntegration}
                    onSelect={handleCatalogSelect}
                    enableInput={facebookIntegration}
                    initialValue={facebook.fbCatalogId}
                    onChangeInput={handleCatalogIdChange}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={"Instagram ID"}>
                  {facebookIntegration && (
                    <FacebookAssetSelect
                      title={"instagram"}
                      willSetValueOnLoad
                      pageId={facebook.fbPageId}
                      initialValue={facebook.fbInstagramId}
                      selectedAccount={selectedAccount}
                      setFacebookIntegration={setFacebookIntegration}
                      onSelect={handleInstagramSelect}
                    />
                  )}
                </Form.Item>
              </Col>
            </div>
            <Typography.Text className={styles.title}>Google</Typography.Text>
            <div className={styles.paddingContainer}>
              <Col>
                <Form.Item label={"Google Ad Account"}>
                  <GoogleAdAccountSelect
                    value={selectedGoogleAccountCID}
                    onSelect={handleSelectGoogleAccount}
                    onClear={handleClearGoogleAccount}
                    onDeselect={handleClearGoogleAccount}
                  />
                </Form.Item>
              </Col>
            </div>
          </Col>
        </Row>
        {selectedAccount && (
          <FacebookPixelModal
            showModal={showPixelModal}
            setShowModal={setShowPixelModal}
            setCreatePixelSuccess={setCreatePixelSuccess}
            pixelModalData={pixelModalData}
          />
        )}
      </Form>
    </div>
  );
};
