import { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popover, Divider } from "antd";
import styles from "./HelpPopover.module.scss";
import { NavLink } from "react-router-dom";

const HelpPopover = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const content = (
    <div className={styles.helpContent}>
      <div className={styles.helpTitle}>
        <span>How can we help?</span>
        <span onClick={() => setVisible(false)}>X</span>
      </div>
      <Divider
        type="horizontal"
        style={{ marginBottom: "16px", marginTop: "16px" }}
      />
      <div className={styles.helpButtons}>
        <Button>
          <NavLink to="/help/ad-library" target="_blank">
            FAQ
          </NavLink>
        </Button>
        <Button>
          <NavLink to="/help/step-0" target="_blank">
            Resources
          </NavLink>
        </Button>
      </div>
    </div>
  );

  return (
    <Popover
      trigger="click"
      placement="topRight"
      content={content}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <QuestionCircleOutlined
        style={{
          fontSize: "24px",
          marginRight: ".5rem",
        }}
      />
    </Popover>
  );
};

export default HelpPopover;
