import { FolderOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Ellipsis } from "../../Ellipsis";
import { useQPBreadcrumb } from "../../useQPBreadcrumb";
import { useSelected } from "../../useSelected";
import { EditableCell } from "../EditableCell";

import styles from "./Folder.module.scss";

type Props = {
  resource: WDFolder;
};

export const Folder = ({ resource }: Props) => {
  const { toggleSelected } = useSelected();
  const { toFolder } = useQPBreadcrumb();

  return (
    <div className={styles.host}>
      <Link to={{ search: toFolder(resource.id) }}>
        <EditableCell
          editable
          title="Name"
          element="div"
          dataIndex="name"
          record={resource}
        >
          <Ellipsis>{resource.name}</Ellipsis>
        </EditableCell>
      </Link>
      <div className={styles.icon} onClick={_ => toggleSelected(resource)}>
        <FolderOutlined />
      </div>
    </div>
  );
};
