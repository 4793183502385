import { Spin } from "antd";
import { memo } from "react";

import AdCardListContainer from "./adReview/AdCardListContainer";
import AdDetailsDrawer from "./adReview/shared/components/AdDetailsDrawer";

import FilterDrawer from "screens/adLibrary/library/FilterDrawer";
import {
  useCampaignManagementFilters,
  useCampaignManagementGlobalFilter,
  useCampaignManagementIds,
} from "screens/adLibrary/shared/hooks/dataListHooks";
import { DataListTagsURL } from "shared/components/dataListURL/DataListTagsURL";
import { useDataListURLData } from "shared/components/dataListURL/useDataListURLData";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import styles from "./PublicAdReview.module.scss";
import AdReviewDrawerFilters from "./adReview/AdReviewDrawerFilters";
import AdTableListContainer from "./adReview/AdTableListContainer";
import HeaderToolbar from "./adReview/HeaderToolbar";
import usePublicAdReviewState from "./adReview/shared/hooks/usePublicAdReviewState";
import { IAdReviewFields } from "./fields";

const PublicAdReview = () => {
  const { props, handlers } = usePublicAdReviewState();

  const { adsToShow, isLoadingAds, dataSource, sharedAdReview } = props;
  const {
    showDetails,
    selectedEntry,
    facebookAdsFromEntry,
    layout,
    expand,
    displayColumn,
    filterDrawer,
  } = props;

  const {
    setShowDetails,
    setSelectedEntryId,
    setExpand,
    setDisplayColumn,
    setDestinationUrls,
    setFilterDrawer,
  } = handlers;

  const { selectedIds, setSelectedItemIds } = useCampaignManagementIds();
  const { setGlobalFilter } = useCampaignManagementGlobalFilter();
  const { filters, resetFilters } = useCampaignManagementFilters();

  const { data, originalData, selectedItems, isLoading } = useDataListURLData<
    IAdReviewFields,
    AdReviewTableEntry
  >({
    isError: false,
    isLoading: isLoadingAds,
    data: dataSource,
  });

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        {sharedAdReview && (
          <>
            {facebookAdsFromEntry && (
              <AdDetailsDrawer
                showDetails={showDetails}
                selectedEntry={selectedEntry}
                facebookAdsFromEntry={facebookAdsFromEntry}
                setShowDetails={setShowDetails}
                setSelectedEntryId={setSelectedEntryId}
              />
            )}
            <HeaderToolbar
              showContent={true}
              displayColumn={displayColumn}
              allowSelected={selectedItems.length > 0}
              selectedAdIds={selectedItems.map(item => item.id)}
              enhancedSearch={false}
              filterDrawer={filterDrawer}
              setFilterDrawer={setFilterDrawer}
              onSearch={setGlobalFilter}
              expand={expand}
              setExpand={setExpand}
              validDateRange={true}
              fixedAccount={sharedAdReview.account}
              fixedDateRange={[
                sharedAdReview?.dateRange.since,
                sharedAdReview?.dateRange.to,
              ]}
            />
            <div className={styles.filtersContainer}>
              <DataListTagsURL
                data={data}
                originalData={originalData}
                showSelectAll={layout === "card"}
              />
            </div>
            {layout === "table" ? (
              <AdTableListContainer
                dataSource={data}
                facebookAds={originalData}
                displayColumn={displayColumn}
                setDisplayColumn={setDisplayColumn}
                setDestinationUrls={setDestinationUrls}
                selectedRowKeys={selectedIds}
                setSelectedRowKeys={setSelectedItemIds}
                setSelectedEntryId={setSelectedEntryId}
                setShowDetails={setShowDetails}
                expand={expand}
              />
            ) : (
              <>
                <AdCardListContainer
                  dataSource={data}
                  facebookAds={adsToShow || []}
                  setShowDetails={setShowDetails}
                  setSelectedEntryId={setSelectedEntryId}
                />
                <FilterDrawer
                  filterDrawer={filterDrawer}
                  setFilterDrawer={setFilterDrawer}
                  resetFilters={resetFilters}
                  isSomeFilterApplied={Object.values(filters).some(
                    value => !!value,
                  )}
                >
                  <AdReviewDrawerFilters />
                </FilterDrawer>
              </>
            )}
          </>
        )}
      </div>
    </Spin>
  );
};

export default PublicAdReview;
