import moment from "moment";
import {
  IFeedForm,
  WeeklyRepeatOptions,
  ServerFeedWeeklyRepeat,
  IFrequency,
  ServerHoursMinutesConfig,
} from "shared/types/assetExporter";

export const convertRepeatForServer = (feedForm: IFeedForm) => {
  const { repeat, frequency } = feedForm;
  if (!repeat || repeat === "--" || frequency === "Daily") return undefined;
  if (WeeklyRepeatOptions.includes(repeat as ServerFeedWeeklyRepeat))
    return repeat;
  return `Every ${repeat}`;
};

export const convertStartTimeForServer = (isoStartTime?: string) => {
  if (!isoStartTime) return undefined;
  if (isoStartTime === "--") return "00:00";
  return moment(isoStartTime).format("HH:mm");
};

export const getStartHoursMinutes = (
  isoStartTime?: string,
): ServerHoursMinutesConfig => {
  if (!isoStartTime || isoStartTime === "--") return { hours: 0, minutes: 0 };
  const time = moment(isoStartTime);
  return {
    hours: time.get("hours"),
    minutes: time.get("minutes"),
  };
};

export const getWeeklyFrequencyDate = (
  feedForm: IFeedForm,
  startMoment: moment.Moment,
  startTime: ServerHoursMinutesConfig,
  now: moment.Moment,
) => {
  const dayIndex =
    !feedForm.repeat || feedForm.repeat === "--"
      ? 1
      : Object.values(WeeklyRepeatOptions).indexOf(
          feedForm.repeat as ServerFeedWeeklyRepeat,
        ) + 1;
  const comingDayIndex = () => {
    const todayWeekday = moment().weekday();
    if (todayWeekday === dayIndex && startMoment > now) {
      return dayIndex;
    }
    return todayWeekday < dayIndex ? dayIndex : dayIndex + 7;
  };
  return moment()
    .day(comingDayIndex())
    .set("hour", startTime.hours)
    .set("minutes", startTime.minutes);
};

export const getDateForFrequency = (feedForm: IFeedForm) => {
  const startTime = getStartHoursMinutes(feedForm.startTime);
  const now = moment();
  const startMoment = moment()
    .set("hour", startTime.hours)
    .set("minutes", startTime.minutes);
  switch (feedForm.frequency) {
    case IFrequency.Hourly:
    case IFrequency.Daily:
      if (startMoment < now) {
        now.add(1, "day");
      }
      return now.set("hour", startTime.hours).set("minutes", startTime.minutes);
    case IFrequency.Weekly:
      return getWeeklyFrequencyDate(feedForm, startMoment, startTime, now);
    default:
      throw new Error("Could not determine Feed frequency");
  }
};

export const createNextTimeStamp = (feedForm: IFeedForm) => {
  const nextDate = getDateForFrequency(feedForm);
  switch (feedForm.timezone) {
    case "EST":
      return nextDate.utcOffset(-5).toDate().getTime();
    case "PST":
      return nextDate.utcOffset(-8).toDate().getTime();
    default:
      return nextDate.utcOffset(0).toDate().getTime();
  }
};
