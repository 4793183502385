import { TExtendedVideo } from "shared/types/designStudio";
import { isVideo } from "../../validators";

export const getVideoData = (canvas?: fabric.StaticCanvas) => {
  const layers = canvas?.getObjects().filter(isVideo);
  const background = [canvas?.backgroundImage].filter(isVideo);
  return [...(background ?? []), ...(layers ?? [])].filter(
    Boolean,
  ) as TExtendedVideo[];
};
