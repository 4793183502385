import groupBy from "lodash/groupBy";
import {
  UploadResults,
  LoadStatus,
  PmaxAdReviewItem,
  PmaxLoadItem,
} from "../../shared/types";

export const getNumberByStatus = (results: UploadResults, status: LoadStatus) =>
  results.responses.filter(response => response.status === status).length;

const getReviewItemLoadStatus = (
  item: PmaxAdReviewItem,
  uploadResults: UploadResults,
): LoadStatus =>
  uploadResults.responses.find(uR => uR.name === item.assetGroup.name)
    ?.status || "Loading";

export const parseLoadItems = (
  items: PmaxAdReviewItem[],
  uploadResults: UploadResults,
): PmaxLoadItem[] =>
  Object.entries(groupBy(items, item => item.campaign.name)).map(
    ([key, value]) => ({
      campaign: key,
      assetGroups: value.map(item => ({
        ...item,
        loadStatus: getReviewItemLoadStatus(item, uploadResults),
      })),
    }),
  );

export const filterLoadItems = (items: PmaxLoadItem[], status: LoadStatus) => {
  const filteredItems = items.map(item => ({
    ...item,
    assetGroups: item.assetGroups.filter(
      assetGroup => assetGroup.loadStatus === status,
    ),
  }));
  return filteredItems.filter(item => item.assetGroups.length > 0);
};

export const getComputedCampaignLoadStatus = (item: PmaxLoadItem) => {
  const statuses = item.assetGroups.map(assetGroups => assetGroups.loadStatus);
  if (statuses.includes("Loading")) return "Loading";
  if (statuses.every(status => status === "Failed")) return "Failed";
  if (statuses.every(status => status === "Loaded")) return "Loaded";
  return "Mixed";
};

// We currently only support loading ads for a single account at a time
export const getAdAccountNameAndCID = (items: PmaxAdReviewItem[]) => ({
  name: items?.[0]?.adAccount?.name || "",
  cid: items?.[0]?.adAccount?.id || "",
});
