import { CheckCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import { message, Modal, Table, Checkbox, notification } from "antd";
import { ColumnProps } from "antd/es/table";
import moment from "moment";
import { FC, useRef } from "react";
import { IAccountRecord } from "shared/types/accountManagement";
import {
  INewOrder,
  INewOrderRecord,
  INewOrderTableRecord,
  LADStatusOptions,
  OrderStatus,
  PharmaStatusOptions,
  StatusOptions,
} from "shared/types/newOrders";
import uuid from "uuid/v4";
import { isEnvVarEquals, isPharma } from "utils/helpers";
import {
  getActionColumns,
  getColumnData,
  getSorter,
} from "./OrdersTable.utils";
import { useFetchOrders } from "shared/hooks/assetBuilder/useFetchOrders";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import actions from "redux/rootActions";
import { DecodedValueMap } from "use-query-params";
import { OrderQueryType } from "../Orders";
import { OrdersTableAction } from "shared/types/assetBuilder";
import ActionButton from "./ordersTable/ActionButton";
import StatusSelect from "./ordersTable/StatusSelect";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { ColumnType } from "antd/lib/table";

interface INewOrderTable {
  orderStatus: OrderStatus; // This indicates the status of the orders that should be displayed in this table component. ex) Archived, or Expired
  openOrderDrawer: (newOrderRecord: INewOrderRecord) => void;
  duplicateOrderState: (
    originalOrderId: string,
    newOrderId: string,
    resetToFeedData: boolean,
  ) => void;
  createNewOrder: (inputNewOrder: INewOrder, message?: string) => void;
  updateNewOrder: (
    updateOrder: Partial<INewOrderRecord>,
    message?: string,
  ) => void;
  dealerRecords: IAccountRecord[];

  setProcessingOrderId: (orderId: string) => void;
  processingOrderId?: string;

  launcherOrderId?: string;
  setLauncherOrderId: (orderId: string) => void;

  resetAssetInstances: () => void;
  onPaginationChange?: (page: number, pageSize: number | undefined) => void;
  fetchOrderStateV2: (orderRecord: INewOrderRecord | INewOrder) => void;
  query: DecodedValueMap<OrderQueryType>;
}

const OrdersTable: FC<INewOrderTable> = ({
  orderStatus,
  openOrderDrawer,
  duplicateOrderState,
  createNewOrder,
  updateNewOrder,
  setProcessingOrderId,
  processingOrderId,
  launcherOrderId,
  setLauncherOrderId,
  resetAssetInstances,
  onPaginationChange,
  fetchOrderStateV2,
  query,
}) => {
  const { featureFlags, user } = useAppShallowEqualSelector(
    ({ configuration, auth }) => ({
      featureFlags: configuration.config?.featureFlags,
      user: auth.user,
    }),
  );

  const dispatch = useAppDispatch();
  const onResults = (creators: string[]): void =>
    dispatch(actions.assetBuilder.setCreators(creators));
  const { isFetching, orders, invalidate, records, filters } = useFetchOrders(
    query,
    orderStatus,
    onResults,
  );

  const handleStatusUpdate = (
    tableOrder: INewOrderTableRecord,
    status: StatusOptions,
  ) => {
    const orderToUpdate = orders.find(order => order.id === tableOrder.id);
    if (!orderToUpdate) return;

    const orderRecordConversion: Partial<INewOrderRecord> = {
      id: orderToUpdate.id,
      status,
    };
    updateNewOrder(orderRecordConversion, "The order status has been updated");
    invalidate();
  };

  const handleArchive = (tableOrder: INewOrderTableRecord) => {
    const orderToArchive = orders.find(order => order.id === tableOrder.id);
    if (!orderToArchive) return;

    const orderRecordConversion: Partial<INewOrderRecord> = {
      id: orderToArchive.id,
      is_archived: true,
    };
    updateNewOrder(orderRecordConversion, "The order has been archived");
    invalidate();
  };

  const isAdmin = useIsAdmin();

  const handleDuplicate = async (
    tableOrder: INewOrderTableRecord,
    resetToFeedData: boolean,
  ) => {
    const orderToDuplicate = orders.find(order => order.id === tableOrder.id);
    if (!orderToDuplicate) return;

    const id = uuid();

    await duplicateOrderState(orderToDuplicate.id, id, resetToFeedData);

    const duplicatedOrder: INewOrder = {
      ...orderToDuplicate,
      id,
      pushed_WF: false,
      proofUploadData: "",
      creator_name: `${user?.name}`,
      createdAt: Date.now(),
      expiresAt: moment().add(1, "month").valueOf(),
    };
    await createNewOrder(duplicatedOrder, "The order has been duplicated");
    invalidate();
  };

  const columnData = getColumnData(featureFlags?.enableWorkfront || false);
  const generateTableColumns = (
    cData: Array<{
      key: keyof INewOrderTableRecord;
      title: string;
      width: string;
    }>,
  ): Array<ColumnProps<INewOrderTableRecord>> => {
    return cData.map(data => {
      const { key, title, width } = data;

      return {
        key,
        title,
        dataIndex: key,
        defaultSortOrder: key === "createdAt" ? "descend" : undefined,
        width: width,
        filters: key === "tags" ? filters.tags : undefined,
        onFilter:
          key === "tags"
            ? (value, record) => !record.tags?.includes(value as string)
            : undefined,
        render: (data, record) => {
          switch (key) {
            case "createdAt":
              return record.createdText;

              case "pushed_WF":
                return data ? (
                  <CheckCircleTwoTone
                    twoToneColor="#33cc33"
                    style={{ fontSize: "2em" }}
                  />
                ) : (
                  <MinusCircleTwoTone
                    twoToneColor="#e6e600"
                    style={{ fontSize: "2em" }}
                  />
                );
              case "status":
                const isLad = isEnvVarEquals("CLIENT", "ladtech");
                const getOptions = (isLad: boolean, isPharma: boolean) => {
                  if (isPharma) return PharmaStatusOptions;
                  if (isLad) return LADStatusOptions;
                  return StatusOptions;
                };
                const options = getOptions(isLad, isPharma);
                return (
                  <StatusSelect
                    value={data}
                    disabled={!isAdmin}
                    record={record}
                    handleStatusUpdate={handleStatusUpdate}
                    options={options}
                  />
                );

            default:
              return data;
          }
        },

        sorter: (a: INewOrderTableRecord, b: INewOrderTableRecord) =>
          getSorter(a, b, key),
      };
    });
  };

  const resetToFeedData = useRef(false);
  const getActionButton = (
    actionType: OrdersTableAction,
    record: INewOrderTableRecord,
  ) => {
    switch (actionType) {
      case "edit":
        return (
          <ActionButton
            record={record}
            type="edit"
            user={user || undefined}
            onClick={() => {
              const order = orders.find(
                tmpRecord => tmpRecord.id === record.id,
              );
              if (!order) {
                message.error("Failed to find selected order.");

                return;
              }

              // NOTE: INewOrder and INewOrderRecord are almost identical except "key".
              // In later refactor, the types related to order has to be re-organized.
              const orderRecord: INewOrderRecord = {
                ...order,
              } as INewOrderRecord;
              openOrderDrawer(orderRecord);
            }}
          />
        );

            case "modify":
              return (
                <ActionButton
                  record={record}
                  type="modify"
                  user={user || undefined}
                  loading={processingOrderId === record.id}
                  onClick={() => {
                    if (!isAdmin && !record.num_assets) {
                      notification.warning({
                        message: "Warning",
                        description: `0 Assets found. Unable to access this part of Asset Builder. Please reach out to an account admin if you require access.`,
                        placement: "bottomRight",
                      });

                return;
              }

              setProcessingOrderId(record.id);

              const orderRecord = orders.find(
                tmpRecord => tmpRecord.id === record.id,
              );

              if (!orderRecord) {
                message.error("Failed to process selected order.");

                return;
              }
              resetAssetInstances();

              // NOTE: setting "savedOrder" in the redux store will be done in Orders.tsx with useFetchOrderState custom hook
              fetchOrderStateV2(orderRecord);
            }}
          />
        );
      case "archive":
        return (
          <ActionButton
            record={record}
            type="archive"
            user={user || undefined}
            onClick={() => {
              Modal.confirm({
                title: "Confirm Archive",
                content: "Are you sure you want to archive this order?",
                cancelText: "No",
                okText: "Yes",
                onOk: () => {
                  handleArchive(record);
                },
              });
            }}
          />
        );
      case "launcher":
        return (
          <ActionButton
            record={record}
            type="launcher"
            user={user || undefined}
            loading={launcherOrderId === record.id}
            onClick={() => {
              setLauncherOrderId(record.id);

              const orderRecord = orders.find(
                tmpRecord => tmpRecord.id === record.id,
              );

              if (!orderRecord) {
                message.error("Failed to process selected order.");

                return;
              }
              // AV2-1461 bugfix
              resetAssetInstances();

              const order = {
                ...orderRecord,
                selectTemplateCollapseSearchInput:
                  orderRecord.selectTemplateCollapseSearchInput || "",
                selectImageCollapseSearchInput:
                  orderRecord.selectImageCollapseSearchInput || "",
              } as INewOrderRecord;
              fetchOrderStateV2(order);
            }}
          />
        );

      case "duplicate":
        return (
          <ActionButton
            record={record}
            type="duplicate"
            user={user || undefined}
            onClick={() => {
              resetToFeedData.current = false; // reset before confirm modal appears.

              Modal.confirm({
                title: "Confirm Duplicate",
                content: (
                  <div>
                    Are you sure you want to duplicate this order?
                    <Checkbox
                      style={{
                        marginTop: "1rem",
                        marginBottom: "-.5rem",
                        fontWeight: 700,
                      }}
                      onChange={e => {
                        resetToFeedData.current = e.target.checked;
                      }}
                    >
                      Reset Offer Data back to Feed Data?
                    </Checkbox>
                  </div>
                ),
                cancelText: "No",
                okText: "Yes",
                onOk: () => {
                  handleDuplicate(record, resetToFeedData.current);
                },
              });
            }}
          />
        );

      case "restore":
        return (
          <ActionButton
            record={record}
            type="restore"
            user={user || undefined}
            onClick={() => {
              const orderToRestore = orders.find(
                order => order.id === record.id,
              );
              if (!orderToRestore) return;

              const orderRecordConversion: INewOrderRecord = {
                ...orderToRestore,
                is_archived: false,
              } as INewOrderRecord;
              updateNewOrder(
                orderRecordConversion,
                "The order has been restored",
              );

              invalidate();
            }}
          />
        );
    }
  };

  const actionColumns: OrdersTableAction[] = getActionColumns(
    orderStatus,
    !!featureFlags?.enableLauncher,
  );

  return (
    <Table<INewOrderTableRecord>
      loading={isFetching}
      scroll={{
        x: isPharma ? 1000 : 2000 /*, y: true - TODO: Review this*/,
      }}
      className="data-management-table"
      columns={[
        ...generateTableColumns(columnData),
        ...actionColumns.map<ColumnType<INewOrderTableRecord>>(column => ({
          fixed: "right",
          width: "5%",
          render: (value, record) => getActionButton(column, record),
        })),
      ]}
      dataSource={records}
      pagination={{
        pageSize: 50,
        showSizeChanger: false,
        onChange: onPaginationChange,
      }}
      style={{ whiteSpace: "unset" }}
    />
  );
};

export default OrdersTable;
