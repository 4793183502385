/* eslint-disable react/display-name */
import { SetStateAction } from "react";
import { Tooltip, Typography } from "antd";

import NameCardCell from "screens/adLibrary/shared/components/NameCardCell";

import moment from "moment";
import styles from "./columns.module.scss";

import {
  compareString,
  checkFilterMatch,
  checkDateMatch,
  getUniqValues,
} from "utils/helpers";

import { IAd, IAdWithChildren, QcStatus } from "shared/types/adLibrary";
import { AdType, CallToAction } from "screens/adLibrary/facebookUtils/types";

import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";

import {
  getValidationForAdId,
  hasErrors,
  IAdValidations,
} from "screens/adLibrary/adImport/adValidationUtils";
import classNames from "classnames";
import AutoEllipsisText from "shared/components/AutoEllipsisText";
import { months } from "utils/helpers.date";
import { ColumnFilterItem } from "antd/lib/table/interface";
import FilterDropdown from "../../../../adLoad/adLoadDrawer/shared/components/FilterDropdown";
import IndeterminateSwitch from "shared/button/IndeterminateSwitch";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import Tags from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/Tags";
import StatusSwitch from "./StatusSwitch";
import { callToActionValues } from "screens/adLibrary/constants";
import {
  getAdFormatTypes,
  getAdTypeLabel,
  getCtaText,
} from "screens/adLibrary/utils";
import {
  isNotChildRecord,
  isChildRecord,
  adDoesNotHaveChildren,
} from "./adImportTable.utils";
import { terms } from "utils/terms";
import { EditableCellType } from "shared/components/dataList/EditableCell";
import { EditableColumnParameters } from "shared/components/dataList/setupEditableCells";
interface IModel {
  year: string;
  name: string;
}

export type AdTableColumn = EditableColumnParameters<IAd> & {
  name: string;
  width: string | number;
};

interface Props {
  ads: IAd[];
  setAd: (adId: string, ad: IAd) => void;
  setAds: (ads: IAd[]) => void;
  stickyColumns?: string[];
  validationErrors?: IAdValidations[];
  setPreviewAd: (value: SetStateAction<IAd | null>) => void;
  onToggleExpand: (record: IAd) => void;
  expandedRowKeys: string[];
}

const getCellProps = (
  adId: string,
  field: keyof IAdValidations,
  validationErrors: IAdValidations[] | undefined,
) =>
  classNames({
    "cell-with-error": !!getValidationForAdId(adId, validationErrors ?? [])?.[
      field
    ],
  });

export const getColumns = ({
  ads,
  setAd,
  setAds,
  stickyColumns,
  validationErrors,
  setPreviewAd,
  onToggleExpand,
  expandedRowKeys,
}: Props): AdTableColumn[] => {
  const oems = getUniqValues(ads, ad => ad?.inputParameters?.oems);
  const adFormatTypes = getAdFormatTypes();

  const isExpanded = (record: IAd) => expandedRowKeys.includes(record.id);

  const MultipleIfHasChildren = ({
    text,
    record,
    numberOfItems,
    tooltip,
  }: {
    text: string;
    record: IAdWithChildren;
    numberOfItems: number;
    tooltip: string;
  }) => {
    if (adDoesNotHaveChildren(record)) {
      return <AutoEllipsisText>{text}</AutoEllipsisText>;
    }

    return (
      <Tooltip title={tooltip}>
        <Typography.Link
          onClick={e => {
            e.stopPropagation();
            onToggleExpand(record);
          }}
        >
          Multiple ({numberOfItems})
        </Typography.Link>
      </Tooltip>
    );
  };

  const columns: AdTableColumn[] = [
    {
      name: "Name",
      title: "Name",
      dataIndex: ["inputParameters", "name"],
      sorter: (a: IAd, b: IAd) =>
        compareString(a.inputParameters?.name, b.inputParameters?.name),
      render: (name: string, ad: IAd) => (
        <NameCardCell
          ad={ad}
          name={name}
          onImageClick={event => {
            event.stopPropagation();
            setPreviewAd(ad);
          }}
        />
      ),
      fixed: "left",
      className: styles.nameCell,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.name?.toString(),
        ),
      getClassNameOnError: record =>
        getCellProps(record.id, "name", validationErrors),
      width: 250,
      editable: isNotChildRecord,
    },
    {
      name: "Ad Format",
      title: "Ad Format",
      dataIndex: "type",
      sorter: (a: IAd, b: IAd) => compareString(a.type, b.type),
      render: (adFormat: AdType, record) => {
        if (isChildRecord(record)) return null;
        return adFormat ? getAdTypeLabel(adFormat) : "";
      },
      filters: Object.values(AdType).map(adType => {
        [].indexOf;
        return {
          text: getAdTypeLabel(adType),
          value: adType,
        };
      }),
      onFilter: (value, record: IAd) =>
        record.type?.indexOf(value as AdType) === 0,
      getClassNameOnError: record =>
        getCellProps(record.id, "adFormat", validationErrors),
      width: 180,
      editable: record =>
        isNotChildRecord(record) && adDoesNotHaveChildren(record),
      editableCellType: EditableCellType.Select,
      selectOptions: adFormatTypes,
    },
    {
      name: "Status",
      title: () => (
        <StatusSwitch
          ads={ads}
          setAds={setAds}
          validationErrors={validationErrors}
        />
      ),
      dataIndex: "qcStatus",
      render: (value: QcStatus, ad) => {
        if (isChildRecord(ad)) return "";
        return (
          <IndeterminateSwitch
            size="small"
            disabled={hasErrors(ad.id, validationErrors)}
            checked={value === QcStatus.INTERNALLY_APPROVED}
            onChange={checked => {
              setAd(ad.id, {
                ...ad,
                qcStatus: checked
                  ? QcStatus.INTERNALLY_APPROVED
                  : QcStatus.DRAFT,
              });
            }}
          />
        );
      },
      className: "ant-table-selection-column",
      width: 120,
    },
    {
      name: "Primary Text",
      title: "Primary Text",
      dataIndex: ["visuals", "postCopy"],
      sorter: (a: IAd, b: IAd) =>
        compareString(a.visuals?.postCopy, b.visuals?.postCopy),
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.visuals?.postCopy?.toString(),
        ),
      getClassNameOnError: record =>
        getCellProps(record.id, "postCopy", validationErrors),
      width: 360,
      editable: isNotChildRecord,
    },
    {
      name: "Headline",
      title: "Headline",
      dataIndex: ["visuals", "headline"],
      sorter: (a: IAd, b: IAd) =>
        compareString(a.visuals?.headline, b.visuals?.headline),
      render: (text, record) => (
        <MultipleIfHasChildren
          text={text}
          record={record}
          numberOfItems={
            record.visuals?.cards?.filter(card => card.headline).length ?? 0
          }
          tooltip={
            isExpanded(record)
              ? "Click to collapse Headlines"
              : "Click to view all Headlines"
          }
        />
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record: IAd) =>
        checkFilterMatch(
          value?.toString(),
          record.visuals?.headline?.toString(),
        ),
      getClassNameOnError: record =>
        getCellProps(record.id, "headline", validationErrors),
      width: 370,
      editable: adDoesNotHaveChildren,
    },
    {
      name: "Destination URL",
      title: "Destination URL",
      dataIndex: ["inputParameters", "destinationUrl"],
      sorter: (a: IAd, b: IAd) =>
        compareString(
          a.inputParameters?.destinationUrl,
          b.inputParameters?.destinationUrl,
        ),
      render: (text, record) => (
        <MultipleIfHasChildren
          text={text}
          record={record}
          numberOfItems={
            record.visuals?.cards?.filter(card => card.destinationUrl).length ??
            0
          }
          tooltip={
            isExpanded(record)
              ? "Click to collapse Destination URLs"
              : "Click to view all Destination URLs"
          }
        />
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.destinationUrl?.toString(),
        ),
      width: 380,
      editable: adDoesNotHaveChildren,
    },
    {
      name: "Description",
      title: "Description",
      dataIndex: ["visuals", "headlineDescription"],
      sorter: (a: IAd, b: IAd) =>
        compareString(
          a.visuals?.headlineDescription,
          b.visuals?.headlineDescription,
        ),
      render: (text, record) => (
        <MultipleIfHasChildren
          text={text}
          record={record}
          numberOfItems={
            record.visuals?.cards?.filter(card => card.description).length ?? 0
          }
          tooltip={
            isExpanded(record)
              ? "Click to collapse Descriptions"
              : "Click to view all Descriptions"
          }
        />
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.visuals?.headlineDescription?.toString(),
        ),
      getClassNameOnError: record =>
        getCellProps(record.id, "headlineDescription", validationErrors),
      width: 370,
      editable: adDoesNotHaveChildren,
    },
    {
      name: "CTA Button",
      title: "CTA Button",
      dataIndex: ["visuals", "ctaButtonText"],
      render: (cta, record) => (
        <MultipleIfHasChildren
          text={getCtaText(cta) ?? cta}
          record={record}
          numberOfItems={
            record.visuals?.cards?.filter(card => card.ctaButtonText).length ??
            0
          }
          tooltip={
            isExpanded(record)
              ? "Click to collapse CTA Buttons"
              : "Click to view all CTA Buttons"
          }
        />
      ),
      sorter: (a: IAd, b: IAd) =>
        compareString(a.visuals?.ctaButtonText, b.visuals?.ctaButtonText),
      filters: Object.values(CallToAction)
        .filter(Boolean)
        .map(status => {
          return { text: status, value: status };
        }),
      onFilter: (value, record) =>
        record.visuals?.ctaButtonText?.indexOf(value as CallToAction) === 0,
      getClassNameOnError: record =>
        getCellProps(record.id, "ctaButtonText", validationErrors),
      width: 130,
      editable: adDoesNotHaveChildren,
      editableCellType: EditableCellType.Select,
      selectOptions: callToActionValues,
    },
    {
      name: "Display URL",
      title: "Display URL",
      dataIndex: ["visuals", "displayUrl"],
      sorter: (a: IAd, b: IAd) =>
        compareString(a.visuals?.displayUrl, b.visuals?.displayUrl),
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record: IAd) =>
        checkFilterMatch(
          value?.toString(),
          record.visuals?.displayUrl?.toString(),
        ),
      width: 370,
      editable: isNotChildRecord,
    },
    {
      name: "UTM",
      title: "UTM",
      dataIndex: ["inputParameters", "utm"],
      sorter: (a: IAd, b: IAd) =>
        compareString(a.inputParameters?.utm, b.inputParameters?.utm),
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.utm?.toString(),
        ),
      getClassNameOnError: record =>
        getCellProps(record.id, "utm", validationErrors),
      width: 380,
      editable: isNotChildRecord,
    },
    {
      name: "Start Date",
      title: "Start Date",
      dataIndex: ["inputParameters", "campaignStartDate"],
      render: date => (date ? moment(parseInt(date)).format("MM/DD/YY") : ""),
      sorter: (a, b) =>
        compareString(
          a.inputParameters?.campaignStartDate,
          b.inputParameters?.campaignStartDate,
        ),
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkDateMatch(
          value?.toString(),
          parseInt(record.inputParameters?.campaignStartDate || "0"),
        ),
      width: 150,
      editable: isNotChildRecord,
      editableCellType: EditableCellType.Date,
    },
    {
      name: "End Date",
      title: "End Date",
      dataIndex: ["inputParameters", "campaignEndDate"],
      render: (date: string) =>
        date ? moment(parseInt(date)).format("MM/DD/YY") : "",
      sorter: (a: IAd, b: IAd) =>
        compareString(
          a.inputParameters?.campaignEndDate,
          b.inputParameters?.campaignEndDate,
        ),
      filterDropdown: DateFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkDateMatch(
          value?.toString(),
          parseInt(record.inputParameters?.campaignEndDate || "0"),
        ),
      width: 150,
      editable: isNotChildRecord,
      editableCellType: EditableCellType.Date,
    },
    {
      name: "Audience",
      title: "Audience",
      dataIndex: ["inputParameters", "audiences"],
      render: audiences => <Tags tags={audiences} />,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.audiences?.join(", "),
        ),
      width: 250,
      editable: isNotChildRecord,
      editableCellType: EditableCellType.MultipleInput,
    },
    {
      name: "Tags",
      title: "Tags",
      dataIndex: ["inputParameters", "tags"],
      render: tags => <Tags tags={tags} />,
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.tags?.join(", "),
        ),
      width: 190,
      editable: isNotChildRecord,
      editableCellType: EditableCellType.MultipleInput,
    },
    {
      name: "Store",
      title: "Store",
      key: "store",
      dataIndex: ["inputParameters", "client"],
      render: (text: string) => (
        <AutoEllipsisText>{text?.split(",").join(", ") || ""}</AutoEllipsisText>
      ),
      sorter: (a: IAd, b: IAd) =>
        compareString(a.inputParameters?.client, b.inputParameters?.client),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.client?.toString(),
        ),
      width: 200,
      editable: isNotChildRecord,
    },
    {
      name: terms.accountName,
      title: terms.accountName,
      key: "adAccount",
      dataIndex: ["inputParameters", "client"],
      render: (text: string) => (
        <AutoEllipsisText>{text?.split(",").join(", ") || ""}</AutoEllipsisText>
      ),
      sorter: (a: IAd, b: IAd) =>
        compareString(a.inputParameters?.client, b.inputParameters?.client),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.client?.toString(),
        ),
      width: 200,
      editable: isNotChildRecord,
    },
    {
      name: "Package",
      title: "Package",
      dataIndex: ["inputParameters", "package"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      sorter: (a: IAd, b: IAd) =>
        compareString(a.inputParameters?.package, b.inputParameters?.package),
      filters: ["A", "B", "C", "D"].map(packageCategory => {
        return { text: packageCategory, value: packageCategory };
      }),
      onFilter: (value, record) =>
        record.inputParameters?.package?.indexOf(value?.toString()) === 0,
      width: 100,
      editable: isNotChildRecord,
    },
    {
      name: "OEM",
      title: "OEM",
      dataIndex: ["inputParameters", "oems"],
      key: "oem",
      render: (oems: string[]) => (
        <AutoEllipsisText>{oems?.join(", ") || ""}</AutoEllipsisText>
      ),
      filterDropdown: FilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      filters: oems?.map(
        (oem): ColumnFilterItem => ({
          text: oem,
          value: oem,
        }),
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.oems?.join(", "),
        ),
      width: 210,
      editable: isNotChildRecord,
      editableCellType: EditableCellType.MultipleInput,
    },
    {
      name: "Brand",
      title: "Brand",
      key: "brand",
      dataIndex: ["inputParameters", "oems"],
      render: (oems: string[]) => (
        <AutoEllipsisText>{oems?.join(", ") || ""}</AutoEllipsisText>
      ),
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.oems?.join(", "),
        ),
      width: 210,
      editable: isNotChildRecord,
    },
    {
      name: "Model Year",
      title: "Model Year",
      key: "modelYear",
      dataIndex: ["inputParameters", "models", 0, "year"],
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.models
            ?.map((model: IModel) => model.year)
            .join(","),
        ),
      width: 110,
      editable: isNotChildRecord,
    },
    {
      name: "Model Name",
      title: "Model Name",
      key: "modelName",
      dataIndex: ["inputParameters", "models", 0, "name"],
      filterDropdown: SearchFilterDropdown,
      filterIcon: (filtered: boolean) => (
        <SearchOutlined color={filtered ? "#1890ff" : undefined} />
      ),
      onFilter: (value, record) =>
        checkFilterMatch(
          value?.toString(),
          record.inputParameters?.models
            ?.map((model: IModel) => model.name)
            .join(","),
        ),
      width: 170,
      editable: isNotChildRecord,
    },
    {
      name: "Year",
      title: "Year",
      dataIndex: ["inputParameters", "year"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      sorter: (a: IAd, b: IAd) =>
        compareString(a.inputParameters?.year, b.inputParameters?.year),
      onFilter: (value, record) =>
        record.category?.indexOf(value?.toString()) === 0,
      width: 130,
      editable: isNotChildRecord,
    },
    {
      name: "Month",
      title: "Month",
      dataIndex: ["inputParameters", "month"],
      render: months => <Tags tags={months} />,
      filters: months.map(month => {
        return { text: month, value: month };
      }),
      onFilter: (value, record) =>
        (record.inputParameters?.month || []).includes(value as string),
      width: 200,
    },
    {
      name: "Version",
      title: "Version",
      dataIndex: ["inputParameters", "version"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      sorter: (a: IAd, b: IAd) =>
        compareString(a.inputParameters?.version, b.inputParameters?.version),
      onFilter: (value, record) =>
        record.category?.indexOf(value?.toString()) === 0,
      width: 130,
      editable: isNotChildRecord,
    },
    {
      name: "Strategy",
      title: "Strategy",
      dataIndex: ["inputParameters", "strategy"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      sorter: (a: IAd, b: IAd) =>
        compareString(a.inputParameters?.strategy, b.inputParameters?.strategy),
      filters: ["Prospecting", "Retargeting"].map(strategy => {
        return { text: strategy, value: strategy };
      }),
      onFilter: (value, record) =>
        record.inputParameters?.strategy?.indexOf(value as string) === 0,

      width: 130,
      editable: isNotChildRecord,
    },
    {
      name: "Story Placement",
      title: "Story Placement",
      dataIndex: ["inputParameters", "storyPlacement"],
      render: (text: string) => <AutoEllipsisText>{text}</AutoEllipsisText>,
      sorter: (a: IAd, b: IAd) =>
        compareString(
          a.inputParameters?.storyPlacement,
          b.inputParameters?.storyPlacement,
        ),
      filters: ["Yes", "No"].map(placement => {
        return { text: placement, value: placement };
      }),
      onFilter: (value, record) =>
        record.inputParameters?.storyPlacement?.indexOf(value as string) === 0,
      width: 130,
      editable: isNotChildRecord,
    },
  ];
  stickyColumns?.length &&
    columns.forEach((column: any) => {
      if (stickyColumns.includes(column.name)) {
        column.fixed = "left";
      }
    });

  return columns;
};
