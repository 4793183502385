import {
  EditOutlined,
  InboxOutlined,
  DeleteOutlined,
  FileDoneOutlined,
  EyeOutlined,
  CopyOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { BaseDesignStudioItemCardProps } from "../DesignStudioItemCard";
import styles from "../DesignStudioItemCard.module.scss";
import { useDesignStudioTableActions } from "../../designStudioTable/hooks/useDesignStudioTableActions";
import { isPublished } from "../../../helpers";
import UnpublishOutlined from "shared/icons/UnpublishOutlined";

export const CardActions = ({ item }: BaseDesignStudioItemCardProps) => {
  const {
    editParams,
    showPreview,
    archive,
    edit,
    deleteAsset,
    publish,
    duplicate,
  } = useDesignStudioTableActions();

  const itemIsPublished = isPublished(item);

  const handleClick =
    (callback: () => void) =>
    (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e.stopPropagation();
      callback();
    };

  return (
    <div className={styles.actions}>
      {!edit.disabled(item) && (
        <Tooltip title="Edit">
          <Button
            icon={<EditOutlined />}
            onClick={handleClick(() => edit.handler(item))}
            className={styles.actionButton}
          />
        </Tooltip>
      )}
      {!showPreview.disabled(item) && (
        <Tooltip title="Preview">
          <Button
            icon={<EyeOutlined />}
            onClick={handleClick(() => showPreview.handler(item))}
          />
        </Tooltip>
      )}

      {!editParams.disabled(item) && (
        <Tooltip title="Edit parameters">
          <Button
            icon={<ControlOutlined />}
            onClick={handleClick(() => editParams.handler(item))}
          />
        </Tooltip>
      )}
      {!duplicate.disabled(item) && (
        <Tooltip title="Duplicate">
          <Button
            icon={<CopyOutlined />}
            onClick={handleClick(() => duplicate.handler(item))}
          />
        </Tooltip>
      )}
      {!publish.disabled(item) && (
        <Tooltip title={itemIsPublished ? "Unpublish" : "Publish"}>
          <Button
            icon={
              itemIsPublished ? <UnpublishOutlined /> : <FileDoneOutlined />
            }
            onClick={handleClick(() => publish.handler(item))}
          />
        </Tooltip>
      )}
      {!archive.disabled([item]) && (
        <Tooltip title="Archive">
          <Button
            icon={<InboxOutlined />}
            onClick={handleClick(() => archive.handler([item]))}
          />
        </Tooltip>
      )}
      {!deleteAsset.disabled(item) && (
        <Tooltip title="Delete">
          <Button
            icon={<DeleteOutlined />}
            onClick={handleClick(() => deleteAsset.handler(item))}
          />
        </Tooltip>
      )}
    </div>
  );
};
