import { notification } from "antd";
import { useLazyGetAssetEmbedlinksQuery } from "redux/media/media.api";

export const useShareLink = () => {
  const [getAssetUrl] = useLazyGetAssetEmbedlinksQuery();

  return async (record: WDAsset) => {
    try {
      const { directlinks } = await getAssetUrl(record.id, false).unwrap();
      await navigator.clipboard.writeText(directlinks.original);
      notification.success({
        message: "Copied Link to Clipboard",
      });
    } catch (error) {
      notification.error({
        message: "Failed to share link to clipboard",
      });
    }
  };
};
