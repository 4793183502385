import { useQuery } from "react-query";
import API from "services";
import { IImageGenParams, IImageGenResponse } from "shared/types/genAI";

export default (args: IImageGenParams) => {
  const { data, isLoading, refetch, isRefetching } = useQuery<
    IImageGenResponse,
    Error
  >(
    ["generateImages", args.prompt],
    () => API.services.genAI.generateImages(args.prompt, args.nSamples),
    { retry: false, enabled: false },
  );

  return {
    isFetching: isLoading || isRefetching,
    data,
    handleGenerateImages: refetch,
  } as unknown as {
    isFetching: boolean;
    data: IImageGenResponse;
    handleGenerateImages: () => void;
  };
};
