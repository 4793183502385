const getFirstPart = (
  text: string,
  middleOfText: number,
  hiddenTextLength: number,
) => {
  const firstPartStart = 0;
  const firstPartEnd = Math.floor(middleOfText - hiddenTextLength / 2);
  const firstPart = text.substring(firstPartStart, firstPartEnd);
  return firstPart;
};

const getSecondPart = (
  text: string,
  middleOfText: number,
  hiddenTextLength: number,
) => {
  const secondPartStart = Math.ceil(middleOfText + hiddenTextLength / 2);
  const secondPart = text.substring(secondPartStart);
  return secondPart;
};

export const getTextWithEllipses = (
  text: string,
  containerWidth: number,
  numberOfRows: number,
  wordWidth: number,
) => {
  const ellipses = "...";
  const rowLength = Math.floor(containerWidth / wordWidth);
  const availableLength = numberOfRows * rowLength;
  const overflowTextLength = text.length - availableLength;

  if (overflowTextLength > 0) {
    const middleOfText = text.length / 2;
    const hiddenTextLength = overflowTextLength + ellipses.length;

    const textBeforeEllipses = getFirstPart(
      text,
      middleOfText,
      hiddenTextLength,
    );
    const textAfterEllipses = getSecondPart(
      text,
      middleOfText,
      hiddenTextLength,
    );

    const textWithEllipses = textBeforeEllipses + ellipses + textAfterEllipses;

    return { text: textWithEllipses, rowLength, hasEllipses: true };
  } else {
    return { text, rowLength, hasEllipses: false };
  }
};

// Recursively decode URI component until it is fully decoded
export const fullyDecodeURIComponent = (uriComponent: string): string => {
  if (decodeURIComponent(uriComponent) === uriComponent) return uriComponent;
  return fullyDecodeURIComponent(decodeURIComponent(uriComponent));
};

export const findAllOccurrences = (
  str: string,
  searchTerm: string,
  currentIndex = 0,
  indices: number[] = [],
): number[] => {
  const nextIndex = str.indexOf(searchTerm, currentIndex);
  if (nextIndex === -1) {
    return indices;
  }
  return findAllOccurrences(str, searchTerm, nextIndex + searchTerm.length, [
    ...indices,
    nextIndex,
  ]);
};
