import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Popconfirm,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { RefObject, useState, memo } from "react";
import { callToActionValues } from "screens/adLibrary/constants";
import { ICard, IVisuals } from "shared/types/adLibrary";
import AdCarouselCard from "./AdCarouselCard";
import { CallToAction } from "screens/adLibrary/facebookUtils/types";
import styles from "./AdCarouselForm.module.scss";
import { formatOption } from "utils/helpers";

export const SLIDES_TO_SHOW = 4;

interface IProps {
  cards: ICard[];
  addCard(): void;
  moveCard(index: number, to: "left" | "right"): void;
  removeCard(cardIndex: number): void;
  carouselRef: RefObject<CarouselRef>;
  patchCard(
    index: number,
    newValues: Partial<ICard>,
    updatedCards?: ICard[],
  ): void;
  shouldDisplayErrors: boolean;
  adEditVisuals?: IVisuals;
}

const AdCarouselForm = ({
  cards,
  addCard,
  removeCard,
  patchCard,
  shouldDisplayErrors,
}: IProps) => {
  const { TabPane } = Tabs;
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleCTAChange = (value: CallToAction | undefined) => {
    const updatedCards = cards.map(card => ({
      ...card,
      ctaButtonText: value,
    }));

    patchCard(currentSlide, updatedCards[currentSlide], updatedCards);
  };

  const tabBarExtraContent = {
    right: (
      <div className={styles.buttonsContainer}>
        <Button
          icon={<PlusOutlined />}
          disabled={cards.length > 9}
          onClick={() => {
            addCard();
            setCurrentSlide(cards.length);
          }}
          className={styles.button}
          data-cy="add-visual-button"
        />
        {cards.length && (
          <Popconfirm
            placement="topLeft"
            title={"Are you sure you want to remove this card?"}
            onConfirm={() => {
              removeCard(currentSlide);
              setCurrentSlide(currentSlide > 0 ? currentSlide - 1 : 0);
            }}
            okText="Yes"
            cancelText="No"
            disabled={cards.length <= 2}
            id="delete-visual-confirm"
            data-cy="delete-visual-confirm"
            className={styles.button}
          >
            <Button
              icon={<DeleteOutlined />}
              disabled={cards.length <= 2}
              data-cy="delete-visual-button"
            />
          </Popconfirm>
        )}
      </div>
    ),
  };

  const getTabPaneLabel = (index: number) => index + 1;

  return (
    <>
      <Form.Item
        name={["visuals", "ctaButtonText"]}
        required={true}
        label="CTA Button"
        rules={[
          {
            required: true,
            message: "Please select a CTA button text",
            whitespace: true,
          },
        ]}
      >
        <Select
          onChange={handleCTAChange}
          value={cards[0]?.ctaButtonText}
          data-cy="cta-button-select"
        >
          {callToActionValues.map(({ key, text, value }) => (
            <Select.Option
              key={key}
              value={value}
              data-cy={`option-${formatOption(text)}`}
            >
              {text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Typography.Text className={styles.title}>
        <span>*</span>
        {"Cards"}
      </Typography.Text>
      <Row>
        <Col span={24}>
          <div>
            <Row className={styles.tabsRow} data-cy="visual-controls">
              <Tabs
                activeKey={currentSlide.toString()}
                onChange={value => setCurrentSlide(parseInt(value))}
                tabBarStyle={{ margin: "unset" }}
                tabBarGutter={8}
                className={styles.tabs}
                tabBarExtraContent={tabBarExtraContent}
              >
                {cards.map((_, index) => (
                  <TabPane
                    key={index}
                    tabKey={index.toString()}
                    tab={getTabPaneLabel(index)}
                  >
                    <div data-cy={`card-${getTabPaneLabel(index)}`}>
                      <AdCarouselCard
                        patchCard={patchCard}
                        card={cards[index]}
                        index={index}
                        shouldDisplayErrors={shouldDisplayErrors}
                      />
                    </div>
                  </TabPane>
                ))}
              </Tabs>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default memo(AdCarouselForm);
