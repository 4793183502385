import { CheckOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Collapse, Typography, Space, Button, Table, Tooltip } from "antd";
import { useState, useCallback } from "react";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { LoadStatus, PmaxAdReviewItem, PmaxLoadItem } from "../shared/types";
import { columns } from "./pmaxLoadStep/columns";
import styles from "./pmaxLoadStep/PmaxLoadStep.module.scss";
import { PmaxLoadStepExpandableTable } from "./pmaxLoadStep/PmaxLoadStepExpandableTable";
import {
  getNumberByStatus,
  parseLoadItems,
  getAdAccountNameAndCID,
  filterLoadItems,
} from "./pmaxLoadStep/utils";

type PmaxLoadStepProps = {
  reviewItems: PmaxAdReviewItem[];
};

type LoadStatusFilter = Extract<LoadStatus, "Loaded" | "Failed"> | "All";

export const PmaxLoadStep = ({ reviewItems }: PmaxLoadStepProps) => {
  const { uploadResults } = usePMaxDataContext();
  const dataSource = parseLoadItems(reviewItems, uploadResults);
  const numberOfFailed = getNumberByStatus(uploadResults, "Failed");
  const numberOfLoaded = getNumberByStatus(uploadResults, "Loaded");
  const numberOfProcessedAssetGroups = numberOfFailed + numberOfLoaded;
  const { name: adAccountName, cid: adAccountCID } =
    getAdAccountNameAndCID(reviewItems);
  const [loadStatusFilter, setLoadStatusFilter] =
    useState<LoadStatusFilter>("All");

  const filteredItems =
    loadStatusFilter === "All"
      ? dataSource
      : filterLoadItems(dataSource, loadStatusFilter);

  const handleLoadStatusFilterChange = useCallback(
    (value: LoadStatusFilter) =>
      (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.stopPropagation();

        if (value === loadStatusFilter) return setLoadStatusFilter("All");
        setLoadStatusFilter(value);
      },
    [loadStatusFilter],
  );

  return (
    <Space direction="vertical" className={styles.drawerContent}>
      <Space>
        <Typography.Title level={5}>Creating New Asset Groups</Typography.Title>
      </Space>
      <Collapse
        className={styles.collapseContent}
        defaultActiveKey={adAccountName}
      >
        <Collapse.Panel
          key={adAccountName}
          className={styles.collapsePanel}
          header={
            <Space direction="horizontal" className={styles.titleRow}>
              <Space>
                <Typography.Text strong>Ad account:</Typography.Text>
                <Tooltip title={`CID: ${adAccountCID}`}>
                  <Typography.Text> {adAccountName}</Typography.Text>
                </Tooltip>
              </Space>

              <Space direction="horizontal">
                <Typography.Text>
                  {numberOfProcessedAssetGroups}/{reviewItems.length} Asset
                  groups
                </Typography.Text>
                <Button
                  size="small"
                  className={
                    loadStatusFilter === "Loaded" ? styles.activeFilter : ""
                  }
                  disabled={numberOfLoaded === 0}
                  onClick={handleLoadStatusFilterChange("Loaded")}
                >
                  <CheckOutlined className={styles.loadedIcon} />
                  <Typography.Text>{numberOfLoaded} Loaded</Typography.Text>
                </Button>
                <Button
                  size="small"
                  className={
                    loadStatusFilter === "Failed" ? styles.activeFilter : ""
                  }
                  disabled={numberOfFailed === 0}
                  onClick={handleLoadStatusFilterChange("Failed")}
                >
                  <InfoCircleFilled className={styles.failedIcon} />
                  <Typography.Text>{numberOfFailed} Failed</Typography.Text>
                </Button>
              </Space>
            </Space>
          }
        >
          <Table<PmaxLoadItem>
            columns={columns}
            dataSource={filteredItems}
            pagination={false}
            expandable={{
              expandedRowRender: record => (
                <PmaxLoadStepExpandableTable items={record.assetGroups} />
              ),
              defaultExpandAllRows: true,
            }}
            size="small"
          />
        </Collapse.Panel>
      </Collapse>
    </Space>
  );
};
