import { useEditorContext } from "../../../Editor.context";
import { useAppSelector } from "shared/hooks/useAppSelector";
import Canvas from "screens/designStudio/editor/canvasContainer/Canvas";
import { DesignStudioTableTemplate } from "screens/designStudio/context/DesignStudioDataProvider";
import styles from "./Editor.module.scss";
import { useDesignStudioEditor } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";
import { getDimensionsObject } from "screens/designStudio/helpers";

type EditorProps = {
  item: DesignStudioTableTemplate;
};

export const Editor = ({ item }: EditorProps) => {
  const { editorQuery } = useDesignStudioEditor();
  const {
    canvasJson,
    fetchVariables,
    copyAction,
    onCanvasJsonUpdate,
    resetImageInsertData,
    setImageInsertData,
    layerAction,
    setCanvasAction,
    align,
    showGrid,
    redo,
    undo,
    objectUpdateAction,
    imageInsertData,
    resetAlign,
  } = useEditorContext();

  const { editor, assetId } = editorQuery;

  const { designStudio, configuration } = useAppSelector(state => state);

  const { feed, config } = configuration;
  const dimensions = getDimensionsObject(item)!;
  const isValid =
    (editor === "templates" || editor === "stamps") && assetId && dimensions;

  if (!isValid) return null;

  return (
    <div className={styles.canvasContainer}>
      <Canvas
        json={canvasJson}
        dimension={dimensions}
        onCanvasJsonUpdate={args => onCanvasJsonUpdate(args.json, args.replace)}
        imageInsertData={imageInsertData}
        resetImageInsertData={resetImageInsertData}
        fetchVariables={fetchVariables}
        feed={feed}
        config={config}
        copyAction={copyAction}
        layerAction={layerAction}
        updateImageInsertData={setImageInsertData}
        setCanvasAction={setCanvasAction}
        align={align || null}
        showGrid={!!showGrid}
        objectUpdateAction={objectUpdateAction}
        resetAlign={resetAlign}
        savingDraft={designStudio.savingDraft}
        saveDraft={() => {
          // TODO: Future ticket
        }}
        publishCanvasStatus={designStudio?.publishCanvasStatus}
        redo={redo}
        undo={undo}
      />
    </div>
  );
};
