import { Button, Divider, Input, message, Modal, Select } from "antd";

import { isStamp, IStamp } from "shared/types/designStudio";

import { context } from "../../../Editor.context";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import API from "services";
import useDeepEffect from "shared/hooks/useDeepEffect";
import "./ManageStamp.scss";
import { orderBy } from "lodash";
import { offerTypes, stateOptions } from "shared/constants/dataManagement";
import { useNavigate, useParams } from "react-router-dom";
import { FC, useContext, useEffect, useState } from "react";

const ManageStamp: FC = () => {
  const { targetTab, targetId } =
    useParams<{ targetTab: string; targetId: string }>();

  const [searchBy, setSearchBy] = useState<string>();

  const editorContext = useContext(context);
  const { resource } = editorContext || {};
  const navigate = useNavigate();

  const [selectedTags, setSelectedTags] = useState<string[]>();
  useEffect(() => {
    if (!resource || !isStamp(resource) || !targetTab || !targetId) return;

    const offerType = resource.offerType;
    const url = `design-studio/editor/${targetTab}/${targetId}/editor-v2`;
    navigate(`${url}?offerType=${offerType}`);

    setSelectedTags(resource.tags);
  }, [navigate, resource, targetId, targetTab]);

  const [updatingTags, setUpdatingTags] = useState<boolean>(false);
  useDeepEffect(() => {
    if (!selectedTags || !editorContext?.resource) return;
    if (isStamp(editorContext.resource)) {
      setUpdatingTags(true);
      const updatedStamp: IStamp = {
        ...editorContext.resource!,
        tags: selectedTags,
      };

      API.services.designStudio
        .updateStamp(updatedStamp)
        .then(({ result, error }) => {
          if (error?.message || !result) {
            if (!result) message.error("There was system error!");
            else message.error(error?.message || "There was system error!");

            return;
          }

          editorContext?.setResource({
            ...updatedStamp,
          });

          setUpdatingTags(false);
        })
        .catch(err => {
          message.error(`${err}`);
          setUpdatingTags(false);
        });
    }
  }, [selectedTags]);

  return (
    <div className="manage-artboard-container">
      {isStamp(resource) && (
        <>
          <div className="property-with-title name-property">
            <div className="title">Name</div>
            <Input disabled={true} value={resource.name} />
          </div>
          <div className="property-with-title asset-type-property">
            <div className="title">Offer Type</div>
            <Select
              value={resource.offerType || "-"}
              onChange={offerType => {
                editorContext?.setStampByOfferType(offerType);
              }}
            >
              {orderBy(offerTypes).map(offerType => (
                <Select.Option key={offerType} value={offerType}>
                  {offerType}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="property-with-title stamp-exception-property">
            <div className="title">Exceptions</div>
            <Select
              className="stamp-exception-select"
              value={editorContext?.selectedStampException.value}
              placeholder="Exceptions"
              optionLabelProp="label"
              onChange={state => {
                if (!resource) return;
                editorContext?.setStampException("state", state);
              }}
            >
              <Select.Option value={"default"} label="Default">
                Default
              </Select.Option>
              {stateOptions.map(state => {
                const isSaved =
                  resource?.exceptions?.some(
                    exp => exp.type === "state" && exp.value === state,
                  ) || false;
                return (
                  <Select.Option
                    key={`stamp-exception-select-option-${state}`}
                    value={state}
                    label={state}
                  >
                    <div className="stamp-exception-select-row">
                      <div className="title">{state}</div>
                      {isSaved && (
                        <div className={`check-mark ${isSaved ? "saved" : ""}`}>
                          <CheckOutlined style={{ color: "" }} />
                        </div>
                      )}
                    </div>
                  </Select.Option>
                );
              })}
            </Select>
            {resource.exceptions?.some(
              exp =>
                exp.value !== "default" &&
                exp.value === editorContext?.selectedStampException?.value,
            ) && (
              <div className="exception-revert-button-container">
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={e => {
                    e.preventDefault();

                    Modal.confirm({
                      title: "Do you want to revert the exception?",
                      content: (
                        <div>
                          This action will remove saved stamp exception for
                          {/* <span>{` ${props.stampException?.value}`}</span>. */}
                        </div>
                      ),
                      okText: "Revert",
                      okType: "primary",
                      okButtonProps: { danger: true },
                      onOk: async () => {
                        if (!isStamp(resource) || !editorContext) return;
                        const {
                          selectedStampException: { type, value },
                        } = editorContext;
                        const exception = {
                          type,
                          value,
                        };

                        API.services.designStudio
                          .revertStampException(resource, exception)
                          .then(() => {
                            const stamp = {
                              ...resource,
                              exceptions: resource.exceptions?.filter(
                                exp => exp.type === type && exp.value !== value,
                              ),
                            };
                            editorContext.setResource(stamp);
                            editorContext.resetCanvas(stamp.stampJsonUrl);
                          });
                      },
                    });
                  }}
                >
                  Revert
                </Button>
              </div>
            )}
          </div>

          <Divider />
          <div className="property-with-title size-property">
            <div className="title">Stamp Size</div>
            <div className="size-fields-container">
              <span>
                <Input disabled={true} value={resource.width} />
                <span>W</span>
              </span>
              <span>
                <Input disabled={true} value={resource.height} />
                <span>H</span>
              </span>
            </div>
          </div>

          <Divider />
          <div className="property-with-title tag-property">
            <div className="title">Tags</div>
            <div className="tag-input-container">
              <Select
                loading={updatingTags}
                mode="multiple"
                value={selectedTags}
                onSelect={(value: string) => {
                  const updatedTags = [...(selectedTags || []), value];
                  setSelectedTags(updatedTags);
                  setSearchBy(undefined);
                }}
                onDeselect={(value: string) => {
                  const updatedTags =
                    selectedTags?.filter(tag => tag !== value) || [];
                  setSelectedTags(updatedTags);
                  setSearchBy(undefined);
                }}
                onSearch={value => {
                  if (value) setSearchBy(value);
                  else setSearchBy(undefined);
                }}
                dropdownRender={() => {
                  return (
                    <div
                      className="add-tag-dropdown-container"
                      onMouseDown={e => {
                        e.preventDefault(); // ******** This part is needed to make the onClick method to work properly..
                      }}
                      onClick={() => {
                        if (!searchBy?.trim()) {
                          Modal.warning({
                            title:
                              "The tag name is not valid. Please enter valid tag name.",
                          });

                          return;
                        }

                        Modal.confirm({
                          title: "Do you want to add new tag?",
                          content: (
                            <span className="add-template-tag-container-span">
                              Are you sure you want to add new tag,
                              <span>{searchBy}</span>
                              for this template?
                            </span>
                          ),
                          okText: "Add",
                          onOk: async () => {
                            const updatedTags = [...resource.tags, searchBy];
                            setSelectedTags(updatedTags);
                          },
                        });
                      }}
                    >
                      <PlusOutlined /> Add Tag
                    </div>
                  );
                }}
              >
                {resource.tags.map(tag => {
                  return (
                    <Select.Option value={tag} key={`${tag}-key`}>
                      {tag}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ManageStamp;
