import { Input, Space, Typography, Form, Select } from "antd";
import { DesignStudioTableItem } from "screens/designStudio/context/DesignStudioDataProvider";
import {
  getAssetType,
  getArtboardName,
  getDimensionsObject,
  isArtboard,
  getTags,
} from "screens/designStudio/helpers";

type LayerDetailsProps = {
  item: DesignStudioTableItem;
};

export const LayerDetails = ({ item }: LayerDetailsProps) => {
  if (isArtboard(item)) return null;

  return (
    <Space direction="vertical">
      <Form.Item>
        <Typography.Text type="secondary">Asset Type</Typography.Text>
        <Input placeholder="Asset Type" disabled value={getAssetType(item)} />
      </Form.Item>
      <Form.Item>
        <Typography.Text type="secondary">Artboard Name</Typography.Text>
        <Input
          placeholder="Artboard Name"
          disabled
          value={getArtboardName(item)}
        />
      </Form.Item>
      <Form.Item>
        <Typography.Text type="secondary">Dimensions</Typography.Text>
        <Space>
          <Space>
            <Typography.Text>W</Typography.Text>
            <Input
              placeholder="Width"
              disabled
              value={getDimensionsObject(item)?.width}
            />
          </Space>
          <Space>
            <Typography.Text>H</Typography.Text>
            <Input
              placeholder="Height"
              disabled
              value={getDimensionsObject(item)?.height}
            />
          </Space>
        </Space>
      </Form.Item>
      <Form.Item>
        <Typography.Text type="secondary">Tags</Typography.Text>
        <Select value={getTags(item)} disabled />
      </Form.Item>
    </Space>
  );
};
