import { Rule } from "antd/lib/form";
import { UploadFile } from "antd/lib/upload/interface";
import { useMemo } from "react";
import { IFile } from "shared/types/uploadAsset";
import { useAsyncZodValidation, getErrorFromZod } from "utils/helpers.zod";
import { ZodType, ZodTypeDef } from "zod";

type UseValidateFilesArgs = {
  title: string;
  fileList: UploadFile<IFile>[];
  schema: ZodType<any, ZodTypeDef, any>;
};
export const useValidateFiles = ({
  title,
  fileList,
  schema,
}: UseValidateFilesArgs) => {
  const { data: validationData } = useAsyncZodValidation(fileList, schema, [
    title,
    JSON.stringify(fileList),
  ]);

  const validationError = useMemo(() => {
    if (validationData && !validationData.success) {
      return (
        getErrorFromZod(validationData.error, []) ||
        "Please hover the images to check the errors"
      );
    }
    return "";
  }, [validationData]);

  const computedFiles: UploadFile<IFile | string>[] = useMemo(() => {
    if (validationData && validationData.success) return fileList;

    return fileList.map((file, index) => {
      const fileHasError =
        getErrorFromZod(validationData?.error, [index]) || "";

      if (!fileHasError) return file;
      return {
        ...file,
        status: "error",
        response: fileHasError,
        xhr: file.response,
      };
    });
  }, [validationData, fileList]);

  const repeatedFiles = useMemo(() => {
    return computedFiles.filter((file, index) => {
      const indexFound = computedFiles.findIndex(f => f.uid !== file.uid);

      return indexFound !== -1 && indexFound !== index;
    });
  }, [computedFiles]);

  const formRules: Rule[] = useMemo(
    () => [
      {
        validator(rule, value, callback) {
          if (
            repeatedFiles.length === 0 &&
            (validationData?.success || !computedFiles.length)
          ) {
            callback();
          } else {
            callback(validationError);
          }
        },
      },
    ],
    [repeatedFiles, validationData, computedFiles, validationError],
  );
  return {
    computedFiles,
    validationData,
    validationError,
    formRules,
  };
};
