import { Spin, message } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import actions from "redux/rootActions";
import API from "services";
import { UndoMessage } from "shared/components/UndoMessage";
import { BROADCAST_CHANNEL_NAME } from "shared/constants/assetExporter";
import GenericError from "shared/errors/GenericError";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useBroadCastChannel } from "shared/hooks/useBroadcastChannel";
import { useRouteQuery } from "shared/hooks/useRouteQuery";
import { isGrid } from "shared/types/assetBuilder";
import { IConfigurationState } from "shared/types/configuration";
import {
  CanvasProcessing,
  IDimension,
  IPublishCanvasStatus,
  IStamp,
  IStampExceptionType,
  ITemplate,
  TemplateStatusType,
  getTemplateMediaType,
  isStamp,
  isTemplate,
} from "shared/types/designStudio";
import { countStampsFromObj } from "utils/helpers.fabric";
import { TResource, context } from "../Editor.context";
import styles from "./CanvasContainer.module.scss";
import Canvas from "./canvasContainer/Canvas";
import Toolbar from "./canvasContainer/Toolbar";
import { User } from "redux/auth/auth.slice";
interface Props {
  savingDraft: CanvasProcessing;
  feed: IConfigurationState["feed"];
  config: IConfigurationState["config"];
  user: User;
  publishCanvasStatus: IPublishCanvasStatus | null;
}

interface Handlers {
  updateSavingDraft: (
    status: "success" | "fail",
    resource: ITemplate | IStamp | null,
    error: GenericError | null,
  ) => void;
  publishCanvasStatusReset: () => void;
  updateShouldBlockFromNavigateAway: (
    block: boolean,
    resource?: TResource,
  ) => void;
}

type CanvasContainerProps = Props & Handlers;

export const preProcessCanvasJson = (json: any) => {
  if (!json || !json?.objects || !Array.isArray(json?.objects)) return json;

  const updatedObjects = json.objects.filter((obj: any) => !isGrid(obj));

  // Few old templates, the attr "type" of "backgroundImage" is set to "canvas_bg".
  // If this is the case, we must change the "type" to "image" otherwise, fabric throws an error.
  const hasWrongBgType = json?.backgroundImage === "canvas_bg";

  return {
    ...json,
    backgroundImage: hasWrongBgType
      ? { ...json.backgroundImage, type: "image" }
      : json.backgroundImage,
    objects: updatedObjects,
  };
};

const CanvasContainer = (props: CanvasContainerProps) => {
  const editorContext = useContext(context);
  const { resource } = editorContext || {};

  useEffect(() => {
    editorContext?.setUser(props.user);
  }, [editorContext, props.user]);

  let dimension: IDimension | undefined;
  let title: string | undefined;
  if (isTemplate(resource)) {
    dimension = {
      width: resource.artboard.width,
      height: resource.artboard.height,
    };

    title = `${resource.name}`;
  } else if (isStamp(resource)) {
    dimension = {
      width: resource.width,
      height: resource.height,
    };

    title = `${resource.name}`;
  }

  const [canvasJson] = useState<string>();

  const { user, onSaveComplete, updateStamp, selectedStampException } =
    editorContext || {};
  const { updateSavingDraft, publishCanvasStatusReset } = props;

  const isFromAdEngine = useRouteQuery("from") === "ad-engine";

  const getMsgContent = (
    templateName: string,
    fromAdEngine: boolean,
    isPublishing: boolean,
  ) => {
    const publishedMsg = "Template was successfully published.";

    const messageKey = "publishing-key";
    const publishedMsgForAdEngine = (
      <UndoMessage
        message={`"${templateName}" was successfully published.`}
        onClick={() => {
          window.opener.focus();
          window.close();
        }}
        onClose={() => {
          message.destroy(messageKey);
        }}
        alternativeText="Redirect me to Ad Engine"
      />
    );

    const saveDraftMsg = "Saving draft success.";

    if (fromAdEngine) return publishedMsgForAdEngine;
    if (isPublishing) return publishedMsg;
    return saveDraftMsg;
  };
  const postMessage = useBroadCastChannel(BROADCAST_CHANNEL_NAME);
  const saveTemplate = useCallback(
    async (resource: ITemplate, json: any, isPublishing: boolean) => {
      const status: TemplateStatusType = isPublishing
        ? "PUBLISHED"
        : "UN-PUBLISHED";

      const prejson = preProcessCanvasJson(json);
      const numOfStamps = countStampsFromObj(
        prejson.objects as fabric.Object[],
      );
      const body = {
        ...resource,
        mediaType: getTemplateMediaType(json),
        numOfStamps,
        status,
      };

      try {
        const res = await API.services.designStudio.saveDraft(
          body,
          prejson,
          user || undefined,
        );

        if (res.error) {
          message.error(res.error?.message);
          return;
        }

        if (isFromAdEngine) postMessage("template is updated");

        message.success({
          className: isFromAdEngine ? styles.publishedMessage : undefined,
          key: "publishing-key",
          content: getMsgContent(resource.name, isFromAdEngine, isPublishing),
        });
        updateSavingDraft("success", resource, null);
        onSaveComplete?.();
      } catch (error) {
        const errorMsg = "There was a system error!";
        const err = new GenericError({ message: errorMsg });
        updateSavingDraft("fail", null, err);
      }
    },
    [user, isFromAdEngine, updateSavingDraft, onSaveComplete, postMessage],
  );

  const saveStamp = useCallback(
    async (
      resource: IStamp,
      json: any,
      selectedStampException?: {
        type: "state";
        value: string;
      },
    ) => {
      if (!selectedStampException) return;

      let stampException:
        | {
            exceptionType: IStampExceptionType;
            exceptionValue: string;
          }
        | undefined;
      if (selectedStampException.value !== "default") {
        stampException = {
          exceptionType: selectedStampException.type,
          exceptionValue: selectedStampException.value,
        };
      }
      API.services.designStudio
        .saveStamp(
          resource,
          preProcessCanvasJson(json),
          user || undefined,
          stampException,
        )
        .then(res => {
          const { result, error } = res;
          if (error || !result) {
            message.error(error?.message || "There was an error.");
            return;
          }

          const { stamp } = result;
          updateStamp?.(stamp, true);
          publishCanvasStatusReset();
          onSaveComplete?.();
        })
        .catch(() => {
          message.error("Unable to publish stamp.");
          publishCanvasStatusReset();
        });
    },
    [user, updateStamp, onSaveComplete, publishCanvasStatusReset],
  );

  const saveDraft = useCallback(
    async (resource: TResource | undefined, json, isPublishing) => {
      if (isTemplate(resource)) saveTemplate(resource, json, isPublishing);
      if (isStamp(resource)) saveStamp(resource, json, selectedStampException);
    },
    [saveTemplate, saveStamp, selectedStampException],
  );

  useEffect(() => {
    props.updateShouldBlockFromNavigateAway(
      editorContext?.shouldBlockFromNavigateAway || false,
      editorContext?.resource,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContext?.shouldBlockFromNavigateAway]);

  const dispatch = useAppDispatch();
  const setDisabledPublishButton = (disabled: boolean) =>
    dispatch(actions.designStudio.setDisabledPublishButton(false));

  return (
    <div className={styles.CanvasContainer}>
      <div className={styles.Header}>
        <div className={styles.Title}>{title}</div>
      </div>

      <div className={styles.Wrapper}>
        <div className={styles.ToolbarWrapper}>
          <Toolbar config={props.config} canvasJson={canvasJson} />

          <div className={styles.SizeLabel}>
            {`${dimension?.width} x ${dimension?.height}`}
          </div>
        </div>
        <div className={styles.CanvasWrapper} style={{ overflowX: "auto" }}>
          {!dimension && <div className={styles.CanvasPlaceholder} />}

          {dimension && (
            <Spin
              wrapperClassName="canvas-spinner-wrapper"
              size="large"
              spinning={editorContext?.loading}
              tip="Loading..."
            >
              <Canvas
                json={editorContext?.canvasJson}
                dimension={dimension}
                onCanvasJsonUpdate={args => {
                  // add to history
                  editorContext?.onCanvasJsonUpdate(args.json, args.replace);
                  setDisabledPublishButton(false);
                }}
                imageInsertData={editorContext?.imageInsertData}
                resetImageInsertData={() => {
                  editorContext?.resetImageInsertData(); // this will set the state to null
                }}
                fetchVariables={() => {
                  editorContext?.fetchVariables();
                }}
                feed={props.feed}
                config={props.config}
                copyAction={editorContext?.copyAction}
                layerAction={editorContext?.layerAction}
                updateImageInsertData={imageInsertData => {
                  editorContext?.setImageInsertData(imageInsertData);
                }}
                setCanvasAction={canvasAction => {
                  editorContext?.setCanvasAction(canvasAction);
                }}
                align={editorContext?.align || null}
                showGrid={!!editorContext?.showGrid}
                objectUpdateAction={editorContext?.objectUpdateAction}
                resetAlign={() => {
                  editorContext?.resetAlign();
                }}
                savingDraft={props.savingDraft}
                saveDraft={(json, isPublishing) =>
                  saveDraft(resource, json, isPublishing)
                }
                publishCanvasStatus={props.publishCanvasStatus}
                redo={() => editorContext?.redo()}
                undo={() => editorContext?.undo()}
              />
            </Spin>
          )}
        </div>
      </div>
    </div>
  );
};

export default CanvasContainer;
