import { Space, Button } from "antd";
import { PmaxCreateAssetGroupFooterProps } from "../PmaxLoadDrawer";
import styles from "./pmaxLoadStep/PmaxLoadStep.module.scss";
import { useProgressNotification } from "./hooks/useProgressNotification";
import { getNumberByStatus } from "./pmaxLoadStep/utils";

type LoadStepFooterProps = Pick<
  PmaxCreateAssetGroupFooterProps,
  "onClose" | "reviewItems" | "uploadResults"
>;

export const LoadStepFooter = ({
  onClose,
  uploadResults,
  reviewItems,
}: LoadStepFooterProps) => {
  const numberOfFailed = getNumberByStatus(uploadResults, "Failed");
  const numberOfLoaded = getNumberByStatus(uploadResults, "Loaded");
  const numberOfProcessedAssetGroups = numberOfFailed + numberOfLoaded;

  const { closeNotification } = useProgressNotification(
    uploadResults,
    numberOfProcessedAssetGroups,
    reviewItems.length,
  );

  const closeNotificationAndCloseDrawer = () => {
    closeNotification();
    onClose();
  };

  const isAllLoaded = uploadResults.progress === 100;
  const buttonText = isAllLoaded ? "Done" : "Loading...";
  return (
    <Space direction="horizontal" className={styles.loadDrawerActionsContainer}>
      <Space direction="horizontal" align="start">
        <Button onClick={closeNotificationAndCloseDrawer}>Cancel</Button>
      </Space>
      <Space align="end">
        <Button
          disabled={!isAllLoaded}
          onClick={closeNotificationAndCloseDrawer}
          type="primary"
        >
          {buttonText}
        </Button>
      </Space>
    </Space>
  );
};
