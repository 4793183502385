import { IAd } from "shared/types/adLibrary";
import { CampaignAdStatus } from "shared/types/campaignPlanner";
import { pluralize } from "utils/terms";

export const Messages = {
  addInstanceAds: (length: number) =>
    `${length} ${pluralize("ad", length)} ${
      length > 1 ? "have" : "has"
    } been added to this planner instance`,
  removeInstanceAds: (length: number) =>
    `${length} ${pluralize("ad", length)} removed from this planner instance`,
  removePlannerAds: (length: number) =>
    `${length} ${pluralize("ad", length)} ${
      length > 1 ? "were" : "was"
    } removed from this campaign planner and all its planner instances.`,
  addPlannerAds: (length: number) =>
    `${length} ${pluralize("ad", length)} ${
      length > 1 ? "have" : "has"
    } been added to this campaign planner and all its planner instances.`,
  adEditSuccessHeader: () =>
    `The ad was saved in Ad Library and all planners and instances its part of:`,
  adEditSuccessBody: (ad: IAd) => (
    <>
      <ul>
        {ad.planners?.map(planner => (
          <li key={planner.id}>{planner.name}</li>
        ))}
        {ad.instances?.map(instance => (
          <li key={instance.id}>{instance.name}</li>
        ))}
      </ul>
    </>
  ),
  instanceAdCopy: `The ad was saved in ad library`,
  saveAsNewAd: (originalAd: IAd, newAd: IAd) => (
    <>
      {`"${originalAd.inputParameters.name}" has been set as 
      ${
        originalAd.campaignStatus == CampaignAdStatus.LOADED
          ? "Pause requested"
          : "Do not load"
      } 
      and`}
      <a href={`/ad-library?id=${newAd.id}`}>
        {`"${newAd.inputParameters.name}"`}
      </a>
      has been created.
    </>
  ),
};
