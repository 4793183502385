import { Field } from "shared/components/dataListURL/types";
import { DelimitedArrayParam, StringParam } from "use-query-params";
import {
  checkArrayIncludes,
  checkFilterMatch,
  compareString,
  titleCase,
} from "utils/helpers";
import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { CampaignPlanner } from "../../types";

export type CampaignPlannerFields = Field<CampaignPlanner>;

type Fields = Partial<Record<keyof CampaignPlanner, CampaignPlannerFields>>;

export type CampaignPlannerFieldKeys = keyof Fields;

export const fields: Fields = {
  name: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), record.name),
    sorterFn: (a, b) => compareString(a.name, b.name),
    queryParamConfigFilter: StringParam,
  },
  updatedAt: {
    sorterFn: (a, b) => a.updatedAt?.localeCompare(b.updatedAt),
  },
  updatedBy: {
    filterFn: (value, record) =>
      checkFilterMatch(value.toString(), record.updatedBy),
    sorterFn: (a, b) =>
      compareString(a.updatedBy?.toString(), b.updatedBy?.toString()),
    queryParamConfigFilter: StringParam,
  },
  createdAt: {
    sorterFn: (a, b) =>
      a.createdAt?.toString().localeCompare(b?.createdAt.toString()),
    defaultSortOrder: "descend",
  },
  createdBy: {
    sorterFn: (a, b) => compareString(a.createdBy, b.createdBy),
    filterFn: (value, record) =>
      checkFilterMatch(value?.toString(), record.createdBy?.toString()),
    queryParamConfigFilter: StringParam,
  },
  adShells: {
    sorterFn: (a, b) => a.adShells.length - b.adShells.length,
  },
  instances: {
    sorterFn: (a, b) => a.adShells.length - b.adShells.length,
  },
  condition: {
    filterFn: (value, record) => checkArrayIncludes(value, record.condition),
    sorterFn: (a, b) => compareString(a.condition, b.condition),
    queryParamConfigFilter: DelimitedArrayParam,
    display: titleCase,
  },
};

export const campaignPlannerFields = Object.keys(fields) as Array<
  keyof typeof fields
>;

export type CampaignPlannerTableColumn = ITableColumnURL<
  CampaignPlannerFieldKeys,
  CampaignPlanner
>;
