import { Loading } from "../../Loading";
import { Resource } from "./Resource";

import styles from "./Grid.module.scss";

type Props = {
  isFetching: boolean;
  data?: Array<WDAsset | WDFolder>;
};

export const Grid = ({ data, isFetching }: Props) => {
  return (
    <div className={styles.host}>
      <div data-loading={isFetching}>
        {data?.map(resource => (
          <Resource key={resource.id} resource={resource} />
        ))}
      </div>
      <div>{isFetching && <Loading />}</div>
    </div>
  );
};
