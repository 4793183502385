import { getTags } from "../../../helpers";
import { TooltipTag } from "../../shared/TooltipTag";
import { BaseDesignStudioItemCardProps } from "../DesignStudioItemCard";

export const CardTags = ({ item }: BaseDesignStudioItemCardProps) => {
  const tags = getTags(item);
  const [first, second, ...rest] = tags;

  if (!tags.length) return null;
  if (tags.length > 1)
    return (
      <div>
        <TooltipTag title={first}>{first}</TooltipTag>
        <TooltipTag title={first}>{second}</TooltipTag>
        {rest.length > 0 && (
          <TooltipTag title={rest.join(", ")}>+{rest.length}</TooltipTag>
        )}
      </div>
    );

  return (
    <div>
      <TooltipTag title={first}>{first}</TooltipTag>
    </div>
  );
};
