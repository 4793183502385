import { useColumn } from "../useColumn";

export const useColSize = () => {
  return useColumn("filesize", {
    title: "Size",
    renderFn(text, { type }) {
      return <span>{type === "asset" ? `${text} bytes` : null}</span>;
    },
  });
};
