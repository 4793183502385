import { differenceBy } from "lodash";
import { useQueryClient } from "react-query";
import { IGetAdsResponseData, IAd } from "shared/types/adLibrary";

type SetAdsQueryDataArgs = {
  ads?: IAd[];
  adId?: string;
  operation: "mutate" | "delete";
};

const useSetAdsQueryData = () => {
  const queryClient = useQueryClient();
  const adsRes: IGetAdsResponseData | undefined =
    queryClient.getQueryData("ads");

  return async ({ ads, adId, operation }: SetAdsQueryDataArgs) => {
    const willDelete = !!adId && operation === "delete";

    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries("ads");

    // Optimistically update the ad
    queryClient.setQueryData<IAd[] | undefined>("ads", prevAds => {
      if (!prevAds) return;

      const newAds = differenceBy(ads, prevAds, "id");
      const newData = !willDelete
        ? prevAds?.map(
            originalAd =>
              ads?.find(ad => ad.id === originalAd.id) ?? originalAd,
          )
        : prevAds.filter(ad => ad.id !== adId);

      newData.concat(newAds);
      return newData;
    });

    return adsRes;
  };
};

export default useSetAdsQueryData;
