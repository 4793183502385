import { Button, Col, Form, Select, TimePicker } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useAdEngineActions } from "redux/assetExporter/assetExporter.slice";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  HourlyRepeatOptions,
  IFrequency,
  ITimeZone,
  WeeklyRepeatOptions,
} from "shared/types/assetExporter";

export const SchedulingStep = () => {
  const { drawerMode, frequency, repeat, startTime, timezone, googleSheetId } =
    useAppSelector(state => state.assetExporter.sourceFeed.feedDrawer);
  const {
    enableNextButton,
    setStartTime,
    setRepeat,
    setFrequency,
    setTimeZone,
  } = useAdEngineActions();
  const [time, setTime] = useState<moment.Moment | null>(null);

  const enableNextBtn = useCallback(() => {
    if (drawerMode === "UPDATE") {
      enableNextButton();
    }
  }, [enableNextButton, drawerMode]);

  const repeatOptions =
    frequency === "Hourly" ? HourlyRepeatOptions : WeeklyRepeatOptions;
  const defaultRepeat = repeat ?? repeatOptions[0];

  const onTimeChange = (newTime: moment.Moment | null) => {
    setStartTime(newTime?.toISOString());
  };

  const onFrequencyChange = (freq: IFrequency) => {
    setFrequency(freq);
    if (freq === IFrequency.Daily) {
      setRepeat(undefined);
      return;
    }
    const defaultOptions =
      freq === IFrequency.Hourly ? HourlyRepeatOptions : WeeklyRepeatOptions;

    setRepeat(defaultOptions[0]);
  };

  const setMomentStartTime = useCallback(() => {
    if (startTime && startTime !== "--") {
      setTime(moment(startTime));
      return;
    }
    setTime(moment("00:00:00", "hh:mm:ss"));
  }, [startTime]);

  useEffect(enableNextBtn, [enableNextBtn]);
  useEffect(setMomentStartTime, [setMomentStartTime]);

  const inputStyle = { width: 230 };

  return (
    <Form layout="vertical" requiredMark={false}>
      <Form.Item label={<b>Updates</b>}>
        {Object.values(IFrequency).map(value => {
          if (!googleSheetId && value === "Never") return null;
          return (
            <Button
              key={value}
              type={value === frequency ? "primary" : "default"}
              onClick={() => onFrequencyChange(value)}
            >
              {value}
            </Button>
          );
        })}
      </Form.Item>
      {frequency !== "Never" && (
        <>
          {frequency !== "Daily" && (
            <Col span={12}>
              <Form.Item label="Repeat">
                <Select
                  value={defaultRepeat}
                  onChange={setRepeat}
                  style={inputStyle}
                >
                  {Object.values(repeatOptions).map((day: string) => {
                    return (
                      <Select.Option key={day} value={day}>
                        {day}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={6}>
            <Form.Item label="Start Time">
              <TimePicker
                value={time}
                use12Hours
                format="h:mm a"
                onChange={onTimeChange}
                style={inputStyle}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Time Zone">
              <Select
                value={timezone}
                onChange={setTimeZone}
                style={inputStyle}
              >
                {Object.values(ITimeZone).map(timezone => {
                  return (
                    <Select.Option key={timezone} value={timezone}>
                      {timezone}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </>
      )}
    </Form>
  );
};
