import { Navigate, Route, Routes } from "react-router-dom";
import { CAMCallback, CAMGuard } from "shared/components/media";
import * as helpers from "../utils/helpers";
import AdLibraryContainer from "./AdLibrary";
import { Lobby } from "./app/Lobby";
import AssetBuilder from "./AssetBuilder";
import AssetExport from "./AssetExport";
import AssetExporter from "./AssetExporter";
import DashboardManagement from "./DashboardManagement";
import DealerManagement from "./DealerManagement";
import DesignStudio from "./DesignStudio";
import InstantExperience from "./designStudio/editor/InstantExperience";
import EverythingAds from "./EverythingAds";
import Ads from "./everythingAds/Ads";
import HelpPages from "./helpPages/HelpPages";
import LegalLingo from "./LegalLingo";
import LegalMessaging from "./legalLingo/LegalMessaging";
import LegalVariables from "./legalLingo/LegalVariables";
import LegalLingoV2 from "./LegalLingoV2";
import Media from "./Media";
import Metrics from "./Metrics";
import { NavigateTest } from "./NavigateTest";
import OemManagement from "./OemManagement";
import PlatformManagement from "./PlatformManagement";
import { ProtectedRoute } from "./ProtectedRoute";
import AdReview from "./campaignManagement/AdReview";
import AudienceManager from "./campaignManagement/AudienceManager";
import PublicAdReview from "./campaignManagement/PublicAdReview";
import CampaignPlannerList from "./campaignManagement/campaignPlanner/CampaignPlannerList";
import { CampaignPlannerInstanceDetail } from "./campaignManagement/campaignPlanner/CampaignPlannerInstanceDetail";
import CampaignPlanner from "./campaignManagement/CampaignPlanner";
import { GooglePerformanceMax } from "./campaignManagement/GooglePerformanceMax";
import { CampaignPlannerDetail } from "./campaignManagement/campaignPlanner/CampaignPlannerDetail";
import { CampaignManagementProvider } from "./CampaignManagement";
import AiImageGen from "./adLibrary/genAI/AiImageGen";
import BrandsAccountsManagement from "./BrandsAccountsManagement";
import Resize from "./adLibrary/genAI/resize/Resize";
import LobbyWithoutAuthentication from "./app/Layout";
import { MetricsShare } from "./MetricsShare";

export const App = () => {
  const isNu = helpers.isEnvVarEquals("CLIENT", "nu");
  const isAuto = helpers.industryType("auto");
  const index = isNu ? "/ad-library" : "/asset-builder/orders";
  const dealer = isAuto ? "store" : "account";
  const oem = isAuto ? "oem" : "brand";

  return (
    <Routes>
      <Route
        path="/"
        element={
          window.Cypress ? <Lobby /> : <ProtectedRoute component={Lobby} />
        }
      >
        <Route element={<CAMGuard />}>
          <Route
            index
            key="root-route"
            element={<Navigate to={index} replace />}
          />
          <Route
            path={`${dealer}-management/*`}
            element={<DealerManagement />}
          />
          <Route path={`${oem}-management/*`} element={<OemManagement />} />
          <Route
            path="dashboard-management/*"
            element={<DashboardManagement />}
          />
          <Route
            path="brands-accounts-management"
            element={<BrandsAccountsManagement />}
          />
          <Route
            path="platform-management/*"
            element={<PlatformManagement />}
          />
          <Route path="legal-lingo">
            <Route path="variables" element={<LegalVariables />} />
            <Route path="disclosures" element={<LegalLingo />} />
            <Route path="messaging" element={<LegalMessaging />} />
          </Route>
          <Route path="legal-lingo-v2/*" element={<LegalLingoV2 />} />
          <Route path="media/*" element={<Media />} />

          <Route path="asset-builder/*" element={<AssetBuilder />} />
          <Route path="ad-engine/*" element={<AssetExporter />} />
          <Route path="asset-export/*" element={<AssetExport />} />
          <Route path="design-studio/*" element={<DesignStudio />} />
          <Route path="ad-library/*" element={<AdLibraryContainer />} />
          <Route path="everything-ads">
            <Route path="ads" element={<Ads />} />
            <Route path="mapper" element={<EverythingAds />} />
            <Route path="ads/create" element={<InstantExperience />} />
            <Route
              path="ads/:instantExperienceId/edit"
              element={<InstantExperience />}
            />
          </Route>
          <Route
            path={"campaign-management"}
            element={<CampaignManagementProvider />}
          >
            <Route path={"ad-review"} element={<AdReview />} />
            <Route path={"audience-manager"} element={<AudienceManager />} />
            <Route path={"campaign-planner"} element={<CampaignPlanner />}>
              <Route index element={<CampaignPlannerList />} />
              <Route
                path=":plannerId/instance/:instanceId"
                element={<CampaignPlannerInstanceDetail />}
              />
              <Route path=":plannerId" element={<CampaignPlannerDetail />} />
            </Route>
            <Route
              path={"google-performance-max"}
              element={<GooglePerformanceMax />}
            />
          </Route>
          <Route path="metrics/*" element={<Metrics />} />
          <Route path="navtest/*" element={<NavigateTest />} />
          <Route path="ai-image-tools" element={<AiImageGen />} />
          <Route path="ai-image-tools/resize" element={<Resize />} />
        </Route>
        <Route path="media-callback" element={<CAMCallback />} />
      </Route>
      {/* TODO: figure out what happend with metrics share */}
      {/* <Route path="metrics/share/*" element={<MetricsShare />} /> */}

      {/* PUBLIC ROUTES */}
      <Route path="/" element={<LobbyWithoutAuthentication />}>
        <Route
          path={"campaign-management"}
          element={<CampaignManagementProvider />}
        >
          <Route path={"ad-review/shared"} element={<PublicAdReview />} />
        </Route>
        <Route path="metrics" element={<Metrics />}>
          <Route path="share/:id" element={<MetricsShare />} />
        </Route>
      </Route>
      <Route key="help-route" path="help" element={<HelpPages />} />
    </Routes>
  );
};
