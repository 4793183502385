import { SelectValue } from "antd/lib/select";
import {
  ROWS_PER_PAGE,
  MATCHED_COLOR,
  WARNING_COLOR,
  feedTypes,
  UNFORMATTED_TEXT,
  REQUIRED_COLS_ERROR_TEXT,
  DUPLICATE_ERROR_TEXT,
  NO_IMPORT_TEXT,
  FB_ADS_CTA_WARNING_TEXT,
} from "shared/constants/assetExporter";
import {
  AssetExporterState,
  FeedColData,
  FeedPrevData,
  FeedType,
  FormattedPreviewCols,
  RecColumn,
  SmartCol,
} from "shared/types/assetExporter";

export const getRecommendedColumns = (type: SelectValue) => {
  const feedData = feedTypes.find(feedType => feedType.type === type);
  return feedData?.recommendedColumns ?? [];
};

export const getMatchingColsHeaders = (
  feedHeaders: string[],
  recHeaders: RecColumn[],
  feedType: FeedType,
): FeedColData[] => {
  return feedHeaders.map(feedHeader => {
    const lcHeader = feedHeader.toLowerCase();
    const colMatch = recHeaders.find(recHeader => {
      if (!lcHeader || !lcHeader.trim()) return false;
      return recHeader.toMatch.some(match => match.includes(lcHeader));
    });
    const isCustomType = ["custom", "pmax"].includes(feedType);
    const isMatched = isCustomType || !!colMatch;
    return {
      matchedTo: isCustomType ? UNFORMATTED_TEXT : colMatch?.name,
      isMatched,
      matchedFrom: feedHeader,
    };
  });
};

export const checkIsAllRequiredMatched = (
  cols: RecColumn[],
  colData: FeedColData[],
) => {
  return cols
    .filter(col => col.required)
    .every(col => colData.find(c => c.matchedTo === col.name));
};

export const checkHasDuplicateCols = (colData: FeedColData[]) => {
  const headers = colData
    .map(col => col.matchedTo)
    .filter(Boolean)
    .filter(col => col !== UNFORMATTED_TEXT)
    .filter(col => col !== NO_IMPORT_TEXT);
  return headers.length !== new Set(headers).size;
};

export const getErrorMsg = (
  isAllReqMatched: boolean,
  hasDuplicateCols: boolean,
) => {
  if (hasDuplicateCols) return DUPLICATE_ERROR_TEXT;
  if (!isAllReqMatched) return REQUIRED_COLS_ERROR_TEXT;
  return "";
};

export const getColVal = (feedData: FeedColData[], currCol: FeedPrevData) => {
  const col = feedData.find(
    data => data.matchedFrom === currCol.columnFoundInSheet,
  );
  return !!col?.matchedFrom && col.isMatched ? col.matchedTo : undefined;
};

export const isColDisabled = (columnData: FeedColData[], name: string) => {
  return !!columnData.find(data => data.matchedTo === name);
};

export const getBadgeHexColor = (
  columnData: FeedColData[],
  currCol: FeedPrevData,
) => {
  const col = columnData.find(
    data => data.matchedFrom === currCol.columnFoundInSheet,
  );
  if (col?.isMatched) return MATCHED_COLOR;
  return WARNING_COLOR;
};

/**
 * In this context, sorted data is in the form:
 * [warningHeader, ..., warningHeader, matchedHeader, ..., matchedHeader]
 */
export const getSortedColHeaders = (columnData: FeedColData[]) => {
  const warningData = columnData.filter(data => !data.isMatched);
  const matchedData = columnData.filter(data => data.isMatched);
  const warningHeaders = warningData.map(data => data.matchedFrom);
  const matchedHeaders = matchedData.map(data => data.matchedFrom);
  return [...warningHeaders, ...matchedHeaders];
};

const getChunkData = (rows: FeedPrevData[], cols: FeedColData[]) => {
  return rows.map(row => {
    return Object.keys(row).reduce((prev, curr) => {
      const colData = cols.find(col => col.matchedFrom === curr)!;
      if (!colData) return { ...prev, [curr]: row[curr] };
      const isImported = colData.matchedTo !== NO_IMPORT_TEXT;
      if (!isImported) return { ...prev };
      const key =
        colData.matchedTo === UNFORMATTED_TEXT
          ? colData.matchedFrom
          : colData.matchedTo!;
      return {
        ...prev,
        [key]: row[colData.matchedFrom],
      };
    }, {});
  });
};
export const getProcessChunks = (rows: FeedPrevData[], cols: FeedColData[]) => {
  const chunks: FeedPrevData[][] = [];
  for (let i = 0; i < rows.length; i += ROWS_PER_PAGE) {
    chunks.push(getChunkData(rows.slice(i, i + ROWS_PER_PAGE), cols));
  }
  return chunks;
};

const checkIsSmartColumn = (colHeader: string, smartCols: SmartCol[]) => {
  return smartCols.map(col => col.column).includes(colHeader);
};
export const mergeColsWithSmartCols = (
  columnHeaders: string[] | undefined,
  smartCols: SmartCol[],
): FormattedPreviewCols => {
  if (!columnHeaders || columnHeaders.length === 0) return [];
  return columnHeaders.map(colHeader => ({
    colHeader,
    isSmartCol: checkIsSmartColumn(colHeader, smartCols),
  }));
};

const checkIsCTAError = (value?: string) => {
  if (!value) return true;
  const recFormat = feedTypes.find(t => t.type === "facebookAds")!;
  const recCol = recFormat.recommendedColumns.find(col => col.name === "CTA")!;
  const recMatches = recCol.requiredMatching;
  return !recMatches.some(name => name === value);
};
const checkIsAdFormatrror = (value?: string) => {
  if (!value) return true;
  const recFormat = feedTypes.find(t => t.type === "facebookAds")!;
  const recCol = recFormat.recommendedColumns.find(
    col => col.name === "Ad Format",
  )!;
  const recMatches = recCol.requiredMatching;
  return !recMatches.some(name => name === value);
};
export const isFbAdsError = (colHeader: string, value?: string) => {
  const isCTAHeader = colHeader === "CTA";
  const isAdFormat = colHeader === "Ad Format";
  if (isCTAHeader) return checkIsCTAError(value);
  else if (isAdFormat) return checkIsAdFormatrror(value);
  return false;
};

export const hasValidColumns = (
  colData: FeedColData[],
  feedType?: FeedType,
) => {
  if (feedType === "facebookAds") {
    const recFormat = feedTypes.find(t => t.type === "facebookAds")!;
    const requiredCol = recFormat.recommendedColumns
      .filter(col => col.required)
      .map(col => col.name);
    const colHeaders = colData.map(col => col.matchedTo);
    return requiredCol.every(col => colHeaders.includes(col));
  }
  return true;
};
export const getWarningCols = (
  colHeaders: string[],
  selectedType?: FeedType,
) => {
  if (!selectedType) return [];
  if (selectedType !== "facebookAds") return [];
  const hasCTAHeader = colHeaders.includes("CTA");
  if (!hasCTAHeader) return [FB_ADS_CTA_WARNING_TEXT];
  return [];
};

export const getErrorMsgCount = (
  isEmptySmartColWarning: boolean,
  reviewGeneralWarnings: string[],
  reviewGeneralErrors: AssetExporterState["sourceFeed"]["feedDrawer"]["reviewGeneralErrors"],
) => {
  const warningCount = !!isEmptySmartColWarning ? 1 : 0;
  const errorMsgsCount =
    Object.values(reviewGeneralErrors).filter(Boolean).length;
  const generalWarningCount = reviewGeneralWarnings.length;
  return errorMsgsCount + warningCount + generalWarningCount;
};
