import { Button, Input } from "antd";
import { useAssetBatchesContext } from "screens/assetExporter/feedConfigurationV2/shared/contexts/AssetBatchesContext";
import { TComposition } from "shared/types/assetExporter";
import styles from "./VariableCollapseHeader.module.scss";
import { DeleteOutlined, EditOutlined, SyncOutlined } from "@ant-design/icons";

export type VariableCollapseHeaderProps = {
  composition: TComposition;
};

export const VariableCollapseHeader = ({
  composition,
}: VariableCollapseHeaderProps) => {
  const {
    templatesToUse: templates,
    replaceComposition,
    removeComposition,
  } = useAssetBatchesContext();
  const template = templates.find(
    compTemplate => compTemplate.id === composition.template,
  );

  return (
    <div className={styles.headerContainer}>
      <div>{template?.name}</div>
      <div className={styles.buttonContainer}>
        <Input
          size="small"
          suffix={<div style={{ color: "rgba(0,0,0,.45)" }}>s</div>}
          className={styles.mediaDurationInput}
          onClick={event => {
            event.stopPropagation();
          }}
        />
        <Button
          type="text"
          size="small"
          icon={
            <SyncOutlined onClick={() => replaceComposition(composition)} />
          }
        />
        <Button type="text" size="small" icon={<EditOutlined />} />
        <Button
          type="text"
          size="small"
          icon={
            <DeleteOutlined onClick={() => removeComposition(composition)} />
          }
        />
      </div>
    </div>
  );
};
