import { useEffect } from "react";
import { Spin } from "antd";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";

import "@blueprintjs/core/lib/css/blueprint.css";

import { usePolotnoStore } from "./polotnoEditor/usePolotnoStore";
import { DesignStudioTableTemplate } from "screens/designStudio/context/DesignStudioDataProvider";
import { useFetchCanvasJson } from "../../hooks/useFetchCanvasJson";

type PolotnoEditorProps = {
  item: DesignStudioTableTemplate;
};
export const PolotnoEditor = ({ item }: PolotnoEditorProps) => {
  const { store } = usePolotnoStore();
  const { data, isFetching } = useFetchCanvasJson(item.asset.canvasJsonUrl);

  useEffect(() => {
    if (data) store.loadJSON(data);
    return () => store.clear();
  }, [data, store]);

  if (isFetching) return <Spin />;
  return (
    <PolotnoContainer style={{ width: "100%", height: "100%" }}>
      <SidePanelWrap>
        <SidePanel store={store} />
      </SidePanelWrap>
      <WorkspaceWrap>
        <Toolbar store={store} downloadButtonEnabled />
        <Workspace store={store} />
        <ZoomButtons store={store} />
      </WorkspaceWrap>
    </PolotnoContainer>
  );
};
