import { useQuery } from "react-query";
import API from "services";
import { IBrand, IGetBrandsByNameResponse } from "shared/types/brandsAccounts";
import { getErrorMessage } from "utils/errorMessage";

export const getBrandsByName = async (names: string[]) => {
  try {
    const res =
      await API.services.oemManagement.getOemsByName<IGetBrandsByNameResponse>(
        names,
      );

    const { result, error } = res;

    if (!result) throw new Error("Unable to get brands");
    if (!!error) throw new Error(error.message);

    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

export const useFetchBrandsByName = (names?: string[]) => {
  return useQuery<IBrand[] | undefined, Error>(
    ["brandsByName", names],
    () => getBrandsByName(names ?? []),
    {
      staleTime: Infinity,
      enabled: !!names,
    },
  );
};
