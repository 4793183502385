import { message } from "antd";
import { useMemo } from "react";
import {
  useGetAssetsMetaQuery,
  useUpdateAssetMetaMutation,
} from "redux/media/media.api";
import { useMediaActions } from "redux/media/media.slice";
import { MIXED_VALUE } from "../constants";
import { handleApiError } from "../handleApiError";
import { useFetchPerms } from "../useFetchPerms";
import { useMetaSchema } from "../useMetaSchema";
import { useSelected } from "../useSelected";
import { getConfig } from "./utils";

type InitialValues = Record<string, string>;

export const useForm = (assets: WDAsset[]) => {
  const { setAssetsToEdit } = useMediaActions();
  const { clearSelected } = useSelected();
  const [updateMeta, { isLoading }] = useUpdateAssetMetaMutation();
  const { data: meta } = useGetAssetsMetaQuery(assets.map(i => i.id));
  const { schema } = useMetaSchema();
  const folderIds = assets.map(asset => asset.folder.id);
  const { canEdit } = useFetchPerms(folderIds, !!folderIds.length);
  const initialValues = useMemo(
    () => getInitialValues(meta, schema),
    [meta, schema],
  );

  const onClose = () => {
    setAssetsToEdit([]);
  };

  const onSubmit = async (formValues: MGFormValues) => {
    const payload = getPayload(meta!, formValues, initialValues!);

    try {
      await updateMeta({ payload }).unwrap();
      clearSelected();
      onClose();
      message.success(`${payload.length} assets updated.`);
    } catch (err) {
      handleApiError(err);
    }
  };

  return {
    initialValues,
    isLoading,
    onSubmit,
    onClose,
    disabled: !canEdit,
    schema,
  };
};

const getUpdatedValues = (
  id: string,
  meta: WDMetaDataXMPRes,
  initialValues: InitialValues,
  formValues: MGFormValues,
) => {
  return Object.entries(formValues).reduce((acc, [key, val]) => {
    // Only updated fiels.
    if (val !== initialValues[key]) {
      // Array fields require special treatment.
      if (Array.isArray(val)) {
        const value = val.filter(v => v !== MIXED_VALUE);
        const original = getArrValue(meta[id][key]);
        const initial = getArrValue(initialValues[key]);
        const add = value.filter(v => !original.includes(v));
        const remove = initial.filter(v => !value.includes(v));
        const newValue = original.filter(v => !remove.includes(v)).concat(add);

        acc[key] = newValue;
      } else {
        // Use empty string to clear the value.
        acc[key] = val === undefined ? "" : val;
      }
    }

    return acc;
  }, {} as MGFormValues);
};

const getArrValue = (value = ""): string[] => {
  return (
    value
      ?.split(",")
      .map(v => v.trim())
      .filter(v => v.length) || []
  );
};

const getPayload = (
  meta: WDMetaDataXMPRes,
  formValues: MGFormValues,
  initialValues: InitialValues,
) => {
  return Object.keys(meta).map(id => {
    const updatedValues = getUpdatedValues(id, meta, initialValues, formValues);

    return { id, ...updatedValues };
  });
};

const getInitialValues = (
  meta?: WDMetaDataXMPRes,
  schemas?: MGSchemas,
): InitialValues | undefined => {
  if (meta && schemas) {
    const config = getConfig(schemas);
    const editableFields = Object.keys(config);
    const entries = Object.entries(meta);
    const values = entries.reduce((acc, [, obj]) => {
      for (const field of editableFields) {
        acc[field] = (acc[field] || []).concat(obj[field]);
      }

      return acc;
    }, {} as Record<string, string[]>);

    return Object.fromEntries(
      Object.entries(values).map(([key, values]) => {
        const schema = schemas.xmp[key];
        const mapFn = config[key].mapTo(schema);

        return [key, mapFn(values)];
      }),
    );
  }
};
