import { Button, Checkbox, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { setSelectedAssetBuildInstance } from "redux/assetBuilder/assetBuilder.slice";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppSelector } from "shared/hooks/useAppSelector";
import {
  IAssetBuildInstance,
  SelectedInstance,
} from "shared/types/assetBuilder";

import styles from "./SelectInstance.module.scss";

type Props = {
  order: number;
  instance: IAssetBuildInstance;
  instancesBySize: Record<string, IAssetBuildInstance[]>;
};

export const SelectInstance = ({ order, instance, instancesBySize }: Props) => {
  const dispatch = useAppDispatch();
  const selectedInstances = useAppSelector(
    state => state.assetBuilder.buildPage.selectedInstance,
  );
  const selectedIds = selectedInstances.map(i => i.instance.id);
  const tplId = instance.template?.id;

  if (!tplId) {
    return null;
  }

  const enabled =
    !selectedInstances.length ||
    tplId === selectedInstances[0].instance.template?.id;

  const toggleInstance = (ev: CheckboxChangeEvent) => {
    if (ev.target.checked) {
      dispatch(
        setSelectedAssetBuildInstance([
          ...selectedInstances,
          mapSelectedInstance(instance, order),
        ]),
      );
    } else {
      dispatch(
        setSelectedAssetBuildInstance(
          selectedInstances.filter(i => i.instance.id !== instance.id),
        ),
      );
    }
  };

  const selectAllWithSameTemplate = () => {
    // TODO: @mattrc (order)
    const instances = Object.entries(instancesBySize)
      .flatMap(([, v]) => v)
      .filter(i => i.template?.id === tplId)
      .map(mapSelectedInstance);

    dispatch(setSelectedAssetBuildInstance(instances));
  };

  return (
    <div className={styles.host}>
      <Tooltip
        title={
          !enabled
            ? "You can only bulk edit instances that use the same tmplate withing the same size"
            : ""
        }
      >
        <Checkbox
          checked={selectedIds.includes(instance.id)}
          disabled={!enabled}
          onChange={toggleInstance}
        />
      </Tooltip>
      <Button size="small" onClick={selectAllWithSameTemplate}>
        Select all with this template
      </Button>
    </div>
  );
};

const mapSelectedInstance = (
  instance: IAssetBuildInstance,
  order: number,
): SelectedInstance => {
  const template = instance.template!;

  return {
    instance,
    assetType: template.assetType.toLowerCase(),
    order,
    size: `${template.artboard.width} x ${template.artboard.height}`,
  };
};
