import { DownloadOutlined, FolderOutlined } from "@ant-design/icons";
import { Button, Image, Skeleton, Typography, message } from "antd";
import Progress from "antd/es/progress";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { CAMUpload, CAM_ENABLED } from "shared/components/media";
import useGenerateImages from "shared/hooks/genAI/useGenerateImages";
import { AiIcon } from "shared/icons/AiIcon";
import styles from "./AiImageGen.module.scss";
import ImagePreview from "./components/ImagePreview";
import ToolPicker from "./components/ToolPicker";
import { handleDownloadAllFromUrl } from "./utils/functions/imageInfoUtils";

const AiImageGen = () => {
  const [text, setText] = useState("");
  const [progress, setProgress] = useState(0);
  const [carouselVisible, setCarouselVisible] = useState(false);

  const { data, isFetching, handleGenerateImages } = useGenerateImages({
    prompt: text,
    height: 512,
    width: 512,
    nSamples: 4,
    imageUrls: [],
  });

  const handleGenerate = async () => {
    handleGenerateImages();
    for (let i = 0; i <= 100; i += 5) {
      setProgress(i);
      await new Promise(resolve => setTimeout(resolve, 800));
      if (!isFetching) {
        setProgress(100);
      }
    }
  };

  const handleDownloadAll = () => {
    handleDownloadAllFromUrl(data.imageUrls);
  };

  return (
    <div className={styles.aiImageGenContainer}>
      <div className={styles.leftPanel}>
        <ToolPicker />
        <div className={styles.inputSectionContainer}>
          <div>
            <Typography.Title level={5}>Generate Image</Typography.Title>
            <Typography.Text>
              Describe the image you want to generate. The more detailed the
              description, the better the results.
            </Typography.Text>
          </div>
          <TextArea
            value={text}
            onKeyDown={e => {
              if (e.key === "Enter") {
                handleGenerate();
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
            onChange={e => setText(e.target.value)}
            className={styles.inputTextArea}
            placeholder="Photograph of a city skyline, with skyscrapers, from ground level, extremely detailed"
          />
          {(isFetching || (progress < 100 && progress > 0)) && (
            <Progress percent={progress} showInfo={false} />
          )}
          <Button type="primary" onClick={handleGenerate}>
            {isFetching
              ? "Cancel"
              : data && data.imageUrls?.length > 0
              ? "Re-generate"
              : "Generate"}
          </Button>
        </div>
      </div>
      <div className={styles.resultSectionContainer}>
        {data && data?.imageUrls?.length > 0 ? (
          <div>
            <div className={styles.buttonsRow}>
              <Button onClick={handleDownloadAll}>
                <DownloadOutlined />
                Download All
              </Button>
              {CAM_ENABLED && (
                <CAMUpload
                  urls={data.imageUrls}
                  onComplete={() => {
                    message.success("All images saved");
                  }}
                >
                  <Button type="primary">
                    <FolderOutlined /> Save all
                  </Button>
                </CAMUpload>
              )}
            </div>
            <div className={styles.gridContainer}>
              {data?.imageUrls.map((image: string) =>
                !isFetching ? (
                  <ImagePreview
                    image={image}
                    key={image}
                    openCarousel={() => setCarouselVisible(true)}
                  />
                ) : (
                  <Skeleton.Image key={image} className={styles.skeleton} />
                ),
              )}
            </div>
          </div>
        ) : (
          <div className={styles.emptyState}>
            <AiIcon height={100} width={100} color="#1890FF" />
            <Typography.Text>
              Enter a prompt, and let the magic begin
            </Typography.Text>
          </div>
        )}
        <div style={{ display: "none" }}>
          <Image.PreviewGroup
            preview={{
              visible: carouselVisible,
              onVisibleChange: vis => setCarouselVisible(vis),
            }}
          >
            {data?.imageUrls.length &&
              data.imageUrls.map(image => <Image src={image} key={image} />)}
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
  );
};

export default AiImageGen;
