import { MediaColErrorArgs } from "shared/types/assetExporter";

export const validMediaExtensions = ["jpg", "jpeg", "png", "mp4"];

export const urlHasExtension = (url: string, extensions: string[]) => {
  // Checks for a URL ending with a valid media extension
  const regex = new RegExp(
    `^http[^\\?]*.(${extensions.join("|")})(\\?(.*))?$`,
    "gim",
  );
  return url.match(regex) ? true : false;
};

export const convertDropboxUrl = (url: string) => {
  return url.includes("www.dropbox.com")
    ? url.replace("?dl=0", "").replace("www.", "dl.")
    : url;
};

export const extractExtension = (url: string) => {
  const parts = url.split(".");
  const extStr = parts[parts.length - 1];
  const ext =
    extStr.indexOf("?") === -1
      ? extStr
      : extStr.substring(0, extStr.indexOf("?"));
  return ext;
};

export const isValidMediaExtension = (extension: string) => {
  return validMediaExtensions.includes(extension);
};

export const getMediaColumnError = ({
  backgroundColumn,
  validRowsForCol,
  totalRows,
}: MediaColErrorArgs) => {
  return validRowsForCol !== undefined && totalRows !== validRowsForCol
    ? `${validRowsForCol} of ${totalRows} rows contain valid media urls for ${backgroundColumn}`
    : undefined;
};
