import {
  EditTwoTone,
  EyeInvisibleTwoTone,
  EyeTwoTone,
  FileImageTwoTone,
} from "@ant-design/icons";
import { Button, Card, Empty, Popover } from "antd";
import { FC, useMemo, useState } from "react";

import {
  ICanvasObject,
  isLogo,
  isStamp,
  TVisibility,
} from "shared/types/assetBuilder";
import { IAccount } from "shared/types/accountManagement";
import {
  IExtendedFabricObject,
  ILogoObjectData,
  LogoEventType,
} from "shared/types/designStudio";
import { IBrand } from "shared/types/brandManagement";
import { CAM_ENABLED } from "../media";
import { MediaSelect } from "./MediaSelect";
import "./CardToggle.scss";

export interface IBox {
  objectType: TVisibility["type"];
  subtype?: LogoEventType;
  order: number;
  id: string;
  width: number;
  height: number;
  x: number;
  y: number;
  isVisible: boolean;
  object: IExtendedFabricObject;
}

interface ICardToggleProps {
  box: IBox;
  onToggle: any;
  logoImageUrls: string[];
  order: number;
  openPopover: IBox | null;
  editable?: boolean;
  highlight?: boolean;
  display?: boolean; // defaulted to true.
  store?: IAccount | null;
  oems?: IBrand[];
  fabricObject?: fabric.Object;
  stampOrder: number;
}

interface ICardToggleEventHandlers {
  onToggle?: (box: IBox) => void;
  onOpenPopover?: (box: IBox) => void;
  onImageChange?: (box: IBox, imgUrl: string) => void;
  closePopover?: () => void;
}

const CardToggle: FC<ICardToggleProps & ICardToggleEventHandlers> = props => {
  const [copiedBool, setCopiedBool] = useState<boolean>(false);

  const showLogoSelection = props.editable && props.box.objectType === "logo";

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const noData = useMemo(() => getNoDataResouce(props.box), []);
  const cardTitle = useMemo(
    () => getCardTitle(props.box, props.stampOrder),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div
      className="card-toggle"
      key={`stamp-box-key-${props.order}`}
      style={
        props.display === undefined || props.display
          ? {
              width: props.box.width,
              height: props.box.height,
              left: props.box.x,
              top: props.box.y,
              position: "absolute",
              border: props.highlight ? "4px dotted #1991FF" : "none",
              boxSizing: "border-box",
              background: !props.box.isVisible
                ? "rgba(255,255,255,0.5)"
                : "inherit",
              transition: "background 300ms",
              transitionTimingFunction: "ease-in-out",
              display: "inherit", // we have to hide box if not being used
            }
          : {}
      }
    >
      <div
        className="box-inner-wrapper"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
        }}
      >
        {props.highlight && <div className="card-label-title">{cardTitle}</div>}
        <div
          className="toggle-button-wrapper"
          style={{
            display: !!props.editable ? "block" : "none",
            position: "absolute",

            width: "100%",
            height: "2em",
            boxSizing: "border-box",
            textAlign: "center",
          }}
        >
          <div className="clickable-buttons">
            {isStamp(props.box.object as unknown as ICanvasObject) && (
              <Popover content={copiedBool ? "Copied!" : "Copy To Clipboard"}>
                <Button
                  ghost
                  key="COPY"
                  icon={<EditTwoTone />}
                  style={{ float: "right", border: "none" }}
                  onClick={() => {
                    const domain = window.location.origin;
                    const designStudioLink =
                      "/design-studio/editor/stamps/:targetId/editor-v2";
                    const url = `${domain}${designStudioLink.replace(
                      ":targetId",
                      (props.box.object.customData as any).stampId ?? "",
                    )}?offerType=Lease`;
                    navigator?.clipboard?.writeText(url);
                    setCopiedBool(true);
                  }}
                ></Button>
              </Popover>
            )}
            {props.box.isVisible ? (
              <EyeInvisibleTwoTone
                twoToneColor="#1890ff"
                onClick={() => {
                  if (!props.onToggle) {
                    return;
                  }

                  props.onToggle(props.box);
                }}
              />
            ) : (
              <EyeTwoTone
                twoToneColor="#1890ff"
                onClick={() => {
                  if (!props.onToggle) {
                    return;
                  }

                  props.onToggle(props.box);
                }}
              />
            )}
            {showLogoSelection && (
              <Popover
                overlayClassName="logo-popover"
                placement="bottom"
                trigger="click"
                visible={props.openPopover?.id === props.box.id}
                onVisibleChange={visible => {
                  if (!visible) {
                    props.closePopover?.();
                  }
                }}
                content={
                  <div className="library-wrapper">
                    {props.logoImageUrls.length ? (
                      props.logoImageUrls.map((url: string, idx: number) => {
                        return (
                          <Card
                            className="logo-preview-card"
                            key={`logo-image-${idx}`}
                            hoverable
                            onClick={() => {
                              props.onImageChange?.(props.box, url);
                            }}
                            cover={<img src={url} alt="preview" />}
                          />
                        );
                      })
                    ) : (
                      <Empty
                        className="empty"
                        image={Empty.PRESENTED_IMAGE_DEFAULT}
                        description={
                          <div className="empty-logo-image-wrapper">
                            {`No images found. You can add them here - `}
                            <span>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={noData?.url}
                              >
                                {noData?.title}
                              </a>
                            </span>
                          </div>
                        }
                      />
                    )}
                  </div>
                }
              >
                {CAM_ENABLED ? (
                  <MediaSelect
                    box={props.box}
                    onImageChange={props.onImageChange}
                  />
                ) : (
                  <FileImageTwoTone
                    twoToneColor="#1890ff"
                    onClick={() => {
                      if (!showLogoSelection || !props.onOpenPopover) return;

                      props.onOpenPopover(props.box);
                    }}
                  />
                )}
              </Popover>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// At the time of writing this, only logo object.
const getNoDataResouce = (box: IBox): { title: string; url: string } | null => {
  const customData = box.object.customData as ILogoObjectData;
  switch (customData.logoEventType) {
    case "OEM_LOGO":
    case "BRAND_LOGO":
      return {
        title: "Brand Logo",
        url: "/oem-management",
      };

    case "SALES_EVENT_LOGO":
      return {
        title: "Sales Event Logo",
        url: "/oem-management",
      };
    case "STORE_LOGO":
    case "ACCOUNT_LOGO":
      return {
        title: "Account Logo",
        url: "/store-management",
      };

    default:
      return null;
  }
};

export const getCardTitle = (box: IBox, stampOrder: number) => {
  const { object } = box;
  if (!object) return "NA";

  const { customType, customData } = object;
  // AV2-2963: Show stamp order to
  if (isStamp(object as unknown as ICanvasObject) && stampOrder !== -1) {
    return `Stamp ${stampOrder + 1}`;
  }

  if (!isLogo(object as unknown as ICanvasObject)) {
    return customType;
  }

  const { logoEventType } = customData as ILogoObjectData;
  switch (logoEventType) {
    case "OEM_LOGO":
    case "BRAND_LOGO":
      return "brand logo";

    case "SALES_EVENT_LOGO":
      return "sales event logo";

    case "STORE_LOGO":
    case "ACCOUNT_LOGO":
      return "account logo";

    default:
      return "NA";
  }
};

export default CardToggle;
