import { isEmpty } from "lodash";
import { dynamicFontMappingsUrl } from "utils/constants";
import { FontNameMappings, MappingsType } from "./FontProperty.types";

export const getPropertyValue = (
  textbox?: fabric.Textbox,
  propertyName?: keyof fabric.Textbox,
) => {
  if (!textbox || !propertyName) return "-";

  const { [propertyName]: defaultPropertyValue, styles } = textbox;
  if (!styles || isEmpty(styles))
    return defaultPropertyValue ? defaultPropertyValue : "-";
  const filteredFontFamilies = Object.values(styles).reduce(
    (acc: string[], style: any) => {
      if (typeof style !== "object" || isEmpty(style)) return acc;

      const fontFamilies = Object.values(style).reduce(
        (innerAcc: string[], innerStyle: any) => {
          const { [propertyName]: propertyValue } = innerStyle || {};
          if (!propertyValue) return innerAcc;

          return [...innerAcc, propertyValue];
        },
        [] as string[],
      );

      return [...acc, ...fontFamilies];
    },
    [],
  );

  const fontFamilies = Array.from(new Set(filteredFontFamilies));

  return fontFamilies.length > 1
    ? "Mixed"
    : fontFamilies[0] ?? defaultPropertyValue;
};

export const getHighlightedPropertyValue = (
  textbox?: fabric.Textbox,
  propertyName?: keyof fabric.Textbox,
) => {
  if (!textbox || !propertyName) return "-";

  const propertyValues = Array.from(
    new Set(
      textbox.getSelectionStyles().reduce((acc, style) => {
        if (!style) return acc;
        const { [propertyName]: propertyValue } = style;
        return propertyValue ? [...acc, propertyValue] : acc;
      }, []),
    ),
  ) as string[];

  if (propertyValues.length === 0) {
    return textbox[propertyName] || "-";
  }

  return propertyValues.length > 1 ? "Mixed" : propertyValues[0]!;
};

export const isNumber = (num: any) => {
  return !Number.isNaN(+num);
};

export const fetchMappingsJson = async () => {
  try {
    const url = dynamicFontMappingsUrl;
    if (!url) throw new Error("mappings.json url invalid.");

    const res = await fetch(url, { cache: "no-cache" });
    return (await res.json()) as MappingsType;
  } catch (err) {
    return null;
  }
};

export const parseMappingsJson = (
  mappings: MappingsType,
): [string[], FontNameMappings] => {
  const fontNames = Object.values(mappings).reduce<string[]>(
    (acc, fontObjects) => {
      return [...acc, ...Object.keys(fontObjects)];
    },
    [],
  );
  const fontNameMappings = Object.values(mappings).reduce<FontNameMappings>(
    (acc, fontObjects) => {
      return { ...acc, ...fontObjects };
    },
    {},
  );
  return [fontNames, fontNameMappings];
};

export const loadDynamicFontList = async () => {
  const mappings = await fetchMappingsJson();
  return parseMappingsJson(mappings || {});
};
