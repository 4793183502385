import { Spin, message, Row, Col } from "antd";
import classNames from "classnames";
import {
  DesignStudioTableItem,
  useDesignStudioContext,
} from "screens/designStudio/context/DesignStudioDataProvider";
import { useDesignStudioEditor } from "screens/designStudio/designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";
import { LayerDetails } from "./LayerDetails";
import { Layers } from "./editorDrawerContent/Layers";
import styles from "../EditorDrawer.module.scss";
import EditorProvider from "../../Editor.context";
import { isTemplate } from "screens/designStudio/helpers";
import { Editor } from "./editorDrawerContent/Editor";
import { PolotnoEditor } from "./editorDrawerContent/PolotnoEditor";
import { POLOTNO_EDITOR } from "../constants";

type EditorDrawerContentProps = {
  item?: DesignStudioTableItem;
  editor?: string | null;
  assetId?: string | null;
  mode?: string | null;
};

export const EditorDrawerContent = ({
  item,
  editor,
  assetId,
  mode,
}: EditorDrawerContentProps) => {
  const { isFetchingMoreTemplates } = useDesignStudioContext();
  const { setEditor } = useDesignStudioEditor();
  if (!editor || !assetId) return null;
  if (isFetchingMoreTemplates && !item) {
    return <Spin className={classNames(styles.content, styles.spinner)} />;
  }

  const isValid =
    (editor === "templates" || editor === "stamps") && isTemplate(item);

  if (!isValid) {
    message.error({
      content:
        "Invalid parameters, selected item does not exist or was deleted",
      key: "invalid-parameters",
    });
    setEditor(null, "replace");

    return null;
  }

  if (mode === POLOTNO_EDITOR) {
    return (
      <EditorProvider targetTab={editor} targetId={assetId}>
        <PolotnoEditor item={item} />
      </EditorProvider>
    );
  }

  return (
    <EditorProvider targetTab={editor} targetId={assetId}>
      <Row align="stretch" className={styles.content}>
        <Col span={4} className={styles.layersColumn}>
          <Layers />
        </Col>
        <Col span={16} className={styles.editorColumn}>
          <Editor item={item} />
        </Col>
        <Col span={4}>
          <LayerDetails item={item} />
        </Col>
      </Row>
    </EditorProvider>
  );
};
