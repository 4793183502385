import { useMemo } from "react";
import { Upload } from "antd";
import { getStoreLogoPayload } from "screens/dealerManagement/MediaStoreLogos/utils";
import { useFetchLogos } from "shared/components/MediaLogos/useFetchLogos";
import { withFileOrientation } from "shared/components/MediaLogos/utils";
import { useDebounce } from "shared/hooks/useDebounce";
import { IAccountRecord } from "shared/types/accountManagement";
import { getCAMThumbnail } from "shared/components/media";

type MediaGalleryLogosProps = {
  record: IAccountRecord;
};

const LogoTypes = {
  Horizontal: "Horizontal",
  Vertical: "Vertical",
  Square: "Square",
};

export const MediaGalleryLogos = ({ record }: MediaGalleryLogosProps) => {
  const { dealerCode, dealerOem } = useDebounce(record, 500);

  const searchPayload = useMemo(() => {
    return getStoreLogoPayload(dealerCode, dealerOem);
  }, [dealerCode, dealerOem]);

  const orientationPayloads = useMemo(() => {
    if (searchPayload) {
      return {
        horizontal: withFileOrientation(searchPayload, "horizontal"),
        vertical: withFileOrientation(searchPayload, "vertical"),
        square: withFileOrientation(searchPayload, "square"),
      };
    }
  }, [searchPayload]);

  const { assets: horizontalAssets } = useFetchLogos(
    orientationPayloads?.horizontal,
  );
  const { assets: verticalAssets } = useFetchLogos(
    orientationPayloads?.vertical,
  );
  const { assets: squareAssets } = useFetchLogos(orientationPayloads?.square);

  const logos = useMemo(() => {
    return {
      [LogoTypes.Horizontal]: horizontalAssets || [],
      [LogoTypes.Vertical]: verticalAssets || [],
      [LogoTypes.Square]: squareAssets || [],
    };
  }, [horizontalAssets, verticalAssets, squareAssets]);

  return (
    <>
      {Object.keys(LogoTypes).map(key => {
        const images = logos?.[key].map(getCAMThumbnail).map(({ url }) => url);
        return (
          <Upload
            key={key}
            disabled={true}
            listType="picture-card"
            fileList={images}
          />
        );
      })}
    </>
  );
};
