import { useQuery } from "react-query";
import AdAccount, { adAccount } from "../facebookUtils/adAccount";
import {
  IFacebookAccount,
  IFacebookAssetType,
  IFacebookObject,
} from "../facebookUtils/types";

export const getFacebookAssetQuery = (
  name: IFacebookAssetType,
  selectedAccount: IFacebookAccount | null | undefined,
  pageId?: string,
) => {
  const accountName = selectedAccount?.name;
  const accountId = selectedAccount?.account_id;
  const args = { accountName, accountId, skipNameMatching: true, pageId };
  switch (name) {
    case "page":
      return () => adAccount.getPages(args);
    case "catalog":
      return () => adAccount.getCatalogs(args);
    case "pixel":
      return () => (accountId ? adAccount.getPixels(args) : undefined);
    case "instagram":
      return () => (pageId ? adAccount.getInstagram(args) : undefined);
  }
};

const fetchAccountPixels = async (accountId: string) => {
  const fbAccount = new AdAccount(accountId);
  const accountPixelsRes = await fbAccount.getPixels({
    accountId: fbAccount.accountId,
  });
  return accountPixelsRes;
};

const fetchPixelsForAccounts = (accountIds: string[]) => async () => {
  const fetchPixelResponses = await Promise.all(
    accountIds.map(async id => await fetchAccountPixels(id)),
  );
  const fetchPixelResults = fetchPixelResponses
    .filter(res => !!res.result?.data)
    .map(res => res.result!.data);
  return fetchPixelResults.flat();
};

export const useFetchPixelsForAccounts = (
  accountIds: string[],
  options?: { enabled?: boolean; retry?: boolean },
) => {
  return useQuery<IFacebookObject[]>(
    ["facebookPixels", accountIds.join(",")],
    fetchPixelsForAccounts(accountIds),
    {
      enabled: options?.enabled,
      retry: options?.retry,
      staleTime: 300000,
    },
  );
};
