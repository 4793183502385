import { useWindowSize } from "shared/hooks/useWindowSize";
import { useDesignStudioTableColumns } from "./designStudioTable/hooks/useDesignStudioTableColumns";
import {
  DesignStudioTableItem,
  useDesignStudioContext,
} from "../context/DesignStudioDataProvider";
import {
  getRowKey,
  isArtboard,
  isPublished,
  isTemplate,
  isHTMLTemplate,
} from "../helpers";
import { DesignStudioFields, designStudioFieldKeys } from "../context/fields";
import DataListURLTable from "shared/components/dataListURL/DataListURLTable";
import { ColumnsFilter } from "./designStudioTable/ColumnsFilter";
import useSelectedColumns from "shared/hooks/userPreferences/useSelectedColumns";
import { columnPresets } from "./designStudioTable/columnFilters/columns";
import { TableActionsContextProvider } from "shared/components/HoverActionsVirtualTable/TableActionsContext";
import { ContextTableActions } from "shared/components/tableActions/ContextTableActions";
import { InlineTableActions } from "shared/components/tableActions/InlineTableActions";
import { ToolbarButton } from "shared/components/toolbarTable/ToolbarTable";
import { useDesignStudioTableActions } from "./designStudioTable/hooks/useDesignStudioTableActions";
import {
  EditOutlined,
  ControlOutlined,
  EyeOutlined,
  FileDoneOutlined,
  InboxOutlined,
  DeleteOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import UnpublishOutlined from "shared/icons/UnpublishOutlined";

export const DesignStudioTable = () => {
  const { windowInnerHeight } = useWindowSize();
  const columns = useDesignStudioTableColumns();

  const { isColumnSelected, selectedColumns, setSelectedColumns } =
    useSelectedColumns(columnPresets.default);

  const {
    isLoading,
    filteredDesignStudioAssets,
    isColumnPickerOpen,
    setIsColumnPickerOpen,
  } = useDesignStudioContext();

  const filteredColumns = columns.filter(column =>
    isColumnSelected(column.key),
  );

  const updateColumnSelection = (columns: string[]) => {
    setSelectedColumns(columns);
    setIsColumnPickerOpen(false);
  };

  const {
    editParams,
    showPreview,
    archive,
    edit,
    deleteAsset,
    publish,
    duplicate,
    isMutatingTemplates,
  } = useDesignStudioTableActions();
  const generateToolbarContents: (
    record?: DesignStudioTableItem,
  ) => ToolbarButton = record => {
    if (!record) return {};

    return {
      Edit: {
        icon: <EditOutlined />,
        disabled: edit.disabled(record),
        onClick: () => edit.handler(record),
        showInContextMenu: !isArtboard(record) && !isHTMLTemplate(record),
      },
      Preview: {
        icon: <EyeOutlined />,
        disabled: showPreview.disabled(record),
        onClick: () => showPreview.handler(record),
        showInContextMenu: isTemplate(record),
      },
      Params: {
        icon: <ControlOutlined />,
        disabled: false,
        onClick: () => editParams.handler(record),
        showInContextMenu: true,
      },
      Load: {
        icon: isPublished(record) ? (
          <UnpublishOutlined />
        ) : (
          <FileDoneOutlined />
        ),
        extraInfo: {
          tooltip: isPublished(record) ? "Unpublish" : "Publish",
          title: isPublished(record) ? "Unpublish" : "Publish",
        },
        disabled: publish.disabled(record),
        onClick: () => publish.handler(record),
        showInContextMenu: isTemplate(record),
      },
      Archive: {
        icon: <InboxOutlined />,
        disabled: archive.disabled([record]),
        onClick: () => archive.handler([record]),
        showInContextMenu: !isArtboard(record),
      },
      Delete: {
        icon: <DeleteOutlined />,
        disabled: deleteAsset.disabled(record),
        onClick: () => deleteAsset.handler(record),
        showInContextMenu: isArtboard(record),
      },
      Duplicate: {
        icon: <CopyOutlined />,
        onClick: () => duplicate.handler(record),
        disabled: duplicate.disabled(record),
        showInContextMenu: !isArtboard(record),
      },
    };
  };

  return (
    <>
      <TableActionsContextProvider
        renderContextActions={(record?: DesignStudioTableItem) => (
          <ContextTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
        renderHoverActions={(record?: DesignStudioTableItem) => (
          <InlineTableActions
            getItemsToRender={generateToolbarContents}
            record={record}
          />
        )}
      >
        <DataListURLTable<DesignStudioFields, DesignStudioTableItem>
          loading={isLoading || isMutatingTemplates}
          dataSource={filteredDesignStudioAssets}
          columns={filteredColumns}
          pagination={false}
          size="small"
          scroll={{ y: windowInnerHeight - 245 }}
          type="hoverActionsVirtualTable"
          rowKey={getRowKey}
          fieldKeys={designStudioFieldKeys}
        />
      </TableActionsContextProvider>

      <ColumnsFilter
        open={isColumnPickerOpen}
        columns={columns}
        selectedColumns={selectedColumns}
        setSelectedColumns={updateColumnSelection}
      />
    </>
  );
};
