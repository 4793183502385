import { useMutation, useQueryClient } from "react-query";
import API from "services";
import { UploadPmaxCampaignsArgs } from "shared/types/adLibrary";
import { useUploadAssetGroups } from "./useUploadAssetGroups";
import useAssetGroups from "./useAssetGroups";

import {
  transformCampaignsToLoad,
  getComputedCampaignStatuses,
} from "./helpers.campaigns";
import { message } from "antd";
import { useUser } from "shared/hooks/useUser";
import { raise } from "utils/errorMessage";
import { pmaxCampaignsQueryKey } from "./constants";

type UseUploadPmaxCampaignsArgs = Omit<UploadPmaxCampaignsArgs, "user">;

export const useUploadPmaxCampaigns = (args: UseUploadPmaxCampaignsArgs) => {
  const user = useUser();
  const queryClient = useQueryClient();
  const { adAccount, campaigns } = args;
  const { selectedAssetGroups } = useAssetGroups({
    selectedIds: campaigns.flatMap(c => c.assetGroupIds || []),
  });
  const {
    upload: uploadAssetGroups,
    results,
    isLoading: isUploadingAssetGroups,
  } = useUploadAssetGroups();

  const campaignsMutation = useMutation(
    async () => {
      const response = await API.services.adLibrary.uploadPmaxCampaigns({
        user: user ?? raise("User is not defined"),
        adAccount,
        campaigns: transformCampaignsToLoad(campaigns),
      });

      if (response.error) throw response.error;
      return response;
    },
    {
      onSettled: (data, error) => {
        if (!data?.result || error) {
          message.error(error ?? data?.error ?? "Something went wrong");
          return;
        }
        const assetGroupToLoadByCampaign = campaigns.flatMap(campaign => {
          return selectedAssetGroups.map(assetGroup => ({
            ...assetGroup,
            campaign: data.result?.find(c => c.name === campaign.name),
            adAccount,
          }));
        });

        queryClient.refetchQueries(pmaxCampaignsQueryKey);

        if (!assetGroupToLoadByCampaign.length) return;
        uploadAssetGroups(assetGroupToLoadByCampaign);
      },
    },
  );

  return {
    ...campaignsMutation,
    results: getComputedCampaignStatuses(
      campaigns,
      campaignsMutation.status,
      results.responses,
    ),
    isLoading: campaignsMutation.isLoading || isUploadingAssetGroups,
  };
};
