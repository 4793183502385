import { useFetchTemplates } from "shared/hooks/useFetchTemplates";
import { useEffect, useMemo } from "react";
import { IArtboard, IStamp, ITemplate } from "shared/types/designStudio";
import {
  getRowKey,
  isArchived,
  sortByLastEditedDateFn,
} from "../../../helpers";
import {
  DesignStudioAssetType,
  DesignStudioTableItem,
} from "screens/designStudio/context/DesignStudioDataProvider";

import actions from "redux/rootActions";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { useDesignStudioURLData } from "screens/adLibrary/shared/hooks/dataListHooks";

const processDesignStudioArray = (
  designStudioArray: ITemplate[] | IArtboard[] | IStamp[] | undefined,
  itemType: DesignStudioAssetType,
) => {
  if (!designStudioArray) return [];

  return designStudioArray.map(asset => ({
    asset,
    itemType,
    id: getRowKey({ asset, itemType } as DesignStudioTableItem),
  })) as DesignStudioTableItem[];
};

export const useDesignStudioData = () => {
  const {
    isFetching: isFetchingTemplates,
    templates: templatesData,
    hasNextPage,
  } = useFetchTemplates({});

  const {
    stamps,
    isFetchingStamps,
    artboards,
    isFetchingArtboards,
    updatingData,
    creatingData,
  } = useAppShallowEqualSelector(({ designStudio }) => ({
    stamps: designStudio.stamps,
    isFetchingStamps: designStudio.fetchingData === "stamps",
    artboards: designStudio.artboards,
    isFetchingArtboards: designStudio.fetchingData === "artboards",
    updatingData: designStudio.updatingData,
    creatingData: designStudio.creatingData,
  }));

  const isUpdatingData =
    updatingData === "stamp" ||
    updatingData === "artboard" ||
    updatingData === "template";

  const isCreatingData =
    creatingData === "stamp" ||
    creatingData === "artboard" ||
    creatingData === "template";

  const isFetchingData =
    isFetchingTemplates || isFetchingArtboards || isFetchingStamps;
  // Data fetching for stamps and artboards is done in redux-land, unlike templates
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(actions.designStudio.fetchData("stamps"));
    dispatch(actions.designStudio.fetchData("artboards"));
  }, [dispatch]);

  const designStudioAssets = useMemo(() => {
    const processedTemplates = processDesignStudioArray(
      templatesData,
      "template",
    ).filter(item => !isArchived(item));
    const processedArtboards = processDesignStudioArray(artboards, "artboard");
    const processedStamps = processDesignStudioArray(stamps, "stamp");

    return [
      ...processedTemplates,
      ...processedArtboards,
      ...processedStamps,
    ].sort(sortByLastEditedDateFn);
  }, [templatesData, artboards, stamps]);

  const isFetchingMoreTemplates = (isFetchingTemplates || hasNextPage) ?? false;

  const designStudioData = useDesignStudioURLData({
    data: designStudioAssets,
    isLoading: isFetchingData || isCreatingData || isUpdatingData,

    isError: false,
  });

  return {
    ...designStudioData,
    isFetchingMoreTemplates,
  };
};
