import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { useFetchDashboardSettings } from "shared/hooks/dashboardManagement/useFetchDashboardSettings";
import { useFetchDashboard } from "shared/hooks/dashboardManagement/useFetchDashboards";
import useFetchLookerIframe from "shared/hooks/metrics/useFetchLookerIframe";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { useUser } from "shared/hooks/useUser";
import { IDashboard } from "shared/types/dashboardManagement";
import styles from "../Metrics.module.scss";
import { DashboardDetailHeader } from "./DashboardDetailHeader";
import { sendDataToLookerIframe } from "./shared/utils/LookerUtils";

const DashboardDetail = ({
  setShareDashboard,
  setShowLinkModal,
}: {
  setShareDashboard?: (dashboard?: IDashboard) => void;
  setShowLinkModal?: (showLinkModal: boolean) => void;
}) => {
  const isAdmin = useIsAdmin();
  const { id } = useParams() as { id: string };
  const { data, isLoading: loadingDashboard } = useFetchDashboard(id);
  const user = useUser();
  const email = user?.email ?? "";
  const { data: dashboardSettings, isIdle } = useFetchDashboardSettings(
    !isAdmin,
    email,
  );
  const { data: iframeUrl, isLoading: loadingIframe } = useFetchLookerIframe(
    data?.dashboard,
    isIdle ? null : dashboardSettings,
  );

  return (
    <>
      <DashboardDetailHeader
        dashboard={data?.dashboard}
        setShareDashboard={setShareDashboard}
        setShowLinkModal={setShowLinkModal}
      />
      <Spin spinning={loadingDashboard || loadingIframe} size="large">
        <div className={styles.iframeContainer}>
          {iframeUrl ? (
            <iframe
              title="Insights Preview Metrics Iframe!"
              src={iframeUrl}
              width="100%"
              height="100%"
              onLoad={e =>
                sendDataToLookerIframe(e.target as HTMLIFrameElement, {
                  type: "dashboard:filters:update",
                  filters: {
                    Account: data?.dashboard?.account || "",
                    Oem: data?.dashboard?.oem || "",
                  },
                })
              }
              id="looker-iframe"
            />
          ) : (
            !loadingDashboard &&
            !loadingIframe && <div>Unable to fetch iframe</div>
          )}
        </div>
      </Spin>
    </>
  );
};

export default DashboardDetail;
