import { useCallback } from "react";
import { Drawer, Row, Col, Space, Button, message } from "antd";
import styles from "./EditorDrawer.module.scss";
import { useDesignStudioEditor } from "../designStudioLayout/designStudioTable/hooks/useDesignStudioEditor";
import { useDesignStudioContext } from "../context/DesignStudioDataProvider";
import { isArtboard, isTemplate } from "../helpers";

import { EditorDrawerContent } from "./editorDrawer/EditorDrawerContent";
import { useSaveTemplateDraft } from "./hooks/useSaveTemplateDraft";
import { usePolotnoStore } from "./editorDrawer/editorDrawerContent/polotnoEditor/usePolotnoStore";

const clickHandler = () => message.info("Not implemented yet");

export const EditorDrawer = () => {
  const { editorQuery, setEditor } = useDesignStudioEditor();
  const { designStudioAssets } = useDesignStudioContext();
  const { mutate: saveDraftMutation, isLoading: isSavingDraft } =
    useSaveTemplateDraft();
  const { toJSON, getBase64Thumbnail } = usePolotnoStore();
  const isOpen = Boolean(editorQuery.editor);

  const selectedItem = designStudioAssets.find(
    item => !isArtboard(item) && item.asset.id === editorQuery.assetId,
  );

  const saveDraft = useCallback(async () => {
    if (!isTemplate(selectedItem)) return;

    const { asset: template } = selectedItem;
    const base64Thumbnail = await getBase64Thumbnail();
    saveDraftMutation(
      { template, json: toJSON(), base64Thumbnail },
      {
        onSuccess: () => {
          message.success("Template draft saved successfully");
        },
        onError: () => {
          message.error("Failed to save template draft");
        },
      },
    );
  }, [saveDraftMutation, selectedItem, toJSON, getBase64Thumbnail]);

  return (
    <Drawer
      visible={isOpen}
      destroyOnClose={true}
      width={"100%"}
      className={styles.drawer}
      bodyStyle={{ padding: 0 }}
      footer={
        <Row justify="space-between">
          <Col>
            <Button onClick={() => setEditor(null)}>Cancel</Button>
          </Col>
          <Col>
            <Space direction="horizontal">
              <Button onClick={clickHandler}>Publish</Button>
              <Button
                onClick={saveDraft}
                disabled={isSavingDraft}
                loading={isSavingDraft}
                type="primary"
              >
                Save draft
              </Button>
            </Space>
          </Col>
        </Row>
      }
      title={
        <Row justify="space-between" align="middle">
          <Col>{selectedItem?.asset.name}</Col>
        </Row>
      }
      closable={false}
    >
      <EditorDrawerContent
        item={selectedItem}
        editor={editorQuery.editor}
        assetId={editorQuery.assetId}
        mode={editorQuery.mode}
      />
    </Drawer>
  );
};
