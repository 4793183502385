import { useEffect, useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import API from "services";
import { getErrorMessage } from "utils/errorMessage";
import { useAppSelector } from "../useAppSelector";
import { getSortedColHeaders } from "screens/assetExporter/sourceFeed/newFeedDrawer/utils";

const getFeedPreview = async (
  filename: string,
  isUpdate: boolean,
  feedId: string,
  page: number,
  isServer: boolean,
) => {
  try {
    const { getCsvPreview } = API.services.assetExporter;
    const { result, error } = await getCsvPreview(
      filename,
      isUpdate,
      feedId,
      page,
      isServer,
    );

    if (!!error) throw new Error(error.message);
    if (!result) throw new Error("An error ocurred");

    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

type UseFetchPreviewParams = {
  filename: string;
  isUpdate: boolean;
  feedId: string;
  isServer: boolean;
};
const useFetchPreview = (params: UseFetchPreviewParams) => {
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useFetchPreviewPaginated(params);

  useEffect(() => {
    if (!hasNextPage || isFetchingNextPage) return;

    fetchNextPage();
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const { columnSortedByWarning, columnData } = useAppSelector(
    state => state.assetExporter.sourceFeed.feedDrawer,
  );

  const previewData = useMemo(() => {
    return (
      data?.pages.flatMap(page => {
        return page.data;
      }) ?? []
    );
  }, [data?.pages]);

  const colHeaders = data?.pages[0].columnHeaders ?? [];
  const columnHeaders = columnSortedByWarning
    ? getSortedColHeaders(columnData)
    : colHeaders;

  return {
    isLoading,
    refetch,
    previewData,
    columnHeaders,
  };
};

const useFetchPreviewPaginated = ({
  feedId,
  filename,
  isServer,
  isUpdate,
}: UseFetchPreviewParams) =>
  useInfiniteQuery(
    ["csvPreview", filename],
    ({ pageParam = 0 }) =>
      getFeedPreview(filename, isUpdate, feedId, pageParam, isServer),
    {
      getNextPageParam: lastPage => {
        const nextPage = lastPage.nextPage;
        if (!nextPage) return false;
        return nextPage;
      },
      staleTime: Infinity,
      retryDelay: 2000,
      retry: true,
    },
  );

export default useFetchPreview;
