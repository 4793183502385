import { AdType } from "screens/adLibrary/facebookUtils/types";
import { useCurrentSession } from "./useCurrentSession";
import { useMemo } from "react";

export const useFilteredProtosDrafts = () => {
  const { session } = useCurrentSession();
  const { adProtoDrafts } = session.data;

  const adProtoDraftsWithDests = useMemo(
    () => adProtoDrafts.filter(adProtoDraft => adProtoDraft.dests.length > 0),
    [adProtoDrafts],
  );

  const adProtoDraftsWithDestUrls = useMemo(
    () =>
      adProtoDraftsWithDests.filter(adProtoDraft => {
        switch (adProtoDraft.type) {
          case AdType.Still:
          case AdType.Video:
          case AdType.Carousel:
          case AdType.AIA:
            return true;
          case AdType.InstantExperience:
          case AdType.Collection:
            return adProtoDraft.elementDests.some(
              elementDest => elementDest.destType === "url",
            );
          default:
            return false;
        }
      }),
    [adProtoDraftsWithDests],
  );

  const adProtoDraftsWithProductSets = useMemo(() => {
    return adProtoDraftsWithDests.filter(adProtoDraft => {
      switch (adProtoDraft.type) {
        case AdType.InstantExperience:
        case AdType.Collection:
          return adProtoDraft.elementDests.some(
            elementDest => elementDest.destType === "productSet",
          );
        default:
          return false;
      }
    });
  }, [adProtoDraftsWithDests]);

  const adProtoDraftsWithConvertToVideo = useMemo(
    () =>
      adProtoDraftsWithProductSets.filter(
        adProtoDraft =>
          "convertToVideo" in adProtoDraft && adProtoDraft.convertToVideo,
      ),
    [adProtoDraftsWithProductSets],
  );

  return {
    adProtoDraftsWithDestUrls: adProtoDraftsWithDestUrls.concat(
      adProtoDraftsWithConvertToVideo,
    ),
    adProtoDraftsWithProductSets,
  };
};
