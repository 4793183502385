import { IConfig } from "shared/types/configuration";
import {
  customFabricAttributes,
  GetArtboardsResponse,
  GetStampsResponse,
  GetTemplateOemsMap,
  IGetTemplatesResponse,
  IStamp,
  IStampExceptionType,
  ITemplate,
  TemplateStatusType,
} from "shared/types/designStudio";
import { IApiResponse } from "shared/types/shared";
import HttpClient from "./httpClient";
import { fabric } from "fabric";
import {
  IGetPutSignedUrlReqBody,
  IGetSignedPutUrlResponse,
  IUploadImage,
  IUploadImagesResponse,
  IUploadImagesResult,
} from "shared/types/uploadManagement";
import API from "services";
import { dataURLtoBlob } from "utils/helpers.fabric";
import GenericError from "shared/errors/GenericError";
import { IThemeBackgroundImage } from "shared/types/assetBuilder";
import { ImageUploadBody } from "screens/designStudio/artboardDrawer/utils";
import { User } from "redux/auth/auth.slice";
import {
  Feature,
  FilterPreset,
} from "screens/designStudio/DrawerContext/useFilterPresets";
import { PolotnoJSON } from "screens/designStudio/designStudioV2/editorDrawer/editorDrawerContent/polotnoEditor/usePolotnoStore";

type GetTemplatesPaginatedParams = {
  status?: TemplateStatusType;
  isDeleted?: boolean;
  paginationKey?: string;
};

export default ({ config }: { headers: any; config: IConfig }) => ({
  getArtboards: () =>
    HttpClient.get<GetArtboardsResponse>(
      config.services.designStudio.getArtboardsUrl,
    ),
  getTemplatesPaginated: async ({
    status,
    isDeleted,
    paginationKey,
  }: GetTemplatesPaginatedParams = {}) => {
    const { services } = config;
    const { designStudio } = services;
    const { getTemplatesUrl } = designStudio;

    const parameters = {
      status,
      ...(isDeleted && {
        isDeleted: "true",
      }),
      ...(paginationKey && {
        paginationKey: encodeURIComponent(paginationKey),
      }),
    };

    const { result, error } = await HttpClient.get<IGetTemplatesResponse>(
      getTemplatesUrl(parameters),
    );
    if (error || !result) {
      return {
        error: {
          message: "Template list cannot be retrieved at this moment.",
        },
      };
    }

    return {
      result,
    };
  },
  getTemplateById: async (
    id: string,
  ): Promise<IApiResponse<{ template: ITemplate }>> => {
    const url = `${config.services.designStudio.getTemplatesUrl()}/${id}`;
    return HttpClient.get(url);
  },
  getTemplateByOemAndMapFields: (oems: string[], mapFields: string) => {
    const url = config.services.designStudio.getTemplatesUrl({
      oems: oems.join(","),
      map_fields: mapFields,
    });
    return HttpClient.get<GetTemplateOemsMap>(url);
  },
  getStamps: () =>
    HttpClient.get<GetStampsResponse>(
      config.services.designStudio.getStampsUrl,
    ),
  getStampById: async (
    id: string,
  ): Promise<IApiResponse<{ stamps: IStamp[] }>> => {
    const url = `${config.services.designStudio.getStampsUrl}/${id}`;
    return HttpClient.get(url, { cache: "no-cache" });
  },
  saveDraftV3: async (
    template: ITemplate,
    json: PolotnoJSON,
    base64Thumbnail: string,
    user?: User,
  ): Promise<IApiResponse<{ template: ITemplate }>> => {
    return HttpClient.post<IApiResponse<{ template: ITemplate }>>(
      config.services.designStudio.saveDraftUrl,
      {
        base64Thumbnail,
        template,
        canvasJson: json,
        user: user?.email,
      },
    );
  },
  saveDraft: async (
    resource: ITemplate,
    json: string,
    user?: User,
  ): Promise<IApiResponse<{ template: ITemplate }>> => {
    return new Promise((resolve, reject) => {
      const url = `${config.services.designStudio.saveDraftUrl}`;

      const canvas = new fabric.Canvas(document.createElement("canvas"));
      canvas.loadFromJSON(json, async () => {
        const canvasDataUri = canvas.toDataURL({
          format: "jpeg",
          quality: 0.8,
        });

        const base64Thumbnail = canvasDataUri.replace(
          /^data:image\/(jpg|jpeg|png);base64,/,
          "",
        );

        const requestBody = {
          base64Thumbnail,
          template: {
            ...resource,
            type: resource.type || "carcut",
          },
          canvasJson: json,
          user: user?.email,
        };

        // validate
        const isRequestValid =
          !!requestBody.base64Thumbnail &&
          !!requestBody.template &&
          !!requestBody.canvasJson;
        if (!isRequestValid) {
          return reject({
            message:
              "The canvas data is invalid. This is most likely a system error that need to be fixed.",
          });
        }

        try {
          const response = await HttpClient.post<
            IApiResponse<{ template: ITemplate }>
          >(url, requestBody);

          resolve(response);
        } catch (err) {
          reject({
            message: "Saving draft failed due to a system error!",
          });
        }
      });
    });
  },

  saveStamp: async (
    // base64Thumbnail: string,
    resource: IStamp,
    canvasJson: string,
    user?: User,
    stampException?: {
      exceptionType: "state";
      exceptionValue: string;
    },
  ): Promise<IApiResponse<{ stamp: IStamp }>> => {
    return new Promise(resolve => {
      const url = `${config.services.designStudio.stampBaseUrl}/${
        resource.id
      }/offer-types/${encodeURI(resource.offerType as string)}`;

      // conversion to Group object has to be done
      const canvas = new fabric.Canvas(document.createElement("canvas"));

      canvas.loadFromJSON(canvasJson, async () => {
        const canvasDataUri = canvas.toDataURL({
          format: "image/jpeg",
          quality: 0.6,
        });

        const base64Thumbnail = canvasDataUri.replace(
          /^data:image\/(jpg|jpeg|png);base64,/,
          "",
        );

        const { width, height } = resource;
        const objects = canvas.getObjects();
        const group = new fabric.Group(objects, {
          top: 0,
          left: 0,

          width,
          height,

          originX: "left",
          originY: "top",
          centerPoint: new fabric.Point(0, 0),
        } as any);

        const response = await HttpClient.put<IApiResponse<{ stamp: IStamp }>>(
          url,
          {
            stamp: {
              ...resource,
              status: "PUBLISHED",
              updatedBy: user?.email || "",
            },
            stampJson: group.toJSON(customFabricAttributes),
            thumbnailBase64: base64Thumbnail,
            imageType: "png",
            ...(stampException || {}),
          },
        );

        resolve(response);
      });
    });
  },

  getStampsByOEM: async (
    oems: string[],
  ): Promise<IApiResponse<{ stamps: IStamp[] }>> => {
    const url = `${
      (config as IConfig).services.designStudio.getStampsUrl
    }?oems=${oems.join(",")}`;

    return HttpClient.get(url);
  },

  updateStamp: async (
    stamp: IStamp,
  ): Promise<IApiResponse<{ stamps: IStamp[] }>> => {
    const url = config.services.designStudio.createStampUrl;

    return HttpClient.put(url, {
      stampToUpdate: stamp,
    });
  },

  revertStampException: async (
    stamp: IStamp,
    exception: {
      type: IStampExceptionType;
      value: string;
    },
  ): Promise<IApiResponse<{ stamp: IStamp }>> => {
    const url = `${config.services.designStudio.stampBaseUrl}/${
      stamp.id
    }/offer-types/${encodeURIComponent(stamp.offerType || "")}/exceptions`;
    return HttpClient.delete(url, {
      exception,
    });
  },

  uploadVideo: async (
    uploadParam: IUploadImage,
  ): Promise<{
    result: IUploadImagesResult | null;
    error: GenericError | null;
  }> => {
    try {
      const isDesignStudio = uploadParam.featureName === "design-studio";
      const path = `${uploadParam.featureName}/uploads`;

      const getSignedPutUrlReqBody: IGetPutSignedUrlReqBody = {
        uploadParams: {
          file: uploadParam.filename!,
          path: isDesignStudio ? `${path}-raw` : path,
          contentType: "video/mp4", // possible TO DO: allow for different media types
        },
      };

      const getSignedPutUrlRes =
        (await API.services.uploadManagement.getSignedPutUrl(
          getSignedPutUrlReqBody,
        )) as IGetSignedPutUrlResponse;

      if (getSignedPutUrlRes.error || !getSignedPutUrlRes.result) {
        return {
          result: null,
          error: new GenericError({
            message:
              getSignedPutUrlRes.error?.message || "There was unknow error.",
          }),
        };
      }

      const fileBlob = dataURLtoBlob(uploadParam.imageData as string);

      const commonHeaders: HeadersInit = new Headers();
      commonHeaders.set("Content-Type", "video/mp4");
      commonHeaders.set("Accept", "application/json");

      const putResult = await fetch(getSignedPutUrlRes.result.url, {
        method: "PUT",
        headers: commonHeaders,
        body: fileBlob,
      });

      if (putResult.status !== 200) {
        return {
          result: null,
          error: new GenericError({
            message: "Could not upload mp4 video",
          }),
        };
      }

      const params = {
        ...uploadParam,
        imageData: "data:video/mp4",
      };

      const { error: videoErr, result: videoRes } =
        (await API.services.uploadManagement.uploadImages({
          files: [params],
        })) as IUploadImagesResponse;

      if (videoErr || !videoRes?.images) {
        return {
          result: null,
          error: new GenericError({
            message: videoErr?.message || "Could upload video asset.",
          }),
        };
      }

      return {
        result: videoRes as IUploadImagesResult,
        error: null,
      };
    } catch (error) {
      return {
        result: null,
        error: new GenericError({
          message: "There was unknown error.",
          errorObject: `${error}`,
        }),
      };
    }
  },
  updateBgImages: (bgImages: IThemeBackgroundImage[]) => {
    const { services } = config;
    const url = services.designStudio.uploadThemeBackgroundImageUrl;
    const body = { themeImagesToUpdate: bgImages };
    return HttpClient.put<{ error: GenericError }>(url, body, {
      cache: "no-cache",
    });
  },
  uploadBgImages: (
    data: ImageUploadBody,
  ): Promise<{
    result: { themeBackgroundImage: IThemeBackgroundImage };
    error: GenericError;
  }> => {
    const { services } = config;
    const url = services.designStudio.uploadThemeBackgroundImageUrl;
    const { bgImage, imageType, imageBase64, videoSrc } = data;
    const body = { bgImage, imageType, imageBase64, videoSrc };
    return HttpClient.post(url, body, { cache: "no-cache" });
  },
  createTemplate: async (
    template: ITemplate,
    htmlText?: string,
    zipFileId?: string,
    duplicate?: boolean,
  ) => {
    // htmlText will be provided only for "html" type template.
    // This html text will be saved as separate html file in s3 and the url will replace
    //  the "canvasJsonUrl" field.

    const url = config.services.designStudio.createTemplateUrl;
    return HttpClient.post<{
      result: { template: ITemplate | null };
      error: { message: string | null };
    }>(url, {
      template,
      htmlText,
      zipFileId,
      duplicate,
    });
  },
  updateTemplate: async (
    template: ITemplate,
    htmlText?: string,
    zipFileId?: string,
  ) => {
    const url = config.services.designStudio.updateTemplateUrl;
    return HttpClient.put<IApiResponse<{ template: ITemplate }>>(url, {
      templateToUpdate: template,
      htmlText,
      zipFileId,
    });
  },
  getZipSignedUrl: async (id: string) => {
    const url = config.services.designStudio.getZipSignedUrl;
    return HttpClient.get<IApiResponse<{ url: string }>>(`${url}/${id}`);
  },
  putZipFile: async (url: string, data: Blob) => {
    const headers: HeadersInit = new Headers();
    headers.set("Content-Type", "application/zip");
    headers.set("Accept", "*/*");
    return fetch(url, {
      method: "PUT",
      body: data,
      headers,
    });
  },
  deleteZipFile: async (id: string) => {
    const url = config.services.designStudio.deleteZipFileUrl;
    return HttpClient.delete<IApiResponse<boolean>>(`${url}/${id}`);
  },
  validateZipFile: async (id: string) => {
    const url = config.services.designStudio.validateZipFileUrl;
    return HttpClient.post<IApiResponse<boolean>>(url, {
      id,
    });
  },

  fetchFilterPresets: async () => {
    const url =
      config.services.designStudio.fetchFilterPresetsUrl("design-studio");
    return HttpClient.get<{
      result: { presets: FilterPreset[] };
      error: { message: string };
    }>(url);
  },

  createFilterPreset: async (preset: FilterPreset) => {
    const url = config.services.designStudio.createFilterPresetUrl;
    return HttpClient.post<{
      result: { preset: FilterPreset };
      error: { message: string };
    }>(url, preset);
  },

  deleteFilterPreset: async (id: string, feature: Feature) => {
    const url = config.services.designStudio.deleteFilterPresetUrl({
      id,
      feature,
    });
    return HttpClient.delete<{
      result: { preset: FilterPreset };
      error: { message: string };
    }>(url);
  },
});
