import { groupBy, isEmpty } from "lodash";
import {
  Connection,
  Edge,
  isEdge,
  isNode,
  XYPosition,
} from "react-flow-renderer";
import {
  IAd,
  IInstantExperience,
  InstantExperienceBodyElement,
} from "shared/types/adLibrary";
import {
  DataType,
  EAElements,
  EAFlowElement,
  EAFlowNode,
} from "./useContextAPI";
import { v4 as uuid } from "uuid";
import { Element } from "./useContextAPI";
import API from "services";
import { isUrlInputValid } from "utils/validators";
import {
  isAd,
  isButton,
  isFooter,
  isImage,
  isInstantExp,
} from "utils/adLibrary.validators";
import { DEFAULT_DIMENSION, GAP } from "shared/constants/everythingads";
import { isURLData } from "./useContextAPI.utils";

/**
 * @param elements The latest elements after an operation has completed.
 */
export const updateReferences = (elements: EAElements) => {
  const edges = elements.filter(ele => isEdge(ele)) as Edge[];
  return elements.map(ele => {
    const connectedEdges = edges.filter(edge => edge.source === ele.id);
    const isSourceNode = isNode(ele) && !isEmpty(connectedEdges);
    if (isSourceNode) {
      const { data } = ele;
      const { ie, ad } = data || {};
      if (ie) {
        return {
          ...ele,
          data: {
            ie: {
              ...(ele.data?.ie || {}),
              body_elements: updateBodyElements(
                connectedEdges,
                elements,
                ele.data?.ie?.body_elements,
              ),
            },
          },
        };
      }

      if (ad) {
        const hasValidEdges = connectedEdges.length === 1; // an ad element cannot have 2 different edges to other elements.
        if (!hasValidEdges) return ele;
        const [edge] = connectedEdges;
        const { target } = edge;
        const targetFlowElement = elements.find(e => e.id === target);
        if (!targetFlowElement) return ele;

        const { data } = targetFlowElement;
        const { ie } = data || {};
        if (!ie) return ele;

        return {
          ...ele,
          data: {
            ad: {
              ...ad,
              visuals: {
                ...ad.visuals,
                destination: {
                  instantExperienceId: ie.id,
                },
              },
            },
          },
        };
      }
    }

    return ele;
  });
};

export const updateBodyElements = (
  edges: Edge<any>[],
  elements: EAElements,
  bodyElements?: InstantExperienceBodyElement[],
) => {
  if (!bodyElements) return;

  return bodyElements.map(ele => {
    // find the actual edge that connects between the flow object (FlowImage, FlowCarousel, ...) and the targeting IE instance.
    const theEdge = edges.find(edge => edge.sourceHandle === ele.id);
    if (!theEdge) return ele;

    const targetFlowElement = elements.find(
      flowEle => flowEle.id === theEdge.target,
    );
    if (!targetFlowElement) return ele;

    if (isButton(ele) || isImage(ele)) {
      return {
        ...ele,
        destination: {
          instantExperienceId: targetFlowElement.data?.ie?.id,
        },
      };
    }

    return ele;
  });
};

/******
 * This function is a little helper function to fetch and get an instant experience comveniently.
 * Reason why not directly use API.services.adLibrary.getInstantExperience() is becuase "error" and "result" handling needed to be done and tryint to get rid of it.
 * This function will be used within generateElements() below.
 */
export const fetchInstantExperience = async (id: string) => {
  const { error, result } = await API.services.adLibrary.getInstantExperience(
    id,
  );

  const { instantExperiences } = result || {};
  if (error || !instantExperiences) return;

  const [fetchedInstExp] = instantExperiences as Array<IInstantExperience>;
  if (!fetchedInstExp) return;

  return fetchedInstExp;
};

/**
 * This will generates nodes in the canvas based on the data. The generation of edges will come separately.
 */
type GenerateElementsArgs = {
  element?: Element;
  dataSource: readonly Element[];
  depth: number;
  degree: number;
};

export const generateElements = async (args: GenerateElementsArgs) => {
  const { element, depth, degree, dataSource } = args;
  if (!element) return;

  let result: EAElements = [];

  if (isAd(element)) {
    const instantExperienceId = getValueFromDestination(
      "instantExperienceId",
      element,
    );
    if (!instantExperienceId)
      return [
        createFlowElement({
          element,
          type: "ad",
          position: { x: depth, y: degree },
        }),
      ];

    // Try to find the data in "dataSource", or fetch it if not found.
    const instantExp =
      dataSource
        .filter(isInstantExp)
        .find(inst => inst.id === instantExperienceId) ||
      (await fetchInstantExperience(instantExperienceId));

    const x = depth;
    const y = degree;
    const flowElement = createFlowElement({
      element,
      type: "ad",
      position: { x, y },
    });
    result = result.concat([flowElement]).concat(
      (await generateElements({
        element: instantExp,
        dataSource,
        depth: depth + DEFAULT_DIMENSION.width + GAP,
        degree,
      })) || [],
    );
  } else if (isInstantExp(element)) {
    const { body_elements } = element;
    const hasBodyElements = body_elements && body_elements.length > 0;
    if (!hasBodyElements)
      return [
        createFlowElement({
          element,
          type: "ie",
          position: { x: depth, y: degree },
        }),
      ];

    // add current element first
    result.push(
      createFlowElement({
        element,
        type: "ie",
        position: { x: depth, y: degree },
      }),
    );

    let deg = degree;

    for (const bodyEle of body_elements) {
      // First, skip all body elements that are not connectable.
      if (!isButton(bodyEle) && !isImage(bodyEle) && !isFooter(bodyEle)) {
        deg += DEFAULT_DIMENSION.height;
        continue;
      }

      const elementHasUrlDest =
        (isButton(bodyEle) && bodyEle.destination?.urlLabel) ||
        (isImage(bodyEle) && bodyEle.destination?.urlLabel) ||
        (isFooter(bodyEle) && bodyEle.child_elements[0]?.destination?.urlLabel); // For a Footer type, there will always be one element in "child_elements"

      if (elementHasUrlDest) {
        const urlLabel = getValueFromDestination("urlLabel", bodyEle) || "";
        const isUrl = isUrlInputValid(urlLabel);
        const type = isUrl ? "url" : "label";

        const buttonFlowElement = createFlowElement({
          element: {
            id: uuid(),
            type,
            value: urlLabel,
          },
          position: {
            x: args.depth + DEFAULT_DIMENSION.width + GAP,
            y: deg,
          },
          type: "url",
        });

        result.push(buttonFlowElement);

        deg += DEFAULT_DIMENSION.height + GAP;
        continue;
      }

      // either bodyEle is button element or image element, both must have ie destination.
      const instantExperienceId = getValueFromDestination(
        "instantExperienceId",
        bodyEle,
      );
      if (!instantExperienceId) {
        deg += DEFAULT_DIMENSION.height;
        continue;
      }

      let nextIE = dataSource
        .filter(isInstantExp)
        .find(inst => inst.id === instantExperienceId);
      if (!nextIE) {
        nextIE = await fetchInstantExperience(instantExperienceId);
        if (!nextIE) {
          deg += DEFAULT_DIMENSION.height;
          continue;
        }
      }

      const nextEle = await generateElements({
        element: nextIE,
        dataSource,
        depth: args.depth + DEFAULT_DIMENSION.width + GAP,
        degree: deg,
      });

      if ((nextEle?.length ?? 0) !== 0) {
        result.push(...nextEle!);
      }

      deg += DEFAULT_DIMENSION.height + GAP;
    }
  } else if (isURLData(element)) {
    result.push(
      createFlowElement({
        element,
        position: { x: depth, y: degree },
        type: "url",
      }),
    );
  }

  return result;
};

const getValueFromDestination = (
  key: "instantExperienceId" | "urlLabel",
  ele?: InstantExperienceBodyElement | IAd,
) => {
  if (!ele) return;

  if (isAd(ele))
    return key === "urlLabel"
      ? undefined
      : ele.visuals.destination?.instantExperienceId;
  if (isFooter(ele)) return ele.child_elements?.[0]?.destination?.[key];

  return "destination" in ele ? ele.destination?.[key] : undefined;
};

type CreateFlowElementArgs = {
  element: Element;
  position?: XYPosition;
  type: keyof DataType;
};
export const createFlowElement = (args: CreateFlowElementArgs) => {
  const { type, element, position = { x: 0, y: 0 } } = args;

  return {
    id: uuid(),
    type,
    data: {
      [type]: element,
    },
    position,
  };
};

type GenerateEdgesArgs = {
  elements: EAElements;
};
export const generateEdges = (args: GenerateEdgesArgs) => {
  const { elements } = args;

  return elements.reduce<Array<Edge<any>>>((acc, ele) => {
    const { data } = ele;
    const { ie, ad } = data || {};

    if (ad) {
      const {
        visuals: { destination },
      } = ad as IAd;
      const { instantExperienceId } = destination || {};
      if (!instantExperienceId) return acc;

      const flowIeElement = elements
        .filter(el => el.data?.ie)
        .find(el => el.data?.ie?.id === instantExperienceId);
      if (!flowIeElement) return acc;

      return [
        ...acc,
        {
          id: uuid(),
          source: ele.id,
          sourceHandle: ad.id,
          target: flowIeElement.id,
          type: "deletable",
        },
      ];
    }

    if (ie) {
      const { body_elements: bodyElements = [] } = ie as IInstantExperience;
      const supportingBodyElements = bodyElements.filter(
        bodyEle => isImage(bodyEle) || isButton(bodyEle) || isFooter(bodyEle), // check if it is connectable elements
      );
      if (supportingBodyElements.length === 0) return acc; // if no body_elements, then it is not possbile that this element have edge connection.

      /**
       * The "processedUrlLabelIds" will keep track of those url flow element id that was already processed previously.
       * This will be used in the ".map()" below.
       *
       * Having reference in outer scope like this is little bit hacky but could not find better way of doing this.
       */
      const processedUrlLabelId: string[] = [];
      return [
        ...acc,
        ...supportingBodyElements
          .filter(ele => {
            const instantExperienceId = getValueFromDestination(
              "instantExperienceId",
              ele,
            );
            const targetIeExist = elements.some(
              ele => ele.data?.ie?.id === instantExperienceId,
            );

            const urlLabel = getValueFromDestination("urlLabel", ele) ?? "";
            const targetUrl = elements.some(
              ele => ele.data?.url?.value === urlLabel,
            );

            return targetIeExist || targetUrl; // return only if the body element contains destination.
          })
          .map(bodyEle => {
            const instantExperienceId = getValueFromDestination(
              "instantExperienceId",
              bodyEle,
            );
            const urlLabel = getValueFromDestination("urlLabel", bodyEle);

            const targetFlowElement = instantExperienceId
              ? elements.find(ele => ele.data?.ie?.id === instantExperienceId)
              : elements.find(
                  ele =>
                    !processedUrlLabelId.includes(ele.id) &&
                    ele.data?.url?.value === urlLabel,
                );

            if (urlLabel) {
              processedUrlLabelId.push(targetFlowElement!.id);
            }

            return {
              id: uuid(),
              source: ele.id,
              sourceHandle: bodyEle.id,
              target: targetFlowElement!.id,
              type: "deletable",
            };
          }),
      ];
    }

    return acc; // else
  }, []);
};

/**
 * This function takes an edge and returns connected two elements.
 * NOTE: Based on the fact that the parameter "edge" is passed, we know that there are 2 connected elements exist.
 * @param edge
 * @param elements
 * @returns [EAFlowElement | undefined, EAFlowElement | undefined]
 */
export const getConnectedElements = (
  edge: Edge | Connection,
  elements: EAElements,
): [EAFlowElement | undefined, EAFlowElement | undefined] => {
  const { sourceHandle, target } = edge;
  return [
    findElementByHandleId(sourceHandle || null, elements),
    findElementById(target || null, elements),
  ];
};

export const findElementByHandleId = (
  handleId: string | null,
  elements: EAElements,
) => {
  return elements.find(
    ele =>
      (isNode(ele) &&
        ele.data?.ie?.body_elements?.some(
          bodyEle => bodyEle.id === handleId,
        )) ||
      ele.data?.ad?.id === handleId ||
      ele.data?.url?.id === handleId,
  );
};

export const findElementById = (id: string | null, elements: EAElements) => {
  return elements.find(ele => isNode(ele) && ele.id === id);
};

/**
 * This func suppose to remove all references (instantExperienceId) in all of
 *  body_elements in an IE instance.
 * @param ele
 * @returns
 */
export const removeAllReferences = (ele: EAFlowElement): EAFlowElement => {
  if (!ele.data?.ie) return ele;

  return {
    ...ele,
    data: {
      ie: {
        ...ele.data.ie,
        body_elements: removeBodyElementsDestination(ele.data.ie.body_elements),
      },
    },
  };
};

/**
 * Removing reference, it means removing the reference id in "destination" of the "source" element (it could be button element, image element or an ad type element).
 * @param ele
 * @param handle
 * @returns
 */
export const removeReference = (
  ele: EAFlowElement,
  handle: string | null,
): EAFlowElement => {
  const { data } = ele;
  const { ad, ie } = data || {};
  if (!ad && !ie) return ele;

  if (ad) {
    return {
      ...ele,

      data: {
        ad: {
          ...ad,
          visuals: {
            ...ad.visuals,
            destination: undefined,
          },
        },
      },
    };
  } else {
    return {
      ...ele,
      data: {
        ie: {
          ...ie,
          body_elements: removeBodyElementsDestination(
            ie?.body_elements,
            handle || undefined,
          ),
        },
      },
    };
  }
};

/**
 * If handle is provided, remove destination for that handle only. Otherwise,
 * @param bodyElements
 * @param handle
 */
export const removeBodyElementsDestination = (
  bodyElements?: InstantExperienceBodyElement[],
  handle?: string,
) => {
  if (!bodyElements) return bodyElements;

  return bodyElements.map(bodyEle => {
    if (!handle) {
      return {
        ...bodyEle,
        destination: undefined,
      };
    }

    return bodyEle.id === handle
      ? {
          ...bodyEle,
          destination: undefined,
        }
      : bodyEle;
  });
};

export const getUpdatedElementsOnConnect = (
  edgeParams: Edge | Connection,
  elements: EAElements,
) => {
  const [sourceElement, targetElement] = getConnectedElements(
    edgeParams,
    elements,
  );
  if (!sourceElement || !targetElement || !targetElement.data?.ie?.id) return;

  const { sourceHandle } = edgeParams;
  const updatedElement = updateElementDestination({
    element: sourceElement,
    destination: targetElement.data?.ie?.id,
    sourceHandle: sourceHandle || undefined,
  });

  return [updatedElement];
};

type UpdateElementDestinationArgs = {
  element: EAFlowElement;
  destination: string;
  sourceHandle?: string; // ad type dont need this. Only Ie element
};
const updateElementDestination = (
  args: UpdateElementDestinationArgs,
): EAFlowElement => {
  const { element, destination } = args;

  const { data } = element;
  const { ie, ad } = data || {};
  if (ad) {
    return {
      ...element,
      data: {
        ad: {
          ...ad,
          visuals: {
            ...ad.visuals,
            destination: {
              instantExperienceId: destination,
            },
          },
        },
      },
    };
  }

  if (ie) {
    const { sourceHandle } = args;
    return {
      ...element,
      data: {
        ie: {
          ...ie,
          body_elements: ie.body_elements?.map(bodyEle => {
            if (bodyEle.id !== sourceHandle) return bodyEle;

            return {
              ...bodyEle,
              destination: {
                instantExperienceId: destination,
              },
            };
          }),
        },
      },
    };
  }

  return element;
};

export const getUpdatedElementsOnRemove = (
  removedElements: EAElements,
  elements: EAElements,
) => {
  const removedNodeElements = removedElements.filter(ele => isNode(ele));
  const sourceEdges = removedElements.filter(
    ele =>
      isEdge(ele) &&
      removedNodeElements.map(removedEle => removedEle.id).includes(ele.target),
  ) as Array<Edge>;
  const sourceElements = sourceEdges.reduce<EAElements>((acc, edge) => {
    const [source] = getConnectedElements(edge, elements);
    if (!source) return [...acc];

    const { sourceHandle } = edge;
    return [...acc, removeReference(source, sourceHandle || null)].filter(
      ele => ele,
    ) as EAElements;
  }, []);

  return [
    ...removedNodeElements.map(ele => removeAllReferences(ele)),
    ...sourceElements,
  ];
};

export const getUpdatedElementsOnRemoveEdge = (
  edge: Edge,
  elements: EAElements,
) => {
  const { sourceHandle } = edge;
  const [sourceElement] = getConnectedElements(edge, elements);
  if (!sourceElement || !sourceHandle) return;

  return [removeReference(sourceElement, sourceHandle)];
};

export const getRepositionedElements = (
  elements: EAElements,
  heights: Record<string, number>,
) => {
  const rePositionedElements: EAElements = [];

  const flowElements = elements.filter(ele => isNode(ele)) as Array<EAFlowNode>;

  const group = groupBy(flowElements, flowElement => flowElement.position.x);
  for (const xPos of Object.keys(group)) {
    const elements = group[xPos];
    let y = 0;
    for (const element of elements) {
      const height = heights[element.id];
      rePositionedElements.push({
        ...element,
        position: {
          x: element.position.x,
          y,
        },
      });

      y += height || 0;
    }
  }

  return elements.map(ele => {
    const found = rePositionedElements.find(newEle => newEle.id === ele.id);
    return found ?? ele;
  });
};
