import { Button, message, Modal } from "antd";
import { CSSProperties, FC, memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";

import { ArtboardDrawer } from "./designStudio/ArtboardDrawer";
import EditorV2 from "./designStudio/EditorV2";
import Library from "./designStudio/Library";
import NewStampDrawer from "./designStudio/NewStampDrawer";
import NewTemplateDrawer from "./designStudio/NewTemplateDrawer";

import actions from "redux/rootActions";
import GenericError from "shared/errors/GenericError";
import { IHeader } from "shared/types/header";
import { OperationMode } from "shared/types/inputValues";
import { isFeatureEnabled } from "../utils/helpers";
import { defaultTab, libraryRoute } from "../utils/helpers.designStudio";

import {
  HeaderMenu,
  IArtboard,
  IDesignStudioState,
  IPublishCanvasStatus,
  isStamp,
  IStamp,
  isTemplate,
  ITemplate,
  ITemplateTag,
  TabMenu,
} from "../shared/types/designStudio";

import { isEqual } from "lodash";
import Header from "shared/components/Header";
import { useAppSelector } from "shared/hooks/useAppSelector";
import { useDatadog } from "shared/hooks/useDatadog";
import { useRouteQuery } from "shared/hooks/useRouteQuery";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { DesignStudioV2 } from "./designStudio/DesignStudioV2";
import { DrawerProvider } from "./designStudio/DrawerContext/DrawerContext";
import { TResource } from "./designStudio/Editor.context";

interface IDesignStudio {
  templateToUpdate: ITemplate | null | undefined;
  templateToEditor: IDesignStudioState["templateToEditor"];
  setTemplateToUpdate: (template?: ITemplate) => void;
  publishCanvas: ({
    publishCanvasStatus,
    stamp,
    template,
  }: {
    publishCanvasStatus: IPublishCanvasStatus;
    stamp?: IStamp;
    template?: ITemplate;
  }) => void;
  publishCanvasStatus: IPublishCanvasStatus | null;

  error: GenericError | null;

  stampToUpdate?: null | IStamp;
  setStampToUpdate: (stamp?: IStamp) => void;
  setArtboardToUpdate: (artboard?: IArtboard) => void;
  artboardToUpdate: IArtboard | null | undefined;
  stampToEditor: IDesignStudioState["stampToEditor"];

  filterTemplateOem: (filter: string[] | null) => void;
  filterTemplateStore: (filter: string[] | null) => void;
  filterTemplateTags: (tags: string[] | null) => void;
  filterTemplateOfferCounts: (count: string) => void;

  filterStampOem: (filter: string[] | null) => void;
  filterStampState: (filter: string[] | null) => void;
  filterStampDescriptor: (tags: string[] | null) => void;

  tags: ITemplateTag[];
  savingDraft: IDesignStudioState["savingDraft"];
  fireSaveDraft: () => void;
}

const useGetMatch = () => {
  const templatesMatch = useMatch("/design-studio/library/templates");
  const stampsMatch = useMatch("/design-studio/library/stamps");
  const artboardsMatch = useMatch("/design-studio/library/artboards");

  const templatesEditorV2Match = useMatch(
    "/design-studio/editor/templates/:targetId/editor-v2",
  );
  const stampsEditorV2Match = useMatch(
    "/design-studio/editor/stamps/:targetId/editor-v2",
  );
  const deletedTemplatesMatch = useMatch("/design-studio/deleted/templates");
  const deletedStampsMatch = useMatch("/design-studio/deleted/stamps");

  let selectedTab: TabMenu = "templates";
  let selectedHeader: HeaderMenu = "LIBRARY";

  if (templatesMatch || stampsMatch || artboardsMatch) {
    selectedHeader = "LIBRARY";
  } else if (templatesEditorV2Match || stampsEditorV2Match) {
    selectedHeader = "EDITOR";
  } else if (deletedTemplatesMatch || deletedStampsMatch) {
    selectedHeader = "DELETED ASSETS";
  }

  if (templatesMatch || templatesEditorV2Match || deletedTemplatesMatch) {
    selectedTab = "templates";
  } else if (stampsMatch || stampsEditorV2Match || deletedStampsMatch) {
    selectedTab = "stamps";
  } else if (artboardsMatch) {
    selectedTab = "artboards";
  }

  return { selectedHeader, selectedTab };
};

export type DesignStudioDrawerState = {
  show: boolean;
  mode: OperationMode;
  type: "template" | "stamp" | "artboard";
} | null;

const DesignStudio: FC<IDesignStudio> = ({
  templateToUpdate,
  templateToEditor,
  setTemplateToUpdate,
  error,
  publishCanvas,
  publishCanvasStatus,
  stampToUpdate,
  stampToEditor,
  setStampToUpdate,
  filterTemplateOem,
  filterTemplateStore,
  filterTemplateOfferCounts,
  tags,
  filterTemplateTags,

  filterStampOem,
  filterStampState,
  filterStampDescriptor,
  savingDraft,
  fireSaveDraft,
  artboardToUpdate,
  setArtboardToUpdate,
}) => {
  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  const isAdmin = useIsAdmin();

  const { selectedHeader, selectedTab } = useGetMatch();

  const [drawer, setDrawer] = useState<DesignStudioDrawerState>(null);

  const [resource, setCurrentResource] = useState<TResource>();
  const [shouldBlockFromNavigateAway, setShouldBlockFromNavigateAway] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const isFromAdEngine = useRouteQuery("from") === "ad-engine";
  const disabledPublishButton = useAppSelector(
    state => state.designStudio.disabledPublishButton,
  );
  const disabledFromAdEngine = isFromAdEngine && disabledPublishButton;

  useDatadog();

  useEffect(() => {
    // the template being updated is set in the redux store from Card.tsx
    // However, since the NewTemplateDrawer.tsx is defined in this component, we need to know when and what to edit.
    // NOTE: This templateToUpdate will be cleared if drawer is closed.
    if (templateToUpdate) {
      setDrawer({
        show: selectedHeader !== "EDITOR",
        mode: "UPDATE",
        type: "template",
      });
    }

    if (stampToUpdate) {
      setDrawer({
        show: selectedHeader !== "EDITOR",
        mode: "UPDATE",
        type: "stamp",
      });
    }

    if (artboardToUpdate) {
      setDrawer({
        show: selectedHeader !== "EDITOR",
        mode: "UPDATE",
        type: "artboard",
      });
    }
  }, [templateToUpdate, stampToUpdate, selectedHeader, artboardToUpdate]);

  let actionButtons;
  const isSaving = savingDraft !== null && savingDraft === "SAVE_FIRED";

  const openCreateDrawer = (type: "template" | "stamp" | "artboard") => {
    setDrawer({
      show: true,
      mode: "CREATE",
      type,
    });
  };

  const closeDrawer = () => {
    setDrawer(null);
  };

  const getStyleForButton = (disabled: boolean): CSSProperties => {
    if (disabled) return { textTransform: "uppercase", height: "60%" };
    return {};
  };

  const classNameForSaveDraft = isFromAdEngine ? undefined : "action-button";

  switch (selectedHeader) {
    case "LIBRARY":
      if (selectedTab === "stamps") {
        actionButtons = [
          <Button
            key="new-stamp-action-button"
            className="action-button"
            disabled={!isAdmin}
            onClick={() => openCreateDrawer("stamp")}
          >
            New Stamp
          </Button>,
        ];
      } else if (selectedTab === "templates") {
        actionButtons = [
          <Button
            key="new-template-action-button"
            className="action-button new-template-button"
            disabled={!isAdmin}
            onClick={() => openCreateDrawer("template")}
          >
            New Template
          </Button>,
        ];
      } else if (selectedTab === "artboards") {
        actionButtons = [
          <Button
            key="new-artboard-action-button"
            className="action-button"
            disabled={!isAdmin}
            onClick={() => openCreateDrawer("artboard")}
          >
            New Artboard
          </Button>,
        ];
      } else {
        throw new Error(`Selected tab in ${selectedTab} is not valid.`);
      }

      break;

    case "EDITOR":
      actionButtons = [
        selectedTab === "templates" ? (
          <Button
            key="editor-action-button-2"
            className={classNameForSaveDraft}
            style={getStyleForButton(isFromAdEngine)}
            loading={isSaving}
            disabled={isFromAdEngine}
            onClick={() => {
              // fire save template draft
              fireSaveDraft();
            }}
          >
            Save Draft
          </Button>
        ) : null,
        <Button
          key="editor-action-button"
          className={disabledFromAdEngine ? undefined : "action-button"}
          style={getStyleForButton(disabledFromAdEngine)}
          loading={
            publishCanvasStatus !== null &&
            publishCanvasStatus.status === "BEGIN"
          }
          disabled={disabledFromAdEngine}
          onClick={
            selectedTab === "templates"
              ? () => {
                  // template publish
                  publishCanvas({
                    publishCanvasStatus: {
                      type: "templates",
                      status: "BEGIN",
                    },
                  });
                }
              : () => {
                  // stamp publish
                  publishCanvas({
                    publishCanvasStatus: {
                      type: "stamps",
                      status: "BEGIN",
                    },
                  });
                }
          }
        >
          Publish
        </Button>,
      ];
      break;

    case "DELETED ASSETS":
      actionButtons = [
        <Button
          key="exit-action-button"
          className="action-button"
          onClick={() => {
            navigate(
              "/design-studio/library/:targetTab".replace(
                ":targetTab",
                selectedTab,
              ) + search,
            );
          }}
        >
          EXIT
        </Button>,
      ];

      break;
    default:
      throw new Error(
        "The header menu in Design Studio must be selected when page renders.",
      );
  }

  const promptMessage = `${
    templateToEditor ? templateToEditor.name : stampToEditor?.name || "A stamp"
  } is being processed right now. Are you sure you want to exit?`;

  const shouldDisplayPrompt =
    publishCanvasStatus?.status === "BEGIN" || savingDraft === "IN_PROGRESS";

  const headerMenus: IHeader = {
    style: {
      display: "flex",
      alignItem: "center",
      flex: "initial",
      width: isFeatureEnabled("ENABLE_DESIGN_STUDIO_V2") ? "0" : "20em",
    },
    topSteps: {
      selected: selectedHeader,
      style: {
        flex: 2,
      },
      steps: [
        isFeatureEnabled("ENABLE_DESIGN_STUDIO_V2")
          ? {}
          : {
              title: selectedHeader === "DELETED ASSETS" ? "BACK" : "LIBRARY",
              onClick: () => {
                if (shouldDisplayPrompt) {
                  // preventing navigating away while publishing/saving_draft is done here and in <Prompt></Prompt> at the bottom of this component
                  Modal.confirm({
                    title: "Job has not finished",
                    content: promptMessage,
                  });
                  return;
                }

                // NOTE: At the time of writing this code, this only has effects on canvas v2.
                if (shouldBlockFromNavigateAway) {
                  const message = `${
                    isTemplate(resource)
                      ? resource.name
                      : isStamp(resource)
                      ? resource.name
                      : "An asset"
                  } is being processed right now. Are you sure you want to exit?`;
                  Modal.confirm({
                    title: "Are you sure you want to exit?",
                    content: message,
                    onOk: () => {
                      setShouldBlockFromNavigateAway(false);
                      navigate(
                        "/design-studio/library/:targetTab".replace(
                          ":targetTab",
                          selectedTab,
                        ) + search,
                      );
                    },
                  });

                  return;
                }

                setTemplateToUpdate();
                setStampToUpdate();
                navigate(libraryRoute(selectedHeader, selectedTab) + search);
              },
              state: "enabled",
            },
        {
          title:
            selectedHeader === "DELETED ASSETS" ? "DELETED ASSETS" : "EDITOR",
          state: selectedHeader !== "EDITOR" ? "disabled" : "enabled",
        },
      ],
    },
    actionButtons,
  };

  const isIndex = useMatch("/design-studio");
  const isLibrary = useMatch("/design-studio/library");
  const showDesignStudioHeader = isIndex || isLibrary;

  return (
    <div className="design-studio-root-container">
      {!showDesignStudioHeader && (
        <Header
          style={headerMenus.style}
          topSteps={headerMenus.topSteps}
          actionButtons={headerMenus.actionButtons}
        />
      )}
      <Routes>
        <Route
          index
          element={
            isFeatureEnabled("ENABLE_DESIGN_STUDIO_V2") ? (
              <DesignStudioV2 />
            ) : (
              <Navigate to={defaultTab()} replace />
            )
          }
        />
        <Route
          path="library/templates"
          element={
            <Library
              selectedTab={selectedTab}
              selectedHeader={selectedHeader}
              filterTemplateOem={filterTemplateOem}
              filterTemplateStore={filterTemplateStore}
              filterTemplateTags={filterTemplateTags}
              filterTemplateOfferCounts={filterTemplateOfferCounts}
              tags={tags}
              filterStampOem={filterStampOem}
              filterStampState={filterStampState}
              filterStampDescriptor={filterStampDescriptor}
              onArtboardEdit={(artboard: IArtboard) => {
                setArtboardToUpdate(artboard);
                setDrawer({ type: "artboard", show: true, mode: "UPDATE" });
              }}
            />
          }
        />
        <Route
          path="library/stamps"
          element={
            <Library
              selectedTab={selectedTab}
              selectedHeader={selectedHeader}
              filterTemplateOem={filterTemplateOem}
              filterTemplateStore={filterTemplateStore}
              filterTemplateTags={filterTemplateTags}
              filterTemplateOfferCounts={filterTemplateOfferCounts}
              tags={tags}
              filterStampOem={filterStampOem}
              filterStampState={filterStampState}
              filterStampDescriptor={filterStampDescriptor}
              onArtboardEdit={(artboard: IArtboard) => {
                setArtboardToUpdate(artboard);
                setDrawer({ type: "artboard", show: true, mode: "UPDATE" });
              }}
            />
          }
        />
        <Route
          path="library/artboards"
          element={
            <Library
              selectedTab={selectedTab}
              selectedHeader={selectedHeader}
              filterTemplateOem={filterTemplateOem}
              filterTemplateStore={filterTemplateStore}
              filterTemplateTags={filterTemplateTags}
              filterTemplateOfferCounts={filterTemplateOfferCounts}
              tags={tags}
              filterStampOem={filterStampOem}
              filterStampState={filterStampState}
              filterStampDescriptor={filterStampDescriptor}
              onArtboardEdit={(artboard: IArtboard) => {
                setArtboardToUpdate(artboard);
                setDrawer({ type: "artboard", show: true, mode: "UPDATE" });
              }}
            />
          }
        />
        <Route
          path="deleted/:targetTab"
          element={
            <Library
              selectedTab={selectedTab}
              selectedHeader={selectedHeader}
              filterTemplateOem={filterTemplateOem}
              filterTemplateStore={filterTemplateStore}
              filterTemplateTags={filterTemplateTags}
              filterTemplateOfferCounts={filterTemplateOfferCounts}
              tags={tags}
              filterStampOem={filterStampOem}
              filterStampState={filterStampState}
              filterStampDescriptor={filterStampDescriptor}
              onArtboardEdit={(artboard: IArtboard) => {
                setArtboardToUpdate(artboard);
                setDrawer({ type: "artboard", show: true, mode: "UPDATE" });
              }}
            />
          }
        />

        <Route
          path="editor/:targetTab/:targetId/editor-v2"
          element={
            <EditorV2
              selectedHeader={selectedHeader}
              selectedTab={selectedTab}
              updateShouldBlockFromNavigateAway={(block, resource) => {
                setCurrentResource(resource);
                setShouldBlockFromNavigateAway(block);
              }}
            />
          }
        />
      </Routes>
      <DrawerProvider
        artboardToEdit={artboardToUpdate}
        mode={drawer?.type === "artboard" ? drawer.mode : undefined}
        resetArtboardToEdit={setArtboardToUpdate}
        closeDrawer={closeDrawer}
      >
        <ArtboardDrawer
          showArtboardDrawer={drawer?.type === "artboard" && drawer?.show}
        />
      </DrawerProvider>

      <NewTemplateDrawer
        mode={drawer?.type === "template" ? drawer.mode : undefined}
        showNewTemplateDrawer={drawer?.type === "template" && drawer.show}
        closeDrawer={closeDrawer}
      />

      <NewStampDrawer
        mode={drawer?.type === "stamp" ? drawer.mode : undefined}
        showNewStampDrawer={drawer?.type === "stamp" && drawer.show}
        closeDrawer={closeDrawer}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const { designStudio, oemManagement, dealerManagement } = state;
  const {
    error,
    templateToUpdate,
    artboardToUpdate,
    templateToEditor,
    publishCanvasStatus,
    stampToUpdate,
    stampToEditor,
    tags,
    savingDraft,
  } = designStudio as IDesignStudioState;

  const { dealerRecords } = dealerManagement;

  const { oemRecords, result } = oemManagement;

  return {
    error,
    templateToUpdate,
    artboardToUpdate,
    templateToEditor,
    publishCanvasStatus,
    stampToUpdate,
    stampToEditor,
    oemRecords,
    dealerRecords,
    tags,
    oemsResult: result,
    savingDraft,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    publishCanvas: ({
      publishCanvasStatus,
      stamp,
      template,
    }: {
      publishCanvasStatus: IPublishCanvasStatus;
      stamp?: IStamp;
      template?: ITemplate;
    }) => {
      dispatch(
        actions.designStudio.publishCanvas({
          publishCanvasStatus,
          stamp: stamp || null,
          template: template || null,
          canvasJson: null,
          base64Thumbnail: null,
        }),
      );
    },
    setTemplateToUpdate: (template?: ITemplate) => {
      dispatch(actions.designStudio.setTemplateToUpdate(template));
    },
    setStampToUpdate: (stamp?: IStamp) => {
      dispatch(actions.designStudio.setStampToUpdate(stamp));
    },
    setArtboardToUpdate: (artboard?: IArtboard) => {
      dispatch(actions.designStudio.setArtboardToUpdate(artboard));
    },
    getOems: (paginationToken = "") => {
      dispatch(actions.oemManagement.getOems(paginationToken));
    },
    getDealers: () => {
      dispatch(actions.dealerManagement.getDealers());
    },
    filterTemplateOem: (filter: string[] | null) => {
      dispatch(actions.designStudio.filterTemplateOem(filter));
    },
    filterTemplateStore: (filter: string[] | null) => {
      dispatch(actions.designStudio.filterTemplateStore(filter));
    },
    filterTemplateTags: (tags: string[] | null) => {
      dispatch(actions.designStudio.filterTemplateTags(tags));
    },
    filterTemplateOfferCounts: (count: string) => {
      dispatch(actions.designStudio.filterTemplateOfferCounts(count));
    },
    filterStampOem: (tags: string[] | null) => {
      dispatch(actions.designStudio.filterStampOem(tags));
    },
    filterStampState: (tags: string[] | null) => {
      dispatch(actions.designStudio.filterStampState(tags));
    },
    filterStampDescriptor: (tags: string[] | null) => {
      dispatch(actions.designStudio.filterStampDescriptor(tags));
    },
    fireSaveDraft: () => {
      dispatch(actions.designStudio.fireSaveDraft());
    },
  };
};

const areEqual = (prev: IDesignStudio, next: IDesignStudio): boolean => {
  return isEqual(prev, next);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(DesignStudio, areEqual));
