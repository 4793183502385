export const adsImportCSVTemplateUrl =
  "https://alexia-share.s3.amazonaws.com/Automatic+Ads+Uploader+-+Template.csv";

export const adEngineAssetBuilderUrl =
  "https://alexia-share.s3.amazonaws.com/asset_builder_template.csv";

export const adEngineFBAdsUrl =
  "https://alexia-share.s3.amazonaws.com/facebook_ads_template.csv";

export const adEngineFBAutoUrl =
  "https://alexia-share.s3.amazonaws.com/fb_catalog_vehicle_offer.csv";

export const adEngineFBProductUrl =
  "https://alexia-share.s3.amazonaws.com/fb_catalog_products.csv";

export const adEngineGoogleMerchantUrl =
  "https://alexia-share.s3.amazonaws.com/google_merchant_center.csv";

export const adEnginePinterestProductUrl =
  "https://alexia-share.s3.amazonaws.com/pinterest_product_sample_csv_feed.csv";

export const adEngineSnapchatProductUrl =
  "https://alexia-share.s3.amazonaws.com/snapchat_catalog_reqs.csv";

export const adEngineTiktokProductUrl =
  "https://alexia-share.s3.amazonaws.com/tiktok_catalogues_template.csv";

export const alexiaAppId = "1480296522041789";

export const contentfulDeliveryToken =
  "m5jY6EZLUhkcxlKN5W-Nfqyttg9PVILiDj0mRJWyDzs";

export const contentfulEnvironment = "dev";

export const contentfulSpaceId = "jsuuldtjtvfa";

export const dynamicFontMappingsUrl =
  "https://alexia-fonts.s3.amazonaws.com/mappings.json";

export const facebookVersion = "v15.0";

export const ftpSecretKey = "pxFkaK4tCcXcavZAry2hh";

export const instantExperiencesImportCSVTemplateUrl =
  "https://alexia-share.s3.amazonaws.com/Automatic+Instant+Experiences+Uploader.csv";

export const shouldEnableNotifications = false;
