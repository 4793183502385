import { datadogRum } from "@datadog/browser-rum";

export const isDataDogEnabled = process.env.REACT_APP_ENV === "prd";

export const initDatadog = () => {
  if (!isDataDogEnabled) return;

  datadogRum.init({
    applicationId: process.env.REACT_APP_DD_RUM_ID!,
    clientToken: process.env.REACT_APP_DD_RUM_TOKEN!,
    site: "datadoghq.com",
    env: process.env.REACT_APP_ENV!,
    service: process.env.REACT_APP_DD_RUM_SERVICE!,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    enableExperimentalFeatures: ["clickmap"],
  });
};
