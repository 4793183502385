import { DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { Menu, Modal, message } from "antd";
import { useDeleteFolderMutation } from "redux/media/media.api";
import { useMediaActions } from "redux/media/media.slice";
import { handleApiError } from "../handleApiError";
import { useFetchPerms } from "../useFetchPerms";

export const useMenuFolder = (record: WDFolder, visible: boolean) => {
  const { setEditableRow } = useMediaActions();
  const { canRename, canDelete } = useFetchPerms([record.id], visible);

  return (
    <Menu>
      <Menu.Item
        icon={<FormOutlined />}
        disabled={!canRename}
        key="rename"
        onClick={() => {
          setEditableRow(record);
        }}
      >
        Rename
      </Menu.Item>
      <Menu.Item
        disabled={!canDelete}
        key="delete"
        icon={<DeleteOutlined />}
        onClick={useDeleteFolder(record)}
      >
        Delete folder
      </Menu.Item>
    </Menu>
  );
};

const useDeleteFolder = (record: WDFolder) => {
  const [deleteFolder] = useDeleteFolderMutation();

  return () =>
    Modal.confirm({
      title: `Delete Folder`,
      okText: "Delete",
      okButtonProps: {
        danger: true,
      },
      content: (
        <p>
          <strong>IMPORTANT: This folder can contain resources.</strong>
          <br />
          Are you sure you want to delete <strong>{record.name}</strong>?
        </p>
      ),
      async onOk() {
        try {
          await deleteFolder(record.id).unwrap();
          message.success(`Folder was deleted.`);
        } catch (error) {
          handleApiError(error);
        }
      },
    });
};
