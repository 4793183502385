import { memo, useState } from "react";
import { Form, Typography } from "antd";

import MediaUpload from "../shared/MediaUpload";
import Layout from "../shared/Layout";
import Destination, {
  DestinationType,
  IDestinationValues,
} from "../shared/Destination";
import { IUploadMediaUrls } from "utils/uploadMedia";
import { InstantExperienceElementStyle } from "shared/types/adLibrary";
import { useForm } from "antd/lib/form/Form";

export interface IImageComponentForm {
  layoutOption?: InstantExperienceElementStyle;
  destination?: DestinationType;
  destinationValues?: IDestinationValues;
  fileUrls?: IUploadMediaUrls;
}

interface IProps {
  initialValues: IImageComponentForm;
  destination: DestinationType;
  setDestination(destination: "website" | "instantExperience"): void;
  selectedBrand?: string;
  setSelectedBrand(brandId: string): void;
  onValuesChange(values?: IImageComponentForm): void;
  forceValidations?: boolean;
  hideLayoutOption?: boolean;
  destinationRequired?: boolean;
  hideTitle?: boolean;
}

const ImageComponentForm = ({
  destination,
  setDestination,
  setSelectedBrand,
  selectedBrand,
  initialValues,
  onValuesChange,
  hideLayoutOption,
  hideTitle,
  forceValidations,
  destinationRequired,
}: IProps) => {
  const [form] = useForm();
  const [layoutOption, setLayoutOption] = useState(initialValues.layoutOption);
  return (
    <Form<IImageComponentForm>
      form={form}
      layout="vertical"
      requiredMark={"optional"}
      initialValues={initialValues}
      onValuesChange={(_, allValues) => onValuesChange(allValues)}
    >
      {!hideTitle && (
        <Form.Item style={{ maxWidth: 362 }}>
          <Typography.Title level={4}>Image</Typography.Title>
        </Form.Item>
      )}
      <Form.Item
        name="fileUrls"
        valuePropName="fileUrls"
        trigger="setFileUrls"
        required
      >
        <MediaUpload mediaType="image" validateRequired={forceValidations} />
      </Form.Item>
      {!hideLayoutOption && (
        <Form.Item name="layoutOption" valuePropName="layoutOption">
          <Layout elementType="PHOTO" onChange={setLayoutOption} />
        </Form.Item>
      )}
      {layoutOption &&
        layoutOption === InstantExperienceElementStyle.FIT_TO_WIDTH && (
          <Form.Item name="destinationValues" valuePropName="destinationValues">
            <Destination
              destination={destination}
              selectedBrand={selectedBrand}
              setDestination={setDestination}
              setSelectedBrand={setSelectedBrand}
              shouldValidate={forceValidations}
              required={destinationRequired}
              showAsterisk={true}
            />
          </Form.Item>
        )}
    </Form>
  );
};

export default memo(ImageComponentForm);
