import { Input, DatePicker, Select, FormInstance, Form } from "antd";
import { memo, useState } from "react";
import MultipleBrandSelect from "shared/components/MultipleBrandSelect";
import { dateFormat } from "shared/constants/dataManagement";
import { IAdForm } from "shared/types/adLibrary";
import { AdType } from "../../facebookUtils/types";
import { isEnvVarEquals } from "utils/helpers";
import EditableAdToLoadUrlText from "screens/adLibrary/adLoad/adLoadDrawer/shared/components/adsToLoadTable/EditableAdToLoadUrlText";
import { months } from "utils/helpers.date";
import { IndustryType } from "shared/types/shared";
import styles from "./InputParameters.module.scss";
import { useStoreLabels } from "shared/hooks/useStoreLabels";
import { Rule } from "antd/lib/form";
import { useDestinationURLValidator } from "screens/adLibrary/shared/hooks/useDestinationURLValidator";

interface IProps {
  form: FormInstance<IAdForm>;
  clientType: IndustryType;
  adType: AdType;
}

const InputParameters = ({ form, clientType, adType }: IProps) => {
  const { allStoreLabels } = useStoreLabels();
  const isBearaby = isEnvVarEquals("CLIENT", "bearaby");
  const isAuto = clientType === "auto";
  const isAll = clientType === "all";
  const showAuto = isAuto || isAll;
  const showRetail = !isAuto || isAll;
  const [inputParameters, setInputParameters] = useState({
    ...form.getFieldValue("inputParameters"),
  });

  const { isValidDestinationURL } = useDestinationURLValidator();

  const validateDestinationURL = (_: Rule, value: string | undefined) => {
    if (isValidDestinationURL(value)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error("Please use “https://” or “http://” to format url correctly"),
    );
  };

  const handleBrandChange = (selectedBrands: string[]) => {
    setInputParameters({
      ...inputParameters,
      oems: selectedBrands,
    });
    form.setFieldsValue({
      inputParameters: {
        ...form.getFieldValue("inputParameters"),
        oems: selectedBrands,
      },
    });
  };

  return (
    <div className={styles.inputParameters}>
      <Form.Item name={["inputParameters", "tags"]} label="Tags">
        <Select
          mode="tags"
          data-cy="tags-input"
          filterOption={inputValue => !!inputValue?.trim()}
        />
      </Form.Item>
      <Form.Item name={["inputParameters", "audiences"]} label="Audience(s)">
        <Select mode="tags" data-cy="audience-input" />
      </Form.Item>
      <Form.Item
        name={["inputParameters", "campaignRangeDate"]}
        label="Campaign - In Market Dates"
      >
        <DatePicker.RangePicker
          data-cy="in-market-dates-range-picker"
          format={dateFormat}
          style={{ width: "100%" }}
        />
      </Form.Item>
      {showRetail && (
        <Form.Item name={["inputParameters", "version"]} label="Version">
          <Input />
        </Form.Item>
      )}
      <Form.Item name={["inputParameters", "utm"]} label="UTM">
        <Input data-cy="utm-input" />
      </Form.Item>
      {![AdType.InstantExperience, AdType.Carousel, AdType.Collection].includes(
        adType,
      ) && (
        <Form.Item
          name={["inputParameters", "destinationUrl"]}
          data-cy="input-parameters-destination-url-item"
          label="Destination URL"
          valuePropName="linkValue"
          rules={[
            {
              validator: validateDestinationURL,
            },
          ]}
        >
          <EditableAdToLoadUrlText
            showInputOnly
            urlLabels={allStoreLabels}
            disableLabelPopulation
            willCheckFullUrl={false}
          />
        </Form.Item>
      )}
      <Form.Item
        name={["inputParameters", "oems"]}
        data-cy="input-parameters-oem-item"
        label={"Brand"}
      >
        <MultipleBrandSelect
          selectedBrands={inputParameters.oems}
          onChange={handleBrandChange}
        />
      </Form.Item>
      {showAuto ? (
        <div className="form-group">
          <Form.Item
            name={["inputParameters", "models", 0, "year"]}
            label="Model Year"
            style={{ width: "50%" }}
          >
            <Input data-cy="model-year-input" />
          </Form.Item>
          <Form.Item
            name={["inputParameters", "models", 0, "name"]}
            label="Model Name"
            style={{ width: "50%" }}
          >
            <Input data-cy="model-name-input" />
          </Form.Item>
        </div>
      ) : (
        <Form.Item name={["inputParameters", "year"]} label="Year">
          <Input data-cy="year-input" />
        </Form.Item>
      )}
      <Form.Item name={["inputParameters", "month"]} label="Month">
        <Select mode="multiple" maxTagCount="responsive" data-cy="month-select">
          {months.map(month => (
            <Select.Option
              key={month.toLowerCase()}
              value={month}
              data-cy={`option-${month}`}
            >
              {month}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {showAuto ? (
        <Form.Item name={["inputParameters", "package"]} label="Packages">
          <Select allowClear mode="multiple" data-cy="packages-select">
            {["A", "B", "C", "D"].map(value => (
              <Select.Option
                key={value}
                value={value}
                data-cy={`option-${value}`}
              >
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <Form.Item name={["inputParameters", "strategy"]} label="Strategy">
          <Select data-cy="strategy-select">
            {["Prospecting", "Retargeting"].map(value => (
              <Select.Option
                key={value.toLowerCase()}
                value={value}
                data-cy={`option-${value}`}
              >
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {showRetail && (
        <Form.Item
          name={["inputParameters", "storyPlacement"]}
          label="Story Placement"
        >
          <Select data-cy="story-placement-select">
            {["Yes", "No"].map(value => (
              <Select.Option
                key={value.toLowerCase()}
                value={value}
                data-cy={`option-${value}`}
              >
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {isBearaby && (
        <Form.Item
          name={["inputParameters", "instagramLink"]}
          label="Instagram Links"
        >
          <Input data-cy="instagram-links-input" />
        </Form.Item>
      )}
    </div>
  );
};

export default memo(InputParameters);
