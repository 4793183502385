import { PlayCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useCallback, useRef, useState } from "react";
import { useAssetBatchesRenderContext } from "../../contexts/AssetBatchesRenderContext";
import styles from "./PlaybackControls.module.scss";
import { getVideoData } from "./utils";

const PlaybackControls = () => {
  const { canvas } = useAssetBatchesRenderContext();
  const [playing, setPlaying] = useState(false);
  const animFrameRequest = useRef<number>(0);
  const playStatus = useRef(false);

  const updateVideoElements = useCallback(
    (isPlaying: boolean) => {
      const videos = getVideoData(canvas);
      videos.forEach(element => {
        const video = element.getElement() as HTMLVideoElement;
        if (isPlaying) {
          video.play();
        } else {
          video.pause();
          video.currentTime = 0;
        }
      });
    },
    [canvas],
  );

  const resetFrames = useCallback(() => {
    const videos = getVideoData(canvas);
    updateVideoElements(false);
    videos.forEach(video => {
      (video as any).videoTime = 0;
      canvas?.requestRenderAll();
    });
    cancelAnimationFrame(animFrameRequest.current);
    playStatus.current = false;
    setPlaying(false);
  }, [canvas, updateVideoElements]);

  const playFrames = useCallback(() => {
    if (!playStatus.current) return;
    const videos = getVideoData(canvas);
    if (!videos) {
      return;
    }
    const rendering = videos.map(video => {
      const videoEle = video.getElement() as HTMLVideoElement;
      const maxTime = videoEle.duration;
      if (videoEle.currentTime >= maxTime - 0.1) {
        return false;
      }
      canvas?.requestRenderAll();
      (video as any).videoTime = videoEle.currentTime;
      return true;
    });
    if (rendering.filter(Boolean).length !== 0) {
      requestAnimationFrame(() => {
        playFrames();
      });
      return;
    }
    resetFrames();
  }, [canvas, resetFrames]);

  const togglePlaying = useCallback(
    (isPlaying: boolean) => {
      setPlaying(isPlaying);
      updateVideoElements(isPlaying);
      if (isPlaying) {
        playStatus.current = true;
        animFrameRequest.current = requestAnimationFrame(() => {
          playFrames();
        });
        return;
      }
      resetFrames();
    },
    [playFrames, resetFrames, updateVideoElements],
  );

  return (
    <div className={styles.container}>
      <Button
        className={styles.playButton}
        type="link"
        icon={
          playing ? (
            <div className={styles.stopButton}>
              <div className={styles.stopButtonBox} />
            </div>
          ) : (
            <PlayCircleOutlined className={styles.playButton} />
          )
        }
        onClick={() => togglePlaying(!playing)}
      />
    </div>
  );
};

export default PlaybackControls;
