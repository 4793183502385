/* eslint-disable react/display-name */
import { Key, useMemo, useState } from "react";
import { Badge, Form, Input, Spin, Table, Tree, notification } from "antd";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import styles from "./CreateNewAssetGroups.module.scss";
import useGoogleAdsAccounts from "screens/adLibrary/shared/hooks/useGoogleAdsAccounts";
import { IAssetGroup } from "shared/types/adLibrary";
import { TreeProps } from "antd/lib/tree";
import usePmaxTreeDataNodes, {
  DataNodeKeyType,
} from "screens/adLibrary/shared/hooks/usePmaxTreeDataNodes";
import MultipleStoreSelect from "shared/components/MultipleAccountSelect";
import { TableFiltersSection } from "shared/components/TableFiltersSection";
import { intersectionWith } from "lodash";
import { terms } from "utils/terms";
import { isUnableToLoad } from "shared/components/contextAPI/pmaxAssetGroup/PmaxDataProvider.utils";
import { getThumbnailImage } from "../utils";
import { ThumbnailCell } from "shared/components/ThumbnailCell";
import { IAccount } from "shared/types/accountManagement";

const cantSelectStoreNotification = (description: string) => {
  notification.warning({
    message: "Could not select store",
    description,
  });
};

const CreateNewAssetGroups = () => {
  const {
    assetGroupSelection,
    getComputedSelection,
    selectedDataSource,
    isLoadDrawerOpen,
    loadDrawerWillClose,
    isLoading,
    setDrawerSelection,
  } = usePMaxDataContext();
  const { data: adAccounts, isLoading: isLoadingGoogleAdsAccounts } =
    useGoogleAdsAccounts();
  const columns = [
    {
      width: 10,
      title: <Badge color="gray" />,
      dataIndex: "status",
      key: "status",
      render: () => {
        return <Badge status="error" />;
      },
    },
    {
      title: "Asset group name",
      dataIndex: "name",
      key: "name",
      render: (name: string, assetGroup: IAssetGroup) => (
        <ThumbnailCell name={name} image={getThumbnailImage(assetGroup)} />
      ),
    },
  ];
  const [form] = Form.useForm();

  const [isFiltered, setIsFiltered] = useState(false);

  const onSelectStore = (dealerName: string, stores?: IAccount[]) => {
    const storeToAdd = stores?.find(
      dealer => dealer.dealer_name === dealerName,
    );
    const foundAdAccount = adAccounts?.find(
      acc => acc.id === storeToAdd?.details?.google?.adAccountCID,
    );

    if (!storeToAdd || !foundAdAccount) {
      cantSelectStoreNotification(
        "The chosen store does not have an associated Google Ad Account",
      );
      return;
    }

    const hasDuplicates = assetGroupSelection.selection
      .map(s => s.adAccount.id)
      .includes(foundAdAccount.id);

    if (hasDuplicates) {
      cantSelectStoreNotification("Duplicate Google Ad Accounts found.");
      return;
    }
    // remove this if block to enable back multi-select store
    if (assetGroupSelection.selection?.length) {
      assetGroupSelection.selection.map(selected =>
        assetGroupSelection.removeAccount(selected.dealerName),
      );
    }

    assetGroupSelection.addAccount({
      dealerName: storeToAdd.dealer_name,
      adAccount: foundAdAccount,
    });
  };

  const onDeselectStore = (dealerName: string) =>
    assetGroupSelection.removeAccount(dealerName);

  const [searchBy, setSearchBy] = useState("");

  const computedSelection = useMemo(
    () => getComputedSelection("new"),
    [getComputedSelection],
  );

  const [drawerAnimationDone, setDrawerAnimationDone] = useState(false);
  setTimeout(() => setDrawerAnimationDone(true), 500);

  const { dataNodes: treeDataNodes } = usePmaxTreeDataNodes({
    adAccounts: assetGroupSelection.selection.map(s => s.adAccount),
    searchBy,
  });

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  const onTreeNodeCheck: TreeProps["onCheck"] = (keys, { checked, node }) => {
    const { key } = node;
    if (typeof key === "number") return;
    const [strType, ...rest] = key.split("_"); // check usePmaxTreeDataNodes.tsx for how the key is formated
    const type = strType as DataNodeKeyType;
    const id = type === "ad-account" ? rest[0] : rest[1];
    if (!type) return;
    if (!checked) {
      assetGroupSelection.removeAssetGroups({ type, id });
      return;
    }

    setExpandedKeys(prev => [...prev, key]);
    assetGroupSelection.setAssetGroups({
      type,
      id,
      assetGroups: computedSelection,
    });
  };

  const dataSource = useMemo(() => {
    const baseDatasource = selectedDataSource;
    if (!isFiltered) {
      return baseDatasource;
    }

    return intersectionWith(
      baseDatasource,
      computedSelection,
      (a, b) => a.id === b.id,
    );
  }, [computedSelection, isFiltered, selectedDataSource]);

  const autoFocus =
    isLoadDrawerOpen &&
    !loadDrawerWillClose &&
    !isLoadingGoogleAdsAccounts &&
    drawerAnimationDone;

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div className={styles.assetGroupListContainer}>
          <TableFiltersSection
            totalItems={selectedDataSource.length}
            currentItems={dataSource.length}
            onFilterClick={() => setIsFiltered(!isFiltered)}
            selectedCount={computedSelection.length}
            displaySelectedOnly={isFiltered}
          />
          <Table
            rowSelection={{
              type: "checkbox",
              getCheckboxProps: (record: IAssetGroup) => ({
                disabled: isUnableToLoad(record, "new"),
              }),
              selectedRowKeys: computedSelection.map(grp => grp.id),
              onChange: keys => {
                if (!keys.length) setIsFiltered(false);
                setDrawerSelection(keys);
              },
            }}
            columns={columns}
            dataSource={dataSource.map(src => ({
              ...src,
              key: src.id,
            }))}
            pagination={false}
            bordered={false}
          ></Table>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.pmaxSelectionContainer}>
          <Form form={form} layout="vertical">
            <Form.Item label={terms.account}>
              <MultipleStoreSelect
                autoFocus={autoFocus}
                style={{ width: "100%" }}
                disabled={isLoadingGoogleAdsAccounts}
                loading={isLoadingGoogleAdsAccounts}
                selectedDealers={assetGroupSelection.selection.map(
                  s => s.dealerName,
                )}
                onSelect={onSelectStore}
                onDeselect={onDeselectStore}
              />
            </Form.Item>

            <Form.Item label="Destination Preview">
              <div className={styles.destinationPreview}>
                <Input.Search
                  placeholder="Search"
                  onChange={e => {
                    e.preventDefault();

                    setSearchBy(e.target.value);
                  }}
                  onSearch={val => setSearchBy(val)}
                />
              </div>
              <Spin
                size="large"
                spinning={assetGroupSelection.isFetchingGoogleCampaigns}
                className={styles.spin}
              >
                <Tree
                  disabled={assetGroupSelection.isFetchingGoogleCampaigns}
                  height={720}
                  checkable
                  defaultExpandAll
                  checkedKeys={assetGroupSelection.computedSelectedKeys}
                  selectedKeys={assetGroupSelection.computedSelectedKeys}
                  expandedKeys={expandedKeys}
                  treeData={treeDataNodes}
                  onCheck={onTreeNodeCheck}
                  onExpand={setExpandedKeys}
                />
              </Spin>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default CreateNewAssetGroups;
