import { useMemo, useEffect, useCallback, useState } from "react";
import { Empty } from "antd";

import AdsToLoadByAccountCollapse from "./loadStep/AdsToLoadByAccountCollapse";
import ReviewStepInnerDrawer from "./reviewStep/ReviewStepInnerDrawer";

import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { setAdLoadState } from "redux/adLibrary/adLibrary.slice";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";

import { queryClient } from "queryClient";

import useGetIsMounted from "shared/hooks/useGetIsMounted";
import { useHandleLoadAdToFacebook } from "./shared/hooks/loadStep/useHandleLoadAdToFacebook";
import { useHandleAssembleCreative } from "./shared/hooks/loadStep/useHandleAssembleCreative";
import { useHandleUploadFacebookAdMedia } from "./shared/hooks/loadStep/useHandleUploadFacebookAdMedia";
import { useHandleSelectedAdsToLoadChange } from "./shared/hooks/loadStep/useHandleSelectAdsToLoadChange";
import { useHandleCurrentInnerStepChange } from "./shared/hooks/loadStep/useHandleCurrentInnerStepChange";

import { initSelectedAdsToLoad } from "./loadStep/helpers.ad";
import { returnAdsGroupedByAccount } from "./loadStep/helpers.load";

import {
  IAdToLoadData,
  LoadStepKeys,
  ReviewStepKeys,
} from "shared/types/adLibrary";
import { AdsToLoadByAccountId } from "./shared/types";

import styles from "./LoadStep.module.scss";
import { useProgressNotification } from "./loadStep/useProgressNotification";
import { resetAdLoadStatus } from "./shared/utils";

const {
  LOAD_AGAIN,
  LOADING_ADS,
  WAITING_FOR_LOAD,
  FINISHED_LOADING,
  ASSEMBLING_CREATIVE,
  UPLOADING_MEDIA,
} = LoadStepKeys;

const LoadStep = () => {
  const isMounted = useGetIsMounted();
  const dispatch = useAppDispatch();
  const {
    selectedAccounts,
    currentInnerStep,
    review: adLoadReview,
  } = useAppShallowEqualSelector(({ adLibrary }) => ({
    review: adLibrary.adLoad.review,
    currentInnerStep: adLibrary.adLoad.currentInnerStep,
    selectedAccounts: adLibrary.selectedFacebookAccounts,
  }));
  const { adsToLoad = [], selectedRowKeys = [] } = adLoadReview || {};

  const [selectedAdsToLoad, setSelectedAdsToLoad] = useState<IAdToLoadData[]>(
    initSelectedAdsToLoad({ adsToLoad, selectedRowKeys }),
  );
  const [currentProcessStep, setCurrentProcessStep] =
    useState(WAITING_FOR_LOAD);
  const [currentAdToLoad, setCurrentAdToLoad] = useState<IAdToLoadData | null>(
    null,
  );

  const adsGroupedByAccount: AdsToLoadByAccountId | undefined = useMemo(
    () => returnAdsGroupedByAccount(selectedAdsToLoad, selectedAccounts),
    [selectedAccounts, selectedAdsToLoad],
  );

  const adLoadingStarted = useMemo(
    () =>
      [ASSEMBLING_CREATIVE, UPLOADING_MEDIA, LOADING_ADS].includes(
        currentInnerStep as LoadStepKeys,
      ),
    [currentInnerStep],
  );

  const adLoadingComplete = useMemo(
    () =>
      [FINISHED_LOADING, LOAD_AGAIN].includes(
        currentInnerStep as LoadStepKeys,
      ) || [FINISHED_LOADING, LOAD_AGAIN].includes(currentProcessStep),
    [currentInnerStep, currentProcessStep],
  );

  const { updateProgressNotification, closeNotification } =
    useProgressNotification({
      adLoadingStarted,
      adLoadingComplete,
    });

  const onProgressChange = useCallback(() => {
    if (
      location.pathname !== "/ad-library/sessions" &&
      (adLoadingStarted || adLoadingComplete)
    ) {
      updateProgressNotification(selectedAdsToLoad);
    }
  }, [
    adLoadingComplete,
    adLoadingStarted,
    selectedAdsToLoad,
    updateProgressNotification,
  ]);

  useEffect(onProgressChange, [onProgressChange]);

  const initializeCurrentInnerStep = useCallback(() => {
    const processedAdCount = selectedAdsToLoad.filter(adToLoad =>
      ["error", "success"].includes(adToLoad.adLoadStatus.status),
    ).length;
    if (processedAdCount !== selectedAdsToLoad.length) return;
    const errorCount = selectedAdsToLoad.filter(
      adToLoad => adToLoad.adLoadStatus.status === "error",
    ).length;

    dispatch(
      setAdLoadState({
        prop: "currentInnerStep",
        data: !!errorCount ? LOAD_AGAIN : FINISHED_LOADING,
      }),
    );
  }, [dispatch, selectedAdsToLoad]);

  const closeInnerDrawer = useCallback(() => {
    dispatch(setAdLoadState({ prop: "currentInnerStep", data: null }));
  }, [dispatch]);

  const onComponentDestroy = () => {
    const changeAdLoadStatusToDefault = () => {
      const newSelectedAdsToLoad = resetAdLoadStatus(selectedAdsToLoad, false);
      setSelectedAdsToLoad(newSelectedAdsToLoad);

      const newAdsToLoad = adsToLoad.map(
        adToLoad =>
          newSelectedAdsToLoad.find(
            selected => selected.key === adToLoad.key,
          ) || adToLoad,
      );

      dispatch(
        setAdLoadState({
          prop: "review",
          triggerUpdateProp: "adsToLoad",
          data: {
            ...(adLoadReview || {
              selectedRowKeys: [],
              selectedColumnKeys: [],
            }),
            adsToLoad: newAdsToLoad,
          },
        }),
      );
    };

    const cancelOutgoingRequests = () => {
      queryClient.cancelMutations();
      queryClient.cancelQueries();
    };

    changeAdLoadStatusToDefault();
    closeInnerDrawer();
    closeNotification();
    cancelOutgoingRequests();
  };

  useEffect(() => {
    initializeCurrentInnerStep();
    return onComponentDestroy;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrentInnerStepChange = useHandleCurrentInnerStepChange();

  useEffect(() => {
    // should only call this when inner step changes
    handleCurrentInnerStepChange({
      selectedAdsToLoad,
      currentProcessStep,
      setSelectedAdsToLoad,
      setCurrentProcessStep,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInnerStep]);

  const handleUploadFacebookAdMedia = useHandleUploadFacebookAdMedia();
  const handleAssembleCreative = useHandleAssembleCreative();
  const handleLoadAdToFacebook = useHandleLoadAdToFacebook();

  const handleCurrentProcessStepChange = useCallback(
    (processStep: LoadStepKeys) => {
      const handleAdLoadProcesStepArgs = isMounted()
        ? {
            selectedAdsToLoad,
            setCurrentAdToLoad,
            setSelectedAdsToLoad,
          }
        : { selectedAdsToLoad };
      if (processStep === UPLOADING_MEDIA) {
        return handleUploadFacebookAdMedia(handleAdLoadProcesStepArgs);
      }
      if (processStep === ASSEMBLING_CREATIVE) {
        return handleAssembleCreative(handleAdLoadProcesStepArgs);
      }
      if (processStep === LOADING_ADS) {
        return handleLoadAdToFacebook(handleAdLoadProcesStepArgs);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedAdsToLoad, adLoadReview?.adsToLoad],
  );

  useEffect(() => {
    if (!isMounted()) return;
    handleCurrentProcessStepChange(currentProcessStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProcessStep, isMounted]);

  const handleCurrentAdToLoadChange = useCallback(
    (currentAdToLoad: IAdToLoadData | null) => {
      setSelectedAdsToLoad(prevSelectedAdsToLoad => {
        if (!currentAdToLoad) {
          return prevSelectedAdsToLoad;
        }

        const adToLoadIndex = prevSelectedAdsToLoad.findIndex(
          adToLoad => adToLoad.key === currentAdToLoad.key,
        );
        if (adToLoadIndex === -1) {
          return prevSelectedAdsToLoad;
        }

        const newSelectedAdsToLoad = [...prevSelectedAdsToLoad].map(
          (adToLoad, index) => {
            if (index === adToLoadIndex) return currentAdToLoad;
            return adToLoad;
          },
        );
        return newSelectedAdsToLoad;
      });
    },
    [],
  );

  useEffect(() => {
    handleCurrentAdToLoadChange(currentAdToLoad);
  }, [currentAdToLoad, handleCurrentAdToLoadChange]);

  const handleSelectedAdsToLoadChange = useHandleSelectedAdsToLoadChange();

  useEffect(() => {
    handleSelectedAdsToLoadChange({
      selectedAdsToLoad,
      currentProcessStep,
      setCurrentProcessStep,
    });
  }, [currentProcessStep, handleSelectedAdsToLoadChange, selectedAdsToLoad]);

  if (!adsGroupedByAccount) {
    return <Empty description="No ads are available to load" />;
  }

  return (
    <>
      <div className={styles.loadStepCollapseParentDiv}>
        <AdsToLoadByAccountCollapse
          adLoadingStarted={adLoadingStarted}
          adLoadingCompleted={adLoadingComplete}
          adsGroupedByAccount={adsGroupedByAccount}
        />
      </div>
      <ReviewStepInnerDrawer
        adsToLoad={selectedAdsToLoad}
        currentInnerStep={
          currentInnerStep === ReviewStepKeys.CONVERT_TO_VIDEO
            ? currentInnerStep
            : null
        }
        onSave={({ data }) => {
          setSelectedAdsToLoad(
            selectedAdsToLoad.map(
              adToLoad =>
                data.find(item => item.key === adToLoad.key) ?? adToLoad,
            ),
          );
        }}
        onClose={closeInnerDrawer}
      />
    </>
  );
};

export default LoadStep;
