import { useCallback, useEffect, useState } from "react";
import AdCompleteImportDrawer from "./adLibrary/adImport/AdCompleteImportDrawer";
import AdImportDrawer from "./adLibrary/adImport/AdImportDrawer";
import AdLoadDrawer from "./adLibrary/adLoad/AdLoadDrawer";
import AdLoadSessions from "./adLibrary/AdLoadSessions";
import AdWizard from "./adLibrary/AdWizard";
import AdList from "./adLibrary/library/AdList";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import { useIsMutating, useQueryClient } from "react-query";
import { useFetchSessionJson } from "shared/hooks/adLibrary/adHistory/useFetchSessionJson";
import { useFetchSessions } from "shared/hooks/adLibrary/adHistory/useFetchSessions";
import useGetIsMounted from "shared/hooks/useGetIsMounted";
import useClearAdLoadState from "./adLibrary/shared/hooks/useClearAdLoadState";
import useCreateNewSession from "./adLibrary/shared/hooks/useCreateNewSession";
import useHandleLoadSessionData from "./adLibrary/shared/hooks/useHandleLoadSessionData";
import useSetSessionJsonData from "./adLibrary/shared/hooks/useSetSessionJsonData";
import useStartNewAdLoadSession from "./adLibrary/shared/hooks/useStartNewAdLoadSession";
import PMaxDataProvider from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { DataListURLProvider } from "shared/components/dataListURL/dataListURLContext";
import { useDatadog } from "shared/hooks/useDatadog";
import { IAd, IAdHistorySession, IAdLoad } from "shared/types/adLibrary";
import {
  adSessionFields,
  IAdSessionFields,
} from "./adLibrary/adLoadSessions/sessionsTable/fields";
import { AdLoadSessionTableEntry } from "./adLibrary/adLoadSessions/types";
import { fields, IAdFields } from "./adLibrary/fields";
import PMaxAssetGroups from "./adLibrary/PMaxAssetGroups";
import AdLoadDrawerV2 from "screens/adLibrary/adLoadV2/AdLoadDrawer";
import { useSessionParam } from "./adLibrary/adLoadV2/hooks/useCurrentSession";

const AdLibrary = () => {
  const { sessionId } = useSessionParam();
  const navigate = useNavigate();
  const location = useLocation();
  const isMutating = !!useIsMutating();
  const queryClient = useQueryClient();
  const getIsMounted = useGetIsMounted();

  const clearAdLoadState = useClearAdLoadState();
  const createNewSession = useCreateNewSession();
  const setSessionJsonData = useSetSessionJsonData();
  const handleLoadSessionData = useHandleLoadSessionData();
  const startNewAdLoadSession = useStartNewAdLoadSession();

  const [createAdWizardVisible, setCreateAdWizardVisible] = useState(false);
  const [displayImportDrawer, setDisplayImportDrawer] = useState(false);
  const [previousSearchParams, setPreviousSearchParams] = useState<
    string | undefined
  >();

  const {
    adLoadHistory,
    isAdLoadVisible,
    displayImportTable,
    selectedFacebookAccounts,
  } = useAppShallowEqualSelector(({ adLibrary }) => ({
    isAdLoadVisible: adLibrary.isAdLoadVisible,
    adLoadHistory: adLibrary.adLoad.adLoadHistory,
    displayImportTable: adLibrary.adImport.displayImportTable,
    selectedFacebookAccounts: adLibrary.selectedFacebookAccounts,
  }));

  const onAdLoad = location.pathname === "/ad-library/load";
  const locationSearchSplit = location.search.split("?session_id=") ?? [];
  const currentSessionId =
    adLoadHistory?.sessionId || locationSearchSplit?.[1]?.split("&")?.[0];
  const isViewingHistory =
    location.pathname === "/ad-library/sessions" &&
    locationSearchSplit.length === 2;

  const {
    data: sessionData,
    refetch: getSessionData,
    isLoading: isLoadingSessions,
    isSuccess: isFetchDataSuccess,
  } = useFetchSessions({
    queryParams: {
      id: currentSessionId,
    },
    enabled: false,
  });

  const currentSession: IAdHistorySession | undefined = (
    (queryClient.getQueryData(["adHistorySessions", currentSessionId]) ??
      []) as IAdHistorySession[]
  )?.[0];

  const currentSessionJson: IAdLoad | undefined = queryClient.getQueryData([
    "adHistorySessionJson",
    currentSessionId,
  ]);

  const {
    data: sessionJsonData,
    isSuccess: isFetchJsonSuccess,
    isLoading: isLoadingSessionJson,
  } = useFetchSessionJson({
    params: {
      sessionId: currentSessionId,
      sessionJsonUrl: currentSession?.jsonUrl || "",
    },
  });

  const isLoadingSession = isLoadingSessions || isLoadingSessionJson;

  useDatadog();

  // Check if url changed to have ?session_id=<sessionId>
  // if so, get the session data
  useEffect(() => {
    if (!getIsMounted()) return;

    if (
      (!onAdLoad && !isViewingHistory) ||
      (locationSearchSplit?.length !== 2 && !currentSessionJson)
    ) {
      // clean up redux state if not in ad-library/load
      clearAdLoadState({ currentSessionId });

      // prevent user from seeing ad load if they have not chosen an account
      const willRedirect =
        onAdLoad &&
        locationSearchSplit?.length < 2 &&
        !selectedFacebookAccounts?.length;
      if (!willRedirect) return;
      navigate("/ad-library");
      return;
    }

    getSessionData?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const shouldNotProceed =
    (!!isFetchJsonSuccess && !sessionData) ||
    (!onAdLoad && !isViewingHistory) ||
    isMutating;

  // load current session's JSON data after getting sessions
  useEffect(() => {
    if (!getIsMounted()) return;

    if (!isFetchDataSuccess || shouldNotProceed) return;

    if (!currentSession) {
      /*
       * if no data was returned for the current sessionId,
       * then a new session has been started, so a new one
       * must be created.
       */
      createNewSession();
      return;
    }

    setSessionJsonData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData]);

  useEffect(() => {
    if (!getIsMounted()) return;

    if (shouldNotProceed || isAdLoadVisible) {
      return;
    }

    handleLoadSessionData({
      session: currentSession,
      sessionJsonData: sessionJsonData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionJsonData]);

  const storePreviousSearchParams = useCallback(() => {
    setPreviousSearchParams(location.search);
  }, [location.search]);

  const goBackToAdListView = () => {
    navigate(`/ad-library${previousSearchParams ?? ""}`);
  };

  const onOpenAdLoadDrawer = useCallback(() => {
    storePreviousSearchParams();
    startNewAdLoadSession({
      sessionId: adLoadHistory?.sessionId,
    });
  }, [
    adLoadHistory?.sessionId,
    startNewAdLoadSession,
    storePreviousSearchParams,
  ]);

  const onOpenImportDrawer = useCallback(() => {
    storePreviousSearchParams();
    setDisplayImportDrawer(true);
  }, [storePreviousSearchParams]);

  return (
    <div className="ad-library-root-container">
      <Routes>
        <Route
          index
          element={
            <>
              {createAdWizardVisible && (
                <AdWizard onClose={() => setCreateAdWizardVisible(false)} />
              )}
              {!displayImportTable && !isAdLoadVisible && (
                <AdList
                  setCreateAdWizardVisible={setCreateAdWizardVisible}
                  openAdLoadDrawer={onOpenAdLoadDrawer}
                  openImportDrawer={onOpenImportDrawer}
                />
              )}
            </>
          }
        />
        <Route
          path={"load"}
          element={
            <>
              {createAdWizardVisible && (
                <AdWizard onClose={() => setCreateAdWizardVisible(false)} />
              )}
              {!displayImportTable && !isAdLoadVisible && (
                <AdList
                  setCreateAdWizardVisible={setCreateAdWizardVisible}
                  openAdLoadDrawer={onOpenAdLoadDrawer}
                  openImportDrawer={onOpenImportDrawer}
                />
              )}
            </>
          }
        />
        <Route
          path={"sessions"}
          element={
            <DataListURLProvider<IAdSessionFields, AdLoadSessionTableEntry>
              fields={adSessionFields}
            >
              <AdLoadSessions />
            </DataListURLProvider>
          }
        />
        <Route
          path={"pmax-asset-groups"}
          element={
            <PMaxDataProvider>
              <PMaxAssetGroups />
            </PMaxDataProvider>
          }
        />
      </Routes>
      <AdLoadDrawer
        loading={isLoadingSession}
        visible={isAdLoadVisible}
        onClose={goBackToAdListView}
      />
      {sessionId && <AdLoadDrawerV2 />}
      {displayImportDrawer && (
        <AdImportDrawer handleClose={() => setDisplayImportDrawer(false)} />
      )}
      {displayImportTable && (
        <AdCompleteImportDrawer onClose={goBackToAdListView} />
      )}
    </div>
  );
};

const AdLibraryContainer = () => {
  return (
    <DataListURLProvider<IAdFields, IAd> fields={fields}>
      <AdLibrary />
    </DataListURLProvider>
  );
};

export default AdLibraryContainer;
