import { useCallback } from "react";
import {
  FABRIC_EDITOR,
  POLOTNO_EDITOR,
} from "screens/designStudio/designStudioV2/constants";
import {
  useQueryParams,
  StringParam,
  UrlUpdateType,
  createEnumParam,
} from "use-query-params";

const ModeEnumParam = createEnumParam([FABRIC_EDITOR, POLOTNO_EDITOR]);

export const useDesignStudioEditor = () => {
  const [editorQuery, setEditorQuery] = useQueryParams({
    editor: StringParam,
    assetId: StringParam,
    mode: ModeEnumParam,
  });

  const setEditor = useCallback(
    (
      query: {
        editor: "templates" | "stamps" | null;
        assetId?: string;
        mode?: typeof FABRIC_EDITOR | typeof POLOTNO_EDITOR;
      } | null,
      updateType?: UrlUpdateType,
    ) => {
      setEditorQuery(
        {
          editor: query?.editor ?? null,
          assetId: query?.assetId ?? null,
          mode: query?.mode ?? null,
        },
        updateType,
      );
    },
    [setEditorQuery],
  );

  return {
    editorQuery,
    setEditor,
  };
};
