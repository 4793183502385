import { IAd } from "shared/types/adLibrary";
import AdAccount, { adAccount } from "./adAccount";
import { returnAdCreative } from "./adPreviewCreative";

import { getDefaultPropValue } from "./utils";
import { AdCard, AdFormat, AdPrototype, AdType, CallToAction } from "./types";
import { isEnvVarEquals, getEnvVar, getBase64FromUrl } from "utils/helpers";
import memoizee from "memoizee";
import API from "services";
import { notification } from "antd";

const getFacebookImageHashMemoized = memoizee(
  async (imageUrl: string, accountId: string) => {
    if (!imageUrl) return undefined;

    const { imageHash } = await API.services.adLibrary.uploadImageToFb(
      imageUrl,
      accountId,
    );

    return imageHash;
  },
  {
    promise: true,
    length: 2,
    maxAge: 1000 * 60 * 15,
  },
);

const getFacebookImageHash = async ({
  imageUrl,
  accountId,
}: {
  imageUrl: string;
  accountId: string;
}) => {
  try {
    return await getFacebookImageHashMemoized(imageUrl, accountId);
  } catch (_) {
    notification.error({
      message: "Error while uploading image to Facebook.",
    });
    return undefined;
  }
};

export const generateAdPreview = async (
  adFormat: AdFormat,
  adType: AdType,
  adPrototype: AdPrototype,
): Promise<string> => {
  const forceDummyData = isEnvVarEquals("CLIENT", "ihg");
  const formParameters = {
    forceDummyData,
    pageId: getDefaultPropValue("PAGEID"),
  };

  const previewCreative = {
    ...returnAdCreative({
      adType,
      adPrototype: {
        ...adPrototype,
        assetId: !forceDummyData ? adPrototype.assetId : "",
      },
      formParameters,
      isForLiveAd: true,
    }),
    instagram_actor_id: getDefaultPropValue("INSTAGRAMACTORID") || undefined,
  };

  const params = {
    creative: JSON.stringify(previewCreative),
    ad_format: adFormat,
  };
  const { data } = await adAccount.getGeneratePreviews(params);
  return data[0].body;
};

export const getAdPrototypeFromAd = async (
  ad: IAd,
  accountId: string,
): Promise<AdPrototype> => {
  const isDynamic = [AdType.AIA, AdType.DPA, AdType.FTA].includes(ad.type);

  const callToAction = [AdType.Collection, AdType.InstantExperience].includes(
    ad.type,
  )
    ? undefined
    : ad.visuals?.ctaButtonText || CallToAction.SHOP_NOW;
  const imageHash =
    !isDynamic && ad.visuals?.thumbnail
      ? await getFacebookImageHash({
          imageUrl: ad.visuals.thumbnail,
          accountId: accountId,
        })
      : undefined;

  const cards = await Promise.all(
    ad.visuals?.cards?.map<Promise<AdCard>>(
      async ({
        assetId,
        destinationUrl,
        ctaButtonText,
        thumbnail,
        ...rest
      }) => {
        const cardHash = await getFacebookImageHash({
          imageUrl: thumbnail,
          accountId,
        });
        return {
          asset_id: assetId,
          destination_url: destinationUrl,
          callToAction: ctaButtonText,
          imageHash: cardHash || "",
          thumbnail: thumbnail,
          ...rest,
        };
      },
    ) ?? [],
  );

  return {
    callToAction: callToAction,
    thumbnail: ad.visuals?.thumbnail,
    imageHash: imageHash ?? "",
    message:
      ad.visuals?.postCopy ||
      ad.visuals?.primaryText ||
      "This is placeholder text for Copy",
    assetId: ad.visuals?.assetId ?? "",
    cards,
    title: ad.visuals?.headline || "This is placeholder text for Headline",
    utm: ad.inputParameters?.utm,
    footerText: ad.visuals?.headlineDescription,
    format: ad.visuals?.format,
    creativeOption: ad.visuals?.creativeOption,
    destinationUrl: ad.inputParameters?.destinationUrl,
    displayUrl: ad.visuals?.displayUrl,
    canvasUrl: ad.visuals.destination?.websiteURL?.includes(
      "https://fb.com/canvas_doc",
    )
      ? ad.visuals.destination?.websiteURL
      : getEnvVar("ADPREVIEW_URL"),
  };
};
