import API from "services";
import { useQuery } from "react-query";
import { IAd } from "shared/types/adLibrary";
import { AdType } from "screens/adLibrary/facebookUtils/types";

import AIADefaultImage from "statics/images/svg/aia-default.svg";
import DPADefaultImage from "statics/images/svg/dpa-default.svg";
import FTADefaultImage from "statics/images/svg/fta-default.svg";
import { useValidateAd } from "screens/adLibrary/shared/validationUtils";
import { QcStatus } from "shared/types/adLibrary";
import { useMemo } from "react";

export const fetchAds = async () => {
  const { result, error } = await API.services.adLibrary.getAds();

  if (!result) {
    throw Error(error?.message || "Something went wrong while fetching ads.");
  }

  return result.ads.map(ad => {
    switch (ad.type) {
      case AdType.AIA:
        return getDynamicAdWithThumbnail(ad, AIADefaultImage);
      case AdType.DPA:
        return getDynamicAdWithThumbnail(ad, DPADefaultImage);
      case AdType.FTA:
        return getDynamicAdWithThumbnail(ad, FTADefaultImage);
    }
    return ad;
  });
};

export const useFetchAds = () => {
  const { validateAd, isLoadingStores } = useValidateAd();
  const { data, isLoading, isError } = useQuery<IAd[], Error>("ads", fetchAds, {
    staleTime: 300000,
  });

  const ads = useMemo(
    () =>
      data?.map(ad => ({
        ...ad,
        validation: validateAd(ad),
        qcStatus: ad.qcStatus === QcStatus.ERROR ? QcStatus.DRAFT : ad.qcStatus,
      })),
    [data, validateAd],
  );

  return { data: ads, isLoading: isLoading || isLoadingStores, isError };
};

const getDynamicAdWithThumbnail = (ad: IAd, thumbnail: string): IAd => {
  return {
    ...ad,
    visuals: {
      ...ad.visuals,
      thumbnail: thumbnail,
      thumbnail25: thumbnail,
      thumbnail50: thumbnail,
    },
  };
};
