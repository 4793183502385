import { ReloadOutlined } from "@ant-design/icons";
import { Spin, Typography, message } from "antd";
import Link from "antd/lib/typography/Link";
import classnames from "classnames";
import { fabric } from "fabric";
import { keys, uniq } from "lodash";
import ResizeObserver from "rc-resize-observer";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { loadFontFromBrand } from "screens/designStudio/editor/propertySectionV2/propertyRow/ManageText.utils";
import API from "services";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import { customFonts } from "shared/constants/canvas";
import { getBrandsByName } from "shared/hooks/brandsAccountsManagement/useFetchBrandsByName";
import {
  STEP_CREATE_BATCH,
  STEP_CREATE_BATCH_V2,
} from "shared/types/assetExporter";
import { Font } from "shared/types/brandsAccounts";
import { IDimension, IExtendedFabricObject } from "shared/types/designStudio";
import { loadFont } from "utils/helpers";
import { convertDropboxUrl } from "utils/helpers.adEngine";
import {
  clearHighlightBoxes,
  clearRedHighlightBoxesOnFill,
  clearVarHighlight,
  fetchTemplateJson,
  getHighlightBox,
  highlightImageVar,
  highlightVarInTextbox,
  imageMaskExists,
  removeImageForImageVar,
  scaleCanvas,
  setImageForImageVar,
  setImageForVideoVar,
} from "../../assetBatchDrawer/dynamicText/utils.fabric";
import {
  groupMappingsByLineIdx,
  replaceStylesWithVariables,
  replaceVariablesWithinTextLines,
  setTextboxDataForCanvas,
} from "../../assetBatchDrawer/dynamicText/utils.variable";
import { parseMappingKey } from "../../assetBatchDrawer/utils";
import { useAssetBatchesContext } from "../contexts/AssetBatchesContext";
import { getImageMappingValue } from "../contexts/AssetBatchesContext.utils";
import { useAssetBatchesRenderContext } from "../contexts/AssetBatchesRenderContext";
import { useAssetBatchesValueMappingContext } from "../contexts/AssetBatchesValueMappingContext";
import { useFeedContext } from "../contexts/FeedContext";
import { TValueMapping } from "../types";
import {
  isCarCut,
  isExtendedFabricObject,
  isFixedVideo,
  isImageRect,
  isLogo,
  isTextbox,
  isVideo,
} from "../validators";
import styles from "./Preview.module.scss";
import { MaskItems } from "./Preview/MaskItems";
import { NavTemplateItems } from "./Preview/NavTemplateItems";
import PlaybackControls from "./Preview/PlaybackControls";

type Props = {
  ChangeTemplateNode?: React.ReactNode;
  useMask?: boolean;
};

const Preview = ({ useMask = true }: Props) => {
  const canvasContainerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const {
    previewLoading,
    setPreviewLoading,
    currentStep,
    showReload,
    setShowReload,
    currentCompositionId,
    useTemplateBackgroundEffect,
  } = useAssetBatchesContext();

  const {
    canvas,
    setCanvas,
    setCanvasContainerDim,
    valueMappings,
    onResize,
    isMediaMaskOn,
    isTextMaskOn,
    currentSwitchType,
    template,
    setCurrentSwitchType,
    templateHasFixedVideo,
    setTemplateHasFixedVideo,
    editingComposition,
  } = useAssetBatchesRenderContext();

  const { editingAssetBatch } = useFeedContext();

  const {
    saveTemplate,
    changedMappingKey,
    setChangedMappingKey,
    hoveredMappingKey,
    selectedRow,
    emptyValueHighlight,
    showVariablesOn,
  } = useAssetBatchesValueMappingContext();

  const loadFontsFromTemplate = useCallback(
    async (json: any, brandFonts: Font[]) => {
      if (!json) return;
      const { objects } = json;
      if (!objects || !Array.isArray(objects)) return;

      const textboxes = objects.filter(isTextbox);
      const fontFamilies = textboxes
        .reduce<string[]>((acc, textbox) => {
          const { styles, fontFamily } = textbox;
          const fontFamilies = Object.values<any>(styles).reduce<string[]>(
            (styleAcc, charStyleObj) => {
              const fontFamilies = Object.values<any>(charStyleObj).map(
                style => style.fontFamily,
              ) as string[];
              return [...styleAcc, ...uniq(fontFamilies)];
            },
            [],
          );

          return [
            ...acc,
            ...(uniq(
              [...fontFamilies, fontFamily].filter(fontFam => !!fontFam),
            ) as string[]),
          ];
        }, [])
        .filter(fontFamily => customFonts.includes(fontFamily));

      const brandResults = await Promise.all(
        brandFonts.map(font => loadFontFromBrand(font.name, font.url)),
      );
      const results = await Promise.all(
        fontFamilies.map(fontFamily => loadFont(fontFamily)),
      );
      if ([...brandResults, ...results].some(result => !result)) {
        message.error("Failed to load all required fonts.");
      }
    },
    [],
  );
  useEffect(() => {
    if (!template) return;

    const newCanvas = new fabric.Canvas(
      `canvas_${editingComposition.compositionId}_${editingComposition.template}`,
    );
    newCanvas.setWidth(0);
    newCanvas.setHeight(0);
    setChangedMappingKey(undefined);

    const canvasContainerRect =
      canvasContainerRef.current?.getBoundingClientRect();
    if (!canvasContainerRect) return;

    const render = async () => {
      setPreviewLoading(true);
      const json = await fetchTemplateJson(template);

      const brands = template.oems.length
        ? await getBrandsByName(template.oems)
        : [];
      const brandFonts = brands?.flatMap(brand => brand.fonts) ?? [];

      await loadFontsFromTemplate(json, brandFonts);

      setTemplateHasFixedVideo(false);
      newCanvas.loadFromJSON(json, () => {
        newCanvas.getObjects().forEach(obj => {
          obj.set({ selectable: false, evented: false });
          if (isTextbox(obj)) setTextboxDataForCanvas(obj);
          if (isFixedVideo(obj)) setTemplateHasFixedVideo(true);
          if (isVideo(obj) || isFixedVideo(obj))
            setImageForVideoVar(
              newCanvas,
              obj,
              obj.customData.videoSrc,
              "fill",
            );
        });
        const { artboard } = template;
        const canvasDim: IDimension = {
          width: artboard.width,
          height: artboard.height,
        };
        const containerDim: IDimension = {
          width: canvasContainerRect.width,
          height: canvasContainerRect.height,
        };
        setCanvasContainerDim({
          width: canvasContainerRect.width,
          height: canvasContainerRect.height,
        });
        scaleCanvas(newCanvas, canvasDim, containerDim);
        setCanvas(newCanvas);
      });
    };

    render().catch(() => {
      message.error("Failed to render template.");
    });
  }, [
    template,
    setCanvas,
    setCanvasContainerDim,
    loadFontsFromTemplate,
    setPreviewLoading,
    setTemplateHasFixedVideo,
    setChangedMappingKey,
    editingComposition,
  ]);

  useEffect(() => {
    if (!canvas || !selectedRow) return;

    if (
      !hoveredMappingKey ||
      !canvasRef.current?.id.includes(currentCompositionId ?? "")
    ) {
      clearHighlightBoxes(canvas);
      clearVarHighlight(canvas);
      return;
    }

    const { variableId } = parseMappingKey(hoveredMappingKey);

    if (emptyValueHighlight) {
      clearRedHighlightBoxesOnFill(canvas, variableId);
      return;
    }
    const varsGroupById = keys(valueMappings).reduce<
      Record<string, TValueMapping[]>
    >((acc, mappingKey) => {
      const mapping = valueMappings[mappingKey];

      return {
        ...acc,
        [variableId]: [...(acc[variableId] || []), mapping],
      };
    }, {});

    const mappings = varsGroupById[variableId];
    const mapping = valueMappings?.[hoveredMappingKey];

    if (!mapping) return;

    const groupedByLineIdx = groupMappingsByLineIdx(mappings);

    if (mapping.variable.type === "text")
      highlightVarInTextbox(
        canvas,
        variableId,
        groupedByLineIdx,
        mapping,
        selectedRow,
      );
    else highlightImageVar(canvas, variableId, !!mapping.value);
  }, [
    canvas,
    hoveredMappingKey,
    valueMappings,
    selectedRow,
    emptyValueHighlight,
    editingComposition,
    currentCompositionId,
  ]);

  const setTargetOpacity = (target: IExtendedFabricObject, opacity: number) =>
    target.set({ opacity });

  const renderTextVars = useCallback(
    (
      target: IExtendedFabricObject,
      groupedMappings: Record<number, TValueMapping[]>,
    ) => {
      if (isTextbox(target)) {
        const {
          originalText = undefined,
          originalTextLines = undefined,
          styles: originalStyles,
        } = target;
        if (!originalText || !originalTextLines) return;
        const { updatedTextLines, groupedMappings: newGroupedMappings } =
          replaceVariablesWithinTextLines(
            originalText.split("\n"),
            groupedMappings,
            selectedRow,
          );
        const updatedStyles = replaceStylesWithVariables(
          originalText.split("\n"),
          newGroupedMappings,
          selectedRow,
          originalStyles,
        );
        target.set({
          text: updatedTextLines.join("\n"),
          styles: updatedStyles,
        });
      }
    },
    [selectedRow],
  );

  const renderImageVars = useCallback(
    (
      target: IExtendedFabricObject,
      mappings: TValueMapping[],
      currentStep: STEP_CREATE_BATCH | STEP_CREATE_BATCH_V2,
    ) => {
      if (
        !canvas ||
        !(isCarCut(target) || isImageRect(target) || isLogo(target))
      )
        return;
      if (target.customType === "theme_background") {
        if (currentStep !== STEP_CREATE_BATCH.THREE_TEXT) return;
        setTargetOpacity(target, 0);
        return;
      }
      // Image vars are always single mappings
      const mapping = mappings[0];
      const resizeType = mapping.resizeType ?? "fill";
      const rawSrc = getImageMappingValue(mapping, selectedRow);
      const src = rawSrc ? convertDropboxUrl(rawSrc) : rawSrc;
      if (imageMaskExists(canvas, target)) {
        removeImageForImageVar(canvas, target);
        setTargetOpacity(target, 1);
      }
      if (src?.includes(".mp4")) {
        setImageForVideoVar(canvas, target, src, resizeType);
      } else if (src) setImageForImageVar(canvas, target, src, resizeType);
      if (src || currentStep === STEP_CREATE_BATCH.THREE_TEXT)
        setTargetOpacity(target, 0);
    },
    [canvas, selectedRow],
  );

  useEffect(() => {
    if (!canvas) return;

    if (useMask) {
      const varsGroupById = keys(valueMappings).reduce<
        Record<string, TValueMapping[]>
      >((acc, mappingKey) => {
        const { variableId } = parseMappingKey(mappingKey);

        const mapping = valueMappings[mappingKey];

        return {
          ...acc,
          [variableId]: [...(acc[variableId] || []), mapping],
        };
      }, {});

      keys(varsGroupById).forEach(id => {
        if (changedMappingKey && !changedMappingKey.includes(id)) return;
        const mappings =
          !isMediaMaskOn || !showVariablesOn
            ? varsGroupById[id].map(mapping => {
                return { ...mapping, value: undefined };
              })
            : varsGroupById[id];

        const groupedMappings =
          !isTextMaskOn || !showVariablesOn
            ? []
            : groupMappingsByLineIdx(mappings);

        const target = canvas
          ?.getObjects()
          .filter(isExtendedFabricObject)
          .find(obj => obj.name === id);
        if (!target) return;
        if (!mappings[0].value && !varsGroupById[id][0].value) {
          const highlightBox = getHighlightBox(target, false, false);
          if (highlightBox) {
            canvas?.add(highlightBox);
            canvas?.renderAll();
          }
        }
        renderTextVars(target, groupedMappings);
        currentSwitchType !== "text" &&
          renderImageVars(target, mappings, currentStep);
      });

      canvas.renderAll();
    } else {
      canvas.getObjects().forEach(obj => {
        if (!isTextbox(obj)) return;

        const originalText = (obj as any).originalText;
        obj.set({ text: originalText });
      });

      canvas.renderAll();
    }
    setCurrentSwitchType(undefined);
  }, [
    valueMappings,
    canvas,
    selectedRow,
    useMask,
    renderImageVars,
    renderTextVars,
    isTextMaskOn,
    isMediaMaskOn,
    currentSwitchType,
    setCurrentSwitchType,
    currentStep,
    changedMappingKey,
    showVariablesOn,
  ]);

  useTemplateBackgroundEffect(
    canvas,
    editingComposition,
    isMediaMaskOn,
    showVariablesOn,
    selectedRow,
  );

  const canvasClientWidth = canvasRef.current?.parentElement?.clientWidth;
  const canvasClientHeight = canvasRef.current?.parentElement?.clientHeight;

  const handleReloadTemplate = () => {
    if (!template?.id) return;
    API.services.designStudio
      .getTemplateById(template.id)
      .then(({ result }) => {
        if (!result) return;
        const { template: selectedTemplate } = result;
        saveTemplate(selectedTemplate, editingAssetBatch, true);
      });
    setShowReload(false);
  };

  const showPlayButton = useMemo(() => {
    if (templateHasFixedVideo) return true;
    return Object.keys(valueMappings).some(mappingKey => {
      const mapping = valueMappings[mappingKey];
      const mappingValue: string = getImageMappingValue(mapping, selectedRow);
      return mappingValue?.toString().includes("mp4");
    });
  }, [templateHasFixedVideo, valueMappings, selectedRow]);

  useEffect(() => {
    if (!canvasRef.current?.id.includes(currentCompositionId ?? "")) return;
    canvasRef.current?.scrollIntoView();
  }, [currentCompositionId]);

  return (
    <div className={styles.previewContainer}>
      {!videoCompositionEnabled && (
        <>
          <div className={styles.templateNav}>
            <NavTemplateItems />
          </div>
          <MaskItems step={currentStep} />
        </>
      )}
      <div className={styles.preview}>
        <ResizeObserver
          onResize={({ width, height }) => onResize({ width, height })}
        >
          <div
            ref={canvasContainerRef}
            className={styles.canvasContainer}
            data-cy="canvas-preview"
          >
            <Spin
              spinning={previewLoading}
              className={styles.spin}
              size="large"
            />
            <canvas
              id={`canvas_${editingComposition.compositionId}_${editingComposition.template}`}
              ref={canvasRef}
              className={
                previewLoading
                  ? classnames([styles.canvasHidden, styles.canvas])
                  : styles.canvas
              }
            />
            {showReload && (
              <div
                style={{
                  width: canvasClientWidth,
                  height: canvasClientHeight,
                }}
                className={styles.reloadTemplateLayer}
              >
                <div
                  className={styles.reloadBackgroundLayer}
                  style={{
                    width: canvasClientWidth,
                    height: canvasClientHeight,
                  }}
                ></div>
                <div
                  className={styles.reloadContentLayer}
                  style={{
                    width: canvasClientWidth,
                    height: canvasClientHeight,
                  }}
                >
                  <div className={styles.reloadContentContainer}>
                    <ReloadOutlined
                      className={styles.reloadIcon}
                      style={{
                        left: (canvasClientWidth ?? 48) / 2 - 24,
                      }}
                    />
                    <Typography.Title level={4}>
                      This template has been updated.
                    </Typography.Title>
                    <Link
                      className={styles.loadText}
                      onClick={handleReloadTemplate}
                    >
                      Load latest version
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ResizeObserver>
      </div>
      {showPlayButton && (
        <div className={styles.playbackControls}>
          <PlaybackControls />
        </div>
      )}
    </div>
  );
};

export default Preview;
