import {
  DownloadOutlined,
  LinkOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useState } from "react";
import {
  getImageInfoOnLoad,
  handleDownload,
} from "../utils/functions/imageInfoUtils";
import styles from "./ImagePreview.module.scss";
import CopyLinkModal from "./CopyLinkModal";

const ImagePreview = ({
  image,
  openCarousel,
}: {
  image: string;
  openCarousel: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [copyLinkModalVisible, setCopyLinkModalVisible] = useState(false);
  const [imageInfo, setImageInfo] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [blob, setBlob] = useState<Blob | null>(null);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleImageLoad = async (
    e: React.SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    const { width, height, blob } = await getImageInfoOnLoad(e, image);
    setImageInfo({ width, height });
    setBlob(blob);
  };

  return (
    <>
      <div
        className={styles.previewImage}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={openCarousel}
      >
        <img
          src={image}
          height={400}
          width={400}
          key={image}
          className={styles.image}
          onLoad={handleImageLoad}
        />
        {isHovering && (
          <div className={styles.overlay}>
            <div className={styles.buttonsRow}>
              <Button
                type="ghost"
                icon={
                  <ZoomInOutlined
                    width={32}
                    height={32}
                    style={{ fontSize: "32px" }}
                  />
                }
                className={styles.button}
                onClick={openCarousel}
              />
              <Button
                type="ghost"
                icon={
                  <DownloadOutlined
                    width={32}
                    height={32}
                    style={{ fontSize: "32px" }}
                  />
                }
                onClick={() => handleDownload(blob)}
                className={styles.button}
              />
              <Button
                type="ghost"
                icon={
                  <LinkOutlined
                    width={32}
                    height={32}
                    style={{ fontSize: "32px" }}
                  />
                }
                onClick={() => setCopyLinkModalVisible(true)}
                className={styles.button}
              />
            </div>
            <div className={styles.infoRow}>
              {imageInfo && (
                <>
                  <Typography.Text className={styles.text}>
                    {`${imageInfo.width} x ${imageInfo.height} px`}
                  </Typography.Text>
                  <Typography.Text className={styles.text}>|</Typography.Text>
                </>
              )}
              <Typography.Text className={styles.text}>
                {image.split(".").pop()}
              </Typography.Text>
              <Typography.Text className={styles.text}>|</Typography.Text>
              {blob && (
                <Typography.Text className={styles.text}>
                  {`${(blob.size / 1024 / 1024).toFixed(2)} MB`}
                </Typography.Text>
              )}
            </div>
          </div>
        )}
      </div>
      <CopyLinkModal
        link={image}
        visible={copyLinkModalVisible}
        onClose={() => setCopyLinkModalVisible(false)}
      />
    </>
  );
};

export default ImagePreview;
