import {
  TAssetBatch,
  QUERY_KEY_ASSET_BATCHES,
} from "shared/types/assetExporter";
import { queryClient } from "queryClient";
import { useMutation } from "react-query";
import API from "services";
import { getErrorMessage } from "utils/errorMessage";
import { COLUMN_ASSET_BATCHES } from "shared/constants/assetExporter";

const upsertAssetBatch = async (data: TAssetBatch) => {
  try {
    const { postAssetBatch } = API.services.assetExporter;
    const { result, error } = await postAssetBatch(data);
    if (error) throw new Error(error.message);
    if (!result)
      throw data.assetBatchId
        ? new Error("Unable to update asset batch")
        : new Error("Unable to create asset batch");
    return result;
  } catch (error) {
    const errMsg = getErrorMessage(error);
    throw new Error(errMsg);
  }
};

export const useUpsertAssetBatch = () => {
  return useMutation<TAssetBatch, Error, TAssetBatch>(
    data => upsertAssetBatch(data),
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries(COLUMN_ASSET_BATCHES);
        queryClient.invalidateQueries([QUERY_KEY_ASSET_BATCHES, req.feedId]);
      },
    },
  );
};
