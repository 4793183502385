import {
  CommaField,
  IOfferDataGenerationDescription,
  IOfferDataValidationDescription,
  ICoopInstruction,
  RawOfferData,
  RepeatableOfferKeys,
  SingletonOfferKeys,
} from "shared/types/assetBuilder";
import { timezoneOffset } from "utils/helpers";
export const fieldsToNotPopulateUsingOfferData = ["dealerName"];

export const LIMIT_RECORDS_PER_CALL = 250;

export const blankOfferData = (): RawOfferData => ({
  accessories: "",
  additionalLeaseDisclosure: "",
  additionalPurchaseDisclosure: "",
  aprRate: "",
  aprTerm: "",
  capCostReduction: "",
  centsPerMile: "",
  cylinders: "",
  dealerDiscount: "",
  dealerPrice: "",
  dealerId: "",
  expirationDate: "",
  feature1: "",
  feature2: "",
  finalPrice: "",
  finalPriceName: "",
  financeDownPayment: "",
  leaseVehicleSalesPrice: "",
  make: "",
  milesPerYear: "",
  model: "",
  modelCode: "",
  monthlyPayment: "",
  transactabilityScore: "",
  msrp: "",
  netAdjustedCapitalizedCost: "",
  numberOfPayments: "",
  priceAfterConditionalRebates: "",
  savingsOffMSRP: "",
  savingsOffMSRPDescription: "",
  securityDeposit: "",
  stockNumber: "",
  terminationFee: "",
  totalDueAtSigning: "",
  totalLeaseCharge: "",
  totalPayments: "",
  transmission: "",
  trim: "",
  vin: "",
  year: "",
  acquisitionFee: "",
  amntFinanced: "",
  aprPayment: "",
  capCost: "",
  driveTrain: "",
  financeMinFICO: "",
  minFICO: "",
  packagesExtended: "",
  residualValue: "",
  savingsOffMSRPTitle: "",
  totalDueAtSigningInclRebates: "",
  vehicleCondition: "",
  vehicleType: "",
  zeroDownLeaseAcquisitionFee: "",
  zeroDownLeaseCapCost: "",
  zeroDownLeaseCapCostReduction: "",
  zeroDownLeaseCentsPerMile: "",
  zeroDownLeaseMilesPerYear: "",
  zeroDownLeaseMinFICO: "",
  zeroDownLeaseNetAdjustedCapitalizedCost: "",
  zeroDownLeaseNumberOfPayments: "",
  zeroDownLeaseResidualValue: "",
  zeroDownLeaseSecurityDeposit: "",
  zeroDownLeaseTerminationFee: "",
  zeroDownLeaseTotalLeaseCharge: "",
  zeroDownLeaseTotalPayments: "",
  zeroDownLeaseVehicleSalesPrice: "",
  zeroDownLeaseMonthlyPayment: "",
  zeroDownLeaseTotalDueAtSigning: "",
  zeroDownLeaseTotalDueAtSigningInclRebates: "",
  dateInStock: "",
  dealerCode: "",
  exteriorColor: "",
  mileage: "",
  imageUrl: "",
  daysInStock: "",
  percentOffMSRP: "",
  aprAmntFinanced: "",
  aprMinFICO: "",
  financeRate: "",
  financeTerm: "",
  financePayment: "",
  invoicePrice: "",
  accountingCost: "",
  modelId: "",
  advertisedPrice: "",
  // repeatable keys
  conditionalRebate: [""],
  conditionalRebateDisclosure: [""],
  conditionalRebateName: [""],
  leaseRebate: [""],
  leaseRebateDisclosure: [""],
  leaseRebateName: [""],
  leaseConditionalRebate: [""],
  leaseConditionalRebateDisclosure: [""],
  leaseConditionalRebateName: [""],
  purchaseRebate: [""],
  purchaseRebateDisclosure: [""],
  purchaseRebateName: [""],
  zeroDownLeaseRebate: [""],
  zeroDownLeaseRebateName: [""],
  zeroDownLeaseRebateDisclosure: [""],
  zeroDownLeaseConditionalRebate: [""],
  zeroDownLeaseConditionalRebateName: [""],
  zeroDownLeaseConditionalRebateDisclosure: [""],
  financeConditionalRebate: [""],
  financeConditionalRebateName: [""],
  financeConditionalRebateDisclosure: [""],
  dealerName: "",
  // AV2-2465 added fields
  accessoryPrice: "",
  aprLender: "",
  financeLender: "",
  capCostIncludesAcqusitionFee: "",
  capCostIncludesFirstMonthPayment: "",
  dasIncludesAcqusitionFee: "",
  dasIncludesFirstMonthPayment: "",
  // AV2-2792
  suggestedDealerContribution: "",
  // Internal keys begin here
  leaseTerm: "",
  leaseDownPayment: "",
  leaseExpirationDate: "",
  leaseMonthlyPayment: "",
  leaseTotalDueAtSigning: "",
  leaseSellingPrice: "",
  // AV2-2863
  additionalFinanceDisclosure: "",
  additionalZeroDownLeaseDisclosure: "",
  additionalAPRDisclosure: "",
  // AV2-2663 new fields added
  dmsStatusCode: "",
  zeroDownSuggestedDealerContribution: "",
  //  AV2-2663 new fields with strings separated by "|" for multiple values
  optionCodes: "",
  packageNames: "",
  packageCodes: "",
  imageUrls: "",
  cabStyle: "",

  leaseCustom: [""],
  zeroDownLeaseCustom: [""],
  purchaseCustom: [""],
  financeCustom: [""],
  aprCustom: [""],
  // AV2-3335 lender fields added
  leaseLender: "",
  zeroDownLeaseLender: "",
  // AV2-3795: vehicle info fields added
  blackBookAverage: "",
  blackBookClean: "",
  kbbRetail: "",
  kbbTradeIn: "",
  nadaRetail: "",
  nadatradeIn: "",
  rowIdentifier: "",
});
export const offerGenerationDescription: IOfferDataGenerationDescription = {
  daysInStock: data => {
    const inStock = data.dateInStock
      ? Date.parse(data.dateInStock) + timezoneOffset
      : 0;
    const diff = Date.now() - inStock;
    return (diff / (1000 * 60 * 60 * 24)).toFixed(0);
  },
};
export const offerValidationDescription: IOfferDataValidationDescription = {
  year: str => {
    const year = parseInt(str, 10);
    if (isNaN(year) || year < 100 || year > 3000) {
      return "Invalid year";
    }
    return null;
  },
};
export const skippableProps = [
  "vin",
  "year",
  "model",
  "percentOffMSRP",
  "model",
  "modelCode",
  "stockNumber",
  "trim",
  "driveTrain",
  "modelId",
  "transmission",
  "exteriorColor",
  "mileage",
  "feature1",
  "feature2",
  "additionalAPRDisclosure",
  "additionalLeaseDisclosure",
  "additionalFinanceDisclosure",
  "additionalPurchaseDisclosure",
  "additionalZeroDownLeaseDisclosure",
] as Array<SingletonOfferKeys | RepeatableOfferKeys>;
// Possible TO DO: convert this to a Record<OfferType, Array<SingletonOfferKeys | RepeatableOfferKeys>>
export const roundableProps = [
  // Lease
  "monthlyPayment",
  "totalDueAtSigning",
  "residualValue",
  "totalPayments",
  "totalLeaseCharge",
  "capCost",
  "capCostReduction",
  // Zero Down Lease
  "zeroDownLeaseMonthlyPayment",
  "zeroDownLeaseResidualValue",
  "zeroDownLeaseTotalPayments",
  "zeroDownLeaseTotalLeaseCharge",
  "zeroDownLeaseCapCost",
  "zeroDownLeaseCapCostReduction",
  // Finance
  "financePayment",
  "financeDownPayment",
] as Array<SingletonOfferKeys | RepeatableOfferKeys>;
export const leaseRebateFieldKeys = [
  "leaseRebate1",
  "leaseRebate1Disclosure",
  "leaseRebate1Name",
  "leaseRebate2",
  "leaseRebate2Disclosure",
  "leaseRebate2Name",
  "leaseRebate3",
  "leaseRebate3Disclosure",
  "leaseRebate3Name",
  "leaseRebate4",
  "leaseRebate4Disclosure",
  "leaseRebate4Name",
  "leaseRebate5",
  "leaseRebate5Disclosure",
  "leaseRebate5Name",
  "leaseRebate6",
  "leaseRebate6Disclosure",
  "leaseRebate6Name",
];
export const conditionalRebateFieldKeys = [
  "conditionalRebate1",
  "conditionalRebate1Disclosure",
  "conditionalRebate1Name",
  "conditionalRebate2",
  "conditionalRebate2Disclosure",
  "conditionalRebate2Name",
  "conditionalRebate3",
  "conditionalRebate3Disclosure",
  "conditionalRebate3Name",
  "conditionalRebate4",
  "conditionalRebate4Disclosure",
  "conditionalRebate4Name",
  "conditionalRebate5",
  "conditionalRebate5Disclosure",
  "conditionalRebate5Name",
  "conditionalRebate6",
  "conditionalRebate6Disclosure",
  "conditionalRebate6Name",
];
export const commaFormattedFields: CommaField[] = [
  // vehicleInfo
  "msrp",
  "advertisedPrice",
  "invoicePrice",
  "accountingCost",
  // AV2-3795: vehicle info fields added
  "blackBookAverage",
  "blackBookClean",
  "kbbRetail",
  "kbbTradeIn",
  "nadaRetail",
  "nadatradeIn",
  // Lease
  "monthlyPayment",
  "leaseMonthlyPayment",
  "totalDueAtSigning",
  "leaseTotalDueAtSigning",
  "residualValue",
  "leaseVehicleSalesPrice",
  "totalDueAtSigningInclRebates",
  "capCost",
  "capCostReduction",
  "netAdjustedCapitalizedCost",
  "totalLeaseCharge",
  "totalPayments",
  "securityDeposit",
  "terminationFee",
  "milesPerYear",
  "acquisitionFee",
  "leaseRebate",
  "leaseConditionalRebate",
  // Zero Down Lease
  "zeroDownLeaseMonthlyPayment",
  "zeroDownLeaseTotalDueAtSigning",
  "zeroDownLeaseResidualValue",
  "zeroDownLeaseVehicleSalesPrice",
  "zeroDownLeaseTotalDueAtSigning",
  "zeroDownLeaseTotalDueAtSigningInclRebates",
  "zeroDownLeaseCapCost",
  "zeroDownLeaseCapCostReduction",
  "zeroDownLeaseNetAdjustedCapitalizedCost",
  "zeroDownLeaseTotalLeaseCharge",
  "zeroDownLeaseTotalPayments",
  "zeroDownLeaseSecurityDeposit",
  "zeroDownLeaseTerminationFee",
  "zeroDownLeaseMilesPerYear",
  "zeroDownLeaseAcquisitionFee",
  "zeroDownLeaseRebate",
  "zeroDownLeaseConditionalRebate",
  // Finance
  "financePayment",
  "amntFinanced",
  "financeDownPayment",
  "financeConditionalRebate",
  // Purchase
  "dealerPrice",
  "dealerDiscount",
  "accessoryPrice",
  "finalPrice",
  "savingsOffMSRP",
  "priceAfterConditionalRebates",
  "purchaseRebate",
  "conditionalRebate",
  // APR
  "aprPayment",
];
export const floatValueFields: string[] = [
  // vehicleInfo
  "monthlyPayment",
  "financePayment",
  "savingsOffMSRP",
  "msrp",
  // AV2-3795: vehicle info fields added
  "blackBookAverage",
  "blackBookClean",
  "kbbRetail",
  "kbbTradeIn",
  "nadaRetail",
  "nadatradeIn",
  "acquisitionFee",
  "invoicePrice",
  "suggestedDealerContribution",
  "finalPrice",
  "totalDueAtSigningInclRebates",
  "amntFinanced",
  "capCost",
  "totalPayments",
  "accountingCost",
  "advertisedPrice",
  "dealerPrice",
  "totalDueAtSigning",
  "dealerDiscount",
];
export const coopInstructions: ICoopInstruction[] = [
  {
    oem: "Acura",
    compliance_site: "http://www.acuracomplianceguide.com/login/login.cfm",
    instructions: ` Left side of page choose “Submit Pre-Approval”
  Step 1: “Select a Medium” then “select the appropriate sub-category”.
  Step 2: “Choose your PDF file to upload” then “YES or NO if running solely on dealer’s website”.
  Step 3: “Enter specific advertising information”
  Publication: file name from WorkFront.
  Start date: next day or actual date if later.
  End date: offer expiration date.
  Step 4: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  If YES, enter Previous Reference #
  Step 5: Verify the reply email is correct by checking box “This email address is correct”
  Enter email address to be cc’ed – coop@lithia.com and project manager submitting
  Enter phone number: xxx-xxx-xxxx
  Submit`,
  },
  {
    oem: "Audi",
    compliance_site: "https://www.accessaudi.com",
    instructions: ` top bar choose “APP LINKS” then “Audi Dealer Marketing Center” then login again.
  Right side of the page click “Dealer Co-op Access”
Select “Pre-Approval” from black bar at the top then “Submit Pre-Approvals”
Fund year: Current Year
Program Type: New Vehicle Fund
Email: coop@lithia.com
Choose “Media Category” and “Media Type”
Ad From: next day or actual date if later.
Ad To: offer expiration date.
Media Description: file name from WorkFront.
Click NEXT then select “Upload Documentation” (toward bottom of page) and click NEXT
Right hand side of page under “Submission Method” click “Upload Documentation”
Choose file and upload
  Submit your advertisement.
`,
  },
  {
    oem: "BMW",
    compliance_site:
      "https://cloud.brandmaster.com/mars/external.access?p_com_id=12878",
    instructions: `use “I am logging in as a Non-SSO User or Vendor”
  top bar choose “Co-op and Compliance” then “Retail Co-op”
  Under the “Browser Compatibility Information” click on “Start now”
  Choose “Co-Op Prior Reviews and Claims”
  Upper right corner click “New Activity” then “New Prior Review or Claim”.
  “Activity Name” enter file name from WorkFront.
  Update the “Activity Start Date” and “Activity End Date” – Start next day and end with offer expiration.
  “What Action to Take?” – choose Prior Review and Claim
  “Media Category” – select media then choose sub-category
  “Fund” – choose “BMW New Vehicle” or “BMW CPO”
  Select “Claim” in the bottom right corner
  Complete the 2nd tab “Prior Review Details”
  Update the 2nd column – “VIP Review” – regular
  “Supporting documents” – upload documents
  “Prior Review Status” – submit
  Save and close.`,
  },
  {
    oem: "Chrysler",
    compliance_site: "https://www.fcapap.com/Anonymous/Login.aspx",
    instructions: `top bar choose “Pre-Approval” then “PAP – submit PAP”
  Search for dealer under “Select a dealer”
  Email Notifications – add coop@lithia.com
  Does this Contain Wagoneer Advertising – choose “yes” or “no”
  Activity Start Date - next day or actual date if later.
  Activity End Date offer expiration date
  Choose “Media Category” and type under “Media”
  Activity Description – file name from WorkFront
  Upload File
  Submit`,
  },
  {
    oem: "Jeep",
    compliance_site: "https://www.fcapap.com/Anonymous/Login.aspx",
    instructions: `top bar choose “Pre-Approval” then “PAP – submit PAP”
  Search for dealer under “Select a dealer”
  Email Notifications – add coop@lithia.com
  Does this Contain Wagoneer Advertising – choose “yes” or “no”
  Activity Start Date - next day or actual date if later.
  Activity End Date offer expiration date
  Choose “Media Category” and type under “Media”
  Activity Description – file name from WorkFront
  Upload File
  Submit`,
  },
  {
    oem: "Dodge",
    compliance_site: "https://www.fcapap.com/Anonymous/Login.aspx",
    instructions: `top bar choose “Pre-Approval” then “PAP – submit PAP”
  Search for dealer under “Select a dealer”
  Email Notifications – add coop@lithia.com
  Does this Contain Wagoneer Advertising – choose “yes” or “no”
  Activity Start Date - next day or actual date if later.
  Activity End Date offer expiration date
  Choose “Media Category” and type under “Media”
  Activity Description – file name from WorkFront
  Upload File
  Submit`,
  },
  {
    oem: "RAM",
    compliance_site: "https://www.fcapap.com/Anonymous/Login.aspx",
    instructions: `top bar choose “Pre-Approval” then “PAP – submit PAP”
  Search for dealer under “Select a dealer”
  Email Notifications – add coop@lithia.com
  Does this Contain Wagoneer Advertising – choose “yes” or “no”
  Activity Start Date - next day or actual date if later.
  Activity End Date offer expiration date
  Choose “Media Category” and type under “Media”
  Activity Description – file name from WorkFront
  Upload File
  Submit`,
  },
  {
    oem: "Fiat",
    compliance_site: "https://www.fcapap.com/Anonymous/Login.aspx",
    instructions: `top bar choose “Pre-Approval” then “PAP – submit PAP”
  Search for dealer under “Select a dealer”
  Email Notifications – add coop@lithia.com
  Does this Contain Wagoneer Advertising – choose “yes” or “no”
  Activity Start Date - next day or actual date if later.
  Activity End Date offer expiration date
  Choose “Media Category” and type under “Media”
  Activity Description – file name from WorkFront
  Upload File
  Submit`,
  },
  {
    oem: "Honda",
    compliance_site: "https://www.hondacomplianceguide.com/login/login.cfm",
    instructions: `Left side of page choose “Submit Pre-Approval”
  Step 1: “Select a Medium” then “select the appropriate sub-category”.
  Step 2: “Choose your PDF file to upload” then “YES or NO if running solely on dealer’s website”.
  Step 3: “Enter specific advertising information”
  Publication: file name from WorkFront.
  Start date: next day or actual date if later.
  End date: offer expiration date.
  Step 4: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  If YES, enter Previous Reference #
  Step 5: Verify the reply email is correct by checking box “This email address is correct”
  Enter email address to be cc’ed – coop@lithia.com and project manager submitting
  Enter phone number: xxx-xxx-xxxx
    Submit `,
  },
  {
    oem: "Mazda",
    compliance_site: "https://portal.mazdausa.com/mnaofed/sps/auth",
    instructions: `middle of the top bar select “Resources”
  ·       Under Dealer Resources – select “Sales & Marketing”

  ·       Scroll down to “Marketing Resources” and choose “Mazda Co-op Advertising Program”

  ·       Right side of screen under “Mazda Co-op Advertising Program” – choose “Submit Sales Pre-Approval”

  Step 1: “Select a Medium” then “select the appropriate sub-category”.

  Step 2: “Choose your PDF file to upload” then “YES or NO if running solely on dealer’s website”.

  Step 3: “Enter appropriate advertising information”

  ·       Publication: file name from WorkFront.

  ·       Start date: next day or actual date if later.

  ·       End date: offer expiration date.

  Step 4: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “

  ·       If YES, enter Previous Reference #

  Step 5: Verify the reply email is correct by checking box “This email address is correct”

  ·       Enter email address to be cc’ed – coop@lithia.com and project manager submitting

  ·       Enter phone number: xxx-xxx-xxxx

  Submit your advertisement.

  `,
  },
  {
    oem: "Cadillac",
    compliance_site: "https://www.gmlam.com/login.asp",
    instructions: `- top bar choose “Cadillac” then “Cadillac Pre-Approvals”
  ·       Click “Request Pre-Approval for a New Ad”

  ·       Enter email address

  ·       Choose Media Type and click “Continue”

  ·       Enter Campaign Name, Template ID and Brief Description (can all be the same info)

  ·       Click “Continue”

  ·       Enter Media Outlet Name (i.e. Dealer.com)

  ·       Choose Start and End Dates

  ·       Click “Add to List” and then “Continue”

  ·       Review information and click “Upload Documents”

  ·       Attach files and click “Upload Document”`,
  },
  {
    oem: "Hyundai",
    compliance_site:
      "https://www.tpminsight.com/ClientLoginPages/HyundaiNewVehicle/Login.aspx",
    instructions: `Left side “Submit a Prior Approval Request”
  Choose Dealer from menu
  Program – this will default
  Notifications – Select Recipient – Kelsey LaGasse (do not change)
  Prior Approval Name/Desc – file name from WorkFront
  Select Media
  Upload File
  Submit`,
  },
  {
    oem: "Genesis",
    compliance_site:
      "https://www.tpminsight.com/ClientLoginPages/HyundaiNewVehicle/Login.aspx",
    instructions: `Left side “Submit a Prior Approval Request”
  Choose Dealer from menu
  Program – this will default
  Notifications – Select Recipient – Kelsey LaGasse (do not change)
  Prior Approval Name/Desc – file name from WorkFront
  Select Media
  Upload File
  Submit`,
  },
  {
    oem: "Infiniti",
    compliance_site:
      "https://retaileradvertising.nnanet.com/login/internal_login.cfm",
    instructions: ` top bar or lower right corner  “Submit Pre-Approval”
  Step 1: Choose the dealer from drop down menu
  Step 2: “Select a Medium” then “select the appropriate sub-category”.
  Step 3: “Choose your PDF file to upload”
  Step 4: “Enter appropriate advertising information”
  Advertising Source: file name from WorkFront.
  Start date: next day or actual date if later.
  End date: offer expiration date.
  Step 5: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  If YES, enter Previous Reference #
  Step 6: Verify the reply email is correct by checking box “This email address is correct”
  Enter email addresses to be cc’ed – coop@lithia.com and project manager submitting
  Submit your advertisement. `,
  },
  {
    oem: "Kia",
    compliance_site: "https://www.kiamarketingprogram.com/Account/Login",
    instructions: `choose “Pre-Approval” from the top bar left side, then “Submit Pre-Approval”
  Select a Dealer from drop down on far right (Dealer Number and Dealer Name not needed)
  Activity start Date - next day or actual date if later.
  Activity End Date - offer expiration date.
  Select “Media Source” and “Media Type”
  Description - file name from WorkFront
  Upload File
  Submit`,
  },
  {
    oem: "Lincoln",
    compliance_site: "https://lincolnadcovenant.dealerconnection.com",
    instructions: `middle of screen in the picture choose “Submit Pre-approval”
  Step 1: “Select a Medium” then “select the appropriate sub-category”.
  Step 2: “Choose your PDF file to upload”
  Step 3: “Enter appropriate advertising information”
  Advertising Source: file name from WorkFront.
  Start date: next day or actual date if later.
  End date: offer expiration date.
  Step 4: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  If YES, enter Previous Reference #
  Step 5: “Does this ad contain Lincoln Black Label advertising?” – select “No”.
  Step 6: Verify the reply email is correct by checking box “This email address is correct”
  Enter email addresses to be cc’ed – coop@lithia.com and project manager submitting
  Submit your advertisement. `,
  },
  {
    oem: "Mercedes",
    compliance_site: "https://mercedes.adcompliance.com/login/login.cfm",
    instructions: `middle of page click “Online Pre-Approval”
  Step 1: Choose the dealer from drop down menu
  Step 2: “Select a Medium” then “select the appropriate sub-category”.
  Step 3: “Choose your PDF file to upload”
  Step 4: “Enter appropriate advertising information”
  Advertising Source: file name from WorkFront.
  Start date: next day or actual date if later.
  End date: offer expiration date.
  Step 5: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  If YES, enter Previous Reference #
  Step 6: Verify the reply email is correct by checking box “This email address is correct”
  Enter email addresses to be cc’ed – coop@lithia.com and project manager submitting
  Submit your advertisement. `,
  },
  {
    oem: "Mini",
    compliance_site: "https://minidealermarketingportal.brandmaster.com",
    instructions: `use “I am logging in as a Non-SSO User or Vendor”
  top bar choose “Co-op and Compliance” then “Retail Co-op”
Under the “Browser Compatibility Information” click on “Start now”
Choose “Co-Op Prior Reviews and Claims”
Upper right corner click “New Activity” then “New Prior Review or Claim”.
“Activity Name” enter file name from WorkFront.
Update the “Activity Start Date” and “Activity End Date” – Start next day and end with offer expiration.
“What Action to Take?” – choose Prior Review and Claim
“Media Category” – select media then choose sub-category
“Fund” – choose “MINI New Vehicle” or “MINI CPO”
Select “Claim” in the bottom right corner
Complete the 2nd tab “Prior Review Details”
Update the 2nd column – “VIP Review” – regular
“Supporting documents” – upload documents
“Prior Review Status” – submit
Save and close.`,
  },
  {
    oem: "Porsche",
    compliance_site: "",
    instructions: `Enter “Dealer Marketing Platform”
  Top bar choose “Compliance Approvals” then “Enter Compliance Approval”
  Choose dealer from drop down menu – “Program” should default
  “Spend” - (will need to get from Marketing Manager)
  “Description” - file name from WorkFront
  Activity start Date - next day or actual date if later.
  Activity End Date - offer expiration date.
  Select “Activity Type” and “Sub-activity Type”
  Description - file name from WorkFront
  Right side of page will list documents required – then “Upload File”
  Submit Compliance Approval`,
  },
  {
    oem: "Volkswagen",
    compliance_site: "vwadlab.net",
    instructions: `Select “Submit preapproval”
  Step 1 – Dealer you are submitting for should be listed here

  Step 2 – “Your E-mail Address” Enter coop@lithia.com and project manager submitting

  Step 3 – “Choose the Program you wish to submit your pre-approval for” – this should default

  Step 4 - “Select a Medium” then “select the appropriate sub-category”.

  Step 5 – “Choose your PDF file to upload”

  Step 6 - “Enter appropriate advertising information”

  Advertising Source: file name from WorkFront.

  Start date: next day or actual date if later.

  End date: offer expiration date.

  Step 7 - “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  If YES, enter Previous Reference #

  Submit your advertisement.
  `,
  },
  {
    oem: "Ford",
    compliance_site: "https://fordcoop.dealerconnection.com/login/login.cfm",
    instructions: `Left side of banner – choose “Submit a Pre-Approval”
  Step 1: “Select a Medium” then “select the appropriate sub-category”.
  Step 2: “Choose your PDF file to upload”
  Step 3: “Enter appropriate advertising information”
  •   Advertising Source: file name from WorkFront.
  •   Start date: next day or actual date if later.
  •   End date: offer expiration date.
  Step 4: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  •   If YES, enter Previous Reference #
  Step 5: “Is this advertisement targeting a multi-cultural market?” – select “No”.
  Step 6: Verify the reply email is correct by checking box “This email address is correct”
  •   Enter email addresses to be cc’ed – coop@lithia.com and project manager submitting
  Submit your advertisement. `,
  },
  {
    oem: "Toyota",
    compliance_site: "https://tdmc.toyotacompliance.com/",
    instructions: `Middle of page under TOOLS “Prior Approval Tools”
  Submit a Prior Approval

 Choose the dealer from drop down menu

 Enter Activity Dates, start next day or actual date if later and end with the ad expiration date

 Select Activity Type

 “Activity Description” enter WF job number here

 Upload supporting documentation for prior approval

  Submit for prior approval`,
  },
  {
    oem: "INFINITI",
    compliance_site: "https://retaileradvertising.nnanet.com/",
    instructions: `top bar or lower right corner  “Submit Pre-Approval”
  Step 1: Choose the dealer from drop down menu

  Step 2: “Select a Medium” then “select the appropriate sub-category”.

  Step 3: “Choose your PDF file to upload”

  Step 4: “Enter appropriate advertising information”

  ·       Advertising Source: file name from WorkFront.

  ·       Start date: next day or actual date if later.

  ·       End date: offer expiration date.

  Step 5: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “

  ·       If YES, enter Previous Reference #

  Step 6: Verify the reply email is correct by checking box “This email address is correct”

  ·       Enter email addresses to be cc’ed – coop@lithia.com and project manager submitting

  Submit your advertisement.`,
  },
  {
    oem: "Subaru",
    compliance_site: "https://www.sdccoop.com",
    instructions: `Middle of page select “Submit Pre-Approval
  Step 1: “Select a Medium” then “select the appropriate sub-category”

  Step 2: “Choose your PDF file to upload” then “YES or NO if running solely on dealer’s website”

  Step 3: “Enter appropriate advertising information

  •   Publication: file name from WorkFront

  •   Start date: next day or actual date if later

  •   End date: offer expiration date

  Step 4: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO

  •   If YES, enter Previous Reference

  Step 5: Verify the reply email is correct by checking box “This email address is correct

  •   Enter email address to be cc’ed – coop@lithia.com and project manager submittin

  •   Enter phone number: xxx-xxx-xxx
  Submit your advertisement
  `,
  },
  {
    oem: "Subaru",
    compliance_site: "https://www.subaruadfund.com",
    instructions: `Middle of page select “Submit Pre-Approval” then “Submit Pre-Approval” again

  Step 1: “Select a Medium” then “select the appropriate sub-category”.

  Step 2: “Choose your PDF file to upload” then “YES or NO if running solely on dealer’s website”.

  Step 3: “Enter appropriate advertising information”
  •   Publication: file name from WorkFront.
  •   Start date: next day or actual date if later.
  •   End date: offer expiration date.
  Step 4: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  •   If YES, enter Previous Reference #

  Step 5: Verify the reply email is correct by checking box “This email address is correct”
  •   Enter email address to be cc’ed – coop@lithia.com and project manager submitting
  •   Enter phone number: xxx-xxx-xxxx

  Submit your advertisement
  `,
  },
  {
    oem: "Subaru",
    compliance_site: "https://www.subarunet.com",
    instructions: `Upper right-hand corner – confirm you have the correct dealer
  Left side of page choose “SAF – Subaru Advertising Fund”
  Middle of page – choose “Submit Pre-approval” then “Submit Pre-Approval” again
  Step 1: “Select a Medium” then “select the appropriate sub-category”.
  Step 2: “Choose your PDF file to upload” then “YES or NO if running solely on dealer’s website”.
  Step 3: “Enter appropriate advertising information”
  •   Publication: file name from WorkFront.
  •   Start date: next day or actual date if later.
  •   End date: offer expiration date.
  Step 4: “Is this a re-submission of a previously declined pre-approval?”  Choose “YES or NO “
  •   If YES, enter Previous Reference #
  Step 5: Verify the reply email is correct by checking box “This email address is correct”
  •   Enter email address to be cc’ed – coop@lithia.com and project manager submitting
  •   Enter phone number: xxx-xxx-xxxx
  Submit your advertisement
  `,
  },
  {
    oem: "Misc.", // this is for exotic cars/ones that are not supported
    compliance_site: "",
    instructions: `Please email creatives to regional rep.`,
  },
];
