import { chunk } from "lodash";

export const promiseAllBatched = async <T>(
  funcs: (() => Promise<T>)[],
  batchSize: number,
): Promise<T[]> => {
  const batches = chunk(funcs, batchSize);
  let result: T[] = [];

  for (const batch of batches) {
    const batchResponses = await Promise.all(batch.map(f => f()));
    result = result.concat(batchResponses);
  }

  return result;
};
