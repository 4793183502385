import { useState, useEffect } from "react";
import { Button, Drawer, Space, message } from "antd";
import { IFacebookCampaign } from "screens/adLibrary/facebookUtils/types";
import Select from "./flipperDrawer/Select";
import { useFlipFacebookCampaigns } from "./shared/hooks/useMutateFbCampaign";
import { useSelectedStore } from "shared/hooks/userPreferences/useSelectedStore";
import { getDateRange } from "./shared/utils/helpers.ad";

const FlipperDrawer = ({
  storeName,
  showDrawer,
  setShowDrawer,
  campaigns,
  flipWithAds,
  setFlipWithAds,
  setSelectedDateRange,
}: {
  storeName: string;
  showDrawer: boolean;
  setShowDrawer: (showDrawer: boolean) => void;
  campaigns: IFacebookCampaign[];
  flipWithAds: boolean;
  setFlipWithAds: (flipWithAds: boolean) => void;
  setSelectedDateRange: (dateRange: [number, number]) => void;
}) => {
  const [flipped, setFlipped] = useState<boolean>(false);
  const [targetDate, setTargetDate] = useState<string>();
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
  const onClose = () => {
    setFlipped(false);
    setCheckedKeys([]);
    setShowDrawer(false);
  };
  const onClickDone = () => {
    onClose();
    const dateRange = getDateRange(targetDate as string);
    dateRange && setSelectedDateRange(dateRange);
  };

  const {
    isLoading,
    isSuccess,
    mutate: flipFacebookCampaigns,
  } = useFlipFacebookCampaigns();
  const { selectedStore } = useSelectedStore();
  const fbAccountId = selectedStore?.fbAccountId || "";

  useEffect(() => {
    if (isSuccess) {
      message.success("Successfully flipped campaigns");
      setFlipped(true);
    }
  }, [isSuccess]);

  const onClickFlip = () => {
    const selectedCampaigns: Record<React.Key, React.Key[]> = {};
    checkedKeys.forEach(key => {
      if (key in selectedCampaigns) return;
      const campaign = campaigns.find(campaign => campaign.id === key);
      if (campaign) {
        selectedCampaigns[key] = [];
      } else {
        const adset = campaigns
          .map(campaign => campaign?.adsets?.data || [])
          .flat()
          .find(adset => adset.id === key);
        if (!adset) return;
        if (adset?.campaign_id in selectedCampaigns) {
          selectedCampaigns[adset.campaign_id].push(key);
        } else {
          selectedCampaigns[adset.campaign_id] = [adset.id];
        }
      }
    });
    flipFacebookCampaigns({
      fbAccountId,
      selectedCampaigns,
      flipWithAds,
      flipDate: targetDate,
    });
  };

  const isFlipDisabled = !fbAccountId || !checkedKeys.length || !targetDate;
  return (
    <Drawer
      title={<strong>Flipper</strong>}
      placement="right"
      visible={showDrawer}
      bodyStyle={{
        display: "flex",
        padding: "0",
      }}
      width="95%"
      footer={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          {!flipped ? (
            <Button
              type="primary"
              disabled={isFlipDisabled}
              loading={isLoading}
              onClick={onClickFlip}
              data-cy="feed-next-btn"
            >
              Flip
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={onClickDone}
              data-cy="feed-next-btn"
            >
              Done
            </Button>
          )}
        </Space>
      }
      footerStyle={{ display: "flex", justifyContent: "flex-end" }}
      onClose={onClose}
    >
      <Select
        storeName={storeName}
        campaigns={campaigns}
        flipWithAds={flipWithAds}
        setFlipWithAds={setFlipWithAds}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        setTargetDate={setTargetDate}
      />
    </Drawer>
  );
};

export default FlipperDrawer;
