import Table, {
  ColumnType,
  TablePaginationConfig,
  TableProps,
} from "antd/lib/table";
import {
  FilterValue,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import VirtualTable, { VirtualTableProps } from "../VirtualTable";
import { useDataListURLContext } from "./dataListURLContext";
import { RecordWithId } from "./useDataListURLData";
import { useDataListURLFilters } from "./useDataListURLFilters";
import { useDataListURLIds } from "./useDataListURLIds";
import { useDataListURLSorter } from "./useDataListURLSorter";
import { getAllAdIds, isColumnKeyInFields } from "./utils";
import { HoverActionsVirtualTable } from "../HoverActionsVirtualTable/HoverActionsVirtualTable";

export type ITableColumnURL<FieldKey extends string, RecordType> = Omit<
  ColumnType<RecordType>,
  "sorter" | "sortOrder" | "filteredValue" | "onFilter"
> & {
  key: FieldKey;
  title: string;
  dataIndex?: string[] | string;
  exportFn?: (record: RecordType) => string;
  columnPosition?: number;
};

type Props<FieldKey extends string, RecordType> =
  | {
      columns: ITableColumnURL<FieldKey, RecordType>[] | undefined;
      fieldKeys: FieldKey[];
      disabledRows?: { [recordId: string]: string };
    } & (
      | (Omit<VirtualTableProps<RecordType>, "record" | "columns"> & {
          type: "virtual" | "hoverActionsVirtualTable";
        })
      | (Omit<TableProps<RecordType>, "record" | "columns"> & {
          type: "normal";
        })
    );

const DataListURLTable = <
  FieldKey extends string,
  RecordType extends RecordWithId,
>(
  props: Props<FieldKey, RecordType>,
) => {
  const { fieldKeys, columns } = props;
  const { fields } = useDataListURLContext<FieldKey, RecordType>();
  const { sortKey, sortOrder, sortItems } = useDataListURLSorter<
    FieldKey,
    RecordType
  >();
  const { filters, setFilters } = useDataListURLFilters<FieldKey, RecordType>();
  const { selectedIds, toggleSelectedIds, toggleSelectAll } =
    useDataListURLIds<RecordType>(getAllAdIds);

  const addFilterAndSorter = (
    column: ITableColumnURL<FieldKey, RecordType>,
  ) => {
    const { key } = column;
    const sorter = typeof fields[key]?.sorterFn === "function";
    const filteredValue = isEmpty(filters[key]) ? null : filters[key];

    return {
      ...column,
      sorter,
      filteredValue,
      sortOrder: sortKey === key ? sortOrder : undefined,
    };
  };

  const dataListColumns = columns?.map(column => addFilterAndSorter(column));

  const onChange = (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
    extra: TableCurrentDataSource<RecordType>,
  ) => {
    props.onChange?.(pagination, filters, sorter, extra);

    if (extra.action === "filter") {
      setFilters(tableFilters);
    } else if (
      extra.action === "sort" &&
      !Array.isArray(sorter) &&
      isColumnKeyInFields<FieldKey>(sorter.columnKey, fieldKeys)
    ) {
      sortItems(sorter.columnKey, sorter.order);
    }
  };

  const SelectedTable = useCallback(
    (selectedTableProps: Props<FieldKey, RecordType>) => {
      if (selectedTableProps.type === "hoverActionsVirtualTable") {
        return <HoverActionsVirtualTable<RecordType> {...selectedTableProps} />;
      }
      if (selectedTableProps.type === "virtual") {
        return <VirtualTable<RecordType> {...selectedTableProps} />;
      }
      return <Table<RecordType> {...selectedTableProps} />;
    },
    [],
  );
  const handleToggleSelectAll = () => {
    // When selecting all, we want to select all the items in the table
    if (selectedIds.length === 0) {
      const dataSourceCopy = (props.dataSource || []).filter(
        row => !props.disabledRows || !props.disabledRows[row.id],
      );

      toggleSelectAll(dataSourceCopy);
    } else {
      toggleSelectAll([]);
    }
  };

  return (
    <SelectedTable
      {...props}
      rowKey={record => record.id}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onSelectAll: handleToggleSelectAll,
        onSelectNone: handleToggleSelectAll,
        onSelect: record => toggleSelectedIds(record.id),
        onSelectMultiple: (_selected, _selectedRecords, changedRecords) => {
          toggleSelectedIds(changedRecords.map(record => record.id));
        },
        ...props.rowSelection,
      }}
      columns={dataListColumns}
      onChange={onChange}
    />
  );
};

export default DataListURLTable;
