import {
  CopyOutlined,
  EditOutlined,
  InboxOutlined,
  LoginOutlined,
  RocketOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { User } from "redux/auth/auth.slice";
import { useIsAdmin } from "shared/hooks/useIsAdmin";
import { OrdersTableAction } from "shared/types/assetBuilder";
import { INewOrderTableRecord, StatusOptions } from "shared/types/newOrders";
import { isEnvVarEquals } from "utils/helpers";

type Props = {
  record: INewOrderTableRecord;
  type: OrdersTableAction;
  user?: User;
  loading?: boolean;

  onClick: () => void;
};
const ActionButton = (props: Props) => {
  const getTitleAndIcon = (type: OrdersTableAction) => {
    switch (type) {
      case "edit":
        return {
          title: "Edit Order",
          icon: <EditOutlined />,
        };
      case "modify":
        return {
          title: "Asset Builder",
          icon: <LoginOutlined />,
        };
      case "launcher":
        return {
          title: "Web Asset Launcher",
          icon: <RocketOutlined />,
        };
      case "duplicate":
        return {
          title: "Duplicate Order",
          icon: <CopyOutlined />,
        };
      case "archive":
        return {
          title: "Archive Order",
          icon: <InboxOutlined />,
        };
      case "restore":
        return {
          title: "Restore Order",
          icon: <UndoOutlined />,
        };
    }
  };

  const isAdmin = useIsAdmin();

  const isInternalEnv = isEnvVarEquals("CLIENT", "internal");

  const disable = (actionType: OrdersTableAction) => {
    switch (actionType) {
      case "edit":
      case "archive":
      case "duplicate":
      case "restore":
        return !isAdmin;
      case "modify":
        return false;
      case "launcher":
        const disableInternalLaunch =
          isInternalEnv &&
          ![
            StatusOptions.COOP_APPROVED,
            StatusOptions.COOP_NOT_REQUIRED,
          ].includes(props.record.status || StatusOptions.NO_STATUS);
        return !isAdmin || disableInternalLaunch;
    }
  };

  const disabled = disable(props.type);
  const { title, icon } = getTitleAndIcon(props.type);

  return (
    <Tooltip
      title={
        disabled
          ? "This feature is disabled for this account. Contact your team lead to request access."
          : title
      }
    >
      <Button
        // We need to disabled pointer event when the button is disabled otherwise the tooptip around it wont work properly. Refer https://github.com/ant-design/ant-design/issues/9581#issuecomment-599668648
        style={disabled ? { pointerEvents: "none" } : {}}
        data-cy={`${props.type}-action-button`}
        type="primary"
        icon={icon}
        disabled={disabled}
        onClick={props.onClick}
        loading={props.loading}
      />
    </Tooltip>
  );
};

export default ActionButton;
