import { Tooltip, Typography } from "antd";

interface IProps {
  text: string;
  charDisplayLimit?: number;
  className?: string;
}

const EllipsisText = ({ text, className, charDisplayLimit = 15 }: IProps) => {
  if (!text) return null;

  if (text.length <= charDisplayLimit) {
    return <Typography.Text className={className}>{text}</Typography.Text>;
  }

  return (
    <Tooltip title={text}>
      <Typography.Text className={className}>
        {text.substr(0, charDisplayLimit - 1)}...
      </Typography.Text>
    </Tooltip>
  );
};

export default EllipsisText;
