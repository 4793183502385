import { LoadingOutlined } from "@ant-design/icons";
import { Collapse, notification, Spin, message } from "antd";
import { useState, useCallback, FC, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import {
  IAssetBuilderState,
  IAssetBuild,
  IAssetBuildInstance,
  OfferData,
  IAssetInstance,
  SelectedInstance,
} from "shared/types/assetBuilder";
import { IDisclosure } from "shared/types/legalLingo";
import {
  ITemplate,
  IDesignStudioState,
  ITemplateTag,
  FetchingDataStatus,
  TTemplateType,
} from "shared/types/designStudio";
import { assetBuildInstanceToAssetInstance } from "utils/helpers.asset";

import actions from "../../../../redux/rootActions";

import SizeSelection from "./buildSteps/SizeSelection";
import DisclosureSelection from "./buildSteps/DisclosureSelection";
import OfferSelection from "./buildSteps/OfferSelection";
import TemplateSelection from "./buildSteps/TemplateSelection";
import ImageSelection from "./buildSteps/ImageSelection";

import "../../../../shared/styles/Collapse.scss";
import "./BuildSteps.scss";
import { IAccount } from "shared/types/accountManagement";
import { OfferType } from "shared/types/shared";
import { isPharma } from "utils/helpers";
import { useFetchCanvasJsonObjects } from "shared/hooks/useFetchCanvasJsonObjects";
import {
  getCanvasJsonUrlsFromInstances,
  hasThemeBackgroundSlot,
} from "./buildSteps/utils";

export type TBuildStep =
  | "SELECT_SIZES"
  | "SELECT_DISCLOSURE"
  | "SELECT_OFFER"
  | "SELECT_TEMPLATE"
  | "SELECT_IMAGES";

const buildStepsForAuto: Array<{ title: string; step: TBuildStep }> = [
  {
    title: "Select Asset Sizes",
    step: "SELECT_SIZES",
  },
  {
    title: "Select Disclosure",
    step: "SELECT_DISCLOSURE",
  },
  {
    title: "Select Offer",
    step: "SELECT_OFFER",
  },
  {
    title: "Select Template",
    step: "SELECT_TEMPLATE",
  },
  {
    title: "Select Image",
    step: "SELECT_IMAGES",
  },
];

const buildStepsForPharma: Array<{ title: string; step: TBuildStep }> = [
  {
    title: "Select Asset Sizes",
    step: "SELECT_SIZES",
  },
  {
    title: "Select Module",
    step: "SELECT_OFFER",
  },
  {
    title: "Select Template",
    step: "SELECT_TEMPLATE",
  },
  {
    title: "Select Image",
    step: "SELECT_IMAGES",
  },
];

// The order of the step goes as follows
//  - SELECT_SIZES -> SELECT_OFFER -> SELECT_TEMPLATE -> ACTIONS
// NOTE:
//  - An empty array indicates no step was processed. It is when user first time access the Build page.
//  - Un-processed step collapse will be in gray color to indicate it has not been processed

interface BuildStepsProps {
  assetBuild: IAssetBuild;
  selections: Record<string, Record<string, boolean>>; // asset type -> size -> boolean
  selectedTab: string | undefined;
  templatesBySize: Record<string, ITemplate[]>;
  savedOrder: IAssetBuilderState["savedOrder"];
  buildPage: IAssetBuilderState["buildPage"];
  hasNextPage: boolean | undefined;
  loadingTemplates: boolean;
  dealerResult: IAccount;
  savedSelectTemplateSearch: string;
  savedSelectImageSearch: string;

  tags: ITemplateTag[];
  fetchingData: FetchingDataStatus;

  selectedFilterTags: string[] | null;
  shouldUpdateThemeImage: boolean;
  missingOfferTypes: string[];
  activeStep?: string;
}

interface BuildStepsHandlers {
  toggleSize: (size: string) => void;
  removeFilterTemplate: (selectedTab: string, filterOption: string) => void;
  updateAssetInstance: (
    instance: IAssetInstance | IAssetInstance[],
    assetType: string,
    size: string,
    order?: number,
  ) => void;
  setSelectedAssetBuildInstance: (args: SelectedInstance[]) => void;
  getDealer: (dealerName: string) => void;
  saveSelectTemplateSearch: (searcyBy: string) => void;
  saveSelectImageSearch: (searcyBy: string) => void;
  deleteInstance: (args: { assetType: string; size: string }) => void;
  filterTemplateTags: (tags: string[] | null) => void;
  fetchData: (dataType: FetchingDataStatus, query?: string) => void;
  setShouldUpdateThemeImage: (shouldUpdateThemeImage: boolean) => void;
  triggerAssetInstanceUpdate: () => void;
  setActiveStep: (step: TBuildStep) => void;
}

const BuildSteps: FC<BuildStepsProps & BuildStepsHandlers> = props => {
  const [selectedInstance] = props.buildPage.selectedInstance;
  const assetBuildInstance = selectedInstance?.instance;

  const canvasJsonUrls = useMemo(
    () => getCanvasJsonUrlsFromInstances(props.assetBuild.instances),
    [props.assetBuild.instances],
  );
  const canvasJsonResults = useFetchCanvasJsonObjects(canvasJsonUrls);
  const successfulCanvasJsonObjects = useMemo(() => {
    return canvasJsonResults
      .filter(result => result.status === "success")
      .map(result => result.data);
  }, [canvasJsonResults]);

  const selectedInstanceCanvasJsonObject = useMemo(
    () =>
      successfulCanvasJsonObjects.find(item => {
        const { url } = item || {};
        return selectedInstance?.instance?.template?.canvasJsonUrl === url;
      })?.canvasJson,
    [
      selectedInstance?.instance?.template?.canvasJsonUrl,
      successfulCanvasJsonObjects,
    ],
  );
  const currentTemplateHasThemeBackgroundSlot = Boolean(
    selectedInstanceCanvasJsonObject &&
      hasThemeBackgroundSlot(selectedInstanceCanvasJsonObject),
  );

  const { setSelectedAssetBuildInstance, updateAssetInstance } = props;
  const syncAssetInstance = useCallback(
    (inst: IAssetBuildInstance) => {
      if (!selectedInstance) return;

      const { assetType, order, size } = selectedInstance;
      setSelectedAssetBuildInstance([
        {
          instance: inst,
          assetType,
          order,
          size,
        },
      ]);
      const assetInstance = assetBuildInstanceToAssetInstance(inst);

      if (!assetInstance) return;

      updateAssetInstance(assetInstance, assetType, size, order);
    },
    [selectedInstance, setSelectedAssetBuildInstance, updateAssetInstance],
  );

  const [showTemplateCountTooltip, setTemplateCounterTooltip] = useState<{
    assetType: string;
    size: string;
  }>();

  const [searchBy, setSearchBy] = useState<string>(
    props?.savedOrder?.selectedOrder?.selectTemplateCollapseSearchInput || "",
  );

  const [numBackgroundImages, setNumBackgroundImages] = useState<number>(0);

  useEffect(() => {
    setSearchBy(
      props.savedSelectTemplateSearch ? props.savedSelectTemplateSearch : "",
    );
  }, [props.savedSelectTemplateSearch]);

  useEffect(() => {
    if (
      props?.savedOrder?.selectedOrder?.selectTemplateCollapseSearchInput &&
      !props.savedSelectTemplateSearch
    ) {
      setSearchBy(
        props?.savedOrder?.selectedOrder?.selectTemplateCollapseSearchInput ||
          "",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedTemplateType, setSelectedTemplateType] =
    useState<TTemplateType>("carcut");

  const filterTemplatesByNameFn = useCallback(
    template => {
      // check template name matches
      const isTemplateNameMatch = template.name
        .toLowerCase()
        .includes(searchBy.toLowerCase());

      let isTagsMatch = true;
      for (const tag of props.selectedFilterTags || []) {
        if (!template.tags.includes(tag)) {
          isTagsMatch = false;
        }
      }

      return isTemplateNameMatch && isTagsMatch;
    },
    [props.selectedFilterTags, searchBy],
  );

  const filterTemplatesByTypeFn = useCallback(
    template => {
      return (
        template.type === selectedTemplateType ||
        (!template.type && selectedTemplateType === "carcut")
      );
    },
    [selectedTemplateType],
  );

  const templates = useMemo(
    () =>
      getFilteredTemplates(
        props.templatesBySize?.[selectedInstance?.size || ""] || [],
        selectedInstance?.instance?.selectedOffer?.offerData?.make ||
          "No Dealer Oem Exists",
        props.dealerResult?.state,
        assetBuildInstance,
      )
        .filter(filterTemplatesByTypeFn)
        .filter(filterTemplatesByNameFn),
    [
      assetBuildInstance,
      filterTemplatesByNameFn,
      filterTemplatesByTypeFn,
      selectedInstance?.instance?.selectedOffer?.offerData?.make,
      selectedInstance?.size,
      props.dealerResult?.state,
      props.templatesBySize,
    ],
  );

  const [selectedImageType, setSelectedImageType] = useState(() => {
    const isNew =
      selectedInstance?.instance?.selectedOffer?.offerData?.vehicleCondition;

    return isNew ? "brand" : "account";
  });

  const disclosureUpdate = useCallback(
    async (disclosure: {
      allSizes?: IDisclosure;
      currentSize?: IDisclosure;
      selectedAsset?: IDisclosure;
    }) => {
      if (!selectedInstance) {
        notification.warning({
          message: "Instance not selected",
          description:
            "An instance has to be selected in order to choose offer.",
          placement: "bottomRight",
        });

        return;
      }

      // sizeToUpdate is the size string that will be checked for which specific size to update
      // we have (within a certain assetType) options for updating all sizes, current size or just the selected asset
      // if the selected asset option is chosen we'll skip the loop and just update that one asset's disclosure
      // only if currentSize is available, we'll do a check for if the size matches before updating

      const sizeToUpdate = disclosure.currentSize ? selectedInstance.size : "";

      if (disclosure.allSizes || disclosure.currentSize) {
        const assetInstances = props.assetBuild.instances;

        const instanceType =
          Object.keys(assetInstances).find(
            insType => insType === selectedInstance.assetType,
          ) || "";
        const selectedSizes = Object.keys(assetInstances[instanceType]).filter(
          size => !sizeToUpdate || sizeToUpdate === size,
        );

        // generate assetInstance by size, and batch update to prevent race condition
        selectedSizes.forEach(size => {
          const assetInstancesBatch = assetInstances[instanceType][size]
            .map(asset => {
              const instance = { ...asset, disclosure };
              const assetInstance = assetBuildInstanceToAssetInstance(instance);
              return assetInstance ?? null;
            })
            .filter(Boolean) as IAssetInstance[];
          props.updateAssetInstance(
            assetInstancesBatch,
            selectedInstance.assetType,
            size,
          );
        });
      }
      if (disclosure.selectedAsset) {
        props.triggerAssetInstanceUpdate();
        if (assetBuildInstance)
          syncAssetInstance({ ...assetBuildInstance, disclosure });
      }

      return;
    },
    [props, assetBuildInstance, syncAssetInstance, selectedInstance],
  );

  const buildSteps = isPharma ? buildStepsForPharma : buildStepsForAuto;
  return (
    <Collapse
      expandIconPosition="right"
      accordion={true}
      activeKey={props.activeStep}
      onChange={key => props.setActiveStep(key as TBuildStep)}
    >
      {buildSteps.map(buildStep => {
        const isDisabledPanel = !getEnabledBuildSteps(
          props,
          currentTemplateHasThemeBackgroundSlot,
        ).includes(buildStep.step);

        return (
          <Collapse.Panel
            key={`${buildStep.step}`}
            className={`filter-section-container-panel build-steps ${
              isDisabledPanel ? "disabled" : "enabled"
            } ${buildStep.step}`}
            collapsible={isDisabledPanel ? "disabled" : undefined}
            header={
              <div>
                {getTitle(
                  buildStep,
                  props,
                  assetBuildInstance,
                  numBackgroundImages,
                )}
              </div>
            }
          >
            {buildStep.step === "SELECT_SIZES" && (
              <SizeSelection
                loading={props.loadingTemplates}
                selections={props.selections}
                selectedTab={props.selectedTab}
                templatesBySize={props.templatesBySize}
                savedOrder={props.savedOrder}
                toggleSize={props.toggleSize}
                removeFilterTemplate={props.removeFilterTemplate}
                showTemplateCountTooltip={showTemplateCountTooltip}
                toggleTemplateCountTooltip={args => {
                  setTemplateCounterTooltip(args);
                }}
                deleteInstance={args => {
                  props.deleteInstance(args);
                }}
              />
            )}

            {buildStep.step === "SELECT_DISCLOSURE" && (
              <DisclosureSelection
                onDisclosureChange={disclosureUpdate}
                assetBuildInstance={assetBuildInstance}
              ></DisclosureSelection>
            )}

            {buildStep.step === "SELECT_OFFER" && (
              <OfferSelection
                offers={props.assetBuild.offers}
                instance={assetBuildInstance}
                onOfferTypeChange={(vin, offerType, checked) => {
                  // Before selecting offer type,
                  // there must be an assetBuild instance selected from
                  // one of the cards.
                  if (!selectedInstance) {
                    // build instance has not been selected.
                    notification.warning({
                      message: "Instance not selected",
                      description:
                        "An instance has to be selected in order to choose offer.",
                      placement: "bottomRight",
                    });

                    return;
                  }

                  const offerData = props.assetBuild.offers.find(
                    offer => offer.offerData.vin === vin,
                  )?.offerData as OfferData;

                  if (!offerData) {
                    throw new Error(`offerData with vin = ${vin} must exist.`);
                  }

                  // if checked, add offerType into instance.selectedOffer.offerTypes, otherwise remove it.
                  if (!assetBuildInstance?.selectedOffer) {
                    const filteredTemplates = getFilteredTemplates(
                      props.templatesBySize?.[selectedInstance?.size || ""] ||
                        [],
                      offerData.make,
                      props.dealerResult?.state,
                      assetBuildInstance,
                    )
                      .filter(filterTemplatesByTypeFn)
                      .filter(filterTemplatesByNameFn);

                    syncAssetInstance({
                      ...assetBuildInstance,
                      template: getTemplateToUse(
                        assetBuildInstance,
                        searchBy,
                        filteredTemplates,
                      ),
                      selectedOffer: {
                        vin,
                        offerData,
                        offerTypes: [offerType],
                        purchaseOptions: [],
                      },
                    });

                    return;
                  }

                  if (offerType === "PurchasePlaceholder") {
                    const filteredTemplates = getFilteredTemplates(
                      props.templatesBySize?.[selectedInstance?.size || ""] ||
                        [],
                      offerData.make,
                      props.dealerResult?.state,
                      assetBuildInstance,
                    )
                      .filter(filterTemplatesByTypeFn)
                      .filter(filterTemplatesByNameFn);
                    if (checked) {
                      syncAssetInstance({
                        ...assetBuildInstance,
                        template: getTemplateToUse(
                          assetBuildInstance,
                          searchBy,
                          filteredTemplates,
                        ),
                        selectedOffer: {
                          ...assetBuildInstance.selectedOffer,
                          vin,
                          offerData,
                          offerTypes: [
                            ...assetBuildInstance.selectedOffer.offerTypes,
                          ].concat(["PurchasePlaceholder"]),
                          // AV2-2963: Order Stamps based on offerType selection order
                          offerSelectSortingTracker: [
                            ...(assetBuildInstance.selectedOffer
                              .offerSelectSortingTracker ||
                              assetBuildInstance.selectedOffer.offerTypes),
                            "PurchasePlaceholder",
                          ],
                        },
                      });
                    } else {
                      const updatedOfferTypes =
                        assetBuildInstance.selectedOffer.offerTypes.filter(
                          existingOfferType =>
                            existingOfferType !== "PurchasePlaceholder",
                        );

                      // If no offers are selected for the selected instance, we need to reset the selectedOffer field.
                      let updatedSelectedOffer = undefined;
                      if (updatedOfferTypes.length > 0) {
                        updatedSelectedOffer = {
                          ...assetBuildInstance.selectedOffer,
                          vin,
                          offerData,
                          offerTypes: updatedOfferTypes,
                          purchaseOptions: [],
                          offerSelectSortingTracker:
                            assetBuildInstance.selectedOffer.offerSelectSortingTracker?.filter(
                              ot => !ot.includes("Purchase"),
                            ),
                        };
                      }

                      syncAssetInstance({
                        ...assetBuildInstance,
                        template: getTemplateToUse(
                          assetBuildInstance,
                          searchBy,
                          filteredTemplates,
                        ),
                        selectedOffer: updatedSelectedOffer,
                      });
                    }

                    return;
                  }

                  if (checked) {
                    props.triggerAssetInstanceUpdate();

                    const newOfferTypes =
                      assetBuildInstance.selectedOffer.offerTypes.filter(
                        offerType =>
                          !props.missingOfferTypes.includes(offerType),
                      );

                    syncAssetInstance({
                      ...assetBuildInstance,
                      selectedOffer: {
                        ...assetBuildInstance.selectedOffer,
                        vin,
                        offerData,
                        offerTypes: [...newOfferTypes, offerType],
                        offerSelectSortingTracker: [
                          ...(assetBuildInstance.selectedOffer
                            .offerSelectSortingTracker ||
                            assetBuildInstance.selectedOffer.offerTypes),
                          offerType,
                        ],
                      },
                    });
                  } else {
                    const updatedOfferTypes =
                      assetBuildInstance.selectedOffer.offerTypes.filter(
                        existingOfferType => existingOfferType !== offerType,
                      );

                    const updatedOfferTrackers = (
                      assetBuildInstance.selectedOffer
                        .offerSelectSortingTracker || []
                    ).filter(
                      existingOfferType => existingOfferType !== offerType,
                    );

                    syncAssetInstance({
                      ...assetBuildInstance,
                      selectedOffer:
                        updatedOfferTypes.length === 0
                          ? undefined
                          : {
                              ...assetBuildInstance.selectedOffer,
                              vin,
                              offerData,
                              offerTypes: updatedOfferTypes,
                              offerSelectSortingTracker: updatedOfferTrackers,
                            },
                    });
                  }
                }}
                onPurchaseOptionChange={(vin, options) => {
                  // Before selecting offer type,
                  // there must be an assetBuild instance selected from
                  // one of the cards.

                  const offerData = props.assetBuild.offers.find(
                    offer => offer.offerData.vin === vin,
                  )?.offerData as OfferData;

                  if (!offerData) {
                    throw new Error(`offerData with vin = ${vin} must exist.`);
                  }

                  if (!selectedInstance) {
                    // build instance has not been selected.
                    notification.warning({
                      message: "Instance not selected",
                      description:
                        "An instance has to be selected in order to choose offer.",
                      placement: "bottomRight",
                    });

                    return;
                  }

                  if (!assetBuildInstance?.selectedOffer) {
                    // this should not happen...
                    // we could just retun here but will be better if we address the issue
                    throw new Error(
                      `purchase options should not bes selectable when assetBuildInstance.selectedOffer is not valid.`,
                    );
                  }

                  // AV2-2963: Order Stamps based on offerType selection order
                  let newTracker = [
                    ...(assetBuildInstance.selectedOffer
                      .offerSelectSortingTracker || []),
                  ];

                  const prevOptions =
                    assetBuildInstance.selectedOffer.purchaseOptions;

                  const firstPurchaseSelect =
                    prevOptions.length === 0 && options.length === 1;
                  const purchasesCleared =
                    prevOptions.length > 0 && options.length === 0;
                  const purchaseOptionsChanged =
                    prevOptions.length !== options.length;

                  if (firstPurchaseSelect) {
                    const placeholderIndex = newTracker.indexOf(
                      "PurchasePlaceholder",
                    );
                    newTracker.splice(placeholderIndex, 1, options[0]);
                  } else if (purchasesCleared) {
                    const purchaseIndex = newTracker.findIndex(ot =>
                      ot.includes("Purchase"),
                    );
                    newTracker.splice(purchaseIndex, 1, "PurchasePlaceholder");
                  } else if (purchaseOptionsChanged) {
                    const valueAdded = options.length > prevOptions.length;
                    const bigger = valueAdded ? options : prevOptions;
                    const smaller = valueAdded ? prevOptions : options;
                    const difference = bigger.filter(
                      ot => !smaller.includes(ot),
                    );
                    newTracker = !valueAdded
                      ? newTracker.filter(
                          ot =>
                            !ot.includes("Purchase") ||
                            options.includes(ot as OfferType),
                        )
                      : [...newTracker, difference[0]];
                  }

                  const filteredTemplates = getFilteredTemplates(
                    props.templatesBySize?.[selectedInstance?.size || ""] || [],
                    offerData.make,
                    props.dealerResult?.state,
                    assetBuildInstance,
                    options,
                  )
                    .filter(filterTemplatesByTypeFn)
                    .filter(filterTemplatesByNameFn);

                  syncAssetInstance({
                    ...assetBuildInstance,
                    template: getTemplateToUse(
                      assetBuildInstance,
                      searchBy,
                      filteredTemplates,
                    ),
                    selectedOffer: {
                      ...assetBuildInstance.selectedOffer,
                      offerSelectSortingTracker: newTracker,
                      purchaseOptions: options,
                    },
                  });
                }}
              />
            )}

            {buildStep.step === "SELECT_TEMPLATE" && (
              <TemplateSelection
                tags={props.tags}
                fetchingData={props.fetchingData}
                fetchData={props.fetchData}
                filterTemplateTags={props.filterTemplateTags}
                selectedFilterTags={props.selectedFilterTags}
                instance={assetBuildInstance}
                templates={templates}
                onTemplateSelect={template => {
                  syncAssetInstance({
                    ...assetBuildInstance,
                    template,
                    lifestyleFabricImageJson: undefined,
                  });
                }}
                getDealer={props.getDealer}
                dealerResult={props.dealerResult}
                saveSelectTemplateSearch={props.saveSelectTemplateSearch}
                buildPage={props.buildPage}
                searchBy={searchBy}
                setSearchBy={searchBy => {
                  setSearchBy(searchBy);
                }}
                selectedTemplateType={selectedTemplateType}
                setSelectedTemplateType={type => {
                  setSelectedTemplateType(type);
                }}
              />
            )}

            {buildStep.step === "SELECT_IMAGES" && (
              <ImageSelection
                template={assetBuildInstance?.template}
                selectedImageType={selectedImageType}
                setSelectedImageType={setSelectedImageType}
                saveSelectImageSearch={props.saveSelectImageSearch}
                savedSelectImageSearch={props.savedSelectImageSearch}
                currOrder={props?.savedOrder?.selectedOrder}
                shouldUpdateThemeImage={props.shouldUpdateThemeImage}
                setShouldUpdateThemeImage={props.setShouldUpdateThemeImage}
                setNumBackgroundImages={setNumBackgroundImages}
                selectedInstances={props.buildPage.selectedInstance}
              />
            )}
          </Collapse.Panel>
        );
      })}
    </Collapse>
  );
};

// As a quality of life improvement, if there is only one template available as a result of narrowing down through the search bar,
// we will automatically select that template for the user.
// as long as the user has not already selected a template.
const getTemplateToUse = (
  instance: IAssetBuildInstance | undefined,
  searchBy: string,
  templateList: ITemplate[],
) => {
  if (instance?.template) return instance?.template;

  return searchBy && templateList.length === 1 ? templateList[0] : undefined;
};

const getFilteredTemplates = (
  unfilteredTemplates: ITemplate[],
  make: string,
  dealerState: string,
  assetBuildInstance: IAssetBuildInstance | undefined,
  options?: OfferType[],
) => {
  const resTemplates: ITemplate[] = [];
  unfilteredTemplates.forEach(template => {
    const shouldIncludeTemplate =
      template.oems.length === 0
        ? true
        : template.oems.some(value => {
            return make.toLowerCase() === value.toLowerCase();
          });

    const shouldIncludeTemplateState =
      template.state && dealerState ? dealerState === template.state : true;

    // convert to set to remove duplicates offer types which affects the offer type length, which affects how many templates show up
    const offerTypes = Array.from(
      new Set(assetBuildInstance?.selectedOffer?.offerTypes || []),
    );

    const purchaseOptions =
      options || assetBuildInstance?.selectedOffer?.purchaseOptions || [];

    /**
     * "PurchasePlaceholder" indicates if the purchase checkbox has been checked or not.
     * But when we save the order, we remove this type and just concatenate all selected offer types.
     * And when the order has been fetched,
     */
    let offerTypeLength = offerTypes.length + purchaseOptions.length;

    if (offerTypes.includes("PurchasePlaceholder")) offerTypeLength -= 1;
    const shouldIncludeTemplateOffer =
      offerTypeLength <= 1
        ? template.numOfStamps <= 1
        : offerTypeLength === template.numOfStamps;

    if (
      shouldIncludeTemplate &&
      shouldIncludeTemplateState &&
      shouldIncludeTemplateOffer
    ) {
      resTemplates.push(template);
    }
  });

  return resTemplates;
};

const getTitle = (
  buildStep: { title: string; step: TBuildStep },
  props: BuildStepsProps,
  assetBuildInstance: IAssetBuildInstance | undefined,
  numBackgroundImages: number,
) => {
  const [selectedInstance] = props.buildPage.selectedInstance;

  switch (buildStep.step) {
    case "SELECT_OFFER":
      return `${buildStep.title} (${props.assetBuild.offers.length})`;
    case "SELECT_TEMPLATE":
      const make =
        selectedInstance?.instance?.selectedOffer?.offerData?.make ||
        "No Account Brand Exists";

      const filteredTemplates: ITemplate[] = getFilteredTemplates(
        props.templatesBySize?.[selectedInstance?.size || ""] || [],
        make,
        props.dealerResult?.state,
        assetBuildInstance,
      );

      if (
        selectedInstance?.instance?.selectedOffer &&
        !props.loadingTemplates &&
        !filteredTemplates.length &&
        !props.hasNextPage
      ) {
        message.warning("No templates available");
      }

      return (
        <div>
          {`${buildStep.title} (${filteredTemplates.length})`}
          <Spin
            style={{ marginLeft: "1em" }}
            indicator={<LoadingOutlined rev={undefined} />}
            spinning={props.loadingTemplates}
          />
        </div>
      );

    case "SELECT_IMAGES":
      return numBackgroundImages > 0
        ? `${buildStep.title} (${numBackgroundImages})`
        : `${buildStep.title}`;

    default:
      return buildStep.title;
  }
};

const getEnabledBuildSteps = (
  props: BuildStepsProps,
  currentTemplateHasThemeBackgroundSlot: boolean,
) => {
  const selectedInstances = props.buildPage.selectedInstance;
  const instance = selectedInstances[0]?.instance;
  const updatedEnabledBuildSteps: TBuildStep[] = ["SELECT_SIZES"];

  // SELECT_OFFER is enabled if there is at lease one size attribute is true
  if (selectedInstances.length === 1) {
    for (const assetType in props.selections) {
      for (const size in props.selections[assetType]) {
        if (
          props.selectedTab === assetType &&
          props.selections[assetType][size]
        ) {
          updatedEnabledBuildSteps.push("SELECT_OFFER");
          !isPharma && updatedEnabledBuildSteps.push("SELECT_DISCLOSURE");
        }
      }
    }
  }

  // template is enabled if there is at least one offer selected
  if (selectedInstances.length === 1) {
    if (
      (instance?.selectedOffer?.offerTypes.length || 0) > 0 ||
      (instance?.selectedOffer?.purchaseOptions.length || 0) > 0
    ) {
      updatedEnabledBuildSteps.push("SELECT_TEMPLATE");
    }
  }

  // if selected template has type 'lifestyle', enable image selection
  if (
    instance?.template?.type === "lifestyle" ||
    instance?.template?.artboard?.theme_images ||
    currentTemplateHasThemeBackgroundSlot
  ) {
    updatedEnabledBuildSteps.push("SELECT_IMAGES");
  }

  return updatedEnabledBuildSteps;
};

const mapStateToProps = (state: any) => {
  const { assetBuilder, dealerManagement, designStudio } = state;
  const {
    buildPage,
    savedSelectTemplateSearch,
    savedSelectImageSearch,
    missingOfferTypes,
  } = assetBuilder;

  const { dealerResult } = dealerManagement;

  const { tags, fetchingData, selectedFilterTags } =
    designStudio as IDesignStudioState;

  return {
    buildPage,
    missingOfferTypes,
    dealerResult: dealerResult?.dealer,

    savedSelectTemplateSearch,
    savedSelectImageSearch,
    tags,
    fetchingData,
    selectedFilterTags,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    filterTemplateTags: (tags: string[] | null) => {
      dispatch(actions.designStudio.filterTemplateTags(tags));
    },
    fetchData: (dataType: FetchingDataStatus, query = "") => {
      dispatch(actions.designStudio.fetchData(dataType, query));
    },
    setSelectedAssetBuildInstance: (args: SelectedInstance[]) => {
      dispatch(actions.assetBuilder.setSelectedAssetBuildInstance(args));
    },
    getDealer: (dealerName: string) => {
      dispatch(actions.dealerManagement.getDealer(dealerName));
    },
    saveSelectTemplateSearch: (searchBy: string) => {
      dispatch(actions.assetBuilder.saveSelectTemplateSearch(searchBy));
    },
    saveSelectImageSearch: (searchBy: string) => {
      dispatch(actions.assetBuilder.saveSelectImageSearch(searchBy));
    },
    triggerAssetInstanceUpdate: () => {
      dispatch(actions.assetBuilder.triggerAssetInstanceUpdate());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BuildSteps);
