import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { Key, useCallback, useState } from "react";
import { useAssetBatchesContext } from "../../../shared/contexts/AssetBatchesContext";
import styles from "./AddMediaDropdown.module.scss";

const options = [
  {
    key: "template",
    text: "Add template",
    value: "template",
  },
  {
    key: "video",
    text: "Add video",
    value: "video",
  },
];

export const AddMediaDropdown = () => {
  const { setOpenFileManager, addTemplateBlocked } = useAssetBatchesContext();
  const [optionSelected, setOptionSelected] = useState(options[0]);

  const onSelectClick = useCallback(
    (key: Key) => {
      if (key === "template") {
        setOpenFileManager(true);
      }
    },
    [setOpenFileManager],
  );

  const onOptionSelected = useCallback(
    (key: Key) => {
      const option = options.find(({ key: optionKey }) => optionKey === key);
      if (option) {
        setOptionSelected(option);
        onSelectClick(option.key);
      }
    },
    [onSelectClick, setOptionSelected],
  );

  const menu = (
    <Menu onClick={e => onOptionSelected(e.key)}>
      {options.map(({ key, text }) => (
        <Menu.Item key={key}>{text}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown.Button
      disabled={addTemplateBlocked}
      className={styles.dropdown}
      onClick={() => onSelectClick(optionSelected.key)}
      overlay={menu}
      data-cy="cta-button-select"
      icon={<DownOutlined />}
    >
      {optionSelected.text}
    </Dropdown.Button>
  );
};
