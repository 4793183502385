import { Menu } from "antd";
import { ToolbarButton } from "shared/types/adLibrary";
import { keys } from "shared/types/shared";
import { ToolbarButtonKey } from "shared/types/toolbar";
import styles from "./ContextTableActions.module.scss";
import { parseContextMenuActions } from "./utils";

const isActionDestructive = (itemKey: ToolbarButtonKey) => {
  const destructiveActions: ToolbarButtonKey[] = ["Delete"];
  return destructiveActions.includes(itemKey);
};
export interface Props<T> {
  getItemsToRender: (record: T) => ToolbarButton;
  record: T;
}

export function ContextTableActions<T>({ getItemsToRender, record }: Props<T>) {
  const parsedItems = parseContextMenuActions(getItemsToRender, record);
  return (
    <div className={styles.container}>
      <Menu
        selectedKeys={[]}
        data-cy="context-table-actions"
        forceSubMenuRender
      >
        {keys(parsedItems).map(itemKey => {
          const menuText = parsedItems[itemKey]?.extraInfo?.title
            ? parsedItems[itemKey]?.extraInfo?.title
            : itemKey;
          return (
            <Menu.Item
              className={styles.menuItem}
              key={itemKey}
              style={isActionDestructive(itemKey) ? { color: "red" } : {}}
              onClick={parsedItems[itemKey]?.onClick}
              disabled={parsedItems[itemKey]?.disabled}
            >
              {parsedItems[itemKey]?.icon}
              {menuText}
            </Menu.Item>
          );
        })}
      </Menu>
    </div>
  );
}
