import { IDataTableError } from "shared/types/errors";

export interface IAccountManagementState {
  processingDealers: boolean;
  dealersMessage: string;
  dealerPaginationKey: string;
  dealerRecords: IAccountRecord[];
  result:
    | IGetAccountsResult
    | ICreateAccountResult
    | IDeleteAccountResult
    | IEditAccountResult
    | null;
  error: null | IDataTableError;
  dealerResult: { dealer: IAccount | null } | null;
}
export interface ICreateAccountResult {
  createdDealer: IAccount;
}
export interface IUpdateAccountResult {
  editedDealer: IAccount;
}

export interface IGetAccountsResult {
  dealers: IAccount[];
  paginationKey?: IAccountPaginationKeyObject;
  scannedCount: number;
}

interface IDeleteAccountResult {
  deletedOem: IAccount;
}

interface IEditAccountResult {
  createdDealer: IAccount;
}

export interface IAccountResult {
  result: { dealer: IAccount } | null;
  error: any | null;
}

/**
 * Constellation Store data
 * Note: Dealer is a legacy term, now we call it Store (or Ad Account in non auto clients)
 */
export interface IAccount {
  /** The acount name. This is the primary key */
  dealer_name: string;
  dealer_code: string;
  /** Comma separated list of Brands associated with the account */
  dealer_oem: string;
  dealer_url: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone_number: string;
  final_price_name: string;
  logo_url: string;
  new_dealer_name?: string;
  logo_urls_from_S3?: string | null;
  web_int_positions?: IAccountPosition[];
  labels?: AccountUrl[];
  coopDetails?: {
    emailOrPortal: string;
    coopSite: string;
    coopEmail: string;
    coopUsername: string;
    coopPassword: string;
    coopDealerCode: string;
    coopLoginLocked: boolean;
  };
  details: {
    facebook: AccountDetailsFacebook;
    google?: {
      adAccountCID: string;
    };
  };
  created_at: number;
  updated_at: number;
  /** Whether the store is enabled or not */
  enabled: boolean;
  offerlogix_source_code?: string;
}

export interface IAccountPosition {
  positionModifer: string;
  mobilePositionModifer: string;
  location: string;
  mobileLocation: string;
  name: string;
  relativePath: string;
}
interface IAccountPaginationKeyObject {
  dealer_name: string;
}
export interface IAccountRecord {
  key: number;
  dealerName: string;
  dealerCode: string;
  dealerOem: string;
  dealerUrl: string;
  dealerAddress: string;
  dealerCity: string;
  dealerUsState: string;
  dealerZip: string;
  dealerPhoneNumber: string;
  dealerFinalPriceName: string;
  newDealerName?: string;
  logoUrl: string;
  logoUrlsFromS3?: {
    horizontalImagesFromS3: string[];
    verticalImagesFromS3: string[];
    squareImagesFromS3: string[];
    horizontalEventImagesFromS3: string[];
    verticalEventImagesFromS3: string[];
    squareEventImagesFromS3: string[];
  };
  coopDetails?: {
    emailOrPortal: string;
    coopSite: string;
    coopEmail: string;
    coopUsername: string;
    coopPassword: string;
    coopDealerCode: string;
    coopLoginLocked: boolean;
  };
  webIntPositions?: IAccountPosition[];
  launchLabel?: string;
  labels?: AccountUrl[];
  details: {
    facebook: AccountDetailsFacebook;
    google?: { adAccountCID: string };
  };
  createdAt?: number;
  updatedAt?: number;
  enabled?: boolean;
  offerlogixSourceCode?: string;
  ttl?: number;
}

export interface ICreateAccountResponse {
  result: null | ICreateAccountResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface IUpdateAccountResponse {
  result: null | IUpdateAccountResult;
  error: null | IDataTableError;
  statusCode: number;
}
export interface IGetAccountsResponse {
  result: null | IGetAccountsResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface IGetAccount {
  dealer: null | IAccount;
}

export interface IDeleteAccountResponse {
  result: null | IDeleteAccountResult;
  error: null | IDataTableError;
  statusCode: number;
}

export interface IDeleteAccountsResponse {
  result: null | IAccount[];
  error: null | IDataTableError;
  statusCode: number;
}

export interface IEditAccountResponse {
  result: null | IEditAccountResult;
  error: null | IDataTableError;
  statusCode: number;
  sendMessage?: boolean;
}

export type AccountUrl = {
  name: string;
  url: string;
};

export type AccountDetailsFacebook = {
  fbPageId: string;
  fbAccountId: string;
  fbPixelId: string;
  fbCatalogId: string;
  fbInstagramId: string;
};

export const initialAccountObj: IAccountRecord = {
  key: 0,
  dealerName: "",
  dealerCode: "",
  dealerOem: "",
  dealerUrl: "",
  logoUrl: "",
  newDealerName: "",
  dealerAddress: "",
  dealerCity: "",
  dealerUsState: "",
  dealerZip: "",
  dealerPhoneNumber: "",
  dealerFinalPriceName: "",
  logoUrlsFromS3: {
    horizontalImagesFromS3: [],
    verticalImagesFromS3: [],
    squareImagesFromS3: [],
    horizontalEventImagesFromS3: [],
    verticalEventImagesFromS3: [],
    squareEventImagesFromS3: [],
  },
  webIntPositions: [],
  coopDetails: {
    emailOrPortal: "",
    coopSite: "",
    coopEmail: "",
    coopUsername: "",
    coopPassword: "",
    coopDealerCode: "",
    coopLoginLocked: false,
  },
  details: {
    facebook: {
      fbPageId: "",
      fbAccountId: "",
      fbCatalogId: "",
      fbPixelId: "",
      fbInstagramId: "",
    },
  },
};
