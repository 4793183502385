import ShareOutlined from "shared/icons/ShareOutlined";

import { AdReviewTableEntry } from "shared/types/campaignManagement";

import { AdType } from "screens/adLibrary/facebookUtils/types";
import Link from "antd/lib/typography/Link";
import Paragraph from "antd/lib/typography/Paragraph";
import { isMultipleUrls } from "../../../utils/helpers.ad";

interface IProps {
  selectedEntry?: AdReviewTableEntry;
  destinationUrl?: string;
}

type DestinationUrlProps = IProps;

const DestinationUrl = ({
  selectedEntry,
  destinationUrl: propDestinationUrl,
}: DestinationUrlProps) => {
  const actualDestinationUrl =
    selectedEntry?.destinationUrl || propDestinationUrl || "";
  const [url] = actualDestinationUrl.split("\n") ?? "";
  const { adFormat } = selectedEntry || {};

  const MultipleDestinationUrls = () => (
    <ul>
      {selectedEntry?.destinationUrl.split("\n").map((url, index) => (
        <li key={index}>
          <SingleDestinationUrl link={url} />
        </li>
      ))}
    </ul>
  );
  const SingleDestinationUrl = ({ link }: { link: string }) => (
    <>
      {link}
      <Link href={link} target="_blank">
        <ShareOutlined style={{ marginLeft: 8, cursor: "pointer" }} />
      </Link>
    </>
  );

  return (
    <>
      {isMultipleUrls(actualDestinationUrl) && adFormat === AdType.Carousel && (
        <MultipleDestinationUrls />
      )}
      {isMultipleUrls(actualDestinationUrl) && adFormat !== AdType.Carousel && (
        <SingleDestinationUrl link={url} />
      )}
      {!isMultipleUrls(actualDestinationUrl) && (
        <SingleDestinationUrl link={actualDestinationUrl} />
      )}
      {adFormat === AdType.AIA && (
        <Paragraph>
          Clicking on each product will bring you directly to the VDP of that
          product
        </Paragraph>
      )}
    </>
  );
};

export default DestinationUrl;
