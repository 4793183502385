import { AdLibraryTableColumn } from "shared/types/adLibrary";
import { CampaignPlannerInstance, CampaignPlannerInstanceAd } from "../types";
import { Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import styles from "../CampaignPlannerInstanceDetail.module.scss";
import StatusSelector from "screens/adLibrary/library/adList/adTableListContainer/StatusSelector";
import {
  AdCampaignStatusMap,
  AdStatusDropdownStates,
  getAdCampaignStatusColor,
} from "../utils";
import {
  CampaignAdStatus,
  UpdatePlannerInstanceAdParams,
} from "shared/types/campaignPlanner";

export const getPlannerInstanceColumns: (
  instance: CampaignPlannerInstance,
  plannerId: string,
  onStatusChange: (update: UpdatePlannerInstanceAdParams) => void,
) => AdLibraryTableColumn<CampaignPlannerInstanceAd>[] = (
  instance,
  plannerId,
  onStatusChange,
) => [
  {
    key: "source",
    title: "Source",
    dataIndex: ["source"],
    render: (source: string) => (
      <Tag color={source == instance.name ? "green" : undefined}>{source}</Tag>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined color={filtered ? "#1890ff" : undefined} />
    ),
    width: 250,
    className: styles.source,
    columnPosition: 1,
  },
  {
    key: "campaignStatus",
    title: "Campaign Status",
    dataIndex: ["campaignStatus"],
    className: styles.statusSelector,
    render: (_, ad) => (
      <StatusSelector<CampaignAdStatus>
        status={AdCampaignStatusMap[ad.campaignStatus].value}
        statusOptions={AdStatusDropdownStates[ad.campaignStatus]}
        getStatusColor={getAdCampaignStatusColor}
        onStatusChange={status =>
          onStatusChange({
            adId: ad.id,
            campaignStatus: status,
            instanceId: instance.id,
            plannerId: plannerId,
          })
        }
      />
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined color={filtered ? "#1890ff" : undefined} />
    ),
    width: 250,
    columnPosition: 1,
  },
];
