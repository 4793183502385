import API from "services";
import useDeepEffect from "shared/hooks/useDeepEffect";
import { IStamp, ITemplate, TabMenu } from "shared/types/designStudio";
import { v4 as uuid } from "uuid";

type TUseResourceArgs = {
  target: {
    type?: TabMenu;
    id?: string;
  };
  selectedOfferType?: string;
  dataLoadStart?: () => void;
  dataLoadEnd?: (
    resource?: IStamp[] | ITemplate,
    selectedStamp?: IStamp,
    errorMessage?: string,
  ) => void;
  canvasJsonFetchStart?: (url: string) => void;
  canvasJsonFetchEnd?: (json?: any, errorMessage?: string) => void;
};

export default (args: TUseResourceArgs) => {
  useDeepEffect(() => {
    args.dataLoadStart?.();
    switch (args.target.type) {
      case "templates":
        if (args.target.id) {
          API.services.designStudio
            .getTemplateById(args.target.id)
            .then(response => {
              const { result, error } = response;

              if (error || !result) {
                args.dataLoadEnd?.(
                  undefined,
                  undefined,
                  error?.message || "Template was not able to be loaded.",
                );

                return;
              }

              const { template } = result;
              args.dataLoadEnd?.(template);

              const { canvasJsonUrl } = template;
              if (!canvasJsonUrl) {
                args.canvasJsonFetchEnd?.();

                return;
              }

              const url = `${canvasJsonUrl}?timestamp=${Date.now()}`;
              args.canvasJsonFetchStart?.(url);

              fetch(url)
                .then(response => response.json())
                .then(json => {
                  if (!json) return json;

                  const objects = json.objects ? [...json.objects] : [];
                  return {
                    ...json,
                    objects: objects.map(obj => ({
                      ...obj,
                      name: obj.name || uuid(),
                    })),
                  };
                })
                .then(json => args.canvasJsonFetchEnd?.(json))
                .catch(() =>
                  args.canvasJsonFetchEnd?.(
                    undefined,
                    "There was an error while loading template.",
                  ),
                );
            });
        }
        break;

      case "stamps":
        if (!args.selectedOfferType) break;
        if (args.target.id) {
          API.services.designStudio
            .getStampById(args.target.id)
            .then(response => {
              const { result, error } = response;
              if (error || !result) {
                args.dataLoadEnd?.(
                  undefined,
                  undefined,
                  error?.message || "Template was not able to be loaded.",
                );

                return;
              }

              const { stamps } = result;

              const selectedStamp = stamps.find(
                stmp => stmp.offerType === args.selectedOfferType,
              );
              const stamp = selectedStamp || stamps[0];

              args.dataLoadEnd?.(stamps, stamp);

              const { stampJsonUrl: canvasJsonUrl } = stamp || {};
              if (!canvasJsonUrl) {
                args.canvasJsonFetchEnd?.(undefined, undefined);
                return;
              }

              const url = `${canvasJsonUrl}?timestamp=${Date.now()}`;
              args.canvasJsonFetchStart?.(url);

              fetch(url)
                .then(response => response.json())
                .then(json => args.canvasJsonFetchEnd?.(json))
                .catch(() =>
                  args.canvasJsonFetchEnd?.(
                    undefined,
                    "There was an error while loading template.",
                  ),
                );
            });
        }
        break;

      default:
        break;
    }
  }, [args.selectedOfferType]);
};
