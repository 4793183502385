import { useQuery } from "react-query";
import API from "services";

export const useFetchFilterPresets = () => {
  return useQuery(["filter-presets"], () => fetchFilterPresets());
};

const fetchFilterPresets = async () => {
  const { result, error } =
    await API.services.designStudio.fetchFilterPresets();

  if (error) {
    throw error;
  }

  const { presets } = result;

  return presets;
};
