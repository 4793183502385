import { useState } from "react";
import { Drawer } from "antd";
import { DRAWER_WIDTH } from "../adLoad/adLoadDrawer/shared/constants";
import { usePMaxDataContext } from "shared/components/contextAPI/pmaxAssetGroup/PMaxDataProvider";
import { useFetchDealer } from "shared/hooks/useFetchDealers";
import { getComputedSelectionItems } from "./utils";
import { PmaxAdReviewItem, UploadResults } from "./shared/types";
import { PmaxUpdateDrawerFooter } from "./pmaxLoadDrawer/PmaxUpdateDrawerFooter";
import { IAccountRecord } from "shared/types/accountManagement";
import { PmaxLoadStep } from "./pmaxLoadDrawer/PmaxLoadStep";
import { PmaxSelectStep } from "./pmaxLoadDrawer/PmaxSelectStep";
import { PmaxReviewStep } from "./pmaxLoadDrawer/PmaxReviewStep";
import { SelectStepFooter } from "./pmaxLoadDrawer/SelectStepFooter";
import { ReviewStepFooter } from "./pmaxLoadDrawer/ReviewStepFooter";
import { LoadStepFooter } from "./pmaxLoadDrawer/LoadStepFooter";

export enum PmaxLoadTab {
  create = "create",
  update = "update",
}

export type LoadDrawerStep = "select" | "review" | "load";

const PmaxLoadDrawer = () => {
  const {
    assetGroupSelection,
    delayCloseLoadDrawer,
    closeLoadDrawer,
    selectedDataSource,
    isLoading,
    isLoadDrawerOpen,
    onLoadToGoogleAds,
    uploadResults,
  } = usePMaxDataContext();
  const [loadStep, setLoadStep] = useState<LoadDrawerStep>("select");
  const { data: dealer, isLoading: isLoadingDealer } = useFetchDealer(
    assetGroupSelection.selection[0]?.dealerName,
  );

  const onClose = () => {
    // Allow autofocus animation to complete before closing the drawer if no ad accounts are selected
    (!activeKey || activeKey === PmaxLoadTab.create) &&
    !assetGroupSelection.selection.length
      ? delayCloseLoadDrawer(500)
      : closeLoadDrawer();

    assetGroupSelection.resetSelection();
    setLoadStep("select");
  };

  const reviewItems = dealer
    ? getComputedSelectionItems(assetGroupSelection.selection, dealer)
    : [];

  const createTabDisabled = selectedDataSource.every(
    data => data.feedStatus === "Deleted from feed",
  );

  const updateTabDisabled = selectedDataSource.every(
    data => data.pmaxStatus === "Never Loaded",
  );

  const previewDisabled = !reviewItems.length || isLoading;
  const loadDisabled =
    createTabDisabled ||
    reviewItems.some(item => item.destination.hasUrlError) ||
    isLoading;

  const defaultTab =
    createTabDisabled && updateTabDisabled
      ? undefined
      : createTabDisabled
      ? PmaxLoadTab.update
      : PmaxLoadTab.create;
  const [activeKey, setActiveKey] = useState(defaultTab);

  const { Content, Footer } = getPmaxStructure(activeKey, loadStep);
  return (
    <Drawer
      closable={false}
      destroyOnClose
      visible={isLoadDrawerOpen}
      width={DRAWER_WIDTH}
      maskClosable={false}
      afterVisibleChange={visible => visible && setActiveKey(defaultTab)}
      bodyStyle={{ padding: 0 }}
      footer={
        <Footer
          loadStep={loadStep}
          uploadResults={uploadResults}
          reviewItems={reviewItems}
          onClose={onClose}
          previewDisabled={previewDisabled}
          loadDisabled={loadDisabled}
          onGoToAdLoadReview={() => setLoadStep("review")}
          onGoToAssetGroupSelection={() => setLoadStep("select")}
          onLoadToGoogleAds={() => {
            onLoadToGoogleAds();
            setLoadStep("load");
          }}
        />
      }
      onClose={onClose}
    >
      <Content
        reviewItems={reviewItems}
        dealer={dealer}
        defaultTab={defaultTab}
        createTabDisabled={createTabDisabled}
        updateTabDisabled={updateTabDisabled}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
      />
    </Drawer>
  );
};

export default PmaxLoadDrawer;

export type PmaxCreateAssetGroupFooterProps = {
  loadStep: LoadDrawerStep;
  previewDisabled: boolean;
  loadDisabled: boolean;
  uploadResults: UploadResults;
  reviewItems: PmaxAdReviewItem[];
  onGoToAdLoadReview: () => void;
  onGoToAssetGroupSelection: () => void;
  onClose: () => void;
  onLoadToGoogleAds: () => void;
};

export type PmaxLoadDrawerContentProps = {
  loadStep: LoadDrawerStep;
  reviewItems: PmaxAdReviewItem[];
  dealer: IAccountRecord | undefined;
  isLoadingDealer: boolean;
  defaultTab: PmaxLoadTab | undefined;
  createTabDisabled: boolean;
  updateTabDisabled: boolean;
  activeKey: PmaxLoadTab | undefined;
  setActiveKey: (key: PmaxLoadTab) => void;
};

const getPmaxStructure = (
  activeKey: PmaxLoadTab | undefined,
  loadStep: LoadDrawerStep,
) => {
  if (activeKey === PmaxLoadTab.update) {
    return {
      Content: PmaxSelectStep,
      Footer: PmaxUpdateDrawerFooter,
    };
  }
  switch (loadStep) {
    case "select":
      return {
        Content: PmaxSelectStep,
        Footer: SelectStepFooter,
      };
    case "review":
      return {
        Content: PmaxReviewStep,
        Footer: ReviewStepFooter,
      };
    case "load":
      return {
        Content: PmaxLoadStep,
        Footer: LoadStepFooter,
      };
  }
};
