import { useState } from "react";
import { useFetchFilterPresets } from "shared/hooks/designStudio/useFetchFilterPresets";
import {
  useCreateFilterPreset,
  useDeleteFilterPreset,
} from "shared/hooks/designStudio/useMutateFilterPreset";
import { useFilterFields } from "../designStudioV2/filterDrawer/useFilterFields";

export type Feature = "design-studio";

export type FilterPreset = {
  id: string;
  name: string;
  feature: Feature;
  filters: ReturnType<typeof useFilterFields>["tempFilters"];
};

export default () => {
  const { data: presets = [], isLoading } = useFetchFilterPresets();

  const { mutate: createPreset } = useCreateFilterPreset();
  const { mutate: deletePreset } = useDeleteFilterPreset();

  const [selectedPreset, setSelectedPreset] = useState<FilterPreset>();
  return {
    presets,
    loading: isLoading,
    deletePreset,
    selectedPreset,
    setSelectedPreset,
    createPreset,
  };
};
