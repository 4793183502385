import { useFetchInfiniteDealers } from "shared/hooks/useFetchDealers";
import { AllEntryTypes } from "../../AssignDestsTable.types";
import { useAdLoadIssues } from "screens/adLibrary/adLoadV2/hooks/useAdLoadIssues";
import UrlLabelSelect from "./UrlLabelSelect";
import { useSetCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import { useCallback } from "react";
import { getRecordIssue } from "../recordIssues.utils";
import { AdProtoDraft } from "screens/adLibrary/facebookUtils/adProto.types";
import { AdType } from "screens/adLibrary/facebookUtils/types";
import { findAdProtoDraft } from "screens/adLibrary/facebookUtils/adProto";

type Props = {
  field: "destinationUrl" | "displayUrl";
  record: AllEntryTypes;
};

const RecordUrlLabelSelect = ({ record, field }: Props) => {
  const { issues } = useAdLoadIssues();
  const { dealers: stores } = useFetchInfiniteDealers();
  const { setSession } = useSetCurrentSession();

  const selectedStore = stores.find(
    store => store.dealer_name === record.storeName,
  );
  const urlLabels = selectedStore?.labels ?? [];
  const getLinkValue = () => {
    if (field === "destinationUrl" && "destinationUrl" in record) {
      return record.destinationUrl;
    }
    if (field === "displayUrl" && "displayUrl" in record) {
      return record.displayUrl;
    }
  };

  const onChange = useCallback(
    (value: string): void => {
      setSession(prevSession => {
        const protoDraft = findAdProtoDraft({
          adProtoDrafts: prevSession.data.adProtoDrafts,
          adId: record.adId,
          storeName: record.storeName,
        });

        if (field === "displayUrl" && "displayUrl" in protoDraft) {
          protoDraft.displayUrl = value;
          return;
        }

        if (field === "destinationUrl") {
          updateDestUrl(protoDraft, value, record);
        }
      });
    },
    [record, field, setSession],
  );

  const issue = getRecordIssue({ record, issues, field });

  if (!(field in record)) return null;

  return (
    <UrlLabelSelect
      urlLabels={urlLabels}
      value={getLinkValue()}
      onChange={onChange}
      issue={issue}
    />
  );
};

export default RecordUrlLabelSelect;

/**
 * This function will mutate the protoDraft
 * to update the destinationUrl depending on the record type
 */
export const updateDestUrl = (
  protoDraft: AdProtoDraft,
  value: string,
  record: AllEntryTypes,
) => {
  switch (protoDraft.type) {
    case AdType.Still:
    case AdType.AIA:
    case AdType.Video:
      {
        protoDraft.destinationUrl = value;
      }
      break;
    case AdType.InstantExperience:
    case AdType.Collection:
      {
        if (protoDraft.convertToVideo) {
          protoDraft.destinationUrl = value;
          break;
        }
        const targetElement = protoDraft.elementDests.find(
          dest => dest.elementId === record.id,
        );
        if (targetElement && targetElement.destType === "url") {
          targetElement.destinationUrl = value;
        }
      }
      break;
    case AdType.Carousel:
      {
        if (record.type === "CarouselItem") {
          protoDraft.cards[record.cardIndex].destinationUrl = value;
        }
      }
      break;
    default:
      throw new Error("Invalid ad type");
  }
};
