import av3 from "./av3";
import base from "./base";
import ladtech from "./ladtech";
import truecar from "./truecar";
import internal from "./internal";

export const config: MGConfig = {
  ...base,
  ...{
    ladtech,
    truecar,
    av3,
    internal,
  }[process.env.REACT_APP_AV2_CLIENT!],
};

export const CAMConfig: MGPublicConfig = {
  active: !!config.active,
  integrations: !!config.active && config.integrations,
  wdBaseUrl: config.wdBaseUrl,
  wdBrandPortalId: config.wdBrandPortalId,
  extraFields: config.extraFields,
  defaultFields: config.defaultFields,
  keywordsUrl: config.keywordsUrl,
};

export const CAM_ENABLED = CAMConfig.active && CAMConfig.integrations;

// TODO: Remove after QA.
// eslint-disable-next-line no-console
console.log({
  CLIENT: process.env.REACT_APP_AV2_CLIENT!,
  CAM_ENABLED,
  CAMConfig,
  config,
});
