import { Environment, IConfig } from "../shared/types/configuration";
import assetBuilderAPIs from "./assetBuilder";
import dealerManagementAPIs from "./dealerManagement";
import dashboardManagementAPIs from "./dashboardManagement";
import accountAPIs from "./account";
import localLingoAPIs from "./legalLingo";
import localLingoV2APIs from "./legalLingoV2";
import newOrderAPIs from "./newOrder";
import oemManagementAPIs from "./oemManagement";
import tagManagementAPIs from "./tagManagement";
import specialCampaignAPIs from "./specialCampaign";
import uploadManagementAPIs from "./uploadManagement";
import userManagementAPIs from "./userManagement";
import platformManagementAPIs from "./platformManagement";
import metricsManagementAPIs from "./metricsManagement";
import notificationManagementAPIs from "./notificationManagement";
import adLibraryAPIs from "./adLibrary";
import adLoadAPIs from "./adLoad";
import adReviewAPIs from "./adReview";
import assetExporterAPIs from "./assetExporter";
import designStudioAPIs from "./designStudio";
import errorLoggingAPIs from "./errorLogging";
import webIntAPIs from "./webintegration";
import commonAPIs from "./common";
import campaignPlannerAPIs from "./campaignPlanner";
import mediaAPIs from "./media";
import brandAccountManagementAPIs from "./brandAccountManagement";
import genAIAPIs from "./genAI";
import configGenerator from "configGenerator";

class APIClass {
  private _config?: IConfig;
  private _token: string = "";
  public set token(token: string) {
    this._token = token;
  }

  public send = async <T>(request: Request): Promise<T> => {
    request.headers.set("Content-Type", "application/json");
    request.headers.set("Accept", "application/json");
    request.headers.set("Authorization", `Bearer ${this._token}`);
    return fetch(request)
      .then<T>(response => {
        return response.json();
      })
      .catch(err => {
        return err;
      });
  };

  public get services() {
    const headers: HeadersInit = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    if (!this._config) {
      this._config = configGenerator(process.env.REACT_APP_ENV as Environment);
    }
    return this.getAPIServices(this._config, headers);
  }

  public get privServices() {
    const headers: HeadersInit = new Headers();
    headers.set("Content-Type", "application/json");
    headers.set("Accept", "application/json");
    headers.set("Authorization", `Bearer ${this._token}`);
    if (!this._config) {
      this._config = configGenerator(process.env.REACT_APP_ENV as Environment);
    }
    return this.getAPIServices(this._config, headers);
  }

  private getAPIServices(config: IConfig, headers?: Headers) {
    return {
      uploadManagement: {
        ...uploadManagementAPIs({ config }),
      },
      userManagement: {
        ...userManagementAPIs({ headers, config }),
      },
      platformManagement: {
        ...platformManagementAPIs({ headers, config }),
      },
      oemManagement: {
        ...oemManagementAPIs({ headers, config }),
      },
      tagManagement: {
        ...tagManagementAPIs({ headers, config }),
      },
      specialCampaign: {
        ...specialCampaignAPIs({ headers, config }),
      },
      dealerManagement: {
        ...dealerManagementAPIs({ headers, config }),
      },
      dashboardManagement: {
        ...dashboardManagementAPIs({ config }),
      },
      account: {
        ...accountAPIs({ config }),
      },
      assetBuilder: {
        ...assetBuilderAPIs({ headers, config }),
      },
      legalLingo: {
        ...localLingoAPIs({ headers, config }),
      },
      legalLingoV2: {
        ...localLingoV2APIs({ config }),
      },
      newOrder: {
        ...newOrderAPIs({ headers, config }),
      },
      adLibrary: {
        ...adLibraryAPIs({ headers, config }),
      },
      adLoad: {
        ...adLoadAPIs({ config }),
      },
      adReview: {
        ...adReviewAPIs({ headers, config }),
      },
      designStudio: {
        ...designStudioAPIs({ headers, config }),
      },
      assetExporter: {
        ...assetExporterAPIs({ headers, config }),
      },
      errorLogging: {
        ...errorLoggingAPIs({ config }),
      },
      webInt: {
        ...webIntAPIs({ headers, config }),
      },
      metrics: {
        ...metricsManagementAPIs({ config }),
      },
      campaignPlanner: {
        ...campaignPlannerAPIs({ config }),
      },
      notification: {
        ...notificationManagementAPIs({ config }),
      },
      common: {
        ...commonAPIs({ headers, config }),
      },
      media: {
        ...mediaAPIs({ headers, config }),
      },
      brandAccountManagement: {
        ...brandAccountManagementAPIs({ headers, config }),
      },
      genAI: {
        ...genAIAPIs({ headers, config }),
      },
    };
  }
}

const API = new APIClass();

export default API;
