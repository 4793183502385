import { Button } from "antd";
import { IAdIssue } from "shared/types/adLibrary";
import {
  IAdValidations,
  adValidationFieldsMapping,
} from "../adValidationUtils";

const errorInfoIssue: IAdIssue = {
  message: "Issues are required fields and must be resolved before import.",
  type: "info",
};

const getErrorMessage = (
  field: keyof typeof adValidationFieldsMapping,
): string => {
  if (field === "adFormat") {
    return "Please update the Ad Format(s) for the following ad(s) to a compatible Ad Format.";
  }
  return `${adValidationFieldsMapping[field]} is missing`;
};

export const getIssues = (
  validationErrors?: IAdValidations[],
  selectedIssuesGroup?: string,
  toggleAdsToView?: (field: string, adIdsToSelect: string[]) => void,
): IAdIssue[] => {
  const getMissingFieldIssues = (
    field: keyof typeof adValidationFieldsMapping,
  ): IAdIssue | undefined => {
    if (!validationErrors?.length) {
      return undefined;
    }
    const adsWithTheIssue = validationErrors
      .filter(validation => !!validation[field])
      .map(validation => validation.adId);

    const numberOfAdsWithTheIssue = adsWithTheIssue?.length;
    if (numberOfAdsWithTheIssue) {
      const isSelectedGroup = field === selectedIssuesGroup;

      return {
        message: getErrorMessage(field),
        type: "error",
        actions: [
          <Button
            key="viewAds"
            type="link"
            onClick={() => toggleAdsToView?.(field, adsWithTheIssue)}
            style={{
              fontWeight: isSelectedGroup ? "bold" : "normal",
            }}
          >
            View {numberOfAdsWithTheIssue}{" "}
            {numberOfAdsWithTheIssue > 1 ? "ads" : "ad"}
          </Button>,
        ],
      };
    }
  };

  const issues = Object.keys(adValidationFieldsMapping)
    .map(field =>
      getMissingFieldIssues(field as keyof typeof adValidationFieldsMapping),
    )
    .filter(Boolean) as IAdIssue[];

  if (!issues.length) {
    return [];
  }

  return [errorInfoIssue, ...issues];
};
