import moment from "moment";
import { UseMutationResult } from "react-query";
import {
  LoadStatus,
  StatusResponse,
} from "screens/adLibrary/pmaxAssetGroups/shared/types";
import { GoogleAdCampaignInternal } from "shared/types/adLibrary";

const getComputedCampaignStatus = (
  campaign: GoogleAdCampaignInternal,
  batchCampaignLoadStatus: UseMutationResult["status"],
  assetGroupLoadStatuses: StatusResponse[],
): LoadStatus => {
  if (batchCampaignLoadStatus === "loading") return "Loading";
  if (batchCampaignLoadStatus === "error") return "Failed";

  const assetGroupIds = campaign.assetGroupIds || [];
  const assetGroupResults = assetGroupLoadStatuses.filter(
    assetGroupUploadResult =>
      campaign.assetGroupIds?.includes(assetGroupUploadResult.id),
  );

  const isUploadingAssetGroupsForCampaign =
    assetGroupResults.length < assetGroupIds.length;

  const hasAssetGroupErrors = assetGroupIds?.some(
    assetGroupId =>
      assetGroupLoadStatuses.find(
        assetGroupUploadResult => assetGroupUploadResult.id === assetGroupId,
      )?.status === "Failed",
  );

  if (!assetGroupIds.length && batchCampaignLoadStatus === "success") {
    return "Loaded";
  }
  if (hasAssetGroupErrors) return "Failed";
  if (!isUploadingAssetGroupsForCampaign && !hasAssetGroupErrors)
    return "Loaded";

  return "Loading";
};

export const getComputedCampaignStatuses = (
  campaigns: GoogleAdCampaignInternal[],
  batchCampaignLoadStatus: UseMutationResult["status"],
  assetGroupLoadStatuses: StatusResponse[],
): { [campaignName: string]: LoadStatus } => {
  return campaigns.reduce((acc, campaign) => {
    return {
      ...acc,
      [campaign.name]: getComputedCampaignStatus(
        campaign,
        batchCampaignLoadStatus,
        assetGroupLoadStatuses,
      ),
    };
  }, {});
};

// Google expects dates to be in the format YYYY-MM-DD, but we store them as MM-DD-YYYY
export const transformCampaignsToLoad = (
  campaigns: GoogleAdCampaignInternal[],
) =>
  campaigns.map(campaign => {
    return {
      ...campaign,
      startDate: campaign.startDate
        ? moment(campaign.startDate).format("YYYY-MM-DD")
        : undefined,
      endDate: campaign.endDate
        ? moment(campaign.endDate).format("YYYY-MM-DD")
        : undefined,
    };
  });
