import { ITableColumnURL } from "shared/components/dataListURL/DataListURLTable";
import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import { PmaxCampaignKey } from "./fields";
import { TimeAgo } from "shared/components/TimeAgo";
import { Typography } from "antd";
import { usePmaxCampaignsContext } from "../PmaxCampaignsProvider";
import { toInternalGoogleAdCampaignForm } from "./newCampaignDrawer/newCampaignForm/helpers";
import { usePmaxCampaignsFilters } from "screens/adLibrary/shared/hooks/dataListHooks";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import { SearchFilterDropdown } from "shared/components/SearchFilterDropdown";
import { DateFilterDropdown } from "shared/components/DateFilterDropdown";
import { getLoadStatusFilters } from "./helpers";
import { format } from "date-fns";
import { CampaignLoadStatusCell } from "./CampaignLoadStatusCell";

export const usePmaxCampaignsColumns = () => {
  const { setCampaignDrawer, campaigns } = usePmaxCampaignsContext();

  const { filters } = usePmaxCampaignsFilters();
  const columns: ITableColumnURL<PmaxCampaignKey, GoogleAdCampaignInternal>[] =
    [
      {
        key: "name",
        title: "Campaign Name",
        dataIndex: ["name"],
        filterDropdown: SearchFilterDropdown,
        filterIcon: (
          <SearchOutlined color={filters?.name ? "#1890ff" : undefined} />
        ),
        render: (name, campaign) => {
          const { assetGroupIds, form } =
            toInternalGoogleAdCampaignForm(campaign);
          return (
            <Typography.Link
              onClick={() => {
                setCampaignDrawer({
                  open: true,
                  data: form,
                  assetGroupIds,
                });
              }}
            >
              {name}
            </Typography.Link>
          );
        },
      },
      {
        key: "loadStatus",
        title: "Campaign Load Status",
        filters: getLoadStatusFilters(campaigns),
        dataIndex: ["loadStatus"],
        render: loadStatus => <CampaignLoadStatusCell loadStatus={loadStatus} />,
      },
      {
        key: "startDate",
        title: "Start Date",
        dataIndex: ["startDate"],
        filterDropdown: DateFilterDropdown,
        filterIcon: (filtered: boolean) => (
          <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
        ),
        render: date => format(new Date(date), "MM/dd/yyyy"),
      },
      {
        key: "endDate",
        title: "End Date",
        dataIndex: ["endDate"],
        filterDropdown: DateFilterDropdown,
        filterIcon: (filtered: boolean) => (
          <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
        ),
        render: date => {
          if (!date) return "-";
          return format(new Date(date), "MM/dd/yyyy");
        },
      },
      {
        key: "dailyBudget",
        title: "Budget",
        dataIndex: ["dailyBudget"],
        render: budget => `$${budget}/day`,
      },
      {
        key: "createdAt",
        title: "Created",
        dataIndex: ["createdAt"],
        filterDropdown: DateFilterDropdown,
        filterIcon: (filtered: boolean) => (
          <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
        ),
        render: date => <TimeAgo date={date} />,
      },
      {
        key: "lastEditedAt",
        title: "Last Edited",
        dataIndex: ["lastEditedAt"],
        filterDropdown: DateFilterDropdown,
        filterIcon: (filtered: boolean) => (
          <CalendarOutlined color={filtered ? "#1890ff" : undefined} />
        ),
        render: date => <TimeAgo date={date} />,
      },
      {
        key: "lastEditedBy",
        title: "Last Edited By",
        dataIndex: ["lastEditedBy"],
      },
    ];

  return { columns };
};
