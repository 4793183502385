import { Button, Drawer, notification } from "antd";

import AdImportTable from "./adCompleteImportDrawer/AdImportTable";
import { IAd } from "shared/types/adLibrary";
import { useBatchMutateAds } from "shared/hooks/adLibrary/useMutateAd";
import {
  resetAdImport,
  setAdImport,
  setAdsImport,
  setSelectedAdIds,
} from "redux/adLibrary/adLibrary.slice";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppSelector } from "shared/hooks/useAppSelector";

type Props = {
  onClose: () => void;
};

const AdCompleteImportDrawer = ({ onClose }: Props) => {
  const ads = useAppSelector(state => state.adLibrary.adImport.adsToImport);
  const selectedAdIds = useAppSelector(
    state => state.adLibrary.adImport.selectedAdIds,
  );
  const adValidations = useAppSelector(
    state => state.adLibrary.adImport.adValidations,
  );
  const dispatch = useAppDispatch();

  const setAd = (adId: string, adUpdate: IAd) => {
    dispatch(setAdImport({ adId, adUpdate }));
  };

  const setAds = (ads: IAd[]) => {
    dispatch(setAdsImport({ ads }));
  };

  const handleSetSelectedAdIds = (selectedAdIds: string[]) => {
    dispatch(setSelectedAdIds({ selectedAdIds }));
  };

  const handleClose = () => {
    onClose();
    dispatch(resetAdImport());
  };

  const { mutateAsync: batchMutateAdsAsync, isLoading: isCreatingAds } =
    useBatchMutateAds();

  const selectedAds = ads.filter(ad => selectedAdIds.includes(ad.id));
  const handleImportSelectedAds = async () => {
    try {
      await batchMutateAdsAsync(selectedAds);
      handleClose();
    } catch (e) {
      notification.error({
        message: "There was an error trying to complete the import.",
      });
    }
  };

  return (
    <Drawer
      title={
        <div>
          <strong>Review Import:</strong> {ads.length} ads ready to import to Ad
          Library. Please review before completing the import.
        </div>
      }
      visible
      onClose={handleClose}
      width="100%"
      closable
      footer={
        <div
          style={{
            textAlign: "right",
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button
            loading={isCreatingAds}
            onClick={handleImportSelectedAds}
            type="primary"
          >
            Complete Import
          </Button>
        </div>
      }
      data-cy="complete-import-drawer"
    >
      <AdImportTable
        ads={ads}
        selectedAdIds={selectedAdIds}
        setSelectedAdIds={handleSetSelectedAdIds}
        setAd={setAd}
        setAds={setAds}
        adValidations={adValidations}
      />
    </Drawer>
  );
};

export default AdCompleteImportDrawer;
