import { AdType } from "screens/adLibrary/facebookUtils/types";
import { IAd, ChildCard, IAdWithChildren, ICard } from "shared/types/adLibrary";

export const isChildRecord = (record: IAd) => (record as ChildCard).isChild;
export const isNotChildRecord = (record: IAd) => !isChildRecord(record);
export const adHasChildren = (ad: IAdWithChildren) => !!ad.children?.length;
export const adDoesNotHaveChildren = (ad: IAdWithChildren) =>
  !adHasChildren(ad);

export const updateAdCard = (ad: IAd, childAdCardUpdate: ChildCard): IAd => {
  return {
    ...ad,
    visuals: {
      ...ad.visuals,
      cards: ad.visuals.cards?.map((card, index) =>
        childAdCardUpdate.cardIndex === index
          ? mapAdImportTableRecordToAdCard(card, childAdCardUpdate)
          : card,
      ),
    },
  };
};

const mapAdImportTableRecordToAdCard = (
  card: ICard,
  childAdCardUpdate: ChildCard,
): ICard => ({
  ...card,
  description: childAdCardUpdate.visuals?.headlineDescription ?? "",
  destinationUrl: childAdCardUpdate.inputParameters?.destinationUrl,
  headline: childAdCardUpdate.visuals?.headline ?? "",
  ctaButtonText: childAdCardUpdate.visuals?.ctaButtonText,
  thumbnail: childAdCardUpdate.visuals?.thumbnail ?? "",
});

export const mapAdCardToAdImportTableRecord = (
  ad: IAd,
  card: ICard,
  index: number,
): ChildCard => {
  const id = `${ad.id}_card_${index}`;
  return {
    isChild: true,
    parentAdId: ad.id,
    cardIndex: index,
    id,
    inputParameters: {
      id,
      name: `Card ${index + 1}`,
      destinationUrl: card.destinationUrl,
    },
    createdAt: ad.createdAt,
    updatedAt: ad.updatedAt,
    type: card.videoUrl ? AdType.Video : AdType.Still,
    visuals: {
      id,
      headline: card.headline,
      thumbnail: card.thumbnail,
      videoUrl: card.videoUrl,
      assetId: card.assetId,
      postCopy: "",
      ctaButtonText: card.ctaButtonText,
      headlineDescription: card.description,
    },
  };
};
