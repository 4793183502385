import { message, notification } from "antd";
import { fabric } from "fabric";
import { createContext, FC, ReactNode, useCallback, useState } from "react";

import merge from "deepmerge";
import { isEqual, uniq } from "lodash";
import previewData from "shared/constants/previewData.json";
import {
  CanvasObject,
  FeedOffer,
  FeedTab,
  ICanvasData,
  ICanvasObject,
  IImageObject,
  ILogoObject,
  IOffer,
  IRawOfferDataFromService,
  isCarcut,
  isDisclosure,
  ISelectedOffer,
  isLifestyleImage,
  isLogo,
  isStamp,
  isText,
  isThemeBackground,
  isVideo,
  ITextObject,
  OfferData,
  TLogoSubstitution,
  TVisibility,
  VehicleConditions,
} from "shared/types/assetBuilder";
import { FeedType, IConfig } from "shared/types/configuration";
import {
  CanvasType,
  ICustomVideoData,
  IExtendedFabricObject,
  IGeneralObjectType,
  ILogoObjectData,
  IResponse,
  IStamp,
  IStampObjectData,
  ITemplate,
  LogoEventType,
} from "shared/types/designStudio";
import { INewOrder } from "shared/types/newOrders";
import { OfferType } from "shared/types/shared";

import moment from "moment";
import API from "services";
import { IBox } from "shared/components/templatePreview/CardToggle";
import {
  fullStateOptions,
  storeVariableNames,
} from "shared/constants/dataManagement";
import { TDataCache } from "shared/hooks/useFetchRenderTemplate";
import { IAccount } from "shared/types/accountManagement";
import {
  IDisclosure,
  IStateDisclosureElement,
  IStateDisclosureRecord,
  IStateExceptionElement,
  IStateExceptionRecord,
} from "shared/types/legalLingo";
import { IBrand } from "shared/types/brandManagement";
import * as textHelpers from "utils/fabric/helpers.text";
import { getHeight, getWidth } from "utils/fabric/helpers.utils";
import { formatDateValue, isFeatureEnabled } from "utils/helpers";
import {
  initiateTextbox,
  IPlaceholder,
  replaceTextTokens,
  returnDisclosureNamesToUse,
  returnRePositionedImage,
} from "utils/helpers.fabric";
import * as offerHelpers from "utils/helpers.offer";
import {
  createImagePlaceholder,
  fetchDataFromOfferIfNotEqual,
  getLogoImageUrl,
  getReplacedFabricImage,
  getStampVisibility,
} from "./RenderTemplate.utils";

//////////////////////////////////////
// Defining RenderTEmplateContext type
type RenderTemplateContext = RenderTemplateContextProps &
  RenderTemplateContextHandlers;

// Define RenderTEmplateContextProps
type RenderTemplateContextProps = {
  data?: TData;
  loading?: { type: "data" | "template"; message: string };
};

export type TData = {
  canvasJson?: {
    [key: string]: fabric.Object[] | string | number;
    width: number;
    height: number;
  };
  dealer?: IAccount;
  oem?: IBrand;
  rawOffer?: OfferData;
  disclosures?: IStateDisclosureElement[];
  exceptions?: IStateExceptionElement[]; // Could not found exception type
  v2Disclosure?: IDisclosure;
  feedTabs?: FeedTab[];
  // <stampId, array of stamp>
  // NOTE: stampJson will be fetched only for selected offer types
  stamps?: (IStamp & { stampJson?: any })[];

  numberAtThisPriceOfferList?: IOffer[];

  carcutImage?: fabric.Image;
  toggleBoxes?: IBox[];
  cardLabels?: ReactNode[];
} & TReloadArgs;

type TOffer = {
  offerData: OfferData;
  offerTypes: OfferType[];
};

// Define RenderTemplateContextHandlers
type RenderTemplateContextHandlers = {
  reload: (args: TReloadArgs) => Promise<TData | undefined>;
  setDefaultValues: (data: TData) => Promise<TData>;
  renderTemplateOn: (args: {
    canvas: fabric.Canvas;
    data: TData;
    complete: () => Promise<void>;
    htmlElement?: HTMLElement;
    visibilities?: TVisibility[];
    logoSubstitutions?: TLogoSubstitution[];
    lifestyleImageUrl?: string;
    lifestyleImageJson?: string;
    order?: INewOrder;
    disclosure?: {
      allSizes?: IDisclosure | null;
      currentSize?: IDisclosure | null;
      selectedAsset?: IDisclosure | null;
    };
    feedTabs?: FeedTab[];
  }) => void;
  getData: () => TData | undefined;
  update: (
    type: "toggle_boxes",
    args: {
      toggleBoxes: IBox[];
    },
  ) => void;
};

export type TReloadArgs = {
  renderTemplateCache?: TDataCache;
  // .loadFromJSON() takes json as any type. Let's use any for canvas json.
  template?: ITemplate & { canvasJson?: any };
  offer?: TOffer;
  order?: INewOrder;
  canvasRect?: ClientRect;
  canvasWrapperRect?: ClientRect; // This is rect that contains the canvas
  visibilities?: TVisibility[];
  selectedOffers?: ISelectedOffer[];
  disclosure?: {
    allSizes?: IDisclosure;
    currentSize?: IDisclosure;
    selectedAsset?: IDisclosure;
  };
};
// End of RenderTemplateContext type
//////////////////////////////////////

export const context = createContext<RenderTemplateContext | null>(null);

////////////////////
// Defining Provider
interface IRenderTemplateProps {
  config?: IConfig;
  feed?: FeedType;
}

const clientName = process.env.REACT_APP_AV2_CLIENT || "internal";
const stampMismatchEnabled = isFeatureEnabled("ALLOW_STAMP_MISMATCH", false);
const legalLingoV2Enabled = isFeatureEnabled("ENABLE_LEGAL_LINGO_V2", false);

const Provider: FC<IRenderTemplateProps> = props => {
  const [data, setData] = useState<TData>();
  const [loading, setLoading] = useState<
    { type: "data" | "template"; message: string } | undefined
  >();

  const { services } = props.config || {};

  const recursivelyRemoveEmpty = (obj: any) => {
    Object.entries(obj).forEach(([key, val]) => {
      if (val && typeof val === "object") {
        if (Array.isArray(val) && val.length === 1 && val[0] === "") {
          delete obj[key];
        } else {
          // recursive removal
          recursivelyRemoveEmpty(val);
        }
      } else if (val == null || val === "") {
        delete obj[key];
      }
    });
  };

  const getFabricImage = useCallback(
    (url: string, top?: number, left?: number) => {
      return new Promise<fabric.Image>(resolve => {
        fabric.Image.fromURL(
          `${url}?timestamp=${Date.now()}`,
          img => {
            return resolve(img);
          },
          {
            crossOrigin: "anonymous",
            top,
            left,
          },
        );
      });
    },
    [],
  );

  const state: RenderTemplateContext = {
    data,

    loading,

    reload: async args => {
      setLoading({
        type: "data",
        message: "Loading template data...",
      });

      let updatedData: TData = {
        ...data,
        offer: args.offer,
      };

      // Below fetchDataFromOfferIfNotEqual will update data by fetching all necessary data
      //   from the new offer (args.offer). All necessary data meaning whatever data that we can
      //   fetch using offer data. For example, in order to fetch new offer data from the server,
      //   we need new offer.vin. Or in order to fetch offer list which will be used to render
      //   number at this price variable, we need dealerCode, trim, modelCode and msrp from the offer.offerData.
      updatedData = await fetchDataFromOfferIfNotEqual({
        reloadingArgs: args,
        data,
        config: props.config,
        feed: props.feed,
      });

      const { offer, order, selectedOffers, disclosure } = args;
      if (disclosure) {
        updatedData = {
          ...updatedData,
          disclosure,
        };
      }
      if (!isEqual(data?.order, order)) {
        updatedData = {
          ...updatedData,
          order,
          selectedOffers,
        };

        // get dealer
        if (!isEqual(data?.dealer?.dealer_name, order?.dealer_name)) {
          let dealer = args.renderTemplateCache?.dealer;

          if (!dealer) {
            const dealerUrl = `${
              services?.getDealerUrl
            }?dealerName=${encodeURIComponent(order?.dealer_name || "")}`;
            const dealerRequest = new Request(dealerUrl, {
              method: "GET",
              cache: "no-cache",
            });

            const { result: dealerResult, error } = await API.send<
              IResponse<IAccount>
            >(dealerRequest);
            if (error) {
              // TODO: hendle error
            }
            dealer = dealerResult?.dealer || {};
          }

          updatedData = {
            ...updatedData,
            dealer,
          };

          const { state } = dealer || {};

          if (!legalLingoV2Enabled) {
            let disclosures = args.renderTemplateCache?.disclosures?.[state];
            if (!disclosures && state) {
              // get disclosure
              const disclosureUrl = `${
                services?.legal.getStateDisclosuresUrl
              }?state=${state.toLowerCase()}`;
              const disclosureRequest = new Request(disclosureUrl, {
                method: "GET",
                cache: "no-cache",
              });

              const { result, error: disclosureError } = await API.send<
                IResponse<IStateDisclosureRecord[]>
              >(disclosureRequest);
              if (disclosureError) {
                message.warning("Disclosure data could not be found.");
              }

              const { stateDisclosures } = result || {};
              const [disclosureObj] = stateDisclosures || [];

              disclosures = disclosureObj?.disclosures || [];
            }

            updatedData = {
              ...updatedData,
              disclosures,
            };

            // get exception data
            const { dealer_oem: dealerOem } = dealer;

            const cacheKey = `${state}-${dealerOem}`;
            let exceptions = args.renderTemplateCache?.exceptions?.[cacheKey];

            if (!exceptions && state) {
              const oem =
                dealerOem.split(",").length === 1
                  ? dealerOem
                  : offer?.offerData.make;

              const exceptionUrl = `${
                services?.legal.getStateExceptionsUrl
              }?oem=${oem}&state=${state.toLowerCase()}`;

              const exceptionRequest = new Request(exceptionUrl, {
                method: "GET",
                cache: "no-cache",
              });

              const { result: exceptionResult, error: exceptionError } =
                await API.send<IResponse<IStateExceptionRecord[]>>(
                  exceptionRequest,
                );
              if (exceptionError) {
                message.warning("Exception data could not be found.");
              }
              const { stateExceptions } = exceptionResult || {};
              const [exceptionObj] = stateExceptions || [];

              exceptions = exceptionObj?.exceptions || [];
            }

            updatedData = {
              ...updatedData,
              exceptions,
            };
          }
        }
      }

      const { template } = args;
      let json = args.renderTemplateCache?.canvasJsonMap?.[
        (clientName !== "nu" ? template?.id : template?.canvasJsonUrl) || ""
      ] as any;

      if (!json) {
        if (!template?.canvasJsonUrl) {
          message.error("Template is invalid.");

          return;
        }

        json = await fetchCanvasJson(template.canvasJsonUrl);
      }

      updatedData = {
        ...updatedData,
        template,
        canvasJson: json,
      };

      if (updatedData.canvasJson) {
        // If the "template" passed into the func has canvasJson in it,
        // just assign it to "data". Otherwise, fetch canvasJson and assign.

        // generate toggle boxes and card labels here
        if (!!args.canvasRect && !!args.canvasWrapperRect) {
          const objects = (
            updatedData.canvasJson as unknown as {
              [key: string]: IExtendedFabricObject[];
            }
          )?.["objects"];
          const fabricObjects =
            objects?.filter(
              obj =>
                isStamp(obj as unknown as ICanvasObject) ||
                isLogo(obj as unknown as ICanvasObject) ||
                isLifestyleImage(obj as unknown as ICanvasObject) ||
                isDisclosure(obj as unknown as ICanvasObject),
            ) || [];
          const toggleBoxes: IBox[] = getToggleBoxes(
            fabricObjects,
            args.canvasRect,
            args.canvasWrapperRect,
            args.visibilities,
          );

          updatedData = {
            ...updatedData,
            toggleBoxes,
          };
        }

        // load fonts
        await textHelpers.loadFontsFromJson(
          updatedData.canvasJson as unknown as ICanvasData,
        );
      }

      // load stamps
      const { canvasJson } = updatedData;

      // check if stamps in the template are all in the cache.
      // if not, fetch them.
       const canvasStampsIds = ((canvasJson?.objects || []) as fabric.Object[])
        .filter(obj => isStamp(obj as unknown as ICanvasObject))
        .map(
          obj =>
            ((obj as IExtendedFabricObject).customData as IStampObjectData)
              ?.stampId,
        );
      const updatedDataStampsIds = uniq(
        updatedData.stamps?.map(stamp => stamp.id) || [],
      );

      const stampIdsToFetch = canvasStampsIds.filter(
        id => !updatedDataStampsIds.includes(id),
      );
      const proceedToFetch =
        !stampIdsToFetch.every(id =>
          args.renderTemplateCache?.stamps?.some(
            cacheStamp => cacheStamp.id === id,
          ),
        ) || !args.renderTemplateCache?.stamps; // If cache isnt available, stampJsons arent in the updatedData.stamps. We need to fetch them if this is the case.

      if (proceedToFetch) {
        const responses = await Promise.all(
          // NOTE: Using Set, we make sure we dont make duplicated request with same stamp id.
          //       Same stamp can be used multiple times within template.
          Array.from(new Set(stampIdsToFetch)).map(stampId => {
            const url = `${props.config?.services.designStudio.stampBaseUrl}/${stampId}`;
            const request = new Request(url, {
              method: "GET",
              cache: "no-cache",
            });
            return API.send<IResponse<IStamp[]>>(request);
          }),
        );

        const stamps = responses
          .reduce((prev, curr) => {
            const stampArray = (curr.result?.stamps || []) as IStamp[];
            return prev.concat(stampArray);
          }, [] as Array<IStamp & { stampJson?: any }>)
          .concat(updatedData.stamps || []);

        const stampsToFetch: IStamp[] = stamps.filter(
          stmp =>
            offer?.offerTypes.includes((stmp.offerType || "") as OfferType) &&
            !stmp.stampJson,
        );

        const returnedStamps = await Promise.all(
          stampsToFetch.map(async stmp => {
            let { stampJsonUrl } = stmp;
            const { dealer } = updatedData;

            // Handle stamp exception case
            // We need dealer.state and dealer must have been fetched above. Get it from updatedData
            stampJsonUrl =
              stmp?.exceptions?.find(
                exp => exp.type === "state" && exp.value === dealer?.state,
              )?.stampJsonUrl || stampJsonUrl;

            if (!stampJsonUrl) return stmp;

            const response = await fetch(
              `${stampJsonUrl}?timestamp=${Date.now()}`,
            );
            const json = await response.json();

            // load fonts
            await textHelpers.loadFontsFromJson(json);

            return {
              ...stmp,
              stampJson: json,
            };
          }),
        );

        updatedData = {
          ...updatedData,
          stamps: stamps.map(stmp => {
            const stamp = returnedStamps.find(
              tmp => tmp.id === stmp.id && tmp.offerType === stmp.offerType,
            );
            return stamp || stmp;
          }),
        };
      } else {
        updatedData = {
          ...updatedData,
          stamps: args.renderTemplateCache?.stamps,
        };
      }

      // carcut image
      if (!!updatedData?.canvasJson) {
        // get the carcut placeholder object in order to get its dimension
        const carcutPlaceholder = (
          (updatedData.canvasJson.objects || []) as fabric.Object[]
        ).find(obj => isCarcut(obj as unknown as ICanvasObject));

        const { top, left } = carcutPlaceholder || { top: 0, left: 0 };
        const carcutImageUrl = `${offer?.offerData.imageUrl}`;
        const carcutImage = await getFabricImage(carcutImageUrl, top, left);

        updatedData = {
          ...updatedData,
          carcutImage,
        };
      }
      setData(updatedData);
      setLoading(undefined);

      return updatedData;
    },

    setDefaultValues: async data => {
      // remove empty fields and fill default data with deep merge
      const randomlySelectFromArray = (arr: any[]) => {
        return arr[Math.floor(Math.random() * arr.length)];
      };
      const { make, model, trim } = data.offer?.offerData as any;
      const selectDefaultData = (
        make: string,
        model: string,
        trim: string,
        previewData: IGeneralObjectType[],
      ) => {
        // match oem, otherwise use first previewData
        const oemMatch = !!make
          ? previewData.filter(
              (pData: IGeneralObjectType) => pData.make === make,
            )
          : [];
        if (oemMatch.length) {
          // if model match, match trim otherwise use randomly selected from oemMatch array
          const modelMatch = !!model
            ? oemMatch.filter(
                (pData: IGeneralObjectType) => pData.model === model,
              )
            : [];
          if (modelMatch.length) {
            // if trim match, randomly select from the matching array, otherwise use randomly selected from modelMatch array
            const trimMatch = !!trim
              ? modelMatch.filter(
                  (pData: IGeneralObjectType) => pData.trim === trim,
                )
              : [];
            if (trimMatch.length) {
              return randomlySelectFromArray(trimMatch);
            } else {
              return randomlySelectFromArray(modelMatch);
            }
          } else {
            return randomlySelectFromArray(oemMatch);
          }
        } else {
          return previewData[0];
        }
      };
      const selectedDefaultData = selectDefaultData(
        make,
        model,
        trim,
        previewData,
      );

      recursivelyRemoveEmpty(data);

      const defaultOfferData = Object.entries(selectedDefaultData).reduce(
        (acc: IGeneralObjectType, cur: any) => {
          // remove Lease prefix (ex. LeaseMonthlyPayment -> MonthlyPayment)
          const removeLease = /^Lease.*$/.test(cur[0])
            ? cur[0].slice(5)
            : cur[0];

          // lowercase first letter
          const key =
            removeLease.charAt(0).toLowerCase() + removeLease.slice(1);
          acc[key] = cur[1].toString();
          return acc;
        },
        {},
      );

      let filledData: TData = merge(
        { offer: { offerData: defaultOfferData } },
        data,
      );
      if (!!filledData?.canvasJson && data.offer?.offerData.imageUrl) {
        // get the carcut placeholder object in order to get its dimension
        const offerImgURL = data.offer?.offerData.imageUrl;
        const carcutImage = await getFabricImage(offerImgURL, 0, 0);

        filledData = {
          ...filledData,
          carcutImage,
        };
      }
      setData(filledData);
      setLoading(undefined);

      return filledData;
    },

    renderTemplateOn: async args => {
      const {
        canvas,
        htmlElement,
        complete,
        data,
        visibilities,
        logoSubstitutions,
        lifestyleImageUrl,
        lifestyleImageJson,
        order,
        disclosure,
        feedTabs,
      } = args;

      if (!data?.canvasJson) {
        message.error("Missing rendering data.");

        return;
      }

      let v2Disclosures: IStateDisclosureElement[] = [];
      if (legalLingoV2Enabled) {
        const currentDealer = data.dealer || { state: "", dealer_oem: "" };
        const oem =
          data.oem?.oem_name || currentDealer.dealer_oem || order?.dealer_oem;

        const fullStateName =
          fullStateOptions.find(el => el.abbreviation == currentDealer.state)
            ?.name || "";
        const { dealerName, vehicleCondition, vin } = data.offer?.offerData || {
          dealerName: "",
          vehicleCondition: "",
          vin: "",
        };

        try {
          const currentDisclosures =
            await API.services.legalLingoV2.getDisclosure({
              storeName: dealerName || order?.dealer_name,
              stateName: fullStateName,
              condition: vehicleCondition,
              vin,
              oem,
            });
          const { result } = currentDisclosures as {
            result: IDisclosure;
          };

          v2Disclosures = legalLingoV2Enabled ? result.disclosures : [];
          if (disclosure) {
            const { selectedAsset, currentSize, allSizes } = disclosure;
            const selectedDisc =
              selectedAsset?.disclosures ||
              currentSize?.disclosures ||
              allSizes?.disclosures ||
              [];
            if (selectedDisc.length) {
              v2Disclosures = selectedDisc;
            }
          }
        } catch (err) {
          v2Disclosures = [];
        }
      }

      setLoading({
        type: "template",
        message: "Rendering...",
      });

      canvas.clear();

      const canvasJson = data.canvasJson;

      if (canvasJson.backgroundImage) {
        // converting `canvas_bg` to `image` [AV2-2348]
        (canvasJson.backgroundImage as any).type = "image";
      }

      const processedJson = await updateCanvasObjects({
        canvasJson,
        data,
        htmlElement,
        visibilities,
        logoSubstitutions,
        lifestyleImageUrl,
        lifestyleImageJson,
        v2Disclosures,
        feedTabs,
      });

      // For some objects, the attributes are assigned to different values after updateCanvasObjects()
      // For example, the toggle boxes are created prior to the updateCanvasObjects() but after this function call,
      //  the discolsure textbox have different size (height in this case) because usually disclosure text are much longer than
      //  the variable ({disclosure}) in the textbox. Then we have to re-calculate the toggle box height to represent the bounding rectangle prooperly.
      const disclosureFabricObject = processedJson.objects.filter(
        obj => !!obj && isDisclosure(obj),
      );

      // There should be only one disclosure if present
      if (!!disclosureFabricObject && disclosureFabricObject.length === 1) {
        const disclosureToggleBox = data?.toggleBoxes?.find(
          box => box.objectType === "disclosure",
        );

        if (!disclosureFabricObject) {
          // Unlike disclosureFabricObject, this toggle MUST exist IF disclosure textbox is present.
          // We should throw error to quickly fix the issue.
          throw new Error("Disclosure toggle box must be present.");
        }
        setData(prevData => ({
          ...prevData,
          toggleBoxes: prevData?.toggleBoxes?.map(box => {
            if (box.id === disclosureToggleBox?.id) {
              return {
                ...box,
                height: getHeight(disclosureFabricObject[0]!),
              };
            }

            return box;
          }),
        }));
      }

      canvas.loadFromJSON(processedJson, () => {
        complete().then(() => {
          setLoading(undefined);
        });
      });
    },

    getData: () => data,

    update: (type, args) => {
      switch (type) {
        case "toggle_boxes":
          setData(data => {
            return {
              ...data,
              toggleBoxes: args.toggleBoxes,
            };
          });
          break;

        default:
          break;
      }
    },
  };

  return <context.Provider value={state}>{props.children}</context.Provider>;
};

////////////////////
// helper functions

const getImageSize = (imageUrl: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve([img.width, img.height]);
    img.onerror = () => reject();
    img.src = imageUrl;
  });
};

const getLogoRenderSize = (
  width: number,
  height: number,
  placeholderWidth: number,
  placeholderHeight: number,
) => {
  const widthRatio = width / placeholderWidth;
  const heightRatio = height / placeholderHeight;

  const ratio = Math.max(widthRatio, heightRatio);
  const resizeWidth = Math.floor(width / ratio);
  const resizeHeight = Math.floor(height / ratio);
  return { width: resizeWidth, height: resizeHeight };
};

const fetchResizedImage = async (
  imgUrl: string,
  width: number,
  height: number,
) => {
  const { result: base64 } = await API.services.common.getResizedImg({
    imgUrl,
    width,
    height,
  });
  return base64;
};

const getLogoImage = async (
  placeholder: IPlaceholder,
  logoImageUrl: string | null,
  object: ILogoObject,
) => {
  if (!logoImageUrl) {
    const img = document.createElement("img");
    return new fabric.Image(img);
  }

  const imageSize = (await getImageSize(logoImageUrl)) as number[];

  if (imageSize.length == 2) {
    const { width: resizeWidth, height: resizeHeight } = getLogoRenderSize(
      imageSize[0],
      imageSize[1],
      Math.ceil(placeholder.dimension.width),
      Math.ceil(placeholder.dimension.height),
    );
    const base64 = await fetchResizedImage(
      logoImageUrl,
      resizeWidth,
      resizeHeight,
    );
    return getReplacedFabricImage(
      object as any,
      base64,
      placeholder,
      undefined,
      undefined,
      true,
    );
  }

  return getReplacedFabricImage(
    object as any,
    `${logoImageUrl}?timestamp=${Date.now()}`,
    placeholder,
  );
};

export const fetchCanvasJson = async (url: string) => {
  try {
    const response = await fetch(`${url}?timestamp=${Date.now()}`, {
      method: "GET",
      cache: "no-cache",
    });

    const json = await response.json();

    return json as { [key: string]: ICanvasObject[] | string | number };
  } catch (err) {
    return null;
  }
};

const updateCanvasObjects = async (args: {
  canvasJson: {
    [key: string]: fabric.Object[] | string | number;
    width: number;
    height: number;
  };
  data: TData;
  htmlElement?: HTMLElement;
  visibilities?: TVisibility[];
  logoSubstitutions?: TLogoSubstitution[];
  lifestyleImageUrl?: string;
  lifestyleImageJson?: string;
  v2Disclosures?: IStateDisclosureElement[];
  feedTabs?: FeedTab[];
}) => {
  const { canvasJson, data, htmlElement, visibilities } = args;

  let objects = canvasJson.objects as fabric.Object[];

  // If visibilities is not undefined, we need to hide objects from the canvas.
  // Hide objects meaning remove it before render.

  if (!!visibilities) {
    objects = objects.filter(obj => {
      // At the point of writing this, only stamp and logo can be hidden.
      const canObjectBeHidden =
        isStamp(obj as unknown as ICanvasObject) ||
        isLogo(obj as unknown as ICanvasObject) ||
        isDisclosure(obj as unknown as ICanvasObject);
      if (!canObjectBeHidden) return true;
      /**
       * For hanlding visibilities, we have to handle old and new cases.
       * In latest change, each visibility has unique "id" that is assign to
       *  each objects in canvas. Those ids are uuid assign to a object.name
       *  when it was created in the editor. On Build page, when the visibility object is created,
       *  this object.name is assigned to visibility.id. Since object.name is unique, so the visibility.id.
       * But in old logic, the visibility.id might not be unique because some object, object.name is not a uuid but its resoulce id.
       * For example, a stamp.id is assigned to visiblity.id. If a template has multiple stamps with same id, visibility.id isnt unique anymore.
       * So we keep track of the index (visibility.order). This gotta be handled until all old instances are up to date.
       */
      let foundVisibility = visibilities.find(vis => vis.id === obj.name);

      if (
        isStamp(obj as unknown as ICanvasObject) &&
        foundVisibility === undefined
      ) {
        foundVisibility = getStampVisibility(objects, visibilities);
      }

      return !!foundVisibility ? foundVisibility.isVisible : true;
    });
  }

  // This unprocessedOfferTypes will be used to keep the order of stamps when rendering..
  // This array will pop the processed/rendered offer type of a stamp from beginning of the array.
  const unprocessedOfferTypes = [...(data.offer?.offerTypes || [])];
  if (unprocessedOfferTypes.length === 0) {
    message.warning("No offer type has been selected.");
  }

  const updatedObjects = [];
  // keeping track of original stamps to matchback the id with the copied stamps
  const originalStampsArray = [];
  const copiedOffers = [...unprocessedOfferTypes];
  for (const object of objects) {
    const copied: any = { ...object }; // should be fabric.Object
    if (
      isStamp(object as unknown as ICanvasObject) &&
      !copied.customData?.originalStampId
    ) {
      originalStampsArray.push({
        offerType: copiedOffers.shift(),
        stamp: copied.customData?.stampId,
      });
    }
  }

  for (const object of objects) {
    const copied: any = {
      ...object,

      // if needed, below two attributes will be enabled later
      selectable: false,
      evented: false,
    };

    // Processing and updating the canvas objects.
    // !!!NOTE: That we need to handle stamps separately in order to preserve the order of selected offer types.
    //          The way we keep the order is to process each canvas object, when we find the stamp object,
    //            we first take out the next offer type from unprocessedOfferTypes and render the stamp with this
    //            offer type. Since the unprocessedOfferTypes is altered, the next stamp will be processed with the
    //            next unprocessed offer type.

    let obj: ICanvasObject | undefined;
    if (isStamp(copied as unknown as ICanvasObject)) {
      // process stamp
      let offerType = unprocessedOfferTypes.shift();

      // AV2-3060: Skip PurchasePlaceholder as a displayed stamp
      if ((offerType as string) === "PurchasePlaceholder") {
        offerType = unprocessedOfferTypes.shift();
      }

      obj = !!offerType
        ? await processStampObject(copied, data, offerType, htmlElement)
        : copied;
    } else {
      obj = await processFabricObject({
        object: copied,
        data,
        logoSubstitutions: args.logoSubstitutions,
        lifestyleImageUrl: args.lifestyleImageUrl,
        lifestyleImageJson: args.lifestyleImageJson,
        v2Disclosures: args.v2Disclosures,
        feedTabs: args.feedTabs,
        canvasWidth: canvasJson.width,
        canvasHeight: canvasJson.height,
      });
    }

    updatedObjects.push(!!obj ? obj : null); // if something went wrong, render nothing.
  }

  return {
    ...canvasJson,
    objects: updatedObjects,
  };
};

const clearFontCache = (styleObj: any) => {
  const stylesObjList = Object.keys(styleObj).map(key => styleObj[key]);
  const styleFontsPerLineList = stylesObjList.map(key => {
    return Object.keys(key || {}).map(k => key[k]);
  });

  styleFontsPerLineList.forEach(line => {
    line.forEach(fontObj => {
      fabric.util.clearFabricFontCache(fontObj.fontFamily);
    });
  });
};

const processFabricObject = async (
  args: { object: any; data: TData } & {
    logoSubstitutions?: TLogoSubstitution[];
    lifestyleImageUrl?: string;
    lifestyleImageJson?: string;
    v2Disclosures?: IStateDisclosureElement[];
    feedTabs?: FeedTab[];
    canvasWidth: number;
    canvasHeight: number;
  },
) => {
  const { object, data, v2Disclosures, feedTabs } = args;

  if (!data.offer?.offerData || !data.dealer || !data.oem) {
    const missingResourceNames = [];
    if (!data.offer?.offerData) missingResourceNames.push("Offer");
    if (!data.dealer) missingResourceNames.push("Dealer");
    if (!data.oem) missingResourceNames.push("Oem");

    message.error(
      `${missingResourceNames.join(
        ",",
      )} are missing. Most likely, this is system error.`,
    );

    return;
  }
  let copied: CanvasObject = {
    ...object,
  };
  const textHasStoreVarsRegex = textHelpers.dealerVariablesRegex();
  const { offerData } = data.offer;
  const { year, make, model, trim, modelCode, msrp, dealerCode, vin } =
    offerData || {};
  const feedData: FeedOffer[] = [];
  const feedId = feedTabs ? feedTabs[0]?.feedId : "";
  const filterBy: VehicleConditions = "New";
  const updatedMSRP = `${msrp}`.replace(",", "");

  const params = {
    feedId,
    dealerCode: data.dealer?.dealer_code || dealerCode,
    dealerOem: make,
    filterBy,
    searchBy: "",
    filterField: "dealerId",
    filterFieldSearch: "",
    msrp: updatedMSRP,
    sortingOptions: [],
  };
  if (feedId?.length) {
    const res = await API.services.assetBuilder.getTabData(params, 1);
    const { result, error: apiError } = res;
    if (!apiError && result?.offerList) feedData.push(...result.offerList);
  }
  const isRealVin = vin?.length === 17;
  if (isDisclosure(object as unknown as ICanvasObject)) {
    let offerList: IOffer[] = []; // TODO: handle this

    // we check this field via model code, msrp and dealer code. if not applicable for that offer use ymmt
    const fieldsExist =
      (modelCode && msrp && dealerCode) ||
      (year && make && model && trim && msrp);

    const { disclosures, exceptions } = legalLingoV2Enabled
      ? { disclosures: [], exceptions: [] }
      : data;

    let numberAtPriceDisclosure: IStateDisclosureElement | undefined;

    if (fieldsExist && isRealVin) {
      numberAtPriceDisclosure =
        disclosures?.find(
          discObj => discObj.offerType === "Number at this Price",
        ) ||
        exceptions?.find(
          discObj => discObj.offerType === "Number at this Price",
        );

      if (numberAtPriceDisclosure) {
        const { numberAtThisPriceOfferList } = data;

        /* Fix for AV2-3469:
         * Changes made to offers in the order should be considered
         * when calculating number at this price
         */
        const stringMsrp = typeof msrp === "number" ? `${msrp}` : msrp;
        const parsedMsrp = parseFloat(stringMsrp.replace(",", ""));

        const matchingOffersFromOrder =
          data.selectedOffers?.filter(selectedOffer => {
            const {
              trim: currTrim,
              msrp: currMsrp,
              modelCode: currModelCode,
              dealerCode: currDealerCode,
            } = selectedOffer.offerData;
            const currStrMsrp =
              typeof currMsrp === "number" ? `${currMsrp}` : currMsrp;
            const parsedCurrMsrp = parseFloat(currStrMsrp?.replace(",", ""));

            const isNotMatchedFromService = !numberAtThisPriceOfferList?.find(
              offerFromService =>
                selectedOffer.offerData.vin ===
                (offerFromService as unknown as IRawOfferDataFromService).row
                  .vin,
            );

            return (
              isNotMatchedFromService &&
              parsedCurrMsrp === parsedMsrp &&
              currTrim === trim &&
              currModelCode === modelCode &&
              currDealerCode === dealerCode
            );
          }) ?? [];

        // Convert offers from matchingOffersFromOrder to type of numberAtThisPriceOfferList
        const selectedOffesrTypedLikeFromService = matchingOffersFromOrder.map(
          selectedOffer => ({ row: selectedOffer.offerData }),
        ) as unknown as IOffer[];

        offerList = (numberAtThisPriceOfferList || []).concat(
          selectedOffesrTypedLikeFromService,
        );
      }
    }

    const useNumberAtPrice = !!numberAtPriceDisclosure && offerList.length > 0;

    const disclosureNames = returnDisclosureNamesToUse(
      data.offer?.offerTypes || [],
      useNumberAtPrice,
    );

    let currentException: IStateExceptionElement | undefined;
    let textHasNumberAtPriceValues = false;
    let disclosureText = "";
    disclosureNames.forEach((disclosureName, index) => {
      // fill
      let currentDisclosure = v2Disclosures?.length
        ? v2Disclosures?.find(
            disclosure => disclosure.offerType === disclosureName,
          )
        : disclosures?.find(
            disclosure => disclosure.offerType === disclosureName,
          );
      if ((exceptions?.length || 0) > 0) {
        currentException = exceptions?.find(
          exception => exception.offerType === disclosureName,
        );

        const replaceDisclosure =
          currentException &&
          currentException.text &&
          currentException.text.trim() !== "";

        currentDisclosure =
          replaceDisclosure && !v2Disclosures?.length
            ? currentException
            : currentDisclosure;

        currentDisclosure = currentDisclosure;
      }

      let { text = "" } = currentDisclosure || {};

      /*
         AV2-1548: Lithia requested the separate use of
         Number at this Price disclosures variables  from the disclosure itself
         They can be used in the Vehicle Info disclosure. If used,
         the entire Number of this Price Disclosure is skipped
       */

      if (disclosureName === "Vehicle Info") {
        textHasNumberAtPriceValues = textHelpers
          .numberAtThisPriceVarRegex()
          .test(text);

        const selectedNumAtPriceDisc = v2Disclosures?.find(
          disc => disc.offerType === "Number at this Price",
        );

        if (textHasNumberAtPriceValues && selectedNumAtPriceDisc) {
          const numAtThisPriceReplacement =
            offerHelpers.returnNumberAtThisPriceText(
              (selectedNumAtPriceDisc ||
                currentException ||
                numberAtPriceDisclosure) as IStateDisclosureElement,
              offerList,
              vin,
              feedData,
            );
          text = text.replace(
            /\{numberAtThisPrice\}/gi,
            `${numAtThisPriceReplacement}`,
          );
        }

        if (textHasNumberAtPriceValues && numberAtPriceDisclosure) {
          const { max_number_of_vins: maxNumberOfVins } = JSON.parse(
            numberAtPriceDisclosure.text,
          ) as offerHelpers.INumberAtPriceDisclosureObj;

          const replacedDiscText = offerHelpers.replaceNumberAtPriceVarText(
            text,
            offerList,
            maxNumberOfVins,
            vin,
            feedData,
          );
          text = replacedDiscText;
        }
      } else if (
        disclosureName === "Number at this Price" &&
        useNumberAtPrice &&
        !textHasNumberAtPriceValues
      ) {
        // NOTE: within the call chain, the vin in the element of offerList will be used ONLY. So type casting here will be fine.
        text = offerHelpers.returnNumberAtThisPriceText(
          (currentException ||
            numberAtPriceDisclosure) as IStateDisclosureElement,
          offerList,
          vin,
          feedData,
        );
      }

      const skipNumberAtThisPrice =
        disclosureName === "Number at this Price" && textHasNumberAtPriceValues;

      /*
         AV2-1548: if no Number at this Price variables
         were used independently use disclosure like before
       */
      disclosureText += skipNumberAtThisPrice
        ? ""
        : text
        ? `${text}`
        : `{${disclosureName}}`;

      if (index < disclosureNames.length - 1) {
        disclosureText += " ";
      }
    });

    const disclosureIdentiers = disclosureNames.map(disclosureName =>
      disclosureName.toUpperCase(),
    );

    const { dealer, offer, order } = data;
    const { offerData } = offer;
    let filledText = replaceTextTokens({
      text: disclosureText,
      offerData: data.offer!.offerData,
      replacePunctuation: true,
      skipValueIfZero: true,
    });

    if (textHasStoreVarsRegex.test(filledText)) {
      filledText = data.dealer
        ? textHelpers.replaceDealerTokens(filledText, dealer)
        : filledText;

      filledText = textHelpers.replaceFinalPriceVariable(filledText, dealer);
    }

    const textWithExpDate = textHelpers.replaceDisclosureTypeVariable(
      "Expiration Date",
      filledText,
      offerData.expirationDate
        ? formatDateValue(offerData.expirationDate)
        : moment(order?.expiresAt).format("MM/DD/YYYY"),
    );

    const cleanedText = textHelpers.removeSentencesWithUnfilledVars(
      disclosureText,
      textWithExpDate,
      disclosureIdentiers,
    );

    const textbox = await new Promise<fabric.Textbox>(resolve => {
      fabric.Textbox.fromObject(copied, (textbox: fabric.Textbox) => {
        textbox.set({
          text: cleanedText,
        });

        resolve(textbox);
      });
    });

    copied = {
      ...copied,
      ...textbox.toJSON(),
    } as ITextObject;
  } else if (isText(object)) {
    /*** NOTE: below type conversion to any is necessary because in order to extract originalText, obj need to converted to fabric.Textbox & { originalText: string }
     * But the compiler complains because obj is missing attributes from fabric.Textbox.
     */

    const { text, styles } = processTextboxObject({
      object,
      data,
      textHasStoreVarsRegex,
    });

    clearFontCache(styles);

    copied = {
      ...copied,
      text,
      styles,
    } as ITextObject;
  } else if (isCarcut(object)) {
    if (offerData) if (!offerData.imageUrl) return object;
    /* if there is no carcut image, use default */
    const carcutImageUrl = `${offerData.imageUrl}?timestamp=${Date.now()}`;
    const { top, left, width, height, scaleX, scaleY } = object;
    const placeholder: IPlaceholder = {
      top,
      left,
      dimension: {
        width: width * scaleX,
        height: height * scaleY,
      },
    };

    let { carcutImage } = data;
    if (!carcutImage) {
      carcutImage = await new Promise<fabric.Image>(resolve => {
        fabric.Image.fromURL(
          carcutImageUrl,
          img => {
            return resolve(img);
          },
          {
            crossOrigin: "anonymous",
            top,
            left,
          },
        );
      });
    }

    const repositionedCarcutImage = returnRePositionedImage(
      carcutImage,
      placeholder,
    );
    copied = {
      ...copied,
      ...repositionedCarcutImage.toJSON(),
    } as IImageObject;
  } else if (isLogo(object)) {
    const { top, left } = object;

    let logoImageUrl = getLogoImageUrl(data, object);

    if (args.logoSubstitutions?.some(sub => sub.id === (object as any).name)) {
      logoImageUrl =
        args.logoSubstitutions?.find(sub => sub.id === (object as any).name)
          ?.currentImageUrl || "";
    }

    const toggleBox = args.data.toggleBoxes?.find(
      box => box.id === (object as any).name,
    );

    const placeholder: IPlaceholder = {
      top,
      left,
      dimension: {
        width: toggleBox?.width || getWidth(object),
        height: toggleBox?.height || getHeight(object),
      },
    };

    const image = await getLogoImage(placeholder, logoImageUrl, object);

    copied = {
      ...copied,
      ...image.toJSON(),
    };
  } else if (isVideo(object)) {
    /*
       The images for paused videos need to be set again in order to
       handle edge case where capturing canvas throws "canvas insecure"
       error
     */
    const { top, left, width, height, scaleX, scaleY, opacity, customData } =
      object as unknown as IExtendedFabricObject;

    const url = (customData as ICustomVideoData).videoSrc.replace(
      ".mp4",
      ".png",
    );

    const image = await new Promise<fabric.Image>((resolve, reject) =>
      fabric.Image.fromURL(
        url || "",
        img => {
          return img
            ? resolve(img)
            : reject(new Error(`Logo image is: ${JSON.stringify(img)}`));
        },

        {
          crossOrigin: "anonymous",
          top,
          left,
          width,
          height,
          scaleX,
          scaleY,
          opacity,
        },
      ),
    )
      .then(data => data)
      .catch(() => null);

    if (image) {
      copied = {
        ...copied,
        ...(image ? image.toJSON() : {}),
      };
    }
  } else if (isThemeBackground(object) || isLifestyleImage(object)) {
    // TODO: This part is responsible for replacing theme background image.
    // However, we are checking here if the object is lifestyle image. The reason is
    // for the old logic handlding theme background image, we were mixing with lifestyle image.
    // So for all old data, the object.customType  was set to "lifestyle" and we have to handle it as well.

    const shouldReplaceThemeBackground =
      args.data.template?.type === "carcut" && args.lifestyleImageUrl;
    if (shouldReplaceThemeBackground) {
      const isVideo = args.lifestyleImageUrl?.endsWith("mp4");
      const url = args.lifestyleImageUrl || "";
      const assetUrl = isVideo ? url.replace("mp4", "png") : url;
      const img = await getReplacedFabricImage(
        object as unknown as fabric.Image,
        assetUrl,
        {
          top: 0,
          left: 0,
          dimension: {
            width: args.data.template?.artboard.width || 0,
            height: args.data.template?.artboard.height || 0,
          },
        },
      );

      copied = {
        ...copied,
        ...img.toJSON(),
        customData: {
          ...copied.customData,
          videoSrc: isVideo ? url : "",
        },
      };
    } else {
      const commonAttributes = {
        selectable: true,
        evented: true,
      };
      if (args.lifestyleImageJson) {
        copied = {
          ...copied,
          ...(args.lifestyleImageJson as any),
          filters: undefined, // The filter added in the "complete()" function was throwing error..
          ...commonAttributes,
        };
      } else {
        const width = getWidth(object);
        const height = getHeight(object);
        const placeholder: IPlaceholder = {
          top: object.top,
          left: object.left,
          dimension: {
            width,
            height,
          },
        };

        const img = await getReplacedFabricImage(
          object as unknown as fabric.Image,
          args.lifestyleImageUrl || "",
          placeholder,
          undefined,
          "width",
        );

        (img as fabric.Image).set({
          clipPath: new fabric.Rect({
            top: object.top,
            left: object.left,
            width,
            height,
            absolutePositioned: true,
          }),
        });

        copied = {
          ...copied,
          ...img.toJSON(),
          ...commonAttributes,
        };
      }
    }
  }

  return copied;
};

const processStampObject = async (
  object: any,
  data: TData,
  offerType?: OfferType,
  htmlElement?: HTMLElement,
) => {
  const textHasStoreVarsRegex = textHelpers.dealerVariablesRegex();
  const stampCopiedId = (object.customData as IStampObjectData)
    ?.originalStampId;
  const stampId = (object.customData as IStampObjectData)?.stampId;

  const stampIdMatch = data.stamps?.filter(
    stmp => stmp.id === stampId || (stampCopiedId && stmp.id === stampCopiedId),
  );
  const stamp = data.stamps?.find(
    stmp => stmp.offerType === offerType && stmp.status === "PUBLISHED",
  );

  if (!stampIdMatch || !stamp?.stampJson || !offerType) {
    // We should display error message with link to the template that contains invalid stamp in it.

    const title = (
      <div>
        <span>{`Stamp ID mismatch, please re-save the stamp to resolve this warning in `}</span>
        <span
          onClick={() => {
            htmlElement?.scrollIntoView(true);
          }}
        >
          <a href="#">template</a>
        </span>
      </div>
    );
    const description = (
      <div>
        Click&nbsp;
        <span>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`/design-studio/editor/templates/${
              data.template?.id ?? ""
            }/editor`}
          >
            here
          </a>
        </span>
        &nbsp;to fix the template in the editor.
      </div>
    );
    // AV2-2963: Do not show error if the Purchase checkbox was checked
    if (!!offerType && !offerType.includes("Purchase")) {
      notification.warning({
        message: title,
        description,
        duration: 7, // this will require user interaction to close the notificaiton
      });
    }

    // try to create placeholder for this stamp
    // first, try to find with object.name where one of toggle box "id" has same value.
    // If this is not found, it probably means the template is old one. Then, we need to compare with stamp id and its index among all stamps in the canvas.
    // Allowing DCD client to use stamps when stampIds don't match
    if (!stampMismatchEnabled) {
      let toggleBox = data.toggleBoxes?.find(box => box.id === object.name);
      if (!toggleBox) {
        let thisStampIdx: number | undefined;
        let thisStampId: string | undefined;
        const stamps = (
          data.canvasJson as unknown as {
            objects: fabric.Object[];
          }
        ).objects.filter(obj => isStamp(obj as unknown as ICanvasObject));
        for (let i = 0; i < stamps.length; i++) {
          const stampObj = stamps[i];

          if (stampObj.name === object.name) {
            thisStampId = (
              (stampObj as IExtendedFabricObject).customData as IStampObjectData
            ).stampId;
            thisStampIdx = i;

            break;
          }
        }

        toggleBox = data.toggleBoxes?.find(
          box => box.id === thisStampId && box.order === thisStampIdx,
        );
      }
      const placeholder: IPlaceholder | undefined = !!toggleBox
        ? {
            top: toggleBox.y,
            left: toggleBox.x,
            dimension: {
              width: toggleBox.width,
              height: toggleBox.height,
            },
          }
        : undefined;
      const image = await createImagePlaceholder(object, placeholder);

      return {
        ...object,
        ...image.toJSON(),
      };
    }
  }

  const stampObjects = (stamp?.stampJson.objects as fabric.Textbox[])
    .filter(item => isText(item as unknown as ICanvasObject))
    .map(tmpObj => {
      const { text, styles } = processTextboxObject({
        object: tmpObj as unknown as ITextObject,
        data,
        textHasStoreVarsRegex,
        canvasType: "stamp",
      });

      clearFontCache(styles);

      return initiateTextbox({
        text,
        properties: {
          left: tmpObj.left,
          top: tmpObj.top,
          width: tmpObj.width,
          height: tmpObj.height,
          fill: tmpObj.fill,
          fontFamily: tmpObj.fontFamily,
          fontSize: tmpObj.fontSize,
          fontStyle: tmpObj.fontStyle,
          fontWeight: tmpObj.fontWeight,
          textAlign: tmpObj.textAlign,
          lineHeight: tmpObj.lineHeight || 1.0,
          superscript: tmpObj.superscript,
          subscript: tmpObj.subscript,
          charSpacing: tmpObj.charSpacing,
          styles,
        },
      });
    });

  if (!data.offer?.offerData) {
    message.warning("Invalid offer data.");

    return object;
  }
  const { scaleX, scaleY, top, left } = object || {
    scaleX: 1,
    scaleY: 1,
    top: 0,
    left: 0,
  };

  const clonedObjects = await Promise.all(
    stampObjects.map(obj => {
      return new Promise<fabric.Textbox>(resolve => {
        obj.clone((cloned: any) => {
          const canvasTextbox = cloned as fabric.Textbox;
          const { text = "" } = canvasTextbox;
          const newText = replaceTextTokens({
            text,
            offerData: data.offer!.offerData,
          });

          canvasTextbox.set({
            text: newText,
            scaleX,
            scaleY,

            // the position of the stamp object placed within template + the textbox position within the stamp
            top: cloned.top * scaleX + top,
            left: cloned.left * scaleY + left,
          });

          resolve(canvasTextbox);
        });
      });
    }),
  );

  const group = new fabric.Group(clonedObjects);

  return {
    ...object,
    ...group.toJSON(),
  };
};

type TProcessTextboxObjectArgs = {
  object: ITextObject;
  data: TData;
  textHasStoreVarsRegex: ReturnType<typeof textHelpers.dealerVariablesRegex>;
  canvasType?: CanvasType;
};

const processTextboxObject = (args: TProcessTextboxObjectArgs) => {
  const { object, data, canvasType } = args;
  const { offer } = data;
  if (!offer) {
    return {
      text: object.text,
      styles: object.styles,
    };
  }
  const { offerData } = offer;
  const vinsAtThisPriceRegex = /\{vinsAtThisPrice\}/gi;
  const numberAtThisPriceRegex = /\{numberAtThisPrice\}/gi;

  const textboxProps: Partial<Record<textHelpers.StylePropKeys, any>> = {};
  for (const prop of textHelpers.styleProps) {
    if (!object[prop as keyof ITextObject]) {
      continue;
    }
    textboxProps[prop] = object[prop as keyof ITextObject];
  }

  const { styles, text, originalText, variableAlias } = object as any;

  let templateText: string = text;

  const masksOn =
    Object.keys(variableAlias || {}).filter(
      aliasVar => variableAlias[aliasVar]?.isMaskOn,
    ).length > 0;

  // check if masks are turned on here
  if (masksOn && originalText) {
    templateText = originalText as string;
  }

  let finalText = templateText;
  let finalStyles = styles;
  let finalTextLines: string[] = [];
  let variablesAndValues: Record<string, string> = {};

  const matches = templateText
    .match(/{[^\}^\{^\n]*}/g)
    ?.map(match => match.replace(/\{|\}/g, ""));

  const lcOfferDataProps = Object.keys(offerData).map(propKey =>
    propKey.toLowerCase(),
  );
  const lcStoreVariableNames = storeVariableNames.map(varName =>
    varName.toLowerCase(),
  );

  const offerDataVariables = (
    (matches?.filter(
      variable =>
        templateText.toLowerCase().includes(`{${variable.toLowerCase()}}`) &&
        lcOfferDataProps.includes(variable.toLowerCase()),
    ) ||
      Object.keys(offerData).filter(key =>
        templateText.toLowerCase().includes(key.toLowerCase()),
      )) as Array<keyof OfferData>
  ).filter(variable => !lcStoreVariableNames.includes(variable.toLowerCase()));
  for (const variable of offerDataVariables) {
    const searchPattern = `{${variable}}`;
    const replacePattern = textHelpers.returnOfferDataValueForVarPopulation({
      dealer: data.dealer,
      variable,
      offerData,
      variableAlias,
      willRoundValue:
        canvasType === "stamp" ||
        isDisclosure(object as unknown as ICanvasObject),
      overridePunctuationReplace: true,
    });
    variablesAndValues = {
      ...variablesAndValues,
      [searchPattern]: replacePattern,
    };

    if (searchPattern.toLowerCase() === replacePattern.toLowerCase()) {
      continue;
    }

    const {
      text: newText,
      styles: newStyles,
      textLines: newTextLines,
    } = textHelpers.returnTextAndStylesForVariablePopulation({
      text: finalText,
      styles: finalStyles,
      textboxProps,
      searchPattern,
      replacePattern,
    });

    finalText = newText;
    finalStyles = newStyles;
    finalTextLines = newTextLines ?? [];
  }

  const { textHasStoreVarsRegex } = args;
  const { dealer } = data;
  if (textHasStoreVarsRegex.test(finalText.toLowerCase())) {
    try {
      const dealerDataVariables = matches?.filter(
        variable =>
          templateText.toLowerCase().includes(`{${variable.toLowerCase()}}`) &&
          lcStoreVariableNames.includes(variable.toLowerCase()),
      );

      if (!dealerDataVariables || !dealer) {
        throw new Error("Could not replace store-based variables.");
      }

      for (const variable of dealerDataVariables) {
        const searchPattern = `{${variable}}`;
        const replacePattern =
          textHelpers.returnDealerDataValueForVarPopulation(
            variable,
            dealer,
            variableAlias,
          );

        if (searchPattern.toLowerCase() === replacePattern.toLowerCase()) {
          continue;
        }

        const { text: newText, styles: newStyles } =
          textHelpers.returnTextAndStylesForVariablePopulation({
            text: finalText,
            styles: finalStyles,
            textboxProps,
            searchPattern,
            replacePattern,
          });

        finalText = newText;
        finalStyles = newStyles;
      }

      if (finalText.toLowerCase().match(textHelpers.finalPricePriceNameRegex)) {
        const finalPriceNameSearchPattern = "{finalPriceName}";
        const finalPriceVarReplacePattern =
          offerData.finalPriceName ||
          textHelpers.returnFinalPriceValueForVarPopulation(
            dealer,
            variableAlias,
          );

        const { text: newText, styles: newStyles } =
          textHelpers.returnTextAndStylesForVariablePopulation({
            text: finalText,
            styles: finalStyles,
            textboxProps,
            searchPattern: finalPriceNameSearchPattern,
            replacePattern: finalPriceVarReplacePattern,
          });

        finalText = newText;
        finalStyles = newStyles;
      }
    } catch (error) {
      /*
         Just continue if an error occurs.
         Unfilled variables will be handled at the end
       */
    }
  }

  if (vinsAtThisPriceRegex.test(finalText.toLowerCase())) {
    try {
      const { numberAtThisPriceOfferList: offerList } = data;
      const vinsAtThisPriceSearchPattern = "{vinsAtThisPrice}";
      const vinsAtThisPriceReplacePattern = `${offerList?.length || 0}`;
      const { text: newText, styles: newStyles } =
        textHelpers.returnTextAndStylesForVariablePopulation({
          text: finalText,
          styles: finalStyles,
          textboxProps,
          searchPattern: vinsAtThisPriceSearchPattern,
          replacePattern: vinsAtThisPriceReplacePattern,
        });
      finalText = newText;
      finalStyles = newStyles;
    } catch (error) {
      /*
         Just continue if an error occurs.
         Unfilled variables will be handled at the end
       */
    }
  }

  // Handle numberAtThisPrice variable
  let numberAtThisPriceLegalObj = data.disclosures?.find(
    discObj => discObj.offerType === "Number at this Price",
  );
  const numberAtThisPriceExcObj = data.exceptions?.find(
    excObj => excObj.offerType === "Number at this Price",
  );

  if (numberAtThisPriceExcObj) {
    numberAtThisPriceLegalObj = numberAtThisPriceExcObj;
  }

  if (
    numberAtThisPriceRegex.test(finalText.toLowerCase()) &&
    numberAtThisPriceLegalObj
  ) {
    try {
      const { numberAtThisPriceOfferList: offerList, offer } = data;
      const numberAtThisPriceSearchPattern = "{numberAtThisPrice}";

      if (!offerList || !offer?.offerData) {
        throw new Error(
          "No offer data is available to generate Number at This Price text",
        );
      }

      let numberAtThisPriceReplacePattern =
        offerHelpers.returnNumberAtThisPriceText(
          numberAtThisPriceLegalObj,
          offerList,
          offer.offerData.vin,
        );
      numberAtThisPriceReplacePattern = replaceTextTokens({
        text: numberAtThisPriceReplacePattern,
        offerData: offer.offerData,
      });

      const { text: newText, styles: newStyles } =
        textHelpers.returnTextAndStylesForVariablePopulation({
          text: finalText,
          styles: finalStyles,
          textboxProps,
          searchPattern: numberAtThisPriceSearchPattern,
          replacePattern: numberAtThisPriceReplacePattern,
        });
      finalText = newText;
      finalStyles = newStyles;
    } catch (error) {
      /*
         Just continue if an error occurs.
         Unfilled variables will be handled at the end
       */
    }
  }

  if (textHelpers.generalVariableRegex.test(finalText)) {
    const { text: newText, styles: newStyles } =
      textHelpers.returnTextAndStylesAfterRemovingUnfilledVars({
        text: finalText,
        styles: finalStyles,
        textboxProps,
      });

    finalText = newText;
    finalStyles = newStyles;
  }

  const originalTextArr = templateText.replace(/\n/g, "").split("");
  const flatStyles = textHelpers.getCleanedStyles(finalStyles).flat();
  const testStyles = textHelpers.replaceStylesWithVariables(
    originalTextArr,
    textHelpers.getCleanedStyles(finalStyles),
    flatStyles,
    finalTextLines,
    variablesAndValues,
  );
  finalStyles = testStyles;

  return {
    text: finalText,
    styles: finalStyles,
  };
};

const getToggleBoxes = (
  fabricObjects: IExtendedFabricObject[],
  rect: ClientRect,
  wrapperRect: ClientRect,
  visibilities?: TVisibility[],
) => {
  // We need x,y,width and height of the bounding rect for each stamps.
  // There is padding between the wrapper div and the canvas. We need to consider this.
  const paddingLeft = rect.left - wrapperRect.left;
  const paddingTop = rect.top - wrapperRect.top;

  const updatedToggleBoxes: IBox[] = fabricObjects.map((obj, index) => {
    const objectType = obj.customType;
    let subtype: LogoEventType | undefined = undefined;
    if (objectType === "logo") {
      const { customData } = obj;
      const { logoEventType } = customData as ILogoObjectData;
      subtype = logoEventType;
    }

    if (!obj.name)
      throw new Error('Each object in canvas must have "name" attribute.');

    const id = obj.name || "";

    let foundVisibleObject = visibilities?.find(
      visibilityObj => visibilityObj.id === id,
    );
    if (
      isStamp(obj as unknown as ICanvasObject) &&
      foundVisibleObject === undefined
    ) {
      foundVisibleObject = getStampVisibility(fabricObjects, visibilities);
    }

    // If a visibility object is found, use its isVisible.
    // If not found, then all objects must be shown by default.
    const isVisible = !!foundVisibleObject
      ? foundVisibleObject.isVisible
      : true;

    const width = getWidth(obj);
    const height = getHeight(obj);

    return {
      objectType,
      subtype,
      order: index,
      id,

      // 4px is the width of the border
      // -2px is to center the border that has been shifted 4px to its right
      width,
      height,
      x: (obj.left || 0) + paddingLeft,
      y: (obj.top || 0) + paddingTop, // This 14px is the padding between the canvas wrapper and the actual canvas
      isVisible,
      object: obj,
    };
  });
  return updatedToggleBoxes;
};

export default Provider;
