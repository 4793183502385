import API from "services";

import { useMutation, useQueryClient } from "react-query";
import useSetAdsQueryData from "./useSetAdsQueryData";

import {
  IAd,
  ICreateOrUpdateAdResponseData,
  IGetAdsResponseData,
} from "shared/types/adLibrary";
import { UpdatePlannerInstanceAdRequestBody } from "screens/campaignManagement/campaignPlanner/types";
import { CampaignAdStatus } from "shared/types/campaignPlanner";
import { queryKeys } from "../campaignPlanner/queryKeys";
import { isEmpty, isEqual, uniqWith } from "lodash";

const updatePlannerInstanceAd = async (ad: IAd) => {
  const plannerUpdates =
    ad.planners?.flatMap(planner =>
      planner.instances?.map(instance => {
        return {
          plannerId: planner.id,
          instanceId: instance.id,
          adId: ad.id,
          previousCampaignStatus: instance.adStatuses[ad.id],
          campaignStatus: CampaignAdStatus.LOADED_EDITED,
        };
      }),
    ) || [];
  const instanceUpdates =
    ad.instances?.map(instance => ({
      plannerId: instance.plannerId,
      instanceId: instance.id,
      adId: ad.id,
      previousCampaignStatus: instance.adStatuses[ad.id],
      campaignStatus: CampaignAdStatus.LOADED_EDITED,
    })) || [];

  const updates: UpdatePlannerInstanceAdRequestBody[] = uniqWith(
    [...instanceUpdates, ...plannerUpdates],
    isEqual,
  ).filter(update => update.previousCampaignStatus === CampaignAdStatus.LOADED);

  if (isEmpty(updates)) return;

  await API.services.campaignPlanner.updateModifiedAdsPlannersHandler(updates);
};

const createOrUpdateAd = async (ad: IAd) => {
  const { result, error } = await API.services.adLibrary.createOrUpdateAd(ad);

  if (error) {
    throw Error(error.message);
  }

  await updatePlannerInstanceAd(ad);

  return result;
};

const batchCreateOrUpdateAds = async (ads: IAd[]) => {
  const { result, error } = await API.services.adLibrary.batchCreateOrUpdateAds(
    ads,
  );

  if (error) {
    throw Error(error.message);
  }

  return result;
};

export const useMutateAd = () => {
  const queryClient = useQueryClient();

  return useMutation<ICreateOrUpdateAdResponseData | null, Error, IAd>(
    createOrUpdateAd,
    {
      onSettled: () => {
        queryClient.invalidateQueries("ads");
        queryClient.invalidateQueries(queryKeys.campaignPlanners);
      },
    },
  );
};

export const useBatchMutateAds = () => {
  const queryClient = useQueryClient();
  const setAdsQueryData = useSetAdsQueryData();

  return useMutation<
    ICreateOrUpdateAdResponseData[] | null,
    Error,
    IAd[],
    { previousAds?: IGetAdsResponseData }
  >(batchCreateOrUpdateAds, {
    onMutate: async (ads: IAd[]) => {
      const previousAds = await setAdsQueryData({
        ads,
        operation: "mutate",
      });

      return { previousAds };
    },
    onError: (_err, _newAds, context) => {
      queryClient.setQueryData("ads", context?.previousAds);
    },
    onSettled: () => {
      queryClient.invalidateQueries("ads");
    },
  });
};

export const useOptimisticallyMutateAd = () => {
  const queryClient = useQueryClient();
  const setAdsQueryData = useSetAdsQueryData();

  return useMutation<ICreateOrUpdateAdResponseData | null, Error, IAd>(
    createOrUpdateAd,
    {
      onMutate: async (ad: IAd) => {
        await setAdsQueryData({ ads: [ad], operation: "mutate" });
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries("ads");
        queryClient.invalidateQueries(queryKeys.campaignPlanners);
      },
    },
  );
};
