import { Result, Spin, message, notification } from "antd";
import { fabric } from "fabric";
import { isEqual } from "lodash";

import { useRenderTemplate } from "shared/components/RenderTemplateProvider";
import { useClientRect } from "utils/helpers.hooks";
import * as assetHelpers from "utils/helpers.asset";

import {
  OfferData,
  TVisibility,
  IAssetBuildInstance,
  IAssetBuilderState,
  TLogoSubstitution,
  ICanvasObject,
  IAssetBuild,
  isLogo,
  isLifestyleImage,
  ISelectedOffer,
  FeedTab,
  SelectedInstance,
} from "shared/types/assetBuilder";
import {
  ExtendedObjectType,
  IExtendedFabricObject,
  isLogoEventType,
  ITemplate,
  LogoEventType,
} from "shared/types/designStudio";
import { IDisclosure } from "shared/types/legalLingo";

import { INewOrder } from "shared/types/newOrders";
import { OfferType } from "shared/types/shared";
import * as textHelpers from "utils/fabric/helpers.text";
import CardToggle, { IBox } from "./templatePreview/CardToggle";

import "./TemplatePreviewMemo.scss";
import { context } from "shared/components/contextAPI/shared/RenderTemplate";

import {
  prepareAllVideosInCanvas,
  returnCanvasContainsVideo,
} from "utils/fabric/helpers.media";
import { getReplacedFabricImage } from "./contextAPI/shared/RenderTemplate.utils";
import { TDataCache } from "shared/hooks/useFetchRenderTemplate";
import {
  FC,
  memo,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

interface ITemplatePreviewProps {
  renderTemplateCache?: TDataCache;
  template: ITemplate | null;
  offer?: {
    offerData: OfferData;
    offerTypes: OfferType[];
  };
  offers?: IAssetBuild["offers"];
  order: INewOrder | null;
  disableExport?: boolean;

  highlights?: {
    [key in ExtendedObjectType]?: boolean;
  };

  displayLabelBox: boolean;

  objectVisibilities?: IAssetBuildInstance["visibilities"];
  logoSubstitutions?: IAssetBuildInstance["logoSubstitutions"];
  editable?: boolean;
  displayCanvasObjects?: boolean;
  lifestyleImageUrl?: IAssetBuilderState["lifestyleImageUrl"];
  lifestyleFabricImageJson?: IAssetBuildInstance["lifestyleFabricImageJson"];
  isLifestyleImageEditable?: boolean;

  vin?: string;

  supportVideos?: boolean;
  playVideos?: boolean;
  exportFilename?: string;
  allObjectsShown?: {
    [key in ExtendedObjectType | LogoEventType]?: {
      toggle: boolean;
      action: "show" | "hide";
    };
  };
  displayImageUrl?: boolean;
  index?: number;
  imageUrlList?: string[];
  triggerAssetInstance?: boolean;
  selectedOffers?: ISelectedOffer[];
  disclosure?: {
    allSizes?: IDisclosure;
    currentSize?: IDisclosure;
    selectedAsset?: IDisclosure;
  };
  feedTabs?: FeedTab[];
}
interface ITemplatePreviewHandlers {
  // This function is coming from Build.tsx
  onToggleVisibility?: (visibilities: TVisibility[]) => void;
  onChangeLogoSubstitution?: (logoSubstitution: TLogoSubstitution) => void;
  onLifestyleImageChange?: (img: fabric.Image) => void;
  assetInstanceCounter?: () => void;
  onToggleAllVisibilities?: (visibilities: TVisibility[]) => void;
  setImageUrlList?: (imageUrlList: string[]) => void;
  gatherMissingOfferTypes?: (offerTypes: string[]) => void;
  onRenderComplete?: (templateId?: string) => void;
  selectedInstances?: SelectedInstance[];
}

type ITemplatePreview = ITemplatePreviewProps & ITemplatePreviewHandlers;
type SomethingWrongType = {
  trigger: boolean;
  errorType: "emptyVin" | "emptyOffer" | "";
};

const TemplatePreview: FC<ITemplatePreview> = props => {
  const renderTemplateContext = useRenderTemplate(); // TODO: this need to be removed after export feature refactor
  const renderTemplateContextV2 = useContext(context);
  const [canvasRef, setCanvasRef, canvasRect] = useClientRect();
  const [, setCanvasWrapperRef, canvasWrapperRect] = useClientRect();
  const [somethingWentWrong, setSomethingWentWrong] =
    useState<SomethingWrongType>({
      trigger: false,
      errorType: "",
    });

  const [missingOfferType, setMissingOfferType] = useState<string>("");

  const [canvas, setCanvas] = useState<fabric.Canvas>();

  const { template, offer } = props;

  useEffect(() => {
    if (!template || !canvasWrapperRect || !canvasRect || !canvasRef) {
      return;
    }

    const { offerData, offerTypes } = offer || {};

    if (!offerData || !offerTypes) {
      setSomethingWentWrong({
        trigger: true,
        errorType: "emptyVin",
      });

      return;
    }

    const missingOfferTypes: string[] = [];

    if (!props.triggerAssetInstance) {
      offerTypes.forEach(offerType => {
        if (!props.offers || offerType.includes("Purchase")) return;
        props.offers.forEach(offer => {
          if (
            offerData.vin === offer.offerData.vin &&
            !offer.savedOfferTypes.includes(offerType)
          ) {
            missingOfferTypes.push(offerType);

            setMissingOfferType(offerType);
            setSomethingWentWrong({
              trigger: true,
              errorType: "emptyOffer",
            });

            return;
          }
        });
      });

      if (props.gatherMissingOfferTypes)
        props.gatherMissingOfferTypes(missingOfferTypes);
    } else {
      setMissingOfferType("");
      setSomethingWentWrong({
        trigger: false,
        errorType: "",
      });
    }

    const initCanvas = new fabric.Canvas(
      (canvasRef as MutableRefObject<HTMLCanvasElement>).current,
    );

    initCanvas.setDimensions({
      width: template.artboard.width,
      height: template.artboard.height,
    });

    setCanvas(initCanvas);

    if (props.exportFilename) {
      renderTemplateContext?.addFabricCanvasToIndex(
        initCanvas,
        props.exportFilename,
      );

      if (canvasRef.current) {
        renderTemplateContext?.addCanvasToIndex(
          canvasRef.current as HTMLCanvasElement,
          props.exportFilename,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    canvasRef,
    canvasWrapperRect,
    renderTemplateContext,
    canvasRect,
    props.triggerAssetInstance,
  ]);

  const { displayCanvasObjects, logoSubstitutions } = props;
  const [toggleBoxes, setToggleBoxes] = useState<IBox[]>();
  const [visibilities, setVisibilities] = useState<TVisibility[] | null>(null);

  useEffect(() => {
    if (!props.template || !canvas || somethingWentWrong.trigger) return;

    renderTemplateContextV2
      ?.reload({
        renderTemplateCache: props.renderTemplateCache,
        template: props.template,
        offer: offer,
        order: props.order || undefined,
        selectedOffers: props.selectedOffers,
        canvasRect: canvasRect || undefined,
        canvasWrapperRect: canvasWrapperRect || undefined,
        visibilities: props.objectVisibilities,
        disclosure: props.disclosure,
      })
      .then(data => {
        if (!data) {
          message.error("There was error. Unable to render the template.");
          return;
        }

        const shouldResetToggleBoxes = !toggleBoxes || toggleBoxes.length === 0;
        if (shouldResetToggleBoxes) {
          setToggleBoxes(toggleBoxes || data.toggleBoxes);
        }

        const shouldResetVisibilities = visibilities === null;

        if (shouldResetVisibilities) {
          setVisibilities(
            (toggleBoxes || data.toggleBoxes)?.map(box => {
              let isVisible = true;
              const visibility = props.objectVisibilities?.find(
                vis => vis.id === box.id,
              );
              if (visibility) {
                isVisible = visibility.isVisible;
              }

              return {
                type: box.objectType,
                id: box.id,
                order: box.order,
                subtype: box.subtype,
                isVisible,
              };
            }) || [],
          );
        }
        renderTemplateContextV2?.renderTemplateOn({
          canvas,
          data,
          visibilities:
            displayCanvasObjects !== undefined && !displayCanvasObjects
              ? props.objectVisibilities
              : undefined,
          logoSubstitutions,
          disclosure: props.disclosure,
          feedTabs: props.feedTabs,
          complete: async () => {
            /*
      The canvas takes a little bit of time to render and complete even after the callback.
      Having many (10+) canvases seems to slow down the rendering (probably depends on how fast your machine is too locally).
      Giving the process 3-5 seconds gives it enough time to render.
      */
            const { template } = props;
            if (!template) {
              return;
            }

            // TODO: This part might need more investigation...
            const addFabricCavnasToVideoIndex = () => {
              const containsVideo = returnCanvasContainsVideo(canvas);
              if (!props.exportFilename || !containsVideo) {
                return;
              }
              renderTemplateContext?.addFabricCanvasToIndex(
                canvas,
                props.exportFilename,
                containsVideo,
              );
            };

            if (props.template?.type !== "lifestyle") {
              addFabricCavnasToVideoIndex();
            }

            canvas.getObjects().forEach(obj => {
              textHelpers.dynamicallyResizeDisclosureText(
                obj,
                template!,
                canvas.getHeight(),
              );

              const customType = (obj as IExtendedFabricObject).customType;

              if (customType === "disclosure") {
                canvas.requestRenderAll();
              }

              if (customType === "selected_video" && !props.supportVideos) {
                canvas.remove(obj);
              }
            });

            // Refer to https://theconstellationagency.atlassian.net/browse/AV2-2853
            // below is temporarily commented for testing
            // applyResizeFilterToCanvasImages(canvas);
            if (props.supportVideos) {
              prepareAllVideosInCanvas({
                canvas,
                playVideo: props.playVideos,
              });
            }

            const lifestyleImages = canvas
              .getObjects()
              .filter(obj => isLifestyleImage(obj as unknown as ICanvasObject));
            if (lifestyleImages.length > 1) {
              message.error(
                `${lifestyleImages.length} lifestyle images found. A template can only have one lifestyle image.`,
              );
            }

            const lifestyleImage = lifestyleImages[0];

            // add "modified" event handler on the lifestyle image object
            lifestyleImage?.on("modified", e => {
              if (!e.target) return;

              props.onLifestyleImageChange?.(e.target as fabric.Image);
            });

            // By default, lifestyle image object had evented and selectable attr set to true.
            // But we need to disable those if switch is off.
            // Handle this here instead of adding new attr to IBox type.
            if (!props.highlights?.lifestyle) {
              lifestyleImage?.set({
                evented: false,
                selectable: false,
              });
            }

            props.onRenderComplete?.(props.template?.id);
          },
          htmlElement: canvasRef.current || undefined,
          lifestyleImageUrl: props.lifestyleImageUrl,
          lifestyleImageJson: props.lifestyleFabricImageJson,
          order: props.order || undefined,
        });
      });

    // eslint-disable-next-line
  }, [
    canvas,
    template,
    offer,
    props.playVideos,
    props.renderTemplateCache,
    props.disclosure,
    props.lifestyleImageUrl,
    props.logoSubstitutions,
  ]);

  const prevAllObjectsShownRef = useRef<typeof props.allObjectsShown>();

  useEffect(() => {
    const noChanges = isEqual(
      prevAllObjectsShownRef.current,
      props.allObjectsShown,
    );
    if (noChanges) return;

    let key: ExtendedObjectType | LogoEventType;
    let action: "show" | "hide" | undefined;
    if (!prevAllObjectsShownRef.current) {
      prevAllObjectsShownRef.current = props.allObjectsShown;

      const keys = Object.keys(props.allObjectsShown || {});

      if (keys.length === 0 || !props.allObjectsShown) {
        message.error("There was an error.");

        return;
      }

      key = keys[0] as ExtendedObjectType | LogoEventType;
      // props.allObjectsShown[keys[0] as ExtendedObjectType & LogoEventType];
      action = props.allObjectsShown[key]?.action;
      return;
    } else {
      // Find the ones that object.toggle changed first
      const changed = (
        Object.keys(props.allObjectsShown || {}) as (
          | ExtendedObjectType
          | LogoEventType
        )[]
      ).reduce<
        Record<
          keyof (ExtendedObjectType & LogoEventType),
          {
            toggle: boolean;
            action: "show" | "hide";
          }
        >
      >((acc, key) => {
        const previous = prevAllObjectsShownRef.current![key];
        const current = props.allObjectsShown![key];

        if (previous?.toggle === current?.toggle) return acc;

        acc[key] = current!;

        return acc;
      }, {});

      // Check if more than one type has been changed at once... This is unlikely but just in case.
      if (Object.keys(changed).length === 0) {
        message.error("An error occurred.");

        return;
      }

      // Take object.action and depending on the value, show/hide objects.
      key = Object.keys(changed)[0] as ExtendedObjectType & LogoEventType;
      action = changed[key].action;
    }

    let updatedVisibilities =
      visibilities?.length === 0
        ? toggleBoxes?.map(box => ({
            type: box.objectType,
            id: box.id,
            order: box.order,
            isVisible: box.isVisible,
          }))
        : [...(visibilities || [])];
    const isSubtype = isLogoEventType(key);

    updatedVisibilities = getVisibilitiesForAllObjects(
      updatedVisibilities || [],
      action === "show",
      isSubtype ? "logo" : key,
      isSubtype ? key : undefined,
    );

    setVisibilities(updatedVisibilities);

    props.onToggleAllVisibilities?.(updatedVisibilities);

    // set prevAllObjectsShowRef.current with the current changes
    prevAllObjectsShownRef.current = props.allObjectsShown;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allObjectsShown]);

  useEffect(() => {
    // The disclosure tobble box, its height is calculated after the all data is filled in renderTemplateOn
    // We need to update the height of the disclosure box after its text has been filled.
    setToggleBoxes(renderTemplateContextV2?.data?.toggleBoxes);
  }, [renderTemplateContextV2?.data?.toggleBoxes]);

  const [logoImageUrls, setLogoImageUrls] = useState<string[]>([]);
  const [openPopover, setOpenPopover] = useState<IBox | null>();

  const { displayLabelBox } = props;

  const stampOrderTracker = toggleBoxes
    ?.filter(box => box.objectType === "stamp")
    ?.map((box, index) => ({ ...box, order: index }));

  return (
    <Spin
      spinning={!!renderTemplateContextV2?.loading}
      tip={renderTemplateContextV2?.loading?.message}
      size="large"
    >
      {props.template && !somethingWentWrong.trigger && (
        <div
          ref={setCanvasWrapperRef as (node: HTMLDivElement) => void}
          className="canvas-wrapper"
        >
          <canvas
            ref={setCanvasRef as (node: HTMLCanvasElement) => void}
            id="canvas"
            width={props.template.artboard.width || 0}
            height={props.template.artboard.height || 0}
          />

          {(toggleBoxes || []).map((box, index) => {
            const isEditable =
              props.editable !== undefined && !props.editable
                ? false
                : (box.objectType === "stamp" && props.highlights?.stamp) ||
                  (box.objectType === "logo" && props.highlights?.logo) ||
                  (box.objectType === "lifestyle" &&
                    props.highlights?.lifestyle) ||
                  (box.objectType === "disclosure" &&
                    props.highlights?.disclosure);

            const visibility = visibilities?.find(
              vis => vis.id === box.id && vis.order === box.order,
            );

            const stampOrder =
              box.objectType === "stamp"
                ? (
                    stampOrderTracker?.find(
                      boxTracker => boxTracker.id === box.id,
                    ) || { order: -1 }
                  ).order
                : -1;

            const shouldHighlightCard =
              (props.highlights?.stamp ||
                props.highlights?.logo ||
                props.highlights?.disclosure) &&
              isEditable;

            return (
              <CardToggle
                key={`toggle-box-${box.id}-${index}`}
                box={{
                  ...box,
                  isVisible:
                    visibility === undefined ? true : visibility.isVisible,
                }}
                logoImageUrls={logoImageUrls}
                openPopover={openPopover || null}
                order={index}
                stampOrder={stampOrder}
                editable={isEditable}
                highlight={shouldHighlightCard}
                display={props.displayCanvasObjects}
                onToggle={(toggledBox: IBox) => {
                  const isVisible = !toggledBox.isVisible;

                  const updatedVisibilities =
                    visibilities?.map(vis => {
                      if (vis.id === toggledBox.id) {
                        return {
                          ...vis,
                          isVisible,
                        };
                      }

                      return vis;
                    }) || [];
                  setVisibilities(updatedVisibilities);
                  props.onToggleVisibility?.(updatedVisibilities);
                }}
                onOpenPopover={(box: IBox) => {
                  const matchingObj = canvas
                    ?.getObjects()
                    .find(obj => obj.name === box.id);

                  if (!matchingObj) {
                    return;
                  }

                  const { oem, dealer } = renderTemplateContextV2?.data || {};

                  const logoUrlsArray = assetHelpers.returnLogoOptions(
                    matchingObj as IExtendedFabricObject,
                    box,
                    oem,
                    dealer,
                  );

                  setLogoImageUrls(logoUrlsArray);

                  if (openPopover && openPopover.id == box.id) {
                    setOpenPopover(null);
                  } else {
                    setOpenPopover(box);
                  }
                }}
                onImageChange={async (toggledBox: IBox, imgUrl: string) => {
                  if (!canvas) return; // canvas must be initialized and rendered template prior to this call.

                  // 1. replace logo image on canvas
                  const fabricObject = canvas
                    .getObjects()
                    .find(
                      obj =>
                        isLogo(obj as unknown as ICanvasObject) &&
                        (obj as unknown as IExtendedFabricObject).name ===
                          toggledBox.id,
                    );

                  if (!fabricObject) {
                    notification.error({
                      message: "A system error!",
                      description:
                        "There was a system error. Unable to change logo image.",
                    });

                    return;
                  }

                  const boundingRects =
                    !!canvasWrapperRect && !!canvasRect
                      ? {
                          wrapperRect: canvasWrapperRect,
                          canvasRect: canvasRect,
                        }
                      : undefined;
                  const replacedImage = await getReplacedFabricImage(
                    fabricObject as fabric.Image,
                    `${imgUrl}?timestamp=${Date.now()}`,

                    // numbers are subtracted because the position is based on the toggleBox and the box had adjustment when it was created
                    {
                      left: toggledBox.x,
                      top: toggledBox.y,
                      dimension: {
                        width: toggledBox.width,
                        height: toggledBox.height,
                      },
                    },
                    boundingRects,
                  );

                  canvas.remove(fabricObject);
                  canvas.add(replacedImage);

                  // 2. update data in the parent component
                  const existingBox = toggleBoxes?.find(
                    box => box.id === toggledBox.id,
                  );
                  if (!existingBox) return;

                  const newLogoSub: TLogoSubstitution = {
                    type: toggledBox.objectType,
                    id: toggledBox.id,
                    order: toggledBox.order,
                    currentImageUrl: imgUrl,
                  };

                  props.onChangeLogoSubstitution?.(newLogoSub);

                  setOpenPopover(null);
                }}
                closePopover={() => {
                  setOpenPopover(null);
                }}
              />
            );
          })}

          {displayLabelBox && renderTemplateContextV2?.data?.cardLabels}
        </div>
      )}
      {(!props.template || somethingWentWrong.trigger) && (
        <Result
          status="warning"
          title={
            <div>
              <p>There was a problem loading this instance</p>
              {somethingWentWrong.errorType === "emptyVin" ? (
                <span>{`It is possible that the selected offer was removed. ${
                  props.vin ? `VIN: ${props.vin}` : ""
                }`}</span>
              ) : somethingWentWrong.errorType === "emptyOffer" ? (
                <span>{`It is possible that the ${missingOfferType.toUpperCase()} offer was removed.`}</span>
              ) : null}
            </div>
          }
        />
      )}
    </Spin>
  );
};

const getVisibilitiesForAllObjects = (
  visibilities: TVisibility[],
  isVisible: boolean,
  type: ExtendedObjectType,
  subtype?: LogoEventType,
) => {
  return visibilities.map(vis => {
    if (vis.type === "logo") {
      if (!!subtype) {
        return vis.subtype === subtype
          ? {
              ...vis,
              isVisible,
            }
          : vis;
      } else {
        return vis.type === type
          ? {
              ...vis,
              isVisible,
            }
          : vis;
      }
    } else {
      return vis.type === type
        ? {
            ...vis,
            isVisible,
          }
        : vis;
    }
  });
};

const areEqual = (prevProps: ITemplatePreview, nextProps: ITemplatePreview) => {
  return isEqual(
    {
      selectedInstances: prevProps.selectedInstances,
      template: prevProps.template,
      offer: prevProps.offer,
      highlights: prevProps.highlights,
      lifestyleImageUrl: prevProps.lifestyleImageUrl,
      playVideos: prevProps.playVideos,
      allObjectsShown: prevProps.allObjectsShown,
      logoSubstitutions: prevProps.logoSubstitutions,
    },
    {
      selectedInstances: nextProps.selectedInstances,
      template: nextProps.template,
      offer: nextProps.offer,
      highlights: nextProps.highlights,
      lifestyleImageUrl: nextProps.lifestyleImageUrl,
      playVideos: nextProps.playVideos,
      allObjectsShown: nextProps.allObjectsShown,
      logoSubstitutions: nextProps.logoSubstitutions,
    },
  );
};

export default memo(TemplatePreview, areEqual);
