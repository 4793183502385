import { Tree, TreeProps } from "antd";
import { DataNode, EventDataNode } from "rc-tree/lib/interface";
import { useEffect, useState } from "react";
import { useGetOnlyFoldersMutation } from "redux/media/media.api";
import { Loading } from "../Loading";
import { formatFolders, updateTreeData } from "./utils";

export const SelectFolder = ({ onSelect }: TreeProps) => {
  const [treeData, setTreeData] = useState<DataNode[]>();
  const [getFolders] = useGetOnlyFoldersMutation();

  const onLoad = async (treeNode: EventDataNode) => {
    const folderId = +treeNode.key;
    const folders = await getFolders(folderId).unwrap();

    setTreeData(prev => {
      const children = formatFolders(folders);

      return updateTreeData(folderId, prev!, children);
    });
  };

  useEffect(() => {
    if (!treeData) {
      getFolders(0)
        .unwrap()
        .then(folders => {
          setTreeData(formatFolders(folders));
        });
    }
  }, [getFolders, treeData]);

  return !!treeData ? (
    <Tree.DirectoryTree
      expandAction={false}
      onSelect={onSelect}
      treeData={treeData}
      loadData={onLoad}
    />
  ) : (
    <Loading />
  );
};
