import {
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { FetchBaseQueryArgs } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { cloneDeep, flow } from "lodash";
import { CAMConfig } from "shared/components/media";
import { getAccessToken } from "shared/components/media/auth";

const { extraFields, defaultFields, keywordsUrl, wdBaseUrl } = CAMConfig;

export const withSearchDefaults = (
  payload: WDSearchPayload,
): WDSearchPayload => {
  const { fields = [] } = payload.filters ?? {};

  return {
    ...payload,
    filters: {
      fields: transformFields(fields),
    },
    sort_field: "filename",
    sort_direction: "asc",
  };
};

const appendFields = (arr: WDSearchField[]) => {
  return (fields: WDSearchField[]) => {
    return fields.reduce((acc, val) => {
      const index = acc.findIndex(field => field.field_name === val.field_name);

      // If included, replace with default field.
      if (index >= 0) {
        return Object.assign([], acc, { [index]: val });
      }

      return acc.concat(val);
    }, arr);
  };
};

const mergeFields = (arr: WDSearchField[]) => {
  return (fields: WDSearchField[]) => {
    return fields.reduce((acc, val) => {
      const index = acc.findIndex(field => field.field_name === val.field_name);

      // If included, merge with extra field.
      if (index >= 0) {
        const field = acc[index];
        return Object.assign([], acc, {
          [index]: {
            ...field,
            value: [field.value].concat(val.value),
          },
        });
      }

      return acc.concat(val);
    }, arr);
  };
};

const transformFields = flow([
  appendFields(defaultFields),
  mergeFields(extraFields),
]);

export const withExtraFields = (payload: Record<string, any>) => {
  const initialValue = Object.assign(
    cloneDeep(payload), //
    { replacekeywords: "keyword" in payload },
  );

  return extraFields.reduce((acc, { field_name, value }) => {
    return {
      ...acc,
      [field_name]: Array.isArray(acc[field_name])
        ? acc[field_name].concat(value)
        : value,
    };
  }, initialValue);
};

export const withoutExtraFields = (res: WDMetaDataXMPRes) => {
  return Object.fromEntries(
    Object.entries(cloneDeep(res)).map(
      ([key, val]: [string, WDMetaDataXMP]) => {
        const newVal = extraFields.reduce((acc, { field_name, value }) => {
          if (field_name in acc) {
            acc[field_name] = acc[field_name]
              .split(",")
              // Supports only string values for now.
              .filter(v => v !== value)
              .join(",");
          }

          return acc;
        }, val);

        return [key, newVal];
      },
    ),
  );
};

export const sanitizeExtension = (data: Partial<MGUpdateAssetPayload>) => {
  if (data.name?.length) {
    return {
      ...data,
      name: data.name.replace(/\.[^/.]+$/, ""),
    };
  }
  return data;
};

export const handleMissingThumbnails = (assets: WDAsset[]) => {
  return assets.map(asset => {
    if (!asset.thumbnailurls?.length) {
      return {
        ...asset,
        thumbnailurls: [
          {
            size: 0,
            url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgNTAgNTAiIHdpZHRoPSI1MHB4IiBoZWlnaHQ9IjUwcHgiPjxwYXRoIGQ9Ik0gNyAyIEwgNyA0OCBMIDQzIDQ4IEwgNDMgMTQuNTkzNzUgTCA0Mi43MTg3NSAxNC4yODEyNSBMIDMwLjcxODc1IDIuMjgxMjUgTCAzMC40MDYyNSAyIFogTSA5IDQgTCAyOSA0IEwgMjkgMTYgTCA0MSAxNiBMIDQxIDQ2IEwgOSA0NiBaIE0gMzEgNS40Mzc1IEwgMzkuNTYyNSAxNCBMIDMxIDE0IFoiLz48L3N2Zz4=",
          },
        ],
      };
    }

    return asset;
  });
};

export const handleResponseWithErrors = <T = null>(
  responseArray: QueryReturnValue<
    unknown,
    FetchBaseQueryError,
    FetchBaseQueryMeta
  >[],
  successValue: T,
) => {
  const errors = responseArray.filter(res => res.error).map(res => res.error);

  if (errors.length) {
    return {
      error: {
        status: 400,
        data: errors.map(mapApiError),
      },
    };
  }

  return { data: successValue };
};

export const mapKeywordToOptionValue = (kw: WDKeyword | ExternalKeyword) => {
  if ("value" in kw) {
    return {
      value: kw.value,
      label: kw.value,
    };
  } else {
    return {
      value: kw.title,
      label: kw.title,
    };
  }
};

const mapApiError = (error?: any): MGApiError => {
  return {
    status: error?.status || 400,
    message: error?.data?.message || `Something went wrong`,
  };
};

const conditions: Array<(endpoint: string) => boolean> = [
  endpoint => {
    return endpoint === "getKeywords" && !keywordsUrl.startsWith(wdBaseUrl);
  },
];

export const prepareHeaders: FetchBaseQueryArgs["prepareHeaders"] = async (
  headers,
  { endpoint },
) => {
  if (!conditions.some(cond => cond(endpoint))) {
    headers.set("Authorization", `Bearer ${await getAccessToken()}`);
  }

  return headers;
};
