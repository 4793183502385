import { Space, Table, Typography, Spin } from "antd";
import styles from "./pmaxReviewStep/PmaxReviewStep.module.scss";
import usePmaxReviewDrawerColumns from "./pmaxReviewStep/usePmaxReviewStepColumns";
import { useState } from "react";
import { IAccountRecord } from "shared/types/accountManagement";
import StoreManagementLabelDrawer from "./storeManagementLabelDrawer/StoreManagementLabelDrawer";
import { AlertSection } from "./pmaxReviewStep/PmaxReviewStepAlertSection";
import { PmaxAdReviewItem } from "../shared/types";

type PmaxReviewDrawerProps = {
  reviewItems: PmaxAdReviewItem[];
  dealer: IAccountRecord | undefined;
};

export const PmaxReviewStep = ({
  reviewItems,
  dealer,
}: PmaxReviewDrawerProps) => {
  const columns = usePmaxReviewDrawerColumns();
  const [isFixingErrors, setIsFixingErrors] = useState(false);
  const itemsWithErrors = reviewItems.filter(
    item => item.destination.hasUrlError,
  );

  const labelsToFix = itemsWithErrors
    .filter(item => item.destination.name && !item.destination.url)
    .map(item => ({
      url: item.destination.url,
      name: item.destination.name || "",
    }));

  if (!dealer) return <Spin />;

  return (
    <>
      <Space className={styles.drawerContent} direction="vertical">
        <Space>
          <Typography.Title level={5}>Review Ad Load</Typography.Title>
        </Space>
        <AlertSection
          onDefineUrls={() => setIsFixingErrors(true)}
          itemsWithErrors={itemsWithErrors}
        />
        <Table
          className="ad-table-container"
          pagination={false}
          columns={columns}
          dataSource={reviewItems}
          size="small"
        />
      </Space>
      <StoreManagementLabelDrawer
        labelsToFix={labelsToFix}
        isOpen={isFixingErrors}
        dealer={dealer}
        onClose={() => setIsFixingErrors(false)}
      />
    </>
  );
};
