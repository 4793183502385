import { Drawer } from "antd";
import { useMediaActions } from "redux/media/media.slice";
import { AssetDetail } from "./AssetDetail";
import { AssetMetaForm } from "./AssetMetaForm";
import { useAppSelector } from "./redux";

import styles from "./AssetMetadata.module.scss";

export const AssetMetadata = () => {
  const { setAssetsToEdit } = useMediaActions();
  const assets = useAppSelector(({ media }) => media.assetsToEdit);
  const drawerId = "asset-metadata";

  const handleClose = () => {
    setAssetsToEdit([]);
  };

  return (
    <Drawer
      title="View Assets"
      destroyOnClose
      maskClosable
      width={"65vw"}
      onClose={handleClose}
      visible={!!assets.length}
      bodyStyle={{ padding: "0" }}
      data-id={drawerId}
      footer
    >
      {assets.length && (
        <div className={styles.body}>
          <AssetDetail assets={assets} />
          <AssetMetaForm assets={assets} drawerId={drawerId} />
        </div>
      )}
    </Drawer>
  );
};
