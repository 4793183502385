import { Progress, notification, Typography } from "antd";
import { UploadResults } from "../../shared/types";
import { FileDoneOutlined } from "@ant-design/icons";
import { useState } from "react";
import { getNumberByStatus } from "../pmaxLoadStep/utils";

type ProgressNotificationProps = {
  uploadResults: UploadResults;
  processedNumber: number;
  totalNumber: number;
};

const getStatus = (uploadResults: UploadResults) => {
  const numberOfFailed = getNumberByStatus(uploadResults, "Failed");
  const isCompleted = uploadResults.progress === 100;
  if (isCompleted && numberOfFailed === 0) return "success";
  if (isCompleted && numberOfFailed > 0) return "exception";
  return "active";
};

const ProgressNotification = ({
  uploadResults,
  processedNumber,
  totalNumber,
}: ProgressNotificationProps) => {
  return (
    <div>
      <Typography.Text>
        Creating Asset Groups: {processedNumber}/{totalNumber}
      </Typography.Text>
      <Progress
        percent={uploadResults.progress}
        size="small"
        status={getStatus(uploadResults)}
      />
    </div>
  );
};

export const useProgressNotification = (
  uploadResults: UploadResults,
  processedNumber: number,
  totalNumber: number,
) => {
  const [shouldBeClosed, setShouldBeClosed] = useState(false);

  if (!shouldBeClosed) {
    notification.open({
      key: "pmaxProgressNotification",
      message: "Loading Asset Groups",
      icon: <FileDoneOutlined style={{ color: "#1890FF" }} />,
      duration: 0,
      bottom: 50,
      placement: "bottomRight",
      description: (
        <ProgressNotification
          uploadResults={uploadResults}
          processedNumber={processedNumber}
          totalNumber={totalNumber}
        />
      ),
      onClose: () => setShouldBeClosed(true),
    });
  }

  return {
    closeNotification: () => notification.close("pmaxProgressNotification"),
  };
};
