import { AccessOptions } from "shared/constants/userPermissions";
import {
  INewOrder,
  INewOrderTableRecord,
  OrderStatus,
  OrderTableButtonTypes,
} from "shared/types/newOrders";
import { isEnvVarEquals, isFeatureEnabled, isPharma } from "utils/helpers";
import { convertDate } from "../utils";
import { OrdersTableAction } from "shared/types/assetBuilder";

type OrderTableColumnData = {
  key: keyof INewOrderTableRecord;
  title: string;
  width: string;
};
type ColumnTuple = [keyof INewOrderTableRecord, string, string];
const convertToColumnData = ([key, title, width]: ColumnTuple) => ({
  key,
  title,
  width,
});

export const getColumnData = (
  enableWorkfront: boolean,
): OrderTableColumnData[] => {
  const isInternalEnv = isEnvVarEquals("CLIENT", "internal");
  const tuples: ColumnTuple[] = isPharma
    ? [
        ["createdAt", "Created", "8%"],
        ["expiresAt", "Planned Launch Date", "14%"],
        ["creator_name", "Creator", "10%"],
        [
          "wfProjectName",
          enableWorkfront ? "WF Project Name" : "Project Name",
          "10%",
        ],
        ["status", "Status", "16%"],
        ["dealer_name", "Brand", "10%"],
        ["tags", "Tags", "10%"],
      ]
    : [
        ["createdAt", "Created", "9%"],
        ["expiresAt", "Expires", "9%"],
        ["creator_name", "Creator", "9%"],
        [
          "wfProjectName",
          enableWorkfront ? "WF Project Name" : "Project Name",
          "9%",
        ],
        ["wfProjectNumber", "WF Project Number", "9%"],
        ["pushed_WF", "Pushed WF", "9%"],
        ["status", "Status", "18%"],
        ["dealer_name", "Account DBA", "9%"],
        ["dealer_code", "Account ID", "9%"],
        ["dealer_oem", "Brands", "9%"],
        ["num_assets", "Assets", "9%"],
        ["selectedInventory", "Inventory", "9%"],
        ["totalSelectedOffers", "Offers", "9%"],
        ["totalUsedStamps", "Stamp Count", "9%"],
        ["integrationCount", "Integrations", "9%"],
        ["selectedOrderOffers", "Offer Types", "9%"],
      ];
  return tuples
    .filter(
      ([key]) =>
        (!enableWorkfront ? key !== "wfProjectNumber" : true) &&
        (isInternalEnv ? key !== "pushed_WF" : true),
    )
    .map<OrderTableColumnData>(convertToColumnData);
};

export const getSorter = (
  a: INewOrderTableRecord,
  b: INewOrderTableRecord,
  key: keyof INewOrderTableRecord,
) => {
  const getDefaultValue = () => {
    // when the value is undefined, return -Infinity for the numeric values, otherwise ""
    // so the undefined values will be sorted properly
    if (
      [
        "num_assets",
        "selectedInventory",
        "totalSelectedOffers",
        "totalUsedStamps",
        "integrationCount",
      ].includes(key)
    ) {
      return -Infinity;
    }
    return "";
  };
  const isTimestamp = key === "createdAt" || key === "expiresAt";
  const val1 = isTimestamp
    ? new Date(a[key]).getTime()
    : a[key] ?? getDefaultValue();
  const val2 = isTimestamp
    ? new Date(b[key]).getTime()
    : b[key] ?? getDefaultValue();

  if (val1 < val2) {
    return -1;
  } else if (val1 > val2) {
    return 1;
  } else {
    return 0;
  }
};

export const applyToolTipTitle = (
  title: OrderTableButtonTypes,
  isAdmin?: boolean,
) => {
  const disabledMsg =
    "This feature is disabled for this account. Contact your team lead to request access.";

  if (!isFeatureEnabled("ENABLE_USER_PERMISSIONS")) return title;
  return isAdmin ? title : disabledMsg;
};

export const toOrderTableRecord = (order: INewOrder) => {
  return {
    ...order,
    key: order.id,
    createdText: convertDate(order.createdAt),
    expiresAt: convertDate(order.expiresAt),
    actions: "ok",
  } as INewOrderTableRecord;
};

const checkOrderContainsString = (
  searchText: string | undefined | null,
  order: INewOrder,
) => {
  if (!searchText?.trim()) return true;

  const searchTerms = [
    order.creator_name,
    order.wfProjectName,
    order.wfProjectNumber,
    order.dealer_name,
    order.dealer_oem,
    order.dealer_code,
    order.selectedOrderOffers,
  ]
    .filter(term => !!term)
    .map(term => term?.toLowerCase());

  return searchTerms.some(term =>
    term?.includes(searchText.trim().toLowerCase()),
  );
};

type FilterOrderArgs = {
  brandsToFilter?: (string | null)[] | null | undefined;
  creatorsToFilter?: (string | null)[] | null | undefined;
  searchByToFilter?: string | null;
};
export const filterOrder = (args: FilterOrderArgs) => (order: INewOrder) => {
  const { brandsToFilter, creatorsToFilter, searchByToFilter } = args;

  const matchingBrand =
    (brandsToFilter?.length ?? 0) > 0
      ? brandsToFilter!.some(
          brand => brand && order.dealer_oem?.includes(brand),
        ) // dealer_oem is a comma separated string
      : true;

  const matchingCreator =
    (creatorsToFilter?.length ?? 0) > 0
      ? creatorsToFilter?.includes(order.creator_name)
      : true;

  const matchingSearchBy = checkOrderContainsString(searchByToFilter, order);

  return matchingBrand && matchingCreator && matchingSearchBy;
};

export const getActionColumns = (
  orderStatus: OrderStatus,
  isLauncherEnabled: boolean,
): OrdersTableAction[] =>
  orderStatus === "archived"
    ? ["restore"]
    : [
        "edit",
        "modify",
        ...(isLauncherEnabled ? (["launcher"] as OrdersTableAction[]) : []),
        "duplicate",
        "archive",
      ];
