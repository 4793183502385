import { useQuery } from "react-query";
import { fetchCanvasJson } from "shared/components/contextAPI/shared/RenderTemplate";
import { getCanvasJsonQueryKey } from "shared/hooks/useFetchCanvasJsonObjects";

export const useFetchCanvasJson = (url: string | null) => {
  return useQuery(getCanvasJsonQueryKey(url!), () => fetchCanvasJson(url!), {
    enabled: !!url,
    staleTime: 1000 * 60 * 5,
  });
};
