import { first, last } from "lodash";
import { appendUrlPrefix } from "./appendUrlPrefix";
import { fontThumbSrc } from "./images/fontThumbSrc";
import { isFontType } from "./utils.filetype";

type PreviewThumb = {
  url: string;
  isVideo: boolean;
  size?: number;
};

export const getPreviewThumbnail = ({
  videourls,
  filetype,
  thumbnailurls = [],
}: WDAsset): PreviewThumb => {
  const videoThumbnail = first(videourls);

  if (videoThumbnail) {
    return {
      url: appendUrlPrefix(videoThumbnail.url),
      isVideo: true,
    };
  }

  if (isFontType(filetype)) {
    return {
      url: fontThumbSrc,
      isVideo: false,
    };
  }

  const mediumSize = thumbnailurls.find(thumb => thumb.url?.includes("md_"));
  const previewThumbnail = mediumSize ?? last(thumbnailurls)!;

  return {
    ...previewThumbnail,
    url: appendUrlPrefix(previewThumbnail.url),
    isVideo: false,
  };
};
