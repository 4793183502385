export const basicColumns = [
  "Name",
  "Source",
  "Ad Format",
  "Status",
  "Campaign Planners",
  "Created",
  "Last Edit",
  "Last Edit By",
  "Tags",
];

export const sharedColumns = [
  "Name",
  "Source",
  "Ad Format",
  "Status",
  "Campaign Planners",
  "Created",
  "Last Edit",
  "Last Edit By",
  "Tags",
  "Audience",
  "UTM",
  "Ad URL",
  "Destination URL",
  "Primary Text",
  "Headline",
  "Display URL",
  "Description",
  "CTA Button",
  "Start Date",
  "End Date",
];

export const retailColumns = [
  "Year",
  "Month",
  "Brand",
  "Ad Type",
  "Version",
  "Strategy",
  "Story Placement",
];

export const industrySpecificColumns = {
  auto: ["Model Year", "Model Name", "Package"],
  retail: retailColumns,
  travel: retailColumns,
  pharma: retailColumns,
};
