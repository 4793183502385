import { FilterValue } from "antd/lib/table/interface";
import { isArray, isEmpty } from "lodash";
import { useQueryParams } from "use-query-params";
import { useDataListURLContext } from "./dataListURLContext";
import { FieldWithFilter, IDataListURLFields } from "./types";
import { fromEntries, getEntries, normalizeArray } from "./utils";
import { nonNullable } from "utils/helpers.array";

export const useDataListURLFilters = <
  FieldKey extends string,
  RecordType,
>() => {
  const { fields } = useDataListURLContext<FieldKey, RecordType>();
  const [query, setQuery] = useQueryParams(getFilterQueryParams(fields));

  const setFilters = (filters: Record<FieldKey, FilterValue | null>) => {
    const updatedFilters = fromEntries(
      getEntries(filters).map(([key, value]) => [
        key,
        isEmpty(value) ? undefined : value,
      ]),
    );

    setQuery(previousQuery => ({
      ...previousQuery,
      ...updatedFilters,
    }));
  };

  const updateFilter = (key: FieldKey, value: string | string[] | null) => {
    setQuery(previousQuery => ({
      ...previousQuery,
      [key]: value,
    }));
  };

  const resetFilters = () => {
    setQuery(getEmptyFilterQueryParams(fields));
  };

  const removeFilter = (key: FieldKey, value: React.Key | boolean) => {
    setQuery(previousQuery => {
      const previousFilter = previousQuery[key];

      if (isArray(previousFilter)) {
        const newValue = previousFilter.filter(
          filterValue => filterValue !== value,
        );

        return {
          ...previousQuery,
          [key]: isEmpty(newValue) ? undefined : newValue,
        };
      }

      return {
        ...previousQuery,
        [key]: undefined,
      };
    });
  };

  return {
    filters: fromEntries(
      getEntries(query).map(([key, value]) => [key, normalizeArray(value)]),
    ),
    setFilters,
    updateFilter,
    resetFilters,
    removeFilter,
  };
};

const getFilterQueryParams = <FieldKey extends string, RecordType>(
  fields: IDataListURLFields<FieldKey, RecordType>,
) => {
  const fieldEntries = getEntries(fields);

  return fromEntries(
    fieldEntries
      .map<
        | [FieldKey, FieldWithFilter<RecordType>["queryParamConfigFilter"]]
        | undefined
      >(([key, field]) => {
        return field?.queryParamConfigFilter
          ? [key, field.queryParamConfigFilter]
          : undefined;
      })
      .filter(nonNullable),
  );
};

const getEmptyFilterQueryParams = <FieldKey extends string, RecordType>(
  fields: IDataListURLFields<FieldKey, RecordType>,
) => {
  const filterEntries = getEntries(fields);
  return fromEntries(filterEntries.map(([key, _]) => [key, undefined]));
};
