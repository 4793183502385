import { withAuthenticationRequired } from "@auth0/auth0-react";
import { AuthContext } from "contexts/AuthContext";
import { ComponentType, useContext } from "react";

export const ProtectedRoute = ({
  component,
  ...args
}: {
  component: ComponentType;
}) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};
