import { Button } from "antd";
import styles from "./PmaxDrawerFooter.module.scss";
import { PmaxCreateAssetGroupFooterProps } from "../PmaxLoadDrawer";

type Props = Pick<
  PmaxCreateAssetGroupFooterProps,
  "onClose" | "onGoToAdLoadReview" | "previewDisabled"
>;

export const SelectStepFooter = ({
  onClose,
  onGoToAdLoadReview,
  previewDisabled,
}: Props) => (
  <div className={styles.buttonContainer}>
    <Button onClick={onClose}>Cancel</Button>
    <Button
      type="primary"
      onClick={onGoToAdLoadReview}
      disabled={previewDisabled}
    >
      Next
    </Button>
  </div>
);
