import {
  getAdName,
  extractAdName,
  getAdCopy,
  getAdDisplayUrl,
  getAdHeadline,
  getAdCta,
  getAdNewsLinkDescription,
} from "./helpers.ad";
import { dedupe } from "utils/helpers.array";
import {
  AdGroup,
  AdReviewTableEntry,
  AdReviewTableItem,
} from "shared/types/campaignManagement";
import { IFacebookAd, AdType } from "screens/adLibrary/facebookUtils/types";
import { first, groupBy } from "lodash";
import { compareString } from "utils/helpers";
import { QCStatus } from "shared/types/shared";

export const AD_NAME_DELIMITER = "_";

const tableItem = (ad: IFacebookAd): AdReviewTableItem => {
  return {
    id: ad.id || "",
    adFormat: ad.type as AdType,
    destinationUrl: ad.destinationUrl || "",
    endDate: ad.adset?.end_time || "",
    startDate: ad.adset?.start_time || "",
    createdTime: ad.created_time || "",
    updatedTime: ad.updated_time || "",
    name: extractAdName({ name: ad?.name, delimiter: AD_NAME_DELIMITER }) || "",
    urlTags: ad.creative?.url_tags,
    effectiveStatus: ad.effective_status,
    primaryText:
      ad.creative?.object_story_spec?.video_data?.message ??
      ad.creative?.object_story_spec?.link_data?.message ??
      ad.creative?.object_story_spec?.template_data?.message ??
      "",
  };
};

const getParentValue = (values: string[]) => {
  const valuesSet = Array.from(new Set(values));
  if (valuesSet.length === 1) return valuesSet[0];
  return "Multiple";
};

const getQCStatus = (groupId: string, ads: IFacebookAd[]) => {
  const qcHistoryLogs = ads
    .map(ad => ad.qcHistoryLogs ?? [])
    .flat()
    .filter(qcHistoryLog => qcHistoryLog.id === groupId);

  const sortedQCHistoryLogs = qcHistoryLogs?.sort((a, b) =>
    compareString(b.updatedAt, a.updatedAt),
  );

  const qcStatus = first(sortedQCHistoryLogs)?.status || QCStatus.NOT_QCed;

  return qcStatus;
};

const adGroupToTableEntry = (group: AdGroup): AdReviewTableEntry => {
  const children = group.ads?.map(ad => tableItem(ad));
  const groupId = children.map(child => child.id).join(AD_NAME_DELIMITER);
  const qcStatus = getQCStatus(groupId, group.ads);
  const uniqueUrls = children
    .flatMap(child => child.destinationUrl.split("\n"))
    .filter(dedupe);

  const parent: AdReviewTableItem = {
    // we assume that all ads in the group have the same Ad Format
    ...tableItem(group.ads[0]),
    id: groupId,
    endDate: getParentValue(children.map(child => child.endDate)),
    startDate: getParentValue(children.map(child => child.startDate)),
    destinationUrl: uniqueUrls.join("\n"),
    createdTime: getParentValue(children.map(child => child.createdTime || "")),
    updatedTime: getParentValue(children.map(child => child.updatedTime || "")),
  };

  const getAdExtraValues = (group: AdGroup) => {
    if (!group.ads || group.ads.length === 0) {
      return {};
    }

    const ad = group.ads[0];

    const { adset, campaign, creative } = ad;
    const utm = creative?.url_tags;
    const headline = getAdHeadline(ad);
    const copy = getAdCopy(ad);
    const newsLinkDescription = getAdNewsLinkDescription(ad);
    const displayUrl = getAdDisplayUrl(ad);
    const cta = getAdCta(ad);

    return {
      qcStatus: qcStatus,
      adSetName: adset?.name,
      campaignName: campaign?.name,
      cta: cta,
      copy: copy,
      headline: headline,
      newsLinkDescription: newsLinkDescription,
      displayUrl: displayUrl,
      utm: utm,
    };
  };

  return {
    ...parent,
    ...getAdExtraValues(group),
    children: children.map(child => ({
      ...child,
      parentId: parent.id,
      qcStatus: qcStatus,
    })),
  };
};

const getAdGroupKey = (ad: IFacebookAd, isGroupedAds: boolean) => {
  const adName = getAdName({
    name: ad.name,
    adsetName: ad.adset?.name,
    delimiter: AD_NAME_DELIMITER,
  });
  const adCopy = getAdCopy(ad);
  const startDate = ad.campaign?.start_time || "";
  if (isGroupedAds) {
    return `${ad.type} ${adName} ${adCopy} ${startDate}`.trim();
  }
  return `${ad.id}`;
};

export const createDataSource = (ads: IFacebookAd[], isGroupedAds: boolean) => {
  const groupedAds = groupBy(ads, ad => getAdGroupKey(ad, isGroupedAds));

  const adGroups = Object.entries(groupedAds).map(
    ([groupName, ads]): AdGroup => {
      return {
        name: groupName,
        ads: ads,
      };
    },
  );

  return adGroups.map(group => adGroupToTableEntry(group));
};

/** Based on the defined requirements,
 *  Ad Review table entries are aggregated
 *  items, where there is shared creative
 *  among grouped items. So, we can use
 *  one card per creative item.
 */
export const mapEntriesToCardData = (
  dataSource: AdReviewTableEntry[],
  facebookAds: IFacebookAd[],
): IFacebookAd[] =>
  dataSource.flatMap(entry => {
    const firstChild = entry.children?.[0];
    if (!firstChild) return [];
    const ad = facebookAds.find(ad => ad.id === firstChild.id);
    if (!ad) return [];
    return { ...ad, name: entry.name, id: entry.id };
  });

export const getParentAd = (
  parentEntry: AdReviewTableEntry,
  facebookAdsFromEntry: IFacebookAd[],
) => {
  const firstChild = first(parentEntry.children);
  const parentAd = first(
    facebookAdsFromEntry.filter(ad => ad.id === firstChild?.id),
  );
  return parentAd;
};

export const getRelatedAds = (
  parentEntry: AdReviewTableEntry,
  facebookAdsFromEntry: IFacebookAd[],
) => {
  const parentAd = getParentAd(parentEntry, facebookAdsFromEntry);
  const relatedAds = facebookAdsFromEntry.filter(ad => ad.id !== parentAd?.id);
  return relatedAds;
};
