import { LinkOutlined } from "@ant-design/icons";
import { Button, Col, Divider, message, Row } from "antd";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import BreadcrumbNavigation from "shared/components/BreadcrumbNavigation";
import { useAppDispatch } from "shared/hooks/useAppDispatch";
import { useAppShallowEqualSelector } from "shared/hooks/useAppSelector";
import useShareLinkModal, {
  isSharablePathname,
} from "shared/hooks/useShareLinkModal";
import useWindowDimensions from "shared/hooks/useWindowDimensions";
import ClientIcons from "shared/icons/ClientIcons";
import { IMenu } from "shared/types/menu";
import { SharedProps } from "shared/types/shared";
import { ReactComponent as ConstellationLockup } from "statics/images/constellation-lockup.svg";
import LithiaLockup from "statics/images/lithia-white-logo-lg-v2.png";
import {
  getEnvVar,
  isEnvVarEquals,
  pathnameToAlexiaModule,
} from "utils/helpers";

import actions from "../../../redux/rootActions";
import layoutStyles from "../Layout.module.scss";
import Hamburger from "./Hamburger";
import styles from "./Header.module.scss";
import HelpPopover from "./header/HelpPopover";
import UserProfilePopover from "./header/UserProfilePopover";
import { AuthContext } from "contexts/AuthContext";

type HeaderProps = {
  menus: IMenu[];
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
  shareLinkUrl?: string;
};

const Header = ({ menus, collapsed, setCollapsed }: HeaderProps) => {
  const { authorized } = useContext(AuthContext);
  const clientName = getEnvVar("CLIENT") || "internal";
  const { width } = useWindowDimensions();
  const mobileWidth = width < 768;

  const { shareLinkUrl } = useSelector(
    ({ shared }: { shared: SharedProps }) => ({
      shareLinkUrl: shared.shareLinkUrl,
    }),
  );
  const { Modal } = useShareLinkModal({
    link: shareLinkUrl,
  });

  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const onInitiatingShareLink = useCallback(() => {
    const module = pathnameToAlexiaModule(pathname);
    if (!module) return;

    const at = Date.now();

    const shareLinkMessageKey = "share-link";

    message.loading({
      key: shareLinkMessageKey,
      content: "Preparing...",
      duration: 0,
    });

    dispatch(actions.shared.onInitiatingShareLink(module, at));
  }, [pathname, dispatch]);

  const { user } = useAppShallowEqualSelector(({ auth }) => ({
    user: auth.user,
  }));
  const navigate = useNavigate();
  const onLoginButtonClick = useCallback(() => {
    navigate("/signin");
  }, [navigate]);

  const isLad = isEnvVarEquals("CLIENT", "ladtech");

  return (
    <>
      <Row className={styles.row} wrap={false}>
        <Col className={styles["menu-icon"]}>
          {authorized && (
            <Hamburger
              onClick={() => setCollapsed(!collapsed)}
              collapsed={collapsed}
            />
          )}
        </Col>

        <Col className={layoutStyles.col} xxl={20} lg={18} md={16}>
          {!isLad && <ConstellationLockup />}
          {isLad && (
            <img
              src={LithiaLockup}
              style={{
                width: "101px",
                marginLeft: "30px",
                marginRight: "30px",
              }}
            />
          )}
          {authorized && (
            <>
              <Divider
                className={layoutStyles.divider}
                style={{
                  display: mobileWidth ? "none" : "flex",
                }}
                type="vertical"
              />
              {!mobileWidth && (
                <>
                  {/* {brandsAccountsEnabled && (
                      <>
                        <BrandSelect bordered={false} />
                        <span className={styles.divider}>/</span>
                      </>
                    )} */}
                  <BreadcrumbNavigation
                    menus={menus}
                    setCollapsed={setCollapsed}
                  />
                </>
              )}
            </>
          )}
        </Col>

        <Col
          className={`${layoutStyles.col} ${styles.clientIconsCol}`}
          offset={1}
        >
          {isSharablePathname(pathname) && (
            <>
              <Button
                className={styles.shareButton}
                type="primary"
                icon={<LinkOutlined />}
                onClick={onInitiatingShareLink}
              >
                Share
              </Button>

              {!user && (
                <Button type="link" onClick={onLoginButtonClick}>
                  Login
                </Button>
              )}
            </>
          )}
          <ClientIcons />
          {clientName !== "internal" && (
            <Divider type="vertical" className={layoutStyles.divider} />
          )}
          <>
            {/* FIXME: this is the notifications spammer */}
            {/* <AlarmPopover /> */}
            <HelpPopover />
          </>
          {authorized && <UserProfilePopover />}
        </Col>
      </Row>
      {Modal}
    </>
  );
};

export default Header;
