import { AdType, CallToAction } from "screens/adLibrary/facebookUtils/types";
import VirtualTable from "shared/components/VirtualTable";
import { useWindowSize } from "shared/hooks/useWindowSize";
import { useDataSource } from "./adLoadReviewTable/useDataSource";
import { useColumns } from "./adLoadReviewTable/useColumns";
import { useSetCurrentSession } from "../../hooks/useCurrentSession";
import { getAdKeyFromDest } from "../../campaignData.utils";
import { Key } from "react";

export type AdLoadReviewTableEntryProductSet = {
  productCatalogId: string;
  productSetId: string;
};

export type AdLoadReviewTableEntry = {
  selected: boolean;
  thumbnail: string;
  adId: string;
  name: string;
  type: AdType;
  storeName: string;
  campaignId: string;
  adsetId: string;
  destinationUrls: string[] | undefined;
  displayUrl: string | undefined;
  ctaButton: CallToAction | undefined;
  productSets: AdLoadReviewTableEntryProductSet[];
};

const AdLoadReviewTable = () => {
  const { setSession } = useSetCurrentSession();
  const dataSource = useDataSource();
  const columns = useColumns(dataSource);

  const onChangeSelectedRowKeys = (selectedRowKeys: Key[]) => {
    setSession(prevSession => {
      prevSession.data.adProtoDrafts.forEach(adProtoDraft => {
        adProtoDraft.dests.forEach(dest => {
          const adKey = getAdKeyFromDest({
            adId: adProtoDraft.id,
            storeName: adProtoDraft.storeName,
            campaignId: dest.campaignId,
            adsetId: dest.adsetId,
          });
          dest.loadToFb = selectedRowKeys.includes(adKey);
        });
      });
    });
  };

  const { windowInnerHeight } = useWindowSize();

  return (
    <VirtualTable
      scroll={{ y: windowInnerHeight - 210 }}
      size="small"
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      rowKey={getAdKeyFromDest}
      rowSelection={{
        type: "checkbox",
        selectedRowKeys: dataSource
          .filter(record => record.selected)
          .map(getAdKeyFromDest),
        onChange: onChangeSelectedRowKeys,
      }}
    />
  );
};

export default AdLoadReviewTable;
