import { fabric } from "fabric";
import {
  createContext,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import API from "services";
import { fetchCanvasJson } from "shared/components/contextAPI/shared/RenderTemplate";
import { videoCompositionEnabled } from "shared/constants/assetExporter";
import {
  FeedColumn,
  FeedTblRow,
  TAssetBatch,
  TComposition,
  TConditionType,
} from "shared/types/assetExporter";
import { ITemplate } from "shared/types/designStudio";
import {
  extractVariables,
  setTextboxDataForCanvas,
} from "../../assetBatchDrawer/dynamicText/utils.variable";
import {
  TBackgroundMedia,
  TCanvasJSON,
  TValueMapping,
  TValueMappings,
  TVariable,
} from "../types";
import { isCarCut, isImageRect, isLogo, isTextbox } from "../validators";
import { useAssetBatchesContext } from "./AssetBatchesContext";
import {
  generateValueMappings,
  getBackgroundMediaForUrl,
  hasMediaInputError,
} from "./AssetBatchesContext.utils";

export type TRuleCondition = {
  index: number;
  operator: "and" | "or";
  columnName: string;
  comparisonOperator: TConditionType;
  value: string;
};

export const getEmptyRuleCondition = (index: number) => {
  return {
    index,
    operator: "and",
    columnName: "",
    comparisonOperator: "Equals",
    value: "",
  } as TRuleCondition;
};

interface ContextProps {
  emptyValueHighlight?: boolean;
  saveTemplate: (
    newTemplate: ITemplate,
    editingAssetBatches?: TAssetBatch,
    isTemplateEditing?: boolean,
  ) => Promise<void>;
  onSelectedRowChange: (row: FeedTblRow, compositionId?: string) => void;
  highlightVariable: (
    status: "enter" | "leave" | "focus" | "blur" | "emptyValue" | "fillValue",
    mappingKey: string,
  ) => void;
  onBackgroundMediaChange: (
    compositionId: string,
    media: TBackgroundMedia | undefined,
  ) => void;
  onBackgroundColumnChange: (compositionId: string, column?: string) => void;
  updateValueMappings: (
    variables: TVariable[],
    compositionToEdit: TComposition,
  ) => void;
  getMediaInputError: (
    value?: string,
    valueMapping?: TValueMapping,
  ) => string | undefined;
  useTemplateEffect: (
    editingAssetBatch: TAssetBatch | undefined,
    updateTemplates: (templates: ITemplate[]) => void,
  ) => void;
  selectedRow: any;
  hoveredMappingKey: string | undefined;
  setSelectedRow: React.Dispatch<any>;
  setHoveredMappingKey: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  changedMappingKey: string | undefined;
  setChangedMappingKey: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  updateCompositionVariables: (
    newVariables: TValueMappings,
    compositionId: string,
  ) => void;
  showVariablesOn: boolean;
  setShowVariablesOn: React.Dispatch<React.SetStateAction<boolean>>;
}

type ContextProviderProps = {
  children: ReactNode;
};

const Context = createContext<ContextProps>({} as ContextProps);

const ContextProvider = ({ children }: ContextProviderProps) => {
  const {
    setVariables,
    setCompositions,
    updateMaxStep,
    setTemplates,
    setHasFixedBackground,
    setBackgroundMedias,
    setBackgroundColumns,
    setCurrentCompositionId,
    setCompositionToReplace,
    setTemporalDataFilters,
    removeBackgrounds,
    currentStep,
    previewCount,
    mediaColumns,
    backgroundColumns,
    backgroundMedias,
    rows,
    feedId,
    compositionToReplace,
  } = useAssetBatchesContext();

  const [selectedRow, setSelectedRow] = useState<any>(rows[0]);
  const [hoveredMappingKey, setHoveredMappingKey] = useState<string>();
  const [changedMappingKey, setChangedMappingKey] = useState<string>();
  const [emptyValueHighlight, setEmptyValueHighlight] = useState<boolean>();
  const [showVariablesOn, setShowVariablesOn] = useState(true);

  const updateCompositionVariables = useCallback(
    (newVariables: TValueMappings, compositionId: string) => {
      setCompositions(prevCompositions => {
        return prevCompositions.map(comp => {
          if (comp.compositionId === compositionId) {
            return {
              ...comp,
              variables: newVariables,
            };
          }
          return comp;
        });
      });
    },
    [setCompositions],
  );

  const updateValueMappings = useCallback(
    (variables: TVariable[], compositionToEdit: TComposition) => {
      setVariables(oldVariables =>
        oldVariables.map(oldVar => {
          if (oldVar.compositionId === compositionToEdit.compositionId) {
            return {
              compositionId: compositionToEdit.compositionId,
              variables,
            };
          }
          return oldVar;
        }),
      );

      const mappings = generateValueMappings(variables, selectedRow);
      Object.keys(mappings).forEach(key => {
        if (compositionToEdit?.variables[key])
          mappings[key] = compositionToEdit.variables[key];
      });
      updateCompositionVariables(mappings, compositionToEdit.compositionId);
    },
    [selectedRow, updateCompositionVariables, setVariables],
  );

  const addOrReplaceComposition = useCallback(
    (composition: TComposition) => {
      if (!compositionToReplace)
        return setCompositions(oldCompositions => [
          ...oldCompositions,
          composition,
        ]);
      setCompositions(oldCompositions =>
        oldCompositions.map(oldComposition => {
          if (oldComposition.compositionId === composition.compositionId)
            return composition;

          return oldComposition;
        }),
      );
    },
    [setCompositions, compositionToReplace],
  );

  const addOrReplaceTemplates = useCallback(
    (newTemplate: ITemplate) => {
      if (!videoCompositionEnabled) return setTemplates([newTemplate]);
      setTemplates(oldTemplates => {
        const templatesFiltered = compositionToReplace
          ? oldTemplates.filter(
              templ => templ.id !== compositionToReplace.template,
            )
          : oldTemplates;
        return [...templatesFiltered, newTemplate];
      });
    },
    [compositionToReplace, setTemplates],
  );

  const saveTemplate = useCallback(
    async (
      newTemplate: ITemplate,
      editingAssetBatches?: TAssetBatch,
      isTemplateEditing?: boolean,
    ) => {
      if (!newTemplate.id) return;

      updateMaxStep(currentStep);

      const composition: TComposition = {
        compositionId: compositionToReplace
          ? compositionToReplace.compositionId
          : `composition_${newTemplate.id}_${Date.now()}`,
        feedId,
        template: newTemplate.id,
        frameStart: compositionToReplace ? compositionToReplace.frameStart : 0,
        frameEnd: compositionToReplace ? compositionToReplace.frameEnd : 10,
        variables: {},
        ...(editingAssetBatches?.assetBatchId && {
          assetBatchId: editingAssetBatches.assetBatchId,
        }),
      };

      addOrReplaceTemplates(newTemplate);
      if (videoCompositionEnabled) {
        setVariables(oldVariables => [
          ...oldVariables.filter(
            oldVariables =>
              oldVariables.compositionId !==
              compositionToReplace?.compositionId,
          ),
          { compositionId: composition.compositionId, variables: [] },
        ]);

        addOrReplaceComposition(composition);

        setCurrentCompositionId(composition.compositionId);
        if (compositionToReplace) {
          removeBackgrounds(compositionToReplace);
          setCompositionToReplace(undefined);
        }
        setTemporalDataFilters(undefined);
      } else {
        setVariables([
          { compositionId: composition.compositionId, variables: [] },
        ]);
        setCompositions([composition]);
      }

      if (!isTemplateEditing) {
        setBackgroundMedias(undefined);
        setBackgroundColumns(undefined);
      }

      /*  NOTE: Below process is required for variable highlighting and few things related to preview.
       *    For example, for a textbox, text and text lines arent exactly right unless we render with certain dimensions.
       *    So the rendering is necessary here in order to setup properly for variable highlight.
       */
      const { canvasJsonUrl } = newTemplate;
      if (!canvasJsonUrl) return;

      const json = (await fetchCanvasJson(canvasJsonUrl)) as TCanvasJSON;
      const canvas = new fabric.Canvas(document.createElement("canvas"));
      canvas.setDimensions({
        width: newTemplate.artboard.width,
        height: newTemplate.artboard.height,
      });

      canvas.loadFromJSON(json, () => {
        const backgroundImg = canvas.backgroundImage;
        if (backgroundImg) setHasFixedBackground(true);

        const objs = canvas.getObjects();
        const textboxes = objs.filter(isTextbox);
        textboxes.forEach(textbox => setTextboxDataForCanvas(textbox));
        const images = objs.filter(obj => isCarCut(obj) || isImageRect(obj));
        const logos = objs.filter(obj => isLogo(obj));
        const vars = [...textboxes, ...images, ...logos].reduce<
          Array<TVariable>
        >((acc, obj) => {
          return [...acc, ...extractVariables(obj)];
        }, []);
        updateValueMappings(vars, composition);
      });
    },
    [
      currentStep,
      feedId,
      compositionToReplace,
      setBackgroundColumns,
      setBackgroundMedias,
      setCompositions,
      setCurrentCompositionId,
      setHasFixedBackground,
      setVariables,
      updateMaxStep,
      updateValueMappings,
      setCompositionToReplace,
      setTemporalDataFilters,
      removeBackgrounds,
      addOrReplaceComposition,
      addOrReplaceTemplates,
    ],
  );

  const onBackgroundMediaChange = useCallback(
    (compositionId: string, media: TBackgroundMedia | undefined) => {
      setBackgroundMedias({ ...backgroundMedias, [compositionId]: media });
      setBackgroundColumns({
        ...backgroundColumns,
        [compositionId]: media?.column,
      });
    },
    [
      backgroundColumns,
      backgroundMedias,
      setBackgroundColumns,
      setBackgroundMedias,
    ],
  );

  const onBackgroundColumnChange = useCallback(
    (compositionId: string, column?: string) => {
      setBackgroundColumns({ ...backgroundColumns, [compositionId]: column });
      if (!column) return;
      const media = getBackgroundMediaForUrl(selectedRow[column], column);
      setBackgroundMedias({ ...backgroundMedias, [compositionId]: media });
    },
    [
      setBackgroundColumns,
      backgroundColumns,
      selectedRow,
      setBackgroundMedias,
      backgroundMedias,
    ],
  );

  const onSelectedRowChange = (row: FeedTblRow, compositionId?: string) => {
    setSelectedRow(row);
    if (!compositionId) return;
    if (!backgroundColumns?.[compositionId]) {
      setBackgroundMedias({ ...backgroundMedias, [compositionId]: undefined });
      return;
    }
    // Update background media as selected row changes
    const media = getBackgroundMediaForUrl(
      row[backgroundColumns[compositionId] as FeedColumn],
      backgroundColumns[compositionId],
    );
    setBackgroundMedias({ ...backgroundMedias, [compositionId]: media });
  };

  const highlightVariable = (
    status: "enter" | "leave" | "focus" | "blur" | "emptyValue" | "fillValue",
    mappingKey: string,
  ) => {
    if (status === "fillValue") setEmptyValueHighlight(true);
    else setEmptyValueHighlight(false);

    setHoveredMappingKey(
      status === "leave" || status === "blur" ? undefined : mappingKey,
    );
  };

  const getMediaInputError = useCallback(
    (value?: string, valueMapping?: TValueMapping) => {
      const error = hasMediaInputError(
        previewCount,
        value,
        valueMapping,
        mediaColumns,
      );
      if (error === "EMPTY")
        return "select a column containing media or type a url";
      else if (error === "INVALID") return "enter a valid URL";
      const mediaColumn = mediaColumns?.find(col => col.columnName === value);
      if (error === "COLUMN_DISPARITY" && mediaColumn)
        return `${
          previewCount - mediaColumn.count
        } of ${previewCount} rows contain invalid media links`;
    },
    [mediaColumns, previewCount],
  );

  const useTemplateEffect = (
    editingAssetBatch: TAssetBatch | undefined,
    updateTemplates: (templates: ITemplate[]) => void,
  ) => {
    useEffect(() => {
      if (editingAssetBatch) {
        const promises = editingAssetBatch.compositions.map(composition =>
          API.services.designStudio.getTemplateById(composition.template),
        );

        Promise.all(promises).then(results => {
          const templates = results
            .map(result => result?.result?.template)
            .filter(Boolean);
          updateTemplates(templates as ITemplate[]);
        });
      }
    }, [editingAssetBatch, updateTemplates]);
  };

  return (
    <Context.Provider
      value={{
        selectedRow,
        hoveredMappingKey,
        changedMappingKey,
        emptyValueHighlight,
        showVariablesOn,
        saveTemplate,
        onSelectedRowChange,
        highlightVariable,
        onBackgroundMediaChange,
        onBackgroundColumnChange,
        updateValueMappings,
        getMediaInputError,
        useTemplateEffect,
        setSelectedRow,
        setHoveredMappingKey,
        setChangedMappingKey,
        updateCompositionVariables,
        setShowVariablesOn,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const AssetBatchesValueMappingProvider = memo(ContextProvider);

export const useAssetBatchesValueMappingContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("Context must be used within a ContextProvider");
  }

  return context;
};
