import { Button, Drawer, notification } from "antd";
import { Children, ReactElement, cloneElement, useState } from "react";
import { ButtonGroup } from "../ButtonGroup";
import { SelectFolder } from "../SelectFolder";
import { isRejected } from "../utils.promise";
import { FormMeta } from "./FormMeta";
import { useUpload } from "./useUpload";

import styles from "./CAMUpload.module.scss";

type Props = {
  children: ReactElement;
  urls: string[];
  onComplete?: () => void;
};

export const CAMUpload = ({ children, urls, onComplete }: Props) => {
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [meta, setMeta] = useState<MGFormValues>();
  const [folderId, setFolderId] = useState<string>();
  const uploadFromUrl = useUpload();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
    setMeta(undefined);
    setFolderId(undefined);
  };

  const handleClick = async () => {
    setUploading(true);
    const promises = urls.map(url => uploadFromUrl(url, folderId!, meta));
    const res = await Promise.allSettled(promises);
    const failed = res.filter(isRejected);

    if (failed.length) {
      notification.error({
        message: "Upload files failed",
        description: failed[0].reason.message,
        duration: 0,
      });
    } else {
      notification.success({
        message: "Upload files",
        description: `Success uploading ${urls.length} file(s)`,
        duration: 0,
      });
      onComplete?.();
      closeDrawer();
    }

    setUploading(false);
  };

  return (
    <>
      {Children.map(children, child =>
        cloneElement(child, { onClick: openDrawer }),
      )}
      <Drawer
        destroyOnClose
        title="Upload Files to CAM"
        width={960}
        visible={open}
        closable={false}
        onClose={closeDrawer}
        footer={
          <ButtonGroup>
            <Button key="cancel" onClick={closeDrawer}>
              Close
            </Button>
            <Button
              key="submit"
              type="primary"
              htmlType="submit"
              disabled={!folderId}
              loading={uploading}
              onClick={handleClick}
            >
              Upload
            </Button>
          </ButtonGroup>
        }
      >
        <div className={styles.body}>
          <div>
            <header>
              <h3>Metadata (Optional)</h3>
              <p>Will be used for all images.</p>
            </header>
            <FormMeta onChange={setMeta} />
          </div>
          <div>
            <header>
              <h3>Folder to save</h3>
            </header>
            <SelectFolder
              onSelect={keys => {
                setFolderId(String(keys[0]));
              }}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};
