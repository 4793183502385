import { ExtendedObjectType } from "shared/types/designStudio";
import { PictureOutlined, BorderOutlined } from "@ant-design/icons";
import { ReactComponent as TypographyOutlined } from "statics/images/svg/icons/typography-outlined.svg";
import { ReactComponent as StampOutlined } from "statics/images/svg/icons/stamp-outlined.svg";

type LayerIconProps = {
  type: ExtendedObjectType;
};

export const LayerIcon = ({ type }: LayerIconProps) => {
  switch (type) {
    case "text":
    case "textbox":
    case "disclosure":
      return <TypographyOutlined width="14px" />;
    case "car_cut":
    case "selected_image":
    case "canvas_bg":
    case "lifestyle":
    case "logo":
    case "theme_background":
      return <PictureOutlined width="14px" />;
    case "stamp":
      return <StampOutlined width="14px" />;
    case "shape":
      return <BorderOutlined width="14px" />;
    default:
      return null;
  }
};
