import { Tabs, Typography } from "antd";
import { PmaxLoadDrawerContentProps, PmaxLoadTab } from "../PmaxLoadDrawer";
import CreateNewAssetGroups from "./CreateNewAssetGroups";
import UpdateExistingAssetGroups from "./UpdateExistingAssetGroups";
import styles from "./pmaxSelectStep/PmaxSelectStep.module.scss";

type PmaxSelectStepProps = Pick<
  PmaxLoadDrawerContentProps,
  | "defaultTab"
  | "activeKey"
  | "dealer"
  | "setActiveKey"
  | "updateTabDisabled"
  | "createTabDisabled"
>;

export const PmaxSelectStep = ({
  defaultTab,
  setActiveKey,
  activeKey,
  updateTabDisabled,
  createTabDisabled,
}: PmaxSelectStepProps) => {
  return (
    <Tabs
      className={styles.tabs}
      activeKey={activeKey}
      onChange={key => setActiveKey(key as PmaxLoadTab)}
      defaultActiveKey={defaultTab}
      tabBarExtraContent={{
        left: (
          <Typography.Title className={styles.title} level={5}>
            Load to Google Ads
          </Typography.Title>
        ),
      }}
    >
      <Tabs.TabPane
        disabled={createTabDisabled}
        tab="Create new asset groups"
        key={PmaxLoadTab.create}
      >
        <CreateNewAssetGroups />
      </Tabs.TabPane>
      <Tabs.TabPane
        disabled={updateTabDisabled}
        tab="Update existing asset groups"
        key={PmaxLoadTab.update}
      >
        <UpdateExistingAssetGroups />
      </Tabs.TabPane>
    </Tabs>
  );
};
