import { useCallback, useEffect } from "react";
import { useLocalStorage } from "shared/hooks/useLocalStorage";
import { createEnumParam, useQueryParam } from "use-query-params";

type Layout = "table" | "card";

const LayoutEnumParam = createEnumParam(["table", "card"]);

export const useLayoutPreference = (
  localStorageKey: string,
): [Layout, (layout: Layout) => void] => {
  const [urlLayout, setURLLayout] = useQueryParam("layout", LayoutEnumParam);

  const [localStorageLayout, setLocalStorageLayout] = useLocalStorage<Layout>(
    localStorageKey,
    "table",
  );

  const changeLayout = (layout: Layout) => {
    setURLLayout(layout);
    setLocalStorageLayout(layout);
  };

  const syncUrlWithLocalStorage = useCallback(() => {
    if (!urlLayout) {
      setURLLayout(localStorageLayout);
      return;
    }

    setLocalStorageLayout(urlLayout);
  }, [localStorageLayout, setLocalStorageLayout, setURLLayout, urlLayout]);

  useEffect(syncUrlWithLocalStorage, [syncUrlWithLocalStorage]);

  return [urlLayout ?? localStorageLayout, changeLayout];
};
