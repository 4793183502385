import GenericError from "shared/errors/GenericError";
import { IUserSignUpForm } from "shared/types/userManagement";

const signUpFormAttributes = ["firstName", "lastName", "group", "role"];

export const doesUserSignUpFormHasValidAttributes = (form: IUserSignUpForm) => {
  const { attributes } = form;

  let missingAttributes = [...signUpFormAttributes];

  Object.keys(attributes).forEach(attribute => {
    if (signUpFormAttributes.includes(attribute)) {
      missingAttributes = missingAttributes.filter(
        tmpAttr => tmpAttr !== attribute,
      );
    }
  });

  if (missingAttributes.length > 0) {
    throw new GenericError({
      message: `The ISignUpForm attributes missing the followings: ${missingAttributes}`,
    });
  }
};

export const isEmailValid = (email: string) => {
  // eslint-disable-next-line
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

  return email && emailRegex.test(email);
};

export const isNameInputValid = (name: string) => {
  const regex = /^[a-z](?!.* {2})[ \w.,:;_'-]{2,100}$/gim;
  return name && regex.test(name);
};

export const validUrlRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const isUrlInputValid = (url: string) => {
  return url && validUrlRegex.test(url);
};

export const isDestinationUrlInvalid = (value: string) =>
  value &&
  (value.endsWith("/") ||
    value.match(/\s/) ||
    !value.match(/^https?:\/\//) ||
    !value.match(/\.[a-zA-Z0-9]{2,}(\/{1,1})?(\.[a-zA-Z0-9]{2,})?/));

export const isValidUrl = (
  url: string,
  options?: { optionalProtocol: boolean },
): boolean => {
  try {
    const { protocol, hostname } = new URL(url);
    const hostnameParts = hostname.split(".");
    return (
      (protocol === "http:" || protocol === "https:") &&
      hostnameParts.length > 1 &&
      hostnameParts[hostnameParts.length - 1].length > 1
    );
  } catch (e) {
    if (options?.optionalProtocol) {
      return isValidUrl(`http://${url}`);
    }
    return false;
  }
};
