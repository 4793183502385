import { Table } from "antd";
import { UseMutationResult } from "react-query";
import { LoadStatus } from "screens/adLibrary/pmaxAssetGroups/shared/types";
import { GoogleAdCampaignInternal } from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";
import { getColumns } from "./campaignLoadTable/columns";

export type GoogleAdCampaignInternalWithStatus = GoogleAdCampaignInternal & {
  status: LoadStatus;
};

type CampaignLoadTableProps = {
  store?: IAccount;
  campaigns: GoogleAdCampaignInternalWithStatus[];
  batchCampaignLoadStatus: UseMutationResult["status"];
};

export const CampaignLoadTable = ({
  store,
  campaigns,
  batchCampaignLoadStatus,
}: CampaignLoadTableProps) => {
  const columns = getColumns(store, batchCampaignLoadStatus);

  return (
    <Table
      size="small"
      dataSource={campaigns}
      columns={columns}
      pagination={false}
    />
  );
};
