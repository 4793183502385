import { Empty, Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDesignStudioEditorLayers } from "../hooks/useDesignStudioEditorLayers";
import LayerItem from "./layers/LayerItem";
import { TLayerItem } from "screens/designStudio/editor.hooks/useLayers";

export const Layers = () => {
  const { onLayerDragEnd, layers } = useDesignStudioEditorLayers();

  return (
    <Form.Item>
      <Input
        size="large"
        bordered={false}
        placeholder="Search layers"
        prefix={<SearchOutlined />}
      />

      <DragDropContext onDragEnd={onLayerDragEnd}>
        <Droppable droppableId="droppable">
          {provided => {
            return (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <LayerList layers={layers} />
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
    </Form.Item>
  );
};

type LayerListProps = {
  layers: TLayerItem[];
};
const LayerList = ({ layers }: LayerListProps) => {
  const {
    selectedLayer,
    onLayerSingleClick,
    onLayerDoubleClick,
    onLayerKeyDown,
    onToggleLayerVisibility,
    onToggleLayerLock,
    onDeleteLayer,
    onLayerClickOutside,
  } = useDesignStudioEditorLayers();

  if (layers.length === 0)
    return (
      <Empty style={{ color: "white" }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    );

  return (
    <>
      {layers.map((layer, idx) => {
        const isDraggableLayer = !["canvas_bg", "canvas_area"].includes(
          layer.type,
        );

        const highlight = layer.id === selectedLayer?.layer?.id;
        const editing = Boolean(highlight && selectedLayer?.editing);
        return (
          <Draggable
            key={layer.id}
            draggableId={layer.id}
            index={idx}
            isDragDisabled={!isDraggableLayer}
          >
            {provided => {
              return (
                <LayerItem
                  layer={layer}
                  draggableProvided={provided}
                  highlight={highlight}
                  editing={editing}
                  onClick={onLayerSingleClick}
                  onDoubleClick={onLayerDoubleClick}
                  onKeyDown={onLayerKeyDown}
                  onToggleLayerVisibility={onToggleLayerVisibility}
                  onToggleLayerLock={onToggleLayerLock}
                  onLayerClickOutside={onLayerClickOutside}
                  onDeleteLayer={onDeleteLayer}
                />
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
};
