import { Typography } from "antd";
import Title from "antd/lib/typography/Title";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { ReactNode } from "react";
import { IFacebookAd } from "screens/adLibrary/facebookUtils/types";
import { AdReviewTableEntry } from "shared/types/campaignManagement";
import { formatDateValue } from "utils/helpers";
import {
  getCanvasId,
  getDestinationUrlsFromIE,
  shouldShowDestinationUrl,
} from "../../utils/helpers.ad";
import { getParentAd, getRelatedAds } from "../../utils/helpers.table";
import drawerStyles from "../AdDetailsDrawer.module.scss";
import DeliveryStatusBadge from "../DeliveryStatusBadge";
import styles from "./AdDetails.module.scss";
import AdInformations from "./AdDetails/AdInformations";
import DestinationUrl from "./AdDetails/DestinationUrl";
import { useFetchInstantExperiences } from "./AdPreviewSelector/useFetchInstantExperiences";

interface IProps {
  selectedEntry: AdReviewTableEntry;
  facebookAdsFromEntry: IFacebookAd[];
}

type AdDetailsProps = IProps;

const AdDetails = ({ selectedEntry, facebookAdsFromEntry }: AdDetailsProps) => {
  const parentAd = getParentAd(selectedEntry, facebookAdsFromEntry);
  const relatedAds = getRelatedAds(selectedEntry, facebookAdsFromEntry);

  const selectedInstantExperience = getCanvasId(parentAd || {});

  const { data: instantExperiences } = useFetchInstantExperiences(parentAd?.id);

  const destinationUrls = getDestinationUrlsFromIE({
    instantExperienceId: selectedInstantExperience || "",
    instantExperiences: instantExperiences || [],
  });

  const RelatedAds = () => (
    <>
      {relatedAds.map(ad => (
        <span key={ad.id}>
          <AdInformations facebookAd={ad} />
        </span>
      ))}
    </>
  );

  const AdDetail = ({ label, value }: { label: string; value: ReactNode }) => (
    <div className={drawerStyles.section}>
      <Typography.Text className={drawerStyles.value}>
        <b className={drawerStyles.label}>{`${label}:`}</b>
        {value}
      </Typography.Text>
    </div>
  );

  return (
    <div
      data-cy="ad-details"
      className={classnames(drawerStyles.module, styles.adDetails)}
    >
      <Title style={{ margin: 0 }} level={4} className={drawerStyles.title}>
        <b>Ad Details</b>
      </Title>
      <div className={drawerStyles.moduleContent}>
        {["InstantExperience", "Carousel"].includes(parentAd?.type || "") && (
          <AdDetail
            label="Destination Urls"
            value={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "24px",
                }}
              >
                {destinationUrls.map((url, index) => (
                  <Typography.Link
                    key={index}
                    href={url}
                    style={{ width: "100%" }}
                  >
                    {url}
                  </Typography.Link>
                ))}
              </div>
            }
          />
        )}
        <AdDetail label="Ad Name" value={selectedEntry?.name} />
        <AdDetail
          label="Primary Text"
          value={
            parentAd?.creative?.asset_feed_spec?.bodies[0]?.text ||
            selectedEntry?.primaryText
          }
        />
        {selectedEntry.effectiveStatus && (
          <AdDetail
            label="Delivery"
            value={
              <div className={styles.deliveryStatus}>
                <DeliveryStatusBadge status={selectedEntry.effectiveStatus} />
              </div>
            }
          />
        )}
        <AdDetail
          label="Start Date"
          value={formatDateValue(selectedEntry?.startDate)}
        />
        <AdDetail
          label="End Date"
          value={formatDateValue(selectedEntry?.endDate)}
        />
        <AdDetail label="Ad Format" value={selectedEntry.adFormat ?? ""} />
        {parentAd?.productSet?.catalog?.name && (
          <AdDetail
            label="Product Catalog"
            value={parentAd.productSet.catalog.name}
          />
        )}
        {parentAd?.productSet?.name && (
          <AdDetail label="Product Set" value={parentAd.productSet.name} />
        )}
        {(selectedEntry?.destinationUrl &&
          shouldShowDestinationUrl(selectedEntry) && (
            <AdDetail
              label="Destination URL"
              value={<DestinationUrl selectedEntry={selectedEntry} />}
            />
          )) || (
          <AdDetail
            label="Destination URL"
            value={
              <DestinationUrl
                destinationUrl={
                  parentAd?.creative?.asset_feed_spec?.link_urls[0]?.website_url
                }
              />
            }
          />
        )}
        {selectedEntry.urlTags && (
          <AdDetail label="UTM" value={selectedEntry.urlTags} />
        )}
        <AdDetail
          label="This ad"
          value={<AdInformations facebookAd={parentAd as IFacebookAd} />}
        />
        {relatedAds && !isEmpty(relatedAds) && (
          <AdDetail label="Related ads" value={<RelatedAds />} />
        )}
      </div>
    </div>
  );
};

export default AdDetails;
