import { AdType } from "screens/adLibrary/facebookUtils/types";
import { useFetchInstantExperiences } from "shared/hooks/adLibrary/useFetchInstantExperiences";
import {
  ElementType,
  IAd,
  IInstantExperience,
  InstantExperienceElementStyle,
} from "shared/types/adLibrary";
import { IAccount } from "shared/types/accountManagement";
import {
  AdShellEntry,
  CarouselItemEntry,
  EntryFormat,
  EverythingAdCarouselElementItemEntry,
  EverythingAdElementEntry,
  EverythingAdEntry,
} from "../AssignDestsTable.types";
import { useCurrentSession } from "screens/adLibrary/adLoadV2/hooks/useCurrentSession";
import { useAds } from "screens/adLibrary/shared/hooks/useAds";
import {
  AdProtoDraft,
  EAElementDest,
  InstExpAdProtoDraft,
  SessionData,
} from "screens/adLibrary/facebookUtils/adProto.types";
import { raise } from "utils/errorMessage";
import { useAdLoadSelectedStores } from "screens/adLibrary/adLoadV2/hooks/useAdLoadSelectedStores";
import { TableType } from "../AssignDestsTable";

const eaElementTypeToEntryFormatMap = {
  PHOTO: "Image",
  BUTTON: "Button",
  FOOTER: "Button",
  CAROUSEL: "Carousel",
  ELEMENT_GROUP: "Product Set",
  VIDEO: "Video",
} satisfies Record<ElementType, EntryFormat>;

export const useDataSource = (tableType: TableType): AdShellEntry[] => {
  const { selectedStores = [] } = useAdLoadSelectedStores();
  const { data: instantExperiences = [] } = useFetchInstantExperiences();
  const { session } = useCurrentSession();
  const sessionData = session.data;
  const { originalData: ads } = useAds();

  return sessionData.adProtoDrafts
    .filter(adProtoDraft => adProtoDraft.dests.length > 0)
    .map(adProtoDraft => {
      const ad =
        ads.find(({ id }) => id === adProtoDraft.id) ??
        raise(`Ad with id ${adProtoDraft.id} not found`);

      const selectedStore =
        selectedStores.find(
          store => store.dealer_name === adProtoDraft.storeName,
        ) ?? raise(`Store ${adProtoDraft.storeName} not found`);

      return getAdShellEntry({
        store: selectedStore,
        ad,
        adProtoDraft,
        instantExperiences,
        sessionData,
        tableType,
      });
    });
};

export const getAdShellEntry = ({
  store,
  ad,
  instantExperiences,
  sessionData,
  adProtoDraft,
  tableType,
}: {
  store: IAccount;
  ad: IAd;
  instantExperiences: IInstantExperience[];
  sessionData: SessionData;
  adProtoDraft: AdProtoDraft;
  tableType: TableType;
}): AdShellEntry => {
  const basicEntry = {
    id: ad.id,
    name: ad.inputParameters.name ?? "",
    adId: ad.id,
    fullPath: getNodeFullPath({
      targetId: ad.id,
      parentIds: [store.dealer_name],
    }),
    type: "AdShell",
    storeName: store.dealer_name,
  } satisfies Partial<AdShellEntry>;

  const adType = adProtoDraft.type;
  switch (adType) {
    case AdType.Still:
    case AdType.Video: {
      return {
        ...basicEntry,
        format: adType,
        destinationUrl: adProtoDraft.destinationUrl,
        displayUrl: adProtoDraft.displayUrl,
        thumbnail: ad.visuals.thumbnail,
        ctaButton: adProtoDraft.callToAction,
      };
    }
    case AdType.Carousel: {
      return {
        ...basicEntry,
        format: adType,
        ctaButton: adProtoDraft.callToAction,
        children: ad.visuals.cards?.map<CarouselItemEntry>((card, index) => {
          return {
            id: `${ad.id}-${index}`,
            adId: ad.id,
            fullPath: getNodeFullPath({
              targetId: index,
              parentIds: [store.dealer_name, ad.id],
            }),
            name: `Card ${index + 1}`,
            destinationUrl: adProtoDraft.cards[index].destinationUrl,
            format: card.videoUrl ? "Video" : "Image",
            thumbnail: card.thumbnail,
            type: "CarouselItem",
            cardIndex: index,
            storeName: store.dealer_name,
          };
        }),
      };
    }
    case AdType.AIA: {
      return {
        ...basicEntry,
        primaryText: ad.visuals.primaryText,
        format: adType,
        destinationUrl: adProtoDraft.destinationUrl,
        displayUrl: adProtoDraft.displayUrl,
      };
    }
    case AdType.InstantExperience:
    case AdType.Collection: {
      if (
        tableType === "assignDestinations" &&
        "convertToVideo" in adProtoDraft &&
        adProtoDraft.convertToVideo
      ) {
        return {
          ...basicEntry,
          thumbnail: ad.visuals.thumbnail,
          convertToVideo: true,
          format: adType,
          destinationUrl: adProtoDraft.destinationUrl,
          displayUrl: adProtoDraft.displayUrl,
          ctaButton: adProtoDraft.callToAction,
        };
      }

      const everythingAdId = ad.visuals.destination?.instantExperienceId;

      const everythingAdEntry =
        everythingAdId &&
        getEverythingAdEntry({
          adProtoDraft,
          everythingAdId,
          store,
          ad,
          instantExperiences,
          sessionData,
          parentIds: [store.dealer_name, ad.id],
        });

      return {
        ...basicEntry,
        convertToVideo: false,
        format: adType,
        thumbnail: ad.visuals.thumbnail,
        children: everythingAdEntry ? [everythingAdEntry] : undefined,
      };
    }
    default: {
      const _exhaustiveCheck: never = adType;
      throw Error(`Unknown ad format: ${_exhaustiveCheck}`);
    }
  }
};

const getEverythingAdEntry = ({
  adProtoDraft,
  everythingAdId,
  store,
  ad,
  instantExperiences,
  sessionData,
  parentIds,
}: {
  adProtoDraft: InstExpAdProtoDraft;
  everythingAdId: string;
  store: IAccount;
  ad: IAd;
  instantExperiences: IInstantExperience[];
  sessionData: SessionData;
  parentIds: string[];
}): EverythingAdEntry | undefined => {
  const everythingAd = instantExperiences?.find(ie => ie.id === everythingAdId);
  if (!everythingAd?.id) return;

  const children = getEverythingAdElementEntries({
    adProtoDraft,
    everythingAdId: everythingAd.id,
    store,
    ad,
    instantExperiences,
    sessionData,
    parentIds: [...parentIds, everythingAd.id],
  });

  return {
    adId: ad.id,
    id: everythingAd.id,
    fullPath: getNodeFullPath({
      targetId: everythingAd.id,
      parentIds,
    }),
    storeName: store.dealer_name,
    type: "EverythingAd",
    name: everythingAd.name ?? "Everything Ad",
    format: "Everything Ad",
    children,
  };
};

const getEverythingAdElementEntries = ({
  adProtoDraft,
  everythingAdId,
  store,
  ad,
  instantExperiences,
  sessionData,
  parentIds,
}: {
  adProtoDraft: InstExpAdProtoDraft;
  everythingAdId: string;
  store: IAccount;
  ad: IAd;
  instantExperiences: IInstantExperience[];
  sessionData: SessionData;
  parentIds: string[];
}): EverythingAdElementEntry[] | undefined => {
  const instantExperience = instantExperiences.find(
    ie => ie.id === everythingAdId,
  );
  if (!instantExperience?.id) return;

  return instantExperience.body_elements?.map<EverythingAdElementEntry>(
    element => {
      const elementType = element.element_type;

      const elementCommonProps = {
        name: element.name ?? eaElementTypeToEntryFormatMap[elementType],
        adId: ad.id,
        fullPath: getNodeFullPath({
          targetId: element.id,
          parentIds,
        }),
        id: element.id,
        storeName: store.dealer_name,
        type: "EverythingAdElement",
      } satisfies Partial<EverythingAdElementEntry>;

      switch (elementType) {
        case "PHOTO": {
          if (
            element.style &&
            element.style !== InstantExperienceElementStyle.FIT_TO_WIDTH
          ) {
            return {
              ...elementCommonProps,
              format: "Image",
              hasDestination: false,
              thumbnail: element.url,
            };
          }

          const destinationIeId = element.destination?.instantExperienceId;
          const everythingAdEntry =
            destinationIeId &&
            getEverythingAdEntry({
              adProtoDraft,
              everythingAdId: destinationIeId,
              instantExperiences,
              store,
              ad,
              sessionData,
              parentIds: [...parentIds, element.id],
            });

          const destinationUrl = getElementDestinationUrl({
            elementDests: adProtoDraft.elementDests,
            elementId: element.id,
          });

          return {
            ...elementCommonProps,
            format: "Image",
            hasDestination: true,
            thumbnail: element.url,
            ...(everythingAdEntry
              ? { children: [everythingAdEntry] }
              : {
                  destinationUrl,
                }),
          };
        }
        case "BUTTON": {
          const destinationIeId = element.destination?.instantExperienceId;
          const everythingAdEntry =
            destinationIeId &&
            getEverythingAdEntry({
              adProtoDraft,
              everythingAdId: destinationIeId,
              instantExperiences,
              ad,
              store,
              sessionData,
              parentIds: [...parentIds, element.id],
            });

          const destinationUrl = getElementDestinationUrl({
            elementDests: adProtoDraft.elementDests,
            elementId: element.id,
          });

          return {
            ...elementCommonProps,
            format: "Button",
            ...(everythingAdEntry
              ? { children: [everythingAdEntry] }
              : {
                  destinationUrl,
                }),
          };
        }
        case "FOOTER": {
          const childButtonElement = element.child_elements[0];

          const destinationIeId =
            childButtonElement.destination?.instantExperienceId;
          const everythingAdEntry =
            destinationIeId &&
            getEverythingAdEntry({
              adProtoDraft,
              everythingAdId: destinationIeId,
              instantExperiences,
              store,
              ad,
              sessionData,
              parentIds: [...parentIds, element.id, childButtonElement.id],
            });

          const destinationUrl = getElementDestinationUrl({
            elementDests: adProtoDraft.elementDests,
            elementId: childButtonElement.id,
          });

          return {
            ...elementCommonProps,
            id: childButtonElement.id,
            format: "Button",
            ...(everythingAdEntry
              ? { children: [everythingAdEntry] }
              : {
                  destinationUrl,
                }),
          };
        }
        case "CAROUSEL": {
          const children =
            element.child_elements.map<EverythingAdCarouselElementItemEntry>(
              (childPhotoElement, index) => {
                const destEverythingAdId =
                  childPhotoElement.destination?.instantExperienceId;

                const everythingAdEntry =
                  destEverythingAdId &&
                  getEverythingAdEntry({
                    adProtoDraft,
                    everythingAdId: destEverythingAdId,
                    instantExperiences,
                    store,
                    ad,
                    sessionData,
                    parentIds: [...parentIds, element.id, childPhotoElement.id],
                  });

                const destinationUrl = getElementDestinationUrl({
                  elementDests: adProtoDraft.elementDests,
                  elementId: childPhotoElement.id,
                });

                return {
                  adId: ad.id,
                  id: childPhotoElement.id,
                  fullPath: getNodeFullPath({
                    targetId: childPhotoElement.id,
                    parentIds: [...parentIds, element.id],
                  }),
                  storeName: store.dealer_name,
                  name: `Card ${index + 1}`,
                  type: "EverythingAdElement",
                  format: "Image",
                  thumbnail: childPhotoElement.url,
                  hasDestination: true,
                  ...(everythingAdEntry
                    ? { children: [everythingAdEntry] }
                    : { destinationUrl }),
                };
              },
            );
          return {
            ...elementCommonProps,
            format: "Carousel",
            children,
          };
        }
        case "VIDEO": {
          return {
            ...elementCommonProps,
            format: "Video",
            videoUrl: element.url,
            thumbnail: element.thumbnail,
          };
        }
        case "ELEMENT_GROUP": {
          const elementDest = adProtoDraft.elementDests.find(
            dest => dest.elementId === element.id,
          );
          if (elementDest?.destType !== "productSet") {
            throw new Error(
              `Unexpected destination type for element ${element.id}`,
            );
          }
          return {
            ...elementCommonProps,
            format: "Product Set",
            productCatalogId: elementDest?.productCatalogId,
            productSetId: elementDest?.productSetId,
          };
        }
        default: {
          const _exhaustiveCheck: never = elementType;
          throw Error(`Unknown element type: ${_exhaustiveCheck}`);
        }
      }
    },
  );
};

const getNodeFullPath = ({
  targetId,
  parentIds,
}: {
  targetId: string | number;
  parentIds: string[];
}) => `${parentIds.join("_")}_${targetId}`;

const getElementDestinationUrl = ({
  elementDests,
  elementId,
}: {
  elementDests: EAElementDest[];
  elementId: string;
}) => {
  const elementDest = elementDests.find(dest => dest.elementId === elementId);

  return elementDest?.destType === "url"
    ? elementDest.destinationUrl
    : undefined;
};
