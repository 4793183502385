import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createActionsHook } from "react-redux-actions-hook";

type SliceState = {
  selectedRows: (WDFolder | WDAsset)[];
  assetsToEdit: WDAsset[];
  editableRow?: WDAsset | WDFolder;
  assetToReplace?: WDAsset;
  assetsToMove?: WDAsset[];
  assetsToExpire?: WDAsset[];
};

const initialState: SliceState = {
  selectedRows: [],
  assetsToEdit: [],
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    setSelectedRows(state, action: PayloadAction<(WDFolder | WDAsset)[]>) {
      state.selectedRows = action.payload;
    },
    setAssetsToEdit(state, action: PayloadAction<WDAsset[]>) {
      state.assetsToEdit = action.payload;
    },
    setEditableRow(
      state,
      action: PayloadAction<WDAsset | WDFolder | undefined>,
    ) {
      state.editableRow = action.payload;
    },
    setAssetToReplace(state, action: PayloadAction<WDAsset | undefined>) {
      state.assetToReplace = action.payload;
    },
    setAssetsToMove(state, action: PayloadAction<WDAsset[] | undefined>) {
      state.assetsToMove = action.payload;
    },
    setAssetsToExpire(state, action: PayloadAction<WDAsset[] | undefined>) {
      state.assetsToExpire = action.payload;
    },
  },
});

export default mediaSlice.reducer;
export const useMediaActions = createActionsHook(mediaSlice.actions);
