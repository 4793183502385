import { AppstoreOutlined, TableOutlined } from "@ant-design/icons";
import { Button, Input, Tooltip, TooltipProps } from "antd";
import classNames from "classnames";
import { AdFormat, AdType } from "screens/adLibrary/facebookUtils/types";
import PreviewFormatButton from "screens/campaignManagement/adReview/PreviewFormatButton";
import { IndustryType, QCStatus } from "shared/types/shared";
import {
  ToolbarButtonKey,
  ToolbarButtonValue,
  TableCardLayout,
} from "shared/types/toolbar";
import EnhancedSearch from "./EnhancedSearch";
import { ToolbarButtonSwitch } from "./ToolbarButtonSwitch";
import "./ToolbarTable.scss";

const { Search } = Input;

export type ToolbarButton = Partial<
  Record<ToolbarButtonKey, ToolbarButtonValue>
>;

interface IToolbarTableProps {
  searchValue?: string;
  titleTooltip: string;
  disableSearch?: boolean;
  enhancedSearch?: boolean;
  sortingOrder?: string[];
  layout?: TableCardLayout;
  previewFormat?: boolean;
  searchPlaceholder: string;
  toolbarContents: ToolbarButton;
  clientType?: IndustryType;
  selectedTags?: (AdType | QCStatus | string)[];
  tooltipProps?: Partial<TooltipProps>;
  includeFilterOnTableLayout?: boolean;
}

interface IToolbarTableHandlers {
  onSearch?: (value: string) => void;
  onSortChange?: (value: any) => void;
  onClickLayout?: (layout: TableCardLayout) => void;
  setTagSelected?: (tag: AdType | QCStatus | string) => void;
}

type ToolbarTableProps = IToolbarTableProps & IToolbarTableHandlers;

export const ToolbarTable = ({
  layout,
  previewFormat = true,
  clientType,
  searchValue,
  titleTooltip,
  sortingOrder,
  disableSearch,
  enhancedSearch,
  toolbarContents,
  searchPlaceholder,
  onSearch,
  onSortChange,
  onClickLayout,
  tooltipProps,
  includeFilterOnTableLayout = false,
}: ToolbarTableProps) => {
  const filterImport = (group: string) =>
    group !== "Import" ||
    process.env.REACT_APP_AV2_ENABLE_AUTO_AD_LOAD === "true";

  const leftGroup: ToolbarButtonKey[] = (
    [
      "New",
      "Edit",
      "Duplicate",
      "Delete",
      "Toggle",
      "Import",
      "Preview",
      "Load",
      "LoadV2",
      "History",
      "Configure",
      "PullLatest",
      "OrderPage",
      "SelectOffer",
      "SelectOfferArrow",
      "OrderDetails",
      "BuildAsset",
      "Review",
      "SaveOrder",
      "UpdateOffer",
      "CreateOffer",
      "ViewAssetBatches",
      "Export",
      "Coop",
      "Launch",
      "Status",
      "Launcher",
      "Params",
      "LoadEA",
      "ActiveAll",
      "ActiveSome",
      "GenerateUrl",
      "Archive",
      "NewInstance",
      "CustomButton",
    ] as ToolbarButtonKey[]
  ).filter(filterImport);

  const rightGroup = (layoutGroup?: TableCardLayout): ToolbarButtonKey[] => {
    const shared: ToolbarButtonKey[] = ["Tooltip", "Warning", "Error"];
    const tableSpecific: ToolbarButtonKey[] = [...shared, "Expand", "Column"];

    const tableButtons: ToolbarButtonKey[] = includeFilterOnTableLayout
      ? [...tableSpecific, "Filter"]
      : tableSpecific;

    const cardSpecific: ToolbarButtonKey[] = [
      ...shared,
      "Filter",
      "Sort",
      "SelectWithSort",
    ];

    return layoutGroup === "table" ? tableButtons : cardSpecific;
  };

  const toolbarButtonMapper = (key: ToolbarButtonKey) => (
    <ToolbarButtonSwitch
      key={key}
      buttonKey={key}
      clientType={clientType}
      target={toolbarContents[key]}
      tooltipProps={tooltipProps}
      onSortChange={onSortChange}
      sortingOrder={sortingOrder}
    />
  );

  return (
    <div className="toolbar-table" data-cy="toolbar-table">
      <div className="toolbar-left-group">
        {leftGroup.map(toolbarButtonMapper)}
      </div>
      {!enhancedSearch && onSearch && (
        <Tooltip
          title={titleTooltip}
          trigger={"focus"}
          placement="topLeft"
          {...(tooltipProps ?? {})}
        >
          <Search
            allowClear={true}
            value={searchValue}
            disabled={disableSearch}
            data-cy="search-toolbar-input"
            className="search-toolbar-input"
            placeholder={searchPlaceholder}
            onChange={value => onSearch(value.target.value)}
            style={!layout || !onClickLayout ? { marginRight: 0 } : undefined}
          />
        </Tooltip>
      )}
      {enhancedSearch && (
        <Tooltip
          title={titleTooltip}
          trigger={"focus"}
          placement="topLeft"
          {...(tooltipProps ?? {})}
        >
          <EnhancedSearch />
        </Tooltip>
      )}
      <div className="toolbar-right-group">
        {rightGroup(layout).map(toolbarButtonMapper)}
        {layout === "card" && !!previewFormat && (
          <div className="ad-format-buttons">
            <PreviewFormatButton
              key="mobile"
              format={AdFormat.MOBILE_FEED_STANDARD}
            />
            <PreviewFormatButton
              key="desktop"
              format={AdFormat.DESKTOP_FEED_STANDARD}
            />
          </div>
        )}
        {layout && onClickLayout && (
          <Tooltip title={"View as Table"} {...(tooltipProps ?? {})}>
            <Button
              disabled={disableSearch}
              type={layout === "table" ? "primary" : undefined}
              data-cy="layout-table-button"
              key="layout-table-button"
              onClick={() => onClickLayout("table")}
              className={classNames("table-btn-toolbar", {
                "table-btn-toolbar--selected": layout === "table",
              })}
              icon={<TableOutlined />}
            />
          </Tooltip>
        )}
        {layout && onClickLayout && (
          <Tooltip title={"View as Cards"} {...(tooltipProps ?? {})}>
            <Button
              disabled={disableSearch}
              type={layout === "card" ? "primary" : undefined}
              data-cy="layout-card-button"
              key="layout-card-button"
              onClick={() => onClickLayout("card")}
              className={classNames("card-btn-toolbar", {
                "card-btn-toolbar--selected": layout === "card",
              })}
              icon={<AppstoreOutlined />}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default ToolbarTable;
