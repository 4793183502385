import { PlusSquareFilled } from "@ant-design/icons";
import { Typography } from "antd";
import Link from "antd/lib/typography/Link";
import {
  BROADCAST_CHANNEL_NAME,
  videoCompositionEnabled,
} from "shared/constants/assetExporter";
import { useBroadCastChannel } from "shared/hooks/useBroadcastChannel";
import { HtmlPreview } from "../../shared/components/HtmlPreview";
import Preview from "../../shared/components/Preview";
import { NavTemplateItems } from "../../shared/components/Preview/NavTemplateItems";
import { useAssetBatchesContext } from "../../shared/contexts/AssetBatchesContext";
import { AssetBatchesHtmlRenderProvider } from "../../shared/contexts/AssetBatchesHtmlRenderContext";
import { AssetBatchesRenderProvider } from "../../shared/contexts/AssetBatchesRenderContext";
import { useAssetBatchesValueMappingContext } from "../../shared/contexts/AssetBatchesValueMappingContext";
import styles from "./PanelTemplate.module.scss";
import { TemplateDrawer } from "./panelTemplate/TemplateDrawer";

export const PanelTemplate = () => {
  const {
    compositions,
    setShowReload,
    openFileManager,
    setOpenFileManager,
    templatesToUse: templates,
  } = useAssetBatchesContext();
  const { selectedRow } = useAssetBatchesValueMappingContext();

  useBroadCastChannel(BROADCAST_CHANNEL_NAME, () => setShowReload(true));

  const handleEditTemplate = () => {
    const editorUrl = `/design-studio/editor/templates/${templates[0]?.id}/editor-v2?from=ad-engine`;
    window.open(editorUrl, "_blank");
  };

  if (!videoCompositionEnabled)
    return (
      <div className={styles.main}>
        {!templates[0] && (
          <div
            className={styles.noSelection}
            onClick={() => {
              setOpenFileManager(true);
            }}
            data-cy="select-template"
          >
            <PlusSquareFilled className={styles.plusIcon} />
            Select Template
          </div>
        )}

        {!!templates[0] && (
          <>
            <div className={styles.templateHeader}>
              <Typography.Title level={5}>
                {"Preview Template:"}
              </Typography.Title>
              {templates[0].type === "html" ? (
                <Typography.Text className={styles.templateName}>
                  {`${templates[0]?.name || "-"}`}
                </Typography.Text>
              ) : (
                <Link
                  className={styles.templateName}
                  onClick={handleEditTemplate}
                >
                  {`${templates[0]?.name || "-"}`}
                </Link>
              )}

              <Link
                onClick={() => {
                  setOpenFileManager(true);
                }}
                className={styles.changeTemplate}
              >
                Change Template
              </Link>
            </div>
            {templates[0].type === "html" ? (
              <AssetBatchesHtmlRenderProvider
                template={templates[0]}
                row={selectedRow}
                editingComposition={compositions[0]}
              >
                <HtmlPreview />
              </AssetBatchesHtmlRenderProvider>
            ) : (
              <AssetBatchesRenderProvider
                template={templates[0]}
                row={selectedRow}
                valueMappings={compositions[0].variables}
                editingComposition={compositions[0]}
              >
                <Preview />
              </AssetBatchesRenderProvider>
            )}
          </>
        )}
        <TemplateDrawer
          open={openFileManager}
          onClose={() => setOpenFileManager(false)}
        />
      </div>
    );

  return (
    <div>
      <div className={styles.header}>
        <span>Preview</span>
        <NavTemplateItems />
      </div>
      <div className={styles.main}>
        <TemplateDrawer
          open={openFileManager}
          onClose={() => setOpenFileManager(false)}
        />

        {!!compositions?.length &&
          compositions.map(composition => {
            const template = templates.find(
              compTemplate => compTemplate.id === composition.template,
            );

            if (!template) return null;

            return template.type === "html" ? (
              <AssetBatchesHtmlRenderProvider
                key={composition.compositionId}
                template={template}
                row={selectedRow}
                editingComposition={composition}
              >
                <HtmlPreview />
              </AssetBatchesHtmlRenderProvider>
            ) : (
              <AssetBatchesRenderProvider
                key={composition.compositionId}
                template={template}
                row={selectedRow}
                valueMappings={composition.variables}
                editingComposition={composition}
              >
                <Preview />
              </AssetBatchesRenderProvider>
            );
          })}
      </div>
    </div>
  );
};
